import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddressDetails from "../../../Page/Desktop/TW/Common/AddressDetails";
import MAddressDetails from "../../../Page/Mobile/TW/Common/MAddressDetails";
import { TW_ROUTES } from "../../../Router/Path/TWRoutes";
import { TW_SERVICES } from "../../../Services/tw-node/TWServices";
import { useAppSelector } from "../../../redux/hooks";
import { TWSlice } from "../../../redux/slice/TwoWheeler/TWSlice";
import {
  isEmpty,
  validatePincode,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TAddressDetails } from "../../../types/Common/TAddressDetails";
import { CLIENTS } from "../../../URLCollection/Clients";
import {
  pushMotorDataLayer,
  sendEventToNativeApp,
} from "../../../utils/analytics";

const AddressDetailsContainer = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    ADDRESS_DETAILS,
    ADD_FORM_RESPONSE,
    REDIRECTED,
    SELECTED_QUOTE_DATA,
  } = useAppSelector((state) => state.TW);

  const [pageStatus, setPageStatus] = useState<number>(1);
  // Permanent Address
  const [permanentAddress, setPermanentAddress] =
    useState<TAddressDetails>(ADDRESS_DETAILS);
  const validateAttribute = (attrName: string, value: any): boolean => {
    switch (attrName) {
      case "pincode":
        return !validatePincode(value);
      case "mailingPinCode":
        return !validatePincode(value);
      default:
        return isEmpty(value);
    }
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "pageStatus") {
      validateForm();
      setPageStatus(value);
    } else if (attrName === "backbutton") {
      navigate(-1);
    } else {
      const updatedAttribute = {
        value: value,
        warning: validateAttribute(attrName[0], value),
      };
      attrName[3]((prevDetails: any) => ({
        ...prevDetails,
        [attrName[0]]: { ...prevDetails[attrName[0]], ...updatedAttribute },
      }));
    }
  };

  const handleClick = () => {
    setPermanentAddress((prev) => ({
      ...prev,
      isMailingAddressSame: !prev.isMailingAddressSame,
      mailingPinCode: { ...prev.mailingPinCode, warning: false },
      mailingAddressLineOne: {
        ...prev.mailingAddressLineOne,
        warning: false,
      },
      mailingAddressLineTwo: {
        ...prev.mailingAddressLineTwo,
        warning: false,
      },
      mailingCity: prev.mailingCity,
      mailingState: prev.mailingState,
    }));
  };
  const GetCityStateByPin = (key: "P" | "M", value: any) => {
    const onSuccess = (res: any) => {
      const results = res;
      const error = results.error;
      if (error === false) {
        const response = results?.response;
        const city = response?.city_name;
        const state = response?.state_name;
        let updatedPermanentAddress: TAddressDetails = { ...permanentAddress };
        if (key === "P") {
          updatedPermanentAddress = {
            ...updatedPermanentAddress,
            city: city,
            state: state,
          };

          setPermanentAddress(updatedPermanentAddress);
        } else if (key === "M") {
          updatedPermanentAddress = {
            ...updatedPermanentAddress,
            mailingCity: city,
            mailingState: state,
          };

          setPermanentAddress(updatedPermanentAddress);
        }
      }
    };

    const onError = (err: any) => {
      console.log("error", err);
    };

    let param = {
      pincode: value,
    };

    TW_SERVICES.GetCityStateByPin(onSuccess, onError, param);
  };

  const validateForm = () => {
    let data = { ...permanentAddress };

    data = {
      ...data,
      pincode: {
        ...data.pincode,
        warning: !validatePincode(data.pincode.value),
      },
    };
    if (!data.isMailingAddressSame) {
      data = {
        ...data,
        mailingPinCode: {
          ...data.mailingPinCode,
          warning: !validatePincode(data.mailingPinCode.value),
        },
        mailingAddressLineOne: {
          ...data.mailingAddressLineOne,
          warning: isEmpty(data.mailingAddressLineOne.value),
        },
        mailingAddressLineTwo: {
          ...data.mailingAddressLineTwo,
          warning: isEmpty(data.mailingAddressLineTwo.value),
        },
      };

      if (
        !data.pincode.warning &&
        !data.mailingPinCode.warning &&
        !data.mailingAddressLineOne.warning &&
        !data.mailingAddressLineTwo.warning &&
        !isEmpty(data.mailingCity) &&
        !isEmpty(data.mailingState)
      ) {
        if (window.location.hostname === CLIENTS.JIO) {
          pushMotorDataLayer(
            "jioinsure_flow_details",
            "address",
            "proceed",
            "two wheeler",
            REDIRECTED ? "JFS app" : "Insure website"
          );
          const eventProps = {
            action: "address",
            click: "continue",
            type: "two wheeler",
            EntrySource: REDIRECTED ? "JFS app" : "Insure website",
          };
          sendEventToNativeApp("jioinsure_flow_details", eventProps);
        }
        navigate(
          `${TW_ROUTES.PROPOSAL_PAGE}/${TW_ROUTES.PREVIEW_DEATILS}?type=TW&companyCode=${SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id}`
        );
      }
    } else {
      data = {
        ...data,
        mailingPinCode: {
          ...data.pincode,
        },
        mailingCity: data.city,
        mailingState: data.state,
        mailingAddressLineOne: {
          ...data.addressLineOne,
        },
        mailingAddressLineTwo: {
          ...data.addressLineTwo,
        },
      };
      if (!data.pincode.warning) {
        if (window.location.hostname === CLIENTS.JIO) {
          pushMotorDataLayer(
            "jioinsure_flow_details",
            "address",
            "proceed",
            "two wheeler",
            REDIRECTED ? "JFS app" : "Insure website"
          );
          const eventProps = {
            action: "address",
            click: "continue",
            type: "two wheeler",
            EntrySource: REDIRECTED ? "JFS app" : "Insure website",
          };
          sendEventToNativeApp("jioinsure_flow_details", eventProps);
        }
        navigate(
          `${TW_ROUTES.PROPOSAL_PAGE}/${TW_ROUTES.PREVIEW_DEATILS}?type=TW&companyCode=${SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id}`
        );
      }
    }
    setPermanentAddress(data);
    dispatch(TWSlice.actions.ADDRESS_DETAILS(data));

    updateRecords(data);
  };
  useEffect(() => {
    GetCityStateByPin("P", permanentAddress.pincode.value);
  }, [permanentAddress.pincode.value]);

  useEffect(() => {
    if (!permanentAddress.isMailingAddressSame) {
      GetCityStateByPin("M", permanentAddress?.mailingPinCode?.value);
    }
  }, [permanentAddress?.mailingPinCode?.value]);

  const updateRecords = (data: TAddressDetails) => {
    const onSuccess = (res: any) => {
      const results = res;
      const error = results.error;
      if (error === false) {
        const response = results.response;
      }
    };

    const onError = (err: any) => {
      console.log("error", err);
    };
    let param = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      addressPHouse: data.addressLineOne.value,
      addressPPincode: data.pincode.value,
      addressPArea: data.addressLineTwo.value,
      addressPCity: data.city,
      addressPState: data.state,
      addressMailingSame: data.isMailingAddressSame ? 1 : 0,
      addressMHouse: data.mailingAddressLineOne.value,
      addressMPincode: data.mailingPinCode.value,
      addressMArea: data.mailingAddressLineTwo.value,
      addressMCity: data.mailingCity,
      addressMState: data.mailingState,
      section: "address_details",
      insurance_type: "TW",
    };

    TW_SERVICES.CAPTURE_RECORD_ON_EVERY_STEP(onSuccess, onError, param);
  };

  return (
    <>
      {isMobile ? (
        <MAddressDetails
          permanentAddress={permanentAddress}
          updateMasterState={updateMasterState}
          handleClick={handleClick}
          setPermanentAddress={setPermanentAddress}
          pageStatus={pageStatus}
          validateForm={validateForm}
        />
      ) : (
        <AddressDetails
          permanentAddress={permanentAddress}
          updateMasterState={updateMasterState}
          handleClick={handleClick}
          setPermanentAddress={setPermanentAddress}
          pageStatus={pageStatus}
        />
      )}
    </>
  );
};

export default AddressDetailsContainer;
