import { Route, Routes } from "react-router";
import RSACKYCContainer from "../../../../../Container/ckyc-node/rsa/RSACKYCContainer";
import NomineeDetailsContainer from "../../../../../Container/tw-node/common/NomineeDetailsContainer";
import ReviewDetailsContainer from "../../../../../Container/tw-node/common/ReviewDetailsContainer";
import VehicleDetailsContainer from "../../../../../Container/tw-node/common/VehicleDetailsContainer";
import OwnerDetailsContainer from "../../../../../Container/tw-node/rsa/OwnerDetailsContainer";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import { TW_ROUTES } from "../../../../Path/TWRoutes";
import TWProposalContainer from "../../TWProposalContainer";
import AddressDetailsContainer from "../../../../../Container/tw-node/common/AddressDetailsContainer";

export const RSA_NODE_ROUTES = (
  <Routes>
    <Route path={TW_ROUTES.PROPOSAL_PAGE} element={<TWProposalContainer />}>
      <Route path={CKYC_ROUTES.CKYC} element={<RSACKYCContainer />} />
      <Route
        path={TW_ROUTES.OWNER_DETAILS}
        element={<OwnerDetailsContainer />}
      />
      <Route
        path={TW_ROUTES.VEHICLE_DETAILS}
        element={<VehicleDetailsContainer />}
      />
      <Route
        path={TW_ROUTES.NOMINEE_DETAILS}
        element={<NomineeDetailsContainer />}
      />
      <Route
        path={TW_ROUTES.ADDRESS_DETAILS}
        element={<AddressDetailsContainer />}
      />
      <Route
        path={TW_ROUTES.PREVIEW_DEATILS}
        element={<ReviewDetailsContainer />}
      />
    </Route>
  </Routes>
);
