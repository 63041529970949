import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { HealthSlice } from "../../../../redux/slice/Health/HealthSlice";
import MedicalDetailAdityaBirla from "../../../../Page/Desktop/health-php/ADITYA_BIRLA/medicalDetails/MedicalDetailAdityaBirla";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/health-php/HealthPropsalServices";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { TAdityaBirlaMedicalQuestion } from "../../../../types/Health/HealthQuotation/TAdityaBirlaMedicalQuestion";
import { THealthQuote } from "../../../../types/Health/HealthQuotation/THealthQuote";
import { ADITYA_BIRLA_MEDICAL_REQUEST } from "./AdityaBirlaMedicalRequest";
import { toast } from "react-toastify";
import MMedicalDetailAdityaBirla from "../../../../Page/Mobile/health-php/ADITYA_BIRLA/MMedicalDetails/MMedicalDetailAdityaBirla";

const MedicalDetailsContainer = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [userType, setUserType] = useState<string>("");
  const [utm_medium, setUtm_medium] = useState<string>("");
  const [utm_source, setUtm_source] = useState<string>("");

  useEffect(() => {
    // Access the query parameters directly from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const userTypeFromQuery = searchParams.get("user_type");
    const utm_mediumFromQuery = searchParams.get("utm_medium");
    const utm_sourceFromQuery = searchParams.get("utm_source");
    setUtm_medium(`${utm_mediumFromQuery}`);
    setUtm_source(`${utm_sourceFromQuery}`);
    setUserType(
      atob(`${userTypeFromQuery}`) === "agent" ? btoa("agent") : btoa("user")
    );
  }, []);
  const dispatch = useAppDispatch();
  const {
    INSURED_MEMBER_DETAILS,
    PAGE_STATUS,
    MEDICAL_QUESTION_DATA_ADITYA_BIRLA,
    SELECTED_QUOTE_DATA,
    ADD_FORM_RESPONSE,
    Preview_Loader,
  } = useAppSelector((state: any) => state.Health);
  const { Health } = useAppSelector((state: any) => state);
  const [change_status, setchange_status] = useState(false);
  const [medicalQuestionData, setMedicalQuestionData] =
    useState<TAdityaBirlaMedicalQuestion>(MEDICAL_QUESTION_DATA_ADITYA_BIRLA);

  const setMemberDataAsPerUserSelection = () => {
    let setMemberData: any = { ...MEDICAL_QUESTION_DATA_ADITYA_BIRLA };
    setMemberData = {
      MQ01: {
        main_question: {
          id: "MQ01",
          icon_class: "tobaco",
          title:
            "HIV/SLE/ Rheumatoid Arthiritis / Scleroderma / Sarcoidosis/ Psoriasis/ bleeding or clotting disorders or any other diseases of Blood, Bone marrow/ Immunity or Skin.",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS?.ADULT_TWO_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS?.CHILD_ONE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS?.CHILD_TWO_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS?.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ02: {
        main_question: {
          id: "MQ02",
          icon_class: "tobaco",
          title:
            "Disease or disorder of eye, ear, nose or throat (except any sight related problems corrected by prescription lenses)?",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS?.ADULT_ONE_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS?.ADULT_TWO_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS?.CHILD_ONE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS?.CHILD_TWO_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS?.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ03: {
        main_question: {
          id: "MQ03",
          icon_class: "tobaco",
          title:
            "Any other disease / health adversity / injury/ condition / treatment not mentioned above",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS?.ADULT_ONE_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS?.ADULT_TWO_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS?.CHILD_ONE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS?.CHILD_TWO_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS?.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ04: {
        main_question: {
          id: "MQ04",
          icon_class: "tobaco",
          title:
            "Has any of the Proposed to be Insured been hospitalized/ recommended to take investigations/medication or has been under any prolonged treatment/ undergone surgery for any illness/injury other than for childbirth/minor injuries?",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS?.ADULT_ONE_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS?.ADULT_TWO_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS?.CHILD_ONE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS?.CHILD_TWO_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS?.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ05: {
        main_question: {
          id: "MQ05",
          icon_class: "tobaco",
          title:
            "Have you ever been diagnosed with /advised / taken treatment or observation is suggested or undergone any investigation or consulted a doctor or undergone or advised surgery or hospitalized for any one or more from the following?  If YES then please mention Details in the additional information section below",
          description: "",
          status: false,
          sub_question: {
            "MQ1-SQ1": {
              id: "MQ1-SQ1",
              icon_class: "",
              status: false,
              title: "Cancer, tumor, polyp or cyst",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS?.ADULT_ONE_DETAILS?.name?.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Name of Surgery (if any)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title:
                        "Details of Treatment given(hospitalization/OPD, other)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Disability",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Period of hospitalization (if any)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Any Other information",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Disease name",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F8": {
                      id: "MQ1-SQ1-F8",
                      title: "Date of Diagnosis",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS?.ADULT_TWO_DETAILS?.name?.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Name of Surgery (if any)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title:
                        "Details of Treatment given(hospitalization/OPD, other)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Disability",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Period of hospitalization (if any)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Any Other information",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Disease name",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F8": {
                      id: "MQ1-SQ1-F8",
                      title: "Date of Diagnosis",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS?.CHILD_ONE_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Name of Surgery (if any)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title:
                        "Details of Treatment given(hospitalization/OPD, other)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Disability",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Period of hospitalization (if any)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Any Other information",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Disease name",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F8": {
                      id: "MQ1-SQ1-F8",
                      title: "Date of Diagnosis",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS?.CHILD_TWO_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Name of Surgery (if any)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title:
                        "Details of Treatment given(hospitalization/OPD, other)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Disability",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Period of hospitalization (if any)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Any Other information",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Disease name",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F8": {
                      id: "MQ1-SQ1-F8",
                      title: "Date of Diagnosis",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS?.CHILD_THREE_DETAILS?.name
                    ?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Name of Surgery (if any)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title:
                        "Details of Treatment given(hospitalization/OPD, other)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Disability",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Period of hospitalization (if any)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Any Other information",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Disease name",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F8": {
                      id: "MQ1-SQ1-F8",
                      title: "Date of Diagnosis",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Name of Surgery (if any)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title:
                        "Details of Treatment given(hospitalization/OPD, other)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Disability",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Period of hospitalization (if any)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Any Other information",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Disease name",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F8": {
                      id: "MQ1-SQ1-F8",
                      title: "Date of Diagnosis",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ1-SQ2": {
              id: "MQ1-SQ2",
              icon_class: "",
              status: false,
              title:
                "Any heart disease or disorder, chest pain or discomfort, irregular heartbeats, palpitations or heart murmur",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS?.ADULT_ONE_DETAILS?.name?.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Name of Surgery (if any)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title:
                        "Details of Treatment given(hospitalization/OPD, other)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Disability",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Period of hospitalization (if any)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Any Other information",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Disease name",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F8": {
                      id: "MQ1-SQ1-F8",
                      title: "Date of Diagnosis",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS?.ADULT_TWO_DETAILS?.name?.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Name of Surgery (if any)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title:
                        "Details of Treatment given(hospitalization/OPD, other)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Disability",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Period of hospitalization (if any)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Any Other information",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Disease name",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F8": {
                      id: "MQ1-SQ1-F8",
                      title: "Date of Diagnosis",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS?.CHILD_ONE_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Name of Surgery (if any)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title:
                        "Details of Treatment given(hospitalization/OPD, other)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Disability",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Period of hospitalization (if any)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Any Other information",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Disease name",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F8": {
                      id: "MQ1-SQ1-F8",
                      title: "Date of Diagnosis",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS?.CHILD_TWO_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Name of Surgery (if any)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title:
                        "Details of Treatment given(hospitalization/OPD, other)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Disability",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Period of hospitalization (if any)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Any Other information",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Disease name",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F8": {
                      id: "MQ1-SQ1-F8",
                      title: "Date of Diagnosis",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS?.CHILD_THREE_DETAILS?.name
                    ?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Name of Surgery (if any)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title:
                        "Details of Treatment given(hospitalization/OPD, other)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Disability",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Period of hospitalization (if any)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Any Other information",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Disease name",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F8": {
                      id: "MQ1-SQ1-F8",
                      title: "Date of Diagnosis",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Name of Surgery (if any)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title:
                        "Details of Treatment given(hospitalization/OPD, other)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Disability",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Period of hospitalization (if any)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Any Other information",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Disease name",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F8": {
                      id: "MQ1-SQ1-F8",
                      title: "Date of Diagnosis",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
          },
        },
      },
      MQ06: {
        main_question: {
          id: "MQ06",
          icon_class: "tobaco",
          title:
            "Has any of the Proposed to be Insured have been suffering/suffered from Covid-19 disease?If   yes, confirm if any complications arise due to covid-19.",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS?.ADULT_ONE_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS?.ADULT_TWO_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS?.CHILD_ONE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS?.CHILD_TWO_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS?.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ07: {
        main_question: {
          id: "MQ07",
          icon_class: "tobaco",
          title:
            "Do you consume any of the following substances?(if yes, please mention the quantity)",
          description: "",
          status: false,
          sub_question: {
            "MQ1-SQ1": {
              id: "MQ1-SQ1",
              icon_class: "",
              status: false,
              title:
                "Alcohol [30ml ( number of pegs) of hard liquor/ pints of beer/ glass of wines]     per quantity",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS?.ADULT_ONE_DETAILS?.name?.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Quantity",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    // "MQ1-SQ1-F2": {
                    //   id: "MQ1-SQ1-F2",
                    //   title: "quantity",
                    //   field_type: "TEXTFIELD",
                    //   value: { value: "", warning: false },
                    // },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS?.ADULT_TWO_DETAILS?.name?.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Quantity",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    // "MQ1-SQ1-F2": {
                    //   id: "MQ1-SQ1-F2",
                    //   title: "quantity",
                    //   field_type: "TEXTFIELD",
                    //   value: { value: "", warning: false },
                    // },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS?.CHILD_ONE_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Quantity",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    // "MQ1-SQ1-F2": {
                    //   id: "MQ1-SQ1-F2",
                    //   title: "quantity",
                    //   field_type: "TEXTFIELD",
                    //   value: { value: "", warning: false },
                    // },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS?.CHILD_TWO_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Quantity",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    // "MQ1-SQ1-F2": {
                    //   id: "MQ1-SQ1-F2",
                    //   title: "quantity",
                    //   field_type: "TEXTFIELD",
                    //   value: { value: "", warning: false },
                    // },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS?.CHILD_THREE_DETAILS?.name
                    ?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Quantity",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    // "MQ1-SQ1-F2": {
                    //   id: "MQ1-SQ1-F2",
                    //   title: "quantity",
                    //   field_type: "TEXTFIELD",
                    //   value: { value: "", warning: false },
                    // },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Quantity",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    // "MQ1-SQ1-F2": {
                    //   id: "MQ1-SQ1-F2",
                    //   title: "quantity",
                    //   field_type: "TEXTFIELD",
                    //   value: { value: "", warning: false },
                    // },
                  },
                },
              },
            },
            "MQ1-SQ2": {
              id: "MQ1-SQ2",
              icon_class: "",
              status: false,
              title: "Smoking (Number of Cigarette/bidi sticks)  per Week",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS?.ADULT_ONE_DETAILS?.name?.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Quantity",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    // "MQ1-SQ1-F2": {
                    //   id: "MQ1-SQ1-F2",
                    //   title: "quantity",
                    //   field_type: "TEXTFIELD",
                    //   value: { value: "", warning: false },
                    // },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS?.ADULT_TWO_DETAILS?.name?.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Quantity",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    // "MQ1-SQ1-F2": {
                    //   id: "MQ1-SQ1-F2",
                    //   title: "quantity",
                    //   field_type: "TEXTFIELD",
                    //   value: { value: "", warning: false },
                    // },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS?.CHILD_ONE_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Quantity",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    // "MQ1-SQ1-F2": {
                    //   id: "MQ1-SQ1-F2",
                    //   title: "quantity",
                    //   field_type: "TEXTFIELD",
                    //   value: { value: "", warning: false },
                    // },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS?.CHILD_TWO_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Quantity",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    // "MQ1-SQ1-F2": {
                    //   id: "MQ1-SQ1-F2",
                    //   title: "quantity",
                    //   field_type: "TEXTFIELD",
                    //   value: { value: "", warning: false },
                    // },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS?.CHILD_THREE_DETAILS?.name
                    ?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Quantity",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    // "MQ1-SQ1-F2": {
                    //   id: "MQ1-SQ1-F2",
                    //   title: "quantity",
                    //   field_type: "TEXTFIELD",
                    //   value: { value: "", warning: false },
                    // },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Quantity",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    // "MQ1-SQ1-F2": {
                    //   id: "MQ1-SQ1-F2",
                    //   title: "quantity",
                    //   field_type: "TEXTFIELD",
                    //   value: { value: "", warning: false },
                    // },
                  },
                },
              },
            },
            "MQ1-SQ3": {
              id: "MQ1-SQ3",
              icon_class: "",
              status: false,
              title: "Pan Masala/Gutkha (Number of small Pouches)  per Week",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS?.ADULT_ONE_DETAILS?.name?.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Quantity",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    // "MQ1-SQ1-F2": {
                    //   id: "MQ1-SQ1-F2",
                    //   title: "quantity",
                    //   field_type: "TEXTFIELD",
                    //   value: { value: "", warning: false },
                    // },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS?.ADULT_TWO_DETAILS?.name?.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Quantity",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    // "MQ1-SQ1-F2": {
                    //   id: "MQ1-SQ1-F2",
                    //   title: "quantity",
                    //   field_type: "TEXTFIELD",
                    //   value: { value: "", warning: false },
                    // },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS?.CHILD_ONE_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Quantity",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    // "MQ1-SQ1-F2": {
                    //   id: "MQ1-SQ1-F2",
                    //   title: "quantity",
                    //   field_type: "TEXTFIELD",
                    //   value: { value: "", warning: false },
                    // },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS?.CHILD_TWO_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Quantity",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    // "MQ1-SQ1-F2": {
                    //   id: "MQ1-SQ1-F2",
                    //   title: "quantity",
                    //   field_type: "TEXTFIELD",
                    //   value: { value: "", warning: false },
                    // },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS?.CHILD_THREE_DETAILS?.name
                    ?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Quantity",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    // "MQ1-SQ1-F2": {
                    //   id: "MQ1-SQ1-F2",
                    //   title: "quantity",
                    //   field_type: "TEXTFIELD",
                    //   value: { value: "", warning: false },
                    // },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Quantity",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    // "MQ1-SQ1-F2": {
                    //   id: "MQ1-SQ1-F2",
                    //   title: "quantity",
                    //   field_type: "TEXTFIELD",
                    //   value: { value: "", warning: false },
                    // },
                  },
                },
              },
            },
          },
        },
      },
      MQ08: {
        main_question: {
          id: "MQ08",
          icon_class: "tobaco",
          title: "Any Other substance (Name & Quantity)  per Week",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS?.ADULT_ONE_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS?.ADULT_TWO_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS?.CHILD_ONE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS?.CHILD_TWO_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS?.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ09: {
        main_question: {
          id: "MQ09",
          icon_class: "tobaco",
          title:
            "Hypertension / High Blood Pressure(BP)/ High Cholesterol/Any other Lipid disorders",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS?.ADULT_ONE_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS?.ADULT_TWO_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS?.CHILD_ONE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS?.CHILD_TWO_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS?.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ10: {
        main_question: {
          id: "MQ10",
          icon_class: "tobaco",
          title:
            "Asthma / Tuberculosis (TB) / COPD/ Pleural effusion / Bronchitis / Emphysema or any other disease of Lungs, Pleura and airway or Respiratory disease?              ",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS?.ADULT_ONE_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS?.ADULT_TWO_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS?.CHILD_ONE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS?.CHILD_TWO_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS?.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ11: {
        main_question: {
          id: "MQ11",
          icon_class: "tobaco",
          title:
            "Thyroid disease/ Cushing's disease/ Parathyroid Disease/ Addison's disease / Pituitary tumor/ disease or any other disorder of Endocrine system?",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS?.ADULT_ONE_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS?.ADULT_TWO_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS?.CHILD_ONE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS?.CHILD_TWO_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS?.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ12: {
        main_question: {
          id: "MQ12",
          icon_class: "tobaco",
          title:
            "Diabetes Mellitus / High Blood Sugar / Diabetes on Insulin or medication",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS?.ADULT_ONE_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS?.ADULT_TWO_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS?.CHILD_ONE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS?.CHILD_TWO_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS?.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ13: {
        main_question: {
          id: "MQ13",
          icon_class: "tobaco",
          title:
            "Motor Neuron Disease/ Muscular dystrophies/ Myasthenia Gravis/ Demyelinating disease or any other disease of Neuromuscular system (muscles and/or nervous system)",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS?.ADULT_ONE_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS?.ADULT_TWO_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS?.CHILD_ONE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS?.CHILD_TWO_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS?.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ14: {
        main_question: {
          id: "MQ14",
          icon_class: "tobaco",
          title:
            "Stroke/Paralysis/Transient Ischemic Attack/Multiple Sclerosis/Epilepsy/Mental-Psychiatric illness/ Parkinsonism/Alzheimer's/Depression /Dementia or any other disease of Brain and Nervous System?              ",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS?.ADULT_ONE_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS?.ADULT_TWO_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS?.CHILD_ONE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS?.CHILD_TWO_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS?.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ15: {
        main_question: {
          id: "MQ15",
          icon_class: "tobaco",
          title:
            "Cirrhosis / Hepatitis / Wilson's disease / Pancreatitis / Liver disease / Crohn's disease / Ulcerative Colitis /Inflammatory Bowel Diseases/ Piles or any other disease of Mouth, Esophagus, Liver, Gall bladder, Stomach or Intestines or any other part of Digestive System?",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS?.ADULT_ONE_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS?.ADULT_TWO_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS?.CHILD_ONE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS?.CHILD_TWO_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS?.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ16: {
        main_question: {
          id: "MQ16",
          icon_class: "tobaco",
          title:
            "Kidney Stones/ Renal Failure/ Dialysis/ Chronic Kidney Disease/ Prostate Disease or any other disease of Kidney, Urinary Tract or reproductive organs?",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS?.ADULT_ONE_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS?.ADULT_TWO_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS?.CHILD_ONE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS?.CHILD_TWO_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS?.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ17: {
        main_question: {
          id: "MQ17",
          icon_class: "tobaco",
          title:
            "Do you have Previous/ Current policy or proposal applied for life, health, hospital daily cash or critical illness or Cancer or personal accident insurance?              ",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS?.ADULT_ONE_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title:
                    "Do You want to consider this Health policy  for Portability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Insurer Name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Claim in previous policy",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS?.ADULT_TWO_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title:
                    "Do You want to consider this Health policy  for Portability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Insurer Name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Claim in previous policy",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS?.CHILD_ONE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title:
                    "Do You want to consider this Health policy  for Portability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Insurer Name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Claim in previous policy",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS?.CHILD_TWO_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title:
                    "Do You want to consider this Health policy  for Portability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Insurer Name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Claim in previous policy",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS?.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title:
                    "Do You want to consider this Health policy  for Portability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Insurer Name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Claim in previous policy",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title:
                    "Do You want to consider this Health policy  for Portability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Insurer Name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Claim in previous policy",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ18: {
        main_question: {
          id: "MQ18",
          icon_class: "tobaco",
          title:
            "Was any proposal/policy declined/ deferred/ withdrawn / accepted with modified terms/ cancelled, if “Yes” please provide details in additional information",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS?.ADULT_ONE_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F9": {
                  id: "MQ1-SQ1-F9",
                  title: "Claim in previous policy",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS?.ADULT_TWO_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F9": {
                  id: "MQ1-SQ1-F9",
                  title: "Claim in previous policy",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS?.CHILD_ONE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F9": {
                  id: "MQ1-SQ1-F9",
                  title: "Claim in previous policy",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS?.CHILD_TWO_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F9": {
                  id: "MQ1-SQ1-F9",
                  title: "Claim in previous policy",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS?.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F9": {
                  id: "MQ1-SQ1-F9",
                  title: "Claim in previous policy",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F9": {
                  id: "MQ1-SQ1-F9",
                  title: "Claim in previous policy",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ19: {
        main_question: {
          id: "MQ19",
          icon_class: "tobaco",
          title:
            "Disease of the musculoskeletal system / Or thopedic disorders/Degeneration, Fracture or dislocation of bones or joints/ avascular necrosis of joints or any other disorder related to it?",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS?.ADULT_ONE_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS?.ADULT_TWO_DETAILS?.name?.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS?.CHILD_ONE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS?.CHILD_TWO_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS?.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Last Consultation Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Name of Surgery (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title:
                    "Details of Treatment given(hospitalization/OPD, other)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Disability",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Period of hospitalization (if any)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Any Other information",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Disease name",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "Date of Diagnosis",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
    };
    setMedicalQuestionData({ ...setMemberData });
  };

  useEffect(() => {
    if (PAGE_STATUS === true || Preview_Loader === true) {
      setMedicalQuestionData(MEDICAL_QUESTION_DATA_ADITYA_BIRLA);
    } else {
      setMemberDataAsPerUserSelection();
    }
  }, [MEDICAL_QUESTION_DATA_ADITYA_BIRLA, PAGE_STATUS]);

  const updateMasterState = (attrName: any, value: any) => {
    let manipulatedMedicalQuestion: any = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    if (attrName[0] === "question") {
      // debugger;
      manipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question,
            status: value,
          },
        },
      };

      if (value === false) {
        if (["MQ05", "MQ07"].includes(attrName[1])) {
          // Set all subquestions to false for MQ02 and MQ05
          Object.keys(
            manipulatedMedicalQuestion[attrName[1]].main_question.sub_question
          ).forEach((subQuestionKey) => {
            manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
              subQuestionKey
            ].status = false;
          });
        } else if (!["MQ05", "MQ07"].includes(attrName[1])) {
          // Set selection status of all member data to false for MQ01, MQ03, MQ04
          Object.keys(
            manipulatedMedicalQuestion[attrName[1]].main_question.member_data
          ).forEach((key) => {
            manipulatedMedicalQuestion[attrName[1]].main_question.member_data[
              key
            ].selection_status = false;
          });
        }
      }
      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    } else if (attrName[0] === "subQuestion") {
      let updatedSubQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question,
        [attrName[2]]: {
          ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
            attrName[2]
          ],
          status: value,
        },
      };
      let updatedMainQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question,
        sub_question: updatedSubQuestion,
      };

      let updatedManipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: updatedMainQuestion,
        },
      };
      if (value === false) {
        // Set mainMember and mainMemberDataField to false and empty
        Object.keys(
          manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
            attrName[2]
          ].member_data
        ).forEach((memberDataKey) => {
          manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
            attrName[2]
          ].member_data[memberDataKey].selection_status = false;
          Object.keys(
            manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
              attrName[2]
            ].member_data[memberDataKey].field_data
          ).forEach((fieldDataKey) => {
            manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
              attrName[2]
            ].member_data[memberDataKey].field_data[fieldDataKey].value = {
              value: "",
              warning: false,
            };
          });
        });
        manipulatedMedicalQuestion[attrName[1]].main_question.mainMember =
          false;
        // manipulatedMedicalQuestion[attrName[1]].main_question.mainMemberDataField = '';
      }
      setMedicalQuestionData(updatedManipulatedMedicalQuestion);
    } else if (
      attrName[0] === "mainMember" &&
      ["MQ05", "MQ07"].includes(attrName[1])
    ) {
      // debugger;
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question.sub_question[
          attrName[2]
        ]?.member_data[attrName[3]] !== undefined
      ) {
        // debugger;
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              sub_question: {
                ...manipulatedMedicalQuestion[attrName[1]].main_question
                  .sub_question,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    .sub_question?.[attrName[2]],
                  member_data: {
                    ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                      .sub_question?.[attrName[2]].member_data,
                    [attrName[3]]: {
                      ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                        .sub_question?.[attrName[2]].member_data?.[attrName[3]],
                      selection_status: value,
                    },
                  },
                },
              },
            },
          },
        };

        if (value === false) {
          // Set mainMember and mainMemberDataField to false and empty
          manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
            attrName[2]
          ].member_data[attrName[3]].selection_status = false;
          Object.keys(
            manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
              attrName[2]
            ].member_data[attrName[3]].field_data
          ).forEach((fieldDataKey) => {
            manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
              attrName[2]
            ].member_data[attrName[3]].field_data[fieldDataKey].value = {
              value: "",
              warning: false,
            };
          });
          manipulatedMedicalQuestion[attrName[1]].main_question.mainMember =
            false;
          // manipulatedMedicalQuestion[attrName[1]].main_question.mainMemberDataField = '';
        }

        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      }
    } else if (
      attrName[0] === "mainMember" &&
      !["MQ05", "MQ07"].includes(attrName[1])
    ) {
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data[
          attrName[2]
        ] !== undefined
      )
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              member_data: {
                ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                  .member_data,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    ?.member_data?.[attrName[2]],
                  selection_status: value,
                },
              },
            },
          },
        };

      // if (value === false) {
      //   // Set mainMember and mainMemberDataField to false and empty
      //   manipulatedMedicalQuestion[attrName[1]].main_question.member_data[attrName[3]].selection_status = false;
      //   Object.keys(
      //     manipulatedMedicalQuestion[attrName[1]].main_question.member_data[attrName[3]].field_data
      //   ).forEach((fieldDataKey) => {
      //     manipulatedMedicalQuestion[attrName[1]].main_question.member_data[attrName[3]].field_data[fieldDataKey].value = {
      //       value: "",
      //       warning: false,
      //     };
      //   });
      //   manipulatedMedicalQuestion[attrName[1]].main_question.mainMember =
      //     false;
      //   // manipulatedMedicalQuestion[attrName[1]].main_question.mainMemberDataField = '';
      // }

      setMedicalQuestionData(manipulatedMedicalQuestion);
    } else if (
      attrName[0] === "mainMember" &&
      manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data !==
        undefined
    ) {
      // debugger;
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data[
          attrName[2]
        ] !== undefined
      )
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              member_data: {
                ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                  .member_data,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    ?.member_data?.[attrName[2]],
                  selection_status: value,
                },
              },
            },
          },
        };
      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    } else if (attrName[0] === "mainMemberData") {
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data[
          attrName[2]
        ] !== undefined
      )
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              member_data: {
                ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                  .member_data,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    ?.member_data?.[attrName[2]],
                  selection_status: value,
                },
              },
            },
          },
        };

      setMedicalQuestionData(manipulatedMedicalQuestion);
    } else if (
      (attrName[0] === "mainMemberDataField" && attrName[1] === "MQ05") ||
      (attrName[0] === "mainMemberDataField" && attrName[1] === "MQ07")
    ) {
      // debugger;
      manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
        attrName[4]
      ].member_data[attrName[2]].field_data[attrName[3]].value = {
        value: value,
        warning: isEmpty(value),
      };

      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    } else if (
      (attrName[0] === "mainMemberDataField" && attrName[1] !== "MQ05") ||
      (attrName[0] === "mainMemberDataField" && attrName[1] !== "MQ07")
    ) {
      // debugger;
      manipulatedMedicalQuestion[attrName[1]].main_question.member_data[
        attrName[3]
      ].field_data[attrName[2]].value = {
        value: value,
        warning: isEmpty(value),
      };

      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    }
  };

  const capture_record = (data: TAdityaBirlaMedicalQuestion) => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const cus_type = params.get("cus_type");

    const onSuccess = (res: any) => {
      dispatch(
        HealthSlice.actions.BULK_UPLOAD({
          ...Health,
          QUOTE_LOADER: false,
          MEDICAL_QUESTION_DATA_ADITYA_BIRLA: data,
        })
      );
      if (res.results.error === false) {
        navigate(
          `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
        );
        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.results.response.premiumDetails.finalPremium,
          },
        };
        dispatch(HealthSlice.actions.SAVE_SELECTED_QUOTE_DATA(quoteData));
      }
    };
    const onError = (err: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
    };
    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    const param = ADITYA_BIRLA_MEDICAL_REQUEST(
      data,
      ADD_FORM_RESPONSE,
      SELECTED_QUOTE_DATA
    );
    HEALTH_PROPOSAL_SERVICES.getMedicalData(onSuccess, onError, param);
  };

  const validateForm = () => {
    if (!medicalQuestionData) {
      return;
    }

    let data: TAdityaBirlaMedicalQuestion = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    let hasError = false;
    console.log(
      "dfgfhbfg",
      data.MQ05.main_question?.sub_question?.["MQ1-SQ1"].status
    );

    if (data?.MQ05.main_question.status) {
      if (
        !data.MQ05.main_question?.sub_question?.["MQ1-SQ1"].status &&
        !data.MQ05.main_question?.sub_question?.["MQ1-SQ2"].status
      ) {
        toast.error("Please Select Sub Question");
        hasError = true;
      }
    } else if (data?.MQ07.main_question.status) {
      if (
        !data.MQ07.main_question?.sub_question?.["MQ1-SQ1"].status &&
        !data.MQ07.main_question?.sub_question?.["MQ1-SQ2"].status &&
        !data.MQ07.main_question?.sub_question?.["MQ1-SQ3"].status
      ) {
        toast.error("Please Select Sub Question");
        hasError = true;
      }
    } else {
      Object.values(data).forEach((element: any) => {
        const mainQuestion = element?.main_question;
        console.log("main", mainQuestion);
        if (mainQuestion?.id !== "MQ05" && mainQuestion?.id !== "MQ07") {
          if (mainQuestion && mainQuestion.status) {
            if (
              mainQuestion.member_data.adult_one.selection_status === false &&
              mainQuestion.member_data.adult_two.selection_status === false &&
              mainQuestion.member_data.child_one.selection_status === false &&
              mainQuestion.member_data.child_two.selection_status === false &&
              mainQuestion.member_data.child_three.selection_status === false &&
              mainQuestion.member_data.child_four.selection_status === false
            ) {
              toast.error("Please Select Adult Or Child");
              hasError = true;
            }
          }
        } else {
          if (mainQuestion?.id === "MQ05") {
            if (mainQuestion.sub_question) {
              Object.values(mainQuestion.sub_question).forEach(
                (subQuestion: any) => {
                  if (subQuestion && subQuestion.status) {
                    const hasFalseSelectionStatus = Object.values(
                      subQuestion.member_data
                    ).some((memberdata: any) => !memberdata.selection_status);
                    const hasTrueSelectionStatus = Object.values(
                      subQuestion.member_data
                    ).some((memberdata: any) => memberdata.selection_status);

                    if (hasFalseSelectionStatus && !hasTrueSelectionStatus) {
                      hasError = true;
                      toast.error("Select Sub-Question members");
                    }
                  }
                }
              );
            }
          }
          if (mainQuestion?.id === "MQ07") {
            if (mainQuestion.sub_question) {
              Object.values(mainQuestion.sub_question).forEach(
                (subQuestion: any) => {
                  if (subQuestion && subQuestion.status) {
                    const hasFalseSelectionStatus = Object.values(
                      subQuestion.member_data
                    ).some((memberdata: any) => !memberdata.selection_status);
                    const hasTrueSelectionStatus = Object.values(
                      subQuestion.member_data
                    ).some((memberdata: any) => memberdata.selection_status);

                    if (hasFalseSelectionStatus && !hasTrueSelectionStatus) {
                      hasError = true;
                      toast.error("Select Sub-Question members");
                    }
                  }
                }
              );
            }
          }
        }
      });

      Object.values(data).forEach((element: any) => {
        const mainQuestion = element?.main_question;
        if (mainQuestion && mainQuestion.status) {
          const memberData = mainQuestion.member_data;
          if (memberData) {
            Object.values(memberData).forEach((member: any) => {
              if (member.selection_status && member.field_data) {
                Object.values(member.field_data).forEach((field: any) => {
                  if (field.value?.value === "") {
                    field.value = {
                      ...field.value,
                      warning: true,
                    };
                    hasError = true;
                  }
                });
              }
            });
          }
        }
      });

      Object.values(data).forEach((element: any) => {
        const mainQuestion = element?.main_question;
        if (mainQuestion && mainQuestion.status && mainQuestion.sub_question) {
          Object.values(mainQuestion.sub_question).forEach(
            (subQuestion: any) => {
              if (subQuestion && subQuestion.status) {
                Object.values(subQuestion.member_data).forEach(
                  (memberdata: any) => {
                    if (
                      memberdata &&
                      memberdata.selection_status &&
                      memberdata.field_data
                    ) {
                      Object.values(memberdata.field_data).forEach(
                        (field_data: any) => {
                          if (field_data && field_data.value?.value === "") {
                            let clonedValue = {
                              ...field_data.value,
                              warning: true,
                            };

                            if (field_data.value) {
                              field_data.value = clonedValue;
                            }
                            hasError = true;
                          }
                        }
                      );
                    }
                  }
                );
              }
            }
          );
        }
      });
    }

    setMedicalQuestionData(data);

    if (!hasError) {
      capture_record(data);
    }
  };

  return (
    <>
      {isMobile ? (
        <MMedicalDetailAdityaBirla
          medicalQuestionData={medicalQuestionData}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          PAGE_STATUS={PAGE_STATUS}
        />
      ) : (
        <MedicalDetailAdityaBirla
          medicalQuestionData={medicalQuestionData}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          PAGE_STATUS={PAGE_STATUS}
        />
      )}
    </>
  );
};

export default MedicalDetailsContainer;
