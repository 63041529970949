import EditIcon from "@mui/icons-material/Edit";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { HealthSlice } from "../../../../../redux/slice/Health/HealthSlice";
import { HEALTH_ROUTE } from "../../../../../Router/Path/HealthRoutes";
import { ECompanyCode } from "../../../../../Services/Enum/EHome";
import { TCareHeartMedicalQuestion } from "../../../../../types/Health/HealthQuotation/TCareHeartMedicalQuestion";
import { GET_QUERY_PARAMETERS } from "../../../../../SupportingFiles/HelpingFunction";

function MedicalDetailPreviewBoxCareHeart() {
  const {
    MEDICAL_QUESTION_DATA_CARE_HEART,
    SELECTED_QUOTE_DATA,

    ADD_FORM_RESPONSE,
  } = useAppSelector((state) => state.Health);

  console.log(
    "MEDICAL_QUESTION_DATA_CARE_HEART",
    MEDICAL_QUESTION_DATA_CARE_HEART
  );

  const navigate = useNavigate();
  const [medicalQuestionData, setMedicalQuestionData] =
    React.useState<TCareHeartMedicalQuestion>(MEDICAL_QUESTION_DATA_CARE_HEART);
  console.log("MEDICAL_QUESTION_DATA_CARE_HEART", medicalQuestionData);
  const dispatch = useAppDispatch();
  // Initialize a variable to track if a main question with status true is found
  let found = false;
  let founds = false;

  Object.values(medicalQuestionData).forEach((main_data: any) => {
    console.log("main_data:", main_data);
    if (main_data && main_data.main_question) {
      Object.values(main_data.main_question.member_data).forEach(
        (member_data: any) => {
          console.log("member_data:", member_data);
          if (member_data && member_data.selection_status) {
            founds = true; // Set founds to true if at least one member has selection_status true
            console.log("founds:", founds);
            return; // Break out of the loop
          }
        }
      );
    }
  });

  Object.values(medicalQuestionData).forEach((main_data: any) => {
    // Check if main_data and main_question exist and are not null or undefined
    if (
      main_data &&
      main_data.main_question &&
      main_data.main_question.sub_question
    ) {
      // Iterate over each main question's sub-questions
      Object.values(main_data.main_question.sub_question).forEach(
        (sub_question_data: any) => {
          // Check if sub_question_data exists and is not null or undefined
          if (sub_question_data && sub_question_data.status) {
            // Iterate over each sub-question's member data
            Object.values(sub_question_data.member_data).forEach(
              (member_data: any) => {
                // Check if member_data exists and is not null or undefined
                if (member_data && member_data.selection_status) {
                  // If a sub-question with status true and at least one member with selection_status true is found
                  found = true; // Set found to true
                  return; // Break out of the loop
                }
              }
            );
          }
        }
      );
    }
  });

  return (
    <Box className="previewBox mb-6 mx-3">
      <Grid container columnSpacing={2}>
        <Grid xs={8}>
          {found || founds ? (
            <p className="previewBoxTitle">Insured Member's Medical Details</p>
          ) : (
            <p className="previewBoxTitle">No Medical History Declared</p>
          )}
        </Grid>
        <Grid xs={4} textAlign="right">
          <Link
            onClick={() => {
              dispatch(HealthSlice.actions.CHANGE_PAGE_STATUS(true));
              navigate(
                `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
                  HEALTH_ROUTE.MEDICAL_DETAILS
                }?type=HP&companyCode=${
                  SELECTED_QUOTE_DATA?.CompanyDetails.company_code
                }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
                  SELECTED_QUOTE_DATA?.productDetails.id
                }&user_type=${GET_QUERY_PARAMETERS().userType}&utm_source=${
                  GET_QUERY_PARAMETERS().utm_source
                }&utm_medium=${GET_QUERY_PARAMETERS().utm_medium}`
              );
            }}
            className="previewBoxEdit"
          >
            <EditIcon /> Edit
          </Link>
        </Grid>
      </Grid>
      {SELECTED_QUOTE_DATA.CompanyDetails.company_code === ECompanyCode.CARE ? (
        <Grid xs={12}>
          {Object.values(medicalQuestionData).map((main_data: any) =>
            main_data.main_question.status ? (
              <div key={main_data.main_question.id}>
                {/* Show title and description only when at least one member has selection_status true */}

                {Object.values(main_data.main_question.member_data).some(
                  (member_data: any) => member_data.selection_status
                ) && (
                  <>
                    {main_data.main_question.title === "" ? null : (
                      <h6 className="question">
                        {main_data.main_question.title}
                      </h6>
                    )}
                    <p className="mb-4">
                      {main_data.main_question.description}
                    </p>
                  </>
                )}
                <ul className="member_list">
                  {Object.values(main_data.main_question.member_data).map(
                    (member_data: any) =>
                      member_data.selection_status && (
                        <li key={member_data.id}>
                          <div
                            className={`${member_data.image_class} illness`}
                          ></div>
                          <p className="member_name">{member_data.name}</p>
                          <p className="member_name">
                            {member_data.field_data &&
                              Object.values(member_data.field_data).map(
                                (field_data: any) => (
                                  <p key={field_data.id}>
                                    {`${field_data.title}: ${field_data.value.value}`}
                                  </p>
                                )
                              )}
                          </p>
                        </li>
                      )
                  )}
                </ul>
              </div>
            ) : null
          )}
        </Grid>
      ) : (
        <Grid container columnSpacing={2}>
          <Grid xs={12}></Grid>
        </Grid>
      )}
    </Box>
  );
}

export default MedicalDetailPreviewBoxCareHeart;
