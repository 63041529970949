import React, { useEffect, useRef, useState } from "react";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { HealthSlice } from "../../../../redux/slice/Health/HealthSlice";
import { THealthQuote } from "../../../../types/Health/HealthQuotation/THealthQuote";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { Box } from "@mui/material";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/health-node/HealthPropsalServices";
import AddonDetailSuperStar from "../../../../Page/Desktop/health-node/STAR/addon/AddonDetailSuperStar";
import { TSUPER_STAR_ADDON } from "../../../../types/Health/THealthSlice";

const AddonDetailsContainerSuperStar = () => {
  const { Health, CKYC } = useAppSelector((state) => state);
  const {
    POLICY_TENURE,
    ADD_FORM,
    ADD_FORM_RESPONSE,
    SUPER_STAR_ADDON,
    SELECTED_QUOTE_DATA,
    PAGE_STATUS,
    QUOTE_LOADER,
  } = useAppSelector((state) => state.Health);
  const dispatch = useAppDispatch();
  const [addonStatus, setAddonState] =
    useState<TSUPER_STAR_ADDON>(SUPER_STAR_ADDON);

  const [userType, setUserType] = useState<string>("");
  const [utm_medium, setUtm_medium] = useState<string>("");
  const [utm_source, setUtm_source] = useState<string>("");

  useEffect(() => {
    // Access the query parameters directly from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const userTypeFromQuery = searchParams.get("user_type");
    const utm_mediumFromQuery = searchParams.get("utm_medium");
    const utm_sourceFromQuery = searchParams.get("utm_source");
    setUtm_medium(`${utm_mediumFromQuery}`);
    setUtm_source(`${utm_sourceFromQuery}`);
    setUserType(
      atob(`${userTypeFromQuery}`) === "agent" ? btoa("agent") : btoa("user")
    );
  }, []);
  const isMobole = useIsMobile();
  const navigate = useNavigate();
  const updateMasterState = (attrName: string, value: boolean) => {
    setAddonState((prev) => ({
      ...prev,
      [attrName]: value,
    }));
  };
  const updatePreiumApi = () => {
    const onSuccess = (res: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      FinalPremium();
      if (res.error === false) {
        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.response.premiumDetails.finalPremium,
          },
        };

        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            // ADDON_QUESTION_NIVA_BUPA: addonQuestionData,
            QUOTE_LOADER: false,
          })
        );
      }
      if (res.error === true) {
        toast.error(res.message);
        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: 0,
          },
        };

        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            // ADDON_QUESTION_NIVA_BUPA: addonQuestionData,
            QUOTE_LOADER: false,
          })
        );
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
    };
    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    let param: any = {
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_id: SELECTED_QUOTE_DATA.productDetails.id,
      adult: ADD_FORM.spouse_status === true ? "2" : "1",
      child: `${ADD_FORM.children}`,
      gender: `${CKYC.VERIFY_KYC_FORM.gender.value}`,
      finalPremium: Number(SELECTED_QUOTE_DATA.premiumDetails.finalPremium),
      term: `${POLICY_TENURE}`,
      addon: {
        smartNetwork: addonStatus.SMART_NETWORK ? 1 : 0,
        consumablesCover: addonStatus.CONSUMABLES_COVER ? 1 : 0,
        futureShield: addonStatus.FUTURE_SHIELD ? 1 : 0,
        womenCare: addonStatus.WOMEN_CARE ? 1 : 0,
        highEndDiagnostics: addonStatus.HIGHEND_DIAGNOSTICS ? 1 : 0,
        annualHealthCheckup: addonStatus.ANNUAL_HEALTH_CHECKUP ? 1 : 0,
        eInternationalSecondOption: addonStatus.E_INTERNATIONAL_SECOND_OPINION
          ? 1
          : 0,
        durableMedicalEquipmentCover:
          addonStatus.DURABLE_MEDICAL_EQUIPMENT_COVER ? 1 : 0,
        compassionateVisit: addonStatus.COMPASSIONATE_VISIT ? 1 : 0,
        isNri: addonStatus.NRI_ADVANTAGE ? 1 : 0,
        superStarBonus: addonStatus.SUPER_STAR_BONUS ? 1 : 0,
        limitLessCare: addonStatus.LIMITLESS_CARE ? 1 : 0,
        reductionWaitingPeriod: addonStatus.REDUCTION_PRE_EXISTING_DISEASE
          ? 1
          : 0,
        voluntaryDeductible:
          addonStatus.VOLUNTARY_DEDUCTIBLE === true
            ? addonStatus.voluntaryDeductible
            : 0,
        quickShield: addonStatus.QUICK_SHIELD ? 1 : 0,
        personalAccidentCover: addonStatus.PERSONAL_ACCIDENT_COVER ? 1 : 0,
        maternityExpense: addonStatus.MATERNITY_EXPENSES
          ? addonStatus?.MATERNITY_EXPENSES_OPTION
          : "",
      },
    };

    HEALTH_PROPOSAL_SERVICES.GET_ADDON_PREMIMIUM_SUPER_STAR(
      onSuccess,
      onError,
      param
    );
  };

  const FinalPremium = () => {
    const onSuccess = (res: any) => {
      console.log("res", res);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      final_premium: SELECTED_QUOTE_DATA?.premiumDetails?.finalPremium,
    };
    HEALTH_PROPOSAL_SERVICES.GET_FINAL_PREMIUM(onSuccess, onError, param);
  };

  useEffect(() => {
    updatePreiumApi();
  }, [addonStatus]);

  useEffect(() => {
    RECORD_CKYC_RESPONSE();
  }, [CKYC.CKYC_DETAILS]);

  const RECORD_CKYC_RESPONSE = () => {
    const onSuccess = (res: any) => {
      console.log("res...", res);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      details: CKYC.CKYC_DETAILS,
      section: "kyc_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      insurance_type: "HP",
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
    };
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  const validateForm = () => {
    const onSuccess = (res: any) => {
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      const cus_type = params.get("cus_type");

      if (!res.error) {
        if (PAGE_STATUS) {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
          );
        } else {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
            // `${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=HP&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.productDetails.id}`
          );
        }
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    let param: any = {
      insurance_type: "HP",
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_id: SELECTED_QUOTE_DATA.productDetails.id,
      adult: ADD_FORM.spouse_status === true ? "2" : "1",
      child: `${ADD_FORM.children}`,
      gender: `${CKYC.VERIFY_KYC_FORM.gender.value}`,
      finalPremium: Number(SELECTED_QUOTE_DATA.premiumDetails.finalPremium),
      term: `${POLICY_TENURE}`,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
      section: "add_ons_details",
      addon: {
        smartNetwork: addonStatus.SMART_NETWORK ? 1 : 0,
        consumablesCover: addonStatus.CONSUMABLES_COVER ? 1 : 0,
        futureShield: addonStatus.FUTURE_SHIELD ? 1 : 0,
        womenCare: addonStatus.WOMEN_CARE ? 1 : 0,
        highEndDiagnostics: addonStatus.HIGHEND_DIAGNOSTICS ? 1 : 0,
        annualHealthCheckup: addonStatus.ANNUAL_HEALTH_CHECKUP ? 1 : 0,
        eInternationalSecondOption: addonStatus.E_INTERNATIONAL_SECOND_OPINION
          ? 1
          : 0,
        durableMedicalEquipmentCover:
          addonStatus.DURABLE_MEDICAL_EQUIPMENT_COVER ? 1 : 0,
        compassionateVisit: addonStatus.COMPASSIONATE_VISIT ? 1 : 0,
        isNri: addonStatus.NRI_ADVANTAGE ? 1 : 0,
        superStarBonus: addonStatus.SUPER_STAR_BONUS ? 1 : 0,
        limitLessCare: addonStatus.LIMITLESS_CARE ? 1 : 0,
        reductionWaitingPeriod: addonStatus.REDUCTION_PRE_EXISTING_DISEASE
          ? 1
          : 0,
        voluntaryDeductible:
          addonStatus.VOLUNTARY_DEDUCTIBLE === true
            ? addonStatus.voluntaryDeductible
            : 0,
        quickShield: addonStatus.QUICK_SHIELD ? 1 : 0,
        personalAccidentCover: addonStatus.PERSONAL_ACCIDENT_COVER ? 1 : 0,
        maternityExpense: addonStatus.MATERNITY_EXPENSES
          ? addonStatus?.MATERNITY_EXPENSES_OPTION
          : "",
      },
    };
    dispatch(HealthSlice.actions.ADDON_DETAILS_DATA_SUPER_STAR(addonStatus));

    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };
  return (
    <>
      {QUOTE_LOADER ? (
        <Box
          position="fixed"
          height="100%"
          width="100%"
          zIndex="991"
          top="0px"
          left="0px"
        />
      ) : null}
      {isMobole ? null : (
        <AddonDetailSuperStar
          updateMasterState={updateMasterState}
          addonStatus={addonStatus}
          validateForm={validateForm}
        />
      )}
    </>
  );
};

export default AddonDetailsContainerSuperStar;
