import * as React from "react";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import EditIcon from "@mui/icons-material/Edit";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { TCareMedicalQuestion } from "../../../../../types/Health/HealthQuotation/TCareMedicalQuestion";
import { HealthSlice } from "../../../../../redux/slice/Health/HealthSlice";
import { HEALTH_ROUTE } from "../../../../../Router/Path/HealthRoutes";
import { ECompanyCode } from "../../../../../Services/Enum/EHome";
import { GET_QUERY_PARAMETERS } from "../../../../../SupportingFiles/HelpingFunction";

function MedicalDetailPreviewBoxIcic() {
  const { MEDICAL_QUESTION_DATA_ICIC, SELECTED_QUOTE_DATA, ADD_FORM_RESPONSE } =
    useAppSelector((state) => state.Health);
  const navigate = useNavigate();
  const [medicalQuestionData, setMedicalQuestionData] =
    React.useState<TCareMedicalQuestion>(MEDICAL_QUESTION_DATA_ICIC);
  const dispatch = useAppDispatch();
  // Initialize a variable to track if a main question with status true is found
  let found = false;

  Object.values(medicalQuestionData).forEach((main_data: any) => {
    // Check if main_data and main_question exist and are not null or undefined
    if (
      main_data &&
      main_data.main_question &&
      main_data.main_question.sub_question
    ) {
      // Iterate over each main question's sub-questions
      Object.values(main_data.main_question.sub_question).forEach(
        (sub_question_data: any) => {
          // Check if sub_question_data exists and is not null or undefined
          if (sub_question_data && sub_question_data.status) {
            // Iterate over each sub-question's member data
            Object.values(sub_question_data.member_data).forEach(
              (member_data: any) => {
                // Check if member_data exists and is not null or undefined
                if (member_data && member_data.selection_status) {
                  // If a sub-question with status true and at least one member with selection_status true is found
                  found = true; // Set found to true
                  return; // Break out of the loop
                }
              }
            );
          }
        }
      );
    }
  });

  return (
    <Box className="previewBox mb-6 mx-3">
      <Grid container columnSpacing={2}>
        <Grid xs={8}>
          {found ? (
            <p className="previewBoxTitle">Insured Member's Medical Details</p>
          ) : (
            <p className="previewBoxTitle">No Medical History Declared</p>
          )}
        </Grid>
        <Grid xs={4} textAlign="right">
          <Link
            onClick={() => {
              dispatch(HealthSlice.actions.CHANGE_PAGE_STATUS(true));
              navigate(
                `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
                  HEALTH_ROUTE.MEDICAL_DETAILS
                }?type=HP&companyCode=${
                  SELECTED_QUOTE_DATA?.CompanyDetails.company_code
                }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
                  SELECTED_QUOTE_DATA?.productDetails.id
                }&user_type=${GET_QUERY_PARAMETERS().userType}&utm_source=${
                  GET_QUERY_PARAMETERS().utm_source
                }&utm_medium=${GET_QUERY_PARAMETERS().utm_medium}`
              );
            }}
            className="previewBoxEdit"
          >
            <EditIcon /> Edit
          </Link>
        </Grid>
      </Grid>
      {SELECTED_QUOTE_DATA.CompanyDetails.company_code === ECompanyCode.ICIC ? (
        <Grid xs={12}>
          {Object.values(medicalQuestionData).map((main_data: any) =>
            main_data ? (
              <div key={main_data.main_question.id}>
                <ul className="member_list" style={{ paddingLeft: "12px" }}>
                  {main_data.main_question.sub_question &&
                    Object.values(main_data.main_question.sub_question).map(
                      (sub_question_data: any) =>
                        Object.values(sub_question_data.member_data).some(
                          (member_data: any) => member_data.selection_status
                        ) && (
                          <React.Fragment key={sub_question_data.title}>
                            <h6
                              style={{
                                width: "100%",
                                color: "#DF9F39",
                                fontSize: "14px",
                                fontWeight: 600,
                              }}
                            >
                              {sub_question_data.title}
                            </h6>
                            <h6
                              style={{
                                width: "100%",
                                color: "rgba(0, 0, 0, 0.6)",
                                fontSize: "14px",
                                fontWeight: 600,
                              }}
                            >{`${sub_question_data.description}`}</h6>
                            {Object.values(sub_question_data.member_data).map(
                              (member_data: any) =>
                                member_data.selection_status && (
                                  <li key={member_data.id}>
                                    <div
                                      className={`${member_data.image_class} `}
                                    ></div>
                                    <p className="member_name">
                                      {member_data.name}
                                    </p>
                                  </li>
                                )
                            )}
                          </React.Fragment>
                        )
                    )}
                </ul>
              </div>
            ) : null
          )}
        </Grid>
      ) : (
        <Grid container columnSpacing={2}>
          <Grid xs={12}></Grid>
        </Grid>
      )}
    </Box>
  );
}

export default MedicalDetailPreviewBoxIcic;
