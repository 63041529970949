import Masonry from "@mui/lab/Masonry";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import Footer from "../../../../../Component/Footer/Footer";
import Loader from "../../../../../Component/LoaderblackBg/Loader";
import "../../../../../SCSS/CommonSCSS/ProposalForms.scss";
import { ECompanyCode, EProductId } from "../../../../../Services/Enum/EHome";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { COLORS } from "../../../../../SupportingFiles/colors";
import MedicalDetailPreviewBox from "../MedicalDetailPreviewBox/MedicalDetailPreviewBox";
import { TRAVEL_ROUTE } from "../../../../../Router/Path/TravelRoutes";
import TravelpreviewDetails from "../../../../../Component/PreviewDetailBox/TravelpreviewDetails/TravelpreviewDetails";

function PreviewDetails({
  PreviewDetails,
  navigate,
  CREATE_PROPOSAL,
  loader,
  paymentForm,
  paymentFormData,
}: {
  PreviewDetails: any;
  navigate: Function;
  CREATE_PROPOSAL: Function;
  loader: boolean;
  paymentForm: any;
  paymentFormData: any;
}) {
  const {
    SELECTED_QUOTE_DATA,
    ADDRESS_DETAILS,
    QUOTE_LOADER,
    ADD_FORM_RESPONSE,
  } = useAppSelector((state) => state.Travel);
  const dispatch = useAppDispatch();

  return (
    <>
      {QUOTE_LOADER ? (
        <Box
          sx={{
            backgroundColor: "rgba(20,20,20, 0.9)",
            height: "100%",
            width: "100%",
            position: "fixed",
            left: 0,
            top: "0px",
            zIndex: "991",
            flexDirection: "column",
            gap: "16px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <Loader size="small" /> */}
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader size="large" />
            <h5
              style={{
                color: COLORS.white,
                textAlign: "center",
                marginTop: "4px",
              }}
            >
              Please wait...
            </h5>
          </Box>
        </Box>
      ) : (
        <Box className="proposalPreview">
          <h5 className="sectionTitle">
            Just make sure, all details are correct
          </h5>
          <Masonry columns={2} spacing={3} className="px-0 mx-0">
            {PreviewDetails?.map((data: any, index: any) =>
              data?.detailView?.length > 0 ? (
                <TravelpreviewDetails
                  key={index}
                  isMailingAddresssame={ADDRESS_DETAILS.isMailingAddressSame}
                  title={data?.title}
                  mailing_detail_view={data.mailing_detail_view}
                  preViewData={data?.detailView}
                  url={data.url === undefined ? "" : data.url}
                />
              ) : null
            )}
          </Masonry>

          {SELECTED_QUOTE_DATA.companyCode === ECompanyCode.CARE ? (
            <MedicalDetailPreviewBox />
          ) : null}

          {/* "" */}

          <Footer
            loader={loader}
            attrName={"pageStatus"}
            textName={"Make Payment"}
            value={1}
            forward={CREATE_PROPOSAL}
            disableButton={
              Number(
                SELECTED_QUOTE_DATA?.quote_details?.premiumDetails.premium
              ) === 0
                ? true
                : false
            }
          />
        </Box>
      )}
    </>
  );
}

export default PreviewDetails;
