import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useAppSelector } from "../../redux/hooks";
import {
  formatAmountToCurrency,
  formatToCurrency,
} from "../../SupportingFiles/HelpingFunction";

const TravelLeftSidebar = () => {
  const { ADD_FORM, SELECTED_QUOTE_DATA, QUOTE_LOADER } = useAppSelector(
    (state) => state.Travel
  );

  return (
    <Grid xs className="proposalLeftSidebar">
      <Box className={"healthIcon"}></Box>
      <h6 className="mb-2">
        <span className="p-1">{ADD_FORM.members.value} Travellers</span>
      </h6>
      <img alt="" className="insurerLogo" src={SELECTED_QUOTE_DATA?.logo} />
      <h6 className="mb-6">
        {SELECTED_QUOTE_DATA.quote_details?.productDetails?.product_name}
      </h6>

      <h5>{formatToCurrency(Number(SELECTED_QUOTE_DATA?.sumInsured))}</h5>
      <p className="mb-6">Sum Insured</p>
      <Box>
        <h5>
          {ADD_FORM.travelCountries.value
            .map((country: any) => country.name)
            .join(", ")}
        </h5>
        <p className="mb-6">Country</p>
      </Box>
      <Box>
        <h5>{ADD_FORM?.travelStartDate?.value}</h5>
        <p className="mb-6">Start Date</p>
      </Box>
      <Box>
        <h5>{ADD_FORM?.travelEndDate?.value}</h5>
        <p className="mb-6">End Date</p>
      </Box>
      <h5 className="premium">
        {QUOTE_LOADER ? (
          <img src="../images/loader.svg" alt="" height="12px" />
        ) : (
          <>
            {formatAmountToCurrency(
              Number(SELECTED_QUOTE_DATA.quote_details?.premiumDetails?.premium)
            )}
          </>
        )}
      </h5>
      <p className="mb-6">Premium (Incl. GST)</p>
    </Grid>
  );
};

export default TravelLeftSidebar;
