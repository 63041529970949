import { Alert, AlertTitle, Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import RKTextField from "../../../../../../Component/FieldTypes/RKTextField/RKTextField";
import SearchDropdown from "../../../../../../Component/FieldTypes/SearchDropdown/SearchDropdown";
import Footer from "../../../../../../Component/HI/Desktop/ProposalComponents/Footer/Footer";
import "../../../../../../Component/HI/Desktop/ProposalComponents/AddonQuestion/AddonQuestion.scss";
import "../../../../../../Component/HI/Desktop/SwitchButton/SwitchButton.scss";
import { Addon_Coverages_DTO } from "../../../../../../DTO/HIDTO/ProposalDTO";
import { HI_PATH } from "../../../../../../Router/Path/HIPath";
import { PROPOSAL_SERVICES } from "../../../../../../Services/HI/ProposalService";
import { QUOTES_SERVICES } from "../../../../../../Services/HI/QuotesService";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { pageSlice } from "../../../../../../redux/slice/HI/PageSlice/PageSlice";
import { proposalSlice } from "../../../../../../redux/slice/HI/Proposal/ProposalSlice";
import {
  calculatePercentageOfNum,
  extractNumbersFromString,
  formatIndianCurrency,
  hasEmptyProperty,
} from "../../../../../../SupportingFiles/HelpingFunction";
import { SI_TYPE } from "../../../../../../types/HI/Enums/SI_Type";

function InbuiltCoverage() {
  const navigate = useNavigate();
  const { PAGE_STATUS } = useAppSelector((state) => state.pageSlice);
  const [waitForApi, setWaitForApi] = useState<boolean>(false);
  const { proposalInformation, quote } = useAppSelector((state) => state);
  const inbuiltCoverageState = useAppSelector(
    (state) => state.proposalInformation.inbuiltCoverages
  );

  const householdDetailState = useAppSelector(
    (state) => state.householdDetails
  );

  const dispatch = useAppDispatch();

  const [addonStatus, setAddonStatus] = useState([false, false]);

  const [lossOfRentCoverage, setLossOfRentCoverage] = useState(false);
  const [rentForAlternateAccomodation, setRentForAlternateAccomodation] =
    useState(false);

  const [pacDetails, setPacDetails] = useState<{
    noOfMonthsLor: { value: string; warning: boolean };
    sumInsuredLor: { value: string; warning: boolean };
    noOfMonthsrRfaa: { value: string; warning: boolean };
    sumInsuredRfaa: { value: string; warning: boolean };
  }>({
    noOfMonthsLor: { value: "", warning: false },
    sumInsuredLor: { value: "", warning: false },
    noOfMonthsrRfaa: { value: "", warning: false },
    sumInsuredRfaa: { value: "", warning: false },
  });

  const [noOfMonthsData, setNoOfMonthsData] = React.useState([{}]);

  useEffect(() => {
    let data = [];
    for (var i = 1; i <= 12; i++) {
      data.push({ label: i, value: i + " Months" });
    }
    setNoOfMonthsData(data);
  }, []);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName[0] === "pacDetails") {
      setPacDetails({
        ...pacDetails,
        [attrName[1]]: {
          value: value,
          warning: `${value}`.toString().length === 0 ? true : false,
        },
      });
    } else {
      setAddonStatus(value);
    }
  };

  const updateDigitQuotePremium = () => {
    const onSuccess = (res: any) => {
      dispatch(
        proposalSlice.actions.setPolicyInfo({
          ...proposalInformation.policy_information,
          premiumDetails: res.data.quoteLists,
        })
      );
    };

    const onError = (err: any) => {
      console.log(err);
    };

    QUOTES_SERVICES.update_digit_quote_premium(
      onSuccess,
      onError,
      quote.quote_id
    );
  };

  const saveProposalInfo = (data: any) => {
    setWaitForApi(true);
    const loadingToast = toast.loading("Updating Proposal...");

    const onSuccess = (res: any) => {
      toast.dismiss(loadingToast);

      if (PAGE_STATUS) {
        dispatch(pageSlice.actions.UPDATE_PAGE_STATUS(false));
        navigate(-1);
      } else {
        navigate(`${HI_PATH.Proposal}/${HI_PATH.ADD_ONS}`);
      }

      setWaitForApi(false);
      updateDigitQuotePremium();
    };

    const onError = (err: any) => {
      toast.dismiss(loadingToast);

      setWaitForApi(false);
    };

    PROPOSAL_SERVICES.save_proposal_info(
      onSuccess,
      onError,
      { in_built_coverages: data },
      quote.quote_id
    );
  };

  const validate_form = () => {
    let warning = false;
    const obj = proposalInformation.inbuiltCoverages;

    let dto = {};

    // Check if rentAltSelected is true
    if (obj.rentAltSelected) {
      const rentObj = {
        month: extractNumbersFromString(obj.num_months_alt),
        rent: extractNumbersFromString(obj.rent_amount_alt),
      };

      warning = hasEmptyProperty(rentObj);

      if (rentObj.rent == "0") {
        warning = true;
      }

      // If any property of rentObj is empty, show error
      if (warning) {
        toast.error("Please enter all values");
        return;
      }

      dto = {
        ...dto,
        rentForAltAcc: rentObj,
      };
    }

    // Check if lossOfRentSelcted is true
    if (obj.lossOfRentSelcted) {
      const rentObj = {
        month: extractNumbersFromString(obj.num_months_rent),
        rent: extractNumbersFromString(obj.rent_amount),
      };

      warning = hasEmptyProperty(rentObj);

      if (rentObj.rent == "0") {
        warning = true;
      }

      // If any property of rentObj is empty, show error
      if (warning) {
        toast.error("Please enter all values");
        return;
      }

      dto = {
        ...dto,
        lossOfRent: rentObj,
      };
    }

    let addonsDto: Addon_Coverages_DTO = {
      isEQzone: false,
      coverForValuableContent: obj.coverForValuableContent,
      coverForValuableContentStatus:
        inbuiltCoverageState.isCoverForValuableContent,
      isEQcover: proposalInformation.addonCoverages.isEQcover,
    };

    // If isCoverForValuableContent is false, set coverForValuableContent to 0
    if (!obj.isCoverForValuableContent) {
      addonsDto = {
        ...addonsDto,
        coverForValuableContent: 0,
      };
    }

    if (proposalInformation.addonCoverages.isPac) {
      addonsDto = {
        ...addonsDto,
        pacDetails: {
          insuredAge: Number(
            extractNumbersFromString(
              proposalInformation.addonCoverages.pacDetails.insuredAge.value
            )
          ),
          insuredGender:
            proposalInformation.addonCoverages.pacDetails.insuredGender.value,
          insuredName:
            proposalInformation.addonCoverages.pacDetails.insuredName.value,
          nomineeAge: Number(
            extractNumbersFromString(
              proposalInformation.addonCoverages.pacDetails.nomineeAge.value
            )
          ),
          nomineeGender:
            proposalInformation.addonCoverages.pacDetails.nomineeGender.value,
          nomineeName:
            proposalInformation.addonCoverages.pacDetails.nomineeName.value,
          nomineeRelation:
            proposalInformation.addonCoverages.pacDetails.nomineeRelation.value,
        },
      };
    }

    if (proposalInformation.addonCoverages.isPacSpouse) {
      addonsDto = {
        ...addonsDto,
        pacSpouseDetails: {
          age: Number(
            extractNumbersFromString(
              proposalInformation.addonCoverages.pacSpouseDetails.age.value
            )
          ),
          name: proposalInformation.addonCoverages.pacSpouseDetails.name.value,
          nomineeAge: Number(
            extractNumbersFromString(
              proposalInformation.addonCoverages.pacSpouseDetails.nomineeAge
                .value
            )
          ),
          nomineeAppointeeName:
            proposalInformation.addonCoverages.pacSpouseDetails
              .nomineeAppointeeName.value,
          nomineeGender:
            proposalInformation.addonCoverages.pacSpouseDetails.nomineeGender
              .value,
          nomineeName:
            proposalInformation.addonCoverages.pacSpouseDetails.nomineeName
              .value,
          nomineeRelationWithInsured:
            proposalInformation.addonCoverages.pacSpouseDetails
              .nomineeRelationWithInsured.value,
        },
      };
    }

    if (!warning) {
      saveProposalInfo({ ...dto, ...addonsDto });
    }
    return;
  };

  return (
    <Box>
      <h5 className="sectionTitle">In-built Coverages</h5>
      <Grid container spacing={3}>
        <Grid xs={12} className="addonQuestion borderBottom">
          <>
            <Grid container spacing={2}>
              <Grid xs={9} className="question-part" alignSelf={"center"}>
                <h6>Loss Of Rent</h6>
                <p>
                  Coverage provided for loss of rent when the home building is
                  rendered unfit for living because of an insured event
                  resulting in physical loss.
                </p>
              </Grid>
              <Grid xs={3} textAlign="right">
                <p className="amount">-</p>
                <div className="toggles">
                  <input
                    id="lor"
                    type="checkbox"
                    checked={inbuiltCoverageState.lossOfRentSelcted}
                    onClick={() =>
                      dispatch(
                        proposalSlice.actions.setInbuiltCoverage({
                          ...inbuiltCoverageState,
                          lossOfRentSelcted:
                            !inbuiltCoverageState.lossOfRentSelcted,
                        })
                      )
                    }
                  />
                  <label htmlFor={"lor"} />
                </div>
              </Grid>
            </Grid>
            {inbuiltCoverageState.lossOfRentSelcted ? (
              <Grid container style={{ width: "100%" }} spacing={3}>
                <Grid xs={4}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Number Of Months"
                    value={inbuiltCoverageState.num_months_rent}
                    attrName={[]}
                    value_update={(attrName: any, value: string) =>
                      dispatch(
                        proposalSlice.actions.setInbuiltCoverage({
                          ...inbuiltCoverageState,
                          num_months_rent: value,
                        })
                      )
                    }
                    data={noOfMonthsData}
                    warn_status={pacDetails.noOfMonthsLor.warning}
                    error_message={""}
                  />
                </Grid>
                <Grid xs={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Rent/Month"}
                    validation_type="NUMBER"
                    value={inbuiltCoverageState.rent_amount}
                    attrName={[]}
                    value_update={(attrName: any, value: string) => {
                      if (
                        Number(extractNumbersFromString(value)) >
                        0.1 *
                          Number(
                            extractNumbersFromString(
                              householdDetailState.buildingValue.value
                            )
                          )
                      ) {
                        return;
                      }

                      dispatch(
                        proposalSlice.actions.setInbuiltCoverage({
                          ...inbuiltCoverageState,
                          rent_amount: value,
                        })
                      );
                    }}
                    // InputProps={{
                    //   startAdornment: <>₹</>,
                    // }}
                    warn_status={pacDetails.sumInsuredLor.warning}
                  />
                </Grid>
              </Grid>
            ) : null}
          </>
        </Grid>
        <Grid xs={12} className="addonQuestion borderBottom">
          <>
            <Grid container spacing={2}>
              <Grid xs={9} className="question-part" alignSelf={"center"}>
                <h6>Rent for Alternate Accomodation</h6>
                <p>
                  Coverage provided as rent for alternate accommodation in the
                  event of damage/destruction to the home of the insured.
                </p>
              </Grid>
              <Grid xs={3} textAlign="right">
                <p className="amount">-</p>
                <div className="toggles">
                  <input
                    id="pac"
                    type="checkbox"
                    checked={inbuiltCoverageState.rentAltSelected}
                    onClick={() =>
                      dispatch(
                        proposalSlice.actions.setInbuiltCoverage({
                          ...inbuiltCoverageState,
                          rentAltSelected:
                            !inbuiltCoverageState.rentAltSelected,
                        })
                      )
                    }
                  />
                  <label htmlFor={"pac"} />
                </div>
              </Grid>
            </Grid>
            {inbuiltCoverageState.rentAltSelected ? (
              <Grid container spacing={3}>
                <Grid xs={4}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Number Of Months"
                    value={inbuiltCoverageState.num_months_alt}
                    attrName={[]}
                    value_update={(attrName: any, value: string) =>
                      dispatch(
                        proposalSlice.actions.setInbuiltCoverage({
                          ...inbuiltCoverageState,
                          num_months_alt: value,
                        })
                      )
                    }
                    data={noOfMonthsData}
                    warn_status={pacDetails.noOfMonthsrRfaa.warning}
                    error_message={""}
                  />
                </Grid>
                <Grid xs={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Rent/Month"}
                    validation_type="NUMBER"
                    value={inbuiltCoverageState.rent_amount_alt}
                    attrName={[]}
                    value_update={(attrName: any, value: string) => {
                      if (
                        Number(extractNumbersFromString(value)) >
                        Number(
                          extractNumbersFromString(
                            householdDetailState.buildingValue.value
                          )
                        ) *
                          0.1
                      ) {
                        return;
                      }

                      dispatch(
                        proposalSlice.actions.setInbuiltCoverage({
                          ...inbuiltCoverageState,
                          rent_amount_alt: value,
                        })
                      );
                    }}
                    // InputProps={{
                    //   startAdornment: <>₹</>,
                    // }}
                    warn_status={pacDetails.sumInsuredRfaa.warning}
                  />
                </Grid>
              </Grid>
            ) : null}
          </>
        </Grid>

        {(householdDetailState.sumInsuredFor ===
          SI_TYPE.BUILDING_HOUSEHOLD_ITEMS ||
          householdDetailState.sumInsuredFor === SI_TYPE.HOUSEHOLD_ITEMS) && (
          <Grid xs={12} className="addonQuestion borderBottom">
            <>
              <Grid container spacing={2}>
                <Grid xs={9} className="question-part" alignSelf={"center"}>
                  <h6>Cover for valuable contents</h6>
                  <p>
                    Sum insured as agreed between insured and insurer based on
                    valuation certificate*
                  </p>
                </Grid>
                <Grid xs={3} textAlign="right">
                  <p className="amount">-</p>
                  <div className="toggles">
                    <input
                      id="vic"
                      type="checkbox"
                      checked={inbuiltCoverageState.isCoverForValuableContent}
                      onClick={() =>
                        dispatch(
                          proposalSlice.actions.setInbuiltCoverage({
                            ...inbuiltCoverageState,
                            isCoverForValuableContent:
                              !inbuiltCoverageState.isCoverForValuableContent,
                          })
                        )
                      }
                    />
                    <label htmlFor={"vic"} />
                  </div>
                </Grid>
              </Grid>
            </>

            {inbuiltCoverageState.isCoverForValuableContent ? (
              <>
                <Grid xs={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Amount"}
                    validation_type="NUMBER"
                    value={formatIndianCurrency(
                      inbuiltCoverageState.coverForValuableContent
                    )}
                    attrName={[]}
                    value_update={(attrName: any, value: string) => {
                      const valToUse = Number(extractNumbersFromString(value));

                      if (valToUse > 500000) {
                        return;
                      }

                      if (
                        valToUse >
                        calculatePercentageOfNum(
                          20,
                          Number(
                            extractNumbersFromString(
                              householdDetailState.householdItems.value
                            )
                          )
                        )
                      ) {
                        return;
                      }
                      dispatch(
                        proposalSlice.actions.setInbuiltCoverage({
                          ...inbuiltCoverageState,
                          coverForValuableContent: valToUse,
                        })
                      );
                    }}
                    // InputProps={{
                    //   startAdornment: <>₹</>,
                    // }}
                    warn_status={pacDetails.sumInsuredRfaa.warning}
                  />
                </Grid>

                <Alert style={{ margin: 14, marginTop: 20 }} severity="info">
                  <AlertTitle>Note</AlertTitle>
                  Maximum value of the SI is restricted to 20% of home content
                  SI subject to max a of Rs 5,00,000/-
                </Alert>
              </>
            ) : null}
          </Grid>
        )}
      </Grid>
      <Footer continue_function={validate_form} />
    </Box>
  );
}

export default InbuiltCoverage;
