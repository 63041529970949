import { Box, Checkbox, Collapse, FormControlLabel } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  TFormdataTermMedicalQuestionDetails,
  TTermHDFCMedicalQuestionDetails,
} from "../../../../../../types/TermFullJourney/TTermMedicalQuestionDetails";
import { useAppSelector } from "../../../../../../redux/hooks";
import SelectDropdown from "../../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import RKTextField from "../../../../../../Component/FieldTypes/RKTextField/RKTextField";
import MedicalQuestion from "../../../../../../Component/Mobile/TermFullJourney/MedicalQuestion/MedicalQuestion";
import DatePicker from "../../../../../../Component/FieldTypes/DatePicker/DatePicker";
import Footer from "../../../../../../Component/Mobile/TermFullJourney/ProposalForm/Common/Footer/Footer";

function MedicalPage({
  medicalQuestion,
  updateMasterState,
  continue_function,
  loader,
  formData,
}: {
  medicalQuestion: TTermHDFCMedicalQuestionDetails;
  updateMasterState: Function;
  continue_function: Function;
  loader: boolean;
  formData: TFormdataTermMedicalQuestionDetails;
}) {
  const { dropdownData, proposerDetails } = useAppSelector(
    (state) => state.TermFullJourney
  );

  return (
    <Box>
      <h5 className="sectionTitle">Underwriting Details</h5>
      <>
        <Grid container spacing={3}>
          <Grid xs={5} className="weightField">
            <span className="weightIcon" />
            <SelectDropdown
              sx={{ width: "100%" }}
              class_name="inputField mr-2"
              title={"Weight (Kg)"}
              value={medicalQuestion.weight_kg.value}
              attrName={["weight_kg"]}
              value_update={updateMasterState}
              data={dropdownData.HDFC_WEIGHT}
              warn_status={medicalQuestion.weight_kg.warning}
            />
          </Grid>
          <Grid xs={5} className="heightField">
            <span className="heightIcon"></span>
            {dropdownData.HDFC_HEIGHT.length > 0 && (
              <SelectDropdown
                class_name="inputField mr-2"
                title="Height"
                value={medicalQuestion.height_ft.value}
                attrName={["height_ft"]}
                value_update={updateMasterState}
                data={dropdownData.HDFC_HEIGHT}
                warn_status={medicalQuestion.height_ft.warning}
              />
            )}
          </Grid>
          <Grid xs={4}>
            <RKTextField
              class_name="inputField"
              title={"Identification Mark"}
              value={formData.identification_mark.value}
              attrName={["formData", "identification_mark"]}
              value_update={updateMasterState}
              warn_status={formData.identification_mark.warning}
            />
          </Grid>
          <Grid xs={8}></Grid>
          <Grid xs={12}>
            <span className="question">Occupation</span>
          </Grid>
          <Grid xs={4}>
            <SelectDropdown
              class_name="inputField"
              title="Designation"
              value={formData.designation.value}
              attrName={["formData", "designation"]}
              value_update={updateMasterState}
              data={dropdownData.HDFC_MEDICAL_DESIGNATION}
              warn_status={formData.designation.warning}
            />
          </Grid>
          <Grid xs={4}>
            <RKTextField
              class_name="inputField"
              title={"Employer/business name"}
              value={formData.business_name.value}
              attrName={["formData", "business_name"]}
              value_update={updateMasterState}
              warn_status={formData.business_name.warning}
            />
          </Grid>
        </Grid>
        <MedicalQuestion
          main_class="medicalQuestion borderBottom"
          icon_class={"tobaco"}
          question_name={"Does your occupation involve any of the following"}
          toggle_button_status={formData.occupation_involve_status}
          attrName={["formData", "occupation_involve_status"]}
          value_update={updateMasterState}
        />

        <Grid container rowSpacing={0}>
          <Grid xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={true}
                  value={formData.occupation_involve_value}
                  onClick={() =>
                    updateMasterState(["formData", "occupation_involve_status"])
                  }
                />
              }
              label="None of these"
            />
          </Grid>
          <Grid xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  value={formData.occupation_involve_value}
                  onClick={() =>
                    updateMasterState(["formData", "occupation_involve_status"])
                  }
                />
              }
              label="Exposure to chemical substances"
            />
          </Grid>
          <Grid xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  value={formData.occupation_involve_value}
                  onClick={() =>
                    updateMasterState(["formData", "occupation_involve_status"])
                  }
                />
              }
              label="Exposure to harmful dust or gases"
            />
          </Grid>
          <Grid xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  value={formData.occupation_involve_value}
                  onClick={() =>
                    updateMasterState(["formData", "occupation_involve_status"])
                  }
                />
              }
              label="Exposure to hazardous materials"
            />
          </Grid>
          <Grid xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  value={formData.occupation_involve_value}
                  onClick={() =>
                    updateMasterState(["formData", "occupation_involve_status"])
                  }
                />
              }
              label="Handling explosives"
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid xs={4}>
            <RKTextField
              class_name="inputField"
              title={"Gross annual income"}
              value={formData.gross_annual_income.value}
              attrName={["formData", "gross_annual_income"]}
              value_update={updateMasterState}
              warn_status={formData.gross_annual_income.warning}
              validation_type="NUMBER"
            />
          </Grid>
          <Grid xs={6} />
          <Grid xs={6}>
            <SelectDropdown
              class_name="inputField"
              title="Which of these best describe the nature of your work"
              value={formData.nature_of_work.value}
              attrName={["formData", "nature_of_work"]}
              value_update={updateMasterState}
              data={dropdownData.NATURE_OF_WORK}
              warn_status={formData.nature_of_work.warning}
            />
          </Grid>
          <Grid xs={6}>
            <SelectDropdown
              class_name="inputField"
              title="Industry to which your company belongs"
              value={formData.industry_type.value}
              attrName={["formData", "industry_type"]}
              value_update={updateMasterState}
              data={dropdownData.COMPANY_BELONGS}
              warn_status={formData.industry_type.warning}
            />
          </Grid>
          <Grid xs={3}>
            <RKTextField
              class_name="inputField"
              title={"Workplace city"}
              value={formData.workplace_city.value}
              attrName={["formData", "workplace_city"]}
              value_update={updateMasterState}
              warn_status={formData.workplace_city.warning}
            />
          </Grid>
          <Grid xs={5}>
            <RKTextField
              class_name="inputField"
              title={"Workplace address"}
              value={formData.workplace_address.value}
              attrName={["formData", "workplace_address"]}
              value_update={updateMasterState}
              warn_status={formData.workplace_address.warning}
            />
          </Grid>
        </Grid>
        {medicalQuestion.question.map((data, index) => {
          const isMale = proposerDetails.gender.value === "M";
          const isHiddenQuestion =
            isMale &&
            (data.main_question.code === "PQ20" ||
              data.main_question.code === "PQ21" ||
              data.main_question.code === "PQ22" ||
              data.main_question.code === "PQ23");
          return isHiddenQuestion ? null : (
            <MedicalQuestion
              main_class="medicalQuestion borderBottom"
              icon_class={data.main_question.icon_class}
              question_name={data.main_question.title}
              toggle_button_status={data.main_question.status}
              attrName={["main_question", index, "toggle_status"]}
              value_update={updateMasterState}
              sub_details={data.main_question.description}
              any_question_or_sub_question={
                <Collapse
                  in={data.main_question.status}
                  className="medicalQuestion-SubSection"
                >
                  <Grid
                    container
                    columnSpacing={3}
                    rowSpacing={0}
                    className="pr-2 py-0"
                  >
                    {data.main_question.field_data &&
                      data.main_question.field_data.map(
                        (data_fields, index_fields) => (
                          <Grid
                            xs={
                              data_fields.column === undefined
                                ? 4
                                : data_fields.column
                            }
                          >
                            {data_fields.field_type === "textField" ? (
                              <RKTextField
                                class_name="inputField mb-5"
                                title={data_fields.title}
                                attrName={[
                                  "main_question",
                                  index,
                                  index_fields,
                                  "textfield",
                                ]}
                                value={data_fields.value}
                                warn_status={data_fields.warning}
                                value_update={updateMasterState}
                                validation_type={
                                  data_fields.numbers_only
                                    ? "NUMBER"
                                    : "ALPHANUMERIC_ALL"
                                }
                              />
                            ) : data_fields.field_type === "dropdown" ? (
                              <SelectDropdown
                                class_name="inputField mb-5"
                                title={data_fields.title}
                                value={data_fields.value}
                                attrName={[
                                  "main_question",
                                  index,
                                  index_fields,
                                  "textfield",
                                ]}
                                value_update={updateMasterState}
                                data={
                                  data_fields.code === "PQ02-1"
                                    ? dropdownData.SELECT_ANY_SIMULTANEOUS
                                    : data_fields.code === "PQ08-1"
                                    ? dropdownData.HDFC_COUNTRIES
                                    : data_fields.code === "PQ09-1"
                                    ? dropdownData.HOBBIES
                                    : data_fields.code === "PQ17-1"
                                    ? dropdownData.MAY_UNDERGO_MEDICAL_EXAMINATION
                                    : data_fields.code === "PQ20-1"
                                    ? dropdownData.PREGNANCY_WEEKS
                                    : []
                                }
                                warn_status={data_fields.warning}
                              />
                            ) : data_fields.field_type === "datePicker" ? (
                              <DatePicker
                                class_name="inputField mb-5"
                                title={data_fields.title}
                                value={data_fields.value}
                                attrName={[
                                  "main_question",
                                  index,
                                  index_fields,
                                  "textfield",
                                ]}
                                value_update={updateMasterState}
                                warn_status={data_fields.warning}
                                min_date={100}
                                max_date={0}
                                date_validation_type={"YEARS"}
                              />
                            ) : null}
                          </Grid>
                        )
                      )}
                    {data.sub_question &&
                      data.sub_question.map(
                        (data_sub_question, index_sub_question) => (
                          <>
                            <MedicalQuestion
                              main_class="medicalQuestion borderBottom"
                              icon_class={data_sub_question.icon_class}
                              question_name={data_sub_question.title}
                              toggle_button_status={data_sub_question.status}
                              sub_details={data_sub_question.description}
                              attrName={[
                                "sub_question",
                                index,
                                index_sub_question,
                                "toggle_status",
                              ]}
                              value_update={updateMasterState}
                              any_question_or_sub_question={
                                data_sub_question.status === true ? (
                                  <Collapse
                                    in={data_sub_question.status}
                                    className="medicalQuestion-SubSection"
                                  >
                                    <Grid
                                      container
                                      spacing={3}
                                      className="pr-2"
                                    >
                                      {data_sub_question.field_data &&
                                        data_sub_question.field_data.map(
                                          (
                                            data_sub_question_fields,
                                            index_sub_question_fields
                                          ) => (
                                            <Grid
                                              xs={
                                                data_sub_question_fields.column ===
                                                undefined
                                                  ? 4
                                                  : data_sub_question_fields.column
                                              }
                                            >
                                              {data_sub_question_fields.field_type ===
                                              "textField" ? (
                                                <RKTextField
                                                  class_name="inputField mb-5"
                                                  title={
                                                    data_sub_question_fields.title
                                                  }
                                                  attrName={[
                                                    "sub_question",
                                                    index,
                                                    index_sub_question,
                                                    index_sub_question_fields,
                                                    "textfield",
                                                  ]}
                                                  value={
                                                    data_sub_question_fields.value
                                                  }
                                                  warn_status={
                                                    data_sub_question_fields.warning
                                                  }
                                                  value_update={
                                                    updateMasterState
                                                  }
                                                  validation_type={
                                                    data_sub_question_fields.numbers_only
                                                      ? "NUMBER"
                                                      : "ALPHANUMERIC_ALL"
                                                  }
                                                />
                                              ) : data_sub_question_fields.field_type ===
                                                "dropdown" ? (
                                                <SelectDropdown
                                                  class_name="inputField mb-5"
                                                  title={
                                                    data_sub_question_fields.title
                                                  }
                                                  value={
                                                    data_sub_question_fields.value
                                                  }
                                                  attrName={[
                                                    "sub_question",
                                                    index,
                                                    index_sub_question,
                                                    index_sub_question_fields,
                                                    "dropdown",
                                                  ]}
                                                  value_update={
                                                    updateMasterState
                                                  }
                                                  data={
                                                    data_sub_question_fields.code ===
                                                    "PQ18-SQ01-1"
                                                      ? dropdownData.CONSUME_ALCOHOL
                                                      : data_sub_question_fields.code ===
                                                        "PQ18-SQ02-1"
                                                      ? dropdownData.TOBACCO_PRODUCT
                                                      : data_sub_question_fields.code ===
                                                        "PQ08-SQ01-1"
                                                      ? dropdownData.HDFC_COUNTRIES
                                                      : data_sub_question_fields.code ===
                                                        "PQ20-SQ01-1"
                                                      ? dropdownData.have_you_suffered_from_one
                                                      : data_sub_question_fields.code ===
                                                        "PQ09-SQ02-1"
                                                      ? dropdownData.have_you_suffered_from_two
                                                      : data_sub_question_fields.code ===
                                                        "PQ09-SQ03-1"
                                                      ? dropdownData.have_you_suffered_from_three
                                                      : data_sub_question_fields.code ===
                                                        "PQ09-SQ04-1"
                                                      ? dropdownData.have_you_suffered_from_four
                                                      : data_sub_question_fields.code ===
                                                        "PQ09-SQ05-1"
                                                      ? dropdownData.have_you_suffered_from_five
                                                      : data_sub_question_fields.code ===
                                                        "PQ08-SQ02-1"
                                                      ? dropdownData.HDFC_COUNTRIES
                                                      : data_sub_question_fields.code ===
                                                        "PQ19-SQ01-1"
                                                      ? dropdownData.RELATION_OF_LIFE_TO_BE_ASSURED
                                                      : data_sub_question_fields.code ===
                                                        "PQ19-SQ03-1"
                                                      ? dropdownData.AGE_AT_DIAGNOSIS
                                                      : data_sub_question_fields.code ===
                                                        "PQ19-SQ04-1"
                                                      ? dropdownData.HDFC_ALIVE
                                                      : data_sub_question_fields.code ===
                                                        "PQ19-SQ05-1"
                                                      ? dropdownData.HDFC_CURRENT_AGE
                                                      : data_sub_question_fields.code ===
                                                        "PQ19-SQ02-1"
                                                      ? dropdownData.HDFC_DISEASE
                                                      : []
                                                  }
                                                  warn_status={
                                                    data_sub_question_fields.warning
                                                  }
                                                />
                                              ) : data_sub_question_fields.field_type ===
                                                "datePicker" ? (
                                                <DatePicker
                                                  class_name="inputField mb-5"
                                                  title={
                                                    data_sub_question_fields.title
                                                  }
                                                  value={
                                                    data_sub_question_fields.value
                                                  }
                                                  attrName={[
                                                    "sub_question",
                                                    index,
                                                    index_sub_question,
                                                    index_sub_question_fields,
                                                    "datePicker",
                                                  ]}
                                                  value_update={
                                                    updateMasterState
                                                  }
                                                  warn_status={
                                                    data_sub_question_fields.warning
                                                  }
                                                  min_date={100}
                                                  max_date={0}
                                                  date_validation_type={"YEARS"}
                                                />
                                              ) : null}
                                            </Grid>
                                          )
                                        )}
                                    </Grid>
                                  </Collapse>
                                ) : null
                              }
                            />
                          </>
                        )
                      )}
                  </Grid>
                </Collapse>
              }
            />
          );
        })}
      </>
      <Footer
        loader={loader}
        continueFunction={() => {
          continue_function();
        }}
      />
    </Box>
  );
}

export default MedicalPage;
