import { TNivaBupaMedicalQuestion } from "../../../../types/Health/HealthQuotation/TNivaBupaMedicalQuestion";

export const NIVA_BUPA_INITIAL_VALUE = (): TNivaBupaMedicalQuestion => {
  return {
    MQ01: {
      main_question: {
        id: "MQ01",
        key: "preExistingDisease",
        icon_class: "tobaco",
        title: "",
        description:
          "Other than common cold, flu, infections, minor injury or other minor ailments; has the Applicant ever been diagnosed with any disease and / or hospitalized for more than 5 days and / or undergone / advised to undergo any surgical procedures and / or taken any medication/ had any symptoms for more than 14 days? Medication is including but not limited to inhalers, injections, oral drugs and external medical applications on body parts?",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "adult",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "adult",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
        },
      },
    },
    MQ02: {
      main_question: {
        id: "MQ02",
        key: "medicalDisease",
        icon_class: "tobaco",
        title: "",
        description:
          "Has the Applicant ever had adverse findings to any diagnostic tests or investigations related to Thyroid Profile, Lipid Profile, Treadmill test, Angiography, Echocardiography, Endoscopy, Ultrasound, CT Scan, MRI, Biopsy and FNAC?",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "adult",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "adult",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
        },
      },
    },
    MQ03: {
      main_question: {
        id: "MQ03",
        key: "diabetes",
        icon_class: "tobaco",
        title: "",
        description:
          "Does the Applicant have diabetes or pre-diabetes or has he/she EVER had high blood sugar?",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "adult",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F8": {
                id: "MQ1-SQ1-F8",
                key: "hba1c",
                title: "HbA1c",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "adult",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F8": {
                id: "MQ1-SQ1-F8",
                key: "hba1c",
                title: "HbA1c",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F8": {
                id: "MQ1-SQ1-F8",
                key: "hba1c",
                title: "HbA1c",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F8": {
                id: "MQ1-SQ1-F8",
                key: "hba1c",
                title: "HbA1c",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F8": {
                id: "MQ1-SQ1-F8",
                key: "hba1c",
                title: "HbA1c",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F8": {
                id: "MQ1-SQ1-F8",
                key: "hba1c",
                title: "HbA1c",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
        },
      },
    },
    MQ04: {
      main_question: {
        id: "MQ04",
        key: "hypertension",
        icon_class: "tobaco",
        title: "",
        description:
          "Does the Applicant have Hypertension or High Blood Pressure?",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "adult",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              // "MQ1-SQ1-F8": {
              //   id: "MQ1-SQ1-F8",
              //   title: "HbA1c",
              //   field_type: "TEXTFIELD",
              //   value: { value: "", warning: false },
              // },
              "MQ1-SQ1-F9": {
                id: "MQ1-SQ1-F9",
                key: "systolic",
                title: "Systolic",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F10": {
                id: "MQ1-SQ1-F10",
                key: "diastolic",
                title: "Diastolic",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "adult",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              // "MQ1-SQ1-F8": {
              //   id: "MQ1-SQ1-F8",
              //   title: "HbA1c",
              //   field_type: "TEXTFIELD",
              //   value: { value: "", warning: false },
              // },
              "MQ1-SQ1-F9": {
                id: "MQ1-SQ1-F9",
                key: "systolic",
                title: "Systolic",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F10": {
                id: "MQ1-SQ1-F10",
                key: "diastolic",
                title: "Diastolic",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              // "MQ1-SQ1-F8": {
              //   id: "MQ1-SQ1-F8",
              //   title: "HbA1c",
              //   field_type: "TEXTFIELD",
              //   value: { value: "", warning: false },
              // },
              "MQ1-SQ1-F9": {
                id: "MQ1-SQ1-F9",
                key: "systolic",
                title: "Systolic",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F10": {
                id: "MQ1-SQ1-F10",
                key: "diastolic",
                title: "Diastolic",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              // "MQ1-SQ1-F8": {
              //   id: "MQ1-SQ1-F8",
              //   title: "HbA1c",
              //   field_type: "TEXTFIELD",
              //   value: { value: "", warning: false },
              // },
              "MQ1-SQ1-F9": {
                id: "MQ1-SQ1-F9",
                key: "systolic",
                title: "Systolic",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F10": {
                id: "MQ1-SQ1-F10",
                key: "diastolic",
                title: "Diastolic",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              // "MQ1-SQ1-F8": {
              //   id: "MQ1-SQ1-F8",
              //   title: "HbA1c",
              //   field_type: "TEXTFIELD",
              //   value: { value: "", warning: false },
              // },
              "MQ1-SQ1-F9": {
                id: "MQ1-SQ1-F9",
                key: "systolic",
                title: "Systolic",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F10": {
                id: "MQ1-SQ1-F10",
                key: "diastolic",
                title: "Diastolic",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              // "MQ1-SQ1-F8": {
              //   id: "MQ1-SQ1-F8",
              //   title: "HbA1c",
              //   field_type: "TEXTFIELD",
              //   value: { value: "", warning: false },
              // },
              "MQ1-SQ1-F9": {
                id: "MQ1-SQ1-F9",
                key: "systolic",
                title: "Systolic",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F10": {
                id: "MQ1-SQ1-F10",
                key: "diastolic",
                title: "Diastolic",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
        },
      },
    },
    MQ05: {
      main_question: {
        id: "MQ05",
        key: "genetic",
        icon_class: "tobaco",
        title: "",
        description:
          "Has the Applicant ever been diagnosed or treated for any genetic / hereditary disorders / HIV?",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "adult",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "adult",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
        },
      },
    },
    MQ06: {
      main_question: {
        id: "MQ06",
        key: "psychiatric",
        icon_class: "tobaco",
        title: "",
        description:
          "Has the Applicant ever been diagnosed or treated for any mental/ psychiatric disorders?",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "adult",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "adult",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                key: "symptoms",
                title: "Details of Symptom(s)",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                key: "date",
                title: "Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                key: "duration",
                title: "Duration of Condition",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                key: "medication",
                title: "Medication",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                key: "dosage",
                title: "Dosage",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                key: "status",
                title: "Current Status",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                key: "details",
                title: "Doctor's details",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
        },
      },
    },
    MQ07: {
      main_question: {
        id: "MQ07",
        key: "specialCondition",
        icon_class: "tobaco",
        title: "",
        description:
          "Has any proposal for life, health, hospital daily cash or critical illness insurance on the life of the Applicant ever been declined, postponed, loaded or subjected to any special conditions such as exclusions by any insurance company?",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "adult",
            selection_status: false,
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "adult",
            selection_status: false,
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "child",
            selection_status: false,
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "child",
            selection_status: false,
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "child",
            selection_status: false,
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "child",
            selection_status: false,
          },
        },
      },
    },
    MQ08: {
      main_question: {
        id: "MQ08",
        key: "isPEP",
        icon_class: "tobaco",
        title: "",
        description:
          "Are you a politically exposed person (PEP) or a close relative of PEP?",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "adult",
            selection_status: false,
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "adult",
            selection_status: false,
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "child",
            selection_status: false,
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "child",
            selection_status: false,
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "child",
            selection_status: false,
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "child",
            selection_status: false,
          },
        },
      },
    },
    MQ09: {
      main_question: {
        id: "MQ09",
        key: "eiaAccount",
        icon_class: "tobaco",
        title: "",
        description: "DO you have any EIA account?",
        status: false,
        field_data: {
          "MQ1-SQ1-F1": {
            id: "MQ1-SQ1-F1",
            key: "accountNumber",
            title: "Account Number",
            field_type: "TEXTFIELD",
            value: { value: "", warning: false },
          },
          "MQ1-SQ1-F2": {
            id: "MQ1-SQ1-F2",
            key: "repository",
            title: "Repository Name",
            field_type: "DROPDOWN",
            value: { value: "", warning: false },
          },
        },
      },
    },
    MQ10: {
      main_question: {
        id: "MQ10",
        key: "consumables",
        icon_class: "tobaco",
        title:
          "Does the applicant consume Chewable tobacco /Gutkha/Pan Masala,Alcohol,Drugs,Cigarettes/Bidi/Cigar",
        description: "",
        status: false,
        sub_question: {
          "MQ1-SQ1": {
            id: "MQ1-SQ1",
            icon_class: "",
            status: false,
            title: "",
            description: "Chewable tobacco / Gutkha / Pan Masala",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    key: "pouches",
                    title: "No. of pouches per day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    key: "pouches",
                    title: "No. of pouches per day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    key: "pouches",
                    title: "No. of pouches per day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    key: "pouches",
                    title: "No. of pouches per day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    key: "pouches",
                    title: "No. of pouches per day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    key: "pouches",
                    title: "No. of pouches per day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ2-SQ2": {
            id: "MQ2-SQ2",
            icon_class: "",
            status: false,
            title: "",
            description: "Alcohol",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    key: "alcoholQuantity",
                    title: "Alcohal (No. of ml per week)",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    key: "dailyDrinker",
                    title: "Is Daily Drinker",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    key: "alcoholQuantity",
                    title: "Alcohal (No. of ml per week)",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    key: "dailyDrinker",
                    title: "Is Daily Drinker",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    key: "alcoholQuantity",
                    title: "Alcohal (No. of ml per week)",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    key: "dailyDrinker",
                    title: "Is Daily Drinker",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    key: "alcoholQuantity",
                    title: "Alcohal (No. of ml per week)",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    key: "dailyDrinker",
                    title: "Is Daily Drinker",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    key: "alcoholQuantity",
                    title: "Alcohal (No. of ml per week)",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    key: "dailyDrinker",
                    title: "Is Daily Drinker",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    key: "alcoholQuantity",
                    title: "Alcohal (No. of ml per week)",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    key: "dailyDrinker",
                    title: "Is Daily Drinker",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ3-SQ3": {
            id: "MQ3-SQ3",
            icon_class: "",
            status: false,
            title: "",
            description: "Cigarettes / Bidi / Cigar",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    key: "cigretsWeek",
                    title: "Per week Consumption",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    key: "cigretsWeek",
                    title: "Per week Consumption",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    key: "cigretsWeek",
                    title: "Per week Consumption",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    key: "cigretsWeek",
                    title: "Per week Consumption",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    key: "cigretsWeek",
                    title: "Per week Consumption",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    key: "cigretsWeek",
                    title: "Per week Consumption",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
        },
      },
    },
  };
};
