import { Box, Button, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import DatePicker from "../../../../Component/FieldTypes/DatePicker/DatePicker";
import RKTextField from "../../../../Component/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import MTermFooter from "../../../../Component/Term/MTermFooter/MTermFooter";
import { useAppSelector } from "../../../../redux/hooks";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import { TTermProposerData } from "../../../../types/TTermSlice";
import CustomButton from "../../../../Component/CustomButton/CustomButton";
import CustomCheckbox from "../../../../Component/FieldTypes/CustomCheckbox/CustomCheckbox";

const MProposerDetails = ({
  proposerFields,
  fieldsUpdateState,
  validateForm,
  loader,
  isProposerSameInsured,
  setIsProposerSameInsured,
  isChecked,
  setChecked,
  openIPRUPDFModel,
  setOpenIPRUPDFModel,
  HANDLE_PDF_DOWNLOAD,
  GET_REDIRECTION_URL,
}: {
  proposerFields: TTermProposerData;
  fieldsUpdateState: Function;
  validateForm: Function;
  loader: boolean;
  isProposerSameInsured: boolean;
  setIsProposerSameInsured: Function;
  isChecked: boolean;
  setChecked: Function;
  openIPRUPDFModel: boolean;
  setOpenIPRUPDFModel: Function;
  HANDLE_PDF_DOWNLOAD: Function;
  GET_REDIRECTION_URL: Function;
}) => {
  const { DROPDOWN_DATA, RIDERS_BENEFITS, LOADER } = useAppSelector(
    (state) => state.Term
  );

  const handleCheckboxChange = (attrName: string, isChecked: boolean) => {
    setChecked(isChecked);
    console.log(isChecked, "isChecked");
  };

  const handleClick = () => {
    setIsProposerSameInsured((prevState: boolean) => !prevState);
  };
  return (
    <>
      <Modal open={openIPRUPDFModel} onClose={() => setOpenIPRUPDFModel(false)}>
        <Box className="modalWrapper">
          <Box
            sx={{
              transform: "translate(0,0)",
              transition: "trapnsform 0.3s",
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box className="modalContent kycPopups">
              <Grid className="topbar" container alignItems="center">
                <Grid xs={12} textAlign={"right"}>
                  <Button
                    disableRipple
                    className="closePopup"
                    onClick={() => {
                      setOpenIPRUPDFModel(false);
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} alignContent="flex-start">
                <Grid xs={12} textAlign={"center"}>
                  <iframe
                    src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                      RIDERS_BENEFITS.riders_benefits_response.policyPdf
                    )}&embedded=true`}
                    style={{
                      minWidth: "500px",
                      width: "100%",
                      height: "700px",
                    }}
                    title="PDF Viewer"
                  ></iframe>
                </Grid>
                {/* <Grid
                  xs={6}
                  textAlign={"center"}
                  marginTop={"12px"}
                  display={"flex"}
                  gap={"16px"}
                  justifyContent={"center"}>
                  <CustomButton
                    text_name={"Download"}
                    class_name="primaryBtn"
                    size="large"
                    onClickFunction={() => {
                      HANDLE_PDF_DOWNLOAD();
                    }}
                  />
                </Grid> */}
                <Grid
                  xs={12}
                  textAlign={"center"}
                  marginTop={"12px"}
                  display={"flex"}
                  gap={"16px"}
                  justifyContent={"center"}
                >
                  <CustomButton
                    text_name={"Continue"}
                    class_name="primaryBtn"
                    size="large"
                    onClickFunction={() => {
                      HANDLE_PDF_DOWNLOAD(
                        RIDERS_BENEFITS?.riders_benefits_response?.policyPdf
                      );
                    }}
                    loading={loader}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
      {isProposerSameInsured ? (
        <Box>
          <h5 className="sectionTitle">Proposer Details</h5>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <h6>
                <CustomCheckbox
                  label={"Proposer is also an insured member."}
                  attrName="validPuc"
                  value_update={handleCheckboxChange}
                  value={isChecked}
                  defaultChecked={false}
                  disabled={false}
                  className="proposalCheckBoxWrapper"
                />
              </h6>
            </Grid>
            <Grid xs={12}>
              <h6>Prposer / Insured Details</h6>
            </Grid>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField mb-2"
                title={"Full Name"}
                validation_type="NAME"
                value={proposerFields.full_name.value}
                attrName={["full_name"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields.full_name.warning}
                error_message={
                  isEmpty(proposerFields.full_name.value)
                    ? "Enter Full Name"
                    : "Enter Valid Full Name"
                }
              />
            </Grid>
            <Grid xs={6}>
              <DatePicker
                class_name="inputField"
                title={"DOB"}
                value={proposerFields.dob.value}
                attrName={["dob"]}
                disabled={true}
                value_update={fieldsUpdateState}
                error_message="Enter DOB"
                warn_status={proposerFields.dob.warning}
              />
            </Grid>
            <Grid xs={6}>
              <SelectDropdown
                disabled
                class_name="inputField mb-2"
                title="Gender"
                value={proposerFields.gender.value}
                attrName={["gender"]}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.GENDER}
                warn_status={proposerFields.gender.warning}
                error_message="Select Gender"
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                disabled
                class_name="inputField mb-2"
                title={"Mobile"}
                value={proposerFields.mobile.value}
                attrName={["mobile"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields.mobile.warning}
                max_length={10}
                validation_type="NUMBER"
                error_message={
                  isEmpty(proposerFields.mobile.value)
                    ? "Enter Mobile Number"
                    : "Enter Valid Mobile number"
                }
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField mb-2"
                title={"Email"}
                value={proposerFields.email.value}
                attrName={["email"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields.email.warning}
                error_message={
                  isEmpty(proposerFields.email.value)
                    ? "Enter Email"
                    : "Enter Valid Email"
                }
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField mb-2"
                title={"Pincode"}
                value={proposerFields.pincode.value}
                attrName={["pincode"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields.pincode.warning}
                validation_type="NUMBER"
                max_length={12}
                disabled={true}
                error_message={
                  isEmpty(proposerFields.pincode.value)
                    ? "Enter Pincode"
                    : "Enter Valid Pincode"
                }
              />
            </Grid>
            {/* <Grid xs={6}>
              <RKTextField
                class_name="inputField mb-2"
                title={"Emergency Phone"}
                value={proposerFields.emergency_number.value}
                attrName={["emergency_number"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields.emergency_number.warning}
                max_length={10}
                validation_type="NUMBER"
                error_message={
                  isEmpty(proposerFields.emergency_number.value)
                    ? "Enter Emg Phone num "
                    : "Enter Valid Emg Phone num"
                }
              />
            </Grid> */}
            <Grid xs={6}>
              <SelectDropdown
                class_name="inputField mb-2"
                title="Occupation"
                value={proposerFields.occupation.value}
                attrName={["occupation"]}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.OCCUPATION_DATA}
                warn_status={proposerFields.occupation.warning}
                error_message="Select Proposer Occupation"
              />
            </Grid>
            <Grid xs={6}>
              <SelectDropdown
                class_name="inputField mb-2"
                title="Qualification"
                value={proposerFields?.qualification?.value}
                attrName={["qualification"]}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.QUALIFICATION_DATA}
                warn_status={proposerFields.qualification.warning}
                error_message="Select Proposer Occupation"
              />
            </Grid>
          </Grid>
          <MTermFooter
            loader={LOADER}
            forward={isChecked ? validateForm : handleClick}
          />
        </Box>
      ) : (
        <Box>
          <h5 className="sectionTitle">Insured Details</h5>
          <Grid container spacing={3}>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Full Name"}
                validation_type="NAME"
                value={proposerFields.insured_name.value}
                attrName={["insured_name"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields?.insured_name?.warning}
                error_message={
                  isEmpty(proposerFields.insured_name.value)
                    ? "Enter Full Name"
                    : "Enter Valid Full Name"
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={6}>
              <DatePicker
                class_name="inputField mb-2"
                title={"DOB"}
                value={proposerFields.insured_dob.value}
                attrName={["insured_dob"]}
                value_update={fieldsUpdateState}
                error_message="Enter DOB"
                warn_status={proposerFields?.insured_dob?.warning}
                min_date={100}
                max_date={18}
              />
            </Grid>
            <Grid xs={6}>
              <SelectDropdown
                class_name="inputField mb-2"
                title="Gender"
                value={proposerFields.insured_gender.value}
                attrName={["insured_gender"]}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.GENDER}
                warn_status={proposerFields?.insured_gender?.warning}
                error_message="Select Gender"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField mb-2"
                title={"Mobile"}
                value={proposerFields?.insured_mobile?.value}
                attrName={["insured_mobile"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields?.insured_mobile?.warning}
                max_length={10}
                validation_type="NUMBER"
                error_message={
                  isEmpty(proposerFields.mobile.value)
                    ? "Enter Mobile Number"
                    : "Enter Valid Mobile number"
                }
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Email"}
                value={proposerFields.insured_email.value}
                attrName={["insured_email"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields?.insured_email?.warning}
                error_message={
                  isEmpty(proposerFields.insured_email.value)
                    ? "Enter Email"
                    : "Enter Valid Email"
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField mb-2"
                title={"Pincode"}
                value={proposerFields?.insured_pincode?.value}
                attrName={["insured_pincode"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields?.insured_pincode?.warning}
                validation_type="NUMBER"
                max_length={6}
                error_message={
                  isEmpty(proposerFields.insured_pincode.value)
                    ? "Enter Pincode"
                    : "Enter Valid Pincode"
                }
              />
            </Grid>
            <Grid xs={6}>
              <SelectDropdown
                class_name="inputField mb-2"
                title="Occupation"
                value={proposerFields?.insured_occupation?.value}
                attrName={["insured_occupation"]}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.OCCUPATION_DATA}
                warn_status={proposerFields?.insured_occupation?.warning}
                error_message="Select Proposer Occupation"
              />
            </Grid>
            <Grid xs={6}>
              <SelectDropdown
                class_name="inputField mb-2"
                title="Qualification"
                value={proposerFields?.insured_qualification?.value}
                attrName={["insured_qualification"]}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.QUALIFICATION_DATA}
                warn_status={proposerFields?.insured_qualification?.warning}
                error_message="Select Proposer Occupation"
              />
            </Grid>
          </Grid>

          <MTermFooter loader={LOADER} forward={validateForm} />
        </Box>
      )}
    </>
  );
};

export default MProposerDetails;
