import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../../Component/LeftSidebar/LeftSidebar.scss";
import { useAppSelector } from "../../../redux/hooks";
import {
  formatAmountToCurrency,
  formatNumberToLakhOrCrores,
  isEmpty,
} from "../../../SupportingFiles/HelpingFunction";
const TermLeftSidebar = () => {
  const { SELECTED_QUOTE_DATA, RIDERS_BENEFITS, LOADER, PROPOSER_DATA } =
    useAppSelector((state) => state.Term);
  const { PREMIUM_LOADER } = useAppSelector((state) => state.TermFullJourney);
  return (
    <Grid xs className="proposalLeftSidebar">
      <Box className={"termIcon"}></Box>
      <img
        alt=""
        className="insurerLogo"
        src={SELECTED_QUOTE_DATA?.companyDetails?.logo}
      />
      <h6 className="mb-8">
        {SELECTED_QUOTE_DATA?.productDetails?.product_name}
      </h6>
      <h5>
        {formatNumberToLakhOrCrores(
          Number(`${SELECTED_QUOTE_DATA?.premiumDetails?.sum_assured}`)
        )}
      </h5>
      <p className="mb-8">{"Sum Assured"}</p>

      <h5>{SELECTED_QUOTE_DATA?.premiumDetails?.term} Years</h5>
      <p className="mb-8">Policy Tenure</p>

      <h5>
        {SELECTED_QUOTE_DATA?.productDetails?.frequency === "5"
          ? "1 Year"
          : `${SELECTED_QUOTE_DATA?.premiumDetails?.pay_term} Years`}
      </h5>
      <p className="mb-8">Policy Pay Tenure</p>

      <h5>
        {SELECTED_QUOTE_DATA?.productDetails?.frequency === "12"
          ? "Monthly"
          : SELECTED_QUOTE_DATA?.productDetails?.frequency === "2"
          ? "Half-Yearly"
          : SELECTED_QUOTE_DATA?.productDetails?.frequency === "4"
          ? "Quarterly"
          : SELECTED_QUOTE_DATA?.productDetails?.frequency === "1"
          ? "Yearly"
          : "Single Pay"}
      </h5>
      <p className="mb-8">Payment Mode</p>
      {LOADER || PREMIUM_LOADER ? (
        <img src="../images/loader.svg" height="10px" alt="Brand Logo" />
      ) : (
        <h5 className="premium" id="premium">
          {isEmpty(RIDERS_BENEFITS?.riders_benefits_response?.finalPremium)
            ? formatAmountToCurrency(SELECTED_QUOTE_DATA.premiumDetails.premium)
            : formatAmountToCurrency(
                RIDERS_BENEFITS?.riders_benefits_response?.finalPremium
              )}
        </h5>
      )}
      <p className="mb-8">Premium (Incl. GST)</p>
      {SELECTED_QUOTE_DATA?.companyDetails?.company_code === "L007" &&
      PROPOSER_DATA?.occupation?.value === "07" ? (
        <p className="mb-8">
          {SELECTED_QUOTE_DATA?.productDetails?.frequency === "5"
            ? "Note: A 2% discounted premium is available only for the first year, while a 1% discounted premium is applicable for life."
            : "Note: A 12% discounted premium is available only for the first year, while a 1% discounted premium is applicable for life."}
        </p>
      ) : null}
    </Grid>
  );
};

export default TermLeftSidebar;
