import EditIcon from "@mui/icons-material/Edit";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { pageSlice } from "../../../../../redux/slice/HI/PageSlice/PageSlice";
import { useAppDispatch } from "../../../../../redux/hooks";

interface Props {
  title: string;
  editLink: string;
  data: Array<{
    label: string;
    label_value: string;
  }>;
  isCommunicationSameAsPermanent?: boolean;
  isCoOwner?: boolean;
  typeOfBuilding?: string;
}

const PreviewDetailBox: React.FC<Props> = ({
  title,
  data,
  editLink,
  isCommunicationSameAsPermanent,
  isCoOwner,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleEditClicked = () => {
    dispatch(pageSlice.actions.UPDATE_PAGE_STATUS(true));
    navigate(editLink);
  };

  return (
    <Box className="previewBox ">
      <Grid container rowSpacing={0} columnSpacing={2}>
        <Grid xs={8}>
          <p className="previewBoxTitle">{title}</p>
        </Grid>
        <Grid xs={4} textAlign="right">
          <Button onClick={handleEditClicked} className="previewBoxEdit">
            <EditIcon /> Edit
          </Button>
        </Grid>
        <Grid xs={12}>
          <ul>
            {data.map((data) => (
              <>
                <li>
                  <p>
                    {data.label}:
                    <span style={{ wordWrap: "break-word" }}>
                      {data.label_value}
                    </span>
                  </p>
                </li>
              </>
            ))}
          </ul>
          {title === "Address Details" && isCommunicationSameAsPermanent && (
            <Box
              display={"flex"}
              alignItems={"center"}
              marginTop={"4px"}
              border={"1px dashed #ccc"}
              borderRadius={"8px"}
              padding={"4px"}
              margin={"6px"}
            >
              <TaskAltIcon
                sx={{
                  color: "#00785A",
                  marginRight: "3px",
                  height: "16px",
                }}
              />
              <p style={{ fontSize: "12px", color: "#00785A" }}>
                Mailing / Correspondence address is same as permanent address.
              </p>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PreviewDetailBox;
