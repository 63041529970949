import { PatchAPI } from "../../Axios/AxiosInstance";
import { PostAPI } from "../../Axios/AxiosInstanceToken";
import { AddNonTermDTO } from "../../DTO/NonTermDTO/AddNonTermDTO";
import { DTONonTermHDFCRiderStatus } from "../../types/NonTerm/DTONonTermRidersStatus";
import { NON_TERM_URL } from "../../URLCollection/non-term/NonTermUrl";

const addNonTermFormData = (
  onSuccess: Function,
  onError: Function,
  dto: AddNonTermDTO
) => {
  PostAPI.call(
    NON_TERM_URL.ADD_NON_TERM_FORM,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

// const nonTermQuotes = (onSuccess: Function, onError: Function, dto: any) => {
//   PostAPI.call(
//     NON_TERM_URL.NON_TERM_QUOTES,
//     dto,
//     (res: any) => onSuccess(res.data),
//     onError
//   );
// };

const GET_PRODUCT_LIST = (onSuccess: Function, onError: Function, dto: any) => {
  PostAPI.call(
    NON_TERM_URL.NON_TERM_PRODUCT_LIST,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_SINGLE_NON_TERM_QUOTE = (
  onSuccess: Function,
  onError: Function,
  dto: any
) => {
  PostAPI.call(
    NON_TERM_URL.GET_SINGLE_QUOTE,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const updateNonTermDetails = (
  onSuccess: Function,
  onError: Function,
  dto: any
) => {
  PatchAPI.call(
    NON_TERM_URL.NON_TERM_UPDATE_DETAILS,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const buyAction = (onSuccess: Function, onError: Function, dto: any) => {
  PostAPI.call(
    NON_TERM_URL.NON_TERM_BUY_ACTION,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const UPDATE_PROPOSER_DETAILS = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    NON_TERM_URL.NON_TERM_ADD_PERPOSAL_DETAILS,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const UPDATE_MAX_LIFE_GET_PROPOSAL = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    NON_TERM_URL.NON_TERM_GET_PROPOSAL_MAX_LIFE,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const NON_TERM_UPDATE_RIDERS_STATUS = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: DTONonTermHDFCRiderStatus
) => {
  PostAPI.call(
    NON_TERM_URL.NON_TERM_UPDATE_RIDERS_STATUS,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_REDIRECTION_URL = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    NON_TERM_URL.NON_TERM_GET_REDIRECTION_URL,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const SEND_OTP = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    NON_TERM_URL.NON_TERM_PROPOSER_SEND_OTP,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const VERIFY_OTP = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    NON_TERM_URL.NON_TERM_PROPOSER_VERIFY_OTP,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_REDIRECTION_URL_ADITYA_BIRLA = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: {
    name: string;
    dob: string;
    email_id: string;
    mobile: string;
    product_code: string;
  }
) => {
  PostAPI.call(
    NON_TERM_URL.NON_TERM_GET_REDIRECTION_URL,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const NON_TERM_PROPOSER_OCCUPATION_DETAILS = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    NON_TERM_URL.NON_TERM_PROPOSER_OCCUPATION_DETAILS,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};
const NON_TERM_PROPOSER_QUALIFICATION_DETAILS = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    NON_TERM_URL.NON_TERM_PROPOSER_QUALIFICATION_DETAILS,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

export const NON_TERM_SERVICES = {
  addNonTermFormData,
  // nonTermQuotes,
  GET_PRODUCT_LIST,
  GET_SINGLE_NON_TERM_QUOTE,
  updateNonTermDetails,
  buyAction,
  UPDATE_PROPOSER_DETAILS,
  NON_TERM_UPDATE_RIDERS_STATUS,
  GET_REDIRECTION_URL,
  NON_TERM_PROPOSER_OCCUPATION_DETAILS,
  NON_TERM_PROPOSER_QUALIFICATION_DETAILS,
  GET_REDIRECTION_URL_ADITYA_BIRLA,
  UPDATE_MAX_LIFE_GET_PROPOSAL,
  SEND_OTP,
  VERIFY_OTP
};
