import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { useAppSelector } from "../../redux/hooks";
import {
  calculateAgeInDays,
  isEmpty,
} from "../../SupportingFiles/HelpingFunction";
import MemberRadioButtonNew from "../MemberRadioButton/MemberRadioButtonNew";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import SelectDropdown from "../FieldTypes/SelectDropdown/SelectDropdown";
import DatePicker from "../FieldTypes/DatePicker/DatePicker";
import { subDays } from "date-fns";
import RKTextField from "../FieldTypes/RKTextField/RKTextField";
import "./MMedicalDetailQuestion.scss";
interface Props {
  main_class?: string;
  icon_class?: string;
  question_name?: string;
  sub_details?: any;
  input_id_first?: string;
  input_id_second?: string;
  input_name?: string;
  toggle_button_status?: boolean;
  value_update?: Function;
  attrName?: any;
  select_disease?: any;
  data?: any;
  dataSubQuestion?: any;
  updateMasterState?: any;
}
const MMedicalQuestionRSA: React.FC<Props> = ({
  main_class,
  icon_class,
  question_name,
  sub_details,
  input_id_first,
  input_id_second,
  input_name,
  value_update,
  toggle_button_status,
  attrName,
  data,
  select_disease,
  dataSubQuestion,
  updateMasterState,
}) => {
  const { INSURED_MEMBER_DETAILS, ADD_FORM, KYC_DETAILS } = useAppSelector(
    (state) => state.Health
  );
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  const update_status = (attrName: any, value: any) => {
    value_update?.(attrName, value);
  };

  console.log("innerQuestiondata", data?.main_question?.member_data);
  return (
    <Box className="medicalQuestion_m">
      <Grid container spacing={2}>
        <Grid xs={8} md={9} className="medicalQuestionInner">
          <span className={icon_class}></span>
          <div>
            <h6>{isEmpty(question_name) ? sub_details : question_name}</h6>
            {isEmpty(question_name) ? null : <p>{sub_details}</p>}
          </div>
        </Grid>
        <Grid xs={4} md={3} textAlign="right" className="pt-4">
          <ToggleSwitch
            toggle_status={toggle_button_status}
            value_update={update_status}
            attrName={attrName}
          />
        </Grid>
      </Grid>
      {select_disease}

      <Grid container spacing={2}>
        <Grid xs={12} marginTop={"12px"}>
          {data?.main_question?.member_data &&
            data?.main_question?.status &&
            Object.values(data?.main_question?.member_data)?.map(
              (memberDataValue: any, memberIndex: number) => (
                <React.Fragment key={memberIndex}>
                  {memberDataValue.name !== "" && (
                    <Grid xs={6}>
                      <MemberRadioButtonNew
                        class_name={`mb-3 ${
                          memberIndex === 0 || memberIndex === 1
                            ? "adult"
                            : "child"
                        }`}
                        checked={memberDataValue?.selection_status}
                        label_name={memberDataValue.name}
                        attrName={[
                          "mainMember",
                          data?.main_question?.id,
                          memberDataValue?.keyName,
                        ]}
                        value_update={updateMasterState}
                      />
                      {memberDataValue?.selection_status &&
                        memberDataValue.field_data &&
                        memberDataValue.keyName && (
                          <>
                            {Object.values(memberDataValue.field_data).map(
                              (field_data_data: any) => {
                                return (
                                  <React.Fragment key={field_data_data.id}>
                                    {field_data_data.field_type ===
                                      "TEXTFIELD" && (
                                      <RKTextField
                                        id="mainMemberDataField"
                                        class_name="inputField mb-5"
                                        title={field_data_data?.title}
                                        attrName={[
                                          "mainMemberDataField",
                                          data?.main_question?.id,
                                          field_data_data.id,
                                          memberDataValue.keyName,
                                          "desc",
                                        ]}
                                        value={field_data_data?.value?.value}
                                        value_update={updateMasterState}
                                        warn_status={
                                          field_data_data?.value?.warning
                                        }
                                        error_message={"Enter description"}
                                      />
                                    )}
                                  </React.Fragment>
                                );
                              }
                            )}
                          </>
                        )}
                    </Grid>
                  )}
                </React.Fragment>
              )
            )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MMedicalQuestionRSA;
