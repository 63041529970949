import { subDays, subMonths, subYears } from "date-fns";
import { ECompanyCode } from "../Services/Enum/EHome";
import { THealthAddForm, THealthSlice } from "../types/Health/THealthSlice";
import {
  calculateAge,
  calculateAgeInDays,
  extractDateUnit,
  FIND_LABEL_OF_VALUE,
  FORMAT_DATE_DD_MM_YYYY,
  isEmpty,
} from "./HelpingFunction";
import { EICICIProductID } from "../Enum/EICICIProductID";
import { TCKYC } from "../types/CKYC/TCKYC";
import { TCommonDropdownSlice } from "../types/TCommonDropdownSlice";
import { useAppSelector } from "../redux/hooks";

export const HealthFeatClass = (feature_code: string) => {
  const healthFeatureMapping: { [key: string]: string } = {
    HF001: "discount",
    HF002: "preexisting",
    HF003: "discount",
    HF004: "treatment",
    HF005: "recharge",
    HF006: "extrapremium",
    HF007: "hospitalized",
    HF008: "healthcheckup",
    HF009: "hospital",
    HF010: "hospitalized",
    HF011: "medicines",
    HF012: "treatment",
    HF013: "hospitalized",
    HF014: "hospitalized",
    HF015: "hospitalized",
    HF016: "treatments",
    HF017: "treatment",
    HF018: "bonus",
    HF019: "extrapremium",
    HF020: "hospitalized",
    HF021: "preexisting",
    HF022: "hospitalized",
    HF023: "lungs",
    HF024: "maternity",
    HF025: "pregnancy",
    HF026: "maternity",
    HF027: "maternity",
    HF028: "insulin",
    HF029: "wounds",
    HF030: "amount",
    HF031: "discount",
    HF032: "healthcheckup",
    HF033: "bloodsugar",
    HF034: "extrapremium",
    HF035: "accident",
    HF036: "accident",
    HF037: "healthcheckup",
    HF038: "eyes",
    HF039: "handicaped",
    HF040: "treatment",
    HF041: "extrapremium",
    HF042: "hospitalized",
    HF043: "pregnancy",
    HF044: "pregnancy",
    HF045: "medicalhistory",
    HF046: "maternity",
    HF047: "healthcheckup",
    // HF048: "value_for_HF002",
    // HF049: "value_for_HF002",
  };

  if (feature_code in healthFeatureMapping) {
    return healthFeatureMapping[feature_code];
  }

  return feature_code;
};

type DateValidationType = "DAYS" | "MONTHS" | "YEARS" | "DATE" | undefined;

export const GET_DATE_VALIDATION_FOR_CHILD = (
  health_slice: THealthSlice
): {
  dateValidationType: DateValidationType;
  minDate: number;
  maxDate: number;
  defaultDate: Date;
} => {
  const { company_code } = health_slice.SELECTED_QUOTE_DATA?.CompanyDetails;
  let dateValidationType: DateValidationType;
  let minDate: number;
  let maxDate: number;
  let defaultDate: Date;

  switch (company_code) {
    case ECompanyCode.HDFC_HEALTH:
      dateValidationType = "DAYS";
      minDate = 9496;
      maxDate = 91;
      defaultDate = subDays(new Date(), 91);
      break;

    case ECompanyCode.CARE:
      dateValidationType = "DAYS";
      minDate = 9129;
      maxDate = 91;
      defaultDate = subMonths(new Date(), 3);
      break;

    case ECompanyCode.NIVA_BUPA:
      dateValidationType = "DAYS";
      minDate = calculateAgeInDays(
        FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 23)}`)
      );
      maxDate = 91;
      defaultDate = subYears(new Date(), 1);
      break;

    case ECompanyCode.GO_DIGIT:
      dateValidationType = "MONTHS";
      minDate = 25 * 12;
      maxDate = 12;
      defaultDate = subMonths(new Date(), 12);
      break;

    case ECompanyCode.STAR:
      dateValidationType = "DAYS";
      minDate = 25 * 365;
      maxDate = 91;
      defaultDate = subMonths(new Date(), 3);
      break;

    default:
      // Default case for other companies
      dateValidationType = "MONTHS";
      minDate = 25 * 12;
      maxDate = 3;
      defaultDate = subMonths(new Date(), 3);
      break;
  }

  return {
    dateValidationType,
    minDate,
    maxDate,
    defaultDate,
  };
};

export function GET_DATE_VALIDATION(
  company_code: string,
  product_id: string
): {
  validation_type: "DAYS" | "YEARS" | "MONTHS" | undefined;
  min_date: number;
  max_date: number;
} {
  switch (company_code) {
    // case ECompanyCode.HDFC_HEALTH:
    //   return { validation_type: "DAYS", min_date: 16801, max_date: 6575 };

    case ECompanyCode.HDFC_HEALTH:
      return {
        validation_type: "DAYS",
        min_date:
          calculateAgeInDays(
            FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 66)}`)
          ) - 1,
        max_date: calculateAgeInDays(
          FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 18)}`)
        ),
      };

    case ECompanyCode.STAR:
      if (`${product_id}` === "187") {
        return { validation_type: "YEARS", min_date: 75, max_date: 60 };
      } else if (`${product_id}` === "180") {
        return { validation_type: "YEARS", min_date: 65, max_date: 18 };
      } else if (`${product_id}` === "197") {
        return { validation_type: "YEARS", min_date: 65, max_date: 18 };
      } else {
        return {
          validation_type: "DAYS",
          min_date: 75 * 365,
          max_date: 60 * 365,
        };
      }

    case ECompanyCode.NIVA_BUPA:
      if (
        `${product_id}` === "2592" ||
        `${product_id}` === "2593" ||
        `${product_id}` === "2594"
      ) {
        return {
          validation_type: "DAYS",
          min_date:
            calculateAgeInDays(
              FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 65)}`)
            ) - 1,
          max_date: calculateAgeInDays(
            FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 18)}`)
          ),
        };
      } else if (
        `${product_id}` === "2891" ||
        `${product_id}` === "2890" ||
        `${product_id}` === "2892" ||
        `${product_id}` === "2893"
      ) {
        //Senior first gold floater
        return {
          validation_type: "YEARS",
          min_date: calculateAge(
            FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 99)}`)
          ),
          max_date: calculateAge(
            FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 61)}`)
          ),
        };
      } else {
        return {
          validation_type: "DAYS",
          min_date: 75 * 365,
          max_date: 61 * 365,
        };
      }

    case ECompanyCode.ICIC:
      if (`${product_id}` === "2843") {
        return { validation_type: "YEARS", min_date: 85, max_date: 56 };
      } else if (`${product_id}` === EICICIProductID.HAP) {
        return {
          validation_type: "YEARS",
          min_date: 65,
          max_date: 18,
        };
      } else {
        return {
          validation_type: "YEARS",
          min_date: 99,
          max_date: 18,
        };
      }

    case ECompanyCode.CARE:
      if (`${product_id}` === "443") {
        return { validation_type: "YEARS", min_date: 99, max_date: 61 };
      } else {
        return {
          validation_type: "YEARS",
          min_date: 99,
          max_date: 18,
        };
      }

    default:
      return { validation_type: "YEARS", min_date: 100, max_date: 18 };
  }
}

export function GET_DATE_VALIDATION_FOR_2ND_ADULT(
  company_code: string,
  product_id: string,
  health_slice?: THealthSlice
): {
  validation_type: "DAYS" | "YEARS" | "MONTHS" | undefined;
  min_date: number;
  max_date: number;
} {
  switch (company_code) {
    // case ECompanyCode.HDFC_HEALTH:
    //   return { validation_type: "DAYS", min_date: 16801, max_date: 6575 };

    case ECompanyCode.HDFC_HEALTH:
      return {
        validation_type: "DAYS",
        min_date:
          calculateAgeInDays(
            FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 66)}`)
          ) - 1,
        max_date: calculateAgeInDays(
          FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 18)}`)
        ),
      };

    case ECompanyCode.STAR:
      if (`${product_id}` === "187") {
        return { validation_type: "YEARS", min_date: 75, max_date: 60 };
      } else if (`${product_id}` === "180") {
        return { validation_type: "YEARS", min_date: 65, max_date: 18 };
      } else if (`${product_id}` === "197") {
        return { validation_type: "YEARS", min_date: 65, max_date: 18 };
      } else if (`${product_id}` === "2947") {
        return { validation_type: "YEARS", min_date: 65, max_date: 18 };
      } else {
        return {
          validation_type: "DAYS",
          min_date: 75 * 365,
          max_date: 60 * 365,
        };
      }

    case ECompanyCode.NIVA_BUPA:
      if (
        `${product_id}` === "2592" ||
        `${product_id}` === "2593" ||
        `${product_id}` === "2594"
      ) {
        return {
          validation_type: "DAYS",
          min_date:
            calculateAgeInDays(
              FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 66)}`)
            ) - 1,
          max_date: calculateAgeInDays(
            FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 18)}`)
          ),
        };
      } else if (`${product_id}` === "2891") {
        //Senior first gold floater
        return {
          validation_type: "DAYS",
          min_date:
            calculateAgeInDays(
              FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 99)}`)
            ) - 1,
          max_date: calculateAgeInDays(
            FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 18)}`)
          ),
        };
      } else if (
        `${product_id}` === "2943" ||
        `${product_id}` === "2944" ||
        `${product_id}` === "2945" ||
        `${product_id}` === "2946"
      ) {
        return {
          validation_type: "DAYS",
          min_date:
            calculateAgeInDays(
              FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 110)}`)
            ) - 1,
          max_date: calculateAgeInDays(
            FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 18)}`)
          ),
        };
      } else {
        const adult_one_age_in_days =
          calculateAgeInDays(
            `${health_slice?.INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
          ) - 1;
        return {
          validation_type: "DAYS",
          min_date: adult_one_age_in_days,
          max_date: calculateAgeInDays(
            FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 18)}`)
          ),
        };
      }

    case ECompanyCode.ICIC:
      if (`${product_id}` === "2843") {
        return { validation_type: "YEARS", min_date: 85, max_date: 56 };
      } else if (`${product_id}` === EICICIProductID.HAP) {
        return {
          validation_type: "YEARS",
          min_date: 65,
          max_date: 18,
        };
      } else {
        return {
          validation_type: "YEARS",
          min_date: 99,
          max_date: 18,
        };
      }

    case ECompanyCode.CARE:
      if (`${product_id}` === "443") {
        return { validation_type: "YEARS", min_date: 99, max_date: 61 };
      } else {
        return {
          validation_type: "YEARS",
          min_date: 99,
          max_date: 18,
        };
      }

    default:
      return { validation_type: "YEARS", min_date: 100, max_date: 18 };
  }
}

export const child_gender = (
  child_no: "1" | "2" | "3" | "4",
  formData: THealthAddForm
) => {
  const { son_count, daughter_count } = formData;
  switch (child_no) {
    case "1":
      if (son_count.value > 0) {
        return "M";
      } else if (daughter_count.value > 0) {
        return "F";
      }
      break;

    case "2":
      if (son_count.value > 1) {
        return "M";
      } else if (daughter_count.value > 1) {
        return "F";
      }
      break;

    case "3":
      if (son_count.value > 2) {
        return "M";
      } else if (daughter_count.value > 2) {
        return "F";
      }
      break;

    case "4":
      if (son_count.value > 3) {
        return "M";
      } else if (daughter_count.value > 3) {
        return "F";
      }
      break;

    default:
      break;
  }
};

export const HEALTH_CREATE_PROPOSAL_REQUEST = (
  HEALTH_SLICE: THealthSlice,
  CKYCSlice: TCKYC,
  COMMON_DROPDOWN_DATA_SLICE: TCommonDropdownSlice
) => {
  return {
    utm_medium: HEALTH_SLICE?.ADD_FORM_RESPONSE?.utm_medium,
    utm_source: HEALTH_SLICE?.ADD_FORM_RESPONSE?.utm_source,
    product_code:
      HEALTH_SLICE?.SELECTED_QUOTE_DATA?.productDetails.product_code,
    company_code:
      HEALTH_SLICE?.SELECTED_QUOTE_DATA?.CompanyDetails.company_code,
    quote_no: HEALTH_SLICE?.ADD_FORM_RESPONSE.quote_no,
    cover:
      `${HEALTH_SLICE?.ADD_FORM.spouse_status ? "2A" : "1A"}` +
      `${
        HEALTH_SLICE?.ADD_FORM.children > 0
          ? `${HEALTH_SLICE?.ADD_FORM.children}C`
          : ""
      }`,

    finalPremium:
      HEALTH_SLICE?.SELECTED_QUOTE_DATA?.premiumDetails.finalPremium,
    proposer_name:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.name.value,
    proposer_mobile: HEALTH_SLICE?.ADD_FORM.mobile.value,
    proposer_email:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.email.value,
    proposer_gender:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.gender.value,
    proposer_date: HEALTH_SLICE?.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.dob
      .value
      ? extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.dob.value}`,
          "day"
        )
      : "",
    proposer_month: HEALTH_SLICE?.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.dob
      .value
      ? extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.dob.value}`,
          "month"
        )
      : "",
    proposer_year: HEALTH_SLICE?.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.dob
      .value
      ? extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.dob.value}`,
          "year"
        )
      : "",
    address_p_area:
      HEALTH_SLICE?.SELECTED_QUOTE_DATA?.CompanyDetails.company_code ===
      ECompanyCode.STAR
        ? `${CKYCSlice?.CKYC_DETAILS.kycAddress2}-${FIND_LABEL_OF_VALUE(
            COMMON_DROPDOWN_DATA_SLICE?.AREA_TOWN,
            CKYCSlice?.CKYC_DETAILS.kycAddress2
          )}`
        : HEALTH_SLICE?.ADDRESS_DETAILS?.addressLineOne.value,
    address_m_area:
      HEALTH_SLICE?.SELECTED_QUOTE_DATA?.CompanyDetails.company_code ===
      ECompanyCode.STAR
        ? `${CKYCSlice?.CKYC_DETAILS.kycAddress2}-${FIND_LABEL_OF_VALUE(
            COMMON_DROPDOWN_DATA_SLICE?.AREA_TOWN,
            CKYCSlice?.CKYC_DETAILS.kycAddress2
          )}`
        : HEALTH_SLICE?.ADDRESS_DETAILS?.mailingAddressLineOne.value,
    address_p_district: "",
    address_m_district: "",
    adult1_relationship: HEALTH_SLICE?.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS
      .IS_PROPOSER_INSURED
      ? "Self"
      : HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.relationship
          .value,
    adult2_relationship:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.relationship.value,
    height: !isEmpty(
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.heightFeet.value
    )
      ? `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.heightFeet.value}.${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.heightInches.value} `
      : "",
    weight: `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.weight.value} `,
    insured_name:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
    insured_gender: !HEALTH_SLICE?.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS
      .IS_PROPOSER_INSURED
      ? HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.relationship
          .value === "Father"
        ? "M"
        : HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.relationship
            .value === "Spouse" &&
          HEALTH_SLICE?.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.gender.value ===
            "M"
        ? "F"
        : "M"
      : HEALTH_SLICE?.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.gender.value,
    insured_date: HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob
      .value
      ? extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value} `,
          "day"
        )
      : extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value} `,
          "day"
        ),
    insured_month: HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob
      .value
      ? extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value} `,
          "month"
        )
      : extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value} `,
          "month"
        ),
    insured_year: HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob
      .value
      ? extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value} `,
          "year"
        )
      : extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value} `,
          "year"
        ),
    nominee_relationship:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.NOMINEE__DETAILS.relationship.value,
    nominee_name:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.NOMINEE__DETAILS.full_name.value,
    nominee_date: HEALTH_SLICE?.INSURED_MEMBER_DETAILS.NOMINEE__DETAILS.dob
      .value
      ? extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.NOMINEE__DETAILS.dob.value} `,
          "day"
        )
      : "",
    nominee_month: HEALTH_SLICE?.INSURED_MEMBER_DETAILS.NOMINEE__DETAILS.dob
      .value
      ? extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.NOMINEE__DETAILS.dob.value} `,
          "month"
        )
      : "",
    nominee_year: HEALTH_SLICE?.INSURED_MEMBER_DETAILS.NOMINEE__DETAILS.dob
      .value
      ? extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.NOMINEE__DETAILS.dob.value} `,
          "year"
        )
      : "",
    address_p_house:
      HEALTH_SLICE?.SELECTED_QUOTE_DATA?.CompanyDetails.company_code ===
      ECompanyCode.STAR
        ? CKYCSlice.CKYC_DETAILS.kycAddress1
        : HEALTH_SLICE?.ADDRESS_DETAILS?.addressLineTwo.value,
    address_p_village: "",
    address_p_pincode: HEALTH_SLICE?.ADDRESS_DETAILS?.pincode.value,
    address_p_city: HEALTH_SLICE?.ADDRESS_DETAILS?.city,
    address_p_state: HEALTH_SLICE?.ADDRESS_DETAILS?.state,
    address_mailing_same: HEALTH_SLICE?.ADDRESS_DETAILS?.isMailingAddressSame
      ? "Yes"
      : "No",
    address_m_house:
      HEALTH_SLICE?.SELECTED_QUOTE_DATA?.CompanyDetails.company_code ===
      ECompanyCode.STAR
        ? CKYCSlice.CKYC_DETAILS.kycAddress1
        : HEALTH_SLICE?.ADDRESS_DETAILS?.mailingAddressLineTwo.value,
    address_m_village: "",
    address_m_pincode: HEALTH_SLICE?.ADDRESS_DETAILS?.mailingPinCode.value,
    address_m_city: HEALTH_SLICE?.ADDRESS_DETAILS?.mailingCity,
    address_m_state: HEALTH_SLICE?.ADDRESS_DETAILS?.mailingState,
    proposer_emergency_no:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.emergencyPhone
        .value,
    aadhar_number:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.aadhar.value,
    // marital_statu5s:
    //   HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.maritalStatus.value,

    marital_status:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.maritalStatus
        .value,

    spouse_name:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
    spouse_gender:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value === ""
        ? ""
        : HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.relationship
            .value === "Father"
        ? "M"
        : HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.relationship
            .value === "Mother"
        ? "F"
        : HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.relationship
            .value === "Spouse" &&
          HEALTH_SLICE?.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.gender.value ===
            "M"
        ? "F"
        : "M",
    spouse_date: HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob
      .value
      ? extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value} `,
          "day"
        )
      : "",
    spouse_month: HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob
      .value
      ? extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value} `,
          "month"
        )
      : "",
    spouse_year: HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob
      .value
      ? extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value} `,
          "year"
        )
      : "",
    spouse_mstatus: "",
    spouse_height: !isEmpty(
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.heightFeet.value
    )
      ? `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.heightFeet.value}.${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.heightInches.value} `
      : "",
    spouse_weight:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.weight.value,
    spouse_bmi: "",
    spouse_designation: "",
    spouse_business: "",
    member_income:
      HEALTH_SLICE?.SELECTED_QUOTE_DATA?.CompanyDetails.company_code === "G027"
        ? HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.member_income
            .value
        : HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.annual_income
            .value,
    grainful_income:
      HEALTH_SLICE?.SELECTED_QUOTE_DATA?.CompanyDetails.company_code ===
        "G027" &&
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.grainful_income
        .value,
    occupation:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.occupation.value,
    qualification:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.qualification
        .value,
    spouse_occupation:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.occupation.value,
    spouse_qualification:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.qualification
        .value,
    child1_qualification: "",
    child2_qualification: "",
    child3_qualification: "",
    child4_qualification: "",
    child1_name:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
    child1_gender:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value === ""
        ? ""
        : HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.relationship
            .value === "daughter"
        ? "F"
        : "M",
    child1_date: HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob
      .value
      ? extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value} `,
          "day"
        )
      : "",
    child1_month: HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob
      .value
      ? extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value} `,
          "month"
        )
      : "",
    child1_year: HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob
      .value
      ? extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value} `,
          "year"
        )
      : "",
    child1_mstatus: "",
    child1_occupation: "",
    child1_height: !isEmpty(
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.heightFeet.value
    )
      ? `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.heightFeet.value}.${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.heightInches.value} `
      : "",
    child1_weight:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.weight.value,
    child1_bmi: "",
    child1_designation: "",
    child1_business: "",
    child2_name:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
    child2_gender:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value === ""
        ? ""
        : HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.relationship
            .value === "daughter"
        ? "F"
        : "M",
    child2_date: HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob
      .value
      ? extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value} `,
          "day"
        )
      : "",
    child2_month: HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob
      .value
      ? extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value} `,
          "month"
        )
      : "",
    child2_year: HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob
      .value
      ? extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value} `,
          "year"
        )
      : "",
    child2_mstatus: "",
    child2_occupation: "",
    child2_height: !isEmpty(
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.heightFeet.value
    )
      ? `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.heightFeet.value}.${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.heightInches.value} `
      : "",
    child2_weight:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.weight.value,
    child2_bmi: "",
    child2_designation: "",
    child2_business: "",
    child3_name:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
    child3_gender:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value === ""
        ? ""
        : HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.relationship
            .value === "daughter"
        ? "F"
        : "M",
    child3_date: HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob
      .value
      ? extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value} `,
          "day"
        )
      : "",
    child3_month: HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob
      .value
      ? extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value} `,
          "month"
        )
      : "",
    child3_year: HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob
      .value
      ? extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value} `,
          "year"
        )
      : "",
    child3_mstatus: "",
    child3_occupation: "",
    child3_height: !isEmpty(
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.heightFeet.value
    )
      ? `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.heightFeet.value}.${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.heightInches.value} `
      : "",
    child3_weight:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.weight.value,
    child3_bmi: "",
    child3_designation: "",
    child3_business: "",
    child4_name:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
    child4_gender:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value === ""
        ? ""
        : HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.relationship
            .value === "daughter"
        ? "F"
        : "M",
    child4_date: HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob
      .value
      ? extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value} `,
          "day"
        )
      : "",
    child4_month: HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob
      .value
      ? extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value} `,
          "month"
        )
      : "",
    child4_year: HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob
      .value
      ? extractDateUnit(
          `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value} `,
          "year"
        )
      : "",
    child4_mstatus: "",
    child4_occupation: "",
    child4_height: !isEmpty(
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.heightFeet.value
    )
      ? `${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.heightFeet.value}.${HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.heightInches.value} `
      : "",
    child4_weight:
      HEALTH_SLICE?.INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.weight.value,
    child4_bmi: "",
    child4_designation: "",
    child4_business: "",
    kycDetails: CKYCSlice?.CKYC_DETAILS,
  };
};
