import { Route, Routes } from "react-router-dom";
import { NonTermBasicDetailsContainer } from "../../../../../Container/investment/SHRIRAM/NonTermBasicDetailsContainer";
import { INVESTMENT_ROUTES } from "../../../../Path/investmentRoutes";
import InvestmentProposalContainer from "../../investmentProposalContainer";

function SHRIRAMRoutes() {
  return (
    <Routes>
      <Route
        path={INVESTMENT_ROUTES.PROPOSAL_PAGE}
        element={<InvestmentProposalContainer />}
      >
        <Route
          path={INVESTMENT_ROUTES.BASIC_DETAILS}
          element={<NonTermBasicDetailsContainer />}
        />
      </Route>
    </Routes>
  );
}

export default SHRIRAMRoutes;
