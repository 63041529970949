import React, { useEffect, useState } from "react";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import MedicalDetailCareHeart from "../../../../Page/Desktop/health-php/CARE/MedicalDetails/MedicalDetailCareHeart";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { TCareHeartMedicalQuestion } from "../../../../types/Health/HealthQuotation/TCareHeartMedicalQuestion";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import { HealthSlice } from "../../../../redux/slice/Health/HealthSlice";
import { THealthQuote } from "../../../../types/Health/HealthQuotation/THealthQuote";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/health-php/HealthPropsalServices";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";

const MedicalDetailsCareHeartContainer = () => {
  const isMobile = useIsMobile();
  const {
    INSURED_MEMBER_DETAILS,
    PAGE_STATUS,
    MEDICAL_QUESTION_DATA_CARE_HEART,
    SELECTED_QUOTE_DATA,
    ADD_FORM_RESPONSE,
    Preview_Loader,
  } = useAppSelector((state) => state.Health);
  const [medicalQuestionData, setMedicalQuestionData] =
    useState<TCareHeartMedicalQuestion>(MEDICAL_QUESTION_DATA_CARE_HEART);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [userType, setUserType] = useState<string>("");
  const [utm_medium, setUtm_medium] = useState<string>("");
  const [utm_source, setUtm_source] = useState<string>("");

  useEffect(() => {
    // Access the query parameters directly from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const userTypeFromQuery = searchParams.get("user_type");
    const utm_mediumFromQuery = searchParams.get("utm_medium");
    const utm_sourceFromQuery = searchParams.get("utm_source");
    setUtm_medium(`${utm_mediumFromQuery}`);
    setUtm_source(`${utm_sourceFromQuery}`);
    setUserType(
      atob(`${userTypeFromQuery}`) === "agent" ? btoa("agent") : btoa("user")
    );
  }, []);

  const setMemberDataAsPerUserSelection = () => {
    let setMemberData: any = { ...MEDICAL_QUESTION_DATA_CARE_HEART };
    setMemberData = {
      MQ01: {
        main_question: {
          id: "MQ01",
          icon_class: "tobaco",
          title: "",
          description: "Cancer,Tumor,Polyp or Cyst",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ02: {
        main_question: {
          id: "MQ02",
          icon_class: "tobaco",
          title: "",
          description:
            "HIV/SLE/ Arthiritis/ Scleroderma / Psoriasis/ bleeding or clotting disorders or any other diseases of Blood, Bone marrow/ Immunity or Skin",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ03: {
        main_question: {
          id: "MQ03",
          icon_class: "tobaco",
          title: "",
          description:
            "Asthma / Tuberculosis / COPD/ Pleural effusion / Bronchitis / Emphysema or any other disease of Lungs, Pleura and airway or ",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ04: {
        main_question: {
          id: "MQ04",
          icon_class: "tobaco",
          title: "",
          description:
            "Thyroid disease/ Cushing's disease/ Parathyroid Disease/ Addison's disease / Pituitary tumor/ disease or any other disorder of Endocrine ",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ05: {
        main_question: {
          id: "MQ05",
          icon_class: "tobaco",
          title: "",
          description:
            "Cirrhosis/Hepatitis/Wilson disease/Pancreatitis/Liver, Crohn disease/Ulcerative Colitis/Piles/Gall bladder,Stomach/Intestine/Digestive ",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ06: {
        main_question: {
          id: "MQ06",
          icon_class: "tobaco",
          title: "",
          description:
            "Any other disease / health adversity / injury/ condition / treatment not mentioned above",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Other Dieases Description",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Other Dieases Description",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ07: {
        main_question: {
          id: "MQ07",
          icon_class: "tobaco",
          title: "",
          description:
            "Smoke,consume alcohol,chew tobacco,ghutka or paan or use any recreational drugs? If Yes then please provide the frequency &amp; ",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Hard Liquor- No.of Pegs in 30 ml per week",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Beer-Bottles/ml per week",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Wine-Glasses/ml per week",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Smoking- No.of sticks per day",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Gutka/Pan Masala/Chewing Tobacco etc- Grams per day",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Hard Liquor- No.of Pegs in 30 ml per week",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Beer-Bottles/ml per week",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Wine-Glasses/ml per week",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Smoking- No.of sticks per day",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Gutka/Pan Masala/Chewing Tobacco etc- Grams per day",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ08: {
        main_question: {
          id: "MQ08",
          icon_class: "tobaco",
          title: "",
          description:
            "Have you been advised for any other/repeat procedure or admission? If yes Share details",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
          },
        },
      },
      MQ09: {
        main_question: {
          id: "MQ09",
          icon_class: "tobaco",
          title: "",
          description:
            " Have you undergone any procedure or surgery for any cardiac ailment?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
          },
        },
      },
      MQ10: {
        main_question: {
          id: "MQ10",
          icon_class: "tobaco",
          title: "",
          description:
            "Are you or anyone of your family member 1st blood relationship suffering from any of the following conditions: Downs Syndrome/Turners Syndrome/Sickle Cell Anaemia/ Thalassemia Major/G6PD deficiency",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ11: {
        main_question: {
          id: "MQ11",
          icon_class: "tobaco",
          title: "",
          description:
            "Diabetes Mellitus / High Blood Sugar / Diabetes on Insulin or medication",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
          },
        },
      },
      MQ12: {
        main_question: {
          id: "MQ12",
          icon_class: "tobaco",
          title: "",
          description:
            "Has any of the Proposed to be Insured been hospitalized/recommended to take investigation/medication or has been under any prolonged treatment/undergone surgery for any illness/injury other than for childbirth/minor injuries",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
          },
        },
      },
      MQ13: {
        main_question: {
          id: "MQ13",
          icon_class: "tobaco",
          title: "",
          description:
            "Kidney stone/Renal Failure/ Dialysis/ Chronic Kidney Disease/ Prostate Disease or any other disease of Kidney, Urinary Tract or reproductive organs",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ14: {
        main_question: {
          id: "MQ14",
          icon_class: "tobaco",
          title: "",
          description:
            "Motor Neuron Disease/ Muscular dystrophies/ Myasthenia Gravis or any other disease of Neuromuscular system muscles and/or nervous ",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ15: {
        main_question: {
          id: "MQ15",
          icon_class: "tobaco",
          title: "",
          description:
            "Have you experienced any below mentioned symptoms post undergoing above mentioned surgery/procedure",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Chest heaviness or Pain",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Difficulty in breathing",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Palpitations",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Loss of consciousness",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Weakness or dizziness",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Chest heaviness or Pain",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Difficulty in breathing",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Palpitations",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Loss of consciousness",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Weakness or dizziness",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ16: {
        main_question: {
          id: "MQ16",
          icon_class: "tobaco",
          title: "",
          description:
            "Stroke/Paralysis/TransientIschemicAttack/MultipleSclerosis/Epilepsy/Mental-Psychiatricillness/Parkinson/Alzeihmer/Depression/Dementia/NervousSystem",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ17: {
        main_question: {
          id: "MQ17",
          icon_class: "tobaco",
          title: "",
          description:
            "Disease or disorder of eye, ear, nose or throat except any sight related problems corrected by prescription lenses",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ18: {
        main_question: {
          id: "MQ18",
          icon_class: "tobaco",
          title: "",
          description:
            "Have you ever been diagnosed for any cardiac ailment /disorder?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
          },
        },
      },
      MQ19: {
        main_question: {
          id: "MQ19",
          icon_class: "tobaco",
          title: "",
          description: "Hypertension / High Blood Pressure/ High Cholesterol",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
          },
        },
      },
      MQ20: {
        main_question: {
          id: "MQ20",
          icon_class: "tobaco",
          title: "",
          description:
            "Specify the type of cardiac ailment you have been operated for",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Have you undergone PTCA",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "CABG",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Septal defect surgery-ASD/VSD",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Radiofrequency ablation-RFA",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Others cardiac surgery",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Have you undergone PTCA",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "CABG",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Septal defect surgery-ASD/VSD",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Radiofrequency ablation-RFA",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Others cardiac surgery",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
    };
    setMedicalQuestionData({ ...setMemberData });
  };

  useEffect(() => {
    if (PAGE_STATUS === true || Preview_Loader === true) {
      setMedicalQuestionData(MEDICAL_QUESTION_DATA_CARE_HEART);
    } else {
      setMemberDataAsPerUserSelection();
    }
  }, [MEDICAL_QUESTION_DATA_CARE_HEART]);

  const updateMasterState = (attrName: any, value: any) => {
    let manipulatedMedicalQuestion: any = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );

    if (attrName[0] === "question") {
      manipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question,
            status: value,
          },
        },
      };
      if (value === false) {
        const mainQuestion =
          manipulatedMedicalQuestion[attrName[1]]?.main_question;

        if (mainQuestion && mainQuestion.member_data) {
          Object.keys(mainQuestion.member_data).forEach((member) => {
            const memberData = mainQuestion.member_data[member];

            if (memberData) {
              memberData.selection_status = false;

              if (memberData.field_data) {
                Object.keys(memberData.field_data).forEach((field) => {
                  const fieldData = memberData.field_data[field];

                  if (fieldData && fieldData.value) {
                    fieldData.value.value = "";
                  }
                });
              }
            }
          });
        }
      }

      setMedicalQuestionData(manipulatedMedicalQuestion);
    } else if (attrName[0] === "mainMember") {
      if (medicalQuestionData[attrName[1]].main_question.status) {
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              member_data: {
                ...manipulatedMedicalQuestion[attrName[1]].main_question
                  .member_data,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]].main_question
                    .member_data[attrName[2]],
                  selection_status: value,
                },
              },
            },
          },
        };
        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      }
    } else if (attrName[0] === "mainMemberDataField") {
      if (
        attrName[3] === "adult_one" ||
        (attrName[3] === "adult_two" &&
          manipulatedMedicalQuestion !== undefined)
      ) {
        manipulatedMedicalQuestion[attrName[1]].main_question.member_data[
          attrName[3]
        ].field_data[attrName[2]].value = {
          value: value,
          warning: isEmpty(value),
        };

        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      }
    }
  };

  const validateForm = () => {
    if (!medicalQuestionData) {
      return; // Exit early if medicalQuestionData is null or undefined
    }
    // Deep copy the medicalQuestionData object to avoid state mutations
    let data: TCareHeartMedicalQuestion = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    let hasError = false;
    Object.values(data).forEach((element: any) => {
      const mainQuestion = element?.main_question;
      if (mainQuestion && mainQuestion.status && mainQuestion.member_data) {
        Object.values(mainQuestion.member_data).forEach((memberdata: any) => {
          if (
            memberdata &&
            memberdata.selection_status &&
            memberdata.field_data
          ) {
            Object.values(memberdata.field_data).forEach((field_data: any) => {
              if (field_data && field_data.value?.value === "") {
                let clonedValue = {
                  ...field_data.value,
                  warning: true,
                };

                if (field_data.value) {
                  field_data.value = clonedValue;
                }

                hasError = true;
              }
            });
          }
        });
      }
    });

    setMedicalQuestionData(data);
    if (!hasError) {
      dispatch(HealthSlice.actions.SAVE_MEDICAL_QUESTION_DATA_CARE_HEART(data));

      //api calling
      const onSuccess = (res: any) => {
        if (res.results.error === false) {
          let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
          quoteData = {
            ...quoteData,
            premiumDetails: {
              ...quoteData.premiumDetails,
              finalPremium: res.results.response.premiumDetails.finalPremium,
            },
          };
          dispatch(HealthSlice.actions.SAVE_SELECTED_QUOTE_DATA(quoteData));
        }
      };
      const onError = (err: any) => {
        console.log("err..", err);
      };

      const param = {
        company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
        quote_no: ADD_FORM_RESPONSE.quote_no,
        product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
        product_type_code: SELECTED_QUOTE_DATA.productDetails.product_type_code,

        medical_info: "Yes",

        medicalCancerDiseases:
          data.MQ01?.main_question.status === true ? "Yes" : "No", // Cancer,Tumor,Polyp or Cyst
        medicalCancer:
          data.MQ01?.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        cancer_month_year:
          data?.MQ01?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouse_medicalCancer:
          data.MQ01?.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_cancer_month_year:
          data?.MQ01?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,

        medicalHIVDiseases:
          data.MQ02?.main_question.status === true ? "Yes" : "No", // HIV/SLE/ Arthiritis/ Scleroderma / Psoriasis/ bleeding or clotting disorders or any other diseases of Blood, Bone marrow/ Immunity or Skin
        medicalHIV:
          data.MQ02?.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        hiv_month_year:
          data?.MQ02?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouse_medicalHIV:
          data.MQ02?.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_hiv_month_year:
          data?.MQ02?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,

        medicalRespiratoryDiseases:
          data.MQ03?.main_question.status === true ? "Yes" : "No", //// Asthma / Tuberculosis / COPD/ Pleural effusion / Bronchitis / Emphysema or any other disease of Lungs, Pleura and airway or Respiratory disease
        medicalRespiratory:
          data.MQ03?.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        respiratory_month_year:
          data?.MQ03?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouse_medicalRespiratory:
          data.MQ03?.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_respiratory_month_year:
          data?.MQ03?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,

        medicalThyroidDiseases:
          data.MQ04?.main_question.status === true ? "Yes" : "No", // Thyroid disease/ Cushing's disease/ Parathyroid Disease/ Addison's disease / Pituitary tumor/ disease or any other disorder of Endocrine system
        thyroidDiseases:
          data.MQ04?.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        thyroidDiseases_duration:
          data?.MQ04?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouse_thyroidDiseases:
          data.MQ04?.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_thyroidDiseases_duration:
          data?.MQ04?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,

        medicalLiverDiseases:
          data.MQ05?.main_question.status === true ? "Yes" : "No", // Cirrhosis/Hepatitis/Wilson disease/Pancreatitis/Liver, Crohn disease/Ulcerative Colitis/Piles/Gall bladder,Stomach/Intestine/Digestive system disease
        medicalLiver:
          data.MQ05?.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        liver_month_year:
          data?.MQ05?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouse_medicalLiver:
          data.MQ05?.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_liver_month_year:
          data?.MQ05?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,

        medicalOtherDiseasesDiseases:
          data.MQ06?.main_question.status === true ? "Yes" : "No", // Any other disease / health adversity / injury/ condition / treatment not mentioned above
        medicalOtherDiseases:
          data.MQ06?.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        other_diseases_month_year:
          data?.MQ06?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        other_diseases_desc:
          data?.MQ06?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        spouse_medicalOtherDiseases:
          data.MQ06?.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_other_diseases_month_year:
          data?.MQ06?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouse_other_diseases_desc:
          data?.MQ06?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,

        medicalAlcoholDiseases:
          data.MQ07?.main_question.status === true ? "Yes" : "No", // Smoke,consume alcohol,chew tobacco,ghutka or paan or use any recreational drugs? If Yes then please provide the frequency &amp; amount consumed
        medicalAlcohol:
          data.MQ07?.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        alcohol_week:
          data?.MQ07?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        beer_week:
          data?.MQ07?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        wine_week:
          data?.MQ07?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        smoke_per_day:
          data?.MQ07?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        pan_masala_Quantity:
          data?.MQ07?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,
        spouse_medicalAlcohol:
          data.MQ07?.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_alcohol_week:
          data?.MQ07?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouse_beer_week:
          data?.MQ07?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        spouse_wine_week:
          data?.MQ07?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        spouse_smoke_per_day:
          data?.MQ07?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        spouse_pan_masala_Quantity:
          data?.MQ07?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        medical_repeat_procedure:
          data.MQ08?.main_question.status === true ? "Yes" : "No", // Have you been advised for any other/repeat procedure or admission? If yes Share details
        repeat_procedure:
          data.MQ08?.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_repeat_procedure:
          data.MQ08?.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",

        medical_cardiac_ailment:
          data.MQ09?.main_question.status === true ? "Yes" : "No", // Have you undergone any procedure or surgery for any cardiac ailment?
        surgery_cardiac_ailment:
          data.MQ09?.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_surgery_cardiac_ailment:
          data.MQ09?.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",

        medical_downs_syndrome:
          data.MQ10?.main_question.status === true ? "Yes" : "No", // Are you or anyone of your family member 1st blood relationship suffering from any of the following conditions: Downs Syndrome/Turners Syndrome/Sickle Cell Anaemia/ Thalassemia Major/G6PD deficiency
        downs_syndrome:
          data.MQ10?.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        downs_syndrome_duration:
          data?.MQ10?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouse_downs_syndrome:
          data.MQ10?.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_downs_syndrome_duration:
          data?.MQ10?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,

        medicalDiabetesDiseases:
          data.MQ11?.main_question.status === true ? "Yes" : "No", // Diabetes Mellitus / High Blood Sugar / Diabetes on Insulin or medication
        medicalDiabetes:
          data.MQ11?.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        diabetes_month_year:
          data?.MQ11?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouse_medicalDiabetes:
          data.MQ11?.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_diabetes_month_year:
          data?.MQ11?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,

        medicalHospitalizedDiseases:
          data.MQ12?.main_question.status === true ? "Yes" : "No", // Has any of the Proposed to be Insured been hospitalized/recommended to take investigation/medication or has been under any prolonged treatment/undergone surgery for any illness/injury other than for childbirth/minor injuries
        medicalHospitalized:
          data.MQ12?.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_medicalHospitalized:
          data.MQ12?.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",

        medicalKidneyDiseases:
          data.MQ13?.main_question.status === true ? "Yes" : "No", // Kidney stone/Renal Failure/ Dialysis/ Chronic Kidney Disease/ Prostate Disease or any other disease of Kidney, Urinary Tract or reproductive organs
        medicalKidney:
          data.MQ13?.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        kidney_month_year:
          data?.MQ13?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouse_medicalKidney:
          data.MQ13?.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_kidney_month_year:
          data?.MQ13?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,

        medical_neuron_disease:
          data.MQ14?.main_question.status === true ? "Yes" : "No", // Motor Neuron Disease/ Muscular dystrophies/ Myasthenia Gravis or any other disease of Neuromuscular system muscles and/or nervous system
        m_neuron_disease:
          data.MQ14?.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        m_neuron_disease_duration:
          data?.MQ14?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouse_m_neuron_disease:
          data.MQ14?.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_m_neuron_disease_duration:
          data?.MQ14?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,

        medical_surgery_procedure:
          data.MQ15?.main_question.status === true ? "Yes" : "No", // Have you experienced any below mentioned symptoms post undergoing above mentioned surgery/procedure
        surgery_procedure:
          data.MQ15?.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        chest_heaviness:
          data?.MQ15?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        difficult_brealthing:
          data?.MQ15?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        palpitations:
          data?.MQ15?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        loss_of_consciousness:
          data?.MQ15?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        weakness_or_dizziness:
          data?.MQ15?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,
        spouse_surgery_procedure:
          data.MQ15?.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_chest_heaviness:
          data?.MQ15?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouse_difficult_brealthing:
          data?.MQ15?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        spouse_palpitations:
          data?.MQ15?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        spouse_loss_of_consciousness:
          data?.MQ15?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        spouse_weakness_or_dizziness:
          data?.MQ15?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        medicalParalysisDiseases:
          data.MQ16?.main_question.status === true ? "Yes" : "No", // Stroke/Paralysis/TransientIschemicAttack/MultipleSclerosis/Epilepsy/Mental-Psychiatricillness/Parkinson/Alzeihmer/Depression/Dementia/NervousSystem
        medicalParalysis:
          data.MQ16?.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        paralysis_month_year:
          data?.MQ16?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouse_medicalParalysis:
          data.MQ16?.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_paralysis_month_year:
          data?.MQ16?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,

        medical_disorder_diseases:
          data.MQ17?.main_question.status === true ? "Yes" : "No", // Disease or disorder of eye, ear, nose or throat except any sight related problems corrected by prescription lenses
        disorder_diseases:
          data.MQ17?.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        disorder_diseases_date:
          data?.MQ17?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouse_disorder_diseases:
          data.MQ17?.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_disorder_diseases_date:
          data?.MQ17?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,

        cardiac_disease:
          data.MQ18?.main_question.status === true ? "Yes" : "No", // Have you ever been diagnosed for any cardiac ailment /disorder?
        medicalCardiac:
          data.MQ18?.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_medicalCardiac:
          data.MQ18?.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",

        medicalHypertensionDiseases:
          data.MQ19?.main_question.status === true ? "Yes" : "No", // Hypertension / High Blood Pressure/ High Cholesterol
        medicalHypertension:
          data.MQ19?.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_medicalHypertension:
          data.MQ19?.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",

        cardiac_ailment_disease:
          data.MQ20?.main_question.status === true ? "Yes" : "No",
        cardiac_ailment:
          data.MQ20?.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        ptca: data?.MQ20?.main_question?.member_data?.adult_one?.field_data?.[
          "MQ1-SQ1-F1"
        ].value?.value,
        cabg: data?.MQ20?.main_question?.member_data?.adult_one?.field_data?.[
          "MQ1-SQ1-F2"
        ].value?.value,
        septal_defect:
          data?.MQ20?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        radio_frequency:
          data?.MQ20?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        others_cardiac_surgery:
          data?.MQ20?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,
        spouse_cardiac_ailment:
          data.MQ20?.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_ptca:
          data?.MQ20?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouse_cabg:
          data?.MQ20?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        spouse_septal_defect:
          data?.MQ20?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        spouse_radio_frequency:
          data?.MQ20?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        spouse_others_cardiac_surgery:
          data?.MQ20?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,
      };
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      const cus_type = params.get("cus_type");

      HEALTH_PROPOSAL_SERVICES.getMedicalData(onSuccess, onError, param);
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
      );
    }
  };

  return (
    <>
      {isMobile ? null : (
        <MedicalDetailCareHeart
          medicalQuestionData={medicalQuestionData}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          PAGE_STATUS={PAGE_STATUS}
        />
      )}
    </>
  );
};

export default MedicalDetailsCareHeartContainer;
