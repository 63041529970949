import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import KotakCKYC from "../../../Page/Desktop/CKYC/Kotak/KotakCKYC";
import MKotakCKYC from "../../../Page/Mobile/CKYC/Kotak/MKotakKyc";
import { CAR_ROUTES } from "../../../Router/Path/CarRoutes";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import { TW_ROUTES } from "../../../Router/Path/TWRoutes";
import { ECompanyCode } from "../../../Services/Enum/EHome";
import { CKYCServcies } from "../../../Services/ckyc-node/CKYCServices";
import {
  isEmpty,
  validateFullName,
  validatePAN,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { CLIENTS } from "../../../URLCollection/Clients";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { TVerifyCKYCForm } from "../../../types/CKYC/TCKYC";
import { TDropdown } from "../../../types/Common/TDropdown";
import {
  pushMotorDataLayer,
  sendEventToNativeApp,
} from "../../../utils/analytics";
import { CommonDropdownSlice } from "../../../redux/slice/CommonDropdown/CommonDropdownSlice";
import { TWSlice } from "../../../redux/slice/TwoWheeler/TWSlice";
import { CarSlice } from "../../../redux/slice/Car/CarSlice";
import { HealthSlice } from "../../../redux/slice/Health/HealthSlice";
import { CKYCSlice } from "../../../redux/slice/CKYC/CKYCSlice";

function KotakCKYCContainer() {
  const [loader, setLoader] = useState<boolean>(false);
  const [showManualPopup, setShowManualPopup] = useState<boolean>(false);
  const [showCKYCDetailsPopup, setShowCKYCDetailsPopup] =
    useState<boolean>(false);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const REDUX_HEAD = useAppSelector((state) => state);
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  const { TW, CKYC, Car, Health } = useAppSelector((state) => state);
  const { DISCOUNT_FORM } = useAppSelector((state) => state.Car);
  // const { ADD_FORM_RESPONSE } = useAppSelector((state) => state.Health);
  const [formDetails, setFormDetails] =
    useState<TVerifyCKYCForm>(VERIFY_KYC_FORM);

  const [pageStatus, setPageStatus] = useState<string>("");

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const type = params.get("type");
    if (type) {
      setPageStatus(type);
    }
  }, []);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "full_name") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: !validateFullName(value) },
      }));
    } else if (attrName === "customer_type") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
        dob: { value: "", warning: false },
      }));
    } else {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    }
  };
  useEffect(() => {
    if (DISCOUNT_FORM.vehicle_owned === "Organization") {
      setFormDetails((prev) => ({
        ...prev,
        customer_type: { value: "Organization", warning: false },
      }));
      dispatch(
        CKYCSlice.actions.BULK_UPDATE({
          ...CKYC,
          VERIFY_KYC_FORM: {
            ...formDetails,
            customer_type: { value: "Organization", warning: false },
          },
        })
      );
    }
  }, [DISCOUNT_FORM]);
  useEffect(() => {
    if (!isEmpty(pageStatus)) {
      GET_DROPDOWN_MASTER("POV");
    }
  }, [pageStatus]);

  const GET_DROPDOWN_MASTER = (TYPE: "POI" | "POA" | "POV") => {
    const onSuccess = (res: any) => {
      const results = res.data;
      const error = results.error;

      if (!error) {
        const response = results.data;
        const data: TDropdown[] = [];
        for (const key of response) {
          data.push({ value: key?.doc_code, label: key?.document });
        }

        dispatch(
          CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
            key: TYPE,
            value: data,
          })
        );
      }
    };
    const onError = (error: any) => {};

    switch (pageStatus) {
      case "CAR":
        const car_params = {
          company_code: `${REDUX_HEAD.Car.SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}`,
          list_for: TYPE,
        };
        CKYCServcies.CKYC_DROPDOWN_MASTER(onError, onSuccess, car_params);
        break;
      case "TW":
        const tw_params = {
          company_code: `${REDUX_HEAD.TW.SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}`,
          list_for: TYPE,
        };
        CKYCServcies.CKYC_DROPDOWN_MASTER(onError, onSuccess, tw_params);
        break;
      case "TERM":
        break;
      case "HP":
        const health_params = {
          company_code: `${REDUX_HEAD.Health.SELECTED_QUOTE_DATA.CompanyDetails.company_code}`,
          list_for: TYPE,
        };
        CKYCServcies.CKYC_DROPDOWN_MASTER(onError, onSuccess, health_params);
        break;

      default:
        break;
    }
  };

  const validateForm = () => {
    let data: TVerifyCKYCForm = { ...formDetails };

    data = {
      ...data,
      full_name: {
        ...data.full_name,
        warning: !validateFullName(data.full_name.value),
      },
      dob: { ...data.dob, warning: isEmpty(data.dob.value) },
      doc_type: { ...data.doc_type, warning: isEmpty(data.doc_type.value) },
      doc_id: { ...data.doc_id, warning: isEmpty(data.doc_id.value) },
      customer_type: {
        ...data.customer_type,
        warning: isEmpty(data.customer_type.value),
      },
      gender: { ...data.gender, warning: isEmpty(data.gender.value) },
      section: "premium_details",
    };

    setFormDetails(data);

    let hasError =
      data.full_name.warning ||
      data.doc_type.warning ||
      data.doc_id.warning ||
      data.customer_type.warning ||
      data.dob.warning;

    if (data.customer_type.value !== "Organization") {
      hasError = hasError || data.gender.warning;
    }

    if (!hasError) {
      if (window.location.hostname === CLIENTS.JIO) {
        pushMotorDataLayer(
          "jioinsure_flow_details",
          "ckyc",
          "proceed",
          pageStatus === "TW" ? "two wheeler" : pageStatus?.toLocaleLowerCase(),
          Car.REDIRECTED ? "JFS app" : "Insure website"
        );
        const eventProps = {
          action: "ckyc",
          click: "continue",
          type:
            pageStatus === "TW"
              ? "two wheeler"
              : pageStatus?.toLocaleLowerCase(),
          EntrySource: Car.REDIRECTED ? "JFS app" : "Insure website",
        };
        sendEventToNativeApp("jioinsure_flow_details", eventProps);
      }
      VERIFY_CKYC_DETAILS(data);
    }
  };

  const VERIFY_CKYC_DETAILS = (data: TVerifyCKYCForm) => {
    const toastId = toast.loading("Please wait...");
    const onSuccess = (res: any) => {
      setLoader(false);
      const results = res;

      const error = results.error;
      const response = results?.response;
      if (!error) {
        toast.dismiss(toastId);
        toast.success("Details Fetched.");
        const message = results.message;
        if (message === "No record found") {
          if (window.location.hostname === CLIENTS.JIO) {
            pushMotorDataLayer(
              "jioinsure_flow_details",
              "ckyc details failure",
              `${message}`,
              pageStatus === "TW"
                ? "two wheeler"
                : pageStatus?.toLocaleLowerCase(),
              Car.REDIRECTED ? "JFS app" : "Insure website"
            );
            const eventProps = {
              action: "ckyc",
              status: "ckyc details failure",
              message: `${message}`,
              type:
                pageStatus === "TW"
                  ? "two wheeler"
                  : pageStatus?.toLocaleLowerCase(),
              EntrySource: Car.REDIRECTED ? "JFS app" : "Insure website",
            };
            sendEventToNativeApp("jioinsure_flow_details", eventProps);
          }
          const kycManualURL = response.kycManualURL;
          console.log("hhhggggg", kycManualURL);
          dispatch(
            CKYCSlice.actions.BULK_UPDATE({
              ...CKYC,
              VERIFY_KYC_FORM: { ...data },
              MANUAL_KYC_THIRD_PARTY_URL: kycManualURL,
            })
          );
          if (validatePAN(data.doc_id.value)) {
            dispatch(
              CarSlice.actions.BULK_UPDATE({
                ...Car,
                VEHICLE_DETAILS: {
                  ...Car.VEHICLE_DETAILS,
                  pan_no: { value: data.doc_id.value, warning: false },
                },
              })
            );
            dispatch(
              TWSlice.actions.BULK_UPDATE({
                ...TW,
                VEHICLE_DETAILS: {
                  ...TW.VEHICLE_DETAILS,
                  PAN: { value: data.doc_id.value, warning: false },
                },
              })
            );
          }
          setShowManualPopup(true);
        } else {
          if (window.location.hostname === CLIENTS.JIO) {
            pushMotorDataLayer(
              "jioinsure_flow_details",
              "ckyc details success",
              "na",
              pageStatus === "TW"
                ? "two wheeler"
                : pageStatus?.toLocaleLowerCase(),
              Car.REDIRECTED ? "JFS app" : "Insure website"
            );
            const eventProps = {
              action: "ckyc",
              status: "ckyc details success",
              message: "na",
              type:
                pageStatus === "TW"
                  ? "two wheeler"
                  : pageStatus?.toLocaleLowerCase(),
              EntrySource: Car.REDIRECTED ? "JFS app" : "Insure website",
            };
            sendEventToNativeApp("jioinsure_flow_details", eventProps);
          }
          dispatch(
            CKYCSlice.actions.BULK_UPDATE({
              ...CKYC,
              VERIFY_KYC_FORM: { ...data },
              CKYC_DETAILS: response,
            })
          );
          setShowCKYCDetailsPopup(true);
        }
      } else {
        toast.dismiss(toastId);
        toast.error("Something went wrong.");
        const kycManualURL = response.kycManualURL;
        dispatch(
          CKYCSlice.actions.BULK_UPDATE({
            ...CKYC,
            VERIFY_KYC_FORM: { ...data },
            MANUAL_KYC_THIRD_PARTY_URL: kycManualURL,
          })
        );
        setShowManualPopup(true);
      }
    };

    const onError = (err: any) => {};

    switch (pageStatus) {
      case "TW":
        let tw_dto = {
          quote_no: TW.ADD_FORM_RESPONSE.quote_no,
          cusType: data.customer_type.value,
          kycCusName: data.full_name.value,
          dobForKyc: data.dob.value,
          docNameForKyc: data.doc_type.value,
          docIDNoForKyc: data.doc_id.value,
          genderForKyc: data.gender.value,
          insuranceType: pageStatus,
          companyCode:
            TW.SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code,
          section: "premium_details",
          insurance_type: pageStatus,
          product_code:
            TW?.SELECTED_QUOTE_DATA?.quotationDetail?.productDetails
              ?.product_code,
        };

        CKYCServcies.VERIFY_KOTAK_CKYC(onSuccess, onError, tw_dto);
        break;
      case "CAR":
        let car_dto = {
          quote_no: Car.ADD_FORM_RESPONSE.quote_no,
          cusType: data.customer_type.value,
          kycCusName: data.full_name.value,
          dobForKyc: data.dob.value,
          docNameForKyc: data.doc_type.value,
          docIDNoForKyc: data.doc_id.value,
          genderForKyc: data.gender.value,
          insuranceType: pageStatus,
          companyCode:
            Car.SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails
              .company_code,
          section: "premium_details",
          insurance_type: pageStatus,
          product_code:
            Car?.SELECTED_QUOTE_DATA?.quotationDetail?.productDetails
              ?.product_code,
        };

        CKYCServcies.VERIFY_KOTAK_CKYC(onSuccess, onError, car_dto);
        break;
      case "HP":
        let dto = {
          quote_no: Health.SELECTED_QUOTE_DATA.CompanyDetails.company_code,
          cusType: data.customer_type.value,
          kycCusName: data.full_name.value,
          dobForKyc: data.dob.value,
          docNameForKyc: data.doc_type.value,
          docIDNoForKyc: data.doc_id.value,
          genderForKyc: data.gender.value,
          insuranceType: pageStatus,
          companyCode: Health.SELECTED_QUOTE_DATA.CompanyDetails.company_code,
        };

        CKYCServcies.VERIFY_KOTAK_CKYC(onSuccess, onError, dto);
        break;
      default:
        break;
    }
  };

  const CONTINUE_TO_PROPOSAL = () => {
    switch (pageStatus) {
      case "TW":
        dispatch(
          TWSlice.actions.BULK_UPDATE({
            ...TW,
            OWNER_DETAILS: {
              ...TW.OWNER_DETAILS,
              name: {
                value: CKYC.CKYC_DETAILS.kycCustomerName,
                warning: false,
              },
              gender: {
                value: CKYC.VERIFY_KYC_FORM.gender.value,
                warning: false,
              },
              dob: {
                value: CKYC.CKYC_DETAILS.kycDOB,
                warning: false,
              },
            },
            ADDRESS_DETAILS: {
              ...TW.ADDRESS_DETAILS,
              addressLineOne: {
                value: CKYC.CKYC_DETAILS.kycAddress1,
                warning: false,
              },
              addressLineTwo: {
                value: `${CKYC.CKYC_DETAILS.kycAddress2}, ${CKYC.CKYC_DETAILS.kycAddress3}`,
                warning: false,
              },
              pincode: {
                value: CKYC.CKYC_DETAILS.kycPincode,
                warning: false,
              },
              state: CKYC.CKYC_DETAILS.kycState,
              city: CKYC.CKYC_DETAILS.kycCity,
            },
            VEHICLE_DETAILS: {
              ...TW.VEHICLE_DETAILS,
              PAN: {
                value: validatePAN(CKYC.VERIFY_KYC_FORM.doc_id.value)
                  ? CKYC.VERIFY_KYC_FORM.doc_id.value
                  : "",
                warning: false,
              },
            },
          })
        );

        navigate(
          `${TW_ROUTES.PROPOSAL_PAGE}/${TW_ROUTES.OWNER_DETAILS}?type=TW&companyCode=${TW?.SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}&quote_no=${TW?.ADD_FORM_RESPONSE.quote_no}&product_id=${TW?.SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id}`
        );
        break;
      case "CAR":
        dispatch(
          CarSlice.actions.BULK_UPDATE({
            ...Car,
            OWNER_DETAILS: {
              ...Car.OWNER_DETAILS,
              name: {
                value: CKYC.CKYC_DETAILS.kycCustomerName,
                warning: false,
              },
              gender: {
                value: CKYC.VERIFY_KYC_FORM.gender.value,
                warning: false,
              },
              dob: {
                value: CKYC.CKYC_DETAILS.kycDOB,
                warning: false,
              },
              mobile: {
                value: Car.ADD_FORM.mobile.value,
                warning: false,
              },
            },
            ADDRESS_DETAILS: {
              ...Car.ADDRESS_DETAILS,
              addressLineOne: {
                value: CKYC.CKYC_DETAILS.kycAddress1,
                warning: false,
              },
              addressLineTwo: {
                value: `${CKYC.CKYC_DETAILS.kycAddress2}, ${CKYC.CKYC_DETAILS.kycAddress3}`,
                warning: false,
              },
              pincode: {
                value: CKYC.CKYC_DETAILS.kycPincode,
                warning: false,
              },
              state: CKYC.CKYC_DETAILS.kycState,
              city: CKYC.CKYC_DETAILS.kycCity,
            },
          })
        );
        navigate(
          `${CAR_ROUTES.PROPOSAL_PAGE}/${CAR_ROUTES.OWNER_DETAILS}?type=CAR&companyCode=${Car?.SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}&quote_no=${Car?.ADD_FORM_RESPONSE.quote_no}&product_id=${Car?.SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id}`
        );
        break;
      case "HP":
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            INSURED_MEMBER_DETAILS: {
              ...Health.INSURED_MEMBER_DETAILS,
              PROPOSER_DETAILS: {
                ...Health.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS,
                name: {
                  value: CKYC.CKYC_DETAILS.kycCustomerName,
                  warning: false,
                },
                dob: {
                  value: CKYC.CKYC_DETAILS.kycDOB,
                  warning: false,
                },
                mobile: {
                  value: CKYC.CKYC_DETAILS.kycMobile,
                  warning: false,
                },
                emergencyPhone: { value: "", warning: false },
                email: { value: CKYC.CKYC_DETAILS.kycEmail, warning: false },
                aadhar: { value: "", warning: false },
                gender: {
                  value: VERIFY_KYC_FORM.gender.value,
                  warning: false,
                },
                maritalStatus: { value: "", warning: false },
                weight: { value: "", warning: false },
                heightFeet: { value: "", warning: false },
                heightInches: { value: "", warning: false },
                occupation: { value: "", warning: false },
                relationship: { value: "", warning: false },
                proposer_name: { value: "", warning: false },
              },
            },
            ADDRESS_DETAILS: {
              ...Health.ADDRESS_DETAILS,
              pincode: {
                value: CKYC.CKYC_DETAILS.kycPincode,
                warning: false,
              },
              city: CKYC.CKYC_DETAILS.kycCity,
              state: CKYC.CKYC_DETAILS.kycState,
              addressLineOne: {
                value: CKYC.CKYC_DETAILS.kycAddress1,
                warning: false,
              },
              addressLineTwo: {
                value: CKYC.CKYC_DETAILS.kycAddress2,
                warning: false,
              },
              isMailingAddressSame: true,
              mailingPinCode: { value: "", warning: false },
              mailingCity: "",
              mailingState: "",
              mailingAddressLineOne: { value: "", warning: false },
              mailingAddressLineTwo: { value: "", warning: false },
            },
          })
        );
        if (
          Health.SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
          ECompanyCode.KOTAK
        ) {
          navigate(
            // `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}`
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADDON_DETAILS}?type=HP&companyCode=${Health?.SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${Health?.ADD_FORM_RESPONSE.quote_no}&product_id=${Health?.SELECTED_QUOTE_DATA?.productDetails.id}`
          );
        } else {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}?type=HP&companyCode=${Health?.SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${Health?.ADD_FORM_RESPONSE.quote_no}&product_id=${Health?.SELECTED_QUOTE_DATA?.productDetails.id}`
          );
        }

        break;
      default:
        break;
    }
  };

  return (
    <div>
      {isMobile ? (
        <MKotakCKYC
          updateMasterState={updateMasterState}
          formDetails={formDetails}
          validateForm={validateForm}
          CONTINUE_TO_PROPOSAL={CONTINUE_TO_PROPOSAL}
          setShowCKYCDetailsPopup={setShowCKYCDetailsPopup}
          setShowManualPopup={setShowManualPopup}
          showCKYCDetailsPopup={showCKYCDetailsPopup}
          showManualPopup={showManualPopup}
          isLoading={loader}
        />
      ) : (
        <KotakCKYC
          updateMasterState={updateMasterState}
          formDetails={formDetails}
          validateForm={validateForm}
          CONTINUE_TO_PROPOSAL={CONTINUE_TO_PROPOSAL}
          setShowCKYCDetailsPopup={setShowCKYCDetailsPopup}
          setShowManualPopup={setShowManualPopup}
          showCKYCDetailsPopup={showCKYCDetailsPopup}
          showManualPopup={showManualPopup}
          isLoading={loader}
        />
      )}
    </div>
  );
}

export default KotakCKYCContainer;
