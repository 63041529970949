import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MedicalDetailKotak from "../../../../Page/Desktop/health-php/Kotak/MedicalDetailKotak/MedicalDetailKotak";
import MMedicalDetailKotak from "../../../../Page/Mobile/health-php/Kotak/MMedicalDetailKotak/MMedicalDetailKotak";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/health-php/HealthPropsalServices";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { HealthSlice } from "../../../../redux/slice/Health/HealthSlice";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { THealthQuote } from "../../../../types/Health/HealthQuotation/THealthQuote";
import { TKotakMedicalQuestion } from "../../../../types/Health/HealthQuotation/TKotakMedicalQuestion";

const MedicalDetailContainer = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    PAGE_STATUS,
    MEDICAL_QUESTION_DATA_KOTAK,
    SELECTED_QUOTE_DATA,
    ADD_FORM_RESPONSE,
    Preview_Loader,
    INSURED_MEMBER_DETAILS,
  } = useAppSelector((state) => state.Health);
  const [userType, setUserType] = useState<string>("");
  const [utm_medium, setUtm_medium] = useState<string>("");
  const [utm_source, setUtm_source] = useState<string>("");

  useEffect(() => {
    // Access the query parameters directly from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const userTypeFromQuery = searchParams.get("user_type");
    const utm_mediumFromQuery = searchParams.get("utm_medium");
    const utm_sourceFromQuery = searchParams.get("utm_source");
    setUtm_medium(`${utm_mediumFromQuery}`);
    setUtm_source(`${utm_sourceFromQuery}`);
    setUserType(
      atob(`${userTypeFromQuery}`) === "agent" ? btoa("agent") : btoa("user")
    );
  }, []);
  const [medicalQuestionData, setMedicalQuestionData] =
    useState<TKotakMedicalQuestion>(MEDICAL_QUESTION_DATA_KOTAK);

  const setMemberDataAsPerUserSelection = () => {
    let setMemberData: any = { ...MEDICAL_QUESTION_DATA_KOTAK };
    setMemberData = {
      MQ01: {
        main_question: {
          id: "MQ01",
          icon_class: "tobaco",
          title: "Good Health",
          description:
            "Are you currently in good health and not undergoing any treatment or medication for any illness/medical condition (Physical, Psychiatric, Mental illness /disorders, Sleep disorders)?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
          },
        },
      },
      MQ02: {
        main_question: {
          id: "MQ02",
          icon_class: "tobaco",
          title: "Pre-Existing Diseases",
          description:
            "Does any person(s) to be insured has any Pre-Existing diseases?",
          status: false,
          sub_question: {
            "MQ1-SQ1": {
              id: "MQ1-SQ1",
              icon_class: "",
              status: false,
              title: "Infections / Allergies?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ2-SQ2": {
              id: "MQ2-SQ2",
              icon_class: "",
              status: false,
              title: "Are you suffering from HIV/AIDS?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ3-SQ3": {
              id: "MQ3-SQ3",
              icon_class: "",
              status: false,
              title: "Are you suffering from Cancer/Tumor/Cyst?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ4-SQ4": {
              id: "MQ4-SQ4",
              icon_class: "",
              status: false,
              title: "Are you suffering from Mental psychiatric Disorders?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ5-SQ5": {
              id: "MQ5-SQ5",
              icon_class: "",
              status: false,
              title: "Disorders of the Eyes/Ears/Nose/throat/?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ6-SQ6": {
              id: "MQ6-SQ6",
              icon_class: "",
              status: false,
              title:
                "Are you suffering from circulatory/Heart/hypertension diseases?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ7-SQ7": {
              id: "MQ7-SQ7",
              icon_class: "",
              status: false,
              title: "Any Respiratory Infections/Diseases/Asthma?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ8-SQ8": {
              id: "MQ8-SQ8",
              icon_class: "",
              status: false,
              title:
                "Are you suffering from Bones/Joints/Spondylitis/arthritis etc?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ9-SQ9": {
              id: "MQ9-SQ9",
              icon_class: "",
              status: false,
              title:
                "Are you suffering from Stomach/Intestines/liver/appendix Diseases?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ10-SQ10": {
              id: "MQ10-SQ10",
              icon_class: "",
              status: false,
              title: "Are you suffering from Kidney/urinary Disease?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ11-SQ11": {
              id: "MQ11-SQ11",
              icon_class: "",
              status: false,
              title:
                "Pregnant/Gynaecological Disorders/any disorder of Prostrate?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ12-SQ12": {
              id: "MQ12-SQ12",
              icon_class: "",
              status: false,
              title: "Any Birth Defects?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ13-SQ13": {
              id: "MQ13-SQ13",
              icon_class: "",
              status: false,
              title:
                "Have you undergone any medical test or health check up in the past 6 month if yes then please mention if any abnormal result detected",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ14-SQ14": {
              id: "MQ14-SQ14",
              icon_class: "",
              status: false,
              title: "Any other illness/ Injury details",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
          },
        },
      },
      MQ03: {
        main_question: {
          id: "MQ03",
          icon_class: "tobaco",
          title: "",
          description:
            "Are you currently suffering or previously suffered from any illness and on continuous medication for same and duration since on treatment?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ04: {
        main_question: {
          id: "MQ04",
          icon_class: "tobaco",
          title: "",
          description:
            "Are you currently suffering or previously suffered from High BP, Cholestero and on continuous medication for same?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ05: {
        main_question: {
          id: "MQ05",
          icon_class: "tobaco",
          title: "",
          description:
            "Diabetes Mellitus type 1 or Diabetes on insulin or Diabetes associated with blindness or chronic foot ulcer?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ06: {
        main_question: {
          id: "MQ06",
          icon_class: "tobaco",
          title: "Hospitalized",
          description:
            "Has any of the new person(s) to be insured been diagnosed / hospitalized for any illness / injury during the last 48 months?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Duration",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Duration",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Duration",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Duration",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Duration",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Duration",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ07: {
        main_question: {
          id: "MQ07",
          icon_class: "tobaco",
          title: "Accidents/burns?",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ08: {
        main_question: {
          id: "MQ08",
          icon_class: "tobaco",
          title: "Smoking",
          description: "Do you Smoke/Tobacco consumption?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "No of Years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "No of Years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "No of Years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "No of Years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "No of Years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "No of Years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ09: {
        main_question: {
          id: "MQ09",
          icon_class: "tobaco",
          title: "Alcohol",
          description:
            "Do you consume Alcohol(Beer - No. of Pints/week, Wine & Spirit - ml/week)?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "No of Years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "No of Years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "No of Years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "No of Years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "No of Years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "No of Years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ10: {
        main_question: {
          id: "MQ10",
          icon_class: "tobaco",
          title: "Refused/being accepted on special terms",
          description:
            "Have you or any person proposed to be insured under the policy has been refused insurance cover by any insurance company or being accepted on special terms?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
          },
        },
      },
    };
    setMedicalQuestionData({ ...setMemberData });
  };
  useEffect(() => {
    if (PAGE_STATUS === true || Preview_Loader === true) {
      setMedicalQuestionData(MEDICAL_QUESTION_DATA_KOTAK);
    } else {
      setMemberDataAsPerUserSelection();
    }
  }, [MEDICAL_QUESTION_DATA_KOTAK]);
  let flag = false;
  const updateMasterState = (attrName: any, value: any) => {
    flag = value;

    // console.log('sss',flag)
    console.log(attrName, "attrName", value);
    let manipulatedMedicalQuestion: any = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    if (attrName[0] === "question") {
      manipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question,
            status: value,
          },
        },
      };
      // if (value === false) {
      //   // Update selection_status of members
      //   manipulatedMedicalQuestion[attrName[1]].main_question.member_data = {
      //     ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data,
      //     adult_one: {
      //       ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
      //         .adult_one,
      //       selection_status: false,
      //     },
      //     adult_two: {
      //       ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
      //         .adult_two,
      //       selection_status: false,
      //     },
      //     child_one: {
      //       ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
      //         .child_one,
      //       selection_status: false,
      //     },
      //     child_two: {
      //       ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
      //         .child_two,
      //       selection_status: false,
      //     },
      //     child_three: {
      //       ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
      //         .child_three,
      //       selection_status: false,
      //     },
      //     child_four: {
      //       ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
      //         .child_four,
      //       selection_status: false,
      //     },
      //   };
      // }

      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    } else if (attrName[0] === "subQuestion") {
      // setchange_status(flag);

      let updatedSubQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question,
        [attrName[2]]: {
          ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
            attrName[2]
          ],
          status: value,
        },
      };
      let updatedMainQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question,
        sub_question: updatedSubQuestion,
      };

      let updatedManipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: updatedMainQuestion,
        },
      };

      setMedicalQuestionData(updatedManipulatedMedicalQuestion);
    } else if (attrName[0] === "mainMember") {
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question.sub_question[
          attrName[2]
        ]?.member_data[attrName[3]] !== undefined
      ) {
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              sub_question: {
                ...manipulatedMedicalQuestion[attrName[1]].main_question
                  .sub_question,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    .sub_question?.[attrName[2]],
                  member_data: {
                    ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                      .sub_question?.[attrName[2]].member_data,
                    [attrName[3]]: {
                      ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                        .sub_question?.[attrName[2]].member_data?.[attrName[3]],
                      selection_status: value,
                    },
                  },
                },
              },
            },
          },
        };
        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      }
    } else if (attrName[0] === "mainMemberData") {
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data[
          attrName[2]
        ] !== undefined
      )
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              member_data: {
                ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                  .member_data,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    ?.member_data?.[attrName[2]],
                  selection_status: value,
                },
              },
            },
          },
        };

      setMedicalQuestionData(manipulatedMedicalQuestion);
    } else if (
      attrName[0] === "mainMemberDataField" &&
      attrName[1] === "MQ02"
    ) {
      if (
        attrName[2] === "adult_one" ||
        attrName[2] === "adult_two" ||
        attrName[2] === "child_one" ||
        attrName[2] === "child_two" ||
        attrName[2] === "child_three" ||
        (attrName[2] === "child_four" &&
          manipulatedMedicalQuestion !== undefined)
      ) {
        manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
          attrName[4]
        ].member_data[attrName[2]].field_data[attrName[3]].value = {
          value: value,
          warning: isEmpty(value),
        };

        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      }
    } else if (
      attrName[0] === "mainMemberDataField" &&
      attrName[1] !== "MQ02"
    ) {
      if (
        attrName[2] === "adult_one" ||
        attrName[2] === "adult_two" ||
        attrName[2] === "child_one" ||
        attrName[2] === "child_two" ||
        attrName[2] === "child_three" ||
        (attrName[2] === "child_four" &&
          manipulatedMedicalQuestion !== undefined)
      ) {
      }

      manipulatedMedicalQuestion[attrName[1]].main_question.member_data[
        attrName[2]
      ].field_data[attrName[3]].value = {
        value: value,
        warning: isEmpty(value),
      };

      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    }
  };
  const validateForm = () => {
    if (!medicalQuestionData) {
      return; // Exit early if medicalQuestionData is null or undefined
    }
    let data: TKotakMedicalQuestion = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    let hasError = false;

    Object.values(data).forEach((element) => {
      const mainQuestion = element?.main_question;
      if (mainQuestion && mainQuestion.status) {
        const memberData = mainQuestion.member_data;
        if (memberData) {
          Object.values(memberData).forEach((member) => {
            if (member.selection_status && member.field_data) {
              Object.values(member.field_data).forEach((field) => {
                if (field.value?.value === "") {
                  field.value = {
                    ...field.value,
                    warning: true,
                  };
                  hasError = true;
                }
              });
            }
          });
        }
      }
    });
    setMedicalQuestionData(data);
    console.log("hasError", hasError);
    if (!hasError) {
      dispatch(HealthSlice.actions.SAVE_MEDICAL_QUESTION_DATA_KOTAK(data));
      const onSuccess = (res: any) => {
        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
        if (res.results.error === false) {
          let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
          quoteData = {
            ...quoteData,
            premiumDetails: {
              ...quoteData.premiumDetails,
              finalPremium: res.results.response.premiumDetails.finalPremium,
            },
          };
          dispatch(HealthSlice.actions.SAVE_SELECTED_QUOTE_DATA(quoteData));
        }
      };
      const onError = (err: any) => {
        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
        console.log("err..", err);
      };
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
      const param = {
        quote_no: ADD_FORM_RESPONSE.quote_no,
        company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
        product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
        medical_info: "Yes",
        medicalMedicationDiseases:
          data.MQ01.main_question.status === true ? "Yes" : "No", //Are you currently in good mental and physical health
        medicalMedication:
          data.MQ01.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_medicalMedication:
          data.MQ01.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child1_medicalMedication:
          data.MQ01.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",
        child2_medicalMedication:
          data.MQ01.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child3_medicalMedication:
          data.MQ01.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        medicalSufferDiseasesDiseases:
          data.MQ03.main_question.status === true ? "Yes" : "No", // Are you currently suffering or previously suffered from any illness and on continuous medication for same
        medicalSufferDiseases:
          data.MQ03.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        sufferdiseases_month_year:
          data?.MQ03?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        spouse_medicalSufferDiseases:
          data.MQ03.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_sufferdiseases_month_year:
          data?.MQ03?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child1_medicalSufferDiseases:
          data.MQ03.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",
        child1_sufferdiseases_month_year:
          data?.MQ03?.main_question?.member_data?.child_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child2_medicalSufferDiseases:
          data.MQ03.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child2_sufferdiseases_month_year:
          data?.MQ03?.main_question?.member_data?.child_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child3_medicalSufferDiseases:
          data.MQ03.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child3_sufferdiseases_month_year:
          data?.MQ03?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        medicalDiabetes: data.MQ05.main_question.status === true ? "Yes" : "No", //Diabetes Mellitus: If Yes provide duration ,type I or II and name of medication
        medicalDiabetesDiseases:
          data.MQ05.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        diabetes_month_year:
          data?.MQ05?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        spouse_medicalDiabetes:
          data.MQ05.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_diabetes_month_year:
          data?.MQ05?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child1_medicalDiabetes:
          data.MQ05.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",
        child1_diabetes_month_year:
          data?.MQ05?.main_question?.member_data?.child_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child2_medicalDiabetes:
          data.MQ05.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child2_diabetes_month_year:
          data?.MQ05?.main_question?.member_data?.child_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child3_medicalDiabetes:
          data.MQ05.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child3_diabetes_month_year:
          data?.MQ05?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        medicalHighBPCholesteroDiseases:
          data.MQ04.main_question.status === true ? "Yes" : "No", // High BP, Cholesterol: If Yes since when and medication being taken
        medicalHighBPCholestero:
          data.MQ04.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        highbpcholestero_month_year:
          data?.MQ04?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        spouse_medicalHighBPCholestero:
          data.MQ04.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_highbpcholestero_month_year:
          data?.MQ04?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child1_medicalHighBPCholestero:
          data.MQ04.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",
        child1_highbpcholestero_month_year:
          data?.MQ04?.main_question?.member_data?.child_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child2_medicalHighBPCholestero:
          data.MQ04.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child2_highbpcholestero_month_year:
          data?.MQ04?.main_question?.member_data?.child_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child3_medicalHighBPCholestero:
          data.MQ04.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child3_highbpcholestero_month_year:
          data?.MQ04?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        medicalPreExisting:
          data.MQ02.main_question.status === true ? "Yes" : "No", //Does any person(s) to be insured has any Pre-Existing diseases?
        medicalHealthCheckupDiseases:
          data.MQ02.main_question.sub_question?.["MQ13-SQ13"].status === true
            ? "Yes"
            : "No", // Have you undergone any medical test or health check up in the past 6 month if yes then please mention if any abnormal result detected
        medicalHealthCheckup:
          data.MQ02.main_question.sub_question?.["MQ13-SQ13"].member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        healthcheckup_desc:
          data.MQ02.main_question.sub_question?.["MQ13-SQ13"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_medicalHealthCheckup:
          data.MQ02.main_question.sub_question?.["MQ13-SQ13"].member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_healthcheckup_desc:
          data.MQ02.main_question.sub_question?.["MQ13-SQ13"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_medicalHealthCheckup:
          data.MQ02.main_question.sub_question?.["MQ13-SQ13"].member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_healthcheckup_desc:
          data.MQ02.main_question.sub_question?.["MQ13-SQ13"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_medicalHealthCheckup:
          data.MQ02.main_question.sub_question?.["MQ13-SQ13"].member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_healthcheckup_desc:
          data.MQ02.main_question.sub_question?.["MQ13-SQ13"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_medicalHealthCheckup:
          data.MQ02.main_question.sub_question?.["MQ13-SQ13"].member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_healthcheckup_desc:
          data.MQ02.main_question.sub_question?.["MQ13-SQ13"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        medicalHospitalizedDiseases:
          data.MQ06.main_question.status === true ? "Yes" : "No", //Any hospitalization in the past Yes / No
        medicalHospitalized:
          data.MQ06.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        hospitalized_duration:
          data?.MQ06?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        spouse_medicalHospitalized:
          data.MQ06.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_hospitalized_duration:
          data?.MQ06?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child1_medicalHospitalized:
          data.MQ06.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",
        child1_hospitalized_duration:
          data?.MQ06?.main_question?.member_data?.child_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child2_medicalHospitalized:
          data.MQ06.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child2_hospitalized_duration:
          data?.MQ06?.main_question?.member_data?.child_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child3_medicalHospitalized:
          data.MQ06.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child3_hospitalized_duration:
          data?.MQ06?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        medicalParalysisDiseases:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].status === true
            ? "Yes"
            : "No", //Infections / Allergies
        medicalParalysis:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        paralysis_month_year:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_medicalParalysis:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_paralysis_month_year:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_medicalParalysis:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_paralysis_month_year:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_medicalParalysis:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_paralysis_month_year:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_medicalParalysis:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_paralysis_month_year:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        medicalHIVDiseases:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].status === true
            ? "Yes"
            : "No", // HIV / AIDs
        medicalHIV:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        hiv_month_year:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_medicalHIV:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_hiv_month_year:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_medicalHIV:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_hiv_month_year:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_medicalHIV:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_hiv_month_year:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_medicalHIV:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_hiv_month_year:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        medicalCancerDiseases:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].status === true
            ? "Yes"
            : "No", //Cancer / Tumor / Cyst
        medicalCancer:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        cancer_month_year:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_medicalCancer:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_cancer_month_year:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_medicalCancer:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_cancer_month_year:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_medicalCancer:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_cancer_month_year:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_medicalCancer:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_cancer_month_year:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        medicalCongenitalDiseases:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].status === true
            ? "Yes"
            : "No", //Mental psychiatric Disorders
        medicalCongenital:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        congenital_month_year:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_medicalCongenital:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_congenital_month_year:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_medicalCongenital:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_congenital_month_year:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_medicalCongenital:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_congenital_month_year:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_medicalCongenital:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_congenital_month_year:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        medicalProlongedDiseases:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].status === true
            ? "Yes"
            : "No", //Disorders of the Eyes / Ears / Nose / throat/
        medicalProlonged:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        prolonged_month_year:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_medicalProlonged:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_prolonged_month_year:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_medicalProlonged:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_prolonged_month_year:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_medicalProlonged:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_prolonged_month_year:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_medicalProlonged:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_prolonged_month_year:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        medicalHypertensionDiseases:
          data.MQ02.main_question.sub_question?.["MQ6-SQ6"].status === true
            ? "Yes"
            : "No", //Are you suffering from circulatory/Heart/hypertension diseases?
        medicalHypertension:
          data.MQ02.main_question.sub_question?.["MQ6-SQ6"].member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        hypertension_month_year:
          data.MQ02.main_question.sub_question?.["MQ6-SQ6"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_medicalHypertension:
          data.MQ02.main_question.sub_question?.["MQ6-SQ6"].member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_hypertension_month_year:
          data.MQ02.main_question.sub_question?.["MQ6-SQ6"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_medicalHypertension:
          data.MQ02.main_question.sub_question?.["MQ6-SQ6"].member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_hypertension_month_year:
          data.MQ02.main_question.sub_question?.["MQ6-SQ6"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_medicalHypertension:
          data.MQ02.main_question.sub_question?.["MQ6-SQ6"].member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_hypertension_month_year:
          data.MQ02.main_question.sub_question?.["MQ6-SQ6"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_medicalHypertension:
          data.MQ02.main_question.sub_question?.["MQ6-SQ6"].member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_hypertension_month_year:
          data.MQ02.main_question.sub_question?.["MQ6-SQ6"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        medicalRespiratoryDiseases:
          data.MQ02.main_question.sub_question?.["MQ7-SQ7"].status === true
            ? "Yes"
            : "No", //Respiratory Infections and Diseases, Asthma
        medicalRespiratory:
          data.MQ02.main_question.sub_question?.["MQ7-SQ7"].member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        respiratory_month_year:
          data.MQ02.main_question.sub_question?.["MQ7-SQ7"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_medicalRespiratory:
          data.MQ02.main_question.sub_question?.["MQ7-SQ7"].member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_respiratory_month_year:
          data.MQ02.main_question.sub_question?.["MQ7-SQ7"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_medicalRespiratory:
          data.MQ02.main_question.sub_question?.["MQ7-SQ7"].member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_respiratory_month_year:
          data.MQ02.main_question.sub_question?.["MQ7-SQ7"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_medicalRespiratory:
          data.MQ02.main_question.sub_question?.["MQ7-SQ7"].member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_respiratory_month_year:
          data.MQ02.main_question.sub_question?.["MQ7-SQ7"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_medicalRespiratory:
          data.MQ02.main_question.sub_question?.["MQ7-SQ7"].member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_respiratory_month_year:
          data.MQ02.main_question.sub_question?.["MQ7-SQ7"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        medicalJointDiseases:
          data.MQ02.main_question.sub_question?.["MQ8-SQ8"].status === true
            ? "Yes"
            : "No", //Bones and Joints, Spondylitis / arthritis etc
        medicalJoint:
          data.MQ02.main_question.sub_question?.["MQ8-SQ8"].member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        joint_month_year:
          data.MQ02.main_question.sub_question?.["MQ8-SQ8"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_medicalJoint:
          data.MQ02.main_question.sub_question?.["MQ8-SQ8"].member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_joint_month_year:
          data.MQ02.main_question.sub_question?.["MQ8-SQ8"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_medicalJoint:
          data.MQ02.main_question.sub_question?.["MQ8-SQ8"].member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_joint_month_year:
          data.MQ02.main_question.sub_question?.["MQ8-SQ8"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_medicalJoint:
          data.MQ02.main_question.sub_question?.["MQ8-SQ8"].member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_joint_month_year:
          data.MQ02.main_question.sub_question?.["MQ8-SQ8"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_medicalJoint:
          data.MQ02.main_question.sub_question?.["MQ8-SQ8"].member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_joint_month_year:
          data.MQ02.main_question.sub_question?.["MQ8-SQ8"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        medicalLiverDiseases:
          data.MQ02.main_question.sub_question?.["MQ9-SQ9"].status === true
            ? "Yes"
            : "No", //Diseases of Stomach, Intestines, liver, appendix
        medicalLiver:
          data.MQ02.main_question.sub_question?.["MQ9-SQ9"].member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        liver_month_year:
          data.MQ02.main_question.sub_question?.["MQ9-SQ9"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_medicalLiver:
          data.MQ02.main_question.sub_question?.["MQ9-SQ9"].member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_liver_month_year:
          data.MQ02.main_question.sub_question?.["MQ9-SQ9"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_medicalLiver:
          data.MQ02.main_question.sub_question?.["MQ9-SQ9"].member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_liver_month_year:
          data.MQ02.main_question.sub_question?.["MQ9-SQ9"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_medicalLiver:
          data.MQ02.main_question.sub_question?.["MQ9-SQ9"].member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_liver_month_year:
          data.MQ02.main_question.sub_question?.["MQ9-SQ9"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_medicalLiver:
          data.MQ02.main_question.sub_question?.["MQ9-SQ9"].member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_liver_month_year:
          data.MQ02.main_question.sub_question?.["MQ9-SQ9"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        medicalKidneyDiseases:
          data.MQ02.main_question.sub_question?.["MQ10-SQ10"].status === true
            ? "Yes"
            : "No", //Kidney and urinary system
        medicalKidney:
          data.MQ02.main_question.sub_question?.["MQ10-SQ10"].member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        kidney_month_year:
          data.MQ02.main_question.sub_question?.["MQ10-SQ10"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_medicalKidney:
          data.MQ02.main_question.sub_question?.["MQ10-SQ10"].member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_kidney_month_year:
          data.MQ02.main_question.sub_question?.["MQ10-SQ10"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_medicalKidney:
          data.MQ02.main_question.sub_question?.["MQ10-SQ10"].member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_kidney_month_year:
          data.MQ02.main_question.sub_question?.["MQ10-SQ10"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_medicalKidney:
          data.MQ02.main_question.sub_question?.["MQ10-SQ10"].member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_kidney_month_year:
          data.MQ02.main_question.sub_question?.["MQ10-SQ10"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_medicalKidney:
          data.MQ02.main_question.sub_question?.["MQ10-SQ10"].member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_kidney_month_year:
          data.MQ02.main_question.sub_question?.["MQ10-SQ10"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        medicalGynaecologicalDiseases:
          data.MQ02.main_question.sub_question?.["MQ11-SQ11"].status === true
            ? "Yes"
            : "No", //Pregnant / Gynaecological Disorders / any disorder of Prostrate
        medicalGynaecological:
          data.MQ02.main_question.sub_question?.["MQ11-SQ11"].member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        gynaecological_month_year:
          data.MQ02.main_question.sub_question?.["MQ11-SQ11"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_medicalGynaecological:
          data.MQ02.main_question.sub_question?.["MQ11-SQ11"].member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_gynaecological_month_year:
          data.MQ02.main_question.sub_question?.["MQ11-SQ11"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_medicalGynaecological:
          data.MQ02.main_question.sub_question?.["MQ11-SQ11"].member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_gynaecological_month_year:
          data.MQ02.main_question.sub_question?.["MQ11-SQ11"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_medicalGynaecological:
          data.MQ02.main_question.sub_question?.["MQ11-SQ11"].member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_gynaecological_month_year:
          data.MQ02.main_question.sub_question?.["MQ11-SQ11"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_medicalGynaecological:
          data.MQ02.main_question.sub_question?.["MQ11-SQ11"].member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_gynaecological_month_year:
          data.MQ02.main_question.sub_question?.["MQ11-SQ11"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        medicalBirthDefectDiseases:
          data.MQ02.main_question.sub_question?.["MQ12-SQ12"].status === true
            ? "Yes"
            : "No", // Birth Defects
        medicalBirthDefect:
          data.MQ02.main_question.sub_question?.["MQ12-SQ12"].member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        birthdefect_desc:
          data.MQ02.main_question.sub_question?.["MQ12-SQ12"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_medicalBirthDefect:
          data.MQ02.main_question.sub_question?.["MQ12-SQ12"].member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_birthdefect_desc:
          data.MQ02.main_question.sub_question?.["MQ12-SQ12"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_medicalBirthDefect:
          data.MQ02.main_question.sub_question?.["MQ12-SQ12"].member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_birthdefect_desc:
          data.MQ02.main_question.sub_question?.["MQ12-SQ12"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_medicalBirthDefect:
          data.MQ02.main_question.sub_question?.["MQ12-SQ12"].member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_birthdefect_desc:
          data.MQ02.main_question.sub_question?.["MQ12-SQ12"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_medicalBirthDefect:
          data.MQ02.main_question.sub_question?.["MQ12-SQ12"].member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_birthdefect_desc:
          data.MQ02.main_question.sub_question?.["MQ12-SQ12"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        medicalAccidentBurnDiseases:
          data.MQ07.main_question.status === true ? "Yes" : "No", // Accidents / burns
        medicalAccidentBurn:
          data.MQ07.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        accidentburn_month_year:
          data?.MQ07?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        spouse_medicalAccidentBurn:
          data.MQ07.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_accidentburn_month_year:
          data?.MQ07?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child1_medicalAccidentBurn:
          data.MQ07.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",
        child1_accidentburn_month_year:
          data?.MQ07?.main_question?.member_data?.child_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child2_medicalAccidentBurn:
          data.MQ07.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child2_accidentburn_month_year:
          data?.MQ07?.main_question?.member_data?.child_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child3_medicalAccidentBurn:
          data.MQ07.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child3_accidentburn_month_year:
          data?.MQ07?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        medicalOtherSubstanceDiseases:
          data.MQ08.main_question.status === true ? "Yes" : "No", //Smoking / Tobacco consumption
        medicalOtherSubstance:
          data.MQ08.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        OtherSubstanceQuantity:
          data?.MQ08?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        OtherSubstanceDuration:
          data?.MQ08?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,
        spouse_medicalOtherSubstance:
          data.MQ08.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_OtherSubstanceQuantity:
          data?.MQ08?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        spouse_OtherSubstanceDuration:
          data?.MQ08?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,
        child1_medicalOtherSubstance:
          data.MQ08.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",
        child1_OtherSubstanceQuantity:
          data?.MQ08?.main_question?.member_data?.child_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child1_OtherSubstanceDuration:
          data?.MQ08?.main_question?.member_data?.child_one?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,
        child2_medicalOtherSubstance:
          data.MQ08.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child2_OtherSubstanceQuantity:
          data?.MQ08?.main_question?.member_data?.child_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child2_OtherSubstanceDuration:
          data?.MQ08?.main_question?.member_data?.child_two?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,
        child3_medicalOtherSubstance:
          data.MQ08.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child3_OtherSubstanceQuantity:
          data?.MQ08?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child3_OtherSubstanceDuration:
          data?.MQ08?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,
        medicalAlcoholDiseases:
          data.MQ09.main_question.status === true ? "Yes" : "No", //Alcohol consumption
        medicalAlcohol:
          data.MQ09.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        alcoholQuantity:
          data?.MQ09?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        alcoholDuration:
          data?.MQ09?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,
        spouse_medicalAlcohol:
          data.MQ09.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_alcoholQuantity:
          data?.MQ09?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        spouse_alcoholDuration:
          data?.MQ09?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,
        child1_medicalAlcohol:
          data.MQ09.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",
        child1_alcoholQuantity:
          data?.MQ09?.main_question?.member_data?.child_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child1_alcoholDuration:
          data?.MQ09?.main_question?.member_data?.child_one?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,
        child2_medicalAlcohol:
          data.MQ09.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child2_alcoholQuantity:
          data?.MQ09?.main_question?.member_data?.child_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child2_alcoholDuration:
          data?.MQ09?.main_question?.member_data?.child_two?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,
        child3_medicalAlcohol:
          data.MQ09.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child3_alcoholQuantity:
          data?.MQ09?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child3_alcoholDuration:
          data?.MQ09?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,
        medicalCancelDiseases:
          data.MQ10.main_question.status === true ? "Yes" : "No", //Have you or any person proposed to be insured under the policy has been refused insurance cover by any insurance company or being accepted on special terms?
        medicalCancel:
          data.MQ10.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_medicalCancel:
          data.MQ10.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child1_medicalCancel:
          data.MQ10.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",
        child2_medicalCancel:
          data.MQ10.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child3_medicalCancel:
          data.MQ10.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        medicalOtherDiseasesDiseases:
          data.MQ02.main_question.sub_question?.["MQ14-SQ14"].status === true
            ? "Yes"
            : "No", //Any other illness/ Injury details
        medicalOtherDiseases:
          data.MQ02.main_question.sub_question?.["MQ14-SQ14"].member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        other_diseases_month_year:
          data.MQ02.main_question.sub_question?.["MQ14-SQ14"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        other_diseases_desc:
          data.MQ02.main_question.sub_question?.["MQ14-SQ14"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        spouse_medicalOtherDiseases:
          data.MQ02.main_question.sub_question?.["MQ14-SQ14"].member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_other_diseases_month_year:
          data.MQ02.main_question.sub_question?.["MQ14-SQ14"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_other_diseases_desc:
          data.MQ02.main_question.sub_question?.["MQ14-SQ14"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        child1_medicalOtherDiseases:
          data.MQ02.main_question.sub_question?.["MQ14-SQ14"].member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_other_diseases_month_year:
          data.MQ02.main_question.sub_question?.["MQ14-SQ14"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_other_diseases_desc:
          data.MQ02.main_question.sub_question?.["MQ14-SQ14"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        child2_medicalOtherDiseases:
          data.MQ02.main_question.sub_question?.["MQ14-SQ14"].member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_other_diseases_month_year:
          data.MQ02.main_question.sub_question?.["MQ14-SQ14"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_other_diseases_desc:
          data.MQ02.main_question.sub_question?.["MQ14-SQ14"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        child3_medicalOtherDiseases:
          data.MQ02.main_question.sub_question?.["MQ14-SQ14"].member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_other_diseases_month_year:
          data.MQ02.main_question.sub_question?.["MQ14-SQ14"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_other_diseases_desc:
          data.MQ02.main_question.sub_question?.["MQ14-SQ14"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,
      };

      HEALTH_PROPOSAL_SERVICES.getMedicalData(onSuccess, onError, param);
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
      );
    }
  };

  return (
    <>
      {isMobile ? (
        <MMedicalDetailKotak
          medicalQuestionData={medicalQuestionData}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          PAGE_STATUS={PAGE_STATUS}
        />
      ) : (
        <MedicalDetailKotak
          medicalQuestionData={medicalQuestionData}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          PAGE_STATUS={PAGE_STATUS}
        />
      )}
    </>
  );
};

export default MedicalDetailContainer;
