import { Box, Modal, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useAppSelector } from "../../redux/hooks";
import CustomButton from "../CustomButton/CustomButton";

function CKYCDetailsPopup({
  open,
  setOpen,
  loader,
}: {
  open: boolean;
  setOpen: Function;
  loader: boolean;
}) {
  const { CKYC_DETAILS } = useAppSelector((state) => state.CKYC);
  return (
    <Modal open={open} className="modalWrapper">
      {loader ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "16px",
            height: "100vh",
            width: "100%",
          }}
        >
          <CircularProgress />
          <p>Please wait...</p>
        </Box>
      ) : (
        <Box className="modalContent xl-width kycPopups">
          <Grid container spacing={2}>
            <Grid xs={12}>
              <h4 className="popup-heading mb-2">CKYC Details</h4>
              <p className="grey_p">
                We fetch these details as per record, please verify
              </p>
              <hr />
            </Grid>
          </Grid>
          <Grid container spacing={2} textAlign="center">
            <Grid xs={12} className="">
              <span className="policy_number_box my-4">
                {CKYC_DETAILS.kycNo}
              </span>
            </Grid>
          </Grid>
          <Grid container spacing={2} textAlign="center">
            <Grid xs={6}>
              <h6>
                Full Name:
                <span>{CKYC_DETAILS.kycCustomerName}</span>
              </h6>
            </Grid>
            <Grid xs={6}>
              <h6>
                DOB:
                <span>{CKYC_DETAILS.kycDOB}</span>
              </h6>
            </Grid>
            <Grid xs={12}>
              <h6>
                Address:
                <span>{`${CKYC_DETAILS.kycAddress1}, ${CKYC_DETAILS.kycAddress2}, ${CKYC_DETAILS.kycAddress3}, ${CKYC_DETAILS.kycCity}, ${CKYC_DETAILS.kycState}, ${CKYC_DETAILS.kycPincode}`}</span>
              </h6>
            </Grid>
            <Grid xs={12}>
              <hr />
            </Grid>
          </Grid>
          <Grid container columnSpacing={3} marginTop={"24px"}>
            <Grid xs={12} style={{ textAlign: "center" }}>
              <CustomButton
                text_name={"Continue"}
                class_name="mediumPrimaryBtn"
                onClickFunction={() => {
                  setOpen(false);
                }}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Modal>
  );
}

export default CKYCDetailsPopup;
