import { NODE_DOMAIN } from "../Domain";

export const CAR_URLS = {
  GET_VEHICLE_NO_INFO: `${NODE_DOMAIN}/api/v1/premium/vehicledetails-pro`,
  GET_PROPOSAL_INFO: `${NODE_DOMAIN}/api/v1/premium/proposal-details`,
  GET_PREVIOUS_INSURER_LIST: `${NODE_DOMAIN}/api/v1/premium/previous-insurers?type=CAR`,
  UPDATE_ADDON_STATUS: `${NODE_DOMAIN}/api/v1/premium/update-addon`,
  PREMIUM_RECALCULATION: `${NODE_DOMAIN}/api/v1/recalculate/motor`,
  GET_CITY_STATE_BY_PINCODE: `${NODE_DOMAIN}/api/v1/proposal/pincode`,
  CAPTURE_DATA: `${NODE_DOMAIN}/api/v1/proposal/capture-records`,
  PAYMENT_URL: `${NODE_DOMAIN}/api/v1/proposal/motor`,
  CREATE_INSPECTION: `${NODE_DOMAIN}/api/v1/car-inspection/motor`,
  VEHICLE_ON_LOAN: `${NODE_DOMAIN}/api/v1/proposal/bank`,
  BULK_UPDATE_ADDON_STATUS: `${NODE_DOMAIN}/api/v1/premium/update-bulk-addon`,
  GET_NOMINEE_RELATIONSHIP_LIST: `${NODE_DOMAIN}/api/v1/proposal/nominee/`,
  GET_PREVIEW_PAGE_DATA: `${NODE_DOMAIN}/api/v1/premium/preview-details`,
  GENERATE_OTP: `${NODE_DOMAIN}/api/v1/proposal/generateOtp`,
  VERIFY_OTP: `${NODE_DOMAIN}/api/v1/proposal/validateOtp`,
};
