import { Box, Collapse } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { useAppSelector } from "../../../../../redux/hooks";
import MedicalQuestion from "../../../../../Component/MedicalQuestion/MedicalQuestion";
import MemberRadioButtonNew from "../../../../../Component/MemberRadioButton/MemberRadioButtonNew";
import Footer from "../../../../../Component/Footer/Footer";

function MedicalDetailsElevateICICI({
  medicalQuestionData,
  updateMasterState,
  validateForm,
  PAGE_STATUS,
  blockJournary,
}: {
  medicalQuestionData: any;
  updateMasterState: Function;
  validateForm: Function;
  PAGE_STATUS: boolean;
  blockJournary: boolean;
}) {
  const { INSURED_MEMBER_DETAILS, SELECTED_QUOTE_DATA } = useAppSelector(
    (state) => state.Health
  );
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  console.log(
    INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
    medicalQuestionData,
    "jjj3"
  );
  return (
    <Box>
      <h5 className="sectionTitle">Underwriting Details Elevate</h5>
      <>
        {medicalQuestionData &&
          Object.values(medicalQuestionData).map(
            (data: any, indexMainQuestion: number) => (
              <div key={indexMainQuestion}>
                <MedicalQuestion
                  key={indexMainQuestion}
                  main_class="medicalQuestion borderBottom"
                  icon_class={data?.main_question?.icon_class}
                  question_name={data?.main_question?.title}
                  sub_details={<p>{data?.main_question?.description}</p>}
                  input_id_first={data?.main_question?.id}
                  input_id_second={data?.main_question?.id}
                  input_name="diagnosed"
                  toggle_button_status={data?.main_question?.status}
                  attrName={["question", data?.main_question?.id]}
                  value_update={updateMasterState}
                  data={data}
                  updateMasterState={updateMasterState}
                  select_disease={
                    <Collapse
                      in={data?.main_question?.status}
                      className="medicalQuestion-SubSection"
                    >
                      <Grid container spacing={3} className="pr-2 mb-3">
                        {data?.main_question?.sub_question && (
                          <Grid xs={12}>
                            {/* <h5>High or low blood pressure</h5> */}
                            {data?.main_question?.sub_question &&
                              Object.values(
                                data?.main_question?.sub_question
                              ).map(
                                (
                                  dataSubQuestion: any,
                                  indexSubQuestion: number
                                ) => (
                                  <>
                                    {(dataSubQuestion.id === "MQ17-SQ17" ||
                                      dataSubQuestion.id === "MQ18-SQ18") &&
                                    SELECTED_QUOTE_DATA.productDetails.id ===
                                      2843 ? null : (
                                      <MedicalQuestion
                                        key={indexSubQuestion}
                                        main_class="medicalQuestion borderBottom"
                                        icon_class={"tobaco"}
                                        question_name={dataSubQuestion?.title}
                                        sub_details={
                                          <p>{dataSubQuestion?.description}</p>
                                        }
                                        input_id_first={dataSubQuestion?.id}
                                        input_id_second={dataSubQuestion?.id}
                                        input_name="blood pressure"
                                        toggle_button_status={
                                          dataSubQuestion?.status
                                        }
                                        attrName={[
                                          "subQuestion",
                                          data?.main_question?.id,
                                          dataSubQuestion?.id,
                                        ]}
                                        value_update={updateMasterState}
                                        data={data}
                                        dataSubQuestion={dataSubQuestion}
                                        updateMasterState={updateMasterState}
                                        select_disease={
                                          <Collapse
                                            in={dataSubQuestion?.status}
                                            className="medicalQuestion-SubSection"
                                          >
                                            <Grid container spacing={2}>
                                              {Object.values(
                                                dataSubQuestion?.member_data
                                              )?.map(
                                                (
                                                  memberDataValue: any,
                                                  memberIndex: any
                                                ) => (
                                                  <React.Fragment
                                                    key={memberIndex}
                                                  >
                                                    {memberDataValue.name !==
                                                      "" && (
                                                      <Grid xs={4}>
                                                        <MemberRadioButtonNew
                                                          class_name={
                                                            memberIndex === 0 ||
                                                            memberIndex === 1
                                                              ? "adult"
                                                              : "child"
                                                          }
                                                          checked={
                                                            memberDataValue?.selection_status
                                                          }
                                                          label_name={
                                                            memberDataValue.name
                                                          }
                                                          attrName={[
                                                            "memberData",
                                                            data.main_question
                                                              .id,
                                                            dataSubQuestion.id,
                                                            memberDataValue.keyName,
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                        />
                                                      </Grid>
                                                    )}
                                                  </React.Fragment>
                                                )
                                              )}
                                            </Grid>
                                          </Collapse>
                                        }
                                      />
                                    )}
                                  </>
                                )
                              )}
                          </Grid>
                        )}
                      </Grid>
                    </Collapse>
                  }
                />
              </div>
            )
          )}
      </>
      <Footer
        textName={PAGE_STATUS ? "Update" : "Continue"}
        forward={() => validateForm()}
        disableButton={blockJournary}
      />
    </Box>
  );
}

export default MedicalDetailsElevateICICI;
