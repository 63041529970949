import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../Footer/Footer.scss";
import LoopIcon from "@mui/icons-material/Loop";
import { useAppSelector } from "../../../../../../redux/hooks";

const Footer = ({
  continueFunction,
  backText = "Back",
  backStatus = false,
  continueText = "Continue",
  loader = false,
}: {
  loader?: boolean;
  continueFunction: Function;
  backStatus?: boolean;
  backText?: string;
  continueText?: string;
}) => {
  const proposerPage = useAppSelector(
    (state) => state.TermFullJourney.proposerPage
  );
  return (
    <Box className="proposalFooterMobile">
      <Grid container spacing={3}>
        <Grid xs={12} className="footerInner" alignItems="center">
          <div className="ctaBtn">
            <Button
              className="regularSecondaryBtn"
              disabled={loader}
              onClick={() => {
                continueFunction();
              }}
            >
              {loader && <LoopIcon />}
              {loader && <span>Loading</span>}
              {!loader && (
                <span>
                  {proposerPage.update_status ? "Update" : continueText}
                </span>
              )}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
