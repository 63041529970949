import { ETermCompanyCode } from "../../../Enum/ETermCompanyCode";
import { useAppSelector } from "../../../redux/hooks";
import BajajRoutes from "./Bajaj/BajajRoutes";
import GodigitRoutes from "./Godigit/GodigitRoutes";
import HDFCRoutes from "./HDFC/HDFCRoutes";
import ICICIRoutes from "./ICICI/ICICIRoutes";
import MaxLifeRoutes from "./MaxLife/MaxLifeRoutes";
import TATARoutes from "./TATA/TATARoutes";

const TermRoutes = () => {
  const { SELECTED_QUOTE_DATA } = useAppSelector((state) => state?.Term);
  return (
    <>
      {SELECTED_QUOTE_DATA?.companyDetails?.company_code ===
      ETermCompanyCode.HDFC ? (
        <HDFCRoutes />
      ) : null}
      {SELECTED_QUOTE_DATA?.companyDetails?.company_code ===
      ETermCompanyCode.ICICI ? (
        <ICICIRoutes />
      ) : null}
      {SELECTED_QUOTE_DATA?.companyDetails?.company_code ===
      ETermCompanyCode.MAX_LIFE ? (
        <MaxLifeRoutes />
      ) : null}
      {SELECTED_QUOTE_DATA?.companyDetails?.company_code ===
      ETermCompanyCode.TATA ? (
        <TATARoutes />
      ) : null}
      {SELECTED_QUOTE_DATA?.companyDetails?.company_code ===
      ETermCompanyCode.BAJAJ ? (
        <BajajRoutes />
      ) : null}
      {SELECTED_QUOTE_DATA?.companyDetails?.company_code ===
      ETermCompanyCode.Godigit ? (
        <GodigitRoutes />
      ) : null}
    </>
  );
};

export default TermRoutes;
