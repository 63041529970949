import { EMotorCompCode } from "../../../../Enum/EMotorCompCode";
import { useAppSelector } from "../../../../redux/hooks";
import GO_DIGIT_HI_PROPOSAL_ROUTES from "./GoDigit/GoDigitHIRoutes";

function HIRoutes() {
  const company_code = useAppSelector(
    (state: any) =>
      state.TW.SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code
  );
  return (
    <>
      {/* {company_code === EMotorCompCode.GO_DIGIT
        ? GO_DIGIT_HI_PROPOSAL_ROUTES
        : null} */}
      <GO_DIGIT_HI_PROPOSAL_ROUTES />
    </>
  );
}

export default HIRoutes;
