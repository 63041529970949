import React from "react";
import Footer from "../../../../../Component/Footer/Footer";
import { useAppSelector } from "../../../../../redux/hooks";
import { Box, Collapse, Grid } from "@mui/material";
import MedicalQuestion from "../../../../../Component/MedicalQuestion/MedicalQuestion";
import MemberRadioButtonNew from "../../../../../Component/MemberRadioButton/MemberRadioButtonNew";
import MedicalQuestionRSA from "../../../../../Component/MedicalQuestion/MedicalQuestionRSA";
import RKTextField from "../../../../../Component/FieldTypes/RKTextField/RKTextField";

const MedicalDetailsRSA = ({
  medicalQuestionData,
  updateMasterState,
  validateForm,
  PAGE_STATUS,
}: {
  medicalQuestionData: any;
  updateMasterState: Function;
  validateForm: Function;
  PAGE_STATUS: boolean;
}) => {
  const { INSURED_MEMBER_DETAILS, SELECTED_QUOTE_DATA } = useAppSelector(
    (state) => state.Health
  );
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  console.log(medicalQuestionData, "jjj3");

  return (
    <Box>
      <h5 className="sectionTitle">Underwriting Details</h5>
      <>
        {Object.values(medicalQuestionData).map(
          (data: any, indexMainQuestion: number) => {
            return (
              <div key={indexMainQuestion}>
                <MedicalQuestionRSA
                  key={indexMainQuestion}
                  main_class="medicalQuestion borderBottom"
                  icon_class={data?.main_question?.icon_class}
                  question_name={data?.main_question?.title}
                  sub_details={<p>{data?.main_question?.description}</p>}
                  input_id_first={data?.main_question?.id}
                  input_id_second={data?.main_question?.id}
                  input_name="diagnosed"
                  toggle_button_status={data?.main_question?.status}
                  attrName={["question", data?.main_question?.id]}
                  value_update={updateMasterState}
                  data={data}
                  updateMasterState={updateMasterState}
                  select_disease={
                    <Collapse
                      in={data?.main_question?.status}
                      className="medicalQuestion-SubSection"
                    >
                      <Grid container spacing={3} className="pr-2 mb-3">
                        {data?.main_question?.member_data && (
                          <Grid xs={12}>
                            {/* <h5>High or low blood pressure</h5> */}
                            {data?.main_question?.member_data &&
                              Object.values(
                                data?.main_question?.member_data
                              ).map(
                                (memberDataValue: any, memberIndex: number) => {
                                  memberDataValue.name !== "" && (
                                    <Grid xs={4}>
                                      <MemberRadioButtonNew
                                        class_name={
                                          memberIndex === 2
                                            ? "child"
                                            : memberIndex === 3
                                            ? "child"
                                            : memberIndex === 4
                                            ? "child"
                                            : memberIndex === 5
                                            ? "child"
                                            : "adult"
                                        }
                                        checked={
                                          memberDataValue?.selection_status
                                        }
                                        label_name={memberDataValue.name}
                                        attrName={[
                                          "memberData",
                                          data.main_question.id,
                                          // dataSubQuestion.id,
                                          memberDataValue.keyName,
                                        ]}
                                        value_update={updateMasterState}
                                      />
                                      {memberDataValue?.selection_status &&
                                        memberDataValue?.keyName &&
                                        memberDataValue?.field_data &&
                                        Object.values(
                                          memberDataValue?.field_data
                                        ).length > 0 && (
                                          <>
                                            {Object.values(
                                              memberDataValue?.field_data
                                            ).map((field_data_data: any) => {
                                              return (
                                                <React.Fragment
                                                  key={field_data_data.id}
                                                >
                                                  {field_data_data?.field_type ===
                                                  "TEXTFIELD" ? (
                                                    <h1>Ttrue</h1>
                                                  ) : (
                                                    // <RKTextField
                                                    //   class_name="inputField mb-5"
                                                    //   title={
                                                    //     field_data_data.title
                                                    //   }
                                                    //   attrName={[
                                                    //     "mainMemberDataField",
                                                    //     data?.main_question?.id,
                                                    //     memberDataValue.keyName,
                                                    //     field_data_data.id,
                                                    //   ]}
                                                    //   value={
                                                    //     field_data_data?.value
                                                    //       ?.value
                                                    //   }
                                                    //   value_update={
                                                    //     updateMasterState
                                                    //   }
                                                    //   warn_status={
                                                    //     field_data_data?.value
                                                    //       ?.warning
                                                    //   }
                                                    //   error_message={
                                                    //     "Enter description"
                                                    //   }
                                                    // />
                                                    <h1>dsahfbsahfashfbhd</h1>
                                                  )}
                                                </React.Fragment>
                                              );
                                            })}
                                          </>
                                        )}
                                    </Grid>
                                  );
                                }
                              )}
                          </Grid>
                        )}
                      </Grid>
                    </Collapse>
                  }
                />
              </div>
            );
          }
        )}
      </>
      <Footer
        textName={PAGE_STATUS ? "Update" : "Continue"}
        forward={() => validateForm()}
      />
    </Box>
  );
};

export default MedicalDetailsRSA;
