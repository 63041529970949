import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import UploadDocument from "../../../../../Page/Desktop/TermFullJourney/HDFC/Proposal/UploadDocument/UploadDocument";
import MUploadDocuments from "../../../../../Page/Mobile/TermFullJourney/HDFC/Proposal/UploadDocument/MUploadDocuments";
import { TERM_FULL_JOURNEY_ROUTES } from "../../../../../Router/Path/TermFullJourneyRoutes";
import { useAppSelector } from "../../../../../redux/hooks";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";

function UploadDocumentContainer() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { HDFCUploadDocDetails } = useAppSelector(
    (state) => state.TermFullJourney
  );

  const continueFunction = () => {
    let isValid = true;
    for (var i = 0; i < HDFCUploadDocDetails.data.length; i++) {
      const data = HDFCUploadDocDetails.data[i];

      if (data.upload_status === false) {
        isValid = false;
        toast.error("Please upload all documents.");
        return;
      }
    }

    if (isValid) {
      navigate(TERM_FULL_JOURNEY_ROUTES.POLICY_CONFIRMATION);
    }
  };

  return (
    <>
      {isMobile ? (
        <MUploadDocuments continueFunction={continueFunction} />
      ) : (
        <UploadDocument continueFunction={continueFunction} />
      )}
    </>
  );
}

export default UploadDocumentContainer;
