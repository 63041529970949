import React, { useEffect, useState } from "react";
// import { TRSAAddon } from '../../../../../Type/Health/HealthQuotation/TAddonRSA';
import Footer from "../../../../../Component/Footer/Footer";
import { useNavigate } from "react-router";
import { HEALTH_ROUTE } from "../../../../../Router/Path/HealthRoutes";
import AddonQuestion from "../../../../../Component/AddonQuestion/AddonQuestion";
import { Box } from "@mui/system";
import Grid from "@mui/material/Unstable_Grid2";
import { Modal } from "@mui/material";
import { TADITYABIRLAAddon } from "../../../../../Type/Health/ProposalDeatail/TADITYABIRLAAddon/TADITYABIRLAAddon";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../../redux/hooks";
import CustomButton from "../../../../../Component/CustomButton/CustomButton";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import CustomCheckbox from "../../../../../Component/FieldTypes/CustomCheckbox/CustomCheckbox";
import MFooter from "../../../../../Component/MFooter/MFooter";
import Loader from "../../../../../Component/Loader/Loader";

const MAddonDetailsADITYABIRLA = ({
  addonStatus,
  updateMasterState,
  validate,
  PAGE_STATUS,
  isLoading,
  kycDetail,
  setKycDetail,
}: {
  addonStatus: TADITYABIRLAAddon;
  PAGE_STATUS: boolean;
  updateMasterState: Function;
  validate: Function;
  isLoading: any;
  kycDetail: any;
  setKycDetail: any;
}) => {
  const navigate = useNavigate();
  console.log("addonStatus", addonStatus);
  const { CKYC_DETAILS } = useAppSelector((state) => state.CKYC);
  const { SELECTED_QUOTE_DATA, QUOTE_LOADER, INSURED_MEMBER_DETAILS } =
    useAppSelector((state) => state.Health);

  console.log(QUOTE_LOADER, "QUOTE_LOADER");

  return (
    <>
      {QUOTE_LOADER && <Loader />}
      {
        <Box>
          <Modal open={kycDetail} className="modalWrapperMobile mwMobile-kyc">
            <Box className="modalContent">
              <Grid container spacing={2}>
                <Grid xs={12}>
                  <h4 className="popup-heading mb-2">CKYC Details rahul</h4>
                  <p className="grey_p">
                    We fetch these details as per record, please verify
                  </p>
                  <hr />
                </Grid>
              </Grid>
              <Grid container spacing={2} textAlign="center">
                <Grid xs={12} className="">
                  <span className="policy_number_box my-4">
                    {CKYC_DETAILS.kycNo}
                  </span>
                </Grid>
              </Grid>
              <Grid container spacing={2} textAlign="center">
                <Grid xs={6}>
                  <h6>
                    Full Name:
                    <span>{CKYC_DETAILS.kycCustomerName}</span>
                  </h6>
                </Grid>
                <Grid xs={6}>
                  <h6>
                    DOB:
                    <span>{CKYC_DETAILS.kycDOB}</span>
                  </h6>
                </Grid>
                <Grid xs={12}>
                  <h6>
                    Address:
                    <span>{`${CKYC_DETAILS.kycAddress1}, ${CKYC_DETAILS.kycAddress2}, ${CKYC_DETAILS.kycAddress3}, ${CKYC_DETAILS.kycCity}, ${CKYC_DETAILS.kycState}, ${CKYC_DETAILS.kycPincode}`}</span>
                  </h6>
                </Grid>
                <Grid xs={12}>
                  <hr />
                </Grid>
              </Grid>
              <Grid container columnSpacing={3} marginTop={"24px"}>
                <Grid
                  xs={12}
                  style={{ textAlign: "center" }}
                  className="ctaBtn"
                >
                  <CustomButton
                    text_name={"Continue"}
                    class_name="primaryBtn"
                    onClickFunction={() => {
                      // CONTINUE_TO_PROPOSAL();
                      setKycDetail(false);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Modal>

          <h5 className="sectionTitle">Addon Details</h5>
          <Grid xs={12}>
            <div>
              {/* <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Second medical opinion for listed major illness"
            toggle_status={
              addonStatus?.second_medical_opinion_for_listed_major_illness
            }
            value_update={updateMasterState}
            attrName={"second_medical_opinion_for_listed_major_illness"}
          /> */}
              {/* <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Compassionate visit"
            toggle_status={addonStatus?.compassionate_visit}
            value_update={updateMasterState}
            attrName={"compassionate_visit"}
          /> */}
              {/* <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Reduction in specific disease waiting period"
            toggle_status={
              addonStatus?.reduction_in_specific_disease_waiting_period
            }
            value_update={updateMasterState}
            attrName={"reduction_in_specific_disease_waiting_period"}
          /> */}
              {/* <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Per claim deductible"
            toggle_status={addonStatus?.per_claim_deductible}
            value_update={updateMasterState}
            attrName={"per_claim_deductible"}
          /> */}
              {/* 
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="In patient hospitalization"
            toggle_status={addonStatus?.in_patient_hospitalization}
            value_update={updateMasterState}
            attrName={"in_patient_hospitalization"}
          /> */}
              {/* <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Road ambulance cover per hospitalization"
            toggle_status={
              addonStatus?.road_ambulance_cover_per_hospitalization
            }
            value_update={updateMasterState}
            attrName={"road_ambulance_cover_per_hospitalization"}
          /> */}
              {/* <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Modern procedures treatments"
            toggle_status={addonStatus?.modern_procedures_treatments}
            value_update={updateMasterState}
            attrName={"modern_procedures_treatments"}
          /> */}
              {/* <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Hiv aids and std over"
            toggle_status={addonStatus?.hiv_aids_and_std_over}
            value_update={updateMasterState}
            attrName={"hiv_aids_and_std_over"}
          /> */}
              {/* <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Mental illness hospitalization"
            toggle_status={addonStatus?.mental_illness_hospitalization}
            value_update={updateMasterState}
            attrName={"mental_illness_hospitalization"}
          /> */}
              {/* <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Obesity treatment"
            toggle_status={addonStatus?.obesity_treatment}
            value_update={updateMasterState}
            attrName={"obesity_treatment"}
          /> */}
              {/* 
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Pre hospitalization expenses"
            toggle_status={addonStatus?.pre_hospitalization_expenses}
            value_update={updateMasterState}
            attrName={"pre_hospitalization_expenses"}
          /> */}
              {/* <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Post hospitalization expenses"
            toggle_status={addonStatus?.post_hospitalization_expenses}
            value_update={updateMasterState}
            attrName={"post_hospitalization_expenses"}
          /> */}
              {/* <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Domiciliary hospitalization"
            toggle_status={addonStatus?.domiciliary_hospitalization}
            value_update={updateMasterState}
            attrName={"domiciliary_hospitalization"}
          /> */}
              {/* <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Home health care"
            toggle_status={addonStatus?.home_health_care}
            value_update={updateMasterState}
            attrName={"home_health_care"}
          /> */}
              {/* <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Ayush treatment"
            toggle_status={addonStatus?.ayush_treatment}
            value_update={updateMasterState}
            attrName={"ayush_treatment"}
          /> */}
              {/* <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Organ donor expenses"
            toggle_status={addonStatus?.organ_donor_expenses}
            value_update={updateMasterState}
            attrName={"organ_donor_expenses"}
          /> */}
              {/* <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Super reload"
            toggle_status={addonStatus?.super_reload}
            value_update={updateMasterState}
            attrName={"super_reload"}
          /> */}
              {/* <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Health assessmentTM"
            toggle_status={addonStatus?.health_assessmentTM}
            value_update={updateMasterState}
            attrName={"health_assessmentTM"}
          /> */}
              {/* <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Health returnsTM"
            toggle_status={addonStatus?.healthReturnsTM}
            value_update={updateMasterState}
            attrName={"healthReturnsTM"}
          /> */}
              {/* 
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Claim protect non medical"
            toggle_status={addonStatus?.claim_protect_non_medical}
            value_update={updateMasterState}
            attrName={"claim_protect_non_medical"}
          /> */}
              {/* <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Preferred provider network"
            toggle_status={addonStatus?.preferred_provider_network}
            value_update={updateMasterState}
            attrName={"preferred_provider_network"}
          /> */}
              <AddonQuestion
                addon_amount=""
                main_class="addonQuestion borderBottom"
                icon_class="bonus"
                question_name="Reduction in pre existing disease waiting period"
                toggle_status={
                  addonStatus?.reduction_in_pre_existing_disease_waiting_period
                }
                value_update={updateMasterState}
                attrName={"reduction_in_pre_existing_disease_waiting_period"}
                sub_details={
                  <Box maxWidth={"200px"}>
                    <SelectDropdown
                      title="Reduction Ped Waiting Period"
                      value={addonStatus.reduction_ped_waiting_period}
                      value_update={updateMasterState}
                      attrName={"reduction_ped_waiting_period"}
                      data={[
                        { value: "3TO1", label: "3 Years to 1 Year" },
                        { value: "3TO2", label: "3 Years to 2 Years" },
                      ]}
                    />
                  </Box>
                }
              />
              <AddonQuestion
                addon_amount=""
                main_class="addonQuestion borderBottom"
                icon_class="bonus"
                question_name="Room rent type options"
                toggle_status={addonStatus?.room_rent_type_options}
                value_update={updateMasterState}
                attrName={"room_rent_type_options"}
                sub_details={
                  <Box maxWidth={"200px"}>
                    <SelectDropdown
                      class_name="inputField"
                      title="Room Renty Type"
                      value={addonStatus.room_rent_type}
                      value_update={updateMasterState}
                      attrName={"room_rent_type"}
                      data={[
                        { value: "SH", label: "Shared Accomodation" },
                        { value: "YSY", label: "Single Private Room" },
                      ]}
                    />
                  </Box>
                }
              />
              <AddonQuestion
                addon_amount=""
                main_class="addonQuestion borderBottom"
                icon_class="bonus"
                question_name="Critical illness cover"
                toggle_status={addonStatus?.critical_illness_cover}
                value_update={updateMasterState}
                attrName={"critical_illness_cover"}
                sub_details={
                  <Box maxWidth={"200px"}>
                    <SelectDropdown
                      class_name="inputField"
                      title="Critical Illness SI"
                      value={addonStatus.critical_illness_cover_SI}
                      value_update={updateMasterState}
                      attrName={"critical_illness_cover_SI"}
                      data={[
                        { value: "1000000", label: "1000000" },
                        { value: "1500000", label: "1500000" },
                        { value: "2000000", label: "2000000" },
                        { value: "2500000", label: "2500000" },
                      ]}
                    />
                  </Box>
                }
              />
              <AddonQuestion
                addon_amount=""
                main_class="addonQuestion borderBottom"
                icon_class="bonus"
                question_name="Personal accident cover"
                toggle_status={addonStatus?.personal_accident_cover}
                value_update={updateMasterState}
                attrName={"personal_accident_cover"}
                sub_details={
                  <Box maxWidth={"400px"} display={"flex"} gap={"24px"}>
                    <SelectDropdown
                      class_name="inputField"
                      title="Personal Accident SI"
                      value={addonStatus.personal_accident_cover_SI}
                      value_update={updateMasterState}
                      attrName={"personal_accident_cover_SI"}
                      data={[
                        { value: "1000000", label: "1000000" },
                        { value: "1500000", label: "1500000" },
                        { value: "2000000", label: "2000000" },
                        { value: "2500000", label: "2500000" },
                        { value: "5000000", label: "5000000" },
                      ]}
                    />
                    <SelectDropdown
                      class_name="inputField"
                      title="Nature Of Duty"
                      value={addonStatus.natureOfDuty}
                      value_update={updateMasterState}
                      attrName={"natureOfDuty"}
                      data={[
                        { value: "BANKER", label: "Banker" },
                        { value: "TEACHER", label: "Teacher" },
                        { value: "Housewife", label: "Housewife" },
                        { value: "Army", label: "Army" },
                        { value: "Farmers", label: "Farmers" },
                        { value: "Engineer-Others", label: "Engineer-Others" },
                      ]}
                    />
                  </Box>
                }
              />
              <AddonQuestion
                addon_amount=""
                main_class="addonQuestion borderBottom"
                icon_class="bonus"
                question_name="Durable equipment cover"
                toggle_status={addonStatus?.durable_equipment_cover}
                value_update={updateMasterState}
                attrName={"durable_equipment_cover"}
              />
              <AddonQuestion
                addon_amount=""
                main_class="addonQuestion borderBottom"
                icon_class="bonus"
                question_name="Cancer booster"
                toggle_status={addonStatus?.cancer_booster}
                value_update={updateMasterState}
                attrName={"cancer_booster"}
              />
              <AddonQuestion
                addon_amount=""
                main_class="addonQuestion borderBottom"
                icon_class="bonus"
                question_name="Chronic management program"
                toggle_status={addonStatus?.chronic_management_program}
                value_update={updateMasterState}
                attrName={"chronic_management_program"}
                sub_details={
                  <Box>
                    <ul className="newList">
                      <li>
                        {" "}
                        <CustomCheckbox
                          label={"Non-Chronic"}
                          value_update={updateMasterState}
                          value={addonStatus?.NonChronic as boolean}
                          defaultChecked={false}
                          attrName={"NonChronic"}
                        />
                      </li>
                      <li>
                        <CustomCheckbox
                          label={"Diabetes"}
                          value_update={updateMasterState}
                          value={addonStatus?.Diabetes as boolean}
                          defaultChecked={false}
                          attrName={"Diabetes"}
                        />
                      </li>
                      <li>
                        {" "}
                        <CustomCheckbox
                          label={"Asthama"}
                          value_update={updateMasterState}
                          value={addonStatus?.Asthama as boolean}
                          defaultChecked={false}
                          attrName={"Asthama"}
                        />
                      </li>
                      <li>
                        {" "}
                        <CustomCheckbox
                          label={"Hypertension"}
                          value_update={updateMasterState}
                          value={addonStatus?.Hypertension as boolean}
                          defaultChecked={false}
                          attrName={"Hypertension"}
                        />
                      </li>
                      <li>
                        {" "}
                        <CustomCheckbox
                          label={"Hyperlipidaemia"}
                          value_update={updateMasterState}
                          value={addonStatus?.Hyperlipidaemia as boolean}
                          defaultChecked={false}
                          attrName={"Hyperlipidaemia"}
                        />
                      </li>
                      <li>
                        {" "}
                        <CustomCheckbox
                          label={"PTCA"}
                          value_update={updateMasterState}
                          value={addonStatus?.PTCA as boolean}
                          defaultChecked={false}
                          attrName={"PTCA"}
                        />
                      </li>
                      <li>
                        {" "}
                        <CustomCheckbox
                          label={"COPD"}
                          value_update={updateMasterState}
                          value={addonStatus?.COPD as boolean}
                          defaultChecked={false}
                          attrName={"COPD"}
                        />
                      </li>
                      <li>
                        {" "}
                        <CustomCheckbox
                          label={"HighBMI"}
                          value_update={updateMasterState}
                          value={addonStatus?.HighBMI as boolean}
                          defaultChecked={false}
                          attrName={"HighBMI"}
                        />
                      </li>
                    </ul>
                  </Box>
                }
              />
              {SELECTED_QUOTE_DATA.productDetails.product_code ===
                "HPAB058" && (
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="Chronic care"
                  toggle_status={addonStatus?.chronic_care}
                  value_update={updateMasterState}
                  attrName={"chronic_care"}
                />
              )}
              {/* <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Super credit increases irrespective of claim"
            toggle_status={
              addonStatus?.Super_credit_increases_irrespective_of_claim
            }
            value_update={updateMasterState}
            attrName={"Super_credit_increases_irrespective_of_claim"}
          /> */}
              {/* <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Chronic care"
            toggle_status={addonStatus?.chronic_care}
            value_update={updateMasterState}
            attrName={"chronic_care"}
          /> */}
              {/* <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Annual screening package for cancer diagnosed patients"
            toggle_status={
              addonStatus?.annual_screening_package_for_cancer_diagnosed_patients
            }
            value_update={updateMasterState}
            attrName={"annual_screening_package_for_cancer_diagnosed_patients"}
          /> */}
              {/* <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Annual health check up"
            toggle_status={addonStatus?.annual_health_check_up}
            value_update={updateMasterState}
            attrName={"annual_health_check_up"}
          /> */}
              {/* <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Vaccine cover"
            toggle_status={addonStatus?.vaccine_cover}
            value_update={updateMasterState}
            attrName={"vaccine_cover"}
          /> */}
              {/* 
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Tele OPD consultation"
            toggle_status={addonStatus?.tele_OPD_consultation}
            value_update={updateMasterState}
            attrName={"tele_OPD_consultation"}
          /> */}
              {/* <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Opd add on"
            toggle_status={addonStatus?.opd_add_on}
            value_update={updateMasterState}
            attrName={"opd_add_on"}
          /> */}
            </div>
          </Grid>
          <MFooter
            textName={PAGE_STATUS === true ? "Update" : "Continue"}
            attrName={"pageStatus"}
            forward={() => {
              validate();
            }}
            value={0}
            backward={() => {
              navigate(
                `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.QOUTE_PAGE}`
              );
            }}
          />
        </Box>
      }
    </>
  );
};

export default MAddonDetailsADITYABIRLA;
