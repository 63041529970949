import { GetAPI } from "../../Axios/AxiosInstance";
import { PostAPI } from "../../Axios/AxiosInstanceToken";
import { CARE_HEARTH_DTO } from "../../types/Health/TCareAddon";
import { HealthURLs } from "../../URLCollection/health-php/HealthURLS";

const getPeriumByAddon = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    HealthURLs.GET_PREIUM_BY_ADDON,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

//icic_addons

const getPeriumByAddonIcic = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    HealthURLs.GET_PREIUM_BY_ADDON_ICIC,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

//rsa_addons

const getPeriumByAddonRsa = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    HealthURLs.GET_PREIUM_BY_ADDON_RSA,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

//aditya_birls_addons

const getPeriumByAddonAdityaBirla = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    HealthURLs.GET_PREIUM_BY_ADDON_ADITYA_BIRLA,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

//hdfc_addons
const getPeriumByAddonHdfc = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    HealthURLs.GET_PREIUM_BY_ADDON_HDFC,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

//godigit_addons
const getPeriumByAddonGoDigit = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    HealthURLs.GET_PREIUM_BY_ADDON_GODIGIT,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

//bajaj_addons
const getPeriumByAddonBajaj = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    HealthURLs.GET_PREIUM_BY_ADDON_BAJAJ,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

//CignaPrimeAdvantage_addons
const getPeriumByAddonCignaPrimeAdvantage = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    HealthURLs.GET_PREIUM_BY_ADDON_CIGNA_PRIME_ADVANTAGE,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

//CignaPrimeActive_addons
const getPeriumByAddonCignaPrimeActive = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    HealthURLs.GET_PREIUM_BY_ADDON_CIGNA_PRIME_ACTIVE,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

//CignaPrimeProtect_addons
const getPeriumByAddonCignaPrimeProtect = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    HealthURLs.GET_PREIUM_BY_ADDON_CIGNA_PRIME_PROTECT,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

//CignaLifeTimeIndia_addons
const getPeriumByAddonCignaLifeTimeIndia = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    HealthURLs.GET_PREIUM_BY_ADDON_CIGNA_LIFETIME_INDIA,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

//CignaLifeTimeGlobal_addons
const getPeriumByAddonCignaLifeTimeGlobal = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    HealthURLs.GET_PREIUM_BY_ADDON_CIGNA_LIFETIME_GLOBAL,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

//hdfce_secure
const getPeriumByAddonHdfcSecure = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    HealthURLs.GET_PREIUM_BY_ADDON_HDFC_SECURE,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

const getMartialData = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: {
    product_code: string;
    product_type_code: string;
  }
) => {
  PostAPI.call(
    HealthURLs.GET_MARITAL_DATA,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

const getOccupationData = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: {
    product_code: string;
    product_type_code: string;
  }
) => {
  PostAPI.call(
    HealthURLs.GET_OCCUPATION_DATA,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};
// const getMartialStatusData = (
//   onSuccess: (data: any) => void,
//   onError: (data: any) => void,
//   dto: {
//     product_code: string;
//     product_type_code: string;
//   }
// ) => {
//   PostAPI.call(
//     HealthURLs.GET_MARITAL_DATA,
//     dto,
//     (res: any) => onSuccess(res.data),
//     (res: any) => onError(res.data)
//   );
// };

const getRelationData = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: {
    product_code: string;
    // product_type_code: string;
    relationship?: string;
    buy_online_code?: string;
  }
) => {
  PostAPI.call(
    HealthURLs.GET_RELATION_DATA,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};
const getNomineeRelationData = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: {
    product_type_code: string;
    product_code?: string;
  }
) => {
  PostAPI.call(
    HealthURLs.NOMINEE_RELATION_API,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};
const submitKyc = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: {
    cusType: string;
    kycCusName: string;
    dobForKyc: Date | null | string;
    docNameForKyc: string;
    docIDNoForKyc: string;
    genderForKyc: string;
    transactionId: string;
  }
) => {
  PostAPI.call(
    HealthURLs.KYC_SUBMIT,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

const submitManualKyc = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: {
    cusType: string;
    kycCusName: string;
    dobForKyc: string;
    genderForKyc: string;
    idenDocNameForKyc: string;
    docAddNameForKyc: string;
    documentLists: {
      identityDocumentAll: string;
    };
  }
) => {
  PostAPI.call(
    HealthURLs.MANUAL_KYC_SUBMIT,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

const submitKycIIFCO = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    HealthURLs.KYC_SUBMIT_IIFCO,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

const submitManualKycIIFCO = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    HealthURLs.MANUAL_KYC_SUBMIT_IIFCO,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};
const getCapturedRecordData = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    HealthURLs.Capture_Record,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};
const makePayment = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    HealthURLs.MAKE_PAYMENT,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};
const getMedicalData = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    HealthURLs.MEDICAL_DATA,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

const GET_ADDON_PREMIUM_CARE_SUPREME = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    HealthURLs.GET_ADDON_PREMIUM_CARE_SUPREME,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

const GET_ADDON_PREMIUM_CARE_SENIOR = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    HealthURLs.GET_ADDON_PREMIUM_CARE_SENIOR,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};
const GET_CIS_URL = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    HealthURLs.CIS_URL,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

const GET_ADDON_PREMIMIUM_NIVA_BUPA = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    HealthURLs.SAVE_NIVA_BUPA_ADDON,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

const GET_ADDON_PREMIMIUM_NIVA_BUPA_SENIOR = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    HealthURLs.SAVE_NIVA_BUPA_ADDON_SENIOR,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

const getPeriumByAddonGoldenShieldIcic = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    HealthURLs.GET_PREIUM_BY_ADDON_GOLDEN_SHIELD_ICIC,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

const getPeriumByAddonKotak = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    HealthURLs.GET_PREIUM_BY_ADDON_KOTAK,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

const GET_ADDON_PREMIUM_CARE_HEART = (
  onSuccess: (data: any) => void,
  onError: (data: CARE_HEARTH_DTO) => void,
  dto: CARE_HEARTH_DTO
) => {
  PostAPI.call(
    HealthURLs.GET_ADDON_PREMIUM_CARE_HEART,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

const GENERATE_OTP = (onSuccess: Function, onError: Function, param: any) => {
  PostAPI.call(
    HealthURLs.GENERATE_OTP,
    param,
    (res: any) => onSuccess(res.data),
    onError
  );
};
const VERIFY_OTP = (onSuccess: Function, onError: Function, param: any) => {
  PostAPI.call(
    HealthURLs.VERIFY_OTP,
    param,
    (res: any) => onSuccess(res.data),
    onError
  );
};
const PAYMENT_LINK = (onSuccess: Function, onError: Function, param: any) => {
  PostAPI.call(
    HealthURLs.PAYMENT_LINK,
    param,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_MANDATORY_CARE_SUPREME_ADDON = (
  onSuccess: Function,
  onError: Function,
  param: any
) => {
  PostAPI.call(
    HealthURLs.GET_MANDATORY_CARE_SUPREME_ADDON,
    param,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_BANK_DETAILS_BY_IFSC_CODE = (
  onSuccess: Function,
  onError: Function,
  param: any
) => {
  PostAPI.call(
    HealthURLs.GET_BANK_DETAILS_BY_IFSC_CODE,
    param,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_CIGNA_BANK_DETAILS = (
  onSuccess: Function,
  onError: Function,
  param: any
) => {
  GetAPI.call(
    `${HealthURLs.GET_CIGNA_BANK_DETAILS}/${param.IFSCCODE}`,
    (res: any) => onSuccess(res.data),
    "",
    onError
  );
};

const GET_SECTION_DATA = (
  onSuccess: Function,
  onError: Function,
  param: any
) => {
  PostAPI.call(
    HealthURLs.GET_SECTION_DATA,
    param,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_FINAL_PREMIUM = (
  onSuccess: Function,
  onError: Function,
  param: any
) => {
  PostAPI.call(
    HealthURLs.GET_FINAL_PREMIUM,
    param,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const OCR_CKYC = (onSuccess: Function, onError: Function, param: any) => {
  PostAPI.call(
    HealthURLs.OCR_CKYC,
    param,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_MANDATORY_CIGNA = (
  onSuccess: Function,
  onError: Function,
  param: any
) => {
  PostAPI.call(
    HealthURLs.GET_MANDATORY_CIGNA,
    param,
    (res: any) => onSuccess(res.data),
    onError
  );
};

export const HEALTH_PROPOSAL_SERVICES = {
  GET_ADDON_PREMIUM_CARE_SENIOR,
  GET_ADDON_PREMIUM_CARE_SUPREME,
  getPeriumByAddon,
  getPeriumByAddonIcic,
  getPeriumByAddonRsa,
  getPeriumByAddonAdityaBirla,
  getPeriumByAddonHdfc,
  getPeriumByAddonHdfcSecure,
  getMedicalData,
  getNomineeRelationData,
  getMartialData,
  getOccupationData,
  getRelationData,
  getCapturedRecordData,
  submitKyc,
  submitManualKyc,
  submitKycIIFCO,
  submitManualKycIIFCO,
  makePayment,
  GET_CIS_URL,
  getPeriumByAddonGoDigit,
  GET_ADDON_PREMIMIUM_NIVA_BUPA,
  GET_ADDON_PREMIMIUM_NIVA_BUPA_SENIOR,
  getPeriumByAddonGoldenShieldIcic,
  getPeriumByAddonBajaj,
  getPeriumByAddonKotak,
  getPeriumByAddonCignaPrimeAdvantage,
  getPeriumByAddonCignaPrimeActive,
  getPeriumByAddonCignaPrimeProtect,
  getPeriumByAddonCignaLifeTimeIndia,
  getPeriumByAddonCignaLifeTimeGlobal,
  GET_ADDON_PREMIUM_CARE_HEART,
  GENERATE_OTP,
  VERIFY_OTP,
  PAYMENT_LINK,
  GET_MANDATORY_CARE_SUPREME_ADDON,
  GET_BANK_DETAILS_BY_IFSC_CODE,
  GET_SECTION_DATA,
  GET_CIGNA_BANK_DETAILS,
  GET_FINAL_PREMIUM,
  OCR_CKYC,
  GET_MANDATORY_CIGNA,
};
