import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AddonDetailsICIC from "../../../../Page/Desktop/health-php/ICIC/AddonDetailsICIC/AddonDetailsICIC";
import MAddonDetailsICIC from "../../../../Page/Mobile/health-php/ICIC/AddonDetailsICIC/MAddonDetailsICIC";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/health-php/HealthPropsalServices";
import { HealthSlice } from "../../../../redux/slice/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { THealthQuote } from "../../../../types/Health/HealthQuotation/THealthQuote";
import { TICICAddon } from "../../../../types/Health/ProposalDeatail/TICICAddon/TICICAddon";
import { GET_QUERY_PARAMETERS } from "../../../../SupportingFiles/HelpingFunction";

export default function AddonDetailsContainerICIC() {
  const isMobile = useIsMobile();
  const { Health } = useAppSelector((state) => state);
  const {
    ADD_FORM,
    ADD_FORM_RESPONSE,
    ADDON_DETAILS_ICIC,
    SELECTED_QUOTE_DATA,
    PAGE_STATUS,
    QUOTE_LOADER,
  } = useAppSelector((state) => state.Health);
  const { CKYC } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const [addonStatus, setAddonStatus] =
    useState<TICICAddon>(ADDON_DETAILS_ICIC);
  const [trueCount, setTrueCount] = useState(0);

  const countTrueValues = (obj: TICICAddon) => {
    setTrueCount(Object.values(obj).filter((value) => value === true).length);
  };

  useEffect(() => {
    if (trueCount > 10) {
      toast.error("Upto 10 addons are allowed");
    }
  }, [trueCount]);

  useEffect(() => {
    updatePreiumApi();
    // setTimeout(() => {
    //   FinalPremium();
    // }, 1000);
    countTrueValues(addonStatus);
    dispatch(HealthSlice.actions.ADDON_DETAILS_DATA_ICIC(addonStatus));
  }, [addonStatus]);

  useEffect(() => {
    const sumInsured = ADD_FORM_RESPONSE.sum_insured;

    // Initialize addonStatus with the current state
    let updatedAddonStatus = { ...addonStatus };

    // Check conditions and update addonStatus accordingly
    if (sumInsured >= 1500000) {
      updatedAddonStatus = {
        ...updatedAddonStatus,
        Claim_Protector: true,
      };
    }

    if (sumInsured >= 10000000) {
      updatedAddonStatus = {
        ...updatedAddonStatus,
        Compassionate_Visit: true,
        Nursing_at_Home: true,
      };
    }

    // Update state using setAddonStatus
    setAddonStatus(updatedAddonStatus);
  }, [ADD_FORM_RESPONSE.sum_insured]);

  useEffect(() => {
    if (Number(SELECTED_QUOTE_DATA.sumInsured) >= 10000000) {
      setAddonStatus((prevState) => ({
        ...prevState,
        Compassionate_Visit: true,
        Nursing_at_Home: true,
      }));
    }
  }, []);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "Maternity") {
      // for female after selecting maternity addon Both the new born addon and Vaccination addon is selected and vice versa by default they were disabled
      setAddonStatus((prevState) => ({
        ...prevState,
        Maternity: value,
        New_Born: value,
        Vaccination: value,
      }));
      return;
    }
    setAddonStatus((prevState) => ({
      ...prevState,
      [attrName]: value,
    }));
  };

  const updatePreiumApi = () => {
    const onSuccess = (res: any) => {
      if (res.results.error === false) {
        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.results.response.premiumDetails.finalPremium,
          },
        };
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            QUOTE_LOADER: false,
          })
        );
        // setTimeout(() => {
        //     FinalPremium();
        // }, 1000);
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };

    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      addon: {
        AddOn78: addonStatus.Critical_Illness === true ? 1 : 0, // Adult only
        AddOn79: addonStatus.Maternity === true ? 1 : 0, // Only For Female Adults members
        AddOn80: addonStatus.New_Born === true ? 1 : 0, // Only For Female Adults members
        AddOn81: addonStatus.Vaccination === true ? 1 : 0, // Only For Female Adults members
        AddOn82: addonStatus.personal_accident === true ? 1 : 0, // Adult only
        AddOn83: addonStatus.SI_Protector === true ? 1 : 0, // optional
        AddOn84: addonStatus.Claim_Protector === true ? 1 : 0, // Optional but Mandatory for SI 15 lacs & above
        AddOn85: addonStatus.Compassionate_Visit === true ? 1 : 0, // Optional but Mandatory for SI of 1 cr & above
        AddOn86: addonStatus.Nursing_at_Home === true ? 1 : 0, // Optional but Mandatory for SI of 1 cr & above
        AddOn87: addonStatus.BeFit_A === true ? 1 : 0, // Applicable as per Pincode of BeFit
        AddOn88: addonStatus.BeFit_B === true ? 1 : 0, // Applicable as per Pincode of BeFit
        AddOn89: addonStatus.BeFit_C === true ? 1 : 0, // Applicable as per Pincode of BeFit
        AddOn90: addonStatus.BeFit_D === true ? 1 : 0, // Applicable as per Pincode of BeFit
        // AddOn91: addonStatus.BeFit_E === true ? 1 : 0, // Applicable as per Pincode of BeFit
        // AddOn92: addonStatus.BeFit_F === true ? 1 : 0, // Applicable as per Pincode of BeFit
        // AddOn93: addonStatus.BeFit_G === true ? 1 : 0, // Not configured by UW
        // AddOn94: addonStatus.BeFit_H === true ? 1 : 0, // Not configured by UW
      },
    };

    HEALTH_PROPOSAL_SERVICES.getPeriumByAddonIcic(onSuccess, onError, param);
  };

  const FinalPremium = () => {
    const onSuccess = (res: any) => {
      console.log("res", res);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      final_premium: SELECTED_QUOTE_DATA?.premiumDetails?.finalPremium,
    };
    HEALTH_PROPOSAL_SERVICES.GET_FINAL_PREMIUM(onSuccess, onError, param);
  };

  const validate = () => {
    const onSuccess = (res: any) => {
      if (!res.results.error) {
        setLoader(false);
        dispatch(HealthSlice.actions.ADDON_DETAILS_DATA_ICIC(addonStatus));
        if (PAGE_STATUS) {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
              HEALTH_ROUTE.PREVIEW_DETAILS
            }?type=HP&companyCode=${
              SELECTED_QUOTE_DATA?.CompanyDetails.company_code
            }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
              SELECTED_QUOTE_DATA?.productDetails.id
            }&utm_source=${GET_QUERY_PARAMETERS().utm_source}&utm_medium=${
              GET_QUERY_PARAMETERS().utm_medium
            }&user_type=${GET_QUERY_PARAMETERS().userType}`
          );
        } else {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
              HEALTH_ROUTE.ADULT_ONE_DETAILS
            }?type=HP&companyCode=${
              SELECTED_QUOTE_DATA?.CompanyDetails.company_code
            }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
              SELECTED_QUOTE_DATA?.productDetails.id
            }&utm_source=${GET_QUERY_PARAMETERS().utm_source}&utm_medium=${
              GET_QUERY_PARAMETERS().utm_medium
            }&user_type=${GET_QUERY_PARAMETERS().userType}`
          );
        }
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    setLoader(true);
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      section: "add_ons_details",
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
      AddOn78: addonStatus.Critical_Illness === true ? 1 : 0,
      AddOn79: addonStatus.Maternity === true ? 1 : 0,
      AddOn80: addonStatus.New_Born === true ? 1 : 0,
      AddOn81: addonStatus.Vaccination === true ? 1 : 0,
      AddOn82: addonStatus.personal_accident === true ? 1 : 0,
      AddOn83: addonStatus.SI_Protector === true ? 1 : 0,
      AddOn84: addonStatus.Claim_Protector === true ? 1 : 0,
      AddOn85: addonStatus.Compassionate_Visit === true ? 1 : 0,
      AddOn86: addonStatus.Nursing_at_Home === true ? 1 : 0,
      AddOn87: addonStatus.BeFit_A === true ? 1 : 0,
      AddOn88: addonStatus.BeFit_B === true ? 1 : 0,
      AddOn89: addonStatus.BeFit_C === true ? 1 : 0,
      AddOn90: addonStatus.BeFit_D === true ? 1 : 0,
      // AddOn91: addonStatus.BeFit_E === true ? 1 : 0,
      // AddOn92: addonStatus.BeFit_F === true ? 1 : 0,
      // AddOn93: addonStatus.BeFit_G === true ? 1 : 0,
      // AddOn94: addonStatus.BeFit_H === true ? 1 : 0,
    };

    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  useEffect(() => {
    FinalPremium();
  }, [SELECTED_QUOTE_DATA]);

  useEffect(() => {
    setTimeout(() => {
      RECORD_CKYC_RESPONSE();
    }, 2000);
  }, [CKYC.CKYC_DETAILS]);

  const RECORD_CKYC_RESPONSE = () => {
    const onSuccess = (res: any) => {
      console.log("res...", res?.results);
      // validate();
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      details: CKYC.CKYC_DETAILS,
      section: "kyc_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
    };
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  return (
    <>
      {" "}
      {QUOTE_LOADER ? (
        <Box
          position="fixed"
          height="100%"
          width="100%"
          zIndex="991"
          top="0px"
          left="0px"
        />
      ) : null}
      {isMobile ? (
        <MAddonDetailsICIC
          isLoading={loader}
          addonStatus={addonStatus}
          updateMasterState={updateMasterState}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
          trueCount={trueCount}
        />
      ) : (
        <AddonDetailsICIC
          isLoading={loader}
          addonStatus={addonStatus}
          updateMasterState={updateMasterState}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
          trueCount={trueCount}
        />
      )}
    </>
  );
}
