import Masonry from "@mui/lab/Masonry";
import { Box } from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import { toast } from "react-toastify";
import AddressPreviewDetailBox from "../../../../../../Component/HI/Desktop/ProposalComponents/AddressPreviewDetailBox/AddressPreviewDetailBox";
import Footer from "../../../../../../Component/HI/Desktop/ProposalComponents/Footer/Footer";
import PreviewDetailBox from "../../../../../../Component/HI/Desktop/ProposalComponents/PreviewDetailBox/PreviewDetailBox";
import {
  HI_PATH,
  HI_PATH_POLICY_CONFIRM,
} from "../../../../../../Router/Path/HIPath";
import { QUOTES_SERVICES } from "../../../../../../Services/HI/QuotesService";
import { useAppSelector } from "../../../../../../redux/hooks";
import {
  FORMAT_DATE_DD_MM_YYYY,
  FORMAT_YYYY_MM_DD,
  isEmpty,
} from "../../../../../../SupportingFiles/HelpingFunction";

const ReviewDetails: React.FC = () => {
  const { proposalInformation, quote, kycResponseSlice } = useAppSelector(
    (state) => state
  );
  console.log("info", proposalInformation.ownerDetailsScreen);

  const quoteState = useAppSelector((state) => state.quote);
  const [load, setLoad] = useState<boolean>(false);
  const [previewDetails, setPreviewDetails] = useState<
    Array<{
      title: string;
      editLink: string;
      detailView: Array<{
        label: string;
        label_value: string;
      }>;
    }>
  >([
    {
      title: "Owner Details",
      editLink: `${HI_PATH.Proposal}/${HI_PATH.OWNER_DETAILS}`,
      detailView: [
        {
          label: "Full Name",
          label_value:
            proposalInformation.ownerDetailsScreen.ownerDetails.name.value,
        },
        {
          // label: "DOB",
          // label_value: proposalInformation.ownerDetailsScreen.ownerDetails.dob
          //   .value
          //   ? // ? proposalInformation.ownerDetailsScreen.ownerDetails.dob.value.toString()
          //     format(
          //       new Date(
          //         proposalInformation.ownerDetailsScreen.ownerDetails.dob.value
          //       ),
          //       "P"
          //     )
          //   : "",
          label: "DOB",
          label_value: proposalInformation.ownerDetailsScreen.ownerDetails.dob
            .value
            ? FORMAT_DATE_DD_MM_YYYY(
                proposalInformation.ownerDetailsScreen.ownerDetails.dob.value
              )
            : "",
        },
        {
          label: "Gender",
          label_value:
            proposalInformation.ownerDetailsScreen.ownerDetails.gender.value,
        },
        {
          label: "Mobile",
          label_value:
            proposalInformation.ownerDetailsScreen.ownerDetails.mobile.value,
        },
        {
          label: "Email",
          label_value:
            proposalInformation.ownerDetailsScreen.ownerDetails.email.value,
        },
        {
          label: "Pancard",
          label_value:
            proposalInformation.ownerDetailsScreen.ownerDetails.pancard.value,
        },
      ],
    },
    // {
    //   title: "Co-Owner Details",
    //   editLink: `${HI_PATH.Proposal}/${HI_PATH.OWNER_DETAILS,
    //   detailView: [
    //     {
    //       label: "Full Name",
    //       label_value:
    //         proposalInformation.ownerDetailsScreen.coOwnerDetails.name.value,
    //     },
    //     {
    //       label: "DOB",
    //       label_value: proposalInformation.ownerDetailsScreen.coOwnerDetails.dob
    //         .value
    //         ? format(
    //             new Date(
    //               proposalInformation.ownerDetailsScreen.coOwnerDetails.dob.value
    //             ),
    //             "P"
    //           )
    //         : "",
    //     },
    //     {
    //       label: "Gender",
    //       label_value:
    //         proposalInformation.ownerDetailsScreen.coOwnerDetails.gender.value,
    //     },
    //     {
    //       label: "Mobile",
    //       label_value:
    //         proposalInformation.ownerDetailsScreen.coOwnerDetails.mobile.value,
    //     },
    //     {
    //       label: "Email",
    //       label_value:
    //         proposalInformation.ownerDetailsScreen.coOwnerDetails.email.value,
    //     },
    //   ],
    // },
    !isEmpty(proposalInformation.ownerDetailsScreen.coOwnerDetails.name.value)
      ? {
          title: "Co-Owner Details",
          editLink: `${HI_PATH.Proposal}/${HI_PATH.OWNER_DETAILS}`,
          detailView: [
            {
              label: "Full Name",
              label_value:
                proposalInformation.ownerDetailsScreen.coOwnerDetails.name
                  .value,
            },
            // {
            //   label: "DOB",
            //   label_value: proposalInformation.ownerDetailsScreen.coOwnerDetails
            //     .dob.value
            //     ? format(
            //         new Date(
            //           proposalInformation.ownerDetailsScreen.coOwnerDetails.dob.value
            //         ),
            //         "P"
            //       )
            //     : "",
            // },
            {
              label: "DOB",
              label_value: proposalInformation.ownerDetailsScreen.coOwnerDetails
                .dob.value
                ? proposalInformation.ownerDetailsScreen.coOwnerDetails.dob.value.toString()
                : "",
            },
            {
              label: "Gender",
              label_value:
                proposalInformation.ownerDetailsScreen.coOwnerDetails.gender
                  .value,
            },
            {
              label: "Mobile",
              label_value:
                proposalInformation.ownerDetailsScreen.coOwnerDetails.mobile
                  .value,
            },
            {
              label: "Email",
              label_value:
                proposalInformation.ownerDetailsScreen.coOwnerDetails.email
                  .value,
            },
          ],
        }
      : { detailView: [], editLink: "", title: "" },
    {
      title: "Address Details",
      editLink: `${HI_PATH.Proposal}/${HI_PATH.ADDRESS_DETAILS}`,
      detailView: [
        {
          label: "Address",
          label_value:
            proposalInformation.addressDetails.propertyAddress.address.value,
        },
        {
          label: "City",
          label_value:
            proposalInformation.addressDetails.propertyAddress.city.value,
        },
        {
          label: "Pincode",
          label_value:
            proposalInformation.addressDetails.propertyAddress.pincode.value,
        },

        // ...(proposalInformation.addressDetails.isSame
        //   ? [
        //       {
        //         label: "Mailing Address",
        //         label_value:
        //           proposalInformation.addressDetails.communicationAddress
        //             .address.value,
        //       },
        //       {
        //         label: "Mailing City",
        //         label_value:
        //           proposalInformation.addressDetails.communicationAddress.city
        //             .value,
        //       },
        //       {
        //         label: "Mailing Pincode",
        //         label_value:
        //           proposalInformation.addressDetails.communicationAddress
        //             .pincode.value,
        //       },
        //     ]
        //   : []),
      ],
    },

    // {
    //   title: "In-Built Covers",
    //   editLink: `${HI_PATH.Proposal}/${HI_PATH.IN_BUILT_COVERAGES,
    //   detailView: [
    //     {
    //       label: "Loss of Rent",
    //       label_value: proposalInformation.inbuiltCoverages.lossOfRentSelcted
    //         ? "Yes"
    //         : "No",
    //     },
    //     {
    //       label: "No of Months",
    //       label_value: proposalInformation.inbuiltCoverages.num_months_rent,
    //     },
    //     {
    //       label: "Loss of Rent Alternative",
    //       label_value: proposalInformation.inbuiltCoverages.rentAltSelected
    //         ? "Yes"
    //         : "No",
    //     },
    //     {
    //       label: "No of Months for Alternative",
    //       label_value: proposalInformation.inbuiltCoverages.num_months_alt,
    //     },
    //     {
    //       label: "Cover for Valuable Contents",
    //       label_value: proposalInformation.inbuiltCoverages
    //         .isCoverForValuableContent
    //         ? "Yes"
    //         : "No",
    //     },
    //   ],
    // },
    {
      title: "Addon Covers",
      editLink: `${HI_PATH.Proposal}/${HI_PATH.ADD_ONS}`,
      detailView: [
        {
          label: "Personal Accident Cover",
          label_value: proposalInformation.addonCoverages.isPac ? "Yes" : "No",
        },
        {
          label: "Personal Accident Cover for Spouse",
          label_value: proposalInformation.addonCoverages.isPacSpouse
            ? "Yes"
            : "No",
        },
        {
          label: "Earthquake Cover",
          label_value: proposalInformation.addonCoverages.isEQcover
            ? "Yes"
            : "No",
        },
      ],
    },
    // {
    //   title: "Property Details",
    //   editLink: `${HI_PATH.Proposal}/${HI_PATH.PROPERTY_DETAILS,
    //   detailView: [
    //     {
    //       label: "Year of Construction",
    //       label_value:
    //         proposalInformation.propertyDetails.constructionYear.value,
    //     },
    //     {
    //       label: "Type of Building",
    //       label_value: proposalInformation.propertyDetails.typeOfBuilding.value,
    //     },
    //     {
    //       label: "Floor Number",
    //       label_value: proposalInformation.propertyDetails.floorNo?.value
    //         ? proposalInformation.propertyDetails.floorNo?.value
    //         : "",
    //     },
    //   ],
    // },
    {
      title: "Property Details",
      editLink: `${HI_PATH.Proposal}/${HI_PATH.PROPERTY_DETAILS}`,
      detailView: [
        {
          label: "Year of Construction",
          label_value:
            proposalInformation.propertyDetails.constructionYear.value,
        },
        {
          label: "Type of Building",
          label_value: proposalInformation.propertyDetails.typeOfBuilding.value,
        },
        // Check if the type of building is not "Standalone House"
        ...(proposalInformation.propertyDetails.typeOfBuilding.value !==
        "Standalone House"
          ? [
              {
                label: "Floor Number",
                label_value: proposalInformation.propertyDetails.floorNo?.value
                  ? proposalInformation.propertyDetails.floorNo?.value
                  : "",
              },
            ]
          : []),
      ],
    },
  ]);

  const handleMakePaymentClicked = () => {
    setLoad(true);
    const loadingToast = toast.loading("Loading...");

    const onSuccess = (res: any) => {
      toast.dismiss("Updating Proposal...");
      setLoad(false);
      if (res.data.data.link) {
        window.location.href = res.data.data.link;
      }
    };

    const onError = (err: any) => {
      toast.dismiss(loadingToast);
      setLoad(false);
      toast.error("Failed!");
    };

    let redirectionURL = "";
    let cancelURL = "";

    const dobValue =
      proposalInformation.ownerDetailsScreen.ownerDetails.dob.value;

    // Convert the dobValue to string if it's a Date, or leave it as a string, but default to an empty string if it's null
    const formattedDob: string =
      dobValue instanceof Date
        ? dobValue.toISOString().split("T")[0] // Convert Date to YYYY-MM-DD format
        : dobValue ?? ""; // Use an empty string if dobValue is null

    // Now you can call the function with a guaranteed string type
    const result = FORMAT_YYYY_MM_DD(formattedDob);

    const dto: {
      quote_no: string;
      digitKycId: string;
      dob: string;
      redirectionURL: string;
      cancelURL: string;
    } = {
      quote_no: quote.quote_id,
      redirectionURL:
        window.location.protocol +
        "//" +
        window.location.host +
        "/" +
        HI_PATH_POLICY_CONFIRM,
      cancelURL:
        window.location.protocol +
        "//" +
        window.location.host +
        "/" +
        HI_PATH_POLICY_CONFIRM,
      dob: result ? result : "",
      digitKycId: kycResponseSlice.digitKYCId,
    };

    QUOTES_SERVICES.create_quote(dto, onSuccess, onError);
  };

  return (
    <Box className="proposalPreview">
      <h5 className="sectionTitle">Just make sure, all details are correct</h5>
      <Masonry columns={2} spacing={3} className="px-0 mx-0">
        {/* Personal, Address Details Box */}
        {previewDetails.map((data, index) => {
          let baseUrl = HI_PATH.Proposal + "/" + quoteState.quote_id + "/";

          return (
            <>
              {isEmpty(data.title) ? null : data.title === "Address Details" ? (
                <AddressPreviewDetailBox />
              ) : (
                <PreviewDetailBox
                  title={data.title}
                  data={data.detailView}
                  editLink={data.editLink}
                  isCommunicationSameAsPermanent={
                    proposalInformation.addressDetails.isSame
                  }
                  isCoOwner={
                    proposalInformation.ownerDetailsScreen.selectedCoOwner
                  }
                />
              )}
            </>
          );
        })}
      </Masonry>
      <Footer continue_function={handleMakePaymentClicked} loader={load} />
    </Box>
  );
};

export default ReviewDetails;
