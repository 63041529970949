import { Box, Checkbox, FormControlLabel } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import RKTextField from "../../../../../Component/FieldTypes/RKTextField/RKTextField";
import MFooter from "../../../../../Component/MFooter/MFooter";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { TAddressDetails } from "../../../../../types/Common/TAddressDetails";
import "./../../../../../SCSS/CommonSCSS/MProposalForms.scss";
import { FIND_LABEL_OF_VALUE } from "../../../../../SupportingFiles/HelpingFunction";

const MAddressDetails = ({
  updateMasterState,
  validateForm,
  handleClick,
  permanentAddress,
}: {
  updateMasterState: Function;
  validateForm: Function;
  handleClick: Function;
  permanentAddress: TAddressDetails;
}) => {
  const navigate = useNavigate();
  const { PAGE_STATUS, SELECTED_QUOTE_DATA } = useAppSelector(
    (state) => state.Health
  );
  const { AREA_TOWN } = useAppSelector((state) => state.CommonDropdownData);

  const { CKYC_DETAILS } = useAppSelector((state) => state.CKYC);
  const Health = useAppSelector((state) => state.Health);
  return (
    // <Box>
    //   <Grid container className="row" columnSpacing={3} marginBottom={"24px"}>
    //     <Grid xs={12}>
    //       <h3>Address Details</h3>
    //     </Grid>
    //   </Grid>
    //   <Grid
    //     container
    //     className="row"
    //     columnSpacing={3}
    //     marginBottom={"24px"}
    //     rowGap={"16px"}
    //     alignItems={"center"}
    //   >
    //     <Grid xs={6}>
    //       <TextInputField title="Pincode" />
    //     </Grid>
    //     <Grid xs={6}>
    //       <h6 style={{ color: "#141414", letterSpacing: "-0.07" }}>
    //         Mohali,Punjab
    //       </h6>
    //     </Grid>
    //     <Grid xs={12}>
    //       <TextInputField title="H. No. / Building" />
    //     </Grid>
    //     <Grid xs={12}>
    //       <TextInputField title="Area / Town / Locality" />
    //     </Grid>
    //     <Grid xs={12}>
    //       <CustomCheckbox
    //         label={
    //           "Mailing / Correspondence address is same as permanent address."
    //         }
    //         attrName="mailingCorrespondingAddress"
    //         value_update={handleCheckboxChange}
    //         value={isChecked}
    //         defaultChecked={false}
    //         disabled={false}
    //       />
    //     </Grid>
    //     {!isChecked && (
    //       <>
    //         <Grid xs={6}>
    //           <TextInputField title="Pincode" />
    //           </Grid>
    //         <Grid xs={6}>
    //           <h6 style={{ color: "#141414", letterSpacing: "-0.07" }}>
    //             Mohali,Punjab
    //           </h6>
    //         </Grid>
    //         <Grid xs={12}>
    //           <TextInputField title="H. No. / Building" />
    //         </Grid>
    //         <Grid xs={12}>
    //           <TextInputField title="Area / Town / Locality" />
    //         </Grid>
    //       </>
    //     )}
    //   </Grid>
    // </Box>

    <Box className="proposalAddress">
      <h5 className="sectionTitle">Address Details</h5>
      <Grid
        container
        columnSpacing={2}
        rowSpacing={3}
        marginBottom={"12px"}
        marginTop={"4px"}
        alignItems="center"
      >
        <Grid xs={6}>
          <RKTextField
            validation_type="NUMBER"
            max_length={6}
            class_name="inputField"
            title="Pincode"
            value={permanentAddress.pincode.value}
            attrName="pincode"
            value_update={updateMasterState}
            warn_status={permanentAddress.pincode.warning}
            error_message="Enter Pincode"
            // disabled={!goDigit}
            disabled={CKYC_DETAILS.kycPincode ? true : false}
          />
        </Grid>
        <Grid xs={6} sx={{ fontSize: "14px" }}>
          {`${permanentAddress.city}, ${permanentAddress.state}`}
        </Grid>
        <Grid xs={12}>
          <RKTextField
            class_name="inputField"
            title="H.No. / Building"
            value={permanentAddress.addressLineOne.value}
            attrName="addressLineOne"
            value_update={updateMasterState}
            warn_status={permanentAddress.addressLineOne.warning}
            error_message="Enter Address"
            disabled={CKYC_DETAILS.kycAddress1 ? true : false}
          />
        </Grid>
        <Grid xs={12}>
          <RKTextField
            class_name="inputField"
            title="Area / Town / Locality"
            value={
              SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G015"
                ? FIND_LABEL_OF_VALUE(
                    AREA_TOWN,
                    permanentAddress.addressLineTwo.value
                  )
                : permanentAddress.addressLineTwo.value
            }
            attrName="addressLineTwo"
            value_update={updateMasterState}
            warn_status={permanentAddress.addressLineTwo.warning}
            error_message="Enter Address"
            disabled={CKYC_DETAILS.kycAddress2 ? true : false}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid xs={12}>
          <FormControlLabel
            className="proposalCheckBoxWrapper mr-0 mb-4 mt-2"
            control={
              <Checkbox
                id="mailingCheckboxAddress"
                defaultChecked={permanentAddress.isMailingAddressSame}
                value={permanentAddress.isMailingAddressSame}
                onClick={() => handleClick()}
              />
            }
            label="Mailing / Correspondence address is same as permanent address."
          />
        </Grid>
      </Grid>

      {/* Mailing Address */}
      {!permanentAddress.isMailingAddressSame && (
        <Box>
          <Grid
            container
            columnSpacing={2}
            rowSpacing={3}
            marginBottom={"12px"}
            alignItems="center"
          >
            <Grid xs={6}>
              <RKTextField
                validation_type="NUMBER"
                max_length={6}
                class_name="inputField"
                title="Pincode"
                value={permanentAddress.mailingPinCode.value}
                attrName="mailingPinCode"
                value_update={updateMasterState}
                warn_status={permanentAddress.mailingPinCode.warning}
                error_message="Enter Pincode"
              />
            </Grid>
            <Grid xs={6} sx={{ fontSize: "14px" }}>
              {`${permanentAddress.mailingCity}, ${permanentAddress.mailingState}`}
            </Grid>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title="H.No. / Building"
                value={permanentAddress.mailingAddressLineOne.value}
                attrName="mailingAddressLineOne"
                value_update={updateMasterState}
                warn_status={permanentAddress.mailingAddressLineOne.warning}
                error_message="Enter Address"
              />
            </Grid>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title="Area / Town / Locality"
                value={
                  SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G015"
                    ? FIND_LABEL_OF_VALUE(
                        AREA_TOWN,
                        permanentAddress.mailingAddressLineTwo.value
                      )
                    : permanentAddress.mailingAddressLineTwo.value
                }
                attrName="mailingAddressLineTwo"
                value_update={updateMasterState}
                warn_status={permanentAddress.mailingAddressLineTwo.warning}
                error_message="Enter Address"
              />
            </Grid>
          </Grid>
        </Box>
      )}

      <MFooter
        textName={PAGE_STATUS ? "Update" : "Continue"}
        forward={validateForm}
      />
    </Box>
  );
};

export default MAddressDetails;
