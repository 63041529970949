export const Yes_Or_No = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];
  
  export const Diabetes_when =[
    { label: "Less than 10 Years", value: "L10Y" },
    { label: "More than 10 Years", value: "M10Y" },
  ]

  export const Diabetes_type =[
    { label: "Insulin dependent", value: "TYPE1" },
    { label: "Non -insulin dependent", value: "TYPE2" },
  ]

  export const Diabetes_HBA1C =[
    { label: "Less than 7%", value: "L7P" },
    { label: "More than or equal to 7.1%", value: "M71" },
  ]

  export const HYPERTENSION_WHEN =[
    { label: "Less than 10 Years", value: "L10Y" },
    { label: "More than 10 Years", value: "M10Y" },
  ]

  export const ASTHMA_WHEN =[
    { label: "Less than 15 years", value: "L15Y" },
    { label: "More than 15 years", value: "M15Y" },
  ]
 
  export const ASTHMA_SYMPTOMS =[
    { label: "Less than twice a week", value: "L2WK" },
    { label: "Continuous symptoms during waking hours and frequent symptoms at night", value: "CSYMP" },
  ]

  export const ASTHMA_PULMONARY_TEST =[
    { label: "Less than 70", value: "L70" },
    { label: "More than 70", value: "M70" },
  ]

  export const HYPERLIPIDAEMIA_WHEN =[
    { label: "Less than 10 Years", value: "L10Y" },
    { label: "More than 10 Years", value: "M10Y" },
  ]

  export const HYPERLIPIDAEMIA_ORAL_MEDICINE_COUNT =[
    { label: "2", value: "2" },
    { label: "More than 2", value: "M2" },
  ]

  export const HYPERLIPIDAEMIA_CHOLESTROL_LEVEL =[
    { label: "Less than 240mg/dl", value: "L240MGDI" },
    { label: "More than 240mg/dl", value: "M240MGDI" },
  ]


  