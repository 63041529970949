import { NODE_DOMAIN, PHP_DOMAIN } from "../Domain";

export const CAR_URLS = {
  GET_VEHICLE_NO_INFO: `${NODE_DOMAIN}/api/v1/premium/vehicledetails-pro`,
  GET_PROPOSAL_INFO: `${NODE_DOMAIN}/api/v1/premium/proposal-details`,
  GET_PREVIOUS_INSURER_LIST: `${PHP_DOMAIN}/api/previous-insurers/get-lists.json`,
  UPDATE_ADDON_STATUS: `${PHP_DOMAIN}/api/car-insurances/update-addon.json`,
  PREMIUM_RECALCULATION: `${PHP_DOMAIN}/api/car-insurances/recalculate-premium.json`,
  GET_CITY_STATE_BY_PINCODE: `${PHP_DOMAIN}/api/state-city-maps/get-details.json`,
  CAPTURE_DATA: `${PHP_DOMAIN}/api/car-proposals/capture-record.json`,
  GET_CITY_STATE_BY_PINCODE_ONLY: `${PHP_DOMAIN}/api/state-city-maps/getDetailsByPincode.json`,
  PAYMENT_URL: `${PHP_DOMAIN}/api/car-proposals/create-proposal.json`,
  CREATE_INSPECTION: `${PHP_DOMAIN}/api/car-proposals/car-inspection.json`,
  VEHICLE_ON_LOAN: `${PHP_DOMAIN}/api/financier-masters/get-list-auto.json`,
  BULK_UPDATE_ADDON_STATUS: `${NODE_DOMAIN}/api/v1/premium/update-bulk-addon`,
  GET_NOMINEE_RELATIONSHIP_LIST: `${PHP_DOMAIN}/api/product-nominee-relationship-maps/get-lists.json`,
  GET_PREVIEW_PAGE_DATA: `${NODE_DOMAIN}/api/v1/premium/preview-details`,
  GENERATE_OTP: `${NODE_DOMAIN}/api/v1/proposal/generateOtp`,
  VERIFY_OTP: `${NODE_DOMAIN}/api/v1/proposal/validateOtp`,
};
