import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Outlet } from "react-router-dom";
import Navbar from "../../../Component/Navbar/Navbar";
import MNonTermNavbar from "../../../Component/NonTerm/MNonTermNavbar/MNonTermNavbar";
import NonTermLeftSidebarProposerDetails from "../../../Component/NonTerm/NonTermLeftSidebar/NonTermLeftSidebarProposerDetails";
import useIsMobile from "../../../SupportingFiles/MobileProvider";

function InvestmentProposalContainer() {
  const isMobile = useIsMobile();
  return (
    <>
      {isMobile ? (
        <Box className="proposalWrapper_m">
          <MNonTermNavbar />
          <Grid container spacing={0} className="d-non">
            <Grid xs={12} className="proposalContentSection">
              <Outlet />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box className="proposalWrapper">
          <Navbar />
          <Grid container spacing={3} className="pb-0">
            <NonTermLeftSidebarProposerDetails />
            <Grid xs className="proposalContentSection">
              <Outlet />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}

export default InvestmentProposalContainer;
