import blowfish from "egoroof-blowfish";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ETermCompanyCode } from "../../../../Enum/ETermCompanyCode";
import PaymentConfirmation from "../../../../Page/Desktop/TermFullJourney/ICICI/PaymentConfirmation/PaymentConfirmation";
import PaymentFailed from "../../../../Page/Desktop/TermFullJourney/ICICI/PaymentFailed/PaymentFailed";
import MPaymentConfirmation from "../../../../Page/Mobile/TermFullJourney/ICICI/PaymentConfirmation/MPaymentConfirmation";
import { TERM_FULL_JOURNEY_ROUTES } from "../../../../Router/Path/TermFullJourneyRoutes";
import { PROPOSAL_PAGE_SERVICES } from "../../../../Services/TermFullJourneyServices/ProposalPageServices";
import { TermFullJourneySlice } from "../../../../redux/slice/TermFullJourney/TermFullJourneySlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { TermSlice } from "../../../../redux/slice/Term/TermSlice";

function PaymentConfirmationPageContainer() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { SELECTED_QUOTE_DATA, ADD_FORM_RESPONSE } = useAppSelector(
    (state) => state.Term
  );
  const dispatch = useAppDispatch();
  const [paymentStatus, setPaymentStatus] = useState<
    "SUCCESS" | "FAIL" | "PENDING"
  >("FAIL");
  const [paymentMessage, setPaymentMessage] = useState("");

  // useEffect(() => {
  //   if (
  //     SELECTED_QUOTE_DATA.companyDetails.company_code === ETermCompanyCode.HDFC
  //   ) {
  //     const windowURL = window.location.search;
  //     const params = new URLSearchParams(windowURL);

  //     const appnum = params.get("appnum");
  //     const paymentstatus = params.get("paymentstatus");
  //     setPaymentMessage(paymentStatus);
  //     setPaymentStatus(paymentstatus === "Success" ? "SUCCESS" : "FAIL");

  //     dispatch(
  //       TermSlice.actions.UPDATE_QUOTE_DATA([
  //         {
  //           key: "appno",
  //           value: appnum || "",
  //         },
  //         {
  //           key: "transaction_id",
  //           value: appnum || "",
  //         },
  //       ])
  //     );

  //     sendConfirmationPageData({
  //       appnum: appnum,
  //       paymentstatus: paymentstatus,
  //     });
  //   } else if (
  //     SELECTED_QUOTE_DATA.companyDetails.company_code === ETermCompanyCode.ICICI
  //   ) {
  //     const windowURL = window.location.search;
  //     const params = new URLSearchParams(windowURL);

  //     const key = "!i@*6@($%(";
  //     let encrypted = params.get("encJson") || "";
  //     encrypted = encrypted.replace(/\s/g, "+");
  //     console.log("encrypted", encrypted);
  //     const bf = new blowfish(key, blowfish.MODE.ECB, blowfish.PADDING.NULL);

  //     // Convert the encrypted value from Base64 to Uint8Array
  //     const encryptedBytes = Uint8Array.from(atob(encrypted), (c) =>
  //       c.charCodeAt(0)
  //     );

  //     // Decrypt the value
  //     const decrypted = bf.decode(encryptedBytes);

  //     const cleanedDecrypted = `${decrypted}`.replace(
  //       /[\u0000-\u001F\u007F-\u009F]/g,
  //       ""
  //     );

  //     const decryptedJSON = JSON.parse(`${cleanedDecrypted}`);

  //     const app_no = decryptedJSON.identificationCode;
  //     const transaction_id = decryptedJSON.transactionID;
  //     dispatch(
  //       TermSlice.actions.UPDATE_QUOTE_DATA([
  //         {
  //           key: "transaction_id",
  //           value: transaction_id,
  //         },
  //         {
  //           key: "appno",
  //           value: app_no || decryptedJSON?.identificationCode,
  //         },
  //       ])
  //     );

  //     const paymentStatus = decryptedJSON.paymentStatus;
  //     setPaymentStatus(
  //       `${paymentStatus}`.toLowerCase() === "success" ||
  //         `${paymentStatus}`.toLowerCase() === "pending realisation"
  //         ? "SUCCESS"
  //         : "FAIL"
  //     );
  //     sendConfirmationPageData(decryptedJSON);
  //   }
  // }, []);

  useEffect(() => {
    if (
      SELECTED_QUOTE_DATA.companyDetails.company_code === ETermCompanyCode.HDFC
    ) {
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);

      const appnum = params.get("appnum");
      const paymentstatus = params.get("paymentstatus");
      setPaymentMessage(paymentStatus);
      setPaymentStatus(paymentstatus === "Success" ? "SUCCESS" : "FAIL");

      dispatch(
        TermSlice.actions.UPDATE_QUOTE_DATA([
          {
            key: "appno",
            value: appnum || "",
          },
          {
            key: "transaction_id",
            value: appnum || "",
          },
        ])
      );

      sendConfirmationPageData({
        appnum: appnum,
        paymentstatus: paymentstatus,
      });
    } else if (
      SELECTED_QUOTE_DATA.companyDetails.company_code === ETermCompanyCode.ICICI
    ) {
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);

      const key = "!i@*6@($%("; // Blowfish key
      let encrypted = params.get("encJson") || "";
      encrypted = encrypted.replace(/\s/g, "+"); // Ensure proper format of the encrypted string
      console.log("encrypted", encrypted);

      const bf = new blowfish(key, blowfish.MODE.ECB, blowfish.PADDING.NULL);

      try {
        // Convert encrypted value from Base64 to Uint8Array
        const encryptedBytes = Uint8Array.from(atob(encrypted), (c) =>
          c.charCodeAt(0)
        );

        // Decrypt the value
        let decryptedString = bf.decode(encryptedBytes);

        // Ensure that decrypted is a string and valid before parsing
        if (decryptedString) {
          console.log("Decrypted string:", decryptedString);

          // Remove control characters and unwanted characters from the decrypted string
          const cleanedDecrypted = `${decryptedString}`.replace(
            /[\u0000-\u001F\u007F-\u009F]/g,
            ""
          );

          try {
            const decryptedJSON = JSON.parse(cleanedDecrypted);
            console.log("Decrypted JSON:", decryptedJSON);

            const app_no = decryptedJSON.identificationCode;
            const transaction_id = decryptedJSON.transactionID;

            dispatch(
              TermSlice.actions.UPDATE_QUOTE_DATA([
                { key: "transaction_id", value: transaction_id },
                {
                  key: "appno",
                  value: app_no || decryptedJSON?.identificationCode,
                },
              ])
            );

            const paymentStatus = decryptedJSON.paymentStatus;
            setPaymentStatus(
              `${paymentStatus}`.toLowerCase() === "success" ||
                `${paymentStatus}`.toLowerCase() === "pending realisation"
                ? "SUCCESS"
                : "FAIL"
            );
            sendConfirmationPageData(decryptedJSON);
          } catch (error) {
            console.error("Error parsing decrypted JSON:", error);
          }
        } else {
          console.error("Decryption returned null or invalid data.");
        }
      } catch (decryptionError) {
        console.error("Error during decryption:", decryptionError);
      }
    }
  }, [SELECTED_QUOTE_DATA, dispatch]); // Include dependencies

  const sendConfirmationPageData = (response: any) => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };

    const data = {
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
      response: response,
    };

    PROPOSAL_PAGE_SERVICES.sendPaymentConfirmationData(
      onSuccess,
      onError,
      data
    );
  };

  const continue_function = () => {
    navigate(TERM_FULL_JOURNEY_ROUTES.JOURNEY_REQUIREMENT);
  };
  return (
    <>
      {isMobile ? (
        <>
          {paymentStatus === "FAIL" ? (
            <PaymentFailed status={paymentMessage} />
          ) : (
            <MPaymentConfirmation continue_function={continue_function} />
          )}
        </>
      ) : (
        <>
          {paymentStatus === "FAIL" ? (
            <PaymentFailed status={paymentMessage} />
          ) : (
            <PaymentConfirmation
              continue_function={continue_function}
              payment_status={paymentStatus}
            />
          )}
        </>
      )}
    </>
  );
}

export default PaymentConfirmationPageContainer;
