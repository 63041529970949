import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddressDetails from "../../../Page/Desktop/Car/GoDigit/AddressDetails";
import MAddressDetails from "../../../Page/Mobile/Car/GoDigit/MAddressDetails";
import { CAR_ROUTES } from "../../../Router/Path/CarRoutes";
import { CAR_SERVICES } from "../../../Services/car-node/CarServices";
import { CarSlice } from "../../../redux/slice/Car/CarSlice";
import { useAppSelector } from "../../../redux/hooks";
import {
  isEmpty,
  validatePincode,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { CLIENTS } from "../../../URLCollection/Clients";
import { TAddressDetails } from "../../../types/Common/TAddressDetails";
import {
  pushMotorDataLayer,
  sendEventToNativeApp,
} from "../../../utils/analytics";

const AddressDetailsContainer = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    ADDRESS_DETAILS,
    ADD_FORM_RESPONSE,
    PAGE_STATUS,
    SELECTED_QUOTE_DATA,
    REDIRECTED,
  } = useAppSelector((state) => state.Car);

  const [pageStatus, setPageStatus] = useState<number>(1);

  // Permanent Address
  const [permanentAddress, setPermanentAddress] =
    useState<TAddressDetails>(ADDRESS_DETAILS);

  const updateMasterState = (attrName: any, value: any) => {
    console.log("attrName", attrName);
    if (attrName === "pageStatus") {
      validateForm();
    } else if (attrName === "backbutton") {
      navigate(-1);
    } else {
      attrName[3]({
        ...attrName[2],
        [attrName[0]]: {
          [attrName[1]]: value,
          warning:
            attrName[0] === "pincode"
              ? !validatePincode(value)
              : isEmpty(value),
        },
      });
    }
  };

  const handleClick = () => {
    setPermanentAddress({
      ...permanentAddress,
      isMailingAddressSame: !permanentAddress.isMailingAddressSame,
    });
  };

  const GetCityStateByPin = (key: string, value: any) => {
    const onSuccess = (res: any) => {
      const results = res;
      const error = results.error;
      if (error === false) {
        const response = results?.response;
        const city = response?.city_name;
        const state = response?.state_name;
        let updatedPermanentAddress: TAddressDetails = { ...permanentAddress };
        if (key === "cityState") {
          updatedPermanentAddress = {
            ...updatedPermanentAddress,
            city: city,
            state: state,
          };

          setPermanentAddress(updatedPermanentAddress);
        } else if (key === "mailingAddresscityState") {
          updatedPermanentAddress = {
            ...updatedPermanentAddress,
            mailingCity: city,
            mailingState: state,
          };

          setPermanentAddress(updatedPermanentAddress);
        }
      }
    };

    const onError = (err: any) => {
      console.log("error", err);
    };

    let param = {
      pincode: value,
    };

    CAR_SERVICES.GetCityStateByPin(onSuccess, onError, param);
  };

  const validateForm = () => {
    let data: TAddressDetails = { ...permanentAddress };

    data = {
      ...data,
      pincode: {
        ...data.pincode,
        warning: isEmpty(data.pincode.value),
      },
      addressLineOne: {
        ...data.addressLineOne,
        warning: isEmpty(data.addressLineOne.value),
      },
      addressLineTwo: {
        ...data.addressLineTwo,
        warning: isEmpty(data.addressLineTwo.value),
      },
    };
    if (!data.isMailingAddressSame) {
      data = {
        ...data,
        mailingPinCode: {
          ...data.mailingPinCode,
          warning: isEmpty(data.mailingPinCode.value),
        },
        mailingAddressLineOne: {
          ...data.mailingAddressLineOne,
          warning: isEmpty(data.mailingAddressLineOne.value),
        },
        mailingAddressLineTwo: {
          ...data.mailingAddressLineTwo,
          warning: isEmpty(data.mailingAddressLineTwo.value),
        },
      };
    }
    // console.log('dataa',data)
    setPermanentAddress(data);
    let hasError =
      data.pincode.warning ||
      data.addressLineOne.warning ||
      data.addressLineTwo.warning;

    if (!data.isMailingAddressSame) {
      hasError =
        data.mailingPinCode.warning ||
        data.mailingAddressLineOne.warning ||
        data.mailingAddressLineTwo.warning ||
        hasError;
    }

    if (!hasError) {
      if (window.location.hostname === CLIENTS.JIO) {
        pushMotorDataLayer(
          "jioinsure_flow_details",
          "address",
          "proceed",
          "car",
          REDIRECTED ? "JFS app" : "Insure website"
        );
        const eventProps = {
          action: "address",
          click: "continue",
          type: "car",
          EntrySource: REDIRECTED ? "JFS app" : "Insure website",
        };
        sendEventToNativeApp("jioinsure_flow_details", eventProps);
      }
      navigate(
        `${CAR_ROUTES.PROPOSAL_PAGE}/${CAR_ROUTES.PREVIEW_DEATILS}?type=CAR&companyCode=${SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id}`
      );
      CAPTURE_DATA();
      dispatch(CarSlice.actions.ADDRESS_DETAILS(data));
    }
  };

  const CAPTURE_DATA = () => {
    let dto: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      addressPPincode: permanentAddress.pincode.value,
      addressPHouse: permanentAddress.addressLineOne.value,
      addressPArea: permanentAddress.addressLineTwo.value,
      addressPCity: permanentAddress.city,
      addressPState: permanentAddress.state,
      addressMailingSame: permanentAddress.isMailingAddressSame ? "1" : "0",
      addressMPincode: permanentAddress.mailingPinCode.value,
      addressMHouse: permanentAddress.mailingAddressLineOne.value,
      addressMArea: permanentAddress.mailingAddressLineTwo.value,
      addressMCity: permanentAddress.mailingCity,
      addressMState: permanentAddress.mailingState,
      section: "address_details",
      insurance_type: "CAR",
    };
    const onSuccess = (res: any) => {
      const response = res;
      const error = response.error;

      if (error === false) {
        // navigate(`${CAR_ROUTES.PROPOSAL_PAGE}/${CAR_ROUTES.PREVIEW_DEATILS}`);
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    CAR_SERVICES.Capture_data(onSuccess, onError, dto);
  };

  useEffect(() => {
    GetCityStateByPin("cityState", permanentAddress.pincode.value);
  }, [permanentAddress.pincode.value]);

  useEffect(() => {
    GetCityStateByPin(
      "mailingAddresscityState",
      permanentAddress.mailingPinCode.value
    );
  }, [permanentAddress.mailingPinCode.value]);

  return (
    <>
      {isMobile ? (
        <MAddressDetails
          permanentAddress={permanentAddress}
          updateMasterState={updateMasterState}
          handleClick={handleClick}
          setPermanentAddress={setPermanentAddress}
          pageStatus={pageStatus}
          validateForm={validateForm}
        />
      ) : (
        <AddressDetails
          permanentAddress={permanentAddress}
          updateMasterState={updateMasterState}
          handleClick={handleClick}
          setPermanentAddress={setPermanentAddress}
          pageStatus={pageStatus}
          validateForm={validateForm}
        />
      )}
    </>
  );
};

export default AddressDetailsContainer;
