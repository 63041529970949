import React from "react";
import { Box, Collapse } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import AddonQuestion from "../../../../../Component/AddonQuestion/AddonQuestion";
import {
  TGOLDEN_SHIELDICICIAddon,
  TELEVATEICICIAddon,
} from "../../../../../types/Health/ProposalDeatail/TICICAddon/TICICAddon";
import Footer from "../../../../../Component/Footer/Footer";
import { useAppSelector } from "../../../../../redux/hooks";
import Loader from "../../../../../Component/Loader/Loader";
import { HEALTH_ROUTE } from "../../../../../Router/Path/HealthRoutes";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import CustomCheckbox from "../../../../../Component/FieldTypes/CustomCheckbox/CustomCheckbox";
import MemberRadioButtonNew from "../../../../../Component/MemberRadioButton/MemberRadioButtonNew";
const AddonDetailElevateICICI = ({
  addonStatus,
  updateMasterState,
  validate,
  PAGE_STATUS,
  isLoading,
  addonData,
  memberstate,
}: {
  addonStatus: TELEVATEICICIAddon;
  PAGE_STATUS: boolean;
  updateMasterState: Function;
  validate: Function;
  isLoading: any;
  addonData: any;
  memberstate: any;
}) => {
  const navigate = useNavigate();
  const { SELECTED_QUOTE_DATA, ADD_FORM } = useAppSelector(
    (state) => state.Health
  );

  const getMemberName = (i: number) => {
    if (i < 2) {
      return `Adult ${i + 1} `;
    } else if (i > 2) return `Child ${i - 2} `;
  };
  console.log("addon", addonData);
  return (
    <Box>
      <h5 className="sectionTitle">Addon Details Elevate</h5>
      <Grid xs={12}>
        {Object.keys(addonData).map((key: any) => {
          // addonData.data[obj]
          return (key === "Vaccination" || key === "New_born") &&
            !addonData["Maternity"].status ? (
            ""
          ) : (
            <>
              <AddonQuestion
                addon_amount=""
                main_class="addonQuestion borderBottom"
                icon_class="bonus"
                question_name={addonData[key].label}
                toggle_status={addonData[key].status}
                value_update={updateMasterState}
                attrName={["addon", key]}
                desc={addonData[key].desc}
              />
              <Collapse
                in={addonData[key].status}
                className="medicalQuestion-SubSection"
              >
                <Grid container spacing={3} className="pr-2 mb-3">
                  {memberstate.map((member: any, i: number) => {
                    let memberName = getMemberName(i);
                    return (
                      <Grid xs={4}>
                        <Box>
                          <MemberRadioButtonNew
                            class_name={
                              memberName?.includes("Adult") ? "adult" : "child"
                            }
                            checked={member[key]}
                            label_name={memberName}
                            // attrName={[
                            // "mainMemberData",
                            // data?.main_question?.id,
                            // memberDataValue?.keyName,
                            // ]}
                            attrName={["member", i, key]}
                            value_update={updateMasterState}
                          />
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Collapse>
            </>
          );
        })}

        {/* <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Maternity Cover"
          toggle_status={addonStatus.Maternity}
          value_update={updateMasterState}
          attrName={"Maternity"}
          desc="Covers medical expenses pertaining to maternity benefits.
All SI -Covers medical expenses up to 10% of the Annual SI, with a maximum limit of INR 1 Lakh (Maternity Sum Insured) for childbirth and/or pregnancy termination, limited to three instances, for insured females aged 21 to 45.
"
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Vaccination For New Born Baby"
          toggle_status={addonStatus.Vaccination}
          value_update={updateMasterState}
          attrName={"Vaccination"}
          //   desc="Only for Female"
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="New Born"
          toggle_status={addonStatus.New_Born}
          value_update={updateMasterState}
          attrName={"New_Born"}
          //   desc="Only for Female"
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Annual Health Checkup"
          toggle_status={addonStatus.Anuual_Health_Checkup}
          value_update={updateMasterState}
          attrName={"Anuual_Health_Checkup"}
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Domestic Air Ambulance"
          toggle_status={addonStatus.Domestic_Air_Ambulance}
          value_update={updateMasterState}
          attrName="Domestic_Air_Ambulance"
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Dependent accommodation 
benefit"
          toggle_status={addonStatus.Dependent_Accomodation_benefit}
          value_update={updateMasterState}
          attrName="Dependent_Accomodation_benefit"
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Convalescence Benefit"
          toggle_status={addonStatus.Convalescence_Benefit}
          value_update={updateMasterState}
          attrName={"Convalescence_Benefit"}
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Durable Medical Equipment 
Cover"
          toggle_status={addonStatus.Durable_Medical_Equipment_Cover}
          value_update={updateMasterState}
          attrName={"Durable_Medical_Equipment_Cover"}
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Worldwide Cover"
          toggle_status={addonStatus.Worldwide_Cover}
          value_update={updateMasterState}
          attrName="Worldwide_Cover"
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Personal Accident"
          toggle_status={addonStatus.Personal_Accident}
          value_update={updateMasterState}
          attrName="Personal_Accident"
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Sum Insured Protector"
          toggle_status={addonStatus.SI_Protector}
          value_update={updateMasterState}
          attrName={"SI_Protector"}
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Claim Protector"
          toggle_status={addonStatus.Claim_Protector}
          value_update={updateMasterState}
          attrName={"Claim_Protector"}
          desc="Get benefit for the non-payable items in case of claim to ensure no out-pocket expenses."
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Infinite Care"
          toggle_status={addonStatus.Infinite_Care}
          value_update={updateMasterState}
          attrName="Infinite_Care"
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Compassionate Visit HP"
          toggle_status={addonStatus.Compassionate_Visit_HP}
          value_update={updateMasterState}
          attrName="Compassionate_Visit_HP"
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Nursing At Home HP"
          toggle_status={addonStatus.Nursing_at_Home_HP}
          value_update={updateMasterState}
          attrName="Nursing_at_Home_HP"
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="BeFit A"
          toggle_status={addonStatus.BeFit_A_HP}
          value_update={updateMasterState}
          attrName={"BeFit_A_HP"}
          desc=" 	Routine Diagnostic, Minor Procedure cover & Pharmacy cover - ₹1000/- cover
 	E-counselling virtual sessions with qualified mental health medical practitioners - 6 sessions
 	Diet and nutrition e-consultation with qualified nutrition experts - 6 sessions
"
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="BeFit B"
          toggle_status={addonStatus.BeFit_B_HP}
          value_update={updateMasterState}
          attrName="BeFit_B_HP"
          desc=" 	Routine Diagnostic & Minor Procedure cover - ₹1000/- cover
 	Pharmacy cover - ₹1000/- cover
 	E-counselling virtual sessions with qualified mental health medical practitioners - 6 sessions
"
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="BeFit C"
          toggle_status={addonStatus.BeFit_C_HP}
          value_update={updateMasterState}
          attrName={"BeFit_C_HP"}
          desc="Get up to 4 outpatient consultations(OPD).
Routine diagnostic and minor procedures cover of ₹ 1,000.
Pharmacy cover of ₹ 1,000.
6 sessions for physiotherapy, e-counseling, diet and nutrition e-consultation each.
"
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="BeFit D"
          toggle_status={addonStatus.BeFit_D_HP}
          value_update={updateMasterState}
          attrName="BeFit_D_HP"
          desc=" 	Routine Diagnostic & Minor Procedure cover - ₹1000/- cover
 	Pharmacy cover - ₹1000/- cover
 	E-counselling virtual sessions with qualified mental health medical practitioners - 6 sessions
"
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="BeFit E"
          toggle_status={addonStatus.BeFit_E_HP}
          value_update={updateMasterState}
          attrName="BeFit_E_HP"
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="BeFit F"
          toggle_status={addonStatus.BeFit_F_HP}
          value_update={updateMasterState}
          attrName="BeFit_F_HP"
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="BeFit G"
          toggle_status={addonStatus.BeFit_G_HP}
          value_update={updateMasterState}
          attrName="BeFit_G_HP"
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="BeFit H"
          toggle_status={addonStatus.BeFit_H_HP}
          value_update={updateMasterState}
          attrName={"BeFit_H_HP"}
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Room Upgrade"
          toggle_status={addonStatus.Room_Upgrade}
          value_update={updateMasterState}
          attrName="Room_Upgrade"
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Waiting Period"
          toggle_status={addonStatus.Waiting_Period}
          value_update={updateMasterState}
          attrName="Waiting_Period"
        /> */}
      </Grid>
      <Footer
        loader={isLoading}
        textName={PAGE_STATUS === true ? "Update" : "Continue"}
        attrName={"pageStatus"}
        forward={() => {
          validate();
        }}
        value={0}
      />
    </Box>
  );
};

export default AddonDetailElevateICICI;
