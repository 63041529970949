import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import RKTextField from "../../../../../../Component/FieldTypes/RKTextField/RKTextField";
import SearchDropdown from "../../../../../../Component/FieldTypes/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import Footer from "../../../../../../Component/HI/Desktop/ProposalComponents/Footer/Footer";
import "../../../../../../Component/HI/Desktop/ProposalComponents/AddonQuestion/AddonQuestion.scss";
import "../../../../../../Component/HI/Desktop/SwitchButton/SwitchButton.scss";
import { Addon_Coverages_DTO } from "../../../../../../DTO/HIDTO/ProposalDTO";
import { HI_PATH } from "../../../../../../Router/Path/HIPath";
import { PROPOSAL_SERVICES } from "../../../../../../Services/HI/ProposalService";
import { QUOTES_SERVICES } from "../../../../../../Services/HI/QuotesService";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { pageSlice } from "../../../../../../redux/slice/HI/PageSlice/PageSlice";
import { proposalSlice } from "../../../../../../redux/slice/HI/Proposal/ProposalSlice";
import {
  extractNumbersFromString,
  isEmpty,
  objectHasWarningv2,
  validateFullName,
} from "../../../../../../SupportingFiles/HelpingFunction";
import { TAddonCoverages } from "../../../../../../types/HI/TProposal";

function AddOns() {
  const navigate = useNavigate();
  const { PAGE_STATUS } = useAppSelector((state) => state.pageSlice);
  const state = useAppSelector(
    (state) => state.proposalInformation.addonCoverages
  );
  const { proposalInformation, quote } = useAppSelector((state) => state);
  const [load, setLoad] = useState<boolean>(false);
  const [addons, setAddons] = useState<TAddonCoverages>(state);

  const dispatch = useAppDispatch();
  // const quotationNo = useAppSelector((state) => )

  const location = useLocation();

  const [addonStatus, setAddonStatus] = useState([false, false]);

  const [personalAccidentCoverage, setPersonalAccidentCoverage] =
    useState(false);
  const [personalAccidentCoverageSpouse, setPersonalAccidentCoverageSpouse] =
    useState(false);
  const [isSectionDisabled, setIsSectionDisabled] = useState(true);

  const gender_data = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];
  const relationship_data = [
    { label: "Father", value: "Father" },
    { label: "Mother", value: "Mother" },
    { label: "Brother", value: "Brother" },
    { label: "Sister", value: "Sister" },
    { label: "Grand Father", value: "Grand Father" },
    { label: "Grand Mother", value: "Grand Mother" },
    { label: "Brother In Law", value: "Brother In Law" },
    { label: "Mother In Law", value: "Mother In Law" },
    { label: "Daughter", value: "Daughter" },
    { label: "Son", value: "Son" },
    { label: "Wife", value: "Wife" },
  ];
  const [insuredAgeData, setInsuredAgeData] = React.useState([{}]);
  const [nomineeAgeData, setNomineeAgeData] = React.useState([{}]);
  const [spouseAgeData, setSpouseAgeData] = React.useState([{}]);
  const [spouseNomineeAgeData, setSpouseNomineeAgeData] = React.useState([{}]);

  useEffect(() => {
    let data = [];
    for (var i = 18; i < 111; i++) {
      data.push({ value: i, label: i + " Years" });
    }
    setInsuredAgeData(data);
    setSpouseAgeData(data);
  }, []);

  useEffect(() => {
    let data = [];
    for (var i = 5; i < 111; i++) {
      data.push({ value: i, label: i + " Years" });
    }
    setNomineeAgeData(data);
    setSpouseNomineeAgeData(data);
  }, []);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName[0] === "pacDetails") {
      if (attrName[1] === "nomineeAge") {
        if (parseInt(value) > 18) {
          // dispatch(
          //   proposalSlice.actions.setAddonCoverage({
          //     ...state,
          //     pacDetails: {
          //       ...state.pacDetails,
          //       [attrName[1]]: {
          //         value: value,
          //         warning: isEmpty(value),
          //       },
          //       nomineeAppointeeName: {
          //         ...state.pacDetails.nomineeAppointeeName,
          //         warning: false,
          //       },
          //     },
          //   })
          // );
          setAddons({
            ...addons,
            pacDetails: {
              ...addons.pacDetails,
              [attrName[1]]: {
                value: value,
                warning: isEmpty(value),
              },
              nomineeAppointeeName: {
                ...addons.pacDetails.nomineeAppointeeName,
                warning: false,
              },
            },
          });
          return;
        }
      }

      // dispatch(
      //   proposalSlice.actions.setAddonCoverage({
      //     ...state,
      //     pacDetails: {
      //       ...state.pacDetails,
      //       [attrName[1]]: {
      //         value: value,
      //         warning: isEmpty(value),
      //       },
      //     },
      //   })
      // );
      setAddons({
        ...addons,
        pacDetails: {
          ...addons.pacDetails,
          [attrName[1]]: {
            value: value,
            warning: isEmpty(value),
          },
        },
      });
    } else if (attrName[0] === "spousePacDetails") {
      if (attrName[1] === "nomineeAge") {
        if (value > 18) {
          // dispatch(
          //   proposalSlice.actions.setAddonCoverage({
          //     ...state,
          //     pacSpouseDetails: {
          //       ...state.pacSpouseDetails,
          //       [attrName[1]]: {
          //         value: value,
          //         warning: isEmpty(value),
          //       },
          //       nomineeAppointeeName: {
          //         ...state.pacSpouseDetails.nomineeAppointeeName,
          //         warning: false,
          //       },
          //     },
          //   })
          // );
          setAddons({
            ...addons,
            pacSpouseDetails: {
              ...addons.pacSpouseDetails,
              [attrName[1]]: {
                value: value,
                warning: isEmpty(value),
              },
              nomineeAppointeeName: {
                ...addons.pacSpouseDetails.nomineeAppointeeName,
                warning: false,
              },
            },
          });
          return;
        }
      }

      // dispatch(
      setAddons({
        ...addons,
        pacSpouseDetails: {
          ...addons.pacSpouseDetails,
          [attrName[1]]: {
            value: value,
            warning: isEmpty(value),
          },
        },
      });
      // );
    } else {
      setAddonStatus(value);
    }
  };

  const updateDigitQuotePremium = () => {
    setLoad(true);
    const onSuccess = (res: any) => {
      setLoad(false);
      dispatch(
        proposalSlice.actions.setPolicyInfo({
          ...proposalInformation.policy_information,
          premiumDetails: res.data.quoteLists,
        })
      );
    };

    const onError = (err: any) => {
      console.log(err);
      setLoad(false);
    };

    QUOTES_SERVICES.update_digit_quote_premium(
      onSuccess,
      onError,
      quote.quote_id
    );
  };

  const saveProposalInfo = (data: any) => {
    const loadingToast = toast.loading("Updating Proposal...");

    const onSuccess = (res: any) => {
      toast.dismiss(loadingToast);
      dispatch(proposalSlice.actions.setAddonCoverage(addons));
      if (PAGE_STATUS) {
        dispatch(pageSlice.actions.UPDATE_PAGE_STATUS(false));
        navigate(-1);
      } else {
        navigate(`${HI_PATH.Proposal}/${HI_PATH.OWNER_DETAILS}`);
      }
      updateDigitQuotePremium();
    };

    const onError = (err: any) => {
      toast.dismiss(loadingToast);
    };
    PROPOSAL_SERVICES.save_proposal_info(
      onSuccess,
      onError,
      { add_ons: data },
      quote.quote_id
    );
  };
  const validate_form = () => {
    const obj = { ...addons };
    let dto: Addon_Coverages_DTO = {
      isEQzone: false,
      isEQcover: obj.isEQcover,
      isPac: addons.isPac,
      isPacSpouse: addons.isPacSpouse,
      coverForValuableContent:
        proposalInformation.inbuiltCoverages.coverForValuableContent,
    };

    // If isPac is true, check for warning in pacDetails
    if (obj.isPac) {
      // const warn = objectHasWarningv2(obj.pacDetails);

      const [warn, validatedObj] = objectHasWarningv2(
        structuredClone(obj.pacDetails)
      );
      console.log("validate", warn, validatedObj);
      // return;

      // dispatch(
      //   proposalSlice.actions.setAddonCoverage({
      //     ...state,
      //     pacDetails: validatedObj,
      //   })
      // );
      setAddons({
        ...addons,
        pacDetails: validatedObj,
      });
      if (warn) {
        toast.error("Please enter valid values");
        return;
      }

      dto = {
        ...dto,
        pacDetails: {
          insuredAge: Number(
            extractNumbersFromString(obj.pacDetails.insuredAge.value)
          ),
          insuredGender: obj.pacDetails.insuredGender.value,
          insuredName: obj.pacDetails.insuredName.value,
          nomineeAge: Number(
            extractNumbersFromString(obj.pacDetails.nomineeAge.value)
          ),
          nomineeGender: obj.pacDetails.nomineeGender.value,
          nomineeName: obj.pacDetails.nomineeName.value,
          nomineeRelation: obj.pacDetails.nomineeRelation.value,
        },
      };
    }
    // If isPacSpouse is true, check for warning in pacSpouseDetails

    if (obj.isPacSpouse) {
      // const warn = objectHasWarning(obj.pacSpouseDetails);
      const [warn2, validatedObj2] = objectHasWarningv2(
        structuredClone(obj.pacSpouseDetails)
      );
      // console.log("obj.pacSpouseDetails", obj.pacSpouseDetails);
      console.log("prabh", warn2, validatedObj2);
      // dispatch(
      //   proposalSlice.actions.setAddonCoverage({
      //     ...state,
      //     pacSpouseDetails: validatedObj2,
      //   })
      // );
      setAddons({
        ...addons,
        pacSpouseDetails: validatedObj2,
      });
      if (warn2 || validatedObj2.nomineeAppointeeName.warning) {
        toast.error("Please enter valid values in spouse section");
        return;
      }

      dto = {
        ...dto,
        pacSpouseDetails: {
          age: Number(extractNumbersFromString(obj.pacSpouseDetails.age.value)),
          name: obj.pacSpouseDetails.name.value,
          nomineeAge: Number(
            extractNumbersFromString(obj.pacSpouseDetails.nomineeAge.value)
          ),
          nomineeAppointeeName: obj.pacSpouseDetails.nomineeAppointeeName.value,
          nomineeGender: obj.pacSpouseDetails.nomineeGender.value,
          nomineeName: obj.pacSpouseDetails.nomineeName.value,
          nomineeRelationWithInsured:
            obj.pacSpouseDetails.nomineeRelationWithInsured.value,
        },
      };
    }

    saveProposalInfo(dto);
    dispatch(proposalSlice.actions.setAddonCoverage(addons));
    console.log("form submitted");
  };

  useEffect(() => {
    const obj = proposalInformation.addonCoverages;
    let dto = {
      isEQzone: false,
      isEQcover: addons.isEQcover,
      isPac: addons.isPac,
      isPacSpouse: addons.isPacSpouse,
      coverForValuableContent:
        proposalInformation.inbuiltCoverages.coverForValuableContent,
    };

    const loadingToast = toast.loading("Updating Proposal...");

    const onSuccess = (res: any) => {
      toast.dismiss(loadingToast);

      // dispatch(pageSlice.actions.UPDATE_PAGE_STATUS(false));
      updateDigitQuotePremium();
      dispatch(
        proposalSlice.actions.setAddonCoverage({
          ...state,
          isPac: addons.isPac,
          isPacSpouse: addons.isPacSpouse,
          isEQcover: addons.isEQcover,
        })
      );
    };

    const onError = (err: any) => {
      toast.dismiss(loadingToast);
    };

    PROPOSAL_SERVICES.save_proposal_info(
      onSuccess,
      onError,
      { add_ons: dto },
      quote.quote_id
    );
  }, [
    // proposalInformation.addonCoverages.isEQcover,
    addons.isPac,
    addons.isPacSpouse,
    addons.isEQcover,
  ]);

  return (
    <Box>
      <h5 className="sectionTitle">Addon Details</h5>
      {/* <Grid container spacing={3}>
        <Grid xs={12} className="addonQuestion borderBottom">
          <Box>
            <Grid container spacing={2}>
              <Grid xs={9} className="question-part" alignSelf={"center"}>
                <h6>Personal Accident</h6>
                <p>Death Benefit of Rs. 5 Lakhs for Insured</p>
              </Grid>
              <Grid xs={3} textAlign="right">
                <p className="amount"></p>
                <div className="toggles">
                  <input
                    id="pac"
                    type="checkbox"
                    checked={state.isPac}
                    onClick={() => {
                      dispatch(
                        proposalSlice.actions.setAddonCoverage({
                          ...state,
                          isPac: !state.isPac,
                          isPacSpouse: false,
                        })
                      );
                      setPersonalAccidentCoverage(!personalAccidentCoverage);
                      setIsSectionDisabled(!isSectionDisabled);
                    }}
                  />
                  <label htmlFor={"pac"} />
                </div>
              </Grid>
            </Grid>
            {state.isPac ? (
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <h6 className="innerheading">Insured Details</h6>
                </Grid>
                <Grid xs={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Insured Name"}
                    validation_type="NAME"
                    value={state.pacDetails.insuredName.value}
                    attrName={["pacDetails", "insuredName"]}
                    value_update={updateMasterState}
                    warn_status={state.pacDetails.insuredName.warning}
                  />
                </Grid>
                <Grid xs={4}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Insured Age"
                    value={state.pacDetails.insuredAge.value}
                    attrName={["pacDetails", "insuredAge"]}
                    value_update={updateMasterState}
                    data={insuredAgeData}
                    warn_status={state.pacDetails.insuredAge.warning}
                    error_message={""}
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Insured Gender"
                    value={state.pacDetails.insuredGender.value}
                    attrName={["pacDetails", "insuredGender"]}
                    value_update={updateMasterState}
                    dropdown_data={gender_data}
                    warn_status={state.pacDetails.insuredGender.warning}
                  />
                </Grid>
                <Grid xs={12}>
                  <h6 className="innerheading"> Nominee Details</h6>
                </Grid>
                <Grid xs={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Nominee Name"}
                    validation_type="NAME"
                    value={state.pacDetails.nomineeName.value}
                    attrName={["pacDetails", "nomineeName"]}
                    value_update={updateMasterState}
                    warn_status={state.pacDetails.nomineeName.warning}
                  />
                </Grid>
                <Grid xs={4}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Nominee Age"
                    value={state.pacDetails.nomineeAge.value}
                    attrName={["pacDetails", "nomineeAge"]}
                    value_update={updateMasterState}
                    data={nomineeAgeData}
                    warn_status={state.pacDetails.nomineeAge.warning}
                    error_message={""}
                  />
                </Grid>

                {Number(
                  extractNumbersFromString(state.pacDetails.nomineeAge.value)
                ) <= 18 ? (
                  <Grid xs={4}>
                    <RKTextField
                      class_name="inputField"
                      title={"Appointee Name"}
                      validation_type="NAME"
                      value={state.pacDetails.nomineeAppointeeName.value}
                      attrName={["pacDetails", "nomineeAppointeeName"]}
                      value_update={updateMasterState}
                      warn_status={
                        state.pacDetails.nomineeAppointeeName.warning
                      }
                    />
                  </Grid>
                ) : null}

                <Grid xs={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Nominee Gender"
                    value={state.pacDetails.nomineeGender.value}
                    attrName={["pacDetails", "nomineeGender"]}
                    value_update={updateMasterState}
                    dropdown_data={gender_data}
                    warn_status={state.pacDetails.nomineeGender.warning}
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Relation with the insured"
                    value={state.pacDetails.nomineeRelation.value}
                    attrName={["pacDetails", "nomineeRelation"]}
                    value_update={updateMasterState}
                    dropdown_data={relationship_data}
                    warn_status={state.pacDetails.nomineeRelation.warning}
                  />
                </Grid>
              </Grid>
            ) : null}
            <Grid
              xs={12}
              className={`addonQuestion borderBottom ${
                isSectionDisabled ? "disabled-section" : ""
              }`}
            >
              <>
                <Grid container spacing={2}>
                  <Grid xs={9} className="question-part" alignSelf={"center"}>
                    <h6>Personal Accident- Spouse</h6>
                    <p>Death Benefit of Rs. 5 Lakhs for Spouse</p>
                  </Grid>
                  <Grid xs={3} textAlign="right">
                    <p className="amount"></p>
                    <div className="toggles">
                      <input
                        id="pacforSpouse"
                        type="checkbox"
                        checked={state.isPacSpouse}
                        onClick={() => {
                          if (!state.isPacSpouse) {
                            dispatch(
                              proposalSlice.actions.setAddonCoverage({
                                ...state,
                                isPacSpouse: !state.isPacSpouse,
                                isPac: true,
                              })
                            );
                          } else {
                            dispatch(
                              proposalSlice.actions.setAddonCoverage({
                                ...state,
                                isPacSpouse: !state.isPacSpouse,
                              })
                            );
                          }
                          setPersonalAccidentCoverageSpouse(
                            !personalAccidentCoverageSpouse
                          );
                          setPersonalAccidentCoverage(true);
                        }}
                      />
                      <label htmlFor={"pacforSpouse"} />
                    </div>
                  </Grid>
                </Grid>
                {state.isPacSpouse ? (
                  <Grid container spacing={3}>
                    <Grid xs={12}>
                      <h6 className="innerheading"> Spouse Details</h6>
                    </Grid>
                    <Grid xs={4}>
                      <RKTextField
                        class_name="inputField"
                        title={"Spouse Name"}
                        validation_type="NAME"
                        value={state.pacSpouseDetails.name.value}
                        attrName={["spousePacDetails", "name"]}
                        value_update={updateMasterState}
                        warn_status={state.pacSpouseDetails.name.warning}
                      />
                    </Grid>
                    <Grid xs={4}>
                      <SearchDropdown
                        class_name="inputField"
                        title="Spouse Age"
                        value={state.pacSpouseDetails.age.value}
                        attrName={["spousePacDetails", "age"]}
                        value_update={updateMasterState}
                        data={spouseAgeData}
                        warn_status={state.pacSpouseDetails.age.warning}
                        error_message={""}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <h6 className="innerheading"> Spouse Nominee Details</h6>
                    </Grid>
                    <Grid xs={4}>
                      <RKTextField
                        class_name="inputField"
                        title={"Nominee Name"}
                        validation_type="NAME"
                        value={state.pacSpouseDetails.nomineeName.value}
                        attrName={["spousePacDetails", "nomineeName"]}
                        value_update={updateMasterState}
                        warn_status={state.pacSpouseDetails.nomineeName.warning}
                      />
                    </Grid>
                    <Grid xs={4}>
                      <SearchDropdown
                        class_name="inputField"
                        title="Nominee Age"
                        value={state.pacSpouseDetails.nomineeAge.value}
                        attrName={["spousePacDetails", "nomineeAge"]}
                        value_update={updateMasterState}
                        data={spouseNomineeAgeData}
                        warn_status={state.pacSpouseDetails.nomineeAge.warning}
                        error_message={""}
                      />
                    </Grid>
                    {Number(
                      extractNumbersFromString(
                        state.pacSpouseDetails.nomineeAge.value
                      )
                    ) <= 18 ? (
                      <Grid xs={4}>
                        <RKTextField
                          class_name="inputField"
                          title={"Appointee Name"}
                          validation_type="NAME"
                          value={
                            state.pacSpouseDetails.nomineeAppointeeName.value
                          }
                          attrName={[
                            "spousePacDetails",
                            "nomineeAppointeeName",
                          ]}
                          value_update={updateMasterState}
                          warn_status={
                            state.pacSpouseDetails.nomineeAppointeeName.warning
                          }
                        />
                      </Grid>
                    ) : null}
                    <Grid xs={4}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Nominee Gender"
                        value={state.pacSpouseDetails.nomineeGender.value}
                        attrName={["spousePacDetails", "nomineeGender"]}
                        value_update={updateMasterState}
                        dropdown_data={gender_data}
                        warn_status={
                          state.pacSpouseDetails.nomineeGender.warning
                        }
                      />
                    </Grid>
                    <Grid xs={4}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Relation with the insured"
                        value={
                          state.pacSpouseDetails.nomineeRelationWithInsured
                            .value
                        }
                        attrName={[
                          "spousePacDetails",
                          "nomineeRelationWithInsured",
                        ]}
                        value_update={updateMasterState}
                        dropdown_data={relationship_data}
                        warn_status={
                          state.pacSpouseDetails.nomineeRelationWithInsured
                            .warning
                        }
                      />
                    </Grid>
                  </Grid>
                ) : null}
              </>
            </Grid>
            <Grid container spacing={2}>
              <Grid xs={9} className="question-part" alignSelf={"center"}>
                <h6>EarthQuake Cover</h6>
                <p>Death Benefit of Rs. 5 Lakhs for Spouse</p>
              </Grid>
              <Grid xs={3} textAlign="right">
                <p className="amount"></p>
                <div className="toggles">
                  <input
                    id="eqCover"
                    type="checkbox"
                    checked={state.isEQcover}
                    onClick={() => {
                      dispatch(
                        proposalSlice.actions.setAddonCoverage({
                          ...state,
                          isEQcover: !state.isEQcover,
                        })
                      );
                    }}
                  />
                  <label htmlFor={"eqCover"} />
                </div>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid> */}

      <Grid container spacing={3}>
        <Grid xs={12} className="addonQuestion borderBottom">
          <Box>
            <Grid container spacing={2}>
              <Grid xs={9} className="question-part" alignSelf={"center"}>
                <h6>Personal Accident</h6>
                <p>Death Benefit of ₹ 5 Lakhs for Insured</p>
              </Grid>
              <Grid xs={3} textAlign="right">
                <p className="amount"></p>
                <div className="toggles">
                  <input
                    id="pac"
                    type="checkbox"
                    checked={addons.isPac}
                    onClick={() => {
                      // dispatch(
                      //   proposalSlice.actions.setAddonCoverage({
                      //     ...state,
                      //     isPac: !state.isPac,
                      //     isPacSpouse: false,
                      //   })
                      // );
                      setAddons({
                        ...addons,
                        isPac: !addons.isPac,
                      });
                      setPersonalAccidentCoverage(!personalAccidentCoverage);
                      setIsSectionDisabled(!isSectionDisabled);
                    }}
                  />
                  <label htmlFor={"pac"} />
                </div>
              </Grid>
            </Grid>
            {addons.isPac ? (
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <h6 className="innerheading">Insured Member Details</h6>
                </Grid>
                <Grid xs={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Full Name"}
                    validation_type="NAME"
                    value={addons.pacDetails.insuredName.value}
                    attrName={["pacDetails", "insuredName"]}
                    value_update={updateMasterState}
                    warn_status={addons.pacDetails.insuredName.warning}
                    error_message={
                      isEmpty(addons.pacDetails.insuredName.value)
                        ? "Enter Full Name"
                        : validateFullName(addons.pacDetails.insuredName.value)
                        ? ""
                        : "Enter Valid Full Name"
                    }
                  />
                </Grid>
                <Grid xs={4}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Age"
                    value={addons.pacDetails.insuredAge.value}
                    attrName={["pacDetails", "insuredAge"]}
                    value_update={updateMasterState}
                    data={insuredAgeData}
                    warn_status={addons.pacDetails.insuredAge.warning}
                    error_message={
                      isEmpty(addons.pacDetails.insuredAge.value)
                        ? "Enter Age"
                        : ""
                    }
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Gender"
                    value={addons.pacDetails.insuredGender.value}
                    attrName={["pacDetails", "insuredGender"]}
                    value_update={updateMasterState}
                    data={gender_data}
                    warn_status={addons.pacDetails.insuredGender.warning}
                    error_message={
                      isEmpty(addons.pacDetails.insuredGender.value)
                        ? "Enter Insured Gender"
                        : ""
                    }
                  />
                </Grid>
                <Grid xs={12}>
                  <h6 className="innerheading"> Nominee Details</h6>
                </Grid>
                <Grid xs={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Nominee Name"}
                    validation_type="NAME"
                    value={addons.pacDetails.nomineeName.value}
                    attrName={["pacDetails", "nomineeName"]}
                    value_update={updateMasterState}
                    warn_status={addons.pacDetails.nomineeName.warning}
                    error_message={
                      isEmpty(addons.pacDetails.nomineeName.value)
                        ? "Enter Nominee Name"
                        : validateFullName(addons.pacDetails.nomineeName.value)
                        ? ""
                        : "Enter Valid Nominee Name"
                    }
                  />
                </Grid>
                <Grid xs={4}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Nominee Age"
                    value={addons.pacDetails.nomineeAge.value}
                    attrName={["pacDetails", "nomineeAge"]}
                    value_update={updateMasterState}
                    data={nomineeAgeData}
                    warn_status={addons.pacDetails.nomineeAge.warning}
                    error_message={
                      isEmpty(addons.pacDetails.nomineeAge.value)
                        ? "Enter Nominee Age"
                        : ""
                    }
                  />
                </Grid>

                {Number(
                  extractNumbersFromString(addons.pacDetails.nomineeAge.value)
                ) <= 18 ? (
                  <Grid xs={4}>
                    <RKTextField
                      class_name="inputField"
                      title={"Appointee Name"}
                      validation_type="NAME"
                      value={addons.pacDetails.nomineeAppointeeName.value}
                      attrName={["pacDetails", "nomineeAppointeeName"]}
                      value_update={updateMasterState}
                      warn_status={
                        addons.pacDetails.nomineeAge.value
                          ? addons.pacDetails.nomineeAppointeeName.warning
                          : false
                      }
                      error_message={
                        isEmpty(addons.pacDetails.nomineeAppointeeName.value)
                          ? "Enter Appointee Name"
                          : validateFullName(
                              addons.pacDetails.nomineeAppointeeName.value
                            )
                          ? ""
                          : "Enter Valid Appointee Name"
                      }
                    />
                  </Grid>
                ) : null}

                <Grid xs={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Nominee Gender"
                    value={addons.pacDetails.nomineeGender.value}
                    attrName={["pacDetails", "nomineeGender"]}
                    value_update={updateMasterState}
                    data={gender_data}
                    warn_status={addons.pacDetails.nomineeGender.warning}
                    error_message={
                      isEmpty(addons.pacDetails.nomineeGender.value)
                        ? "Enter Nominee Gender"
                        : ""
                    }
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Relation with the Insured"
                    value={addons.pacDetails.nomineeRelation.value}
                    attrName={["pacDetails", "nomineeRelation"]}
                    value_update={updateMasterState}
                    data={relationship_data}
                    warn_status={addons.pacDetails.nomineeRelation.warning}
                    error_message={
                      isEmpty(addons.pacDetails.nomineeRelation.value)
                        ? "Enter Nominee Relation"
                        : ""
                    }
                  />
                </Grid>
              </Grid>
            ) : null}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={12} className="addonQuestion borderBottom">
          <Box className={isSectionDisabled ? "disabled-section" : ""}>
            <Grid container spacing={2}>
              <Grid xs={9} className="question-part" alignSelf={"center"}>
                <h6>Personal Accident- Spouse</h6>
                <p>Death Benefit of ₹ 5 Lakhs for Spouse</p>
              </Grid>
              <Grid xs={3} textAlign="right">
                <p className="amount"></p>
                <div className="toggles">
                  <input
                    id="pacforSpouse"
                    type="checkbox"
                    checked={addons.isPacSpouse}
                    onClick={() => {
                      setAddons({
                        ...addons,
                        isPacSpouse: !addons.isPacSpouse,
                        isPac: addons.isPacSpouse ? false : true,
                      });
                      // dispatch(
                      //   proposalSlice.actions.setAddonCoverage({
                      //     ...state,
                      //     isPacSpouse: !state.isPacSpouse,
                      //     isPac: state.isPacSpouse ? false : true,
                      //   })
                      // )
                    }}
                    //   if (!state.isPacSpouse) {
                    //       dispatch(
                    //         proposalSlice.actions.setAddonCoverage({
                    //           ...state,
                    //           isPacSpouse: !state.isPacSpouse,
                    //           isPac: false,
                    //         })
                    //       );
                    //     } else {
                    //       dispatch(
                    //         proposalSlice.actions.setAddonCoverage({
                    //           ...state,
                    //           isPacSpouse: !state.isPacSpouse,
                    //           isPac: false,
                    //         })
                    //       );
                    //     }
                    //     setPersonalAccidentCoverageSpouse(
                    //       !personalAccidentCoverageSpouse
                    //     );
                    //     setPersonalAccidentCoverage(true);
                    //   }
                    // }}
                  />
                  <label htmlFor={"pacforSpouse"} />
                </div>
              </Grid>
            </Grid>
            {addons.isPacSpouse ? (
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <h6 className="innerheading"> Spouse Details</h6>
                </Grid>
                <Grid xs={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Spouse Name"}
                    validation_type="NAME"
                    value={addons.pacSpouseDetails.name.value}
                    attrName={["spousePacDetails", "name"]}
                    value_update={updateMasterState}
                    warn_status={addons.pacSpouseDetails.name.warning}
                    error_message={
                      isEmpty(addons.pacSpouseDetails.name.value)
                        ? "Enter Spouse Name"
                        : validateFullName(addons.pacSpouseDetails.name.value)
                        ? ""
                        : "Enter Valid Spouse Name"
                    }
                  />
                </Grid>
                <Grid xs={4}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Spouse Age"
                    value={addons.pacSpouseDetails.age.value}
                    attrName={["spousePacDetails", "age"]}
                    value_update={updateMasterState}
                    data={spouseAgeData}
                    warn_status={addons.pacSpouseDetails.age.warning}
                    error_message={
                      isEmpty(addons.pacSpouseDetails.age.value)
                        ? "Enter Spouse Age"
                        : ""
                    }
                  />
                </Grid>
                <Grid xs={12}>
                  <h6 className="innerheading"> Spouse Nominee Details</h6>
                </Grid>
                <Grid xs={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Nominee Name"}
                    validation_type="NAME"
                    value={addons.pacSpouseDetails.nomineeName.value}
                    attrName={["spousePacDetails", "nomineeName"]}
                    value_update={updateMasterState}
                    warn_status={addons.pacSpouseDetails.nomineeName.warning}
                    error_message={
                      isEmpty(addons.pacSpouseDetails.nomineeName.value)
                        ? "Enter Nominee Name"
                        : validateFullName(
                            addons.pacSpouseDetails.nomineeName.value
                          )
                        ? ""
                        : "Enter Valid Nominee Name"
                    }
                  />
                </Grid>
                <Grid xs={4}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Nominee Age"
                    value={addons.pacSpouseDetails.nomineeAge.value}
                    attrName={["spousePacDetails", "nomineeAge"]}
                    value_update={updateMasterState}
                    data={spouseNomineeAgeData}
                    warn_status={addons.pacSpouseDetails.nomineeAge.warning}
                    error_message={
                      isEmpty(addons.pacSpouseDetails.nomineeAge.value)
                        ? "Enter Nominee Age"
                        : ""
                    }
                  />
                </Grid>
                {Number(
                  extractNumbersFromString(
                    addons.pacSpouseDetails.nomineeAge.value
                  )
                ) <= 18 ? (
                  <Grid xs={4}>
                    <RKTextField
                      class_name="inputField"
                      title={"Appointee Name"}
                      validation_type="NAME"
                      value={addons.pacSpouseDetails.nomineeAppointeeName.value}
                      attrName={["spousePacDetails", "nomineeAppointeeName"]}
                      value_update={updateMasterState}
                      // warn_status={
                      //   Number(
                      //     extractNumbersFromString(
                      //       addons.pacSpouseDetails.nomineeAge.value
                      //     )
                      //   ) < 18
                      //     ? addons.pacSpouseDetails.nomineeAppointeeName.warning
                      //     : false
                      // }

                      // warn_status={
                      //   addons.pacSpouseDetails.nomineeAge.value
                      //     ? addons.pacSpouseDetails.nomineeAppointeeName.warning
                      //     : false
                      // }

                      // error_message={
                      //   isEmpty(
                      //     addons.pacSpouseDetails.nomineeAppointeeName.value
                      //   )
                      //     ? "Enter Appointee Name"
                      //     : validateFullName(
                      //         addons.pacSpouseDetails.nomineeAppointeeName.value
                      //       )
                      //     ? ""
                      //     : "Enter Valid Appointee Name"
                      // }
                      warn_status={
                        addons.pacSpouseDetails.nomineeAge.value
                          ? addons.pacSpouseDetails.nomineeAppointeeName.warning
                          : false
                      }
                      error_message={
                        isEmpty(
                          addons.pacSpouseDetails.nomineeAppointeeName.value
                        )
                          ? "Enter Appointee Name"
                          : validateFullName(
                              addons.pacSpouseDetails.nomineeAppointeeName.value
                            )
                          ? ""
                          : "Enter Valid Appointee Name"
                      }
                    />
                  </Grid>
                ) : null}
                <Grid xs={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Nominee Gender"
                    value={addons.pacSpouseDetails.nomineeGender.value}
                    attrName={["spousePacDetails", "nomineeGender"]}
                    value_update={updateMasterState}
                    data={gender_data}
                    warn_status={addons.pacSpouseDetails.nomineeGender.warning}
                    error_message={
                      isEmpty(addons.pacSpouseDetails.nomineeGender.value)
                        ? "Enter Nominee Gender"
                        : ""
                    }
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Relation with the Insured"
                    value={
                      addons.pacSpouseDetails.nomineeRelationWithInsured.value
                    }
                    attrName={[
                      "spousePacDetails",
                      "nomineeRelationWithInsured",
                    ]}
                    value_update={updateMasterState}
                    data={relationship_data}
                    warn_status={
                      addons.pacSpouseDetails.nomineeRelationWithInsured.warning
                    }
                    error_message={
                      isEmpty(addons.pacSpouseDetails.nomineeGender.value)
                        ? "Enter Spouse Relation"
                        : ""
                    }
                  />
                </Grid>
              </Grid>
            ) : null}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={12} className="addonQuestion borderBottom">
          <Box>
            <Grid container spacing={2}>
              <Grid xs={9} className="question-part" alignSelf={"center"}>
                <h6>Earthquake Cover</h6>
                <p>Death Benefit of ₹ 5 Lakhs for Spouse</p>
              </Grid>
              <Grid xs={3} textAlign="right">
                <p className="amount"></p>
                <div className="toggles">
                  <input
                    id="eqCover"
                    type="checkbox"
                    checked={addons.isEQcover}
                    onClick={() => {
                      // dispatch(
                      //   proposalSlice.actions.setAddonCoverage({
                      //     ...state,
                      //     isEQcover: !state.isEQcover,
                      //   })
                      // );
                      setAddons({
                        ...addons,
                        isEQcover: !addons.isEQcover,
                      });
                    }}
                  />
                  <label htmlFor={"eqCover"} />
                </div>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <Footer continue_function={validate_form} loader={load} />
    </Box>
  );
}

export default AddOns;
