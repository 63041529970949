import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import AdultOneDetails from "../../../../Page/Desktop/health-node/common/AdultOneDetails/AdultOneDetails";
import MAdultOneDetails from "../../../../Page/Mobile/health-node/common/MAdultOneDetails/MAdultOneDetails";
import { CKYC_ROUTES } from "../../../../Router/Path/CKYCRoutes";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { CKYCServcies } from "../../../../Services/CKYC/CKYCServices";
import { ECompanyCode, EProductId } from "../../../../Services/Enum/EHome";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/health-node/HealthPropsalServices";
import { CKYCSlice } from "../../../../redux/slice/CKYC/CKYCSlice";
import { HealthSlice } from "../../../../redux/slice/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  calculateAge,
  calculateAgeInDays,
  calculateBMI,
  evaluateNivaBupaBMI,
  FORMAT_DATE_DD_MM_YYYY,
  FORMAT_DD_MM_YYYY,
  isEmpty,
  validateAadharNumber,
  validateEmail,
  validateFullName,
  validateMobileNumber,
  validatePAN,
} from "../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../../types/Common/TDropdown";
import { TDropdownData } from "../../../../types/DropdownData/DropdownData";
import { TAdultOneDetails } from "../../../../types/Health/ProposalDeatail/TProposalDetail";
import {
  TBank_Details_HDFC,
  THealthNomineeDetails,
} from "../../../../types/Health/THealthSlice";
import AdultOneDetails from "../../../../Page/Desktop/health-php/CARE/AdultOneDetails/AdultOneDetails";

export default function AdultOneDetailsContainer() {
  const isMobile = useIsMobile();
  const {
    ADD_FORM,
    INSURED_MEMBER_DETAILS,
    NOMINEE_RELATIONSHIP_STATUS,
    SELECTED_QUOTE_DATA,
    PAGE_STATUS,
    ADD_FORM_RESPONSE,
    ADDON_DETAILS_ICIC,
    BANK_DETAILS_HDFC,
  } = useAppSelector((state) => state.Health);
  const { Health, CKYC } = useAppSelector((state) => state);
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);

  const [userType, setUserType] = useState<string>("");
  const [utm_medium, setUtm_medium] = useState<string>("");
  const [utm_source, setUtm_source] = useState<string>("");

  useEffect(() => {
    // Access the query parameters directly from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const userTypeFromQuery = searchParams.get("user_type");
    const utm_mediumFromQuery = searchParams.get("utm_medium");
    const utm_sourceFromQuery = searchParams.get("utm_source");
    setUtm_medium(`${utm_mediumFromQuery}`);
    setUtm_source(`${utm_sourceFromQuery}`);
    setUserType(
      atob(`${userTypeFromQuery}`) === "agent" ? btoa("agent") : btoa("user")
    );
  }, []);

  const navigate = useNavigate();
  const proposerData = {
    ...INSURED_MEMBER_DETAILS.PROPOSER_DETAILS,
    gender: ADD_FORM.gender,
  };
  const adultOneData = {
    ...INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS,
    gender: ADD_FORM.gender,
  };
  const [proposerFields, setProposerFields] =
    useState<TAdultOneDetails>(proposerData);
  const [adultOneFields, setAdultOneFields] =
    useState<TAdultOneDetails>(adultOneData);
  useEffect(() => {
    const prop_data = { ...INSURED_MEMBER_DETAILS.PROPOSER_DETAILS };
    setProposerFields({
      ...INSURED_MEMBER_DETAILS.PROPOSER_DETAILS,
      dob: {
        value: isEmpty(prop_data.dob.value)
          ? CKYC.VERIFY_KYC_FORM.dob.value
          : FORMAT_DATE_DD_MM_YYYY(prop_data.dob.value),
        warning: false,
      },
      name: {
        value: isEmpty(prop_data.name.value)
          ? CKYC.CKYC_DETAILS.kycCustomerName
          : prop_data.name.value,
        warning: false,
      },
      mobile: {
        value: isEmpty(prop_data.mobile.value)
          ? CKYC.CKYC_DETAILS.kycMobile
          : prop_data.mobile.value,
        warning: false,
      },
      email: {
        value: isEmpty(prop_data.email.value)
          ? CKYC.CKYC_DETAILS.kycEmail
          : prop_data.email.value,
        warning: false,
      },
      aadhar: {
        value: isEmpty(prop_data.aadhar.value)
          ? CKYC.CKYC_DETAILS.kycAadhaar
          : prop_data.aadhar.value,
        warning: false,
      },
      pan_card: {
        value: isEmpty(prop_data.pan_card.value)
          ? CKYC.CKYC_DETAILS.kycPAN
          : prop_data.pan_card.value,
        warning: false,
      },
      gender: {
        value: prop_data.gender.value,
        warning: false,
      },
    });
    setAdultOneFields({
      ...INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS,
      dob: {
        value: isEmpty(prop_data.dob.value)
          ? CKYC.VERIFY_KYC_FORM.dob.value
          : FORMAT_DATE_DD_MM_YYYY(prop_data.dob.value),
        warning: false,
      },
      name: {
        value: isEmpty(prop_data.name.value)
          ? CKYC.CKYC_DETAILS.kycCustomerName
          : prop_data.name.value,
        warning: false,
      },
      mobile: {
        value: isEmpty(prop_data.mobile.value)
          ? CKYC.CKYC_DETAILS.kycMobile
          : prop_data.mobile.value,
        warning: false,
      },
      email: {
        value: isEmpty(prop_data.email.value)
          ? CKYC.CKYC_DETAILS.kycEmail
          : prop_data.email.value,
        warning: false,
      },
      aadhar: {
        value: isEmpty(prop_data.aadhar.value)
          ? CKYC.CKYC_DETAILS.kycAadhaar
          : prop_data.aadhar.value,
        warning: false,
      },
      pan_card: {
        value: isEmpty(prop_data.pan_card.value)
          ? CKYC.CKYC_DETAILS.kycPAN
          : prop_data.pan_card.value,
        warning: false,
      },
      gender: {
        value: prop_data.gender.value,
        warning: false,
      },
    });
  }, [
    CKYC.CKYC_DETAILS,
    proposerFields.IS_PROPOSER_INSURED,
    INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
  ]);

  const [nomineeDetails, setNomineeDetails] = useState<THealthNomineeDetails>({
    ...INSURED_MEMBER_DETAILS.NOMINEE__DETAILS,
  });
  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState<boolean>(false);
  const [kycOpen, setKycOpen] = useState<boolean>(false);
  const [kycDetail, setKycDetail] = useState(false);
  const [toggle_status, setToggle_status] = useState(false);
  const [disbaledButton, setDisbaledButton] = useState(false);
  const { CKYC_DETAILS } = useAppSelector((state) => state.CKYC);
  const [occupationList, setOpccupationList] = useState<TDropdownData[]>([]);
  const [relationshipList, setRelationshipList] = useState<TDropdownData[]>([]);
  const [maritalStatusList, setMaritalStatusList] = useState<TDropdownData[]>(
    []
  );
  const [nomineeRealtionshipList, setNomineeRealtionshipList] = useState<
    TDropdownData[]
  >([]);
  const [Bank_Details_HDFC, SET_Bank_Details_HDFC] =
    useState<TBank_Details_HDFC>({
      ...BANK_DETAILS_HDFC,
    });

  useEffect(() => {
    setNomineeDetails({ ...INSURED_MEMBER_DETAILS.NOMINEE__DETAILS });
    if (SELECTED_QUOTE_DATA.CompanyDetails.company_code !== ECompanyCode.CARE) {
      getOccupationData();
    }
    if (SELECTED_QUOTE_DATA.productDetails.product_code) {
      getRealtionData();
      getMartialStatusData();
      getNomineeRelationData();
    }
  }, [SELECTED_QUOTE_DATA.productDetails.product_code]);

  const GET_KYC_DETAIL_ADITYA_BIRLA = (params: any) => {
    let param: any = {
      quote_no: params.quote_no,
      transactionId: params.transactionId,
    };

    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
      setLoader(false);
      if (!error) {
        const response = results.response;

        setAdultOneFields((prev) => ({
          ...prev,
          name: {
            value: response.kycCustomerName,
            warning: false,
          },
          dob: {
            value: response.kycDOB,
            warning: false,
          },
          mobile: {
            value: Health.ADD_FORM.mobile.value,
            warning: false,
          },
        }));
        setProposerFields((prev) => ({
          ...prev,
          name: {
            value: response.kycCustomerName,
            warning: false,
          },
          dob: {
            value: response.kycDOB,
            warning: false,
          },
          mobile: {
            value: Health.ADD_FORM.mobile.value,
            warning: false,
          },
        }));
        dispatch(
          CKYCSlice.actions.BULK_UPDATE({
            ...CKYC,
            CKYC_DETAILS: response,
          })
        );

        if (
          occupationList.length > 0 &&
          relationshipList.length > 0 &&
          maritalStatusList.length > 0 &&
          nomineeRealtionshipList.length > 0
        ) {
          dispatch(
            HealthSlice.actions.BULK_UPLOAD({
              ...Health,
              INSURED_MEMBER_DETAILS: {
                ...Health.INSURED_MEMBER_DETAILS,
                PROPOSER_DETAILS: {
                  ...Health.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS,
                  name: {
                    value: CKYC.CKYC_DETAILS.kycCustomerName,
                    warning: false,
                  },
                  dob: {
                    value: CKYC.CKYC_DETAILS.kycDOB,
                    warning: false,
                  },
                  mobile: {
                    value: CKYC.CKYC_DETAILS.kycMobile,
                    warning: false,
                  },
                  email: { value: CKYC.CKYC_DETAILS.kycEmail, warning: false },
                  gender: {
                    value: VERIFY_KYC_FORM.gender.value,
                    warning: false,
                  },
                },
              },
              ADDRESS_DETAILS: {
                ...Health.ADDRESS_DETAILS,
                pincode: {
                  value: response.kycPincode,
                  warning: false,
                },
                city: response.kycCity,
                state: response.kycState,
                addressLineOne: {
                  value: response.kycAddress1,
                  warning: false,
                },
                addressLineTwo: {
                  value: response.kycAddress2,
                  warning: false,
                },
              },
              DROPDOWN_DATA: {
                ...Health.DROPDOWN_DATA,
                OCCUPATION: occupationList,
                ADULT1_RELATIONSHIP: relationshipList,
                MARTIAL: maritalStatusList,
                NOMINEE_RELATIONSHIP: nomineeRealtionshipList,
              },
            })
          );
        }
      }
    };
    const onError = () => {
      setLoader(false);
    };
    setKycDetail(true);
    setLoader(true);
    CKYCServcies.GET_KYC_DETAIL_ADITYA_BIRLA(onSuccess, onError, param);
  };

  const GET_CKYC_DETAILS_FROM_HDFC = (params: {
    quote_no: string;
    vehicle_owned: string;
    kyc_id: string;
  }) => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
      setLoader(false);
      if (!error) {
        const response = results.response;

        setAdultOneFields((prev) => ({
          ...prev,
          name: {
            value: response.kycCustomerName,
            warning: false,
          },
          dob: {
            value: response.kycDOB,
            warning: false,
          },
          mobile: {
            value: Health.ADD_FORM.mobile.value,
            warning: false,
          },
        }));
        setProposerFields((prev) => ({
          ...prev,
          name: {
            value: response.kycCustomerName,
            warning: false,
          },
          dob: {
            value: response.kycDOB,
            warning: false,
          },
          mobile: {
            value: Health.ADD_FORM.mobile.value,
            warning: false,
          },
        }));
        dispatch(
          CKYCSlice.actions.BULK_UPDATE({
            ...CKYC,
            CKYC_DETAILS: response,
          })
        );
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            INSURED_MEMBER_DETAILS: {
              ...Health.INSURED_MEMBER_DETAILS,
              PROPOSER_DETAILS: {
                ...Health.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS,
                name: {
                  value: CKYC.CKYC_DETAILS.kycCustomerName,
                  warning: false,
                },
                dob: {
                  value: CKYC.CKYC_DETAILS.kycDOB,
                  warning: false,
                },
                mobile: {
                  value: CKYC.CKYC_DETAILS.kycMobile,
                  warning: false,
                },
                email: { value: CKYC.CKYC_DETAILS.kycEmail, warning: false },
                gender: {
                  value: VERIFY_KYC_FORM.gender.value,
                  warning: false,
                },
              },
            },
            ADDRESS_DETAILS: {
              ...Health.ADDRESS_DETAILS,
              pincode: {
                value: response.kycPincode,
                warning: false,
              },
              city: response.kycCity,
              state: response.kycState,
              addressLineOne: {
                value: response.kycAddress1,
                warning: false,
              },
              addressLineTwo: {
                value: response.kycAddress2,
                warning: false,
              },
            },
          })
        );
      }
    };
    const onError = () => {
      setLoader(false);
    };
    setKycOpen(true);
    setLoader(true);
    CKYCServcies.GET_CKYC_DETAILS_FROM_HDFC(onSuccess, onError, params);
  };

  const getRealtionData = () => {
    const onSuccess = (res: any) => {
      console.log("res..", res);
      let data: TDropdown[] = res?.response?.map((maritalData: any) => ({
        label: maritalData.title,
        value: maritalData.value,
      }));
      setRelationshipList(data);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      relationship: "adult1",
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
    };
    HEALTH_PROPOSAL_SERVICES.getRelationData(onSuccess, onError, param);
  };

  const getNomineeRelationData = () => {
    const onSuccess = (res: any) => {
      console.log("res..", res);
      let data: TDropdown[] = res?.response?.map((NomineeData: any) => ({
        value: `${NomineeData.code}-${NomineeData.title}`,
        label: NomineeData.title,
      }));
      setNomineeRealtionshipList(data);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      product_type_code: "HP",
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
    };
    HEALTH_PROPOSAL_SERVICES.getNomineeRelationData(onSuccess, onError, param);
  };

  const getMartialStatusData = () => {
    const onSuccess = (res: any) => {
      console.log("res..", res);
      let data: TDropdown[] = res?.response?.map((maritalData: any) => ({
        value: `${maritalData.code}-${maritalData.title}`,
        label: maritalData.title,
      }));
      setMaritalStatusList(data);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      product_type_code: SELECTED_QUOTE_DATA.productDetails.product_type_code,
    };
    HEALTH_PROPOSAL_SERVICES.getMartialData(onSuccess, onError, param);
  };

  const getOccupationData = () => {
    const onSuccess = (res: any) => {
      let data: TDropdown[] = res?.response?.map((maritalData: any) => ({
        value:
          SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G023"
            ? `${maritalData.code}-${maritalData.title}`
            : maritalData.code,
        label: maritalData.title,
      }));
      setOpccupationList(data);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      product_type_code: SELECTED_QUOTE_DATA.productDetails.product_type_code,
    };
    HEALTH_PROPOSAL_SERVICES.getOccupationData(onSuccess, onError, param);
  };

  const handleClick = (new_value: any) => {
    console.log(new_value, "hjdfhvbkdfhvbhdfbvhbdfvb");
    setProposerFields({
      ...INSURED_MEMBER_DETAILS.PROPOSER_DETAILS,
      IS_PROPOSER_INSURED: new_value,
    });
    dispatch(
      HealthSlice.actions.BULK_UPLOAD({
        ...Health,
        INSURED_MEMBER_DETAILS: {
          ...INSURED_MEMBER_DETAILS,
          PROPOSER_DETAILS: {
            ...INSURED_MEMBER_DETAILS.PROPOSER_DETAILS,
            IS_PROPOSER_INSURED: new_value,
          },
        },
      })
    );
    console.log(proposerFields, "hjdfhvbkdfhvbhdfbvhbdfvb");
  };

  const fieldsUpdateState = (attrName: any, value: any) => {
    console.log(attrName, "attrName56", value);
    if (attrName[0] === "nomineeDetails") {
      setNomineeDetails((prev) => ({
        ...prev,
        [attrName[1]]: {
          value: value,
          warning:
            attrName === "full_name"
              ? !validateFullName(value)
              : isEmpty(value),
        },
      }));
    } else if (attrName[1] === "adultOneDetails") {
      setAdultOneFields((prev) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning:
            attrName[0] === "pan_card"
              ? !validatePAN(value)
              : attrName[0] === "annual_income"
                ? isEmpty(value)
                : attrName[0] === "email"
                  ? !validateEmail(value)
                  : attrName[0] === "mobile" || attrName[0] === "emergencyPhone"
                    ? !validateMobileNumber(value)
                    : attrName[0] === "aadhar"
                      ? !validateAadharNumber(value)
                      : attrName[0] === "gender"
                        ? isEmpty(value)
                        : attrName[0] === "member_income"
                          ? isEmpty(value)
                          : attrName[0] === "qualification"
                            ? isEmpty(value)
                            : attrName[0] === "maritalStatus"
                              ? isEmpty(value)
                              : isEmpty(value),
        },
      }));
    } else if (attrName === "toggle_switch") {
      setToggle_status(value);
    } else {
      setProposerFields((prev) => ({
        ...prev,
        [attrName]: {
          value: value,
          warning:
            attrName[0] === "pan_card"
              ? !validatePAN(value)
              : attrName === "email"
                ? !validateEmail(value)
                : attrName === "mobile"
                  ? !validateMobileNumber(value)
                  : attrName[0] === "annual_income"
                    ? isEmpty(value)
                    : attrName === "gender"
                      ? isEmpty(value)
                      : isEmpty(value),
        },
      }));
    }
  };

  const UPDATE_PROPOSER_NOMINEE_DATA = (
    PROPOSER_DATA: TAdultOneDetails,
    NOMINEE_DETAILS: THealthNomineeDetails
  ) => {
    const onSuccess = (res: any) => {
      console.log("res...", res?.results);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      insurance_type: "HP",
      proposerRelationship: "Yes",
      proposerName:
        SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
          ECompanyCode.ADITY_BIRLA
          ? CKYC_DETAILS.kycCustomerName
          : PROPOSER_DATA.name.value,
      proposerDOB: PROPOSER_DATA.dob.value,
      proposerGender: PROPOSER_DATA.gender.value,
      // proposerMobile: PROPOSER_DATA.mobile.value,
      proposerMobile: ADD_FORM.mobile.value,
      proposerEmail: PROPOSER_DATA.email.value,
      aadhar_number: PROPOSER_DATA.aadhar.value,
      ProposalAnnualIncome: PROPOSER_DATA.annual_income.value,
      proposerEmergency: PROPOSER_DATA.emergencyPhone.value,
      proposerPanNo: PROPOSER_DATA.pan_card.value,
      proposerGSTNo: "",
      adult1Relationship: proposerFields.IS_PROPOSER_INSURED ? "Self" : "",
      weight: PROPOSER_DATA.weight.value,
      heightFeet: PROPOSER_DATA.heightFeet.value,
      heightInches: PROPOSER_DATA.heightInches.value,
      maritalStatus: PROPOSER_DATA.maritalStatus.value,
      nomineeName:
        NOMINEE_RELATIONSHIP_STATUS === "OTHER"
          ? NOMINEE_DETAILS.full_name.value
          : "",
      nomineeDOB:
        NOMINEE_RELATIONSHIP_STATUS === "OTHER"
          ? NOMINEE_DETAILS.dob.value
          : "",
      nomineeRelationShip:
        NOMINEE_RELATIONSHIP_STATUS === "OTHER"
          ? NOMINEE_DETAILS.relationship.value
          : "",
      section: "proposer_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${(parseInt(ADD_FORM?.son_count?.value) || 0) +
        (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
        ? `${(parseInt(ADD_FORM?.son_count?.value) || 0) +
        (parseInt(ADD_FORM?.daughter_count?.value) || 0)
        }C`
        : ""
        }`,
      abha_no: PROPOSER_DATA.abha_no.value,
    };
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  useEffect(() => {
    if (
      SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
      ECompanyCode.HDFC_HEALTH ||
      SELECTED_QUOTE_DATA.CompanyDetails.company_code === ECompanyCode.STAR
    ) {
      RECORD_CKYC_RESPONSE();
    }
  }, [CKYC.CKYC_DETAILS]);

  const RECORD_CKYC_RESPONSE = () => {
    const onSuccess = (res: any) => {
      console.log("res...", res?.results);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      insurance_type: "HP",
      details: CKYC.CKYC_DETAILS,
      section: "kyc_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${(parseInt(ADD_FORM?.son_count?.value) || 0) +
        (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
        ? `${(parseInt(ADD_FORM?.son_count?.value) || 0) +
        (parseInt(ADD_FORM?.daughter_count?.value) || 0)
        }C`
        : ""
        }`,
    };
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  const UPDATE_ADULT_ONE_NOMINEE_DATA = (
    PROPOSER_DATA: TAdultOneDetails,
    NOMINEE_DETAILS: THealthNomineeDetails
  ) => {
    const onSuccess = (res: any) => {
      console.log("res...", res?.results);
    };

    const onError = (err: any) => {
      console.log("err..", err);
    };

    let param = {
      insurance_type: "HP",
      insured_name: PROPOSER_DATA.name.value,
      insured_dob: PROPOSER_DATA.dob.value,
      insured_gender: PROPOSER_DATA.gender.value,
      insured_mobile: PROPOSER_DATA.mobile.value,
      insured_email: PROPOSER_DATA.email.value,
      aadhar_number: PROPOSER_DATA.aadhar.value,
      insured_emergency: PROPOSER_DATA.emergencyPhone.value,
      insured_gst: "",
      adult1Relationship: proposerFields.IS_PROPOSER_INSURED ? "Self" : "",
      weight: PROPOSER_DATA.weight.value,
      heightFeet: PROPOSER_DATA.heightFeet.value,
      heightInches: PROPOSER_DATA.heightInches.value,
      maritalStatus: PROPOSER_DATA.maritalStatus.value,
      member_income:
        SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G023"
          ? PROPOSER_DATA?.annual_income?.value
          : PROPOSER_DATA.member_income.value,
      qualification: PROPOSER_DATA.qualification.value,
      occupation: PROPOSER_DATA.occupation.value,
      nomineeName: NOMINEE_DETAILS.full_name.value,
      nomineeDOB: NOMINEE_DETAILS.dob.value,
      nomineeRelationShip: NOMINEE_DETAILS.relationship.value,
      section: "adult1_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${(parseInt(ADD_FORM?.son_count?.value) || 0) +
        (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
        ? `${(parseInt(ADD_FORM?.son_count?.value) || 0) +
        (parseInt(ADD_FORM?.daughter_count?.value) || 0)
        }C`
        : ""
        }`,
    };

    // let param = {
    //   proposerRelationship: "Yes",
    //   proposerName: PROPOSER_DATA.name.value,
    //   proposerDOB: PROPOSER_DATA.dob.value,
    //   proposerGender: PROPOSER_DATA.gender.value,
    //   proposerMobile: PROPOSER_DATA.mobile.value,
    //   proposerEmail: PROPOSER_DATA.email.value,
    //   ProposalAnnualIncome: PROPOSER_DATA?.annual_income?.value,
    //   aadhar_number: PROPOSER_DATA.aadhar.value,
    //   proposerEmergency: PROPOSER_DATA.emergencyPhone.value,
    //   proposerPanNo: PROPOSER_DATA.pan_card.value,
    //   proposerGSTNo: "",
    //   adult1Relationship: PROPOSER_DATA.IS_PROPOSER_INSURED ? "Self" : "",
    //   weight: PROPOSER_DATA.weight.value,
    //   heightFeet: PROPOSER_DATA.heightFeet.value,
    //   heightInches: PROPOSER_DATA.heightInches.value,
    //   maritalStatus: PROPOSER_DATA.maritalStatus.value,
    //   occupation: PROPOSER_DATA.occupation.value,
    //   nomineeName: NOMINEE_DETAILS.full_name.value,
    //   nomineeDOB: NOMINEE_DETAILS.dob.value,
    //   nomineeRelationShip: NOMINEE_DETAILS.relationship.value,
    //   section: "adult1_details",
    //   quote_no: ADD_FORM_RESPONSE.quote_no,
    //   cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${(parseInt(ADD_FORM?.son_count?.value) || 0) +
    //     (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
    //     0
    //     ? `${(parseInt(ADD_FORM?.son_count?.value) || 0) +
    //     (parseInt(ADD_FORM?.daughter_count?.value) || 0)
    //     }C`
    //     : ""
    //     }`,
    //   qualification: PROPOSER_DATA.qualification.value,
    //   member_income:
    //     SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G023"
    //       ? PROPOSER_DATA?.annual_income?.value
    //       : PROPOSER_DATA.member_income.value,
    //   grainful_income: PROPOSER_DATA.grainful_income.value,
    //   abha_no: PROPOSER_DATA.abha_no.value,
    // };
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };
  useEffect(() => {
    const isFemaleProposer = adultOneFields.gender.value === "M";
    const hasAddon =
      ADDON_DETAILS_ICIC.Maternity ||
      ADDON_DETAILS_ICIC.Vaccination ||
      ADDON_DETAILS_ICIC.New_Born;

    if (
      proposerFields.IS_PROPOSER_INSURED &&
      SELECTED_QUOTE_DATA.CompanyDetails.company_code === ECompanyCode.ICIC &&
      isFemaleProposer &&
      hasAddon
    ) {
      toast.warning(
        "Maternity, Vaccination and New Born Addons are not allowed for Male Proposer"
      );
      setDisbaledButton(true);
    } else {
      setDisbaledButton(false);
    }
  }, [adultOneFields.gender.value, ADDON_DETAILS_ICIC]);

  const isICICIProduct = SELECTED_QUOTE_DATA.productDetails.id === 2551;

  const hdfcProductIds = [
    ECompanyCode.HDFC_HEALTH,
    ECompanyCode.HDFC_HEALTH,
    EProductId.HDFC_HEALTH,
    EProductId.HDFC_HEALTH_Global,
    EProductId.HDFC_HEALTH_Plan,
  ];
  const isHdfcProduct =
    SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
    ECompanyCode.HDFC_HEALTH ||
    hdfcProductIds.includes(SELECTED_QUOTE_DATA.productDetails.id);

  const validateForm = () => {
    let proposer_data = { ...proposerFields };
    let adult_one_data = { ...adultOneFields };
    let nominee_data = { ...nomineeDetails };
    let bank_details_hdfc = { ...Bank_Details_HDFC };

    if (proposer_data.IS_PROPOSER_INSURED) {
      const isCignaProduct =
        SELECTED_QUOTE_DATA.CompanyDetails.company_code === ECompanyCode.CIGNA;
      const isGoDigitProduct =
        SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
        ECompanyCode.GO_DIGIT;
      const isAdityaBirla =
        SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
        ECompanyCode.ADITY_BIRLA && CKYC_DETAILS.kycCustomerName;

      adult_one_data = {
        ...adult_one_data,
        name: {
          ...adult_one_data.name,
          warning: isAdityaBirla
            ? false
            : isEmpty(`${adult_one_data.name.value}`),
        },
        dob: {
          ...adult_one_data.dob,
          warning: isEmpty(`${adult_one_data.dob.value} `),
        },
        gender: {
          ...adult_one_data.gender,
          warning: isEmpty(`${adult_one_data.gender.value} `),
        },
        mobile: {
          ...adult_one_data.mobile,
          warning: !validateMobileNumber(`${ADD_FORM.mobile.value} `),
        },
        email: {
          ...adult_one_data.email,
          warning: !validateEmail(`${adult_one_data.email.value} `),
        },
        aadhar: {
          ...adult_one_data.aadhar,
          warning: isEmpty(adult_one_data.aadhar.value)
            ? false
            : !validateAadharNumber(`${adult_one_data.aadhar.value} `),
        },
        emergencyPhone: {
          ...adult_one_data.emergencyPhone,
          warning: isEmpty(adult_one_data.emergencyPhone.value)
            ? false
            : !validateMobileNumber(`${adult_one_data.emergencyPhone.value} `),
        },
        weight: {
          ...adult_one_data.weight,
          warning: isEmpty(`${adult_one_data.weight.value} `),
        },
        heightFeet: {
          ...adult_one_data.heightFeet,
          warning: isEmpty(`${adult_one_data.heightFeet.value} `),
        },
        heightInches: {
          ...adult_one_data.heightInches,
          warning: isEmpty(`${adult_one_data.heightInches.value} `),
        },
        maritalStatus: {
          ...adult_one_data.maritalStatus,
          warning:
            SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.STAR ||
              SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.BAJAJ
              ? false
              : `${adult_one_data.maritalStatus.value} `.trim() === "",
        },
        annual_income: {
          ...adult_one_data.annual_income,
          warning:
            isICICIProduct || hdfcProductIds
              ? isEmpty(`${adult_one_data.annual_income.value} `)
              : false,
        },
        occupation: {
          ...adult_one_data.occupation, // Spread the existing occupation object
          warning:
            isEmpty(adult_one_data.occupation.value) &&
              (isHdfcProduct ||
                // isCignaProduct ||
                isGoDigitProduct ||
                SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                ECompanyCode.NIVA_BUPA) &&
              isEmpty(adult_one_data.occupation.value)
              ? true
              : false,
        },
        pan_card: {
          ...adult_one_data.pan_card,
          warning:
            isEmpty(adult_one_data.pan_card.value) &&
              Math.round(
                Number(SELECTED_QUOTE_DATA.premiumDetails?.finalPremium)
              ) < 50000
              ? false
              : !validatePAN(adult_one_data.pan_card.value),
        },
        qualification: {
          ...adult_one_data.qualification,
          warning: isEmpty(`${adult_one_data.qualification.value} `),
        },
        member_income: {
          ...adult_one_data.member_income,
          warning: isEmpty(`${adult_one_data.member_income.value} `),
        },
        grainful_income: {
          ...adult_one_data.grainful_income,
          warning: isEmpty(`${adult_one_data.grainful_income.value} `),
        },
      };
      if (NOMINEE_RELATIONSHIP_STATUS === "OTHER") {
        nominee_data = {
          ...nominee_data,
          full_name: {
            ...nominee_data.full_name,
            warning: !validateFullName(`${nominee_data.full_name.value} `),
          },
          dob: {
            ...nominee_data.dob,
            warning: isEmpty(`${nominee_data.dob.value} `),
          },
          relationship: {
            ...nominee_data.relationship,
            warning: isEmpty(`${nominee_data.relationship.value} `),
          },
        };
      }

      proposer_data = {
        ...adult_one_data,
        IS_PROPOSER_INSURED: proposer_data.IS_PROPOSER_INSURED,
      };
    } else {
      proposer_data = {
        ...proposer_data,
        name: {
          ...proposer_data.name,
          warning: isEmpty(`${proposer_data.name.value} `),
        },
        dob: {
          ...proposer_data.dob,
          warning: isEmpty(`${proposer_data.dob.value} `),
        },
        gender: {
          ...proposer_data.gender,
          warning: isEmpty(`${proposer_data.gender.value} `),
        },
        mobile: {
          ...proposer_data.mobile,
          warning: !validateMobileNumber(`${ADD_FORM.mobile.value} `),
        },
        email: {
          ...proposer_data.email,
          warning: !validateEmail(`${proposer_data.email.value} `),
        },
        aadhar: {
          ...proposer_data.aadhar,
          warning: isEmpty(proposer_data.aadhar.value)
            ? false
            : !validateAadharNumber(`${proposer_data.aadhar.value} `),
        },
        emergencyPhone: {
          ...proposer_data.emergencyPhone,
          warning: isEmpty(proposer_data.emergencyPhone.value)
            ? false
            : !validateMobileNumber(`${proposer_data.emergencyPhone.value} `),
        },
        annual_income: {
          ...proposer_data.annual_income,
          warning:
            isICICIProduct || hdfcProductIds
              ? isEmpty(`${adult_one_data.annual_income.value} `)
              : false,
        },
        pan_card: {
          ...proposer_data.pan_card,
          warning:
            isEmpty(proposer_data.pan_card.value) &&
              Math.round(
                Number(SELECTED_QUOTE_DATA.premiumDetails?.finalPremium)
              ) < 50000
              ? false
              : !validatePAN(proposer_data.pan_card.value),
        },
      };
    }

    setProposerFields({ ...proposer_data });
    setAdultOneFields({ ...adult_one_data });
    setNomineeDetails({ ...nominee_data });
    SET_Bank_Details_HDFC({ ...bank_details_hdfc });

    let hasError = false;
    if (!proposer_data.IS_PROPOSER_INSURED) {
      if (
        proposer_data.name.warning ||
        proposer_data.dob.warning ||
        proposer_data.gender.warning ||
        proposer_data.mobile.warning ||
        proposer_data.email.warning ||
        proposer_data.pan_card.warning
        // proposer_data.annual_income.warning ||
        // proposer_data.emergencyPhone.warning ||
      ) {
        hasError = true;
      }
    } else {
      if (
        adult_one_data.name.warning ||
        adult_one_data.dob.warning ||
        adult_one_data.gender.warning ||
        adult_one_data.mobile.warning ||
        adult_one_data.email.warning ||
        // adult_one_data.annual_income.warning ||
        // adult_one_data.emergencyPhone.warning ||
        adult_one_data.weight.warning ||
        adult_one_data.heightFeet.warning ||
        adult_one_data.heightInches.warning ||
        adult_one_data.maritalStatus.warning ||
        adult_one_data.occupation.warning ||
        adult_one_data.pan_card.warning ||
        (SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G027" &&
          adult_one_data.member_income.warning) ||
        (SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G027" &&
          adult_one_data.grainful_income.warning)
      ) {
        hasError = true;
      }

      if (NOMINEE_RELATIONSHIP_STATUS === "OTHER") {
        if (
          nominee_data.full_name.warning ||
          nominee_data.dob.warning ||
          nominee_data.relationship.warning ||
          hasError
        ) {
          hasError = true;
        }
      }
    }

    const bmi = calculateBMI(
      adult_one_data.weight.value,
      adult_one_data.heightFeet.value,
      adult_one_data.heightInches.value
    );
    const roundedBMI = Math.round(bmi);

    if (SELECTED_QUOTE_DATA.productDetails.id === 2947) {
      if (roundedBMI < 15 || roundedBMI > 30) {
        toast.error(
          "BMI falls outside acceptable range!, BMI should be 15 to 30"
        );
        hasError = true;
      }
    }

    if (
      SELECTED_QUOTE_DATA.productDetails.id ===
      EProductId.NIVA_BUPA_SENIOR_FIRST_GOLD_IND ||
      SELECTED_QUOTE_DATA.productDetails.id ===
      EProductId.NIVA_BUPA_Senior_First_Gold_Floater ||
      SELECTED_QUOTE_DATA.productDetails.id ===
      EProductId.NIVA_BUPA_Senior_First_Platinum_Floater ||
      SELECTED_QUOTE_DATA.productDetails.id ===
      EProductId.NIVA_BUPA_Senior_First_Platinum_IND ||
      SELECTED_QUOTE_DATA.productDetails.id ===
      EProductId.NIVA_BUPA_Aspire_Gold_Plus ||
      SELECTED_QUOTE_DATA.productDetails.id ===
      EProductId.NIVA_BUPA_Aspire_Diamond_Plus ||
      SELECTED_QUOTE_DATA.productDetails.id ===
      EProductId.NIVA_BUPA_Aspire_Platinum_Plus ||
      SELECTED_QUOTE_DATA.productDetails.id ===
      EProductId.NIVA_BUPA_Aspire_Titanium_Plus
    ) {
      const result = evaluateNivaBupaBMI(
        roundedBMI,
        calculateAge(adult_one_data.dob.value)
      );
      // Check if the result is not acceptable and show an error message
      if (result === "Decline") {
        hasError = true;
        toast.error("BMI falls outside acceptable range!");
      } else if (result === "10%" || result === "30%") {
        console.log("api call recaluclate premium");
      }
    }

    if (SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G033") {
      if (roundedBMI <= 19 || roundedBMI > 35) {
        toast.error("BMI falls between 20 to 35 range!");
        hasError = true;
      }
    }

    if (SELECTED_QUOTE_DATA.productDetails.id === 445) {
      if (roundedBMI >= 17 && roundedBMI <= 35) {
        toast.success("BMI falls within STP range!");
      } else if (roundedBMI > 35 && roundedBMI <= 37) {
        const loadedBMI = roundedBMI * 1.1;
        toast.warn(
          `BMI exceeded 35 but below or equal to 37. Applying 10 % loading.New BMI: ${loadedBMI} `
        );
      } else if (roundedBMI < 17 || roundedBMI > 37) {
        toast.error("BMI falls outside acceptable range!");
        hasError = true;
      }
    }
    if (
      SELECTED_QUOTE_DATA.productDetails.id === EProductId.HDFC_HEALTH_Global
    ) {
      if (calculateAge(adult_one_data.dob.value) >= 65) {
        toast.error("Age must be below 65 years or equal to 65 years.");
        hasError = true;
      }
    }

    if (
      (proposerFields.IS_PROPOSER_INSURED &&
        SELECTED_QUOTE_DATA.productDetails.id === 2890) ||
      (proposerFields.IS_PROPOSER_INSURED &&
        SELECTED_QUOTE_DATA.productDetails.id === 2891) ||
      (proposerFields.IS_PROPOSER_INSURED &&
        SELECTED_QUOTE_DATA.productDetails.id === 2892) ||
      (proposerFields.IS_PROPOSER_INSURED &&
        SELECTED_QUOTE_DATA.productDetails.id === 2893)
    ) {
      if (calculateAgeInDays(`${adult_one_data.dob.value} `) <= 61 * 365) {
        toast.error("Age must be greater than 61");
        hasError = true;
      }
    }

    if (
      proposerFields.IS_PROPOSER_INSURED &&
      SELECTED_QUOTE_DATA.productDetails.id === 187
    ) {
      if (
        calculateAgeInDays(`${adult_one_data.dob.value} `) <= 60 * 365 ||
        calculateAgeInDays(`${adult_one_data.dob.value} `) >= 75 * 365
      ) {
        toast.error("Age must be greater than 60 and less than 75");
        hasError = true;
      }
    }
    if (
      SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
      ECompanyCode.HDFC_HEALTH
    ) {
      if (calculateAgeInDays(`${adult_one_data.dob.value} `) > 24106) {
        toast.error("Age Must be below 65 year's or equal to 65 year's");
        return;
      }
    }
    if (
      proposerFields.IS_PROPOSER_INSURED &&
      SELECTED_QUOTE_DATA.productDetails.id === EProductId.CARE_SENIOR_CITIZEN
    ) {
      if (
        calculateAgeInDays(`${adult_one_data.dob.value} `) <= 61 * 365 ||
        calculateAgeInDays(`${adult_one_data.dob.value} `) >= 75 * 365
      ) {
        toast.error("Age must be greater than 61 and less than 75");
        hasError = true;
        return;
      }
    }

    if (
      SELECTED_QUOTE_DATA.CompanyDetails.company_code === ECompanyCode.GO_DIGIT
    ) {
      const age = calculateAge(adultOneFields.dob.value);
      const relationship = adultOneFields.relationship.value;

      // if (
      //   age >= 35 &&
      //   age <= 60 &&
      //   (relationship === "Father" || relationship === "Mother")
      // ) {
      //   toast.error("Adult Age Should be greater than 35 and less than 60");
      //   return;
      // }
      if (
        !ADD_FORM.spouse_status &&
        ADD_FORM.children === 0 &&
        age <= 85 &&
        adultOneFields.maritalStatus.value === "V-Married"
      ) {
        toast.error("Plan not available for Age less than 85 and Married");
        return;
      }
    }

    if (!hasError) {
      const total_child = ADD_FORM.children;
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      const cus_type = params.get("cus_type");

      if (!proposer_data.IS_PROPOSER_INSURED) {
        UPDATE_PROPOSER_NOMINEE_DATA(proposer_data, nominee_data);
        if (PAGE_STATUS) {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
          );
        } else {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.INSURED_MEMBER}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
          );
        }
      } else {
        UPDATE_PROPOSER_NOMINEE_DATA(proposer_data, nominee_data);
        setTimeout(() => {
          UPDATE_ADULT_ONE_NOMINEE_DATA(adult_one_data, nominee_data);
        }, 500);
        if (PAGE_STATUS) {
          navigate(-1);
        } else {
          if (ADD_FORM.spouse_status) {
            navigate(
              `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_TWO_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
            );
          } else if (total_child > 0) {
            navigate(
              `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.CHILD_ONE_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
            );
          } else {
            if (
              SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.HDFC_HEALTH ||
              SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.NIVA_BUPA ||
              SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.ADITY_BIRLA ||
              SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.CIGNA
            ) {
              navigate(
                `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.BANK_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
              );
            } else {
              navigate(
                `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADDRESS_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
              );
            }
          }
        }
      }
      dispatch(
        HealthSlice.actions.BULK_UPLOAD({
          ...Health,
          INSURED_MEMBER_DETAILS: {
            ...Health.INSURED_MEMBER_DETAILS,
            PROPOSER_DETAILS: { ...proposer_data },
            ADULT_ONE_DETAILS: { ...adult_one_data },
            NOMINEE__DETAILS: { ...nominee_data },
          },
          PAGE_STATUS: false,
        })
      );
    }
  };

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const txnId = params.get("txnId");
    const status = params.get("status");
    const kyc_id = params.get("kyc_id");

    if (txnId && status && kyc_id) {
      if (status === "pending") {
        toast.error("No Data Found!");
        window.location.href = `https://${window.location.href}${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=HP&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.productDetails.id}`;
      }
      const prams = {
        quote_no: ADD_FORM_RESPONSE.quote_no,
        vehicle_owned: "Individual",
        kyc_id: kyc_id,
      };
      GET_CKYC_DETAILS_FROM_HDFC(prams);
    }
  }, []);

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const transactionId = params.get("transactionId");
    const status = params.get("status");
    if (transactionId && status) {
      if (status === "needs_review" || status === "auto_declined") {
        toast.error("No Data Found!");
        window.location.href = `${window.location.origin}/proposal${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=HP&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.productDetails.id}`;
      }
      const prams = {
        quote_no: ADD_FORM_RESPONSE.quote_no,
        transactionId: transactionId,
      };
      GET_KYC_DETAIL_ADITYA_BIRLA(prams);
    }
  }, []);

  // useEffect(() => {
  //   getOccupationData();
  //   getRealtionData();
  //   getMartialStatusData();
  //   getNomineeRelationData();
  // }, [SELECTED_QUOTE_DATA.productDetails]);

  useEffect(() => {
    if (
      (VERIFY_KYC_FORM?.doc_type?.value === "Pan Card" &&
        SELECTED_QUOTE_DATA.productDetails.id === 2592) ||
      (VERIFY_KYC_FORM?.doc_type?.value === "Pan Card" &&
        SELECTED_QUOTE_DATA.productDetails.id === 2593) ||
      (VERIFY_KYC_FORM?.doc_type?.value === "Pan Card" &&
        SELECTED_QUOTE_DATA.productDetails.id === 2594)
    ) {
      setAdultOneFields((prev) => ({
        ...prev,
        pan_card: {
          value: VERIFY_KYC_FORM.doc_id.value,
          warning: false,
        },
      }));
      setProposerFields((prev) => ({
        ...prev,
        pan_card: {
          value: VERIFY_KYC_FORM.doc_id.value,
          warning: false,
        },
      }));
    }
  }, []);

  useEffect(() => {
    if (toggle_status) {
      toast.error("Plan not allowed for NRI");
      setDisbaledButton(true);
    } else {
      setDisbaledButton(false);
    }
  }, [toggle_status]);

  useEffect(() => {
    setTimeout(() => {
      if (
        SELECTED_QUOTE_DATA.productDetails.id === EProductId.CARE_ADVANTAGE &&
        proposerFields.IS_PROPOSER_INSURED &&
        ADD_FORM?.spouse_status === false &&
        ADD_FORM?.son_count?.value == "0" &&
        ADD_FORM?.daughter_count?.value == "0"
      ) {

        if (
          calculateAge(adultOneFields.dob.value) < 25 &&
          Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) < 10000000
        ) {
          setDisbaledButton(true);
          toast.error("Plan not allowed");
        } else {
          setDisbaledButton(false);
        }
      }
    }, 3000)
  }, [
    adultOneFields.dob.value,
    SELECTED_QUOTE_DATA,
    proposerFields.IS_PROPOSER_INSURED,
  ]);


  return isMobile ? (
    <MAdultOneDetails
      loader={loader}
      kycOpen={kycOpen}
      setKycOpen={setKycOpen}
      adultOneDetails={adultOneFields}
      nomineeDetails={nomineeDetails}
      fieldsUpdateState={fieldsUpdateState}
      proposerFields={proposerFields}
      validateForm={validateForm}
      handleClick={handleClick}
      occupationList={occupationList}
      relationshipList={relationshipList}
      maritalStatusList={maritalStatusList}
      nomineeRealtionshipList={nomineeRealtionshipList}
    />
  ) : (
    <AdultOneDetails
      loader={loader}
      kycOpen={kycOpen}
      setKycOpen={setKycOpen}
      adultOneDetails={adultOneFields}
      nomineeDetails={nomineeDetails}
      fieldsUpdateState={fieldsUpdateState}
      proposerFields={proposerFields}
      validateForm={validateForm}
      handleClick={handleClick}
      toggle_status={toggle_status}
      disbaledButton={disbaledButton}
      occupationList={occupationList}
      relationshipList={relationshipList}
      maritalStatusList={maritalStatusList}
      nomineeRealtionshipList={nomineeRealtionshipList}
    />
  );
}
