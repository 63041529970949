import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { Box, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subYears } from "date-fns";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../Component/Loader/Loader";
import CustomButton from "../../../../Component/CustomButton/CustomButton";
import RKTextField from "../../../../Component/FieldTypes/RKTextField/RKTextField";
import {
  FIND_LABEL_OF_VALUE,
  isEmpty,
} from "../../../../SupportingFiles/HelpingFunction";
import { TVerifyCKYCForm } from "../../../../types/CKYC/TCKYC";
import DatePicker from "../../../../Component/FieldTypes/DatePicker/DatePicker";
import SelectDropdown from "../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import Footer from "../../../../Component/Footer/Footer";
import SearchDropdown from "../../../../Component/FieldTypes/SearchDropdown/SearchDropdown";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { CKYC_ROUTES } from "../../../../Router/Path/CKYCRoutes";
import ToggleSwitch from "../../../../Component/ToggleSwitch/ToggleSwitch";
import { CKYCSlice } from "../../../../redux/slice/CKYC/CKYCSlice";
const STARCKYC = ({
  isLoading,
  showCKYCDetailsPopup,
  setShowCKYCDetailsPopup,
  showManualPopup,
  setShowManualPopup,
  updateMasterState,
  formDetails,
  validateForm,
  CONTINUE_TO_PROPOSAL,
  kycToggle,
}: {
  isLoading: boolean;
  showCKYCDetailsPopup: boolean;
  setShowCKYCDetailsPopup: Function;
  showManualPopup: boolean;
  setShowManualPopup: Function;
  updateMasterState: Function;
  formDetails: TVerifyCKYCForm;
  validateForm: Function;
  CONTINUE_TO_PROPOSAL: Function;
  kycToggle: boolean;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { POV, GENDER, CITY, AREA_TOWN } = useAppSelector(
    (state) => state.CommonDropdownData
  );
  const { CKYC_DETAILS } = useAppSelector((state) => state.CKYC);

  console.log("CKYC_DETAILS", CKYC_DETAILS);

  return (
    <>
      {isLoading && <Loader />}
      <Box>
        <Modal open={showCKYCDetailsPopup} className="modalWrapper">
          <Box className="modalContent xl-width kycPopups">
            <Grid container spacing={2}>
              <Grid xs={12}>
                <h4 className="popup-heading mb-2">CKYC Details</h4>
                <p className="grey_p">
                  We fetch these details as per record, please verify
                </p>
                <Link
                  className="close-button"
                  onClick={() => {
                    setShowCKYCDetailsPopup(false);
                  }}
                />
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={2} textAlign="center">
              <Grid xs={12} className="">
                <span className="policy_number_box my-4">
                  {CKYC_DETAILS.kycNo}
                </span>
              </Grid>
            </Grid>
            <Grid container spacing={2} textAlign="center">
              <Grid xs={6}>
                <h6>
                  Full Name:
                  <span>{CKYC_DETAILS.kycCustomerName}</span>
                </h6>
              </Grid>
              <Grid xs={6}>
                <h6>
                  DOB:
                  <span>{CKYC_DETAILS.kycDOB}</span>
                </h6>
              </Grid>
              <Grid xs={12}>
                <h6>
                  Address:
                  <span>{`${CKYC_DETAILS.kycAddress1}, 
                    ${FIND_LABEL_OF_VALUE(
                      AREA_TOWN,
                      CKYC_DETAILS.kycAddress2
                    )}, ${FIND_LABEL_OF_VALUE(
                    CITY,
                    CKYC_DETAILS.kycAddress3
                  )}, ${CKYC_DETAILS.kycState}, ${
                    CKYC_DETAILS.kycPincode
                  }`}</span>
                </h6>
              </Grid>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container columnSpacing={3} marginTop={"24px"}>
              <Grid xs={12} style={{ textAlign: "center" }} className="ctaBtn">
                <CustomButton
                  text_name={"Continue"}
                  class_name="primaryBtn"
                  size="large"
                  onClickFunction={() => {
                    CONTINUE_TO_PROPOSAL();
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal open={showManualPopup} className="modalWrapper ">
          <Box className="modalContent xl-width kycPopups">
            <Grid container spacing={3}>
              <Grid xs={12}>
                <h4 className="popup-heading mb-5">CKYC Details</h4>
                <Link
                  className="close-button"
                  onClick={() => {
                    setShowManualPopup(false);
                  }}
                />
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <p>
                  Sorry,we are not getting any information from insurer side,
                  please click below Button to upload or verify with other
                  Document
                </p>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} className="ctaBtn my-4">
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid xs={6} className="ctaBtn">
                <CustomButton
                  text_name={"Verify with other Document"}
                  class_name="secondaryBtn"
                  size="large"
                  onClickFunction={() => {
                    setShowManualPopup(false);
                  }}
                />
              </Grid>
              <Grid xs={6} style={{ textAlign: "right" }} className="ctaBtn">
                <CustomButton
                  text_name={"Manual KYC"}
                  class_name="primaryBtn"
                  size="large"
                  onClickFunction={() => {
                    setShowManualPopup(false);
                    dispatch(CKYCSlice.actions.SET_MANUAL_KYC_STATUS(true));
                    navigate(
                      `${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.MANUAL_CKYC}?type=HP`
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} className="ctaBtn my-4">
                <hr />
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <h5 className="sectionTitle">CKYC/eKYC Details</h5>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <h6 className="mb-4 question">Provide CKYC Details for Proposer</h6>
            <p className="textGrey ckyc_p">
              <span className="textBold">
                CKYC refers to Central KYC (Know Your Customer)
              </span>
              , an initiative of the Government of India. The aim of this
              initiative is to have a structure in place which allows investors
              to complete their KYC only once before interacting with various
              entities across the financial sector.
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={10}>
            <h6>Do you have PAN?</h6>
          </Grid>
          <Grid xs={2} textAlign="right">
            <ToggleSwitch
              toggle_status={kycToggle}
              value_update={updateMasterState}
              attrName={"kyc_toggle"}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={4}>
            <RKTextField
              class_name="inputField"
              title={"Proposer Full Name"}
              value={formDetails.full_name.value}
              attrName={"full_name"}
              value_update={updateMasterState}
              warn_status={formDetails.full_name.warning}
              validation_type="NAME"
              error_message={
                isEmpty(formDetails?.full_name.value)
                  ? "Enter Full Name"
                  : "Enter Valid Full Name"
              }
            />
          </Grid>
          {formDetails.customer_type.value === "Organization" ? (
            <Grid xs={4}>
              <DatePicker
                class_name="inputField"
                title={"Proposer DOI"}
                value={formDetails.dob.value}
                attrName={"dob"}
                value_update={updateMasterState}
                error_message="Select DOI"
                warn_status={formDetails.dob.warning}
                date_validation_type="YEARS"
                min_date={100}
                max_date={0}
                default_date={new Date()}
              />
            </Grid>
          ) : (
            <Grid xs={4}>
              <DatePicker
                class_name="inputField"
                title={"Proposer DOB"}
                id="ckycProposerDob"
                value={formDetails.dob.value}
                attrName={"dob"}
                value_update={updateMasterState}
                error_message="Select DOB"
                warn_status={formDetails.dob.warning}
                date_validation_type="YEARS"
                min_date={100}
                max_date={18}
                default_date={subYears(new Date(), 18)}
              />
            </Grid>
          )}
          {formDetails.customer_type.value === "Organization" ? null : (
            <Grid xs={4}>
              <SelectDropdown
                class_name="inputField"
                title="Gender"
                value={formDetails.gender.value}
                attrName={"gender"}
                value_update={updateMasterState}
                data={GENDER}
                warn_status={formDetails.gender.warning}
                error_message="Select Gender"
              />
            </Grid>
          )}
          <Grid xs={6}>
            <SearchDropdown
              class_name="inputField"
              title="Document For Verification"
              value={formDetails.doc_type.value}
              attrName={"doc_type"}
              value_update={updateMasterState}
              data={POV}
              warn_status={formDetails.doc_type.warning}
              error_message="Select Document For Verification"
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Document ID"}
              value={formDetails.doc_id.value}
              attrName={"doc_id"}
              value_update={updateMasterState}
              warn_status={formDetails.doc_id.warning}
              validation_type="ALPHANUMERIC"
              max_length={50}
              error_message="Enter Document ID Number"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={6}>
            <RKTextField
              validation_type="NUMBER"
              max_length={6}
              class_name="inputField"
              title={"Pincode"}
              value={formDetails.pincode.value}
              attrName={"pincode"}
              value_update={updateMasterState}
              warn_status={formDetails.pincode.warning}
              error_message="Enter Pincode"
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"H.No. / Building"}
              value={formDetails.houseNo.value}
              attrName={"houseNo"}
              value_update={updateMasterState}
              warn_status={formDetails.houseNo.warning}
              error_message="Enter H.No. / Building"
            />
          </Grid>
          <Grid xs={6}>
            <SearchDropdown
              class_name="inputField"
              title="City"
              value={formDetails.city.value}
              attrName={"city"}
              value_update={updateMasterState}
              data={CITY}
              warn_status={formDetails.city.warning}
              error_message="Select City"
            />
          </Grid>
          <Grid xs={6}>
            <SearchDropdown
              class_name="inputField"
              title={"Area / Town / Locality"}
              value={formDetails.area_town.value}
              attrName={"area_town"}
              value_update={updateMasterState}
              data={AREA_TOWN}
              warn_status={formDetails.area_town.warning}
              error_message="Enter Area / Town / Locality"
            />
          </Grid>
        </Grid>
        <Footer
          // loader={isLoading}
          attrName={"pageStatus"}
          forward={validateForm}
        />
      </Box>
    </>
  );
};

export default STARCKYC;
