import { NODE_DOMAIN, PHP_DOMAIN } from "../Domain";

export const NON_TERM_URL = {
  // non term
  // ADD_NON_TERM_FORM: `${PHP_DOMAIN}/api/investment-plans/add-investment.json`,
  // NON_TERM_QUOTES: `${PHP_DOMAIN}/api/investment-plans/get-quotes.json`,
  // NON_TERM_UPDATE_DETAILS: `${PHP_DOMAIN}/api/investment-plans/update-record.json`,
  // NON_TERM_BUY_ACTION: `${PHP_DOMAIN}/api/investment-plans/add-buy-now.json`,
  NON_TERM_PERPOSAL_TERM_DETAILS: `${PHP_DOMAIN}/api/investment-plans/add-proposal-data.json`,
  NON_TERM_ADD_PERPOSAL_DETAILS: `${NODE_DOMAIN}/api/v1/investment/get-proposal-journey`,
  NON_TERM_GET_PROPOSAL_MAX_LIFE: `${NODE_DOMAIN}/api/v1/investment/get-proposal-journey`,
  // NON_TERM_UPDATE_RIDERS_STATUS: `${PHP_DOMAIN}/api/investment-plans/get-riders.json`,
  NON_TERM_GET_REDIRECTION_URL: `${NODE_DOMAIN}/api/v1/investment/get-proposal`,
  NON_TERM_PROPOSER_OCCUPATION_DETAILS: `${NODE_DOMAIN}/api/v1/term-plans/product-occupation-maps`,
  NON_TERM_PROPOSER_QUALIFICATION_DETAILS: `${NODE_DOMAIN}/api/v1/term-plans/product-education-maps`,

  NON_TERM_PROPOSER_SEND_OTP: `${NODE_DOMAIN}/api/v1/investment/send-otp`,
  NON_TERM_PROPOSER_VERIFY_OTP: `${NODE_DOMAIN}/api/v1/investment/verify-otp`,



  // non term
  // ADD_TERM_FORM: `${PHP_DOMAIN}/api/term-plans/add.json`,
  // TERM_RIDERS_DETAILS: `${PHP_DOMAIN}/api/iprus-term-v1/get-rider-details.json`,
  // GET_IPRU_REDIRECTION_URL: `${PHP_DOMAIN}/api/iprus-term-v1/proposal.json`,
  // SEND_QUOTE_VIA_EMAIL: `${NODE_DOMAIN}/api/v1/premium/email`,
  // SEND_SINGLE_QUOTE_VIA_EMAIL: `${NODE_DOMAIN}/api/v1/premium/single-email`,
  // SEND_SINGLE_QUOTE_VIA_SMS: `${NODE_DOMAIN}/api/v1/premium/sms`,
  // GET_SINGLE_QUOTE: `${NODE_DOMAIN}/api/v1/term-plans/term-premium`,
  // TERM_PRODUCT_LIST: `${NODE_DOMAIN}/api/v1/term-plans/get-term-productlists`,
  // TERM_UPDATE_DETAILS: `${NODE_DOMAIN}/api/v1/term-plans/updatee-form`,
  // BUY_ACTION_LARAVEL: `${NODE_DOMAIN}/api/v1/term-plans/add-Buy-Now`,

  // non term
  ADD_NON_TERM_FORM: `${NODE_DOMAIN}/api/v1/term-plans/addform`,
  NON_TERM_UPDATE_DETAILS: `${NODE_DOMAIN}/api/v1/term-plans/update-investment-form`,
  NON_TERM_PRODUCT_LIST: `${NODE_DOMAIN}/api/v1/investment/get-investment-product-list`,
  GET_SINGLE_QUOTE: `${NODE_DOMAIN}/api/v1/investment/get-quotes`,
  NON_TERM_BUY_ACTION: `${NODE_DOMAIN}/api/v1/investment/add-buy-now`,
  NON_TERM_UPDATE_RIDERS_STATUS: `${NODE_DOMAIN}/api/v1/investment/get-riders`,
  // https://devnodeapiev.jioinsure.in/api/v1/investment/get-riders
  GET_ADDRESS: `${PHP_DOMAIN}/api/cities/get-details.json?pincode=`,
}