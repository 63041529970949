import EditIcon from "@mui/icons-material/Edit";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks";
import { HealthSlice } from "../../../redux/slice/Health/HealthSlice";
import {
  FORMAT_DATE_DD_MM_YYYY,
  isEmpty,
} from "../../../SupportingFiles/HelpingFunction";

interface Props {
  title: string;
  url?: any;
  isMailingAddresssame?: boolean;
  mailing_detail_view?: any;
  preViewData: Array<{
    label: string;
    label_value: any;
  }> | null;
}

const HealthpreviewDetails: React.FC<Props> = ({
  title,
  preViewData,
  url,
  isMailingAddresssame,
  mailing_detail_view,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <Box className="previewBox">
      {preViewData?.length !== 0 && Array.isArray(preViewData) ? (
        <Grid container rowSpacing={0} columnSpacing={2}>
          <Grid xs={8}>
            <p
              className="previewBoxTitle"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </Grid>
          <Grid xs={4} textAlign="right">
            <Link
              className="previewBoxEdit"
              onClick={() => {
                navigate(url);
                dispatch(HealthSlice.actions.CHANGE_PAGE_STATUS(true));
              }}
            >
              <EditIcon /> Edit
            </Link>
          </Grid>
          <Grid xs={12}>
            <ul>
              {Array.isArray(preViewData) &&
                preViewData.length > 0 &&
                preViewData.map((data, index) =>
                  isEmpty(data?.label_value) ? null : (
                    <>
                      <li>
                        <p>
                          {data?.label}: <span>{data?.label_value}</span>
                        </p>
                        <div></div>
                      </li>
                    </>
                  )
                )}
            </ul>
            {isMailingAddresssame && title === "Address Details" ? (
              <Box
                display={"flex"}
                alignItems={"center"}
                marginTop={"4px"}
                border={"1px dashed #ccc"}
                borderRadius={"8px"}
                padding={"4px"}
              >
                <TaskAltIcon
                  sx={{
                    color: "#00785A",
                    marginRight: "3px",
                    height: "16px",
                  }}
                />
                <p style={{ fontSize: "12px", color: "#00785A" }}>
                  Mailing / Correspondence address is same as permanent address.
                </p>
              </Box>
            ) : (
              <>
                {!isMailingAddresssame && mailing_detail_view?.length && (
                  <p
                    style={{
                      fontSize: "13px",
                      fontWeight: 600,
                      textTransform: "uppercase",
                      marginBottom: "8px",
                      marginTop: "8px",
                    }}
                  >
                    {" "}
                    Mailing Address
                  </p>
                )}

                <ul>
                  {mailing_detail_view?.map((data: any) => (
                    <li>
                      <p>
                        {data.label}:{""}
                        <span>
                          {data.label === "DOB"
                            ? FORMAT_DATE_DD_MM_YYYY(data?.label_value)
                            : data?.label_value}
                        </span>
                      </p>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );
};

export default HealthpreviewDetails;
