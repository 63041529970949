import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import SelectDropdown from "../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import MTermFooter from "../../../../Component/Term/MTermFooter/MTermFooter";
import MTermRiderQuestion from "../../../../Component/Term/MTermRiderQuestion/MTermRiderQuestion";
import { TermSlice } from "../../../../redux/slice/Term/TermSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { TDropdown } from "../../../../types/Common/TDropdown";

function MRidersBenefits({
  forward,
  tf003Data,
  loaderTf003,
}: {
  forward: Function;
  tf003Data: TDropdown[];
  loaderTf003: boolean;
}) {
  const { RIDERS_BENEFITS } = useAppSelector((state) => state.Term);
  const dispatch = useAppDispatch();

  return (
    <Box className="proposalPreview">
      <h5 className="sectionTitle">Addon Details</h5>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <MTermRiderQuestion
            loader={loaderTf003}
            main_class="mAddonQuestion borderBottom"
            icon_class="hospital"
            question_name={`Accidental death`}
            policy_term=""
            desc={
              "Accidental death rider provides additional protection in case of accidental death during the policy term. The accidental death sum assured is payable over and above the base sum assured in case of accidental death."
            }
            sub_details={
              <Box sx={{ maxWidth: "250px", paddingBottom: "16px" }}>
                <SelectDropdown
                  attrName={""}
                  title="Select Value"
                  value={RIDERS_BENEFITS?.local_value?.tf001Value}
                  value_update={(a: any, v: any) => {
                    dispatch(
                      TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                        updates: [
                          {
                            key: "tf001Value",
                            value: v,
                          },
                        ],
                      })
                    );
                  }}
                  data={tf003Data}
                  warn_status={false}
                />
              </Box>
            }
            toggle_status={RIDERS_BENEFITS?.local_value?.tf001Status}
            value_update={(attrName: any, value: any) => {
              dispatch(
                TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "tf001Status",
                      value: value,
                    },
                  ],
                })
              );
            }}
            attrName={"tf001Value"}
            addon_amount={`${RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF001?.premium}`}
          />
        </Grid>
      </Grid>
      <MTermFooter
        forward={() => {
          forward();
        }}
      />
    </Box>
  );
}

export default MRidersBenefits;
