import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CAR_ADD_FORM_SERVICES } from "../../Services/car-node/CarAddFormServices";
import { CAR_SERVICES } from "../../Services/car-node/CarServices";
import {
  DETERMINE_MOTOR_PLAN_TYPE,
  FORMAT_DATE_DD_MM_YYYY,
  isEmpty,
} from "../../SupportingFiles/HelpingFunction";
import { TAddonsValueType } from "../../types/Car/TAddonsValueType";
import { TCarAddonStatus } from "../../types/Car/TCarAddonStatus";
import { TCarProduct } from "../../types/Car/TCarProduct";
import { TAddressDetails } from "../../types/Common/TAddressDetails";
import { TNCB } from "../../types/Common/TNCB";
import {
  TCarAddForm,
  TCarAddFormResponse,
  TCarDiscount,
  TCarOwnerDetails,
  TCarVehicleDetails,
  TClaimStatus,
  TNomineeDetails,
} from "../../types/TCarSlice";
import { useAppDispatch } from "../hooks";
import { CarSlice } from "../slice/Car/CarSlice";
import { CKYCSlice } from "../slice/CKYC/CKYCSlice";

export const CarInitialValues = () => {
  const dispatch = useAppDispatch();
  const [adrilaProStatus, setAdrilaProStatus] = useState<boolean>(false);

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const type = params.get("type");
    const quote_no = params.get("quote_no");
    const product_id = params.get("product_id");
    const companyCode = params.get("companyCode");

    if (type && quote_no && companyCode && product_id && type === "CAR") {
      const prams = {
        type: `${type}`,
        quote_no: quote_no,
        companyCode: companyCode,
        product_id: product_id,
      };

      GET_PREVIEW_DATA(prams);
    }
  }, []);

  const GET_PREVIEW_DATA = (params: {
    type: string;
    quote_no: string;
    companyCode: string;
    product_id: string;
  }) => {
    const onSuccess = (res: any) => {
      const results = res.data;
      const error = results.error;
      if (!error) {
        const response = results.response;

        if (params.type === "CAR") {
          SET_CAR_DATA(response);
        }
      }
    };
    const onError = () => {};

    CAR_SERVICES.GET_PREVIEW_DATA(onError, onSuccess, params);
  };

  const GET_FASTLANE_PRO_INFO = async (data: {
    reg_no: string;
    make_model: string;
    fuel_type: string;
    variant_cc: string;
  }): Promise<{ chasis_no: string; engine_no: string }> => {
    return new Promise((resolve, reject) => {
      const onSuccess = (res: any) => {
        const response_fast_lane = res.response;
        const chasis_no = response_fast_lane.chasis_no;
        const engine_no = response_fast_lane.engine_no;

        resolve({ chasis_no, engine_no }); // Resolve the promise with data
      };

      const onError = (err: any) => {
        // if (window.location.hostname === CLIENTS.JIO) {
        setAdrilaProStatus(err?.response?.data?.error === true);
        // }
        reject(err); // Reject the promise in case of an error
      };

      CAR_ADD_FORM_SERVICES.GET_FASTLANE_PRO_INFO(
        onSuccess,
        onError,
        data.reg_no,
        "CAR",
        data.make_model,
        data.fuel_type,
        data.variant_cc
      );
    });
  };

  const SET_CAR_DATA = async (response: any) => {
    try {
      const form_data = response?.insurance;
      const manipulated_data_add_form: TCarAddForm = {
        utm_medium_d: response?.utmDetails?.utm_medium_d,
        invoice_date: { value: "", warning: false },
        previous_policy_type: { value: "SAOD", warning: false },
        name: { value: "", warning: false },
        business_type: form_data?.business_type,
        claimed: form_data?.claim_made as TClaimStatus,
        make_model: {
          value: form_data?.make_model,
          warning: false,
        },
        pincode: {
          value: form_data?.pincode || response?.praposal?.address_p_pincode,
          warning: false,
        },
        fuel_type: {
          value: form_data?.fuel_type,
          warning: false,
        },
        make: { value: "", warning: false },
        mobile: { value: form_data?.mobile, warning: false },
        model: { value: "", warning: false },
        prev_ncb: form_data?.previous_ncb as TNCB,
        previous_insurer: {
          value: form_data?.previous_insurer,
          warning: false,
        },
        reg_date: {
          value: FORMAT_DATE_DD_MM_YYYY(form_data?.registration_date),
          warning: false,
        },
        reg_no: { value: form_data?.registration_no, warning: false },
        variant_cc: {
          value: form_data?.variant_cc,
          warning: false,
        },
        rto: { value: form_data?.rto_code, warning: false },
        new_ncb: { value: form_data?.new_ncb, warning: false },
        policy_expiry_date: {
          value:
            form_data.business_type === "New"
              ? ""
              : FORMAT_DATE_DD_MM_YYYY(form_data?.policy_expiry_date),
          warning: false,
        },
      };

      const manipulated_data_add_form_response: TCarAddFormResponse = {
        ...CarSlice.getInitialState().ADD_FORM_RESPONSE,
        quote_no: response?.insurance?.quote_no,
        utm_medium: response?.utmDetails?.utm_medium,
        utm_source: response?.utmDetails?.utm_source,
      };

      const manipulated_selected_quote_data: TCarProduct = {
        ...CarSlice.getInitialState().SELECTED_QUOTE_DATA,
        quotationDetail: {
          cashlessGarageLists: [],
          CompanyDetails: response?.companyDetails,
          productDetails: response?.productDetails,
          premiumDetails: response?.jsonData,
        },
      };

      const manipulated_data_discount: TCarDiscount = {
        anti_theft_device: "No",
        deductible: "0",
        vehicle_owned: response?.vehicle_owned,
      };

      const manipulated_addon_status: TCarAddonStatus = {
        depreciation_cover: {
          label: "Zero Depreciation",
          value: form_data?.depreciation_cover === 1,
        },
        pa_owner_cover: {
          label: "Personal Accident Cover",
          value: form_data?.pa_owner_driver === 1,
        },
        personalBelonging: {
          label: "Personal Belonging",
          value: form_data?.personal_belonging === 1,
        },
        rsa: {
          label: "24x7 Roadside Assistance",
          value: form_data?.road_side_assistance === 1,
        },
        driver_cover: {
          label: "Driver Cover",
          value: form_data?.driver_cover === 1,
        },
        consumable_cover: {
          label: "Consumable Cover",
          value: form_data?.consumables_cover === 1,
        },
        engine_cover: {
          label: "Engine Cover",
          value: form_data?.engine_gear_box_protection === 1,
        },
        invoice_cover: {
          label: "Invoice Cover",
          value: form_data?.invoice_cover === 1,
        },
        key_replacement: {
          label: "Key Replacement",
          value: form_data?.key_replacement_cover === 1,
        },
        ncb_cover: {
          label: "NCB Cover",
          value: form_data?.no_claim_bonus_same_slab === 1,
        },
        tyre_cover: {
          label: "Tyre Cover",
          value: form_data?.tyre_secure_cover === 1,
        },
        passenger_cover: {
          label: "Passenger Cover (₹)",
          value: form_data?.passernger_cover > 0,
        },
        electrical: {
          label: "Electrical (₹)",
          value: form_data?.electric_accessory > 0,
        },
        non_electrical: {
          label: "Non-Electrical (₹)",
          value: form_data?.non_electric_accessory > 0,
        },
        cng_kit: { label: "CNG Kit (₹)", value: form_data?.cng_kit > 0 },
      };

      const manipulated_ADDON_USER_VALUE: TAddonsValueType = {
        cngKitValue: { value: form_data?.cng_kit, warning: false },
        electricalAcessories: {
          value: form_data?.electric_accessory,
          warning: false,
        },
        idv: { value: form_data?.idv, warning: false },
        nonElectricalAcessories: {
          value: form_data?.non_electric_accessory,
          warning: false,
        },
        passengerCover: { value: form_data?.passernger_cover, warning: false },
      };

      const proposal_data = response?.praposal;
      const CKYC_DETAILS = response?.CKYC_DETAILS;
      const create_proposal_response_tata = response?.tataDetails;
      const utmData = response?.utmDetails;
      const manipulated_owner_details: TCarOwnerDetails = {
        ...CarSlice.getInitialState().OWNER_DETAILS,
        name: {
          value:
            proposal_data?.customer_name ||
            CKYC_DETAILS?.data?.name ||
            CKYC_DETAILS.kycCustomerName ||
            CKYC_DETAILS?.kyc_details?.full_name,
          warning: false,
        },
        // dob: { value: proposal_data?.customer_dob, warning: false },
        dob: {
          value: CKYC_DETAILS?.kyc_details?.dateOfBirth
            ? CKYC_DETAILS?.kyc_details?.dateOfBirth
            : FORMAT_DATE_DD_MM_YYYY(response?.praposal?.customer_dob) ||
              CKYC_DETAILS?.data?.dob ||
              CKYC_DETAILS.kycDOB ||
              CKYC_DETAILS?.kyc_details?.dob ||
              "",
          warning: false,
        },
        email: { value: proposal_data?.customer_email, warning: false },
        gender: { value: proposal_data?.customer_gender, warning: false },
        gst: { value: proposal_data?.customer_gst_no, warning: false },
        maritalStatus: {
          value: proposal_data?.customer_marital,
          warning: false,
        },
        mobile: {
          value: proposal_data?.customer_mobile || utmData?.mobile,
          warning: false,
        },
        pincode: { value: proposal_data?.address_p_pincode, warning: false },
        pacover: {
          value: response?.insurance?.reason_for_exemption_pa.toString(),
          warning: false,
        },
      };

      const vehicle_data = response?.VEHICLE_DETAILS;

      const manipulated_vehicle_details: TCarVehicleDetails = {
        ...CarSlice.getInitialState().VEHICLE_DETAILS,
        bank_name: { value: vehicle_data?.bank_name, warning: false },
        chasis_no: { value: vehicle_data?.chasis_no, warning: false },
        city: { value: vehicle_data?.city, warning: false },
        engine_no: { value: vehicle_data?.engine_no, warning: false },
        is_vehicle_on_loan:
          vehicle_data?.is_vehicle_on_loan === "No" ||
          vehicle_data?.is_vehicle_on_loan === false ||
          isEmpty(vehicle_data?.is_vehicle_on_loan)
            ? false
            : true,
        mfg_year: {
          value: FORMAT_DATE_DD_MM_YYYY(vehicle_data?.mfg_year),
          warning: false,
        },
        pan_no: { value: vehicle_data?.pan_no, warning: false },
        policy_exp_date: {
          value: FORMAT_DATE_DD_MM_YYYY(vehicle_data?.policy_exp_date),
          warning: false,
        },
        prev_insurer: {
          value: response.insurance.previous_insurer,
          warning: false,
        },
        prev_policy_no: {
          value: proposal_data?.existing_policy_no,
          warning: false,
        },
        reg_date: {
          value: FORMAT_DATE_DD_MM_YYYY(vehicle_data?.reg_date),
          warning: false,
        },
        tp_insurer: {
          value: response.insurance?.tp_previous_insurer,
          warning: false,
        },
        tp_policy: { value: proposal_data?.tp_policy, warning: false },
        tp_policy_start: {
          value: vehicle_data?.tp_policy_start,
          warning: false,
        },
      };

      const manipulated_nominee_details: TNomineeDetails = {
        ...CarSlice.getInitialState().NOMINEE_DETAILS,
        fullName: { value: proposal_data?.nominee_name, warning: false },
        dob: {
          value: isEmpty(proposal_data?.nominee_dob)
            ? ""
            : FORMAT_DATE_DD_MM_YYYY(proposal_data?.nominee_dob),
          warning: false,
        },
        relationship: {
          value: proposal_data?.nominee_relationship,
          warning: false,
        },
      };

      const manipulated_address_details: TAddressDetails = {
        ...CarSlice.getInitialState().ADDRESS_DETAILS,
        pincode: {
          value: !isEmpty(response?.CKYC_DETAILS?.data?.correspondencePincode)
            ? response?.CKYC_DETAILS?.data?.correspondencePincode
            : isEmpty(form_data.pincode)
            ? proposal_data?.address_p_pincode ||
              CKYC_DETAILS?.kyc_details?.permanent_address?.pin_code ||
              CKYC_DETAILS?.kycDetails?.pinCode
            : form_data.pincode || CKYC_DETAILS?.data?.permanentPincode,
          warning: false,
        },
        city: proposal_data?.address_p_city,
        state: proposal_data?.address_p_state,
        addressLineOne: {
          value:
            proposal_data?.address_p_house ||
            CKYC_DETAILS?.kycDetails?.address1 ||
            CKYC_DETAILS?.data?.permanentAddress1 ||
            CKYC_DETAILS?.kyc_details?.permanent_address.address_line_1,
          warning: false,
        },
        addressLineTwo: {
          value:
            proposal_data?.address_p_area ||
            CKYC_DETAILS?.kycDetails?.address2 ||
            CKYC_DETAILS?.data?.permanentAddress2 ||
            CKYC_DETAILS?.kyc_details?.permanent_address.address_line_2,
          warning: false,
        },
        isMailingAddressSame:
          proposal_data?.address_mailing_same === "No" ||
          isEmpty(proposal_data?.address_mailing_same),
        mailingPinCode: {
          value: proposal_data?.address_m_pincode,
          warning: false,
        },
        mailingCity: proposal_data?.address_m_city,
        mailingState: proposal_data?.address_m_state,
        mailingAddressLineOne: {
          value: proposal_data?.address_m_house,
          warning: false,
        },
        mailingAddressLineTwo: {
          value: proposal_data?.address_m_area,
          warning: false,
        },
      };

      const manipulated_create_proposal_response = {
        ...CarSlice.getInitialState().create_proposal_response,
        proposal_no: create_proposal_response_tata?.proposal_no,
        policyId: create_proposal_response_tata?.policyId,
      };

      const engine_no_chassis_no: { chasis_no: string; engine_no: string } =
        await GET_FASTLANE_PRO_INFO({
          reg_no: manipulated_data_add_form.reg_no.value,
          make_model: manipulated_data_add_form.make_model.value,
          fuel_type: manipulated_data_add_form.fuel_type.value,
          variant_cc: manipulated_data_add_form.variant_cc.value,
        });
      const engine_no: string = engine_no_chassis_no?.engine_no;
      const chassis_no: string = engine_no_chassis_no?.chasis_no;

      dispatch(
        CKYCSlice.actions.BULK_UPDATE({
          ...CKYCSlice.getInitialState(),
          CKYC_DETAILS: {
            ...response?.CKYC_DETAILS,
            ...response?.CKYC_DETAILS?.data,
          },
          VERIFY_KYC_FORM: {
            ...CKYCSlice.getInitialState().VERIFY_KYC_FORM,
            customer_type: {
              value: form_data.vehicle_owned,
              warning: false,
            },
          },
        })
      );
      dispatch(
        CarSlice.actions.BULK_UPDATE({
          ...CarSlice.getInitialState(),
          ADD_FORM: {
            ...manipulated_data_add_form,
          },
          ADD_FORM_RESPONSE: {
            ...manipulated_data_add_form_response,
          },
          DISCOUNT_FORM: manipulated_data_discount,
          SELECTED_QUOTE_DATA: {
            ...manipulated_selected_quote_data,
          },
          OWNER_DETAILS: {
            ...manipulated_owner_details,
          },
          VEHICLE_DETAILS: {
            ...manipulated_vehicle_details,
            // engine_no: isEmpty(manipulated_vehicle_details?.engine_no.value)
            //   ? engine_no
            //   : manipulated_vehicle_details?.engine_no.value,
            // chasis_no: isEmpty(manipulated_vehicle_details?.chasis_no.value)
            //   ? chassis_no
            //   : manipulated_vehicle_details?.chasis_no.value,
          },
          NOMINEE_DETAILS: { ...manipulated_nominee_details },
          ADDRESS_DETAILS: { ...manipulated_address_details },
          ADDON_STATUS: {
            ...manipulated_addon_status,
          },
          ADDON_USER_VALUE: {
            ...manipulated_ADDON_USER_VALUE,
          },
          PLAN_TYPE: DETERMINE_MOTOR_PLAN_TYPE(
            `${manipulated_selected_quote_data?.quotationDetail?.productDetails.product_code}`,
            response?.insurance?.business_type
          ),
          QUOTE_LOADER: false,
          CKYC_DETAILS: { ...response?.CKYC_DETAILS },
          create_proposal_response: {
            ...manipulated_create_proposal_response,
          },
        })
      );
    } catch (error) {
      toast.error(`${error}`);
      // Handle error gracefully, maybe show a message to the user
    }
  };

  return null;
};
