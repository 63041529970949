import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProposerDetails from "../../../Page/Desktop/Term/ShortPay/HDFC/ProposerDetails";
import MProposerDetails from "../../../Page/Mobile/Term/HDFC/MProposerDetails";
import { TERM_ROUTES } from "../../../Router/Path/TermRoutes";
import { TERM_SERVICES } from "../../../Services/Term/TermServices";
import { TermSlice } from "../../../redux/slice/Term/TermSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  FORMAT_YYYY_MM_DD,
  isEmpty,
  validateAadharNumber,
  validateEmail,
  validateFullName,
  validateMobileNumber,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TTermProposerData } from "../../../types/TTermSlice";
import { TRidersBenefitsResponse } from "../../../types/Term/TRidersBenifits";
import { toast } from "react-toastify";

const BasicDetailsContainer = () => {
  const { Term } = useAppSelector((state) => state);
  const [loader, setLoader] = useState<boolean>(false);
  const { SELECTED_QUOTE_DATA, ADD_FORM, PROPOSER_DATA, ADD_FORM_RESPONSE } =
    useAppSelector((state) => state.Term);
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const state = useAppSelector((state) => state);
  const [proposerDetails, setProposerDetails] = useState<TTermProposerData>(
    state.Term.PROPOSER_DATA
  );

  const [userType, setUserType] = useState<string>("");
  const [utm_medium, setUtm_medium] = useState<string>("");
  const [utm_source, setUtm_source] = useState<string>("");

  useEffect(() => {
    // Access the query parameters directly from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const userTypeFromQuery = searchParams.get("user_type");
    const utm_mediumFromQuery = searchParams.get("utm_medium");
    const utm_sourceFromQuery = searchParams.get("utm_source");
    setUtm_medium(`${utm_mediumFromQuery}`);
    setUtm_source(`${utm_sourceFromQuery}`);
    setUserType(
      atob(`${userTypeFromQuery}`) === "agent" ? btoa("agent") : btoa("user")
    );
  }, []);

  const GET_RIDERS_PREMIUM = () => {
    setLoader(true);
    dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(true));
    const onSuccess = (data: any) => {
      setLoader(false);
      dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(false));

      const results = data;
      const error = results.error;
      const response: TRidersBenefitsResponse = results.response;

      if (error === false) {
        dispatch(
          TermSlice.actions.BULK_UPDATE({
            ...TermSlice,
            LOADER: false,
            RIDERS_BENEFITS: {
              ...Term.RIDERS_BENEFITS,
              riders_benefits_response: {
                ...Term.RIDERS_BENEFITS.riders_benefits_response,
                finalPremium: response?.finalPremium,
              },
            },
          })
        );
      } else {
        const message = results.message;
        toast.error(message);
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(false));
    };
    const payload: any = {
      product_ids: [SELECTED_QUOTE_DATA.productDetails.id],
      quote_no: ADD_FORM_RESPONSE.quote_no,
    };

    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams) {
      const product_id = searchParams.get("product_id");

      if (SELECTED_QUOTE_DATA.productDetails.id === Number(product_id)) {
        TERM_SERVICES.RIDERS_BENEFITS_DETAIL_NODE(onSuccess, onError, payload);
      }
    }
  };

  useEffect(() => {
    GET_RIDERS_PREMIUM();
  }, [SELECTED_QUOTE_DATA]);

  useEffect(() => {
    setProposerDetails((prev) => ({ ...PROPOSER_DATA, dob: ADD_FORM.age }));

    setProposerDetails({
      ...state.Term.PROPOSER_DATA,
      full_name: { value: PROPOSER_DATA?.full_name.value, warning: false },
      mobile: { value: ADD_FORM.mobile.value, warning: false },
      email: { value: PROPOSER_DATA.email.value, warning: false },
      gender: { value: ADD_FORM.gender.value, warning: false },
      dob: {
        value: ADD_FORM.age.value,
        warning: false,
      },
    });
  }, [SELECTED_QUOTE_DATA]);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName[0] === "full_name") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateFullName(value),
        },
      }));
    } else if (attrName[0] === "mobile") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateMobileNumber(value),
        },
      }));
    } else if (attrName[0] === "email") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateEmail(value),
        },
      }));
    } else if (attrName[0] === "aadhar_number") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateAadharNumber(value),
        },
      }));
    } else {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: attrName[2] === false ? false : isEmpty(value),
        },
      }));
    }
  };

  const validateForm = () => {
    let proposer_details_data: TTermProposerData = { ...proposerDetails };

    proposer_details_data = {
      ...proposer_details_data,
      full_name: {
        ...proposer_details_data.full_name,
        warning: !validateFullName(proposer_details_data.full_name.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      dob: {
        ...proposer_details_data.dob,
        warning: isEmpty(proposer_details_data?.dob?.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      mobile: {
        ...proposer_details_data.mobile,
        warning: !validateMobileNumber(proposer_details_data.mobile.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      email: {
        ...proposer_details_data.email,
        warning: !validateEmail(proposer_details_data.email.value),
      },
    };

    setProposerDetails(proposer_details_data);

    let hasError = false;

    if (
      proposer_details_data.full_name.warning ||
      proposer_details_data.dob.warning ||
      proposer_details_data.mobile.warning ||
      proposer_details_data.email.warning
    ) {
      hasError = true;
    }

    if (!hasError) {
      dispatch(
        TermSlice.actions.BULK_UPDATE({
          ...Term,
          PROPOSER_DATA: { ...proposer_details_data },
        })
      );

      updateProposerDetailsData(proposer_details_data);
      // navigate(
      //   `${TERM_ROUTES.RIDERS_BENEFITS}?type=TM&companyCode=${SELECTED_QUOTE_DATA?.companyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}`
      // );
    }
  };

  const updateProposerDetailsData = (proposerDetails: TTermProposerData) => {
    const onSuccess = (data: any) => {
      // const results = data?.results;
      // const error = results?.error || false;
      // const message = results?.message;
      // const response = results?.response;
      const message = data.message;
      const error = data.error;
      if (error === false) {
        // hdfcCreatePaymentLink();
        // navigate(TERM_ROUTES.RIDERS_BENEFITS);
        navigate(
          `${TERM_ROUTES.RIDERS_BENEFITS}?type=TM&companyCode=${SELECTED_QUOTE_DATA?.companyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&utm_source=${utm_source}&utm_medium=${utm_medium}&user_type=${userType}`
        );
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    const data = {
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
      quote_no: btoa(SELECTED_QUOTE_DATA.QuoteNo),
      // section: PROPOSAL_PAGE_SECTION_STATUS_API.PROPOSAL_DETAILS,
      section: "proposer_details",
      details: {
        proposer_relationship: proposerDetails.is_proposer_is_insured_member
          ? "Yes"
          : "No",
        aadhar_number: proposerDetails.aadhar_number.value,
        // insured_aadhar: insuredMemberDetails.aadhar_number.value,
        // insured_dob: FORMAT_YYYY_MM_DD(insuredMemberDetails.dob.value),
        // insured_email: insuredMemberDetails.email.value,
        // insured_gender: insuredMemberDetails.gender.value,
        // insured_marital_status: insuredMemberDetails.marital_status.value,
        // insured_mobile: insuredMemberDetails.mobile.value,
        // insured_name: insuredMemberDetails.full_name.value,
        // insured_occupation: insuredMemberDetails.occupation.value,
        insured_aadhar: "",
        insured_dob: "",
        insured_email: "",
        insured_gender: "",
        insured_marital_status: "",
        insured_mobile: "",
        insured_name: "",
        insured_occupation: "",
        premium: SELECTED_QUOTE_DATA.premiumDetails.premium,
        proposer_annual_income: proposerDetails.annual_income.value,
        proposer_dob: FORMAT_YYYY_MM_DD(proposerDetails.dob.value),
        proposer_email: proposerDetails.email.value,
        proposer_emergency_no: proposerDetails.emergency_number.value,
        proposer_gender: proposerDetails.gender.value,
        proposer_marital_status: proposerDetails.marital_status.value,
        proposer_mobile: proposerDetails.mobile.value,
        proposer_name: proposerDetails.full_name.value,
        proposer_occupation: proposerDetails.occupation.value,
        proposer_occDesc: proposerDetails.occupation_desc.value,
        proposer_education: proposerDetails.education.value,
      },
    };
    setLoader(true);

    TERM_SERVICES.UPDATE_PROPOSER_DETAILS(onSuccess, onError, data);
  };

  return (
    <>
      {isMobile ? (
        <MProposerDetails
          proposerDetails={proposerDetails}
          setProposerDetails={setProposerDetails}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          loader={loader}
        />
      ) : (
        <ProposerDetails
          proposerDetails={proposerDetails}
          setProposerDetails={setProposerDetails}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          loader={loader}
        />
      )}
    </>
  );
};

export default BasicDetailsContainer;
