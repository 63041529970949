import * as React from "react";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { TNivaBupaMedicalQuestion } from "../../../../../../types/Health/HealthQuotation/TNivaBupaMedicalQuestion";
import { HealthSlice } from "../../../../../../redux/slice/Health/HealthSlice";
import { HEALTH_ROUTE } from "../../../../../../Router/Path/HealthRoutes";
import { ECompanyCode } from "../../../../../../Services/Enum/EHome";
import { COLORS } from "../../../../../../SupportingFiles/colors";
import { FIND_LABEL_OF_VALUE } from "../../../../../../SupportingFiles/HelpingFunction";
const MedicalDetailPreviewBoxNivabupa = () => {
  const {
    MEDICAL_QUESTION_DATA_NIVA_BUPA,
    SELECTED_QUOTE_DATA,
    ADD_FORM_RESPONSE,
  } = useAppSelector((state) => state.Health);
  const navigate = useNavigate();
  const [medicalQuestionData, setMedicalQuestionData] =
    React.useState<TNivaBupaMedicalQuestion>(MEDICAL_QUESTION_DATA_NIVA_BUPA);
  const dispatch = useAppDispatch();
  // Initialize a variable to track if a main question with status true is found
  let found = false;
  let founds = false;

  // Check if any main question's member has selection_status set to true
  Object.values(medicalQuestionData).forEach((main_data: any) => {
    if (
      main_data &&
      main_data.main_question &&
      main_data.main_question.member_data
    ) {
      Object.values(main_data.main_question.member_data).forEach(
        (member_data: any) => {
          if (member_data && member_data.selection_status) {
            founds = true;
            return; // Break out of the loop
          }
        }
      );
    }
  });

  // Check if any sub-question's member has selection_status set to true and the sub-question's status is true
  Object.values(medicalQuestionData).forEach((main_data: any) => {
    if (
      main_data &&
      main_data.main_question &&
      main_data.main_question.sub_question
    ) {
      Object.values(main_data.main_question.sub_question).forEach(
        (sub_question_data: any) => {
          if (
            sub_question_data &&
            sub_question_data.status &&
            sub_question_data.member_data
          ) {
            Object.values(sub_question_data.member_data).forEach(
              (member_data: any) => {
                if (member_data && member_data.selection_status) {
                  found = true;
                  return; // Break out of the loop
                }
              }
            );
          }
        }
      );
    }
  });

  const niva_bupa_repo_value = [
    {
      label: "M/s NSDL Database Management Limited",
      value: "NSDL",
    },
    {
      label: "M/s Central Insurance Repository Limited",
      value: "CIRL",
    },
    {
      label: "M/s Karvy Insurance Repository Limited",
      value: "Karvy",
    },
    {
      label: "M/s CAMS Repository Service Limited",
      value: "CAMS",
    },
  ];

  const [param, setParam] = React.useState("");
  React.useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const cus_type = params.get("cus_type");
    setParam(cus_type || "");
  }, []);

  return (
    <Box className="previewBox mb-6 mx-3">
      <Grid container columnSpacing={2}>
        <Grid xs={8}>
          {found || founds ? (
            <p className="previewBoxTitle">Insured Member's Medical Details</p>
          ) : (
            <p className="previewBoxTitle">No Medical History Declared</p>
          )}
        </Grid>
        <Grid xs={4} textAlign="right">
          <Link
            onClick={() => {
              dispatch(HealthSlice.actions.CHANGE_PAGE_STATUS(true));
              navigate(
                `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
                  HEALTH_ROUTE.MEDICAL_DETAILS
                }?type=HP&companyCode=${
                  SELECTED_QUOTE_DATA?.CompanyDetails.company_code
                }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
                  SELECTED_QUOTE_DATA?.productDetails.id
                }${param === "user" ? "&user_type=user" : ""}`
              );
            }}
            className="previewBoxEdit"
          >
            <EditIcon /> Edit
          </Link>
        </Grid>
      </Grid>
      {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
      ECompanyCode.NIVA_BUPA ? (
        <Grid xs={12}>
          {Object.values(medicalQuestionData).map((mainData: any) => {
            return mainData.main_question.status ? (
              <div key={mainData.main_question.id}>
                {mainData.main_question.id === "MQ10" &&
                  mainData.main_question.title !== "" && (
                    <p style={{ color: COLORS.primary, fontWeight: 600 }}>
                      {mainData.main_question.title}
                    </p>
                  )}

                {mainData.main_question.id === "MQ09" && (
                  <>
                    <p
                      style={{
                        color: COLORS.primary,
                        fontWeight: 600,
                        marginBottom: "1px",
                      }}
                    >
                      {mainData.main_question.description}
                    </p>

                    <p>
                      Account Number:{" "}
                      <span style={{ fontWeight: "600" }}>
                        {
                          mainData.main_question?.field_data["MQ1-SQ1-F1"]
                            ?.value.value
                        }
                      </span>
                    </p>

                    <p>
                      Repository Name:{" "}
                      <span style={{ fontWeight: "600" }}>
                        {FIND_LABEL_OF_VALUE(
                          niva_bupa_repo_value,
                          mainData.main_question?.field_data["MQ1-SQ1-F2"]
                            ?.value.value
                        )}
                      </span>
                    </p>
                  </>
                )}

                {Object.values(mainData.main_question.member_data || {}).some(
                  (memberData: any) => {
                    return memberData.selection_status;
                  }
                ) && (
                  <>
                    {mainData.main_question.title !== "" && (
                      <h6 className="question">
                        {mainData.main_question.title}
                      </h6>
                    )}
                    <p
                      className="mb-4"
                      style={{ color: COLORS.primary, fontWeight: 600 }}
                    >
                      {mainData.main_question.description}
                    </p>
                  </>
                )}

                <ul className="member_list mb-4">
                  {Object.values(mainData.main_question.member_data || {}).map(
                    (memberData: any) =>
                      memberData.selection_status && (
                        <li key={memberData.id}>
                          <div
                            className={`${memberData.image_class} illness`}
                          ></div>
                          <p className="member_name">{memberData.name}</p>
                          <p className="member_name">
                            {memberData.field_data &&
                              Object.values(memberData.field_data).map(
                                (fieldData: any) => (
                                  <p key={fieldData.id}>
                                    {`${fieldData.title}: ${fieldData.value.value}`}
                                  </p>
                                )
                              )}
                          </p>
                        </li>
                      )
                  )}
                </ul>

                {mainData.main_question.sub_question &&
                  Object.values(mainData.main_question.sub_question).map(
                    (subQuestionData: any) =>
                      subQuestionData.status &&
                      Object.values(subQuestionData.member_data || {}).some(
                        (memberData: any) => memberData.selection_status
                      ) && (
                        <React.Fragment key={subQuestionData.id}>
                          <>
                            {subQuestionData.description !== "" && (
                              <p
                                className=""
                                style={{
                                  fontWeight: 500,
                                  marginBottom: "8px",
                                }}
                              >
                                {subQuestionData.description}
                              </p>
                            )}
                            <ul className="member_list mb-3">
                              {Object.values(
                                subQuestionData.member_data || {}
                              ).map((memberData: any) => {
                                console.log("memberData", memberData);
                                return (
                                  memberData.selection_status && (
                                    <li key={memberData.keyName}>
                                      <div
                                        className={`${memberData.image_class} illness`}
                                      ></div>
                                      <p className="member_name">
                                        {memberData.name}
                                      </p>
                                      {memberData.field_data &&
                                        Object.values(
                                          memberData.field_data
                                        ).map((fieldData: any) => (
                                          <p key={fieldData.id}>
                                            {`${fieldData.title}: ${fieldData.value.value}`}
                                          </p>
                                        ))}
                                    </li>
                                  )
                                );
                              })}
                            </ul>
                          </>
                        </React.Fragment>
                      )
                  )}
              </div>
            ) : null;
          })}
        </Grid>
      ) : (
        <Grid container columnSpacing={2}>
          <Grid xs={12}></Grid>
        </Grid>
      )}
    </Box>
  );
};

export default MedicalDetailPreviewBoxNivabupa;
