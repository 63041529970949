import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { isEmpty } from "../../SupportingFiles/HelpingFunction";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import "../MedicalQuestion/MedicalQuestion.scss";
import SelectDropdown from "../FieldTypes/SelectDropdown/SelectDropdown";
import CustomCheckbox from "../FieldTypes/CustomCheckbox/CustomCheckbox";
import BorderRadioButton from "../FieldTypes/BorderRadioButton/BorderRadioButton";
import { EProductId } from "../../Services/Enum/EHome";
import { TNIVABUPATiereNetworkListAddons } from "../../types/Health/ProposalDeatail/TNIVABUPA/TNIVABUPAAddon";

interface Props {
  main_class: string;
  icon_class: string;
  question_name: string;
  sub_details?: any;
  input_id_first: string;
  input_id_second: string;
  input_name: string;
  toggle_button_status?: boolean;
  value_update?: Function;
  attrName?: any;
  select_disease?: any;
  data?: any;
  dataSubQuestion?: any;
  updateMasterState?: any;
  any_question_or_sub_question?: any;
  show_toggle_status?: boolean;
  addonStatus: any;
  tieredNetworkList: TNIVABUPATiereNetworkListAddons[];
}
const NivaBupaAddonQuestion: React.FC<Props> = ({
  main_class,
  icon_class,
  question_name,
  sub_details,
  input_id_first,
  input_id_second,
  input_name,
  value_update,
  toggle_button_status,
  attrName,
  data,
  select_disease,
  dataSubQuestion,
  updateMasterState,
  any_question_or_sub_question,
  show_toggle_status = true,
  addonStatus,
  tieredNetworkList,
}) => {
  const { MEDICAL_QUESTION_DATA_STAR, QUOTE_LOADER, SELECTED_QUOTE_DATA } =
    useAppSelector((state) => state.Health);
  const update_status = (attrName: any, value: any) => {
    value_update?.(attrName, value);
  };
  const LifeOfManagement_data: any = [];

  const [selectedUrl, setSelectedUrl] = useState<string | null>(null);

  for (let i = 1; i <= 2; i++) {
    let status;
    if (i === 1) {
      status = "MEDICAL";
    } else if (i === 2) {
      status = "SURGICAL";
    }
    LifeOfManagement_data.push({ value: status, label: `${status}` });
  }

  const CurrentStatus_data: any = [];

  for (let i = 1; i <= 3; i++) {
    let status;
    if (i === 1) {
      status = "CURED";
    } else if (i === 2) {
      status = "NOT TREATED";
    } else if (i === 3) {
      status = "ONGOING";
    }

    CurrentStatus_data.push({ value: status, label: `${status}` });
  }
  const Details_of_treatment: any = [];

  for (let i = 1; i <= 2; i++) {
    let status;
    if (i === 1) {
      status = "Yes";
    } else if (i === 2) {
      status = "NO";
    }

    Details_of_treatment.push({ value: status, label: `${status}` });
  }

  const proId = SELECTED_QUOTE_DATA?.productDetails?.id;

  // Convert the tieredNetworkList to label-value pairs
  const convertedList = tieredNetworkList.map((item) => ({
    label: item.description,
    value: item.tiered_network_list_id,
  }));

  // Handle dropdown change to get the URL
  const handleDropdownChange = (attrName: string, selectedValue: string) => {
    const selectedItem = tieredNetworkList.find(
      (item) => item.tiered_network_list_id === selectedValue
    );
    if (selectedItem) {
      setSelectedUrl(selectedItem.url); // Set the URL from the selected item
      updateMasterState(attrName, selectedValue);
    }
  };

  useEffect(() => {
    if (
      tieredNetworkList &&
      data?.main_question.id === "MQ07" &&
      data?.main_question?.status
    ) {
      const selectedItem = tieredNetworkList.find(
        (item) =>
          item.tiered_network_list_id ===
          data?.main_question?.field_data?.["MQ1-SQ1-F1"].value?.value
      );
      if (selectedItem) {
        setSelectedUrl(selectedItem.url); // Set the URL from the selected item
      }
    }
  }, [tieredNetworkList]);

  return (
    <Box className={main_class}>
      {/* <Grid container spacing={2}>
        <Grid xs={8} md={9} className="medicalQuestionInner mb-5">
          <span className={icon_class}></span>
          <div>
            <h6>{isEmpty(question_name) ? sub_details : question_name}</h6>
            {isEmpty(question_name) ? null : <p>{sub_details}</p>}
          </div>
        </Grid>
        <Grid xs={4} md={3} textAlign="right" className="pt-4">
          <ToggleSwitch
            toggle_status={toggle_button_status}
            value_update={update_status}
            attrName={attrName}
            disabled={QUOTE_LOADER}
          />
        </Grid>
      </Grid> */}
      {proId === 2594 &&
      (input_id_first === "MQ08" || input_id_first === "MQ10") ? null : (
        <Grid container spacing={2}>
          <Grid xs={8} md={9} className="medicalQuestionInner mb-5">
            <span className={icon_class}></span>
            <div>
              <h6>{isEmpty(question_name) ? sub_details : question_name}</h6>
              {isEmpty(question_name) ? null : <p>{sub_details}</p>}
            </div>
          </Grid>
          <Grid xs={4} md={3} textAlign="right" className="pt-4">
            <ToggleSwitch
              toggle_status={toggle_button_status}
              value_update={update_status}
              attrName={attrName}
              disabled={QUOTE_LOADER}
            />
          </Grid>
        </Grid>
      )}
      {any_question_or_sub_question}
      {data?.main_question.id === "MQ02" ? (
        <Grid container spacing={2}>
          {data?.main_question?.field_data && data?.main_question?.status && (
            <>
              <Grid container spacing={2} textAlign="left">
                <Grid xs={12} textAlign={"center"}>
                  <CustomCheckbox
                    label={"BEST CONSULT"}
                    value_update={updateMasterState}
                    value={
                      data?.main_question?.field_data?.["MQ1-SQ1-F1"].value
                        ?.value
                    }
                    defaultChecked={false}
                    attrName={"best_consult"}
                    className="proposalCheckBoxWrapper mr-4"
                  />
                  <CustomCheckbox
                    label={"BEST CARE"}
                    value_update={updateMasterState}
                    value={
                      data?.main_question?.field_data?.["MQ1-SQ1-F2"].value
                        ?.value
                    }
                    defaultChecked={false}
                    attrName={"best_care"}
                    className="proposalCheckBoxWrapper "
                  />
                </Grid>
                <Grid xs={12}>
                  <div style={{ marginBottom: "16px" }}>
                    {data?.main_question?.field_data?.["MQ1-SQ1-F2"].value
                      ?.value ? (
                      <Box width={"50%"} margin={"auto"}>
                        <BorderRadioButton
                          variant="default"
                          attrName="best_care_value"
                          count="2"
                          options={[
                            { label: "5000", value: "5000" },
                            { label: "10000", value: "10000" },
                            { label: "15000", value: "15000" },
                            { label: "20000", value: "20000" },
                          ]}
                          onChange={updateMasterState}
                          value={addonStatus.best_care_value}
                          defaultValue={"best_care_value"}
                        />
                      </Box>
                    ) : null}
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      ) : null}

      {data?.main_question.id === "MQ01" ? (
        <Grid container spacing={2}>
          {data?.main_question?.field_data && data?.main_question?.status && (
            <Grid xs={8} lg={4} className="pl-14">
              <SelectDropdown
                class_name="inputField mb-5"
                title={"HVB Value"}
                attrName={"dropdown"}
                value={
                  data?.main_question?.field_data?.["MQ1-SQ1-F1"].value?.value
                }
                value_update={updateMasterState}
                warn_status={
                  data?.main_question?.field_data?.["MQ1-SQ1-F1"].value?.warning
                }
                data={
                  `${SELECTED_QUOTE_DATA.premiumDetails.sum_insured}` ===
                    "500000" ||
                  `${SELECTED_QUOTE_DATA.premiumDetails.sum_insured}` ===
                    "300000"
                    ? [{ value: "1000", label: "1000" }]
                    : `${SELECTED_QUOTE_DATA.premiumDetails.sum_insured}` ===
                        "1000000" ||
                      `${SELECTED_QUOTE_DATA.premiumDetails.sum_insured}` ===
                        "1500000"
                    ? [{ value: "2000", label: "2000" }]
                    : Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) >=
                      1500000
                    ? [{ value: "4000", label: "4000" }]
                    : []
                }
                error_message={"Enter description"}
              />
            </Grid>
          )}
        </Grid>
      ) : null}
      {data?.main_question.id === "MQ07" ? (
        <Grid container spacing={2}>
          {data?.main_question?.field_data && data?.main_question?.status && (
            <Grid xs={8} lg={4} className="pl-14">
              <SelectDropdown
                class_name="inputField mb-5"
                title={"Select Type"}
                attrName={"dropdown_hospitals"}
                value={
                  data?.main_question?.field_data?.["MQ1-SQ1-F1"].value?.value
                }
                value_update={handleDropdownChange}
                warn_status={
                  data?.main_question?.field_data?.["MQ1-SQ1-F1"].value?.warning
                }
                data={convertedList} // Use the converted label-value pairs here
                error_message={"Enter description"}
              />
              {selectedUrl && (
                <a
                  href={selectedUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="view-hospital-link"
                >
                  View Hospital List
                </a>
              )}
            </Grid>
          )}
        </Grid>
      ) : null}
    </Box>
  );
};

export default NivaBupaAddonQuestion;
