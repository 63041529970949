import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Link, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Grid from "@mui/material/Unstable_Grid2";
import { toast } from "react-toastify";
import { useAppSelector } from "../../redux/hooks";
import { HEALTH_PROPOSAL_SERVICES } from "../../Services/health-php/HealthPropsalServices";
import {
  GET_QUERY_PARAMETERS,
  isEmpty,
  validateEmail,
  validateMobileNumber,
} from "../../SupportingFiles/HelpingFunction";
import { TFieldData } from "../../types/Common/TFieldData";
import CustomButton from "../CustomButton/CustomButton";
import RKTextField from "../FieldTypes/RKTextField/RKTextField";
import "./Footer.scss";

interface FooterProps {
  forward?: Function;
  value?: number;
  disableButton?: boolean;
  attrName?: string;
  value_update?: Function;
  textName?: string;
  loader?: boolean;
}

const Footer = ({
  forward,
  value,
  disableButton,
  attrName,
  value_update,
  textName = "Continue",
  loader = false,
}: FooterProps) => {
  const navigate = useNavigate();
  const { TW, Car, Health, CKYC } = useAppSelector((state) => ({
    TW: state.TW,
    Car: state.Car,
    Health: state.Health,
    CKYC: state.CKYC.CKYC_DETAILS,
  }));

  const [previewDetail, setPreviewDetail] = useState<string>("");
  const [shareURLData, setShareURLData] = useState<{
    email: TFieldData;
    mobile: TFieldData;
  }>({
    email: { value: "", warning: false },
    mobile: { value: "", warning: false },
  });
  const [showPaymentPopup, setShowPaymentPopup] = useState<boolean>(false);
  const [linkLoad, setLinkLoad] = useState<boolean>(false);

  // Determine the last segment of the URL
  useEffect(() => {
    const pathname = window.location.pathname;
    setPreviewDetail(pathname.split("/").pop() || "");
  }, []);

  const location = useLocation();
  const [url, setUrl] = useState<string>("");
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const userType = urlParams.get("user_type");

    if (userType) {
      // Decode the current `user_type` value
      const decodedUserType = atob(userType);
      console.log("Current user_type:", decodedUserType);

      // Change `user_type` value as needed
      const newUserType = "user"; // Replace with your desired value
      const encodedNewUserType = btoa(newUserType);

      // Update the `user_type` value
      urlParams.set("user_type", encodedNewUserType);

      // Get all the query parameters as an array of key-value pairs
      const paramsArray = Array.from(urlParams.entries());

      // Reorder the parameters: Move `quote_no` to the first position
      const reorderedParams = [
        // Place `quote_no` first, if present
        ...paramsArray.filter(([key]) => key === "quote_no"),
        // Then add the rest
        ...paramsArray.filter(([key]) => key !== "quote_no"),
      ];

      // Rebuild the URL with reordered query parameters
      const newUrlParams = new URLSearchParams(reorderedParams);
      const updatedUrl = `${window.location.protocol}//${
        window.location.host
      }/proposal${location.pathname}?${newUrlParams.toString()}`;

      // Set the updated URL
      setUrl(updatedUrl);
    }
  }, [location]);

  // Validate and update input fields
  const handleDetails = (attrName: string, value: string) => {
    const isEmail = attrName === "email";
    setShareURLData((prev) => ({
      ...prev,
      [attrName]: {
        value,
        warning: isEmail ? !validateEmail(value) : !validateMobileNumber(value),
      },
    }));
  };

  // Generate and send payment link
  const handlePaymentLink = () => {
    setLinkLoad(true);
    const { email, mobile } = shareURLData;

    // Validation checks
    if (isEmpty(email.value) && isEmpty(mobile.value)) {
      toast.error("Please fill mandatory fields.");
      setLinkLoad(false);
      return;
    }

    if (email.warning || mobile.warning) {
      setLinkLoad(false);
      return;
    }

    const type = GET_QUERY_PARAMETERS("type");
    const customerName =
      type === "CAR"
        ? Car?.OWNER_DETAILS?.name?.value
        : type === "TW"
        ? TW?.OWNER_DETAILS?.name?.value
        : CKYC?.kycCustomerName;

    const param = {
      customerName,
      email: email.value,
      number: mobile.value,
      planName:
        type === "CAR"
          ? "Car Insurance"
          : type === "TW"
          ? "Tw Insurance"
          : "Health Insurance",
      insurer:
        type === "CAR"
          ? Car?.SELECTED_QUOTE_DATA?.quotationDetail?.CompanyDetails
              ?.short_desc
          : type === "TW"
          ? TW?.SELECTED_QUOTE_DATA?.quotationDetail?.CompanyDetails?.short_desc
          : Health?.SELECTED_QUOTE_DATA?.CompanyDetails?.short_desc,
      paymentLink: url,
      utm_source: GET_QUERY_PARAMETERS("utm_source"),
      utm_medium: GET_QUERY_PARAMETERS("utm_medium"),
      page: `${type === "CAR" ? "OnSummary_car" : "OnSummary_2Wheeler"}`,
    };

    // API Call
    HEALTH_PROPOSAL_SERVICES.PAYMENT_LINK(
      (res: any) => {
        setLinkLoad(false);
        setShowPaymentPopup(false);
        setShareURLData({
          email: { value: "", warning: false },
          mobile: { value: "", warning: false },
        });
        toast.success("Link sent successfully");
      },
      (err: any) => {
        console.error("Error sending payment link:", err);
        setLinkLoad(false);
      },
      param
    );
  };

  return (
    <>
      <Box className="proposalFooter paymentLinkPage">
        {/* Payment Popup */}
        <Modal open={showPaymentPopup} className="modalWrapper">
          <Box className="modalContent xsWidth kycPopups">
            <Grid container spacing={2} textAlign="center">
              <Grid xs={12}>
                <h4 className="popup-heading">Enter Details</h4>
                <p className="grey_p">Enter Details to proceed</p>
                <Link
                  className="close-button"
                  onClick={() => {
                    setShowPaymentPopup(false);
                    setShareURLData({
                      email: { value: "", warning: false },
                      mobile: { value: "", warning: false },
                    });
                  }}
                />
              </Grid>
              <Grid xs={12}>
                <RKTextField
                  id="email"
                  title="Proposer Email"
                  value={shareURLData.email.value}
                  attrName="email"
                  value_update={handleDetails}
                  error_message="Enter Valid Email"
                  warn_status={shareURLData.email.warning}
                />
              </Grid>
              <Grid xs={12}>
                <RKTextField
                  id="mobile"
                  title="Proposer Mobile"
                  value={shareURLData.mobile.value}
                  attrName="mobile"
                  value_update={handleDetails}
                  error_message="Enter Valid Mobile No."
                  warn_status={shareURLData.mobile.warning}
                  validation_type="NUMBER"
                  max_length={10}
                />
              </Grid>
              <Grid xs={12} textAlign="center">
                <CustomButton
                  text_name="Submit"
                  size="large"
                  class_name="primaryBtn"
                  onClickFunction={handlePaymentLink}
                  loading={linkLoad}
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Grid container spacing={3}>
          <Grid xs={8} xsOffset={3} className="footerInner" alignItems="center">
            <Link
              className="backStep"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBackIcon /> Back
            </Link>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
            >
              <CustomButton
                text_name={
                  atob(GET_QUERY_PARAMETERS("user_type")) === "agent" &&
                  (previewDetail === "preview-details" ||
                    previewDetail === "health-preview-details")
                    ? "Test Link"
                    : textName
                }
                class_name="primaryBtn"
                onClickFunction={
                  forward || (() => value_update?.(attrName!, value! + 1))
                }
                loading={loader}
                disabled={disableButton}
              />
              {atob(GET_QUERY_PARAMETERS("user_type")) === "agent" && (
                <CustomButton
                  text_name="Share Link"
                  class_name="primaryBtn ml-3"
                  onClickFunction={() => setShowPaymentPopup(true)}
                />
              )}
            </Box>
            {/* This button will display only on preview page */}
            {/* <Buttons class_name='regularPrimaryBtn ml-4' text_name='Test Link'/> */}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Footer;
