import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import STARCKYC from "../../../Page/Desktop/CKYC/Star/STARCKYC";
import { CKYC_ROUTES } from "../../../Router/Path/CKYCRoutes";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import { CKYCServcies } from "../../../Services/CKYC/CKYCServices";
import { ECompanyCode } from "../../../Services/Enum/EHome";
import { CKYCSlice } from "../../../redux/slice/CKYC/CKYCSlice";
import { CommonDropdownSlice } from "../../../redux/slice/CommonDropdown/CommonDropdownSlice";
import { HealthSlice } from "../../../redux/slice/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  isEmpty,
  validateFullName,
  validatePincode,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TVerifyCKYCForm } from "../../../types/CKYC/TCKYC";
import { TDropdown } from "../../../types/Common/TDropdown";
import MStarKyc from "../../../Page/Mobile/CKYC/Star/MStarKyc";
const STARCKYCContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const REDUX_HEAD = useAppSelector((state) => state);
  // slice_reducer
  const { CITY } = useAppSelector((state) => state.CommonDropdownData);
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  const { SELECTED_QUOTE_DATA, ADD_FORM_RESPONSE } = useAppSelector(
    (state) => state.Health
  );
  const { CKYC, Health } = useAppSelector((state) => state);
  const [userType, setUserType] = useState<string>("");
  const [utm_medium, setUtm_medium] = useState<string>("");
  const [utm_source, setUtm_source] = useState<string>("");

  useEffect(() => {
    // Access the query parameters directly from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const userTypeFromQuery = searchParams.get("user_type");
    const utm_mediumFromQuery = searchParams.get("utm_medium");
    const utm_sourceFromQuery = searchParams.get("utm_source");
    setUtm_medium(`${utm_mediumFromQuery}`);
    setUtm_source(`${utm_sourceFromQuery}`);
    setUserType(
      atob(`${userTypeFromQuery}`) === "agent" ? btoa("agent") : btoa("user")
    );
  }, []);
  // useState
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCKYCDetailsPopup, setShowCKYCDetailsPopup] =
    useState<boolean>(false);
  const [showManualPopup, setShowManualPopup] = useState<boolean>(false);
  const [formDetails, setFormDetails] =
    useState<TVerifyCKYCForm>(VERIFY_KYC_FORM);
  const isMobile = useIsMobile();
  const [kycToggle, setKycToggle] = useState(true);
  useEffect(() => {
    GET_DROPDOWN_MASTER("POV");
    dispatch(CKYCSlice.actions.SET_MANUAL_KYC_STATUS(false));
  }, []);

  useEffect(() => {
    if (validatePincode(formDetails.pincode.value)) {
      GET_CITY_BY_PINCODE();
    } else {
      dispatch(
        CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
          key: "CITY",
          value: [],
        })
      );
    }
  }, [formDetails.pincode.value]);

  const GET_CITY_BY_PINCODE = () => {
    // setIsLoading(true);
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;

      if (!error) {
        setIsLoading(false);
        const response = results.response?.city;
        const manupulated_data: TDropdown[] = [];
        for (const data of response) {
          manupulated_data.push({ value: data.city_id, label: data.city_name });
        }

        dispatch(
          CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
            key: "CITY",
            value: manupulated_data,
          })
        );
      }
    };
    const onError = (error: any) => {
      // setIsLoading(false);
    };
    const pincode_params = formDetails.pincode.value;
    CKYCServcies.GET_CITY_BY_PINCODE(onSuccess, onError, pincode_params);
  };

  useEffect(() => {
    if (formDetails.city.value) {
      GET_AREA_BY_CITY();
    } else {
      dispatch(
        CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
          key: "AREA_TOWN",
          value: [],
        })
      );
    }
  }, [formDetails.city.value]);

  const GET_AREA_BY_CITY = () => {
    // setIsLoading(true);
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;

      if (!error) {
        setIsLoading(false);
        const response = results.response?.area;
        const manupulated_data: TDropdown[] = [];
        for (const data of response) {
          manupulated_data.push({ value: data.areaID, label: data.areaName });
        }
        dispatch(
          CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
            key: "AREA_TOWN",
            value: manupulated_data,
          })
        );
      }
    };
    const onError = (error: any) => {
      setIsLoading(false);
    };
    const pincode_params = formDetails.pincode.value;
    const city_params = formDetails.city.value;

    CKYCServcies.GET_AREA_BY_CITY(
      onSuccess,
      onError,
      pincode_params,
      city_params
    );
  };

  const GET_DROPDOWN_MASTER = (TYPE: "POI" | "POA" | "POV") => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;

      if (!error) {
        const response = results.response;
        const manupulated_data: TDropdown[] = Object.entries(response).map(
          ([key, value]) => ({
            label: `${value}`,
            value: `${key}`,
          })
        );

        dispatch(
          CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
            key: TYPE,
            value: manupulated_data,
          })
        );
      }
    };
    const onError = (error: any) => {};
    const Health_params = {
      company_code: "G015",
      list_for: TYPE,
    };
    CKYCServcies.CKYC_DROPDOWN_MASTER(onSuccess, onError, Health_params);
  };

  const updateMasterState = (attrName: string, value: any) => {
    if (attrName === "full_name") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: !validateFullName(value) },
      }));
    } else if (attrName === "dob") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName === "gender") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName === "doc_type") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName === "doc_id") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName === "pincode") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: {
          value: value,
          warning:
            attrName === "pincode" ? !validatePincode(value) : isEmpty(value),
        },
      }));
    } else if (attrName === "city") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName === "area_town") {
      console.log("sfdsdfc", value);
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName === "kyc_toggle") {
      setKycToggle(false);
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.MANUAL_CKYC}?type=HP`
      );
    } else {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    }
  };
  const validateForm = () => {
    let data: TVerifyCKYCForm = { ...formDetails };
    data = {
      ...data,
      full_name: {
        ...data.full_name,
        warning: !validateFullName(data.full_name.value),
      },
      dob: {
        ...data.dob,
        warning: isEmpty(data.dob.value),
      },
      doc_type: {
        ...data.doc_type,
        warning: isEmpty(data.doc_type.value),
      },
      doc_id: {
        ...data.doc_id,
        warning: isEmpty(data.doc_id.value),
      },
      customer_type: {
        ...data.customer_type,
        warning: isEmpty(data.customer_type.value),
      },
      gender: { ...data.gender, warning: isEmpty(data.gender.value) },
      pincode: {
        ...data.pincode,
        warning: !validatePincode(data.pincode.value),
      },
      city: {
        ...data.city,
        warning: isEmpty(data.city.value),
      },
      houseNo: {
        ...data.houseNo,
        warning: isEmpty(data.houseNo.value),
      },
      area_town: {
        ...data.area_town,
        warning: isEmpty(data.area_town.value),
      },
    };
    setFormDetails(data);
    let hasError =
      data.full_name.warning ||
      data.doc_type.warning ||
      data.doc_id.warning ||
      data.customer_type.warning ||
      data.dob.warning ||
      data.pincode.warning ||
      data.city.warning ||
      data.houseNo.warning ||
      data.area_town.warning;

    if (data.customer_type.value !== "Organization") {
      hasError = hasError || data.gender.warning;
    }
    if (!hasError) {
      VERIFY_CKYC_DETAILS(data);
    }
  };

  const VERIFY_CKYC_DETAILS = (data: any) => {
    const onSuccess = (res: any) => {
      setIsLoading(false);
      const results = res.results;
      const error = results.error;
      if (!error) {
        const message = results.message;
        const response = results.response;
        if (message === "No record found") {
          const kycManualURL = response.kycManualURL;
          dispatch(
            CKYCSlice.actions.BULK_UPDATE({
              ...CKYC,
              VERIFY_KYC_FORM: { ...data },
              //   MANUAL_KYC_THIRD_PARTY_URL: kycManualURL,
            })
          );
          setShowManualPopup(true);
        } else {
          dispatch(
            CKYCSlice.actions.BULK_UPDATE({
              ...CKYC,
              VERIFY_KYC_FORM: { ...data },
              CKYC_DETAILS: response,
            })
          );
          setShowCKYCDetailsPopup(true);
        }
      } else {
        setShowManualPopup(true);
      }
    };

    const onError = (err: any) => {};

    let dto: any = {
      cusType: data.customer_type.value,
      quote_no: ADD_FORM_RESPONSE.quote_no,
      dobForKyc: data.dob.value,
      docNameForKyc: data.doc_type.value,
      docIDNoForKyc: data.doc_id.value,
      addressPPincode: data.pincode.value,
      addressPCity: data.city.value,
      addressPArea: data.area_town.value,
      addressPHouse: data.houseNo.value,
      section: "premium_details",
    };
    setIsLoading(true);
    CKYCServcies.VERIFY_STAR_CKYC(onSuccess, onError, dto);
  };
  const CONTINUE_TO_PROPOSAL = () => {
    dispatch(
      HealthSlice.actions.BULK_UPLOAD({
        ...Health,
        INSURED_MEMBER_DETAILS: {
          ...Health.INSURED_MEMBER_DETAILS,
          PROPOSER_DETAILS: {
            ...Health.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS,
            name: {
              value: CKYC.CKYC_DETAILS.kycCustomerName,
              warning: false,
            },
            dob: {
              value: CKYC.CKYC_DETAILS.kycDOB,
              warning: false,
            },
            mobile: { value: CKYC.CKYC_DETAILS.kycMobile, warning: false },
            emergencyPhone: { value: "", warning: false },
            email: { value: CKYC.CKYC_DETAILS.kycEmail, warning: false },
            aadhar: { value: "", warning: false },
            gender: { value: VERIFY_KYC_FORM.gender.value, warning: false },
            maritalStatus: { value: "", warning: false },
            weight: { value: "", warning: false },
            heightFeet: { value: "", warning: false },
            heightInches: { value: "", warning: false },
            occupation: { value: "", warning: false },
            relationship: { value: "", warning: false },
            proposer_name: { value: "", warning: false },
          },
        },
        ADDRESS_DETAILS: {
          ...Health.ADDRESS_DETAILS,
          pincode: { value: CKYC.CKYC_DETAILS.kycPincode, warning: false },
          city: CKYC.CKYC_DETAILS.kycCity,
          state: CKYC.CKYC_DETAILS.kycState,
          addressLineOne: {
            value: CKYC.CKYC_DETAILS.kycAddress1,
            warning: false,
          },
          addressLineTwo: {
            value: CKYC.CKYC_DETAILS.kycAddress2,
            warning: false,
          },
          isMailingAddressSame: true,
          mailingPinCode: { value: "", warning: false },
          mailingCity: "",
          mailingState: "",
          mailingAddressLineOne: { value: "", warning: false },
          mailingAddressLineTwo: { value: "", warning: false },
        },
      })
    );
    if (SELECTED_QUOTE_DATA.CompanyDetails.company_code === ECompanyCode.STAR) {
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}?type=HP&companyCode=${Health?.SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${Health?.ADD_FORM_RESPONSE.quote_no}&product_id=${Health?.SELECTED_QUOTE_DATA?.productDetails.id}&utm_medium=${utm_medium}&utm_source=${utm_source}&user_type=${userType}`
      );
    } else {
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADDON_DETAILS}?type=HP&companyCode=${Health?.SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${Health?.ADD_FORM_RESPONSE.quote_no}&product_id=${Health?.SELECTED_QUOTE_DATA?.productDetails.id}&utm_medium=${utm_medium}&utm_source=${utm_source}&user_type=${userType}`
      );
    }
  };

  return (
    <>
      {isMobile ? (
        <MStarKyc
          isLoading={isLoading}
          showCKYCDetailsPopup={showCKYCDetailsPopup}
          setShowCKYCDetailsPopup={setShowCKYCDetailsPopup}
          showManualPopup={showManualPopup}
          setShowManualPopup={setShowManualPopup}
          updateMasterState={updateMasterState}
          formDetails={formDetails}
          validateForm={validateForm}
          CONTINUE_TO_PROPOSAL={CONTINUE_TO_PROPOSAL}
          kycToggle={kycToggle}
        />
      ) : (
        <STARCKYC
          isLoading={isLoading}
          showCKYCDetailsPopup={showCKYCDetailsPopup}
          setShowCKYCDetailsPopup={setShowCKYCDetailsPopup}
          showManualPopup={showManualPopup}
          setShowManualPopup={setShowManualPopup}
          updateMasterState={updateMasterState}
          formDetails={formDetails}
          validateForm={validateForm}
          CONTINUE_TO_PROPOSAL={CONTINUE_TO_PROPOSAL}
          kycToggle={kycToggle}
        />
      )}
    </>
  );
};

export default STARCKYCContainer;
