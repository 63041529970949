import { useEffect, useState } from "react";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import {
  isEmpty,
  validatePincode,
} from "../../../../SupportingFiles/HelpingFunction";
import { CAR_SERVICES } from "../../../../Services/Car/CarServices";
import { ECompanyCode, EProductId } from "../../../../Services/Enum/EHome";
import { toast } from "react-toastify";
import { TDropdown } from "../../../../types/Common/TDropdown";
import { CKYCServcies } from "../../../../Services/CKYC/CKYCServices";
import { CommonDropdownSlice } from "../../../../redux/slice/CommonDropdown/CommonDropdownSlice";
import AddressDetails from "../../../../Page/Desktop/Travel/CARE/AddressDetails/AddressDetails";
import { TAddressDetails } from "../../../../types/Travel/TTravelSlice";
import { TravelSlice } from "../../../../redux/slice/Travel/TravelSlice";
import { TRAVEL_PROPOSAL_SERVICES } from "../../../../Services/Travel/TravelPropsalServices";
import { TRAVEL_ROUTE } from "../../../../Router/Path/TravelRoutes";

export default function AddressDetailsContainer() {
  const { CKYC_DETAILS } = useAppSelector((state) => state.CKYC);
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    ADDRESS_DETAILS,
    PAGE_STATUS,
    ADD_FORM,
    ADD_FORM_RESPONSE,
    SELECTED_QUOTE_DATA,
  } = useAppSelector((state) => state.Travel);
  // Permanent Address
  const [permanentAddress, setPermanentAddress] =
    useState<TAddressDetails>(ADDRESS_DETAILS);

  useEffect(() => {
    if (validatePincode(permanentAddress.pincode.value)) {
      GetCityStateByPin("PERMANENT");
    }
  }, [permanentAddress.pincode.value]);

  useEffect(() => {
    setPermanentAddress(ADDRESS_DETAILS);
  }, [ADDRESS_DETAILS]);

  useEffect(() => {
    if (validatePincode(permanentAddress.mailingPinCode.value)) {
      GetCityStateByPin("MAILING");
    }
  }, [permanentAddress.mailingPinCode.value]);
  const ADD_API_DATA = () => {
    const onSuccess = (res: any) => {
      if (res.results.error === false) {
        let quoteData = SELECTED_QUOTE_DATA;
        // quoteData = {
        //   ...quoteData,
        //   premiumDetails: {
        //     ...quoteData.premiumDetails,
        //     finalPremium: res.results.response.premiumDetails.finalPremium,
        //   },
        // };
        // dispatch(TravelSlice.actions.SAVE_SELECTED_QUOTE_DATA(quoteData));
        // dispatch(TravelSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));

        // Perform hard refresh on success
        // (window.location.reload as any)(true);
      }
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    // dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    let param: any = {
      company_code: SELECTED_QUOTE_DATA.companyCode,
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA.productID,
    };

    // setTimeout(() => {
    //   HEALTH_PROPOSAL_SERVICES.getMedicalData(onSuccess, onError, param);
    // }, 500); // 2000 milliseconds = 2 seconds
  };
  useEffect(() => {
    if (SELECTED_QUOTE_DATA.companyCode !== ECompanyCode.GO_DIGIT) {
      if (permanentAddress.isMailingAddressSame === true) {
        let formValue = { ...ADDRESS_DETAILS };
        formValue = {
          ...formValue,
          mailingPinCode: {
            ...formValue.pincode,
            warning: !validatePincode(formValue.pincode.value),
          },
          mailingAddressLineOne: {
            ...formValue.addressLineOne,
            warning: isEmpty(formValue.addressLineOne.value),
          },
          mailingAddressLineTwo: {
            ...formValue.addressLineTwo,
            warning: isEmpty(formValue.addressLineTwo.value),
          },
        };
        setPermanentAddress({ ...formValue });
      }
    }
  }, [ADDRESS_DETAILS]);

  const updateMasterState = (attrName: any, value: any) => {
    setPermanentAddress((prev: any) => ({
      ...prev,
      [attrName]: {
        value: value,
        warning:
          attrName === "pincode" ? !validatePincode(value) : isEmpty(value),
      },
    }));
  };

  const handleClick = () => {
    setPermanentAddress((prev: any) => ({
      ...prev,
      isMailingAddressSame: !prev.isMailingAddressSame,
    }));
  };

  const validateForm = () => {
    let formValue = { ...permanentAddress };
    formValue = {
      ...formValue,
      pincode: {
        ...formValue.pincode,
        warning: !validatePincode(formValue.pincode.value),
      },
      addressLineOne: {
        ...formValue.addressLineOne,
        warning: isEmpty(formValue.addressLineOne.value),
      },
      addressLineTwo: {
        ...formValue.addressLineTwo,
        warning: isEmpty(formValue.addressLineTwo.value),
      },
      mailingPinCode: {
        ...formValue.mailingPinCode,
        warning: !validatePincode(formValue.mailingPinCode.value),
      },
      mailingAddressLineOne: {
        ...formValue.mailingAddressLineOne,
        warning: isEmpty(formValue.mailingAddressLineOne.value),
      },
      mailingAddressLineTwo: {
        ...formValue.mailingAddressLineTwo,
        warning: isEmpty(formValue.mailingAddressLineTwo.value),
      },
    };
    setPermanentAddress({ ...formValue });
    const onSuccess = (res: any) => {
      console.log(res, "fggnmnmhhm");
      ADD_API_DATA();
      const error = res.error;
      if (!error) {
        if (PAGE_STATUS === true) {
          navigate(
            `${TRAVEL_ROUTE.PROPOSAL_PAGE}/${
              TRAVEL_ROUTE.PREVIEW_DETAILS
            }?type=TI&companyCode=${
              SELECTED_QUOTE_DATA?.companyCode
            }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
              SELECTED_QUOTE_DATA?.productID
            }${cus_type === "YWdlbnQ=" ? "&user_type=YWdlbnQ=" : ""}`
          );
        } else if (SELECTED_QUOTE_DATA?.companyCode == "G002") {
          navigate(
            `${TRAVEL_ROUTE.PROPOSAL_PAGE}/${
              TRAVEL_ROUTE.PREVIEW_DETAILS
            }?type=TI&companyCode=${
              SELECTED_QUOTE_DATA?.companyCode
            }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
              SELECTED_QUOTE_DATA?.productID
            }${cus_type === "YWdlbnQ=" ? "&user_type=YWdlbnQ=" : ""}`
          );
        } else {
          navigate(
            `${TRAVEL_ROUTE.PROPOSAL_PAGE}/${
              TRAVEL_ROUTE.MEDICAL_DETAILS
            }?type=TI&companyCode=${
              SELECTED_QUOTE_DATA?.companyCode
            }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
              SELECTED_QUOTE_DATA?.productID
            }${cus_type === "YWdlbnQ=" ? "&user_type=YWdlbnQ=" : ""}`
          );
        }
      }
    };
    const onError = (err: any) => {
      console.log("errr", err);
    };

    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const cus_type = params.get("cus_type");

    let param = {
      addressPPincode: formValue.pincode.value,
      addressPHouse: formValue.addressLineOne.value,
      addressPArea: formValue.addressLineTwo.value,
      addressPCity: formValue.city,
      addressPState: formValue.state,
      addressMailingSame: formValue.isMailingAddressSame ? "Yes" : "No",
      addressMPincode: formValue.mailingPinCode.value,
      addressMHouse: formValue.mailingAddressLineOne.value,
      addressMArea: formValue.mailingAddressLineTwo.value,
      addressMCity: formValue.mailingCity,
      addressMState: formValue.mailingState,
      section: "address_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      cover: ADD_FORM.members.value,
      finalPremium: `${SELECTED_QUOTE_DATA.quote_details?.premiumDetails.premium}`,
    };

    if (
      !formValue.pincode.warning &&
      !formValue.addressLineOne.warning &&
      !formValue.addressLineTwo.warning
    ) {
      let shouldNavigate = false;
      if (!permanentAddress.isMailingAddressSame) {
        if (
          !formValue.mailingAddressLineOne.warning &&
          !formValue.mailingAddressLineTwo.warning &&
          !formValue.mailingPinCode.warning
        ) {
          shouldNavigate = true;
        }
      } else {
        shouldNavigate = true;
      }

      if (shouldNavigate) {
        TRAVEL_PROPOSAL_SERVICES.getCapturedRecordData(
          onSuccess,
          onError,
          param
        );
        dispatch(TravelSlice.actions.SAVE_ADDRESS_DETAILS({ ...formValue }));
      }
    }
  };

  const GetCityStateByPin = (key: "PERMANENT" | "MAILING") => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;

      if (error === false) {
        const response = results.response;

        if (key === "PERMANENT") {
          setPermanentAddress((prev: any) => ({
            ...prev,
            city: response.city,
            state: response.state,
          }));
        } else {
          setPermanentAddress((prev: any) => ({
            ...prev,
            mailingCity: response.city,
            mailingState: response.state,
          }));
        }
      }
    };

    const onError = (err: any) => {
      console.log("updatefalse", err);
    };
    let param = {
      pincode:
        key === "PERMANENT"
          ? permanentAddress.pincode.value
          : permanentAddress.mailingPinCode.value,
    };

    CAR_SERVICES.GetCityStateByPin(onSuccess, onError, param);
  };

  const GET_AREA_BY_CITY = () => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;

      if (!error) {
        const response = results.response?.area;
        const manupulated_data: TDropdown[] = [];
        for (const data of response) {
          manupulated_data.push({ value: data.areaID, label: data.areaName });
        }
        dispatch(
          CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
            key: "AREA_TOWN",
            value: manupulated_data,
          })
        );
      }
    };
    const onError = (error: any) => {};
    const pincode_params = CKYC_DETAILS.kycPincode;
    const city_params = CKYC_DETAILS.kycAddress3;

    CKYCServcies.GET_AREA_BY_CITY(
      onSuccess,
      onError,
      pincode_params,
      city_params
    );
  };

  return isMobile ? null : (
    <AddressDetails
      updateMasterState={updateMasterState}
      validateForm={validateForm}
      handleClick={handleClick}
      permanentAddress={permanentAddress}
    />
  );
}
