import { useEffect, useState } from "react";
import { TBank_Details_NIVABUPA } from "../../../types/Health/THealthSlice";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import {
  isEmpty,
  validateIFSC,
} from "../../../SupportingFiles/HelpingFunction";
import { useNavigate } from "react-router-dom";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import { HEALTH_PROPOSAL_SERVICES } from "../../../Services/health-node/HealthPropsalServices";
import BankDetailsNivaBupa from "../../../Page/Desktop/health-php/NIVA_BUPA/BankDetails/BankDetailsNivaBupa";
import MBankDetailsNivaBupa from "../../../Page/Mobile/health-php/NIVA_BUPA/MBankDetails/BankDetailsNivaBupa";
import { HealthSlice } from "../../../redux/slice/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

const NivaBupaBankDetailsContainer = () => {
  const { Health } = useAppSelector((state) => state);
  const navigate = useNavigate();
  const { BANK_DETAILS_NIVABUPA, ADD_FORM_RESPONSE, ADD_FORM } = useAppSelector(
    (state) => state.Health
  );
  const [userType, setUserType] = useState<string>("");
  const [utm_medium, setUtm_medium] = useState<string>("");
  const [utm_source, setUtm_source] = useState<string>("");

  useEffect(() => {
    // Access the query parameters directly from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const userTypeFromQuery = searchParams.get("user_type");
    const utm_mediumFromQuery = searchParams.get("utm_medium");
    const utm_sourceFromQuery = searchParams.get("utm_source");
    setUtm_medium(`${utm_mediumFromQuery}`);
    setUtm_source(`${utm_sourceFromQuery}`);
    setUserType(
      atob(`${userTypeFromQuery}`) === "agent" ? btoa("agent") : btoa("user")
    );
  }, []);
  const [disbaledButton, setDisbaledButton] = useState(false);
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const [BankDetails, setBankDetails] = useState<TBank_Details_NIVABUPA>({
    ...BANK_DETAILS_NIVABUPA,
  });

  const getCapturedRecordData = () => {
    const onSuccess = (res: any) => {
      console.log("res", res);
    };

    const onError = (err: any) => {
      console.log("err..", err);
    };

    let param = {
      BankAccountNumber: BankDetails.BankAccountNumber.value,
      BankAccountType: BankDetails.BankAccountType.value,
      BankName: BankDetails.BankName.value,
      IFSCCode: BankDetails.IFSCCode.value,
      CustomerBankBranch: BankDetails.CustomerBankBranch.value,
      BankCity: BankDetails.BankCity.value,
      quote_no: ADD_FORM_RESPONSE.quote_no,
      section: "bank_details",
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${(parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
          0
          ? `${(parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0)
          }C`
          : ""
        }`,
    };
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  const validateForm = () => {
    let bank_details_data = { ...BankDetails };

    bank_details_data = {
      BankAccountNumber: {
        ...bank_details_data.BankAccountNumber,
        warning: isEmpty(`${bank_details_data.BankAccountNumber.value} `),
      },
      BankAccountType: {
        ...bank_details_data.BankAccountType,
        warning: isEmpty(`${bank_details_data.BankAccountType.value} `),
      },
      BankName: {
        ...bank_details_data.BankName,
        warning: isEmpty(`${bank_details_data.BankName.value} `),
      },
      IFSCCode: {
        ...bank_details_data.IFSCCode,
        warning: !validateIFSC(`${bank_details_data.IFSCCode.value} `),
      },
      CustomerBankBranch: {
        ...bank_details_data.CustomerBankBranch,
        warning: isEmpty(`${bank_details_data.CustomerBankBranch.value} `),
      },
      BankCity: {
        ...bank_details_data.BankCity,
        warning: isEmpty(`${bank_details_data.BankCity.value} `),
      },
    };

    setBankDetails({ ...bank_details_data });
    let hasError = false;
    if (
      bank_details_data.BankAccountNumber.warning ||
      bank_details_data.BankAccountType.warning ||
      bank_details_data.BankName.warning ||
      bank_details_data.IFSCCode.warning ||
      bank_details_data.CustomerBankBranch.warning ||
      bank_details_data.BankCity.warning ||
      hasError
    ) {
      hasError = true;
    }

    if (!hasError) {
      dispatch(
        HealthSlice.actions.BULK_UPLOAD({
          ...Health,
          BANK_DETAILS_NIVABUPA: {
            BankAccountNumber: BankDetails.BankAccountNumber,
            BankAccountType: BankDetails.BankAccountType,
            BankName: BankDetails.BankName,
            IFSCCode: BankDetails.IFSCCode,
            CustomerBankBranch: BankDetails.CustomerBankBranch,
            BankCity: BankDetails.BankCity,
          },
        })
      );
      getCapturedRecordData();
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADDRESS_DETAILS}?type=HP&companyCode=${Health?.SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${Health?.SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
      );
    }
  };

  const getBankDataByIFSC = (IFSCCODE: string) => {
    const onSuccess = (res: any) => {
      setBankDetails((prev) => ({
        ...prev,
        BankName: {
          value: res.response.BankName,
          warning: false,
        },
        CustomerBankBranch: {
          value: res.response.BankBranchName,
          warning: false,
        },
        BankCity: {
          value: res.response.City,
          warning: false,
        },
      }));
    };

    const onError = (err: any) => {
      console.log("err..", err);
    };

    let param = {
      IFSCCODE: IFSCCODE,
    };

    HEALTH_PROPOSAL_SERVICES.GET_BANK_DETAILS_BY_IFSC_CODE(
      onSuccess,
      onError,
      param
    );
  };

  const updateBankDetail = (
    attrName: keyof TBank_Details_NIVABUPA,
    value: string
  ) => {
    console.log(attrName, "attrName", value);
    if (attrName == "IFSCCode") {
      getBankDataByIFSC(value);
      setBankDetails((prevState) => ({
        ...prevState,
        [attrName]: { ...prevState[attrName], value },
      }));
    } else {
      setBankDetails((prevState) => ({
        ...prevState,
        [attrName]: { ...prevState[attrName], value },
      }));
    }
  };

  return isMobile ? (
    <MBankDetailsNivaBupa
      BankDetails={BankDetails}
      updateBankDetail={updateBankDetail}
      validateForm={validateForm}
      disbaledButton={disbaledButton}
    />
  ) : (
    <BankDetailsNivaBupa
      BankDetails={BankDetails}
      updateBankDetail={updateBankDetail}
      validateForm={validateForm}
      disbaledButton={disbaledButton}
    />
  );
};

export default NivaBupaBankDetailsContainer;
