import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import AddonQuestion from "../../../../../Component/AddonQuestion/AddonQuestion";
import { TSUPER_STAR_ADDON } from "../../../../../types/Health/THealthSlice";
import Footer from "../../../../../Component/Footer/Footer";
import { useAppSelector } from "../../../../../redux/hooks";
import { HEALTH_ROUTE } from "../../../../../Router/Path/HealthRoutes";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";

const AddonDetailSuperStar = ({
  updateMasterState,
  addonStatus,
  validateForm,
}: {
  updateMasterState: Function;
  addonStatus: TSUPER_STAR_ADDON;
  validateForm: Function;
}) => {
  const navigate = useNavigate();
  const { SELECTED_QUOTE_DATA, ADD_FORM, QUOTE_LOADER, PAGE_STATUS } =
    useAppSelector((state) => state.Health);
  return (
    <Box>
      <h5 className="sectionTitle">Addon Details</h5>
      <Grid xs={12}>
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="SMART NETWORK"
          toggle_status={addonStatus.SMART_NETWORK}
          value_update={updateMasterState}
          attrName={"SMART_NETWORK"}
          desc=""
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="QUICK SHIELD"
          toggle_status={addonStatus.QUICK_SHIELD}
          value_update={updateMasterState}
          attrName={"QUICK_SHIELD"}
          desc=""
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="CONSUMABLES COVER"
          toggle_status={addonStatus.CONSUMABLES_COVER}
          value_update={updateMasterState}
          attrName={"CONSUMABLES_COVER"}
          desc=""
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="FUTURE SHIELD"
          toggle_status={addonStatus.FUTURE_SHIELD}
          value_update={updateMasterState}
          attrName={"FUTURE_SHIELD"}
          desc=""
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="MATERNITY EXPENSES"
          toggle_status={addonStatus.MATERNITY_EXPENSES}
          value_update={updateMasterState}
          attrName={"MATERNITY_EXPENSES"}
          desc=""
          sub_details={
            <Grid xs={6}>
              <SelectDropdown
                class_name="inputField"
                title="MATERNITY EXPENSES"
                value={addonStatus?.MATERNITY_EXPENSES_OPTION}
                attrName={"MATERNITY_EXPENSES_OPTION"}
                value_update={updateMasterState}
                data={[
                  {
                    value: "option1",
                    label:
                      "Delivery Expenses with 24 months Waiting Period + NB Premium for delivery",
                  },
                  {
                    value: "option2",
                    label:
                      "Delivery Expenses with 12 months Waiting Period + NB Premium",
                  },
                  {
                    value: "option3",
                    label: "ART with 24 months waiting period",
                  },
                ]}
                warn_status={false}
                error_message="Select Percentage"
              />
            </Grid>
          }
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="WOMEN CARE"
          toggle_status={addonStatus.WOMEN_CARE}
          value_update={updateMasterState}
          attrName={"WOMEN_CARE"}
          desc=""
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="HIGH-END DIAGNOSTICS"
          toggle_status={addonStatus.HIGHEND_DIAGNOSTICS}
          value_update={updateMasterState}
          attrName={"HIGHEND_DIAGNOSTICS"}
          desc=""
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="PERSONAL ACCIDENT COVER"
          toggle_status={addonStatus.PERSONAL_ACCIDENT_COVER}
          value_update={updateMasterState}
          attrName={"PERSONAL_ACCIDENT_COVER"}
          desc=""
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="ANNUAL HEALTH CHECK-UP"
          toggle_status={addonStatus.ANNUAL_HEALTH_CHECKUP}
          value_update={updateMasterState}
          attrName={"ANNUAL_HEALTH_CHECKUP"}
          desc=""
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="VOLUNTARY CO-PAYMENT"
          toggle_status={addonStatus.VOLUNTARY_COPAYMENT}
          value_update={updateMasterState}
          attrName={"VOLUNTARY_COPAYMENT"}
          desc=""
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="VOLUNTARY DEDUCTIBLE"
          toggle_status={addonStatus.VOLUNTARY_DEDUCTIBLE}
          value_update={updateMasterState}
          attrName={"VOLUNTARY_DEDUCTIBLE"}
          desc=""
          sub_details={
            <Grid xs={6}>
              <SelectDropdown
                class_name="inputField"
                title="voluntary Deductible"
                value={addonStatus?.voluntaryDeductible}
                attrName={"voluntaryDeductible"}
                value_update={updateMasterState}
                data={[
                  {
                    value: 10000,
                    label: 10000,
                  },
                  {
                    value: 25000,
                    label: 25000,
                  },
                  {
                    value: 50000,
                    label: 50000,
                  },
                  {
                    value: 100000,
                    label: 100000,
                  },
                  {
                    value: 200000,
                    label: 200000,
                  },
                  {
                    value: 300000,
                    label: 300000,
                  },
                  {
                    value: 400000,
                    label: 400000,
                  },
                  {
                    value: 500000,
                    label: 500000,
                  },
                ]}
                warn_status={false}
                error_message="Select Percentage"
              />
            </Grid>
          }
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="ROOM RENT MODIFICATION"
          toggle_status={addonStatus.ROOM_RENT_MODIFICATION}
          value_update={updateMasterState}
          attrName={"ROOM_RENT_MODIFICATION"}
          desc=""
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="E-INTERNATIONAL SECOND OPINION"
          toggle_status={addonStatus.E_INTERNATIONAL_SECOND_OPINION}
          value_update={updateMasterState}
          attrName={"E_INTERNATIONAL_SECOND_OPINION"}
          desc=""
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="DURABLE MEDICAL EQUIPMENT COVER"
          toggle_status={addonStatus.DURABLE_MEDICAL_EQUIPMENT_COVER}
          value_update={updateMasterState}
          attrName={"DURABLE_MEDICAL_EQUIPMENT_COVER"}
          desc=""
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="COMPASSIONATE VISIT"
          toggle_status={addonStatus.COMPASSIONATE_VISIT}
          value_update={updateMasterState}
          attrName={"COMPASSIONATE_VISIT"}
          desc=""
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="HOSPITAL CASH BENEFIT"
          toggle_status={addonStatus.HOSPITAL_CASH_BENEFIT}
          value_update={updateMasterState}
          attrName={"HOSPITAL_CASH_BENEFIT"}
          desc=""
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="REDUCTION OF SPECIFIC DISEASE/PROCEDURE WAITING"
          toggle_status={addonStatus.REDUCTION_SPECIFIC_DISEASE_PROCEDURE}
          value_update={updateMasterState}
          attrName={"REDUCTION_SPECIFIC_DISEASE_PROCEDURE"}
          desc=""
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="REDUCTION OF PRE-EXISTING DISEASE WAITING PERIOD"
          toggle_status={addonStatus.REDUCTION_PRE_EXISTING_DISEASE}
          value_update={updateMasterState}
          attrName={"REDUCTION_PRE_EXISTING_DISEASE"}
          desc=""
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="LIMITLESS CARE"
          toggle_status={addonStatus.LIMITLESS_CARE}
          value_update={updateMasterState}
          attrName={"LIMITLESS_CARE"}
          desc=""
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="SUPER STAR BONUS"
          toggle_status={addonStatus.SUPER_STAR_BONUS}
          value_update={updateMasterState}
          attrName={"SUPER_STAR_BONUS"}
          desc=""
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="NRI ADVANTAGE"
          toggle_status={addonStatus.NRI_ADVANTAGE}
          value_update={updateMasterState}
          attrName={"NRI_ADVANTAGE"}
          desc=""
        />
      </Grid>
      <Footer
        textName={PAGE_STATUS === true ? "Update" : "Continue"}
        attrName={"pageStatus"}
        forward={() => {
          validateForm();
        }}
        value={0}
        disableButton={QUOTE_LOADER}
      />
    </Box>
  );
};

export default AddonDetailSuperStar;
