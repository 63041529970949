import { Route, Routes } from "react-router-dom";
import ICICICKYCContainer from "../../../../../Container/CKYC/ICICI/ICICICKYCContainer";
import ICICIManualKYCContainer from "../../../../../Container/CKYC/ICICI/ICICIManualKYCContainer";
import AddonDetailsContainerGoldenShieldIcici from "../../../../../Container/health-php/ICIC/AddonDetailsContainerICIC/AddonDetailsContainerGoldenShieldIcici";
import AddonDetailsContainerICIC from "../../../../../Container/health-php/ICIC/AddonDetailsContainerICIC/AddonDetailsContainerICIC";
import MedicalDetailsContainerICIC from "../../../../../Container/health-php/ICIC/AddonDetailsContainerICIC/MedicalDetailsContainerICIC/MedicalDetailsContainerICIC";
import { ECompanyCode, EProductId } from "../../../../../Services/Enum/EHome";
import { useAppSelector } from "../../../../../redux/hooks";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import { HEALTH_ROUTE } from "../../../../Path/HealthRoutes";
import HealthProposalContainer from "../../HealthProposalContainer";

export const ICICI_ROUTES = () => {
  const { SELECTED_QUOTE_DATA } = useAppSelector((state) => state.Health);
  const company_code = useAppSelector(
    (state) => state.Health.SELECTED_QUOTE_DATA.CompanyDetails.company_code
  );

  return (
    <Routes>
      <Route
        path={HEALTH_ROUTE.PROPOSAL_PAGE}
        element={<HealthProposalContainer />}
      >
        <Route path={CKYC_ROUTES.CKYC} element={<ICICICKYCContainer />} />
        <Route
          path={CKYC_ROUTES.MANUAL_CKYC}
          element={<ICICIManualKYCContainer />}
        />
        {company_code === ECompanyCode.ICIC &&
        SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.ICICI_GOLDEN_PLAN ? (
          <Route
            path={HEALTH_ROUTE.ADDON_DETAILS}
            element={<AddonDetailsContainerGoldenShieldIcici />}
          />
        ) : (
          <></>
        )}
        {company_code === ECompanyCode.ICIC &&
        SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.ICICI_HEALTH_ADVANTAGE ? (
          <Route
            path={HEALTH_ROUTE.ADDON_DETAILS}
            element={<AddonDetailsContainerICIC />}
          />
        ) : (
          <></>
        )}
        <Route
          path={HEALTH_ROUTE.MEDICAL_DETAILS}
          element={<MedicalDetailsContainerICIC />}
        />
      </Route>
    </Routes>
  );
};
