import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import SelectDropdown from "../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import MTermFooter from "../../../../Component/Term/MTermFooter/MTermFooter";
import MTermRiderQuestion from "../../../../Component/Term/MTermRiderQuestion/MTermRiderQuestion";
import { TermSlice } from "../../../../redux/slice/Term/TermSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { formatToCurrency } from "../../../../SupportingFiles/HelpingFunction";

type addon = {
  free: number;
  description: string;
  rider_term: string;
  rider_pay_term: string;
  sum_assured: number;
  sa_percentage: number;
  min_sa_percentage: string;
  max_sa_percentage: number;
  interval_value: string;
  interval: string;
  premium: any;
  status: boolean;
  tf001Data: any;
  tf003Data: any;
};
function MRidersBenefits({
  addOnData,
  forward,
  tf001Data,
  tf003Data,
  loaderTf001,
  loaderTf003,
}: {
  addOnData?: { [key: string]: addon };
  forward: Function;
  tf001Data: any;
  tf003Data: any;
  loaderTf001: any;
  loaderTf003: any;
}) {
  const { RIDERS_BENEFITS, SELECTED_QUOTE_DATA } = useAppSelector(
    (state) => state.Term
  );
  const dispatch = useAppDispatch();

  return (
    <Box className="proposalPreview">
      <h5 className="sectionTitle">Addon Details</h5>
      <Grid container spacing={3}>
        {SELECTED_QUOTE_DATA?.productDetails?.frequency === "5" ? null : (
          <Grid xs={12}>
            <MTermRiderQuestion
              loader={loaderTf001}
              addon_amount={`${RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF001?.premium}`}
              main_class="addonQuestion borderBottom"
              icon_class={"hospital"}
              question_name={"Critical Illness Cover"}
              policy_term=""
              desc={
                RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF001
                  ?.description
              }
              sub_details={
                <Box sx={{ maxWidth: "250px" }}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Select Value"
                    value={RIDERS_BENEFITS.local_value.tf001Value}
                    attrName={"tf001Value"}
                    value_update={(a: any, v: any) => {
                      dispatch(
                        TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
                          {
                            updates: [
                              {
                                key: "tf001Value",
                                value: v,
                              },
                            ],
                          }
                        )
                      );
                    }}
                    data={tf001Data}
                    warn_status={false}
                  />
                </Box>
              }
              attrName={"tf001Value"}
              toggle_status={RIDERS_BENEFITS.local_value.tf001Status}
              value_update={(attrName: any, value: any) => {
                dispatch(
                  TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                    updates: [
                      {
                        key: "tf001Status",
                        value: value,
                      },
                    ],
                  })
                );
              }}
            />
          </Grid>
        )}

        <Grid xs={12}>
          <MTermRiderQuestion
            loader={loaderTf003}
            addon_amount={`${RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF003?.premium}`}
            main_class="addonQuestion borderBottom"
            icon_class={"accident"}
            policy_term=""
            desc={
              RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF003
                ?.description
            }
            question_name={`Accidental Death Benefit`}
            sub_details={
              <Box sx={{ maxWidth: "250px" }}>
                <SelectDropdown
                  class_name="inputField"
                  attrName={""}
                  title="Select Value"
                  value={RIDERS_BENEFITS.local_value.tf003Value}
                  value_update={(a: any, v: any) => {
                    dispatch(
                      TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                        updates: [
                          {
                            key: "tf003Value",
                            value: v,
                          },
                        ],
                      })
                    );
                  }}
                  data={tf003Data}
                  warn_status={false}
                />
              </Box>
            }
            toggle_status={RIDERS_BENEFITS.local_value.tf003Status}
            value_update={(attrName: any, value: any) => {
              dispatch(
                TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "tf003Status",
                      value: value,
                    },
                  ],
                })
              );
            }}
            attrName={"tf003Value"}
          />
        </Grid>
      </Grid>
      <MTermFooter
        forward={() => {
          forward();
        }}
      />
    </Box>
  );
}

export default MRidersBenefits;
