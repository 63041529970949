import { Box, FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { TAddressDetails } from "../../../../../types/Common/TAddressDetails";
import RKTextField from "../../../../../Component/FieldTypes/RKTextField/RKTextField";
import Footer from "../../../../../Component/Footer/Footer";
import {
  FIND_LABEL_OF_VALUE,
  capitalizeWords,
} from "../../../../../SupportingFiles/HelpingFunction";
import { ECompanyCode, EProductId } from "../../../../../Services/Enum/EHome";
import { HealthSlice } from "../../../../../redux/slice/Health/HealthSlice";
import { useEffect } from "react";
import { toast } from "react-toastify";

const AddressDetails = ({
  updateMasterState,
  validateForm,
  handleClick,
  permanentAddress,
}: {
  updateMasterState: Function;
  validateForm: Function;
  handleClick: Function;
  permanentAddress: TAddressDetails;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { PAGE_STATUS, SELECTED_QUOTE_DATA, BANK_DETAILS_HDFC } =
    useAppSelector((state) => state.Health);
  const Health = useAppSelector((state) => state.Health);

  const { AREA_TOWN } = useAppSelector((state) => state.CommonDropdownData);

  let goDigit =
    Health.SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G033";

  let Bajaj = Health.SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G001";

  console.log("permanentAddress", permanentAddress);

  return (
    <Box className="proposalAddress">
      <h5 className="sectionTitle">Address Details</h5>
      <Grid container spacing={3} alignItems="center">
        <Grid xs={4}>
          <RKTextField
            validation_type="NUMBER"
            max_length={6}
            class_name="inputField"
            title={"Pincode"}
            value={permanentAddress.pincode.value}
            attrName={"pincode"}
            value_update={updateMasterState}
            warn_status={permanentAddress.pincode.warning}
            error_message="Enter Pincode"
            disabled={
              SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                ECompanyCode.ADITY_BIRLA || permanentAddress.pincode.value
                ? true
                : SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                    ECompanyCode.GO_DIGIT ||
                  SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                    ECompanyCode.BAJAJ
                ? false
                : false
            }
          />
        </Grid>
        <Grid xs={8}>
          {permanentAddress.city
            ? `${permanentAddress.city}, ${capitalizeWords(
                permanentAddress.state
              )}`
            : null}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={6} md={5}>
          <RKTextField
            class_name="inputField"
            title={"H.No. / Building"}
            value={permanentAddress.addressLineOne.value}
            attrName={"addressLineOne"}
            value_update={updateMasterState}
            warn_status={permanentAddress.addressLineOne.warning}
            error_message="Enter Address"
            disabled={
              goDigit || Bajaj || permanentAddress.addressLineOne.value === ""
                ? false
                : true
            }
          />
        </Grid>

        <Grid xs={6} md={5}>
          <RKTextField
            class_name="inputField"
            title={"Area / Town / Locality"}
            value={
              SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G015"
                ? FIND_LABEL_OF_VALUE(
                    AREA_TOWN,
                    permanentAddress.addressLineTwo.value
                  )
                : permanentAddress.addressLineTwo.value
            }
            attrName={"addressLineTwo"}
            value_update={updateMasterState}
            warn_status={permanentAddress.addressLineTwo.warning}
            error_message="Enter Address"
            disabled={
              goDigit || Bajaj || permanentAddress.addressLineTwo.value === ""
                ? false
                : true
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <FormControlLabel
            className="proposalCheckBoxWrapper"
            control={
              <Checkbox
                defaultChecked={permanentAddress.isMailingAddressSame}
                checked={permanentAddress.isMailingAddressSame}
                value={permanentAddress.isMailingAddressSame}
                onClick={() => handleClick()}
              />
            }
            label="Mailing / Correspondence address is same as permanent address."
          />
        </Grid>
      </Grid>

      {/* Mailing Address */}
      {!permanentAddress.isMailingAddressSame ? (
        <Box>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={4}>
              <RKTextField
                validation_type="NUMBER"
                max_length={6}
                class_name="inputField"
                title={"Pincode"}
                value={permanentAddress.mailingPinCode.value}
                attrName={"mailingPinCode"}
                value_update={updateMasterState}
                warn_status={permanentAddress.mailingPinCode.warning}
                error_message="Enter Pincode"
              />
            </Grid>
            <Grid xs={8}>
              {`${permanentAddress.mailingCity}, ${capitalizeWords(
                permanentAddress.mailingState
              )}`}
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={6} md={5}>
              <RKTextField
                class_name="inputField"
                title={"H.No. / Building"}
                value={permanentAddress.mailingAddressLineOne.value}
                attrName={"mailingAddressLineOne"}
                value_update={updateMasterState}
                warn_status={permanentAddress.mailingAddressLineOne.warning}
                error_message="Enter Address"
              />
            </Grid>
            <Grid xs={6} md={5}>
              <RKTextField
                class_name="inputField"
                title={"Area / Town / Locality"}
                // value={permanentAddress.mailingAddressLineTwo.value}
                value={
                  SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G015"
                    ? FIND_LABEL_OF_VALUE(
                        AREA_TOWN,
                        permanentAddress.mailingAddressLineTwo.value
                      )
                    : permanentAddress.mailingAddressLineTwo.value
                }
                attrName={"mailingAddressLineTwo"}
                value_update={updateMasterState}
                warn_status={permanentAddress.mailingAddressLineTwo.warning}
                error_message="Enter Address"
              />
            </Grid>
          </Grid>
        </Box>
      ) : null}

      {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
      ECompanyCode.HDFC_HEALTH ? (
        <Grid container spacing={3}>
          <Grid xs={12}>
            <FormControlLabel
              className="proposalCheckBoxWrapper"
              control={
                <Checkbox
                  defaultChecked={false}
                  value={BANK_DETAILS_HDFC.DispatchFlag.value}
                  onChange={(e: any, v: any) => {
                    dispatch(
                      HealthSlice.actions.BULK_UPLOAD({
                        ...Health,
                        BANK_DETAILS_HDFC: {
                          ...BANK_DETAILS_HDFC,
                          DispatchFlag: {
                            value: v,
                            warning: false,
                          },
                        },
                      })
                    );
                  }}
                />
              }
              label="Additionally, by ticking the check box we understand that you wish to have a physical copy of your policy."
            />
          </Grid>
        </Grid>
      ) : null}

      <Footer
        textName={PAGE_STATUS === true ? "Update" : "Continue"}
        forward={() => validateForm()}
      />
    </Box>
  );
};

export default AddressDetails;
