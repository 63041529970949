import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import "./MedicalQuestion.scss";
import MemberRadioButtonNew from "../MemberRadioButton/MemberRadioButtonNew";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { useAppSelector } from "../../redux/hooks";
import SelectDropdown from "../FieldTypes/SelectDropdown/SelectDropdown";
import DatePicker from "../FieldTypes/DatePicker/DatePicker";
import { calculateAgeInDays } from "../../SupportingFiles/HelpingFunction";
import { subDays } from "date-fns";
import RKTextField from "../FieldTypes/RKTextField/RKTextField";

interface Props {
  main_class: string;
  icon_class: string;
  question_name: string;
  sub_details?: any;
  input_id_first: string;
  input_id_second: string;
  input_name: string;
  toggle_button_status?: boolean;
  value_update?: Function;
  attrName?: any;
  select_disease?: any;
  data?: any;
  dataSubQuestion?: any;
  updateMasterState?: any;
}
const Medicaldetailsecure: React.FC<Props> = ({
  main_class,
  icon_class,
  question_name,
  sub_details,
  input_id_first,
  input_id_second,
  input_name,
  value_update,
  toggle_button_status,
  attrName,
  data,
  select_disease,
  dataSubQuestion,
  updateMasterState,
}) => {
  const { INSURED_MEMBER_DETAILS, ADD_FORM, KYC_DETAILS } = useAppSelector(
    (state) => state.Health
  );
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  const update_status = (attrName: any, value: any) => {
    value_update?.(attrName, value);
  };
  console.log("INSURED_MEMBER_DETAILS", INSURED_MEMBER_DETAILS);
  const LifeOfManagement_data: any = [];

  for (let i = 1; i <= 2; i++) {
    let status;
    if (i === 1) {
      status = "MEDICAL";
    } else if (i === 2) {
      status = "SURGICAL";
    }
    LifeOfManagement_data.push({ value: status, label: `${status}` });
  }

  const CurrentStatus_data: any = [];

  for (let i = 1; i <= 3; i++) {
    let status;
    if (i === 1) {
      status = "CURED";
    } else if (i === 2) {
      status = "NOT TREATED";
    } else if (i === 3) {
      status = "ONGOING";
    }

    CurrentStatus_data.push({ value: status, label: `${status}` });
  }
  const Details_of_treatment: any = [];

  for (let i = 1; i <= 2; i++) {
    let status;
    if (i === 1) {
      status = "Yes";
    } else if (i === 2) {
      status = "NO";
    }

    Details_of_treatment.push({ value: status, label: `${status}` });
  }
  return (
    <Box className={main_class}>
      <Grid container spacing={2}>
        <Grid xs={8} md={9} className="medicalQuestionInner">
          <span className={icon_class}></span>
          <div>
            <h6>{question_name}</h6>
            {sub_details}
          </div>
        </Grid>
        <Grid xs={4} md={3} textAlign="right" className="pt-4">
          <ToggleSwitch
            toggle_status={toggle_button_status}
            value_update={update_status}
            attrName={attrName}
          />
        </Grid>
      </Grid>
      {select_disease}

      <Grid container spacing={2}>
        {data?.main_question?.member_data &&
          (data?.main_question?.id === "MQ02" ||
            data?.main_question?.id === "MQ03" ||
            data?.main_question?.id === "MQ04" ||
            data?.main_question?.id === "MQ05" ||
            data?.main_question?.id === "MQ07" ||
            data?.main_question?.id === "MQ08") &&
          data?.main_question?.status &&
          Object.values(data?.main_question?.member_data)?.map(
            (memberDataValue: any, memberIndex: number) => (
              <React.Fragment key={memberIndex}>
                {memberDataValue.name !== "" && (
                  <Grid xs={4}>
                    {data?.main_question?.id === "MQ04" ? (
                      memberIndex > 1 ? (
                        ""
                      ) : (INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.relationship
                          .value === "Spouse" &&
                          memberIndex === 1 &&
                          INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.gender
                            .value === "M") ||
                        (INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.gender
                          .value === "F" &&
                          memberIndex === 0) ? (
                        <MemberRadioButtonNew
                          class_name={
                            memberIndex === 0 || memberIndex === 1
                              ? "adult"
                              : "child"
                          }
                          checked={memberDataValue?.selection_status}
                          label_name={memberDataValue.name}
                          attrName={[
                            "mainMemberData",
                            data?.main_question?.id,
                            memberDataValue?.keyName,
                          ]}
                          value_update={updateMasterState}
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      <MemberRadioButtonNew
                        class_name={
                          memberIndex === 0 || memberIndex === 1
                            ? "adult"
                            : "child"
                        }
                        checked={memberDataValue?.selection_status}
                        label_name={memberDataValue.name}
                        attrName={[
                          "mainMemberData",
                          data?.main_question?.id,
                          memberDataValue?.keyName,
                        ]}
                        value_update={updateMasterState}
                      />
                    )}
                    {memberDataValue?.selection_status &&
                      memberDataValue.keyName && (
                        <>
                          {Object.values(memberDataValue.field_data).map(
                            (field_data_data: any) => {
                              let datePickerRendered = false; // Flag to track DatePicker rendering
                              return (
                                <React.Fragment key={field_data_data.id}>
                                  {field_data_data.field_type === "DROPDOWN" ? (
                                    <SelectDropdown
                                      class_name=" inputField mb-5"
                                      title={field_data_data.title}
                                      attrName={[
                                        "mainMemberDataField",
                                        data?.main_question?.id,
                                        memberDataValue.keyName,
                                        field_data_data.id,
                                      ]}
                                      value={field_data_data?.value?.value}
                                      value_update={updateMasterState}
                                      warn_status={
                                        field_data_data?.value?.warning
                                      }
                                      data={
                                        field_data_data?.title ===
                                        "Current Status"
                                          ? CurrentStatus_data
                                          : field_data_data?.title ===
                                            "Is Medical Question Opted"
                                          ? Details_of_treatment
                                          : LifeOfManagement_data
                                      }
                                      error_message={"Enter description"}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {field_data_data.field_type ===
                                  "TEXTFIELD" ? (
                                    <RKTextField
                                      id="mainMemberDataField"
                                      class_name=" inputField mb-5"
                                      title={field_data_data.title}
                                      attrName={[
                                        "mainMemberDataField",
                                        data?.main_question?.id,
                                        memberDataValue.keyName,
                                        field_data_data.id,
                                      ]}
                                      value={field_data_data?.value?.value}
                                      value_update={updateMasterState}
                                      warn_status={
                                        field_data_data?.value?.warning
                                      }
                                      error_message={"Enter description"}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {field_data_data.field_type ===
                                    "DATEPICKER" &&
                                    !datePickerRendered && (
                                      <>
                                        <DatePicker
                                          class_name="inputField mb-5"
                                          title={field_data_data?.title}
                                          value={field_data_data?.value?.value}
                                          attrName={[
                                            "mainMemberDataField",
                                            data?.main_question?.id,
                                            memberDataValue.keyName,
                                            field_data_data.id,
                                          ]}
                                          value_update={updateMasterState}
                                          min_date={
                                            memberDataValue.keyName ===
                                            "adult_one"
                                              ? INSURED_MEMBER_DETAILS
                                                  .ADULT_ONE_DETAILS.dob.value
                                                ? calculateAgeInDays(
                                                    `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                  )
                                                : calculateAgeInDays(
                                                    `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                  )
                                              : memberDataValue.keyName ===
                                                "adult_two"
                                              ? calculateAgeInDays(
                                                  `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`
                                                )
                                              : memberDataValue.keyName ===
                                                "child_one"
                                              ? calculateAgeInDays(
                                                  `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`
                                                )
                                              : memberDataValue.keyName ===
                                                "child_two"
                                              ? calculateAgeInDays(
                                                  `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`
                                                )
                                              : memberDataValue.keyName ===
                                                "child_three"
                                              ? calculateAgeInDays(
                                                  `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`
                                                )
                                              : memberDataValue.keyName ===
                                                "child_four"
                                              ? calculateAgeInDays(
                                                  `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`
                                                )
                                              : 25
                                          }
                                          max_date={
                                            (field_data_data.title ===
                                              "Surgery Date" &&
                                              field_data_data.id ===
                                                "MQ1-SQ1-F2") ||
                                            (field_data_data.title ===
                                              "Pregnant Delivery Date" &&
                                              field_data_data.id ===
                                                "MQ1-SQ1-F1")
                                              ? -365
                                              : 0
                                          }
                                          default_date={subDays(
                                            new Date(),
                                            memberDataValue.keyName ===
                                              "adult_one"
                                              ? INSURED_MEMBER_DETAILS
                                                  .ADULT_ONE_DETAILS.dob.value
                                                ? calculateAgeInDays(
                                                    `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                  )
                                                : calculateAgeInDays(
                                                    `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                  )
                                              : memberDataValue.keyName ===
                                                "adult_two"
                                              ? calculateAgeInDays(
                                                  `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`
                                                )
                                              : memberDataValue.keyName ===
                                                "child_one"
                                              ? calculateAgeInDays(
                                                  `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`
                                                )
                                              : memberDataValue.keyName ===
                                                "child_two"
                                              ? calculateAgeInDays(
                                                  `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`
                                                )
                                              : memberDataValue.keyName ===
                                                "child_three"
                                              ? calculateAgeInDays(
                                                  `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`
                                                )
                                              : memberDataValue.keyName ===
                                                "child_four"
                                              ? calculateAgeInDays(
                                                  `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`
                                                )
                                              : 25
                                          )}
                                          warn_status={
                                            field_data_data?.value?.warning
                                          }
                                          date_validation_type="DAYS"
                                          error_message={
                                            "Select existing since"
                                          }
                                        />
                                      </>
                                    )}
                                </React.Fragment>
                              );
                            }
                          )}
                        </>
                      )}
                  </Grid>
                )}
              </React.Fragment>
            )
          )}
      </Grid>
    </Box>
  );
};

export default Medicaldetailsecure;
