import { TCarAddonStatus } from "../types/Car/TCarAddonStatus";
import { TCarSingleQuoteDetail } from "../types/Car/TCarSingleQuoteDetail";
import { calculate_passenger_cover } from "./HelpingFunction";

function parse_value(value: any): number {
  if (isNaN(parseFloat(value))) {
    return 0;
  } else {
    return parseFloat(value);
  }
}

export function CarPremiumCal_iffco_tokio(
  quote_details: TCarSingleQuoteDetail | undefined,
  addon_status: TCarAddonStatus,
  passenger_cover_user_value: string,
  PLAN_TYPE: string,
  OWNERSHIP: "Individual" | "Organization",
  reg_date: string
) {
  let premium: number = 0;
  let final_premium: number = 0;

  if (quote_details === undefined) return 0;

  const netPremium = parse_value(quote_details.premiumDetails.netPremium);
  const paOwnerDriver = parse_value(quote_details.premiumDetails.paOwnerDriver);
  const depreciationCover = parse_value(
    quote_details.premiumDetails.depreciationCover
  );
  const roadSideAssistance = parse_value(
    quote_details.premiumDetails.roadSideAssistance
  );
  const noClaimBonusSameSlab = parse_value(
    quote_details.premiumDetails.noClaimBonusSameSlab
  );
  const consumablesCover = parse_value(
    quote_details.premiumDetails.consumablesCover
  );
  const driverCover = parse_value(quote_details.premiumDetails.driverCover);

  const cngKitOD = parse_value(quote_details.premiumDetails.cngKitOD);
  const cngKitTP = parse_value(quote_details.premiumDetails.cngKitTP);
  const passengerCover = parse_value(
    quote_details.premiumDetails.passerngerCover
  );

  premium =
    netPremium -
    paOwnerDriver -
    depreciationCover -
    roadSideAssistance -
    noClaimBonusSameSlab -
    consumablesCover -
    driverCover -
    cngKitOD -
    cngKitTP -
    passengerCover;

  if (
    addon_status.pa_owner_cover.value ||
    quote_details.premiumDetails.includedPAOwnerDriver === 1
  ) {
    premium += paOwnerDriver;
  }

  if (
    OWNERSHIP === "Organization" &&
    (addon_status.pa_owner_cover.value === true ||
      quote_details.premiumDetails?.includedPAOwnerDriver === 1)
  ) {
    premium -= paOwnerDriver;
  }

  if (addon_status.depreciation_cover.value) {
    premium += depreciationCover;
  }
  if (addon_status.depreciation_cover.value && addon_status.rsa.value) {
    premium += roadSideAssistance;
  }
  if (addon_status.depreciation_cover.value && addon_status.ncb_cover.value) {
    premium += noClaimBonusSameSlab;
  }
  if (
    addon_status.depreciation_cover.value &&
    addon_status.consumable_cover.value
  ) {
    premium += consumablesCover;
  }

  if (addon_status.driver_cover.value) {
    premium += driverCover;
  }

  premium += cngKitOD;
  premium += cngKitTP;

  if (addon_status.passenger_cover.value && PLAN_TYPE !== "SAOD") {
    premium += calculate_passenger_cover(
      Number(`${passenger_cover_user_value}`),
      quote_details,
      PLAN_TYPE
    );
  }

  let tax_amount = (premium / 100) * 18;

  final_premium = premium + tax_amount;

  return final_premium;
}
