import React, { useEffect, useState } from "react";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import MedicalDetailsSuperStar from "../../../../Page/Desktop/health-node/STAR/MedicalDetailsSuperStar";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { TStarMedicalQuestion } from "../../../../types/Health/HealthQuotation/TStarMedicalQuestion";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import { THealthQuote } from "../../../../types/Health/HealthQuotation/THealthQuote";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/health-node/HealthPropsalServices";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { HealthSlice } from "../../../../redux/slice/Health/HealthSlice";

const MedicalDetailsContainerSuperStar = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    PAGE_STATUS,
    Preview_Loader,
    MEDICAL_QUESTION_DATA_SUPER_STAR,
    INSURED_MEMBER_DETAILS,
    SELECTED_QUOTE_DATA,
    ADD_FORM_RESPONSE,
  } = useAppSelector((state) => state.Health);
  const [userType, setUserType] = useState<string>("");
  const [utm_medium, setUtm_medium] = useState<string>("");
  const [utm_source, setUtm_source] = useState<string>("");

  useEffect(() => {
    // Access the query parameters directly from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const userTypeFromQuery = searchParams.get("user_type");
    const utm_mediumFromQuery = searchParams.get("utm_medium");
    const utm_sourceFromQuery = searchParams.get("utm_source");
    setUtm_medium(`${utm_mediumFromQuery}`);
    setUtm_source(`${utm_sourceFromQuery}`);
    setUserType(
      atob(`${userTypeFromQuery}`) === "agent" ? btoa("agent") : btoa("user")
    );
  }, []);
  const [medicalQuestionData, setMedicalQuestionData] =
    useState<TStarMedicalQuestion>(MEDICAL_QUESTION_DATA_SUPER_STAR);

  const setMemberDataAsPerUserSelection = () => {
    let setMemberData: any = { ...MEDICAL_QUESTION_DATA_SUPER_STAR };
    setMemberData = {
      MQ01: {
        main_question: {
          id: "MQ01",
          icon_class: "tobaco",
          title: "ANY HEALTH DISEASE",
          description: "",
          status: false,
          sub_question: {
            "MQ1-SQ1": {
              id: "MQ1-SQ1",
              icon_class: "",
              status: false,
              title: "DIABETES",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Duration of Diabetes",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Type of Diabetes",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "DIABETES DIAGNOSIS",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "DIABETES HBA1C",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Was your age between 30 to 65 years ?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Duration of Diabetes",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Type of Diabetes",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "DIABETES DIAGNOSIS",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "DIABETES HBA1C",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Was your age between 30 to 65 years ?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Duration of Diabetes",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Type of Diabetes",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "DIABETES DIAGNOSIS",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "DIABETES HBA1C",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Was your age between 30 to 65 years ?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Duration of Diabetes",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Type of Diabetes",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "DIABETES DIAGNOSIS",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "DIABETES HBA1C",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Was your age between 30 to 65 years ?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Duration of Diabetes",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Type of Diabetes",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "DIABETES DIAGNOSIS",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "DIABETES HBA1C",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Was your age between 30 to 65 years ?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Duration of Diabetes",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Type of Diabetes",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "DIABETES DIAGNOSIS",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "DIABETES HBA1C",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Was your age between 30 to 65 years ?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ2-SQ2": {
              id: "MQ2-SQ2",
              icon_class: "",
              status: false,
              title: "HYPERTENTION",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "HYPERTENSION DURATION",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "HYPERTENSION DIAGNOSIS",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Was your age between 30 to 65 years ?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "HYPERTENSION DURATION",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "HYPERTENSION DIAGNOSIS",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Was your age between 30 to 65 years ?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "HYPERTENSION DURATION",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "HYPERTENSION DIAGNOSIS",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Was your age between 30 to 65 years ?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "HYPERTENSION DURATION",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "HYPERTENSION DIAGNOSIS",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Was your age between 30 to 65 years ?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "HYPERTENSION DURATION",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "HYPERTENSION DIAGNOSIS",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Was your age between 30 to 65 years ?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "HYPERTENSION DURATION",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "HYPERTENSION DIAGNOSIS",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Was your age between 30 to 65 years ?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ3-SQ3": {
              id: "MQ3-SQ3",
              icon_class: "",
              title: "ASTHMA",
              description: "",
              status: false,
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Duration",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Symptoms",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "ASTHMA DRUGS USE",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "ASTHMA HOSPITALIZATION",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "ASTHMA RESPIRATORY CARDIAC FAILURE",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "ASTHMA PULMONARY TEST",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Duration",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Symptoms",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "ASTHMA DRUGS USE",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "ASTHMA HOSPITALIZATION",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "ASTHMA RESPIRATORY CARDIAC FAILURE",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "ASTHMA PULMONARY TEST",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Duration",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Symptoms",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "ASTHMA DRUGS USE",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "ASTHMA HOSPITALIZATION",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "ASTHMA RESPIRATORY CARDIAC FAILURE",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "ASTHMA PULMONARY TEST",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Duration",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Symptoms",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "ASTHMA DRUGS USE",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "ASTHMA HOSPITALIZATION",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "ASTHMA RESPIRATORY CARDIAC FAILURE",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "ASTHMA PULMONARY TEST",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Duration",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Symptoms",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "ASTHMA DRUGS USE",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "ASTHMA HOSPITALIZATION",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "ASTHMA RESPIRATORY CARDIAC FAILURE",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "ASTHMA PULMONARY TEST",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Duration",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Symptoms",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "ASTHMA DRUGS USE",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "ASTHMA HOSPITALIZATION",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "ASTHMA RESPIRATORY CARDIAC FAILURE",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "ASTHMA PULMONARY TEST",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ4-SQ4": {
              id: "MQ4-SQ4",
              icon_class: "",
              title: "CAD",
              description: "",
              status: false,
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Date of Cardiac Intervention",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Have you undergone CAG (Coronary Angiography)",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Have you undergone PTCA (Angioplasty)",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title:
                        "Have you undergone CABG (Coronary Artery Bypass Grafting) Surgery",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title:
                        "Any Diagnosis/treatment/ Hospitalization related to Retina, Brain or Neuro, Kidney related conditions.",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Date of Cardiac Intervention",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Have you undergone CAG (Coronary Angiography)",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Have you undergone PTCA (Angioplasty)",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title:
                        "Have you undergone CABG (Coronary Artery Bypass Grafting) Surgery",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title:
                        "Any Diagnosis/treatment/ Hospitalization related to Retina, Brain or Neuro, Kidney related conditions.",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Date of Cardiac Intervention",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Have you undergone CAG (Coronary Angiography)",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Have you undergone PTCA (Angioplasty)",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title:
                        "Have you undergone CABG (Coronary Artery Bypass Grafting) Surgery",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title:
                        "Any Diagnosis/treatment/ Hospitalization related to Retina, Brain or Neuro, Kidney related conditions.",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Date of Cardiac Intervention",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Have you undergone CAG (Coronary Angiography)",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Have you undergone PTCA (Angioplasty)",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title:
                        "Have you undergone CABG (Coronary Artery Bypass Grafting) Surgery",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title:
                        "Any Diagnosis/treatment/ Hospitalization related to Retina, Brain or Neuro, Kidney related conditions.",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Date of Cardiac Intervention",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Have you undergone CAG (Coronary Angiography)",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Have you undergone PTCA (Angioplasty)",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title:
                        "Have you undergone CABG (Coronary Artery Bypass Grafting) Surgery",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title:
                        "Any Diagnosis/treatment/ Hospitalization related to Retina, Brain or Neuro, Kidney related conditions.",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Date of Cardiac Intervention",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Have you undergone CAG (Coronary Angiography)",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Have you undergone PTCA (Angioplasty)",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title:
                        "Have you undergone CABG (Coronary Artery Bypass Grafting) Surgery",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title:
                        "Any Diagnosis/treatment/ Hospitalization related to Retina, Brain or Neuro, Kidney related conditions.",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ5-SQ5": {
              id: "MQ5-SQ5",
              icon_class: "",
              title: "BMI",
              description: "",
              status: false,
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "BMI DIAGNOSIS",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "BMI DIAGNOSIS",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "BMI DIAGNOSIS",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "BMI DIAGNOSIS",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "BMI DIAGNOSIS",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "BMI DIAGNOSIS",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                  },
                },
              },
            },
            "MQ6-SQ6": {
              id: "MQ6-SQ6",
              icon_class: "",
              title: "HYPERLIPIDAEMIA",
              description: "",
              status: false,
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Duration since detection",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Number of oral medicines",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Cholesterol level",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "HYPERLIPIDAEMIA DIAGNOSIS",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Duration since detection",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Number of oral medicines",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Cholesterol level",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "HYPERLIPIDAEMIA DIAGNOSIS",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Duration since detection",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Number of oral medicines",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Cholesterol level",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "HYPERLIPIDAEMIA DIAGNOSIS",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Duration since detection",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Number of oral medicines",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Cholesterol level",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "HYPERLIPIDAEMIA DIAGNOSIS",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Duration since detection",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Number of oral medicines",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Cholesterol level",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "HYPERLIPIDAEMIA DIAGNOSIS",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Duration since detection",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Number of oral medicines",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Cholesterol level",
                      field_type: "DROPDOWN",
                      value: { value: false, warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "HYPERLIPIDAEMIA DIAGNOSIS",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
          },
        },
      },
      MQ02: {
        main_question: {
          id: "MQ02",
          icon_class: "tobaco",
          title: "OTHER HEALTH QUESTION",
          description: "",
          status: false,
          sub_question: {
            "MQ1-SQ1": {
              id: "MQ1-SQ1",
              icon_class: "",
              status: false,
              title: "STROKE",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK STROKE DISEASE",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK STROKE DISEASE",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK STROKE DISEASE",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK STROKE DISEASE",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK STROKE DISEASE",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK STROKE DISEASE",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ2-SQ2": {
              id: "MQ2-SQ2",
              icon_class: "",
              status: false,
              title: "RESPIRATORY",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK RESPIRATORY INFECTION",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK RESPIRATORY INFECTION",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK RESPIRATORY INFECTION",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK RESPIRATORY INFECTION",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK RESPIRATORY INFECTION",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK RESPIRATORY INFECTION",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ3-SQ3": {
              id: "MQ3-SQ3",
              icon_class: "",
              title: "BONE INJURY",
              description: "",
              status: false,
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK BONE INJURY",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK BONE INJURY",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK BONE INJURY",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK BONE INJURY",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK BONE INJURY",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK BONE INJURY",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
              },
            },
            "MQ4-SQ4": {
              id: "MQ4-SQ4",
              icon_class: "",
              title: "CANCER",
              description: "",
              status: false,
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK_CANCER",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK_CANCER",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK_CANCER",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK_CANCER",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK_CANCER",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK_CANCER",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
              },
            },
            "MQ5-SQ5": {
              id: "MQ5-SQ5",
              icon_class: "",
              title: "GYNECOLOGY",
              description: "",
              status: false,
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK GYNECOLOGICAL DISORDER",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK GYNECOLOGICAL DISORDER",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK GYNECOLOGICAL DISORDER",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK GYNECOLOGICAL DISORDER",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK GYNECOLOGICAL DISORDER",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK GYNECOLOGICAL DISORDER",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
              },
            },
            "MQ6-SQ6": {
              id: "MQ6-SQ6",
              icon_class: "",
              title: "FERTILITY",
              description: "",
              status: false,
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK FERTILITY",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK FERTILITY",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK FERTILITY",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK FERTILITY",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK FERTILITY",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK FERTILITY",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
              },
            },
            "MQ7-SQ7": {
              id: "MQ7-SQ7",
              icon_class: "",
              title: "URINARY",
              description: "",
              status: false,
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK URINARY DISEASE",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK URINARY DISEASE",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK URINARY DISEASE",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK URINARY DISEASE",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK URINARY DISEASE",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK URINARY DISEASE",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
              },
            },
            "MQ8-SQ8": {
              id: "MQ8-SQ8",
              icon_class: "",
              title: "GENITAL",
              description: "",
              status: false,
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK GENITAL DISEASE",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK GENITAL DISEASE",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK GENITAL DISEASE",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK GENITAL DISEASE",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK GENITAL DISEASE",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK GENITAL DISEASE",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
              },
            },
            "MQ9-SQ9": {
              id: "MQ9-SQ9",
              icon_class: "",
              title: "ENT",
              description: "",
              status: false,
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK ENT DISEASE",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK ENT DISEASE",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK ENT DISEASE",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK ENT DISEASE",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK ENT DISEASE",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS?.name?.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "CHECK ENT DISEASE",
                      field_type: "DATEPICKER",
                      value: { value: false, warning: false },
                    },
                  },
                },
              },
            },
          },
        },
      },
      MQ03: {
        main_question: {
          id: "MQ03",
          icon_class: "tobaco",
          title: "INSURANCE",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK INSURANCE",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK INSURANCE",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK INSURANCE",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK INSURANCE",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK INSURANCE",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK INSURANCE",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
          },
        },
      },
      MQ04: {
        main_question: {
          id: "MQ04",
          icon_class: "tobaco",
          title: "INSURANCE COMPLICATION",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK INSURANCE COMPLICATION",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK INSURANCE COMPLICATION",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK INSURANCE COMPLICATION",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK INSURANCE COMPLICATION",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK INSURANCE COMPLICATION",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK INSURANCE COMPLICATION",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
          },
        },
      },
      MQ05: {
        main_question: {
          id: "MQ05",
          icon_class: "tobaco",
          title: "MEDICAL INS",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {},
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {},
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {},
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {},
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {},
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {},
            },
          },
        },
      },
      MQ06: {
        main_question: {
          id: "MQ06",
          icon_class: "tobaco",
          title: "MEDICINES",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "PRESCRIBED ILLNESSN",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "DRUG DETAILS PRESCRIBED",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "DRUG PERIOD DETAILS",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "PRESCRIBED ILLNESSN",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "DRUG DETAILS PRESCRIBED",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "DRUG PERIOD DETAILS",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "PRESCRIBED ILLNESSN",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "DRUG DETAILS PRESCRIBED",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "DRUG PERIOD DETAILS",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "PRESCRIBED ILLNESSN",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "DRUG DETAILS PRESCRIBED",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "DRUG PERIOD DETAILS",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "PRESCRIBED ILLNESSN",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "DRUG DETAILS PRESCRIBED",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "DRUG PERIOD DETAILS",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "PRESCRIBED ILLNESSN",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "DRUG DETAILS PRESCRIBED",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "DRUG PERIOD DETAILS",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
          },
        },
      },
      MQ07: {
        main_question: {
          id: "MQ07",
          icon_class: "tobaco",
          title: "SURGERY",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK SURGERY",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK SURGERY",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK SURGERY",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK SURGERY",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK SURGERY",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK SURGERY",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
          },
        },
      },
      MQ08: {
        main_question: {
          id: "MQ08",
          icon_class: "tobaco",
          title: "DISABILITY",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK DISABILITY",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK DISABILITY",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK DISABILITY",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK DISABILITY",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK DISABILITY",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK DISABILITY",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
          },
        },
      },
      MQ09: {
        main_question: {
          id: "MQ09",
          icon_class: "tobaco",
          title: "TOBACCO",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHEW TOBACCO",
                  field_type: "DATEPICKER",
                  value: { value: false, warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHEW TOBACCO",
                  field_type: "DATEPICKER",
                  value: { value: false, warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHEW TOBACCO",
                  field_type: "DATEPICKER",
                  value: { value: false, warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHEW TOBACCO",
                  field_type: "DATEPICKER",
                  value: { value: false, warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHEW TOBACCO",
                  field_type: "DATEPICKER",
                  value: { value: false, warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHEW TOBACCO",
                  field_type: "DATEPICKER",
                  value: { value: false, warning: false },
                },
              },
            },
          },
        },
      },
      MQ10: {
        main_question: {
          id: "MQ10",
          icon_class: "tobaco",
          title: "SMOKE",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "SMOKE",
                  field_type: "DATEPICKER",
                  value: { value: false, warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "SMOKE",
                  field_type: "DATEPICKER",
                  value: { value: false, warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "SMOKE",
                  field_type: "DATEPICKER",
                  value: { value: false, warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "SMOKE",
                  field_type: "DATEPICKER",
                  value: { value: false, warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "SMOKE",
                  field_type: "DATEPICKER",
                  value: { value: false, warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "SMOKE",
                  field_type: "DATEPICKER",
                  value: { value: false, warning: false },
                },
              },
            },
          },
        },
      },
      MQ11: {
        main_question: {
          id: "MQ11",
          icon_class: "tobaco",
          title: "ALCOHOL",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CONSUME ALCOHOL",
                  field_type: "DATEPICKER",
                  value: { value: false, warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CONSUME ALCOHOL",
                  field_type: "DATEPICKER",
                  value: { value: false, warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CONSUME ALCOHOL",
                  field_type: "DATEPICKER",
                  value: { value: false, warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CONSUME ALCOHOL",
                  field_type: "DATEPICKER",
                  value: { value: false, warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CONSUME ALCOHOL",
                  field_type: "DATEPICKER",
                  value: { value: false, warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CONSUME ALCOHOL",
                  field_type: "DATEPICKER",
                  value: { value: false, warning: false },
                },
              },
            },
          },
        },
      },
      MQ12: {
        main_question: {
          id: "MQ12",
          icon_class: "tobaco",
          title: "HIV",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK HIV",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK HIV",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK HIV",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK HIV",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK HIV",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS?.name?.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "CHECK HIV",
                  field_type: "TEXTFIELD",
                  value: { value: false, warning: false },
                },
              },
            },
          },
        },
      },
    };
    setMedicalQuestionData({ ...setMemberData });
  };

  useEffect(() => {
    if (PAGE_STATUS === true || Preview_Loader === true) {
      setMedicalQuestionData(MEDICAL_QUESTION_DATA_SUPER_STAR);
    } else {
      setMemberDataAsPerUserSelection();
    }
  }, [MEDICAL_QUESTION_DATA_SUPER_STAR, PAGE_STATUS]);
  let flag = true;

  const updateMasterState = (attrName: any, value: any) => {
    flag = value;
    console.log(attrName, "attrName", value);
    let manipulatedMedicalQuestion: any = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    if (attrName[0] === "question") {
      console.log(manipulatedMedicalQuestion[attrName[1]], "Before Update");
      manipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question,
            status: value,
          },
        },
      };

      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    } else if (attrName[0] === "subQuestion") {
      let updatedSubQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question,
        [attrName[2]]: {
          ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
            attrName[2]
          ],
          status: value,
        },
      };
      let updatedMainQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question,
        sub_question: updatedSubQuestion,
      };

      let updatedManipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: updatedMainQuestion,
        },
      };

      let subQues =
        manipulatedMedicalQuestion?.[attrName[1]]?.main_question.sub_question;
      Object.keys(subQues).forEach((subQuesKey: any) => {
        console.log("subQues", subQues[subQuesKey]);
        if (subQues[subQuesKey].id === attrName[2]) {
          subQues[subQuesKey].status = false;
          // }
          let memberData = subQues[subQuesKey].member_data;
          Object.keys(memberData).forEach((memberDataKey: any) => {
            if (memberData[memberDataKey].id === attrName[3]) {
              memberData[memberDataKey].selection_status = false;
            }
            let fieldData = memberData[memberDataKey].field_data;

            Object.keys(fieldData).forEach((fieldDataKey: any) => {
              fieldData[fieldDataKey].value = {
                value:
                  fieldData[fieldDataKey].field_type === "TEXTFIELD" ? "" : [],
                warning: false,
              };
            });
          });
        }
      });

      setMedicalQuestionData(updatedManipulatedMedicalQuestion);
    } else if (attrName[0] === "mainMember") {
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question.sub_question[
          attrName[2]
        ]?.member_data[attrName[3]] !== undefined
      ) {
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              sub_question: {
                ...manipulatedMedicalQuestion[attrName[1]].main_question
                  .sub_question,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    .sub_question?.[attrName[2]],
                  member_data: {
                    ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                      .sub_question?.[attrName[2]].member_data,
                    [attrName[3]]: {
                      ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                        .sub_question?.[attrName[2]].member_data?.[attrName[3]],
                      selection_status: value,
                    },
                  },
                },
              },
            },
          },
        };

        if (!value) {
          let memberData =
            manipulatedMedicalQuestion?.[attrName[1]]?.main_question
              .sub_question?.[attrName[2]].member_data;

          console.log("mem data", memberData);

          Object.keys(memberData).forEach((memberDataKey: any) => {
            if (memberData[memberDataKey].keyName === attrName[3]) {
              const fieldData = memberData[memberDataKey].field_data;
              Object.keys(fieldData).forEach((fieldDataKey: any) => {
                fieldData[fieldDataKey].value = {
                  value:
                    fieldData[fieldDataKey].field_type === "TEXTFIELD"
                      ? ""
                      : [],
                  warning: false,
                };
              });
            }
          });
        }

        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      }
    } else if (attrName[0] === "mainMemberData") {
      let mainQues = manipulatedMedicalQuestion[attrName[1]]?.main_question;
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data[
          attrName[2]
        ] !== undefined
      )
        if (!value && mainQues.status) {
          let memberData = mainQues?.member_data;
          Object.keys(memberData).forEach((memberDataKey: any) => {
            console.log("member key", memberData[memberDataKey]);
            if (memberData[memberDataKey].keyName === attrName[2]) {
              let fieldData = memberData[memberDataKey]?.field_data;
              Object.keys(fieldData).forEach((fieldDataKey: any) => {
                console.log("field >>>", fieldData[fieldDataKey]);
                fieldData[fieldDataKey].value = {
                  value:
                    fieldData[fieldDataKey].field_type === "TEXTFIELD"
                      ? ""
                      : [],
                  warning: false,
                };
              });
            }
          });
        }
      manipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: {
            ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
            member_data: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                .member_data,
              [attrName[2]]: {
                ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                  ?.member_data?.[attrName[2]],
                selection_status: value,
              },
            },
          },
        },
      };

      setMedicalQuestionData(manipulatedMedicalQuestion);
    } else if (attrName[0] === "mainMemberDataField") {
      if (
        ((attrName[1] === "MQ02" || attrName[1] === "MQ01") &&
          attrName[2] === "adult_one") ||
        ((attrName[1] === "MQ02" || attrName[1] === "MQ01") &&
          attrName[2] === "adult_two") ||
        ((attrName[1] === "MQ02" || attrName[1] === "MQ01") &&
          attrName[2] === "child_one") ||
        ((attrName[1] === "MQ02" || attrName[1] === "MQ01") &&
          attrName[2] === "child_two") ||
        ((attrName[1] === "MQ02" || attrName[1] === "MQ01") &&
          attrName[2] === "child_three") ||
        ((attrName[1] === "MQ02" || attrName[1] === "MQ01") &&
          attrName[2] === "child_four" &&
          manipulatedMedicalQuestion !== undefined)
      ) {
        manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
          attrName[4]
        ].member_data[attrName[2]].field_data[attrName[3]].value = {
          value: value,
          warning: isEmpty(value),
        };

        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      } else {
        if (
          attrName[2] === "adult_one" ||
          attrName[2] === "adult_two" ||
          attrName[2] === "child_one" ||
          attrName[2] === "child_two" ||
          attrName[2] === "child_three" ||
          (attrName[2] === "child_four" &&
            manipulatedMedicalQuestion !== undefined)
        ) {
        }

        manipulatedMedicalQuestion[attrName[1]].main_question.member_data[
          attrName[2]
        ].field_data[attrName[3]].value = {
          value: value,
          warning: isEmpty(value),
        };

        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      }
    }
  };

  const validateForm = () => {
    if (!medicalQuestionData) {
      return; // Exit early if medicalQuestionData is null or undefined
    }
    let data: TStarMedicalQuestion = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );

    let hasError = false;
    setMedicalQuestionData(data);

    dispatch(HealthSlice.actions.SAVE_MEDICAL_QUESTION_DATA_SUPER_STAR(data));
    const onSuccess = (res: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      if (res.results.error === false) {
        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.results.response.premiumDetails.finalPremium,
          },
        };
        dispatch(HealthSlice.actions.SAVE_SELECTED_QUOTE_DATA(quoteData));
      }
    };
    const onError = (err: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      // console.log("err..", err);
    };
    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));

    let param: any = {
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      quote_no: ADD_FORM_RESPONSE.quote_no,
      medical_info: "Yes",
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,

      isHealthDesease: data.MQ01.main_question.status === true ? "Y" : "N",

      DiabetesQuestion:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.status === true
          ? "Y"
          : "N",
      isMedicalDiabetes:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.adult_one.selection_status === true
          ? "Y"
          : "N",
      diabetesWhen:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,
      diabetesType:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F2"]?.value?.value,
      diabetesDiagnosis:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F3"]?.value?.value,
      diabetesHBA1C:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F4"]?.value?.value,
      diabetesDiagnosed:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F5"]?.value?.value,

      HypertensionQuestion:
        data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.status === true
          ? "Y"
          : "N",
      isMedicalHypertension:
        data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.adult_one.selection_status === true
          ? "Y"
          : "N",
      hypertensionWhen:
        data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,
      hypertensionDiagnosis:
        data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F2"]?.value?.value,
      hypertensionDiagnosed:
        data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F3"]?.value?.value,

      AsthmaQuestion:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.status === true
          ? "Y"
          : "N",
      isMedicalAsthma:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.adult_one.selection_status === true
          ? "Y"
          : "N",
      asthmaWhen:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,
      asthmaSymptoms:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F2"]?.value?.value,
      asthmaDrugsUse:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F3"]?.value?.value,
      asthmaHospitalization:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F4"]?.value?.value,
      asthmaRespiratoryCardiacFailure:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F5"]?.value?.value,
      asthmaPulmonaryTest:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F6"]?.value?.value,

      HyperlipidaemiaQuestion:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.status === true
          ? "Y"
          : "N",
      isMedicalHyperlipidaemia:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.adult_one.selection_status === true
          ? "Y"
          : "N",
      hyperlipidaemiaWhen:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,
      hyperlipidaemiaOralMedicineCount:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F2"]?.value?.value,
      hyperlipidaemiaCholestrolLevel:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F3"]?.value?.value,
      hyperlipidaemiaDiagnosis:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F4"]?.value?.value,

      BMIQuestion:
        data.MQ01.main_question.sub_question?.["MQ5-SQ5"]?.status === true
          ? "Y"
          : "N",
      isMedicalBMI:
        data.MQ01.main_question.sub_question?.["MQ5-SQ5"]?.member_data
          ?.adult_one.selection_status === true
          ? "Y"
          : "N",
      bmiDiagnosis:
        data.MQ01.main_question.sub_question?.["MQ5-SQ5"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      StrokeQuestion:
        data.MQ02.main_question.sub_question?.["MQ1-SQ1"]?.status === true
          ? "Y"
          : "N",
      isMedicalStroke:
        data.MQ02.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.adult_one.selection_status === true
          ? "Y"
          : "N",
      checkStrokeDisease:
        data.MQ02.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      RespiratoryQuestion:
        data.MQ02.main_question.sub_question?.["MQ2-SQ2"]?.status === true
          ? "Y"
          : "N",
      isMedicalRespiratory:
        data.MQ02.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.adult_one.selection_status === true
          ? "Y"
          : "N",
      respiratoryInfectionWhen:
        data.MQ02.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      PsychologicalQuestion:
        data.MQ02.main_question.sub_question?.["MQ3-SQ3"]?.status === true
          ? "Y"
          : "N",
      isMedicalBoneInjury:
        data.MQ02.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.adult_one.selection_status === true
          ? "Y"
          : "N",
      boneInjuryWhen:
        data.MQ02.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      CancerQuestion:
        data.MQ02.main_question.sub_question?.["MQ4-SQ4"]?.status === true
          ? "Y"
          : "N",
      isMedicalCancer:
        data.MQ02.main_question.sub_question?.["MQ4-SQ4"]?.member_data
          ?.adult_one.selection_status === true
          ? "Y"
          : "N",
      cancerWhen:
        data.MQ02.main_question.sub_question?.["MQ4-SQ4"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      GynecologyQuestion:
        data.MQ02.main_question.sub_question?.["MQ5-SQ5"]?.status === true
          ? "Y"
          : "N",
      isMedicalGynecology:
        data.MQ02.main_question.sub_question?.["MQ5-SQ5"]?.member_data
          ?.adult_one.selection_status === true
          ? "Y"
          : "N",
      gynecologyWhen:
        data.MQ02.main_question.sub_question?.["MQ5-SQ5"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      FertilityQuestion:
        data.MQ02.main_question.sub_question?.["MQ6-SQ6"]?.status === true
          ? "Y"
          : "N",
      isMedicalFertility:
        data.MQ02.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.adult_one.selection_status === true
          ? "Y"
          : "N",
      fertilityWhen:
        data.MQ02.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      UrinaryQuestion:
        data.MQ02.main_question.sub_question?.["MQ7-SQ7"]?.status === true
          ? "Y"
          : "N",
      isMedicalUrinary:
        data.MQ02.main_question.sub_question?.["MQ7-SQ7"]?.member_data
          ?.adult_one.selection_status === true
          ? "Y"
          : "N",
      urinaryWhen:
        data.MQ02.main_question.sub_question?.["MQ7-SQ7"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      GenitalQuestion:
        data.MQ02.main_question.sub_question?.["MQ8-SQ8"]?.status === true
          ? "Y"
          : "N",
      isMedicalGenital:
        data.MQ02.main_question.sub_question?.["MQ8-SQ8"]?.member_data
          ?.adult_one.selection_status === true
          ? "Y"
          : "N",
      genitalWhen:
        data.MQ02.main_question.sub_question?.["MQ8-SQ8"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      ENTQuestion:
        data.MQ02.main_question.sub_question?.["MQ9-SQ9"]?.status === true
          ? "Y"
          : "N",
      isMedicalENT:
        data.MQ02.main_question.sub_question?.["MQ9-SQ9"]?.member_data
          ?.adult_one.selection_status === true
          ? "Y"
          : "N",
      medicalEntWhen:
        data.MQ02.main_question.sub_question?.["MQ9-SQ9"]?.member_data
          ?.adult_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      InsuranceQuestion: data.MQ03.main_question.status === true ? "Y" : "N",
      isMedicalInsurance:
        data.MQ03.main_question.member_data?.adult_one.selection_status === true
          ? "Y"
          : "N",
      medicalInsuranceCheck:
        data?.MQ03?.main_question?.member_data?.adult_one?.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      InsuranceComplicationQuestion:
        data.MQ04.main_question.status === true ? "Y" : "N",
      isMedicalInsuranceComplication:
        data.MQ04.main_question.member_data?.adult_one.selection_status === true
          ? "Y"
          : "N",
      medicalInsuranceComplicationCheck:
        data?.MQ04?.main_question?.member_data?.adult_one?.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      MedicalInsCheckQuestion:
        data.MQ05.main_question.status === true ? "Y" : "N",
      isMedicalInsCheck:
        data.MQ05.main_question.member_data?.adult_one.selection_status === true
          ? "Y"
          : "N",

      medicinesQuestion: data.MQ06.main_question.status === true ? "Y" : "N",
      medicines:
        data.MQ06.main_question.member_data?.adult_one.selection_status === true
          ? "Y"
          : "N",
      prescribedIllness:
        data?.MQ06?.main_question?.member_data?.adult_one?.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,
      drugDetailsPrescribed:
        data?.MQ06?.main_question?.member_data?.adult_one?.field_data?.[
          "MQ1-SQ1-F2"
        ]?.value?.value,
      drugPeriodDetails:
        data?.MQ06?.main_question?.member_data?.adult_one?.field_data?.[
          "MQ1-SQ1-F3"
        ]?.value?.value,

      SurgeryQuestion: data.MQ07.main_question.status === true ? "Y" : "N",
      surgery:
        data.MQ07.main_question.member_data?.adult_one.selection_status === true
          ? "Y"
          : "N",
      surgeryCheck:
        data?.MQ07?.main_question?.member_data?.adult_one?.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      DisabilityQuestion: data.MQ08.main_question.status === true ? "Y" : "N",
      disability:
        data.MQ08.main_question.member_data?.adult_one.selection_status === true
          ? "Y"
          : "N",
      disabilityCheck:
        data?.MQ08?.main_question?.member_data?.adult_one?.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      TobaccoQuestion: data.MQ09.main_question.status === true ? "Y" : "N",
      tobacco:
        data.MQ09.main_question.member_data?.adult_one.selection_status === true
          ? "Y"
          : "N",
      tobaccoWhen:
        data?.MQ09?.main_question?.member_data?.adult_one?.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      SmokeQuestion: data.MQ10.main_question.status === true ? "Y" : "N",
      smoke:
        data.MQ10.main_question.member_data?.adult_one.selection_status === true
          ? "Y"
          : "N",
      smokeWhen:
        data?.MQ10?.main_question?.member_data?.adult_one?.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      AlcoholQuestion: data.MQ11.main_question.status === true ? "Y" : "N",
      alcohol:
        data.MQ11.main_question.member_data?.adult_one.selection_status === true
          ? "Y"
          : "N",
      alcoholWhen:
        data?.MQ11?.main_question?.member_data?.adult_one?.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      HivQuestion: data.MQ12.main_question.status === true ? "Y" : "N",
      hiv:
        data.MQ12.main_question.member_data?.adult_one.selection_status === true
          ? "Y"
          : "N",
      hivCheck:
        data?.MQ12?.main_question?.member_data?.adult_one?.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      spouseMedicalDiabetes:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.adult_two.selection_status === true
          ? "Y"
          : "N",
      spouseDiabetesWhen:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,
      spouseDiabetesType:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F2"]?.value?.value,
      spouseDiabetesDiagnosis:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F3"]?.value?.value,
      spouseDiabetesHBA1C:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F4"]?.value?.value,
      spouseDiabetesDiagnosed:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F5"]?.value?.value,

      spouseMedicalHypertension:
        data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.adult_two.selection_status === true
          ? "Y"
          : "N",
      spouseHypertensionWhen:
        data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,
      spouseHypertensionDiagnosis:
        data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F2"]?.value?.value,
      spouseHypertensionDiagnosed:
        data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F3"]?.value?.value,

      spouseMedicalAsthma:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.adult_two.selection_status === true
          ? "Y"
          : "N",
      spouseAsthmaWhen:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,
      spouseAsthmaSymptoms:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F2"]?.value?.value,
      spouseAsthmaDrugsUse:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F3"]?.value?.value,
      spouseAsthmaHospitalization:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F4"]?.value?.value,
      spouseAsthmaRespiratoryCardiacFailure:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F5"]?.value?.value,
      spouseAsthmaPulmonaryTest:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F6"]?.value?.value,

      spouseMedicalHyperlipidaemia:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.adult_two.selection_status === true
          ? "Y"
          : "N",
      spouseHyperlipidaemiaWhen:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,
      spouseHyperlipidaemiaOralMedicineCount:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F2"]?.value?.value,
      spouseHyperlipidaemiaCholestrolLevel:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F3"]?.value?.value,
      spouseHyperlipidaemiaDiagnosis:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F4"]?.value?.value,

      spouseMedicalBMI:
        data.MQ01.main_question.sub_question?.["MQ5-SQ5"]?.member_data
          ?.adult_two.selection_status === true
          ? "Y"
          : "N",
      spouseBmiDiagnosis:
        data.MQ01.main_question.sub_question?.["MQ5-SQ5"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      spouseMedicalStroke:
        data.MQ02.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.adult_two.selection_status === true
          ? "Y"
          : "N",
      spouseCheckStrokeDisease:
        data.MQ02.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      spouseMedicalRespiratory:
        data.MQ02.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.adult_two.selection_status === true
          ? "Y"
          : "N",
      spouseRespiratoryInfectionWhen:
        data.MQ02.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      spouseMedicalBoneInjury:
        data.MQ02.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.adult_two.selection_status === true
          ? "Y"
          : "N",
      spouseBoneInjuryWhen:
        data.MQ02.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      spouseMedicalCancer:
        data.MQ02.main_question.sub_question?.["MQ4-SQ4"]?.member_data
          ?.adult_two.selection_status === true
          ? "Y"
          : "N",
      spouseCancerWhen:
        data.MQ02.main_question.sub_question?.["MQ4-SQ4"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      spouseMedicalGynecology:
        data.MQ02.main_question.sub_question?.["MQ5-SQ5"]?.member_data
          ?.adult_two.selection_status === true
          ? "Y"
          : "N",
      spouseGynecologyWhen:
        data.MQ02.main_question.sub_question?.["MQ5-SQ5"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      spouseMedicalFertility:
        data.MQ02.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.adult_two.selection_status === true
          ? "Y"
          : "N",
      spouseFertilityWhen:
        data.MQ02.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      spouseMedicalUrinary:
        data.MQ02.main_question.sub_question?.["MQ7-SQ7"]?.member_data
          ?.adult_two.selection_status === true
          ? "Y"
          : "N",
      spouseUrinaryWhen:
        data.MQ02.main_question.sub_question?.["MQ7-SQ7"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      spouseMedicalGenital:
        data.MQ02.main_question.sub_question?.["MQ8-SQ8"]?.member_data
          ?.adult_two.selection_status === true
          ? "Y"
          : "N",
      spouseGenitalWhen:
        data.MQ02.main_question.sub_question?.["MQ8-SQ8"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      spouseMedicalENT:
        data.MQ02.main_question.sub_question?.["MQ9-SQ9"]?.member_data
          ?.adult_two.selection_status === true
          ? "Y"
          : "N",
      spouseMedicalENTWhen:
        data.MQ02.main_question.sub_question?.["MQ9-SQ9"]?.member_data
          ?.adult_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      spouseMedicalInsurance:
        data.MQ03.main_question.member_data?.adult_two.selection_status === true
          ? "Y"
          : "N",
      spouseMedicalInsuranceCheck:
        data.MQ03.main_question.member_data?.adult_two.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      spouseMedicalInsuranceComplication:
        data.MQ04.main_question.member_data?.adult_two.selection_status === true
          ? "Y"
          : "N",
      spouseMedicalInsuranceComplicationCheck:
        data.MQ04.main_question.member_data?.adult_two.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      spouseMedicalINSCheck:
        data.MQ05.main_question.member_data?.adult_two.selection_status === true
          ? "Y"
          : "N",

      spouseMedicines:
        data.MQ06.main_question.member_data?.adult_two.selection_status === true
          ? "Y"
          : "N",

      spousePrescribedIllness:
        data.MQ06.main_question.member_data?.adult_two.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      spouseDrugDetailsPrescribed:
        data.MQ06.main_question.member_data?.adult_two.field_data?.[
          "MQ1-SQ1-F2"
        ]?.value?.value,

      spouseDrugPeriodDetails:
        data.MQ06.main_question.member_data?.adult_two.field_data?.[
          "MQ1-SQ1-F3"
        ]?.value?.value,

      spouseSurgery:
        data.MQ07.main_question.member_data?.adult_two.selection_status === true
          ? "Y"
          : "N",

      spouseSurgeryCheck:
        data.MQ07.main_question.member_data?.adult_two.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      spouseDisability:
        data.MQ08.main_question.member_data?.adult_two.selection_status === true
          ? "Y"
          : "N",

      spouseDisabilityCheck:
        data.MQ08.main_question.member_data?.adult_two.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      spouseTobacco:
        data.MQ09.main_question.member_data?.adult_two.selection_status === true
          ? "Y"
          : "N",

      spouseTobaccoWhen:
        data.MQ09.main_question.member_data?.adult_two.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      spouseSmoke:
        data.MQ10.main_question.member_data?.adult_two.selection_status === true
          ? "Y"
          : "N",

      spouseSmokeWhen:
        data.MQ10.main_question.member_data?.adult_two.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      spouseAlcohol:
        data.MQ11.main_question.member_data?.adult_two.selection_status === true
          ? "Y"
          : "N",

      spouseAlcoholWhen:
        data.MQ11.main_question.member_data?.adult_two.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      spouseHiv:
        data.MQ12.main_question.member_data?.adult_two.selection_status === true
          ? "Y"
          : "N",

      spouseHivCheck:
        data.MQ12.main_question.member_data?.adult_two.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child1MedicalDiabetes:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_one.selection_status === true
          ? "Y"
          : "N",
      child1DiabetesWhen:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,
      child1DiabetesType:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F2"]?.value?.value,
      child1DiabetesDiagnosis:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F3"]?.value?.value,
      child1DiabetesHBA1C:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F4"]?.value?.value,
      child1DiabetesDiagnosed:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F5"]?.value?.value,

      child1MedicalHypertension:
        data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.child_one.selection_status === true
          ? "Y"
          : "N",
      child1HypertensionWhen:
        data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,
      child1HypertensionDiagnosis:
        data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F2"]?.value?.value,
      child1HypertensionDiagnosed:
        data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F3"]?.value?.value,

      child1MedicalAsthma:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_one.selection_status === true
          ? "Y"
          : "N",
      child1AsthmaWhen:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,
      child1AsthmaSymptoms:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F2"]?.value?.value,
      child1AsthmaDrugsUse:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F3"]?.value?.value,
      child1AsthmaHospitalization:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F4"]?.value?.value,
      child1AsthmaRespiratoryCardiacFailure:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F5"]?.value?.value,
      child1AsthmaPulmonaryTest:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F6"]?.value?.value,

      child1MedicalHyperlipidaemia:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_one.selection_status === true
          ? "Y"
          : "N",
      child1HyperlipidaemiaWhen:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,
      child1HyperlipidaemiaOralMedicineCount:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F2"]?.value?.value,
      child1HyperlipidaemiaCholestrolLevel:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F3"]?.value?.value,
      child1HyperlipidaemiaDiagnosis:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F4"]?.value?.value,

      child1MedicalBMI:
        data.MQ01.main_question.sub_question?.["MQ5-SQ5"]?.member_data
          ?.child_one.selection_status === true
          ? "Y"
          : "N",
      child1BmiDiagnosis:
        data.MQ01.main_question.sub_question?.["MQ5-SQ5"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child1MedicalStroke:
        data.MQ02.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_one.selection_status === true
          ? "Y"
          : "N",
      child1CheckStrokeDisease:
        data.MQ02.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child1MedicalRespiratory:
        data.MQ02.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.child_one.selection_status === true
          ? "Y"
          : "N",
      child1RespiratoryInfectionWhen:
        data.MQ02.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child1MedicalBoneInjury:
        data.MQ02.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_one.selection_status === true
          ? "Y"
          : "N",
      child1BoneInjuryWhen:
        data.MQ02.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child1MedicalCancer:
        data.MQ02.main_question.sub_question?.["MQ4-SQ4"]?.member_data
          ?.child_one.selection_status === true
          ? "Y"
          : "N",
      child1CancerWhen:
        data.MQ02.main_question.sub_question?.["MQ4-SQ4"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child1MedicalGynecology:
        data.MQ02.main_question.sub_question?.["MQ5-SQ5"]?.member_data
          ?.child_one.selection_status === true
          ? "Y"
          : "N",
      child1GynecologyWhen:
        data.MQ02.main_question.sub_question?.["MQ5-SQ5"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child1MedicalFertility:
        data.MQ02.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_one.selection_status === true
          ? "Y"
          : "N",
      child1FertilityWhen:
        data.MQ02.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child1MedicalUrinary:
        data.MQ02.main_question.sub_question?.["MQ7-SQ7"]?.member_data
          ?.child_one.selection_status === true
          ? "Y"
          : "N",
      child1UrinaryWhen:
        data.MQ02.main_question.sub_question?.["MQ7-SQ7"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child1MedicalGenital:
        data.MQ02.main_question.sub_question?.["MQ8-SQ8"]?.member_data
          ?.child_one.selection_status === true
          ? "Y"
          : "N",
      child1GenitalWhen:
        data.MQ02.main_question.sub_question?.["MQ8-SQ8"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child1MedicalENT:
        data.MQ02.main_question.sub_question?.["MQ9-SQ9"]?.member_data
          ?.child_one.selection_status === true
          ? "Y"
          : "N",
      child1MedicalENTWhen:
        data.MQ02.main_question.sub_question?.["MQ9-SQ9"]?.member_data
          ?.child_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child1MedicalInsurance:
        data.MQ03.main_question.member_data?.child_one.selection_status === true
          ? "Y"
          : "N",
      child1MedicalInsuranceCheck:
        data.MQ03.main_question.member_data?.child_one.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child1MedicalInsuranceComplication:
        data.MQ04.main_question.member_data?.child_one.selection_status === true
          ? "Y"
          : "N",
      child1MedicalInsuranceComplicationCheck:
        data.MQ04.main_question.member_data?.child_one.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child1MedicalINSCheck:
        data.MQ05.main_question.member_data?.child_one.selection_status === true
          ? "Y"
          : "N",

      child1Medicines:
        data.MQ06.main_question.member_data?.child_one.selection_status === true
          ? "Y"
          : "N",

      child1PrescribedIllness:
        data.MQ06.main_question.member_data?.child_one.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child1DrugDetailsPrescribed:
        data.MQ06.main_question.member_data?.child_one.field_data?.[
          "MQ1-SQ1-F2"
        ]?.value?.value,

      child1DrugPeriodDetails:
        data.MQ06.main_question.member_data?.child_one.field_data?.[
          "MQ1-SQ1-F3"
        ]?.value?.value,

      child1Surgery:
        data.MQ07.main_question.member_data?.child_one.selection_status === true
          ? "Y"
          : "N",

      child1SurgeryCheck:
        data.MQ07.main_question.member_data?.child_one.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child1Disability:
        data.MQ08.main_question.member_data?.child_one.selection_status === true
          ? "Y"
          : "N",

      child1DisabilityCheck:
        data.MQ08.main_question.member_data?.child_one.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child1Tobacco:
        data.MQ09.main_question.member_data?.child_one.selection_status === true
          ? "Y"
          : "N",

      child1TobaccoWhen:
        data.MQ09.main_question.member_data?.child_one.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child1Smoke:
        data.MQ10.main_question.member_data?.child_one.selection_status === true
          ? "Y"
          : "N",
      child1SmokeWhen:
        data.MQ10.main_question.member_data?.child_one.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child1Alcohol:
        data.MQ11.main_question.member_data?.child_one.selection_status === true
          ? "Y"
          : "N",

      child1AlcoholWhen:
        data.MQ11.main_question.member_data?.child_one.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child1Hiv:
        data.MQ12.main_question.member_data?.child_one.selection_status === true
          ? "Y"
          : "N",
      child1HivCheck:
        data.MQ12.main_question.member_data?.adult_two.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child2MedicalDiabetes:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_two.selection_status === true
          ? "Y"
          : "N",
      child2DiabetesWhen:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,
      child2DiabetesType:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F2"]?.value?.value,
      child2DiabetesDiagnosis:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F3"]?.value?.value,
      child2DiabetesHBA1C:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F4"]?.value?.value,
      child2DiabetesDiagnosed:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F5"]?.value?.value,

      child2MedicalHypertension:
        data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.child_two.selection_status === true
          ? "Y"
          : "N",
      child2HypertensionWhen:
        data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,
      child2HypertensionDiagnosis:
        data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F2"]?.value?.value,
      child2HypertensionDiagnosed:
        data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F3"]?.value?.value,

      child2MedicalAsthma:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_two.selection_status === true
          ? "Y"
          : "N",
      child2AsthmaWhen:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,
      child2AsthmaSymptoms:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F2"]?.value?.value,
      child2AsthmaDrugsUse:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F3"]?.value?.value,
      child2AsthmaHospitalization:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F4"]?.value?.value,
      child2AsthmaRespiratoryCardiacFailure:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F5"]?.value?.value,
      child2AsthmaPulmonaryTest:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F6"]?.value?.value,

      child2MedicalHyperlipidaemia:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_two.selection_status === true
          ? "Y"
          : "N",
      child2HyperlipidaemiaWhen:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,
      child2HyperlipidaemiaOralMedicineCount:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F2"]?.value?.value,
      child2HyperlipidaemiaCholestrolLevel:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F3"]?.value?.value,
      child2HyperlipidaemiaDiagnosis:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F4"]?.value?.value,

      child2MedicalBMI:
        data.MQ01.main_question.sub_question?.["MQ5-SQ5"]?.member_data
          ?.child_two.selection_status === true
          ? "Y"
          : "N",
      child2BmiDiagnosis:
        data.MQ01.main_question.sub_question?.["MQ5-SQ5"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child2MedicalStroke:
        data.MQ02.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_two.selection_status === true
          ? "Y"
          : "N",
      child2CheckStrokeDisease:
        data.MQ02.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child2MedicalRespiratory:
        data.MQ02.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.child_two.selection_status === true
          ? "Y"
          : "N",
      child2RespiratoryInfectionWhen:
        data.MQ02.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child2MedicalBoneInjury:
        data.MQ02.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_two.selection_status === true
          ? "Y"
          : "N",
      child2BoneInjuryWhen:
        data.MQ02.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child2MedicalCancer:
        data.MQ02.main_question.sub_question?.["MQ4-SQ4"]?.member_data
          ?.child_two.selection_status === true
          ? "Y"
          : "N",
      child2CancerWhen:
        data.MQ02.main_question.sub_question?.["MQ4-SQ4"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child2MedicalGynecology:
        data.MQ02.main_question.sub_question?.["MQ5-SQ5"]?.member_data
          ?.child_two.selection_status === true
          ? "Y"
          : "N",
      child2GynecologyWhen:
        data.MQ02.main_question.sub_question?.["MQ5-SQ5"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child2MedicalFertility:
        data.MQ02.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_two.selection_status === true
          ? "Y"
          : "N",
      child2FertilityWhen:
        data.MQ02.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child2MedicalUrinary:
        data.MQ02.main_question.sub_question?.["MQ7-SQ7"]?.member_data
          ?.child_two.selection_status === true
          ? "Y"
          : "N",
      child2UrinaryWhen:
        data.MQ02.main_question.sub_question?.["MQ7-SQ7"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child2MedicalGenital:
        data.MQ02.main_question.sub_question?.["MQ8-SQ8"]?.member_data
          ?.child_two.selection_status === true
          ? "Y"
          : "N",
      child2GenitalWhen:
        data.MQ02.main_question.sub_question?.["MQ8-SQ8"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child2MedicalENT:
        data.MQ02.main_question.sub_question?.["MQ9-SQ9"]?.member_data
          ?.child_two.selection_status === true
          ? "Y"
          : "N",
      child2MedicalENTWhen:
        data.MQ02.main_question.sub_question?.["MQ9-SQ9"]?.member_data
          ?.child_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child2MedicalInsurance:
        data.MQ03.main_question.member_data?.child_two.selection_status === true
          ? "Y"
          : "N",
      child2MedicalInsuranceCheck:
        data.MQ03.main_question.member_data?.child_two.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child2MedicalInsuranceComplication:
        data.MQ04.main_question.member_data?.child_two.selection_status === true
          ? "Y"
          : "N",
      child2MedicalInsuranceComplicationCheck:
        data.MQ04.main_question.member_data?.child_two.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child2MedicalINSCheck:
        data.MQ05.main_question.member_data?.child_two.selection_status === true
          ? "Y"
          : "N",

      child2Medicines:
        data.MQ06.main_question.member_data?.child_two.selection_status === true
          ? "Y"
          : "N",

      child2PrescribedIllness:
        data.MQ06.main_question.member_data?.child_two.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child2DrugDetailsPrescribed:
        data.MQ06.main_question.member_data?.child_two.field_data?.[
          "MQ1-SQ1-F2"
        ]?.value?.value,

      child2DrugPeriodDetails:
        data.MQ06.main_question.member_data?.child_two.field_data?.[
          "MQ1-SQ1-F3"
        ]?.value?.value,

      child2Surgery:
        data.MQ07.main_question.member_data?.child_two.selection_status === true
          ? "Y"
          : "N",

      child2SurgeryCheck:
        data.MQ07.main_question.member_data?.child_two.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child2Disability:
        data.MQ08.main_question.member_data?.child_two.selection_status === true
          ? "Y"
          : "N",

      child2DisabilityCheck:
        data.MQ08.main_question.member_data?.child_two.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child2Tobacco:
        data.MQ09.main_question.member_data?.child_two.selection_status === true
          ? "Y"
          : "N",

      child2TobaccoWhen:
        data.MQ09.main_question.member_data?.child_two.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child2Smoke:
        data.MQ10.main_question.member_data?.child_two.selection_status === true
          ? "Y"
          : "N",

      child2SmokeWhen:
        data.MQ10.main_question.member_data?.child_two.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child2Alcohol:
        data.MQ11.main_question.member_data?.child_two.selection_status === true
          ? "Y"
          : "N",

      child2AlcoholWhen:
        data.MQ11.main_question.member_data?.child_two.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child2Hiv:
        data.MQ12.main_question.member_data?.child_two.selection_status === true
          ? "Y"
          : "N",

      child2HivCheck:
        data.MQ12.main_question.member_data?.child_two.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child3MedicalDiabetes:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_three.selection_status === true
          ? "Y"
          : "N",
      child3DiabetesWhen:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F1"]?.value?.value,
      child3DiabetesType:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F2"]?.value?.value,
      child3DiabetesDiagnosis:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F3"]?.value?.value,
      child3DiabetesHBA1C:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F4"]?.value?.value,
      child3DiabetesDiagnosed:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F5"]?.value?.value,

      child3MedicalHypertension:
        data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.child_three.selection_status === true
          ? "Y"
          : "N",
      child3HypertensionWhen:
        data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F1"]?.value?.value,
      child3HypertensionDiagnosis:
        data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F2"]?.value?.value,
      child3HypertensionDiagnosed:
        data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F3"]?.value?.value,

      child3MedicalAsthma:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_three.selection_status === true
          ? "Y"
          : "N",
      child3AsthmaWhen:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F1"]?.value?.value,
      child3AsthmaSymptoms:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F2"]?.value?.value,
      child3AsthmaDrugsUse:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F3"]?.value?.value,
      child3AsthmaHospitalization:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F4"]?.value?.value,
      child3AsthmaRespiratoryCardiacFailure:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F5"]?.value?.value,
      child3AsthmaPulmonaryTest:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F6"]?.value?.value,

      child3MedicalHyperlipidaemia:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_three.selection_status === true
          ? "Y"
          : "N",
      child3HyperlipidaemiaWhen:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F1"]?.value?.value,
      child3HyperlipidaemiaOralMedicineCount:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F2"]?.value?.value,
      child3HyperlipidaemiaCholestrolLevel:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F3"]?.value?.value,
      child3HyperlipidaemiaDiagnosis:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F4"]?.value?.value,

      child3MedicalBMI:
        data.MQ01.main_question.sub_question?.["MQ5-SQ5"]?.member_data
          ?.child_three.selection_status === true
          ? "Y"
          : "N",
      child3BmiDiagnosis:
        data.MQ01.main_question.sub_question?.["MQ5-SQ5"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child3MedicalStroke:
        data.MQ02.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_three.selection_status === true
          ? "Y"
          : "N",
      child3CheckStrokeDisease:
        data.MQ02.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child3MedicalRespiratory:
        data.MQ02.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.child_three.selection_status === true
          ? "Y"
          : "N",
      child3RespiratoryInfectionWhen:
        data.MQ02.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child3MedicalBoneInjury:
        data.MQ02.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_three.selection_status === true
          ? "Y"
          : "N",
      child3BoneInjuryWhen:
        data.MQ02.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child3MedicalCancer:
        data.MQ02.main_question.sub_question?.["MQ4-SQ4"]?.member_data
          ?.child_three.selection_status === true
          ? "Y"
          : "N",
      child3CancerWhen:
        data.MQ02.main_question.sub_question?.["MQ4-SQ4"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child3MedicalGynecology:
        data.MQ02.main_question.sub_question?.["MQ5-SQ5"]?.member_data
          ?.child_three.selection_status === true
          ? "Y"
          : "N",
      child3GynecologyWhen:
        data.MQ02.main_question.sub_question?.["MQ5-SQ5"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child3MedicalFertility:
        data.MQ02.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_three.selection_status === true
          ? "Y"
          : "N",
      child3FertilityWhen:
        data.MQ02.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child3MedicalUrinary:
        data.MQ02.main_question.sub_question?.["MQ7-SQ7"]?.member_data
          ?.child_three.selection_status === true
          ? "Y"
          : "N",
      child3UrinaryWhen:
        data.MQ02.main_question.sub_question?.["MQ7-SQ7"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child3MedicalGenital:
        data.MQ02.main_question.sub_question?.["MQ8-SQ8"]?.member_data
          ?.child_three.selection_status === true
          ? "Y"
          : "N",
      child3GenitalWhen:
        data.MQ02.main_question.sub_question?.["MQ8-SQ8"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child3MedicalENT:
        data.MQ02.main_question.sub_question?.["MQ9-SQ9"]?.member_data
          ?.child_three.selection_status === true
          ? "Y"
          : "N",
      child3MedicalENTWhen:
        data.MQ02.main_question.sub_question?.["MQ9-SQ9"]?.member_data
          ?.child_three.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child3MedicalInsurance:
        data.MQ03.main_question.member_data?.child_three.selection_status ===
        true
          ? "Y"
          : "N",
      child3MedicalInsuranceCheck:
        data.MQ03.main_question.member_data?.child_three.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child3MedicalInsuranceComplication:
        data.MQ04.main_question.member_data?.child_three.selection_status ===
        true
          ? "Y"
          : "N",
      child3MedicalInsuranceComplicationCheck:
        data.MQ04.main_question.member_data?.child_three.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child3MedicalINSCheck:
        data.MQ05.main_question.member_data?.child_three.selection_status ===
        true
          ? "Y"
          : "N",

      child3Medicines:
        data.MQ06.main_question.member_data?.child_three.selection_status ===
        true
          ? "Y"
          : "N",

      child3PrescribedIllness:
        data.MQ06.main_question.member_data?.child_three.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child3DrugDetailsPrescribed:
        data.MQ06.main_question.member_data?.child_three.field_data?.[
          "MQ1-SQ1-F2"
        ]?.value?.value,

      child3DrugPeriodDetails:
        data.MQ06.main_question.member_data?.child_three.field_data?.[
          "MQ1-SQ1-F3"
        ]?.value?.value,

      child3Surgery:
        data.MQ07.main_question.member_data?.child_three.selection_status ===
        true
          ? "Y"
          : "N",

      child3SurgeryCheck:
        data.MQ07.main_question.member_data?.child_three.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child3Disability:
        data.MQ08.main_question.member_data?.child_three.selection_status ===
        true
          ? "Y"
          : "N",

      child3DisabilityCheck:
        data.MQ08.main_question.member_data?.child_three.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child3Tobacco:
        data.MQ09.main_question.member_data?.child_three.selection_status ===
        true
          ? "Y"
          : "N",

      child3TobaccoWhen:
        data.MQ09.main_question.member_data?.child_three.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child3Smoke:
        data.MQ10.main_question.member_data?.child_three.selection_status ===
        true
          ? "Y"
          : "N",

      child3SmokeWhen:
        data.MQ10.main_question.member_data?.child_three.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child3Alcohol:
        data.MQ11.main_question.member_data?.child_three.selection_status ===
        true
          ? "Y"
          : "N",

      child3AlcoholWhen:
        data.MQ11.main_question.member_data?.child_three.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child3Hiv:
        data.MQ12.main_question.member_data?.child_three.selection_status ===
        true
          ? "Y"
          : "N",

      child3HivCheck:
        data.MQ12.main_question.member_data?.child_three.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child4MedicalDiabetes:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_four.selection_status === true
          ? "Y"
          : "N",
      child4DiabetesWhen:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F1"]?.value?.value,
      child4DiabetesType:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F2"]?.value?.value,
      child4DiabetesDiagnosis:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F3"]?.value?.value,
      child4DiabetesHBA1C:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F4"]?.value?.value,
      child4DiabetesDiagnosed:
        data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F5"]?.value?.value,

      child4MedicalHypertension:
        data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.child_four.selection_status === true
          ? "Y"
          : "N",
      child4HypertensionWhen:
        data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F1"]?.value?.value,
      child4HypertensionDiagnosis:
        data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F2"]?.value?.value,
      child4HypertensionDiagnosed:
        data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F3"]?.value?.value,

      child4MedicalAsthma:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_four.selection_status === true
          ? "Y"
          : "N",
      child4AsthmaWhen:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F1"]?.value?.value,
      child4AsthmaSymptoms:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F2"]?.value?.value,
      child4AsthmaDrugsUse:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F3"]?.value?.value,
      child4AsthmaHospitalization:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F4"]?.value?.value,
      child4AsthmaRespiratoryCardiacFailure:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F5"]?.value?.value,
      child4AsthmaPulmonaryTest:
        data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F6"]?.value?.value,

      child4MedicalHyperlipidaemia:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_four.selection_status === true
          ? "Y"
          : "N",
      child4HyperlipidaemiaWhen:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F1"]?.value?.value,
      child4HyperlipidaemiaOralMedicineCount:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F2"]?.value?.value,
      child4HyperlipidaemiaCholestrolLevel:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F3"]?.value?.value,
      child4HyperlipidaemiaDiagnosis:
        data.MQ01.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F4"]?.value?.value,

      child4MedicalBMI:
        data.MQ01.main_question.sub_question?.["MQ5-SQ5"]?.member_data
          ?.child_four.selection_status === true
          ? "Y"
          : "N",
      child4BmiDiagnosis:
        data.MQ01.main_question.sub_question?.["MQ5-SQ5"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child4MedicalStroke:
        data.MQ02.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_four.selection_status === true
          ? "Y"
          : "N",
      child4CheckStrokeDisease:
        data.MQ02.main_question.sub_question?.["MQ1-SQ1"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child4MedicalRespiratory:
        data.MQ02.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.child_four.selection_status === true
          ? "Y"
          : "N",
      child4RespiratoryInfectionWhen:
        data.MQ02.main_question.sub_question?.["MQ2-SQ2"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child4MedicalBoneInjury:
        data.MQ02.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_four.selection_status === true
          ? "Y"
          : "N",
      child4BoneInjuryWhen:
        data.MQ02.main_question.sub_question?.["MQ3-SQ3"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child4MedicalCancer:
        data.MQ02.main_question.sub_question?.["MQ4-SQ4"]?.member_data
          ?.child_four.selection_status === true
          ? "Y"
          : "N",
      child4CancerWhen:
        data.MQ02.main_question.sub_question?.["MQ4-SQ4"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child4MedicalGynecology:
        data.MQ02.main_question.sub_question?.["MQ5-SQ5"]?.member_data
          ?.child_four.selection_status === true
          ? "Y"
          : "N",
      child4GynecologyWhen:
        data.MQ02.main_question.sub_question?.["MQ5-SQ5"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child4MedicalFertility:
        data.MQ02.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_four.selection_status === true
          ? "Y"
          : "N",
      child4FertilityWhen:
        data.MQ02.main_question.sub_question?.["MQ6-SQ6"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child4MedicalUrinary:
        data.MQ02.main_question.sub_question?.["MQ7-SQ7"]?.member_data
          ?.child_four.selection_status === true
          ? "Y"
          : "N",
      child4UrinaryWhen:
        data.MQ02.main_question.sub_question?.["MQ7-SQ7"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child4MedicalGenital:
        data.MQ02.main_question.sub_question?.["MQ8-SQ8"]?.member_data
          ?.child_four.selection_status === true
          ? "Y"
          : "N",
      child4GenitalWhen:
        data.MQ02.main_question.sub_question?.["MQ8-SQ8"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child4MedicalENT:
        data.MQ02.main_question.sub_question?.["MQ9-SQ9"]?.member_data
          ?.child_four.selection_status === true
          ? "Y"
          : "N",
      child4MedicalENTWhen:
        data.MQ02.main_question.sub_question?.["MQ9-SQ9"]?.member_data
          ?.child_four.field_data?.["MQ1-SQ1-F1"]?.value?.value,

      child4MedicalInsurance:
        data.MQ03.main_question.member_data?.child_four.selection_status ===
        true
          ? "Y"
          : "N",
      child4MedicalInsuranceCheck:
        data.MQ03.main_question.member_data?.child_four.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child4MedicalInsuranceComplication:
        data.MQ04.main_question.member_data?.child_four.selection_status ===
        true
          ? "Y"
          : "N",
      child4MedicalInsuranceComplicationCheck:
        data.MQ04.main_question.member_data?.child_four.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child4MedicalINSCheck:
        data.MQ05.main_question.member_data?.child_four.selection_status ===
        true
          ? "Y"
          : "N",

      child4Medicines:
        data.MQ06.main_question.member_data?.child_four.selection_status ===
        true
          ? "Y"
          : "N",

      child4PrescribedIllness:
        data.MQ06.main_question.member_data?.child_four.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child4DrugDetailsPrescribed:
        data.MQ06.main_question.member_data?.child_four.field_data?.[
          "MQ1-SQ1-F2"
        ]?.value?.value,

      child4DrugPeriodDetails:
        data.MQ06.main_question.member_data?.child_four.field_data?.[
          "MQ1-SQ1-F3"
        ]?.value?.value,

      child4Surgery:
        data.MQ07.main_question.member_data?.child_four.selection_status ===
        true
          ? "Y"
          : "N",

      child4SurgeryCheck:
        data.MQ07.main_question.member_data?.child_four.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child4Disability:
        data.MQ08.main_question.member_data?.child_four.selection_status ===
        true
          ? "Y"
          : "N",

      child4DisabilityCheck:
        data.MQ08.main_question.member_data?.child_four.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child4Tobacco:
        data.MQ09.main_question.member_data?.child_four.selection_status ===
        true
          ? "Y"
          : "N",

      child4TobaccoWhen:
        data.MQ09.main_question.member_data?.child_four.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child4Smoke:
        data.MQ10.main_question.member_data?.child_four.selection_status ===
        true
          ? "Y"
          : "N",

      child4SmokeWhen:
        data.MQ10.main_question.member_data?.child_four.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child4Alcohol:
        data.MQ11.main_question.member_data?.child_four.selection_status ===
        true
          ? "Y"
          : "N",

      child4AlcoholWhen:
        data.MQ11.main_question.member_data?.child_four.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,

      child4Hiv:
        data.MQ12.main_question.member_data?.child_four.selection_status ===
        true
          ? "Y"
          : "N",

      child4HivCheck:
        data.MQ12.main_question.member_data?.child_four.field_data?.[
          "MQ1-SQ1-F1"
        ]?.value?.value,
    };
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const cus_type = params.get("cus_type");

    HEALTH_PROPOSAL_SERVICES.getMedicalData(onSuccess, onError, param);
    navigate(
      `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
    );
  };
  return (
    <>
      {isMobile ? null : (
        <MedicalDetailsSuperStar
          medicalQuestionData={medicalQuestionData}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          PAGE_STATUS={PAGE_STATUS}
        />
      )}
    </>
  );
};

export default MedicalDetailsContainerSuperStar;
