import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  isEmpty,
  validateAadharNumber,
  validateEmail,
  validateFullName,
  validateMobileNumber,
  validatePAN,
} from "../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../../types/Common/TDropdown";
import { TDropdownData } from "../../../../types/DropdownData/DropdownData";
import AdultOneDetails from "../../../../Page/Desktop/Travel/CARE/AdultOneDetails/AdultOneDetails";
import { TRAVEL_PROPOSAL_SERVICES } from "../../../../Services/Travel/TravelPropsalServices";
import { TravelSlice } from "../../../../redux/slice/Travel/TravelSlice";
import {
  TAdultOneDetails,
  TTravelNomineeDetails,
} from "../../../../types/Travel/TTravelSlice";
import { TRAVEL_ROUTE } from "../../../../Router/Path/TravelRoutes";

export default function AdultOneDetailsContainer() {
  const isMobile = useIsMobile();
  const {
    ADD_FORM,
    INSURED_MEMBER_DETAILS,
    SELECTED_QUOTE_DATA,
    ADD_FORM_RESPONSE,
    PAGE_STATUS,
    NOMINEE_RELATIONSHIP_STATUS,
  } = useAppSelector((state) => state.Travel);
  const { Travel } = useAppSelector((state) => state);
  const { CKYC } = useAppSelector((state) => state);
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  const navigate = useNavigate();
  const [proposerFields, setProposerFields] = useState<TAdultOneDetails>({
    ...INSURED_MEMBER_DETAILS.PROPOSER_DETAILS,
  });
  const [adultOneFields, setAdultOneFields] = useState<TAdultOneDetails>({
    ...INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS,
  });

  useEffect(() => {
    const prop_data = { ...INSURED_MEMBER_DETAILS.PROPOSER_DETAILS };
    setProposerFields({
      ...INSURED_MEMBER_DETAILS.PROPOSER_DETAILS,
      dob: {
        value: isEmpty(prop_data.dob.value)
          ? CKYC.CKYC_DETAILS.kycDOB
          : prop_data.dob.value,
        warning: false,
      },
      name: {
        value: isEmpty(prop_data.name.value)
          ? CKYC.CKYC_DETAILS.kycCustomerName
          : prop_data.name.value,
        warning: false,
      },
      mobile: {
        value: isEmpty(prop_data.mobile.value)
          ? CKYC.CKYC_DETAILS.kycMobile
          : prop_data.mobile.value,
        warning: false,
      },
      email: {
        value: isEmpty(prop_data.email.value)
          ? CKYC.CKYC_DETAILS.kycEmail
          : prop_data.email.value,
        warning: false,
      },
      aadhar: {
        value: isEmpty(prop_data.aadhar.value)
          ? CKYC.CKYC_DETAILS.kycAadhaar
          : prop_data.aadhar.value,
        warning: false,
      },
      pan_card: {
        value: isEmpty(prop_data.pan_card.value)
          ? CKYC.CKYC_DETAILS.kycPAN
          : prop_data.pan_card.value,
        warning: false,
      },
    });
    setAdultOneFields({
      ...INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS,
      dob: {
        value: isEmpty(prop_data.dob.value)
          ? CKYC.CKYC_DETAILS.kycDOB
          : prop_data.dob.value,
        warning: false,
      },
      name: {
        value: isEmpty(prop_data.name.value)
          ? CKYC.CKYC_DETAILS.kycCustomerName
          : prop_data.name.value,
        warning: false,
      },
      mobile: {
        value: isEmpty(prop_data.mobile.value)
          ? CKYC.CKYC_DETAILS.kycMobile
          : prop_data.mobile.value,
        warning: false,
      },
      email: {
        value: isEmpty(prop_data.email.value)
          ? CKYC.CKYC_DETAILS.kycEmail
          : prop_data.email.value,
        warning: false,
      },
      aadhar: {
        value: isEmpty(prop_data.aadhar.value)
          ? CKYC.CKYC_DETAILS.kycAadhaar
          : prop_data.aadhar.value,
        warning: false,
      },
      pan_card: {
        value: isEmpty(prop_data.pan_card.value)
          ? CKYC.CKYC_DETAILS.kycPAN
          : prop_data.pan_card.value,
        warning: false,
      },
    });
  }, [
    CKYC.CKYC_DETAILS,
    proposerFields.IS_PROPOSER_INSURED,
    INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
  ]);

  const [nomineeDetails, setNomineeDetails] = useState<TTravelNomineeDetails>({
    ...INSURED_MEMBER_DETAILS.NOMINEE__DETAILS,
  });
  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState<boolean>(false);
  const [kycOpen, setKycOpen] = useState<boolean>(false);
  const [kycDetail, setKycDetail] = useState(false);
  const [toggle_status, setToggle_status] = useState(false);
  const [disbaledButton, setDisbaledButton] = useState(false);
  const { CKYC_DETAILS } = useAppSelector((state) => state.CKYC);
  const [maritalStatusList, setMaritalStatusList] = useState<TDropdownData[]>([
    {
      label: "Single",
      value: "Single",
    },
    {
      label: "Married",
      value: "Married",
    },
    {
      label: "Divorce",
      value: "Divorce",
    },
  ]);
  const [nomineeRealtionshipList, setNomineeRealtionshipList] = useState<
    TDropdownData[]
  >([]);

  const handleClick = (new_value: any) => {
    setProposerFields({
      ...INSURED_MEMBER_DETAILS.PROPOSER_DETAILS,
      IS_PROPOSER_INSURED: new_value,
    });
    dispatch(
      TravelSlice.actions.BULK_UPLOAD({
        ...Travel,
        INSURED_MEMBER_DETAILS: {
          ...INSURED_MEMBER_DETAILS,
          PROPOSER_DETAILS: {
            ...INSURED_MEMBER_DETAILS.PROPOSER_DETAILS,
            IS_PROPOSER_INSURED: new_value,
          },
        },
      })
    );
  };

  const fieldsUpdateState = (attrName: any, value: any) => {
    if (attrName[0] === "nomineeDetails") {
      setNomineeDetails((prev) => ({
        ...prev,
        [attrName[1]]: {
          value: value,
          warning:
            attrName === "full_name"
              ? !validateFullName(value)
              : isEmpty(value),
        },
      }));
    } else if (attrName[1] === "adultOneDetails") {
      setAdultOneFields((prev) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning:
            attrName[0] === "pan_card"
              ? !validatePAN(value)
              : attrName[0] === "annual_income"
              ? isEmpty(value)
              : attrName[0] === "email"
              ? !validateEmail(value)
              : attrName[0] === "mobile" || attrName[0] === "emergencyPhone"
              ? !validateMobileNumber(value)
              : attrName[0] === "aadhar"
              ? !validateAadharNumber(value)
              : attrName[0] === "gender"
              ? isEmpty(value)
              : attrName[0] === "member_income"
              ? isEmpty(value)
              : attrName[0] === "qualification"
              ? isEmpty(value)
              : attrName[0] === "maritalStatus"
              ? isEmpty(value)
              : isEmpty(value),
        },
      }));
    } else if (attrName === "toggle_switch") {
      setToggle_status(value);
    } else {
      setProposerFields((prev) => ({
        ...prev,
        [attrName]: {
          value: value,
          warning:
            attrName[0] === "pan_card"
              ? !validatePAN(value)
              : attrName === "email"
              ? !validateEmail(value)
              : attrName === "mobile"
              ? !validateMobileNumber(value)
              : attrName[0] === "annual_income"
              ? isEmpty(value)
              : attrName === "gender"
              ? isEmpty(value)
              : isEmpty(value),
        },
      }));
    }
  };

  const UPDATE_ADULT_ONE_NOMINEE_DATA = (
    PROPOSER_DATA: TAdultOneDetails,
    NOMINEE_DETAILS: TTravelNomineeDetails
  ) => {
    const onSuccess = (res: any) => {
      console.log("res...", res.error);
      if (!res.error) {
        const windowURL = window.location.search;
        const params = new URLSearchParams(windowURL);
        const cus_type = params.get("cus_type");

        if (PAGE_STATUS) {
          navigate(-1);
        } else {
          if (!isEmpty(ADD_FORM.memberDetails.adult2_age.value)) {
            navigate(
              `${TRAVEL_ROUTE.PROPOSAL_PAGE}/${
                TRAVEL_ROUTE.ADULT_TWO_DETAILS
              }?type=TI&companyCode=${
                SELECTED_QUOTE_DATA?.companyCode
              }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
                SELECTED_QUOTE_DATA?.productID
              }${cus_type === "YWdlbnQ=" ? "&user_type=YWdlbnQ=" : ""}`
            );
          } else {
            navigate(
              `${TRAVEL_ROUTE.PROPOSAL_PAGE}/${
                TRAVEL_ROUTE.ADDRESS_DETAILS
              }?type=TI&companyCode=${
                SELECTED_QUOTE_DATA?.companyCode
              }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
                SELECTED_QUOTE_DATA?.productID
              }${cus_type === "YWdlbnQ=" ? "&user_type=YWdlbnQ=" : ""}`
            );
          }
        }
      }
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      proposerRelationship: "Yes",
      proposerRelationWithInsured: "Yes",
      proposerName: PROPOSER_DATA.name.value,
      proposerDOB: PROPOSER_DATA.dob.value,
      proposerGender: PROPOSER_DATA.gender.value,
      proposerMobile: ADD_FORM.travelMobile.value,
      proposerEmail: PROPOSER_DATA.email.value,
      aadhar_number: PROPOSER_DATA.aadhar.value,
      proposerPanNo: PROPOSER_DATA.pan_card.value,
      adult1Relationship: "self",
      insuredName: PROPOSER_DATA.name.value,
      insuredDOB: PROPOSER_DATA.dob.value,
      passportNumber: PROPOSER_DATA.passportNumber.value,
      weight: PROPOSER_DATA.weight.value,
      heightFeet: PROPOSER_DATA.heightFeet.value,
      heightInches: PROPOSER_DATA.heightInches.value,
      nomineeName: NOMINEE_DETAILS.full_name.value,
      nomineeDOB: NOMINEE_DETAILS.dob.value,
      nomineeRelationShip: NOMINEE_DETAILS.relationship.value,
      section: "proposer_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      cover: ADD_FORM.members.value,
      finalPremium: SELECTED_QUOTE_DATA.quote_details?.premiumDetails.premium,
      saveAllDetails: "Yes",
    };
    TRAVEL_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  const validateForm = () => {
    let proposer_data = { ...proposerFields };
    let adult_one_data = { ...adultOneFields };
    let nominee_data = { ...nomineeDetails };

    if (proposer_data.IS_PROPOSER_INSURED) {
      adult_one_data = {
        ...adult_one_data,
        name: {
          ...adult_one_data.name,
          warning: isEmpty(`${adult_one_data.name.value}`),
        },
        dob: {
          ...adult_one_data.dob,
          warning: isEmpty(`${adult_one_data.dob.value} `),
        },
        gender: {
          ...adult_one_data.gender,
          warning: isEmpty(`${adult_one_data.gender.value} `),
        },
        email: {
          ...adult_one_data.email,
          warning: !validateEmail(`${adult_one_data.email.value} `),
        },
        aadhar: {
          ...adult_one_data.aadhar,
          warning: isEmpty(adult_one_data.aadhar.value)
            ? false
            : !validateAadharNumber(`${adult_one_data.aadhar.value} `),
        },
        emergencyPhone: {
          ...adult_one_data.emergencyPhone,
          warning: isEmpty(adult_one_data.emergencyPhone.value)
            ? false
            : !validateMobileNumber(`${adult_one_data.emergencyPhone.value} `),
        },
        weight: {
          ...adult_one_data.weight,
          warning: isEmpty(`${adult_one_data.weight.value} `),
        },
        heightFeet: {
          ...adult_one_data.heightFeet,
          warning: isEmpty(`${adult_one_data.heightFeet.value} `),
        },
        heightInches: {
          ...adult_one_data.heightInches,
          warning: isEmpty(`${adult_one_data.heightInches.value} `),
        },
        maritalStatus: {
          ...adult_one_data.maritalStatus,
          warning: false,
        },
        pan_card: {
          ...adult_one_data.pan_card,
          warning: !validatePAN(adult_one_data.pan_card.value),
        },
        qualification: {
          ...adult_one_data.qualification,
          warning: isEmpty(`${adult_one_data.qualification.value} `),
        },
      };
      if (NOMINEE_RELATIONSHIP_STATUS === "OTHER") {
        nominee_data = {
          ...nominee_data,
          full_name: {
            ...nominee_data.full_name,
            warning: !validateFullName(`${nominee_data.full_name.value} `),
          },
          dob: {
            ...nominee_data.dob,
            warning: isEmpty(`${nominee_data.dob.value} `),
          },
          relationship: {
            ...nominee_data.relationship,
            warning: isEmpty(`${nominee_data.relationship.value} `),
          },
        };
      }

      proposer_data = {
        ...adult_one_data,
        IS_PROPOSER_INSURED: proposer_data.IS_PROPOSER_INSURED,
      };
    } else {
      proposer_data = {
        ...proposer_data,
        name: {
          ...proposer_data.name,
          warning: isEmpty(`${proposer_data.name.value} `),
        },
        dob: {
          ...proposer_data.dob,
          warning: isEmpty(`${proposer_data.dob.value} `),
        },
        gender: {
          ...proposer_data.gender,
          warning: isEmpty(`${proposer_data.gender.value} `),
        },
        email: {
          ...proposer_data.email,
          warning: !validateEmail(`${proposer_data.email.value} `),
        },
        aadhar: {
          ...proposer_data.aadhar,
          warning: isEmpty(proposer_data.aadhar.value)
            ? false
            : !validateAadharNumber(`${proposer_data.aadhar.value} `),
        },
        emergencyPhone: {
          ...proposer_data.emergencyPhone,
          warning: isEmpty(proposer_data.emergencyPhone.value)
            ? false
            : !validateMobileNumber(`${proposer_data.emergencyPhone.value} `),
        },
      };
    }

    setProposerFields({ ...proposer_data });
    setAdultOneFields({ ...adult_one_data });
    setNomineeDetails({ ...nominee_data });

    let hasError = false;
    if (!proposer_data.IS_PROPOSER_INSURED) {
      if (
        proposer_data.name.warning ||
        proposer_data.dob.warning ||
        proposer_data.gender.warning ||
        proposer_data.mobile.warning ||
        proposer_data.email.warning ||
        proposer_data.pan_card.warning
      ) {
        hasError = true;
      }
    } else {
      if (
        adult_one_data.name.warning ||
        adult_one_data.dob.warning ||
        adult_one_data.gender.warning ||
        adult_one_data.mobile.warning ||
        adult_one_data.email.warning ||
        adult_one_data.weight.warning ||
        adult_one_data.heightFeet.warning ||
        adult_one_data.heightInches.warning ||
        adult_one_data.maritalStatus.warning
      ) {
        hasError = true;
      }

      if (NOMINEE_RELATIONSHIP_STATUS === "OTHER") {
        if (
          nominee_data.full_name.warning ||
          nominee_data.dob.warning ||
          nominee_data.relationship.warning ||
          hasError
        ) {
          hasError = true;
        }
      }
    }

    if (!hasError) {
      UPDATE_ADULT_ONE_NOMINEE_DATA(adult_one_data, nominee_data);
      dispatch(
        TravelSlice.actions.BULK_UPLOAD({
          ...Travel,
          INSURED_MEMBER_DETAILS: {
            ...Travel.INSURED_MEMBER_DETAILS,
            PROPOSER_DETAILS: { ...proposer_data },
            ADULT_ONE_DETAILS: { ...adult_one_data },
            NOMINEE__DETAILS: { ...nominee_data },
          },
        })
      );
    }
  };

  const getNomineeRelationData = () => {
    const onSuccess = (res: any) => {
      let data: TDropdown[] = res?.response?.map((item: any) => ({
        value: item.title,
        label: item.title,
      }));
      setNomineeRealtionshipList(data);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      product_type_code: "TI",
      product_code: String(
        SELECTED_QUOTE_DATA.quote_details?.productDetails.product_code
      ),
    };
    TRAVEL_PROPOSAL_SERVICES.getNomineeRelationData(onSuccess, onError, param);
  };

  useEffect(() => {
    setNomineeDetails({ ...INSURED_MEMBER_DETAILS.NOMINEE__DETAILS });
    if (SELECTED_QUOTE_DATA.quote_details?.productDetails.product_code) {
      getNomineeRelationData();
    }
  }, [SELECTED_QUOTE_DATA]);

  return isMobile ? null : (
    <AdultOneDetails
      loader={loader}
      kycOpen={kycOpen}
      setKycOpen={setKycOpen}
      adultOneDetails={adultOneFields}
      nomineeDetails={nomineeDetails}
      fieldsUpdateState={fieldsUpdateState}
      proposerFields={proposerFields}
      validateForm={validateForm}
      handleClick={handleClick}
      toggle_status={toggle_status}
      disbaledButton={disbaledButton}
      setDisbaledButton={setDisbaledButton}
      maritalStatusList={maritalStatusList}
      nomineeRealtionshipList={nomineeRealtionshipList}
    />
  );
}
