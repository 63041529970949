import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import InspectionConfContainer from "../../../Container/Common/InspectionConfContainer";
import PaymentConfContainer from "../../../Container/Common/PaymentConfContainer";
import PaymentConfContainerNode from "../../../Container/Common/PaymentConfContainerNode";
import { EProductId } from "../../../Services/Enum/EHome";
import { CAR_ROUTES } from "../../Path/CarRoutes";
import { COMMON_ROUTES } from "../../Path/CommonRoutes";
import { CLIENTS } from "../../../URLCollection/Clients";

function CommonRoutes() {
  const { pathname } = useLocation();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const product_code = queryParams.get("product_code");
  const hostname = window.location.hostname;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    // <div className="App">
    //   <Routes>
    //     {hostname == CLIENTS.AMAZE ? (
    //       <Route
    //         path={COMMON_ROUTES.PAYMENT_CONFIRMATION}
    //         element={<PaymentConfContainer />}
    //       />
    //     ) : `${product_code}` === "HPMB28" ||
    //       `${product_code}` === "HPMB29" ||
    //       `${product_code}` === "HPMB30" ||
    //       `${product_code}` === "HPS137" ||
    //       `${product_code}` === "HPIC42" ||
    //       `${product_code}` === `HPMB27` ||
    //       `${product_code}` === `HPS036` ||
    //       `${product_code}` === `HFR052` ||
    //       (`${product_code}` === `HPHD01` &&
    //         window.location.hostname === CLIENTS.MOTILAL) ||
    //       (`${product_code}` === `HPHD03` &&
    //         window.location.hostname === CLIENTS.MOTILAL) ||
    //       `${product_code}` === `HPMB27` ||
    //       `${product_code}` === `HPMB21` ||
    //       `${product_code}` === `HPMB22` ||
    //       `${product_code}` === `HPMB23` ||
    //       `${product_code}` === `HPMB24` ||
    //       `${product_code}` === `HPMB18` ||
    //       `${product_code}` === `HPMB19` ||
    //       `${product_code}` === `HPMB20` ? (
    //       <Route
    //         path={COMMON_ROUTES.PAYMENT_CONFIRMATION}
    //         element={<PaymentConfContainerNode />}
    //       />
    //     ) : (`${product_code}` === `HPHD01` &&
    //         window.location.hostname === CLIENTS.POLICYPARIVAAR) ||
    //       (`${product_code}` === `HPHD03` &&
    //         window.location.hostname === CLIENTS.POLICYPARIVAAR) ? (
    //       <Route
    //         path={COMMON_ROUTES.PAYMENT_CONFIRMATION}
    //         element={<PaymentConfContainer />}
    //       />
    //     ) : (
    //       <></>
    //     )}
    //     <Route
    //       path={COMMON_ROUTES.PAYMENT_CONFIRMATION_CAR}
    //       element={<PaymentConfContainer />}
    //     />
    //     <Route
    //       path={COMMON_ROUTES.PAYMENT_CONFIRMATION_TW}
    //       element={<PaymentConfContainer />}
    //     />
    //     <Route
    //       path={CAR_ROUTES.INSPECTION_CONFIRMATION}
    //       element={<InspectionConfContainer />}
    //     />
    //   </Routes>
    // </div>
    null
  );
}

export default CommonRoutes;
