import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ETermFullJourneyMasterDropdown } from "../../../../../Enum/ETermFullJourneyMasterDropdown";
import MedicalPage from "../../../../../Page/Desktop/TermFullJourney/ICICI/Proposal/MedicalDetails/MedicalPage";
import MMedicalPage from "../../../../../Page/Mobile/TermFullJourney/ICICI/Proposal/MedicalDetails/MMedicalPage";
import { TERM_FULL_JOURNEY_ROUTES } from "../../../../../Router/Path/TermFullJourneyRoutes";
import { CoronaDetailsDTO } from "../../../../../Services/DTO/TermFullJourney/CoronaDetailsAPIDTO";
import { HealthDetailsAPIDTO } from "../../../../../Services/DTO/TermFullJourney/HealthDetailsDTO";
import { PersonalQuestionAPIDTO } from "../../../../../Services/DTO/TermFullJourney/PersonalQuestionAPIDTO";
import { PROPOSAL_PAGE_SERVICES } from "../../../../../Services/TermFullJourneyServices/ProposalPageServices";
import { TermFullJourneySlice } from "../../../../../redux/slice/TermFullJourney/TermFullJourneySlice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../../../types/TermFullJourney/TDropdown";
import { PROPOSAL_PAGE_SECTION_STATUS_API } from "../../../../../types/TermFullJourney/TProposerDetails";
import {
  TQuestion,
  TTermMedicalQuestionDetails,
  main_question,
  medical_question_field_data,
} from "../../../../../types/TermFullJourney/TTermMedicalQuestionDetails";

function MedicalPageContainer() {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { SELECTED_QUOTE_DATA, ADD_FORM_RESPONSE, ADD_FORM } = useAppSelector(
    (state) => state.Term
  );

  const state = useAppSelector((state) => state);

  const proposerPage = useAppSelector(
    (state) => state.TermFullJourney.proposerPage
  );
  const [loader, setLoader] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const TermICICIMedicalQuestion = useAppSelector(
    (state) => state.TermFullJourney.TermICICIMedicalQuestion
  );
  const [medicalQuestion, setMedicalQuestion] =
    useState<TTermMedicalQuestionDetails>(TermICICIMedicalQuestion);

  const updateMasterState = (attrName: any, value: any) => {
    console.log(attrName, value);
    if (attrName[0] === "main_question") {
      setMedicalQuestion((prevState) => {
        const questionIndex = attrName[1];
        const updatedQuestion = [...prevState.question];
        if (attrName[2] === "toggle_status") {
          // check if the third element of attrName is "toggle_status"
          if (
            updatedQuestion[questionIndex].main_question.code !== "PQ06" &&
            updatedQuestion[questionIndex].main_question.code !== "PQ07" &&
            updatedQuestion[questionIndex].main_question.code !== "HQ05"
          ) {
            updatedQuestion[questionIndex] = {
              ...updatedQuestion[questionIndex],
              main_question: {
                ...updatedQuestion[questionIndex].main_question,
                status: value,
              },
            };
          }
        } else {
          const fieldIndex = attrName[2];
          const updatedFieldData = [
            ...(prevState.question[questionIndex]?.main_question?.field_data ??
              []),
          ];
          updatedFieldData[fieldIndex] = {
            ...updatedFieldData[fieldIndex],
            value: value,
            warning: `${value}`.trim().length > 0 ? false : true,
          };
          updatedQuestion[questionIndex] = {
            ...updatedQuestion[questionIndex],
            main_question: {
              ...updatedQuestion[questionIndex].main_question,
              field_data: updatedFieldData,
            },
          };
        }
        return {
          ...prevState,
          question: updatedQuestion,
        };
      });
    } else if (attrName[0] === "sub_question") {
      setMedicalQuestion((prevState) => {
        const questionIndex = attrName[1];
        const subQuestionIndex = attrName[2];
        const updatedQuestion = [...prevState.question];
        const updatedSubQuestion = [
          ...(updatedQuestion[questionIndex]?.sub_question ?? []),
        ];
        if (attrName[3] === "toggle_status") {
          updatedSubQuestion[subQuestionIndex] = {
            ...updatedSubQuestion[subQuestionIndex],
            status: value,
          };
        } else {
          const fieldIndex = attrName[3];
          const updatedFieldData = [
            ...(updatedSubQuestion[subQuestionIndex]?.field_data ?? []),
          ];
          updatedFieldData[fieldIndex] = {
            ...updatedFieldData[fieldIndex],
            value: value,
            warning: `${value}`.trim().length > 0 ? false : true,
          };
          updatedSubQuestion[subQuestionIndex] = {
            ...updatedSubQuestion[subQuestionIndex],
            field_data: updatedFieldData,
          };
        }
        updatedQuestion[questionIndex] = {
          ...updatedQuestion[questionIndex],
          sub_question: updatedSubQuestion,
        };
        return {
          ...prevState,
          question: updatedQuestion,
        };
      });
    } else {
      setMedicalQuestion((prev) => ({
        ...prev,
        [attrName]: {
          value: value,
          warning: `${value}`.length > 0 ? false : true,
        },
      }));
    }
  };

  // main_question_status: boolean;
  // details: previous_policy_details[];
  // sub_question_one: boolean;
  // sub_question_one_remarks: field_data;
  // sub_question_two: boolean;
  // sub_question_two_remarks: field_data;
  const updateMasterStatePreviousPolicy = (attrName: any, value: any) => {
    if (
      attrName === "main_question_status" ||
      attrName === "sub_question_one" ||
      attrName === "sub_question_two"
    ) {
      setMedicalQuestion((prev: any) => ({
        ...prev,
        previous_policy: {
          ...prev.previous_policy,
          [attrName]: value,
        },
      }));
    } else if (attrName[0] === "add_previous_policy_detail") {
      var medical_question = medicalQuestion;
      var details = medical_question.previous_policy.details;

      details.push({
        base_sum_assured: { value: "", warning: false },
        company_name: { value: "", warning: false },
      });
      medical_question = {
        ...medical_question,
        previous_policy: {
          ...medical_question.previous_policy,
          details: details,
        },
      };
      setMedicalQuestion(medical_question);
    } else if (attrName[0] === "remove_previous_policy_detail") {
      var medical_question = medicalQuestion;
      var details = medical_question.previous_policy.details;

      details.splice(attrName[1], 1);
      medical_question = {
        ...medical_question,
        previous_policy: {
          ...medical_question.previous_policy,
          details: details,
        },
      };
      setMedicalQuestion(medical_question);
    } else if (
      attrName[0] === "company_name" ||
      attrName[0] === "base_sum_assured"
    ) {
      var medical_question: TTermMedicalQuestionDetails = medicalQuestion;
      var details = medical_question.previous_policy.details;

      details[attrName[1]] = {
        ...details[attrName[1]],
        [attrName[0]]: { value: value, warning: isEmpty(value) },
      };

      medical_question = {
        ...medical_question,
        previous_policy: {
          ...medical_question.previous_policy,
          details: details,
        },
      };
      setMedicalQuestion(medical_question);
    } else if (
      attrName === "sub_question_two_remarks" ||
      attrName === "sub_question_one_remarks"
    ) {
      setMedicalQuestion((prev: any) => ({
        ...prev,
        previous_policy: {
          ...prev.previous_policy,
          [attrName]: { value: value, warning: isEmpty(value) },
        },
      }));
    } else {
      setMedicalQuestion((prev: any) => ({
        ...prev,
        previous_policy: {
          ...prev.previous_policy,
          [attrName]: {
            value: value,
            warning: `${value}`.length > 0 ? false : true,
          },
        },
      }));
    }
  };

  function validateFields(): boolean {
    let valid = true;
    let validatedMedicalQuestion = medicalQuestion;

    validatedMedicalQuestion.question.forEach((data, index) => {
      try {
        if (
          data.main_question &&
          data.main_question.status &&
          data.main_question.field_data
        ) {
          const fieldData = data.main_question.field_data.map(
            (field, index_field) => {
              let updatedField = { ...field };
              if (
                field.value === undefined ||
                `${field.value}`.trim().length === 0
              ) {
                updatedField = {
                  ...updatedField,
                  warning: true,
                };
                valid = false;
              } else {
                updatedField = {
                  ...updatedField,
                  warning: false,
                };
              }
              return updatedField;
            }
          );

          validatedMedicalQuestion = {
            ...validatedMedicalQuestion,
            question: validatedMedicalQuestion.question.map((q, i) => {
              if (i === index && q.main_question) {
                return {
                  ...q,
                  main_question: {
                    ...q.main_question,
                    field_data: fieldData,
                  },
                };
              }
              return q;
            }),
          };
        }

        if (data.sub_question) {
          data.sub_question.forEach((subQuestion, subIndex) => {
            if (subQuestion && subQuestion.status && subQuestion.field_data) {
              const fieldData = subQuestion.field_data.map(
                (field, subIndex_field) => {
                  let updatedField = { ...field };
                  if (
                    field.value !== undefined &&
                    `${field.value}`.trim().length === 0
                  ) {
                    updatedField = {
                      ...updatedField,
                      warning: true,
                    };
                    valid = false;
                  } else {
                    updatedField = {
                      ...updatedField,
                      warning: false,
                    };
                  }
                  return updatedField;
                }
              );

              validatedMedicalQuestion = {
                ...validatedMedicalQuestion,
                question: validatedMedicalQuestion.question.map((q, i) => {
                  if (i === index && q.sub_question) {
                    const updatedSubQuestion = q.sub_question.map(
                      (subQ, subI) => {
                        if (subI === subIndex) {
                          return {
                            ...subQ,
                            field_data: fieldData,
                          };
                        }
                        return subQ;
                      }
                    );
                    return {
                      ...q,
                      sub_question: updatedSubQuestion,
                    };
                  }
                  return q;
                }),
              };
            }
          });
        }
      } catch (error) {
        console.log("An error occurred while validating fields: ", error);
      }
    });

    validatedMedicalQuestion = {
      previous_policy: validatedMedicalQuestion.previous_policy,
      question: [...validatedMedicalQuestion.question],
      weight_kg: {
        ...validatedMedicalQuestion.weight_kg,
        warning:
          validatedMedicalQuestion.weight_kg.value === null
            ? true
            : isEmpty(validatedMedicalQuestion.weight_kg.value),
      },
      repository_type: {
        ...validatedMedicalQuestion.repository_type,
        warning:
          validatedMedicalQuestion.repository_type.value === null
            ? true
            : isEmpty(validatedMedicalQuestion.repository_type.value),
      },
      height_ft: {
        ...validatedMedicalQuestion.height_ft,
        warning:
          validatedMedicalQuestion.height_ft.value === null
            ? true
            : isEmpty(validatedMedicalQuestion.height_ft.value),
      },
      height_inches: {
        ...validatedMedicalQuestion.height_inches,
        warning:
          validatedMedicalQuestion.height_inches.value === null
            ? true
            : isEmpty(validatedMedicalQuestion.height_inches.value),
      },
      height_cm: { ...validatedMedicalQuestion.height_cm },
    };

    if (
      validatedMedicalQuestion.weight_kg.warning ||
      validatedMedicalQuestion.height_ft.warning ||
      validatedMedicalQuestion.height_inches.warning ||
      validatedMedicalQuestion.repository_type.warning
    ) {
      valid = false;
    }

    if (validatedMedicalQuestion.question[15].main_question.status === true) {
      const sub_question = validatedMedicalQuestion.question[15].sub_question;
      console.log(sub_question);
      valid = false;
      for (const item of sub_question ?? []) {
        if (item.status === true) {
          valid = true;
          break;
        }
      }
    }
    if (valid === false) toast.error("Select any sub question.");
    setMedicalQuestion(validatedMedicalQuestion);

    return valid;
  }

  const continue_function = () => {
    const hasError = !validateFields();
    console.log(hasError);
    if (!hasError) {
      dispatch(
        TermFullJourneySlice.actions.SET_ICICI_MEDICAL_QUESTION(medicalQuestion)
      );
      updatePersonalQuestionDetailsData(medicalQuestion);
    }
  };

  type ValueTypes = keyof TQuestion;
  function getQuestionDataByCodeAndType(
    code: string,
    type: "main_question" | "field_data" | "sub_question",
    data_needed: ValueTypes
  ): any {
    const question = medicalQuestion.question
      .flatMap((mainQuestion: main_question) =>
        mainQuestion.sub_question
          ? [mainQuestion.main_question, ...mainQuestion.sub_question]
          : mainQuestion.main_question
      )
      .find((question: TQuestion) => question.code === code);
    if (question) {
      switch (type) {
        case "main_question":
          if (data_needed === "status") {
            if (question[data_needed] === true) {
              return "Yes";
            } else {
              return "No";
            }
          }
          return question[data_needed];

        case "field_data":
          return question.field_data;
        case "sub_question":
          if (data_needed === "status") {
            if (question[data_needed] === true) {
              return "Yes";
            } else {
              return "No";
            }
          }
          return question[data_needed];
      }
    }
    return undefined;
  }

  type ValueTypesFields = keyof medical_question_field_data;
  function findFieldDataByCode(code: string, field: ValueTypesFields): any {
    const questions = medicalQuestion.question.flatMap(
      (mainQuestion: main_question) =>
        mainQuestion.sub_question
          ? [mainQuestion.main_question, ...mainQuestion.sub_question]
          : mainQuestion.main_question
    );

    for (let question of questions) {
      const fieldData = question.field_data?.find((data) => data.code === code);
      if (fieldData) {
        return fieldData[field];
      }
    }

    return undefined;
  }

  const updatePersonalQuestionDetailsData = (
    medical_details: TTermMedicalQuestionDetails
  ) => {
    const onSuccess = (data: any) => {
      setLoader(false);
      const results = data.results;
      const error = data.error;
      const message = data.message;
      const response = data.response;

      if (error === false) {
        updateMedicalDetailsData(medical_details);
        dispatch(TermFullJourneySlice.actions.updateUpdateStatus(false));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    const insuranceInfos = medical_details.previous_policy.details.map(
      (details) => {
        const { base_sum_assured, company_name } = details;

        return {
          sum: base_sum_assured.value ?? "",
          cmpany: company_name.value ?? "",
        };
      }
    );

    const data: PersonalQuestionAPIDTO = {
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
      section: PROPOSAL_PAGE_SECTION_STATUS_API.PERSONAL_QUESTION_DETAILS,
      details: {
        industryTypeQuestion: getQuestionDataByCodeAndType(
          "PQ01",
          "main_question",
          "status"
        ),
        indsType: findFieldDataByCode("PQ01-1", "value"),
        indsTypeDesc: findFieldDataByCode("PQ01-2", "value"),
        pltclyExpsd: getQuestionDataByCodeAndType(
          "PQ02",
          "main_question",
          "status"
        ),
        isEIAOpted: getQuestionDataByCodeAndType(
          "PQ03",
          "main_question",
          "status"
        ),
        eiaInsuranceRepository: medical_details.repository_type.value,
        EIAAccountNumber: findFieldDataByCode("PQ03-1", "value"),
        insuranceInfos: insuranceInfos,
        sum: findFieldDataByCode("PQ04-2", "value"),
        cmpany: findFieldDataByCode("PQ04-1", "value"),
        mwpaOpted: "No",
        nriTaxResidentUS: "Yes",
        nriOtherResCountry: "Yes",
        currentlyInvestigated: getQuestionDataByCodeAndType(
          "PQ08",
          "main_question",
          "status"
        ),
        currentlyInvestigatedDesc: findFieldDataByCode("PQ08-1", "value"),
        travelInLast1Year: getQuestionDataByCodeAndType(
          "PQ09",
          "main_question",
          "status"
        ),
      },
    };
    setLoader(true);
    PROPOSAL_PAGE_SERVICES.updateProposalPageDetails(onSuccess, onError, data);
  };

  const updateMedicalDetailsData = (
    medical_details: TTermMedicalQuestionDetails
  ) => {
    const onSuccess = (data: any) => {
      setLoader(false);
      const results = data.results;
      const error = data.error;
      const message = data.message;
      const response = data.response;

      if (error === false) {
        updateCoronaMedicalDetailsData(medical_details);
        dispatch(TermFullJourneySlice.actions.updateUpdateStatus(false));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    const data: HealthDetailsAPIDTO = {
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
      section: PROPOSAL_PAGE_SECTION_STATUS_API.HEALTH_DETAILS,
      details: {
        feet: medical_details.height_ft.value,
        inches: medical_details.height_inches.value,
        weight: medical_details.weight_kg.value,
        tobacco: getQuestionDataByCodeAndType(
          "HQ05",
          "main_question",
          "status"
        ),
        tobaccoConsumed: findFieldDataByCode("HQ05-1", "value"),
        tobaccoQuantity: findFieldDataByCode("HQ05-2", "value"),
        tobaccoYear: findFieldDataByCode("HQ05-3", "value"),
        alcohol: getQuestionDataByCodeAndType(
          "HQ06",
          "main_question",
          "status"
        ),
        alcoholConsumed: findFieldDataByCode("HQ06-1", "value"),
        alcoholQuantity: findFieldDataByCode("HQ06-2", "value"),
        alcoholYear: findFieldDataByCode("HQ06-3", "value"),
        narcotics: getQuestionDataByCodeAndType(
          "HQ07",
          "main_question",
          "status"
        ),
        occupationAssociated: getQuestionDataByCodeAndType(
          "HQ09",
          "main_question",
          "status"
        ),
        occupationAssociatedText: findFieldDataByCode("HQ09-1", "value"),
        employeeArmed: getQuestionDataByCodeAndType(
          "HQ125",
          "main_question",
          "status"
        ),
        employeeArmedText: findFieldDataByCode("HQ125-1", "value"),
        lostWeight: getQuestionDataByCodeAndType(
          "HQ144",
          "main_question",
          "status"
        ),
        lostWeightText: findFieldDataByCode("HQ144-1", "value"),
        familyLifeAssured: getQuestionDataByCodeAndType(
          "HQ165",
          "main_question",
          "status"
        ),
        familyLifeAssuredText: findFieldDataByCode("HQ165-1", "value"),
        pastTreatment: getQuestionDataByCodeAndType(
          "HQ166",
          "main_question",
          "status"
        ),
        pastTreatmentText: findFieldDataByCode("HQ166-1", "value"),
        sufferedDiagnosedHQ167: getQuestionDataByCodeAndType(
          "HQ167",
          "main_question",
          "status"
        ),
        hypertensionHQ167: getQuestionDataByCodeAndType(
          "HQ167-SQ1",
          "sub_question",
          "status"
        ),
        heartDiseaseHQ167: getQuestionDataByCodeAndType(
          "HQ167-SQ2",
          "sub_question",
          "status"
        ),
        bypassSurgeryHQ167: getQuestionDataByCodeAndType(
          "HQ167-SQ3",
          "sub_question",
          "status"
        ),
        sugarHQ167: getQuestionDataByCodeAndType(
          "HQ167-SQ4",
          "sub_question",
          "status"
        ),
        respiratoryDisorderHQ167: getQuestionDataByCodeAndType(
          "HQ167-SQ5",
          "sub_question",
          "status"
        ),
        strokeHQ167: getQuestionDataByCodeAndType(
          "HQ167-SQ6",
          "sub_question",
          "status"
        ),
        colitisHQ167: getQuestionDataByCodeAndType(
          "HQ167-SQ7",
          "sub_question",
          "status"
        ),
        liverDisordersHQ167: getQuestionDataByCodeAndType(
          "HQ167-SQ8",
          "sub_question",
          "status"
        ),
        urinarySystemHQ167: getQuestionDataByCodeAndType(
          "HQ167-SQ9",
          "sub_question",
          "status"
        ),
        cancerHQ167: getQuestionDataByCodeAndType(
          "HQ167-SQ10",
          "sub_question",
          "status"
        ),
        hivHQ167: getQuestionDataByCodeAndType(
          "HQ167-SQ11",
          "sub_question",
          "status"
        ),
        bloodDisordersHQ167: getQuestionDataByCodeAndType(
          "HQ167-SQ12",
          "sub_question",
          "status"
        ),
        mentalDisoredersHQ167: getQuestionDataByCodeAndType(
          "HQ167-SQ13",
          "sub_question",
          "status"
        ),
        anyOtherDisorderHQ167: getQuestionDataByCodeAndType(
          "HQ167-SQ14",
          "sub_question",
          "status"
        ),
        anyOtherDisorderTextHQ167: findFieldDataByCode("HQ167-SQ14-1", "value"),
        healthDeclarationHQ188: getQuestionDataByCodeAndType(
          "HQ188",
          "main_question",
          "status"
        ),
        medicalConditionHQ188: findFieldDataByCode("HQ188-1", "value"),
        dateDiagnosisHQ188: findFieldDataByCode("HQ188-2", "value"),
        treatmentGOHQ188: findFieldDataByCode("HQ188-3", "value"),
        currentMedicalConditionHQ188: findFieldDataByCode("HQ188-4", "value"),
        treatmentDetailsHQ188: findFieldDataByCode("HQ188-5", "value"),
        undergoneTreatmentHQ188: findFieldDataByCode("HQ188-6", "value"),
        anyOtherDetailsHQ188: findFieldDataByCode("HQ188-7", "value"),
        gynecologicalComplications: getQuestionDataByCodeAndType(
          "HQ168",
          "main_question",
          "status"
        ),
        gynecologicalComplicationsText: findFieldDataByCode("HQ168-1", "value"),
        congenitalDefect: getQuestionDataByCodeAndType(
          "HQ301",
          "main_question",
          "status"
        ),
        congenitalDefectText: findFieldDataByCode("HQ301-1", "value"),
        ailment: getQuestionDataByCodeAndType(
          "HQ24",
          "main_question",
          "status"
        ),
        ailmentText: findFieldDataByCode("HQ24-1", "value"),
        pragnant: getQuestionDataByCodeAndType(
          "HQ61",
          "main_question",
          "status"
        ),
        pragnantWeeks: findFieldDataByCode("HQ61-1", "value"),
      },
    };
    console.log(data);
    setLoader(true);
    PROPOSAL_PAGE_SERVICES.updateProposalPageDetails(onSuccess, onError, data);
  };

  const updateCoronaMedicalDetailsData = (
    medical_details: TTermMedicalQuestionDetails
  ) => {
    const onSuccess = (data: any) => {
      setLoader(false);
      const results = data.results;
      const error = data.error;
      const message = data.message;
      if (!error) {
        if (proposerPage.update_status) {
          navigate(-1);
          dispatch(TermFullJourneySlice.actions.updateUpdateStatus(false));
        } else {
          navigate(TERM_FULL_JOURNEY_ROUTES.PREVIEW_PAGE);
        }
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    const data: CoronaDetailsDTO = {
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
      section: PROPOSAL_PAGE_SECTION_STATUS_API.CORONA_DETAILS,
      details: {
        last3MonthsTestedPositiveCHQ1: getQuestionDataByCodeAndType(
          "CHQ58",
          "main_question",
          "status"
        ),
        last3MonthsIsolatedCHQ2: getQuestionDataByCodeAndType(
          "CHQ57",
          "main_question",
          "status"
        ),
        last1MonthsAdvisedIsolatedCHQ3: getQuestionDataByCodeAndType(
          "CHQ19",
          "main_question",
          "status"
        ),
        last1MonthsConfirmedCovid19CHQ4: getQuestionDataByCodeAndType(
          "CHQ59",
          "main_question",
          "status"
        ),
        occupationCovid19CHQ5: getQuestionDataByCodeAndType(
          "CHQ60",
          "main_question",
          "status"
        ),
        positiveCovid19CHQ14: getQuestionDataByCodeAndType(
          "CHQ14",
          "main_question",
          "status"
        ),
        fullyCoveredCHQ15: getQuestionDataByCodeAndType(
          "CHQ14-SQ1",
          "main_question",
          "status"
        ),
        dateDiagnoseCHQ16: findFieldDataByCode("CHQ14-SQ1-1", "value"),
        dateRecoveryCHQ17: findFieldDataByCode("CHQ14-SQ1-2", "value"),
        hospitalizedCovid19CHQ18: getQuestionDataByCodeAndType(
          "CHQ14-SQ2",
          "main_question",
          "status"
        ),
        memberIsolatedCHQ19: getQuestionDataByCodeAndType(
          "CHQ57",
          "main_question",
          "status"
        ),
        advisedCovidTestCHQ20: getQuestionDataByCodeAndType(
          "CHQ20",
          "main_question",
          "status"
        ),
        occupationHealthCareCHQ21: getQuestionDataByCodeAndType(
          "CHQ21",
          "main_question",
          "status"
        ),
        VaccinatedCovide19CHQ30: getQuestionDataByCodeAndType(
          "CHQ30",
          "main_question",
          "status"
        ),
        fisrtDoseCHQ31: findFieldDataByCode("CHQ30-1", "value"),
        nameVaccineCHQ32: findFieldDataByCode("CHQ30-2", "value"),
        vaccinatedSecondDoseCHQ33: getQuestionDataByCodeAndType(
          "CHQ33",
          "main_question",
          "status"
        ),
        secondDoseCHQ34: findFieldDataByCode("CHQ33-1", "value"),
        secondNameVaccineCHQ35: findFieldDataByCode("CHQ33-2", "value"),
        postVaccinationCHQ36: getQuestionDataByCodeAndType(
          "CHQ36",
          "main_question",
          "status"
        ),
        detailsCompleteRecoveryCHQ37: findFieldDataByCode("CHQ36-1", "value"),
        residingOustSideIndiaCHQ38: getQuestionDataByCodeAndType(
          "CHQ38",
          "main_question",
          "status"
        ),
        nameCountryCHQ39: findFieldDataByCode("CHQ38-1", "value"),
        cityCHQ40: findFieldDataByCode("CHQ38-2", "value"),
        dateTravelCHQ41: findFieldDataByCode("CHQ38-3", "value"),
        intendedDurationCHQ42: findFieldDataByCode("CHQ38-4", "value"),
        travelledAbroadCHQ43: getQuestionDataByCodeAndType(
          "CHQ43",
          "main_question",
          "status"
        ),
        nameCountryCHQ44: findFieldDataByCode("CHQ43-1", "value"),
        cityCHQ45: findFieldDataByCode("CHQ43-2", "value"),
        dateArrivedCHQ46: findFieldDataByCode("CHQ43-3", "value"),
        dateDepartedCHQ47: findFieldDataByCode("CHQ43-4", "value"),
        nameCountryCHQ48: findFieldDataByCode("CHQ43-SQ1-1", "value"),
        cityCHQ49: findFieldDataByCode("CHQ43-SQ1-2", "value"),
        dateArrivedCHQ50: findFieldDataByCode("CHQ43-SQ1-3", "value"),
        dateDepartedCHQ51: findFieldDataByCode("CHQ43-SQ1-4", "value"),
        nameCountryCHQ52: findFieldDataByCode("CHQ43-SQ2-1", "value"),
        cityCHQ53: findFieldDataByCode("CHQ43-SQ2-2", "value"),
        dateArrivedCHQ54: findFieldDataByCode("CHQ43-SQ2-3", "value"),
        dateDepartedCHQ55: findFieldDataByCode("CHQ43-SQ2-4", "value"),
        travelAbroadCHQ56: getQuestionDataByCodeAndType(
          "CHQ56",
          "main_question",
          "status"
        ),
        nameCountryCHQ57: findFieldDataByCode("CHQ56-1", "value"),
        cityCHQ58: findFieldDataByCode("CHQ56-2", "value"),
        dateTravelCHQ59: findFieldDataByCode("CHQ56-3", "value"),
        intendedDurationCHQ60: findFieldDataByCode("CHQ56-4", "value"),
        nameCountryCHQ61: findFieldDataByCode("CHQ56-SQ1-1", "value"),
        cityCHQ62: findFieldDataByCode("CHQ56-SQ1-2", "value"),
        dateTravelCHQ63: findFieldDataByCode("CHQ56-SQ1-3", "value"),
        intendedDurationCHQ64: findFieldDataByCode("CHQ56-SQ1-4", "value"),
        nameCountryCHQ65: findFieldDataByCode("CHQ56-SQ1-1", "value"),
        cityCHQ66: findFieldDataByCode("CHQ56-SQ2-2", "value"),
        dateTravelCHQ67: findFieldDataByCode("CHQ56-SQ2-3", "value"),
        intendedDurationCHQ68: findFieldDataByCode("CHQ56-SQ2-4", "value"),
      },
    };
    setLoader(true);
    PROPOSAL_PAGE_SERVICES.updateProposalPageDetails(onSuccess, onError, data);
  };

  useEffect(() => {
    getInsuranceCompanyMaster();
    getCountryMaster();
    getDropdownMaster(ETermFullJourneyMasterDropdown.MY_PROFESSION);
    getDropdownMaster(ETermFullJourneyMasterDropdown.ORG_TYPE);
    getDropdownMaster(ETermFullJourneyMasterDropdown.INDUSTRY_TYPE);
    getDropdownMaster(ETermFullJourneyMasterDropdown.OBJ_OF_BUYING_POLICY);

    const clonedMedicalQuestion = JSON.parse(JSON.stringify(medicalQuestion));
    clonedMedicalQuestion.question[7].main_question.status =
      ADD_FORM.smoke === "N" ? false : true;
    setMedicalQuestion(clonedMedicalQuestion);
  }, []);

  useEffect(() => {
    const clonedMedicalQuestion: TTermMedicalQuestionDetails = JSON.parse(
      JSON.stringify(medicalQuestion)
    );
    const sub_question = clonedMedicalQuestion.question[16]?.sub_question;

    if (sub_question) {
      const lastIndex = sub_question.length - 1;
      let anyStatusTrue = false;

      for (let i = 0; i < lastIndex; i++) {
        if (sub_question[i].status === true) {
          anyStatusTrue = true;
          break; // Exit the loop early if any status is true
        }
      }

      // Check if sub_question[lastIndex] exists before accessing its properties
      if (sub_question[lastIndex]) {
        // Update the status of the last object based on the condition
        sub_question[lastIndex].status = anyStatusTrue;
        setMedicalQuestion(clonedMedicalQuestion);
      }
    }
  }, [medicalQuestion]);

  const getDropdownMaster = (type: ETermFullJourneyMasterDropdown) => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        switch (type) {
          case ETermFullJourneyMasterDropdown.MY_PROFESSION:
            const data_prof: Array<TDropdown> = response.map((obj: any) => {
              return {
                value: obj.code,
                label: obj.title,
              };
            });
            dispatch(TermFullJourneySlice.actions.setMyProfession(data_prof));
            break;
          case ETermFullJourneyMasterDropdown.ORG_TYPE:
            const data_org_type: Array<TDropdown> = response.map((obj: any) => {
              return {
                value: obj.code,
                label: obj.title,
              };
            });
            dispatch(
              TermFullJourneySlice.actions.setICICIOrgType(data_org_type)
            );
            break;
          case ETermFullJourneyMasterDropdown.INDUSTRY_TYPE:
            const data_ind_type: Array<TDropdown> = response.map((obj: any) => {
              return {
                value: obj.code,
                label: obj.title,
              };
            });
            dispatch(
              TermFullJourneySlice.actions.setICICIIndustryType(data_ind_type)
            );
            break;
          case ETermFullJourneyMasterDropdown.OBJ_OF_BUYING_POLICY:
            const data__buying_policy: Array<TDropdown> = response.map(
              (obj: any) => {
                return {
                  value: obj.code,
                  label: obj.title,
                };
              }
            );
            dispatch(
              TermFullJourneySlice.actions.setObjBuyingPolicy(
                data__buying_policy
              )
            );
            break;
          default:
            break;
        }
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };
    let param = {
      buy_online_code: "IPRU",
      type: type,
    };
    PROPOSAL_PAGE_SERVICES.getDropdownDataMaster(onSuccess, onError, param);
  };

  const getInsuranceCompanyMaster = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        let data: TDropdown[] = response.map((obj: any) => {
          return {
            key: obj.code,
            value: obj.title,
          };
        });

        dispatch(
          TermFullJourneySlice.actions.setICICIInsuranceCompanyLife(data)
        );
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };

    let param = {
      buy_online_code: "IPRU",
      type: ETermFullJourneyMasterDropdown.INSURANCE_COMPANY_LIFE,
    };
    PROPOSAL_PAGE_SERVICES.getDropdownDataMaster(onSuccess, onError, param);
  };

  const getCountryMaster = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        let data: TDropdown[] = response.map((obj: any) => {
          return {
            label: obj.name,
            value: obj.name,
          };
        });

        dispatch(TermFullJourneySlice.actions.setCountryMaster(data));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    PROPOSAL_PAGE_SERVICES.getCountryMaster(onSuccess, onError);
  };

  return (
    <>
      {isMobile ? (
        <MMedicalPage
          continue_function={continue_function}
          updateMasterStatePreviousPolicy={updateMasterStatePreviousPolicy}
          medicalQuestion={medicalQuestion}
          updateMasterState={updateMasterState}
        />
      ) : (
        <MedicalPage
          continue_function={continue_function}
          medicalQuestion={medicalQuestion}
          updateMasterState={updateMasterState}
          updateMasterStatePreviousPolicy={updateMasterStatePreviousPolicy}
          loader={loader}
        />
      )}
    </>
  );
}

export default MedicalPageContainer;
