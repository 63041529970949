import { ENonTermCompanyCode } from "../../../Enum/ENonTermComapnyCode";
import { useAppSelector } from "../../../redux/hooks";
import SHRIRAMRoutes from "./investmentProposalRoutes/SHRIRAM/SHRIRAMRoutes";

const InvestmentRoutes = () => {
  const { company_code } = useAppSelector(
    (state) => state.NonTerm.SELECTED_QUOTE_DATA?.companyDetails
  );

  return (
    <>
      {company_code === ENonTermCompanyCode.SHRIRAM ? <SHRIRAMRoutes /> : null}
    </>
  );
};

export default InvestmentRoutes;
