import { Box, colors } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useAppSelector } from "../../../../../../redux/hooks";
import { THDFCCKYCDetails } from "../../../../../../types/TermFullJourney/TCKYCDetails";
import RKTextField from "../../../../../../Component/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import Footer from "../../../../../../Component/Mobile/TermFullJourney/ProposalForm/Common/Footer/Footer";

const CKYCDetails = ({
  loader,
  continueFunction,
  updateMasterState,
  formData,
}: {
  loader: boolean;
  continueFunction: Function;
  updateMasterState: Function;
  formData: THDFCCKYCDetails;
}) => {
  const { dropdownData, proposerDetails } = useAppSelector(
    (state) => state.TermFullJourney
  );
  return (
    <Box>
      <h5 className="sectionTitle">CKYC/eKYC Details</h5>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <h6 className="mb-4 question">
            Central KYC (CKYC) registry is a centralized repository of your KYC
            records.
          </h6>
          <p className="textGrey ckyc_p">
            <span className="textBold">Advantages of CKYC are:-</span>
          </p>
          <ul style={{ color: colors.grey[500] }}>
            <li>
              A single KYC for all your financial transactions i.e. no
              repetitive submission of documents for verification.
            </li>
            <li>
              It is secure, hassle free and saves documentation procession time.
            </li>
          </ul>
          <p className="textGrey ckyc_p">
            <span className="textBold">
              Kindly provide proposer specific details
            </span>
          </p>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"CKYC no. (optional)"}
            value={formData.ckyc_no.value}
            attrName={["ckyc_no", "value", formData, "setFormData"]}
            value_update={updateMasterState}
            warn_status={formData.ckyc_no.warning}
            error_message="Enter valid CKYC no."
            validation_type="NUMBER"
            max_length={14}
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Father's full name"}
            value={formData.father_full_name.value}
            attrName={["father_full_name", "value", formData, "setFormData"]}
            value_update={updateMasterState}
            warn_status={formData.father_full_name.warning}
            error_message="Enter father's full name"
            max_length={61}
            validation_type="NAME"
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Mother's full name"}
            value={formData.mother_full_name.value}
            attrName={["mother_full_name", "value", formData, "setFormData"]}
            value_update={updateMasterState}
            warn_status={formData.mother_full_name.warning}
            error_message="Enter mother's full name"
            max_length={61}
            validation_type="NAME"
          />
        </Grid>
        {proposerDetails.gender.value === "F" &&
        ((typeof proposerDetails.marital_status.value === "string" &&
          proposerDetails.marital_status.value === "MAR_MRD") ||
          proposerDetails.marital_status.value.code === "MAR_MRD") ? (
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Spouse full name"}
              value={formData.spouse_name.value}
              attrName={["spouse_name", "value", formData, "setFormData"]}
              value_update={updateMasterState}
              warn_status={formData.spouse_name.warning}
              error_message="Enter spouse full name"
              max_length={61}
              validation_type="NAME"
            />
          </Grid>
        ) : null}
        {proposerDetails.occupation.value.code === "OCCT_SALR" ||
        proposerDetails.occupation.value.code === "OCCT_SEBS" ||
        (typeof proposerDetails.occupation.value === "string" &&
          (proposerDetails.occupation.value === "OCCT_SALR" ||
            proposerDetails.occupation.value === "OCCT_SEBS")) ? (
          <Grid xs={6}>
            <SelectDropdown
              class_name="inputField"
              title="Occupation"
              value={formData.occupation.value}
              attrName={["occupation", "value", formData, "setFormData"]}
              value_update={updateMasterState}
              data={dropdownData.HDFC_CKYC_OCCUPATION}
              warn_status={formData.occupation.warning}
            />
          </Grid>
        ) : null}
        {proposerDetails.occupation.value.code === "OCCT_SALR" ||
        (typeof proposerDetails.occupation.value === "string" &&
          proposerDetails.occupation.value === "OCCT_SALR") ? (
          <>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Employer name"}
                value={formData.employer_name.value}
                attrName={["employer_name", "value", formData, "setFormData"]}
                value_update={updateMasterState}
                warn_status={formData.employer_name.warning}
                error_message="Enter employer name"
                max_length={50}
                validation_type="NAME"
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Employer address"}
                value={formData.employer_address.value}
                attrName={[
                  "employer_address",
                  "value",
                  formData,
                  "setFormData",
                ]}
                value_update={updateMasterState}
                warn_status={formData.employer_address.warning}
                error_message="Enter employer address"
                max_length={100}
                validation_type="NAME"
              />
            </Grid>
          </>
        ) : null}
      </Grid>
      <Footer continueFunction={continueFunction} loader={loader} />
    </Box>
  );
};

export default CKYCDetails;
