import {
  Box,
  Checkbox,
  FormControlLabel,
  Modal,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  EVDateInput,
  EVSelectDropdown,
  EVTextField,
} from "@evervent_pvt_ltd/ui-kit";
import { TNonTermProposerData } from "../../../../types/TNonTermSlice";
import { useAppSelector } from "../../../../redux/hooks";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import NonTermFooter from "../../../../Component/NonTerm/NonTermFooter/NonTermFooter";
import { Link } from "react-router-dom";
import CustomButton from "../../../../Component/CustomButton/CustomButton";
import MNonTermFooter from "../../../../Component/NonTerm/MNonTermFooter/MNonTermFooter";

const MNonTermProposal = ({
  fieldsUpdateState,
  validateForm,
  loader,
  proposerFields,
  showOTPPopup,
  setShowOTPPopup,
  setOtpValue,
  otpValue,
  VERIFY_OTP,
}: {
  proposerFields: TNonTermProposerData;
  fieldsUpdateState: Function;
  validateForm: Function;
  loader: boolean;
  showOTPPopup: boolean;
  setShowOTPPopup: (showOTPPopup: boolean) => void;
  otpValue: any;
  setOtpValue: (otpValue: string) => void;
  VERIFY_OTP: Function;
}) => {
  const { DROPDOWN_DATA, ADD_FORM, QUOTE_LOADER, UPDATE_RESPONSE } =
    useAppSelector((state) => state.NonTerm);

  const handleUpdateOTP = (attrName: any, value: any) => {
    setOtpValue(value);
  };

  return (
    <>
      <Box>
        <h5 className="sectionTitle">Proposer Details</h5>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <h6>Proposer / Insured Details</h6>
          </Grid>
          <Grid xs={12}>
            <EVTextField
              id="full_name"
              title={"Full Name"}
              max_length={75}
              validation_type="NAME"
              value={proposerFields.full_name.value}
              attrName={["full_name"]}
              value_update={fieldsUpdateState}
              warn_status={proposerFields?.full_name?.warning}
              error_message={
                isEmpty(proposerFields.full_name.value)
                  ? "Enter Full Name"
                  : "Enter Valid Full Name"
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} className="my-4">
          <Grid xs={6}>
            <EVDateInput
              id="dob"
              title={"DOB"}
              value={
                UPDATE_RESPONSE?.dob ? UPDATE_RESPONSE?.dob : ADD_FORM.age.value
              }
              attrName={["dob"]}
              disabled={true}
              value_update={fieldsUpdateState}
              error_message="Enter DOB"
              warn_status={ADD_FORM.age.warning}
            />
          </Grid>
          <Grid xs={6}>
            <EVSelectDropdown
              id="gender"
              title="Gender"
              value={ADD_FORM.gender.value}
              attrName={["gender"]}
              value_update={fieldsUpdateState}
              data={[
                { label: "Male", value: "M" },
                { label: "Female", value: "F" },
              ]}
              disabled={true}
              warn_status={ADD_FORM?.gender?.warning}
              error_message="Select Gender"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={6}>
            <EVTextField
              id="mobile"
              disabled
              title={"Mobile"}
              value={ADD_FORM.mobile.value}
              attrName={["mobile"]}
              value_update={fieldsUpdateState}
              warn_status={ADD_FORM.mobile.warning}
              max_length={10}
              validation_type="NUMBER"
              error_message={
                !ADD_FORM.mobile.value ? "Enter mobile number" : ""
              }
            />
          </Grid>
          <Grid xs={6}>
            <EVTextField
              id="email"
              title={"Email"}
              value={proposerFields.email.value}
              attrName={["email"]}
              value_update={fieldsUpdateState}
              warn_status={proposerFields?.email?.warning}
              error_message={
                isEmpty(proposerFields.email.value)
                  ? "Enter Email"
                  : "Enter Valid Email"
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} className="my-4">
          <Grid xs={6}>
            <EVTextField
              id="pincode"
              title={"Pincode"}
              value={ADD_FORM.pincode.value}
              attrName={["pincode"]}
              value_update={fieldsUpdateState}
              warn_status={ADD_FORM.pincode.warning}
              validation_type="NUMBER"
              max_length={6}
              disabled={true}
              error_message={!ADD_FORM.pincode.warning ? "Enter Pincode" : ""}
            />
          </Grid>
          <Grid xs={6}>
            <EVTextField
              id="emergency_number"
              title={"Emergency Phone"}
              value={proposerFields.emergency_number.value}
              attrName={["emergency_number"]}
              value_update={fieldsUpdateState}
              warn_status={proposerFields?.emergency_number?.warning}
              max_length={10}
              validation_type="NUMBER"
              error_message={
                isEmpty(proposerFields.emergency_number.value)
                  ? "Enter Emergency Phone Number "
                  : "Enter Valid Emergency Phone Number"
              }
            />
          </Grid>
        </Grid>
        <MNonTermFooter loader={QUOTE_LOADER} forward={validateForm} />
      </Box>

      {/* OTP Modal start */}
      <Modal open={showOTPPopup} className="modalWrapper">
        <Box className="modalContent xsWidth kycPopups">
          <Grid container spacing={2}>
            <Grid xs={12} textAlign="center">
              <img
                alt=""
                src="../images/enter-otp.svg"
                width="160px"
                className="mt-6"
              />
              <h4 className="popup-heading mb-2 mt-6">Enter OTP</h4>
              <p className="grey_p">Enter the OTP to proceed</p>
              <Link
                to={""}
                className="close-button"
                onClick={() => {
                  setShowOTPPopup(false);
                  setOtpValue("");
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} textAlign="center">
            <Grid xs={8} xsOffset={2}>
              <TextField
                className="inputField"
                placeholder="Enter OTP"
                value={otpValue}
                onChange={(e) => {
                  const input = e.target.value;
                  if (/^\d{0,6}$/.test(input)) {
                    handleUpdateOTP("otpValue", input);
                  }
                }}
                type="tel"
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={3} marginTop={"24px"}>
            <Grid xs={12} style={{ textAlign: "center" }}>
              <CustomButton
                text_name="Verify"
                class_name="primaryBtn mb-3"
                size="large"
                onClickFunction={VERIFY_OTP}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* OTP Modal End */}
    </>
  );
};

export default MNonTermProposal;
