import SentimentDissatisfiedOutlinedIcon from "@mui/icons-material/SentimentDissatisfiedOutlined";
import { Box, Button, Grid, Link, Modal } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { ECompanyCode } from "../../../../../Services/Enum/EHome";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";
import { kycResponseSlice } from "../../../../../redux/slice/HI/KycResponse/KycResponseSlice";
import { HI_PATH } from "../../../../../Router/Path/HIPath";
import CustomButton from "../../../../CustomButton/CustomButton";

interface Props {
  modalOpen: boolean;
  setModalOpen: any;
  pageStatus?: number;
  setPageStatus?: any;
  subRoutes?: Array<string>;
}

const KycSuccessPopup = ({
  modalOpen,
  setModalOpen,
  pageStatus,
  setPageStatus,
  subRoutes,
}: Props) => {
  const kycRespState = useAppSelector((state) => state.kycResponseSlice);
  const { CompanyDetails } = useAppSelector(
    (state) => state.proposalInformation.policy_information
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  function formatDateString(inputDateStr: any) {
    // Split the input date string by '/'
    const [year, month, day] = inputDateStr.split("/");

    // Return the formatted date string as "DD/MM/YYYY"
    return `${day}/${month}/${year}`;
  }
  return (
    <Modal open={modalOpen} className="modalWrapper">
      <Box className="modalInner xl-width">
        <Grid container spacing={2} textAlign="center">
          <Grid xs={12}>
            <h4 className="popup-heading my-2">CKYC Details</h4>
            <p className="grey_p">
              We fetch these details as per record, please verify
            </p>
            <Link
              className="close-button"
              onClick={() => {
                setModalOpen(false);
              }}
            />
          </Grid>

          <Grid xs={12}>
            <span className="policy_number_box my-4">
              {CompanyDetails.company_code === ECompanyCode.ICICI
                ? kycRespState.iciciKYCId
                : kycRespState.digitKYCId}
            </span>
          </Grid>
          <Grid xs={6}>
            <h6 style={{ textAlign: "center" }}>
              Full Name:
              <span>
                {kycRespState.first_name +
                  " " +
                  (kycRespState.middle_name && kycRespState.middle_name !== null
                    ? kycRespState.middle_name
                    : "") +
                  " " +
                  (kycRespState.last_name && kycRespState.last_name !== null
                    ? kycRespState.last_name
                    : "")}
              </span>
            </h6>
          </Grid>
          <Grid xs={6}>
            <h6 style={{ textAlign: "center" }}>
              DOB:
              <span>
                {kycRespState.digitKYCId
                  ? (() => {
                      const dob: any = kycRespState.dob
                        ? kycRespState.dob
                        : kycRespState.dob_or_doi;
                      const date = new Date(dob as any);
                      const month = ("0" + (date.getMonth() + 1)).slice(-2);
                      const day = ("0" + date.getDate()).slice(-2);
                      const year = date.getFullYear();
                      return `${day}/${month}/${year}`;
                    })()
                  : kycRespState.dob.toString()}
              </span>
            </h6>
          </Grid>
          {!isEmpty(kycRespState.address_line_1) ? (
            <Grid xs={12}>
              <h6 style={{ textAlign: "center" }}>
                Address:
                <span>
                  {kycRespState.address_line_1 +
                    " " +
                    kycRespState.address_line_2 +
                    ", " +
                    kycRespState.city +
                    ", " +
                    kycRespState.state}
                </span>
              </h6>
            </Grid>
          ) : null}
          <Grid
            xs={6}
            style={{ textAlign: "center", marginTop: "20px" }}
            className="ctaBtn"
          >
            {/* <Button
              variant="contained"
              className="regularSecondaryBtn"
              onClick={() => {
                dispatch(kycResponseSlice.actions.reset());
                setModalOpen(false);
              }}
            >
              <SentimentDissatisfiedOutlinedIcon className="mr-2" /> It's not me
            </Button> */}
            <CustomButton
              text_name={
                <>
                  {" "}
                  <SentimentDissatisfiedOutlinedIcon className="mr-2" /> It's
                  not me
                </>
              }
              class_name="secondaryBtn"
              size="large"
              onClickFunction={() => {
                dispatch(kycResponseSlice.actions.reset());
                setModalOpen(false);
              }}
            />
          </Grid>
          <Grid xs={6} style={{ textAlign: "center", marginTop: "20px" }}>
            <CustomButton
              text_name="Continue"
              class_name="primaryBtn"
              size="large"
              onClickFunction={() => {
                navigate(`${HI_PATH.Proposal}/${HI_PATH.ADD_ONS}`);
                setModalOpen(false);
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default KycSuccessPopup;
