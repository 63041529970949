import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Navbar from "../../../../../Component/Navbar/Navbar";
import { ETermCompanyCode } from "../../../../../Enum/ETermCompanyCode";
import { useAppSelector } from "../../../../../redux/hooks";
import {
  formatToCurrency,
  isEmpty,
} from "../../../../../SupportingFiles/HelpingFunction";
import "./PaymentConfirmation.scss";
import CustomButton from "../../../../../Component/CustomButton/CustomButton";

function PaymentConfirmation({
  payment_status,
  continue_function,
}: {
  payment_status: "SUCCESS" | "FAIL" | "PENDING";
  continue_function: Function;
}) {
  const { proposerDetails } = useAppSelector((state) => state.TermFullJourney);
  const { SELECTED_QUOTE_DATA, ADD_FORM } = useAppSelector(
    (state) => state.Term
  );
  return (
    <Box className="paymentWrapper">
      {/* Navbar */}
      <Navbar />

      <Grid container spacing={3}>
        <Grid xs={12} className="my-4" textAlign="center">
          <img src="./images/policy-confirmation.svg" alt="" height="140px" />
        </Grid>
        <Grid xs={12} textAlign="center">
          <h3>Payment Confirmation</h3>
          <p className="mb-0">
            {payment_status === "SUCCESS"
              ? "Great !! Congratulations on buying your investment."
              : "Payment received! We apologize for any inconvenience caused by the issue you encountered."}
          </p>
        </Grid>
        <Grid xs={12} textAlign="center">
          <p className="mb-0 paymentDetail_p">
            Hi, {`${proposerDetails.full_name.value}`}
          </p>
          <p className="paymentDetail_p">
            {`${proposerDetails.gender.value}` === "M" ? "Male" : "Female"},{" "}
            {ADD_FORM.age.value} Years
          </p>
        </Grid>
        <Grid xs={12}>
          <>
            <Grid container spacing={3} className="paymentPolicy_box">
              <Grid xs={6}>
                <p style={{ textAlign: "left" }}>
                  {SELECTED_QUOTE_DATA.productDetails.product_name}
                </p>
              </Grid>
              <Grid xs={6}>
                <p style={{ textAlign: "right" }}>
                  Transaction ID :-{" "}
                  {!isEmpty(SELECTED_QUOTE_DATA.transaction_id)
                    ? SELECTED_QUOTE_DATA.transaction_id
                    : SELECTED_QUOTE_DATA.appno}
                </p>
              </Grid>
              <Grid xs={2.4} textAlign="left">
                <img
                  src={SELECTED_QUOTE_DATA.companyDetails.logo}
                  alt=""
                  style={{ width: "90px" }}
                />
              </Grid>
              <Grid xs={2.4}>
                <p>
                  Sum Assured
                  <span>
                    {formatToCurrency(
                      Number(SELECTED_QUOTE_DATA.premiumDetails.sum_assured)
                    )}
                  </span>
                </p>
              </Grid>
              <Grid xs={2.4}>
                <p>
                  Policy Term
                  <span>{SELECTED_QUOTE_DATA.premiumDetails.term} Years</span>
                </p>
              </Grid>
              <Grid xs={2.4}>
                <p>
                  Pay Term
                  <span>
                    {SELECTED_QUOTE_DATA.premiumDetails.pay_term} Years
                  </span>
                </p>
              </Grid>
              <Grid xs={2.4}>
                <p style={{ textAlign: "right" }}>
                  Application No.
                  <span>{SELECTED_QUOTE_DATA.appno}</span>
                </p>
              </Grid>
            </Grid>
          </>
        </Grid>
        <Grid xs={12} className="mt-6">
          <ul className="steps">
            <li className="activeLi">
              <span className="activebg">1</span>Basic Details
            </li>
            <li className="activeLi">
              <span className="activebg">2</span>Payment Done
            </li>
            <li>
              <span>3</span>Nominee Details
            </li>
            <li>
              <span>4</span>Address Details
            </li>
            <li>
              <span>5</span>CKYC Details
            </li>
            <li>
              <span>6</span>Underwriting Details
            </li>
            <li>
              <span>7</span>Preview Details
            </li>
            {SELECTED_QUOTE_DATA.companyDetails.company_code ===
            ETermCompanyCode.HDFC ? (
              <li>
                <span>8</span>Upload Document
              </li>
            ) : null}
          </ul>
        </Grid>
        <Grid xs={12} textAlign="center">
          <div className="ctaBtn">
            {payment_status === "SUCCESS" ? (
              // <Button
              //   className="regularSecondaryBtn"
              //   onClick={() => continue_function()}>
              //   Continue Application
              // </Button>
              <CustomButton
                text_name={"Continue Application"}
                class_name="secondaryBtn"
                size="large"
                onClickFunction={() => continue_function()}
              />
            ) : null}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PaymentConfirmation;
