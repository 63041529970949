import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MedicalDetailsRSA from "../../../../Page/Desktop/health-php/RSA/MedicalDetailsRSA/MedicalDetailsRSA";
import MMedicalDetailsRSA from "../../../../Page/Mobile/health-php/RSA/MMedicalDetailsRSA/MMedicalDetailsRSA";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/health-php/HealthPropsalServices";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { HealthSlice } from "../../../../redux/slice/Health/HealthSlice";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { THealthQuote } from "../../../../types/Health/HealthQuotation/THealthQuote";
import { TKotakMedicalQuestion } from "../../../../types/Health/HealthQuotation/TKotakMedicalQuestion";
import { TRSAMedicalQuestion } from "../../../../types/Health/HealthQuotation/TRSAMedicalQuestion";

const MedicalDetailsContainerRSA = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [userType, setUserType] = useState<string>("");
  const [utm_medium, setUtm_medium] = useState<string>("");
  const [utm_source, setUtm_source] = useState<string>("");

  useEffect(() => {
    // Access the query parameters directly from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const userTypeFromQuery = searchParams.get("user_type");
    const utm_mediumFromQuery = searchParams.get("utm_medium");
    const utm_sourceFromQuery = searchParams.get("utm_source");
    setUtm_medium(`${utm_mediumFromQuery}`);
    setUtm_source(`${utm_sourceFromQuery}`);
    setUserType(
      atob(`${userTypeFromQuery}`) === "agent" ? btoa("agent") : btoa("user")
    );
  }, []);
  const dispatch = useAppDispatch();
  const {
    PAGE_STATUS,
    MEDICAL_QUESTION_DATA_RSA,
    SELECTED_QUOTE_DATA,
    ADD_FORM_RESPONSE,
    Preview_Loader,
    INSURED_MEMBER_DETAILS,
  } = useAppSelector((state) => state.Health);
  const [medicalQuestionData, setMedicalQuestionData] =
    useState<TRSAMedicalQuestion>(MEDICAL_QUESTION_DATA_RSA);

  const setMemberDataAsPerUserSelection = () => {
    let setMemberData: any = { ...MEDICAL_QUESTION_DATA_RSA };
    setMemberData = {
      MQ01: {
        main_question: {
          id: "MQ01",
          icon_class: "tobaco",
          title: "Medical History",
          description:
            "Within the last 2 years have you consulted a doctor or healthcare professional? (other than Preventive or Pre-Employment Health Check-up)?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ02: {
        main_question: {
          id: "MQ02",
          icon_class: "tobaco",
          title: "medicalMedicationDiseases",
          description:
            "Health Check-up(Within the last 2 years have you undergone any detailed investigation other than Preventive or Pre-Employment Health Check-up (e.g. X-ray, CT Scan, biopsy, MRI, Sonography, etc)?)",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ03: {
        main_question: {
          id: "MQ03",
          icon_class: "Cancer",
          title: "Medical Treatment",
          description:
            "Within the last 5 years have you been to a hospital for an operation/medical treatment?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ04: {
        main_question: {
          id: "MQ04",
          icon_class: "tobaco",
          title: "Medicines",
          description:
            "Do you take tablets, medicines or drugs on a regular basis?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ05: {
        main_question: {
          id: "MQ05",
          icon_class: "tobaco",
          title: "Medical conditions",
          description:
            "Within the last 3 months have you experienced any health problems or medical conditions which you/proposed insured person have/has not seen a doctor for?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ06: {
        main_question: {
          id: "MQ06",
          icon_class: "tobaco",
          title: "Hospitalized",
          description:
            "Have any of the person(s) proposed to be insured ever suffered from or taken treatment, or been hospitalized for or have been recommended to undergo / take investigations / medication / surgery or undergone a surgery for any of the following - Diabetes; Hypertension; Ulcer / Cyst / Cancer; Cardiac Disorder; Kidney or Urinary Tract Disorder; Disorder of muscle / bone / joint; Respiratory disorder; Digestive tract or gastrointestinal disorder; Nervous System disorder; Mental Illness or disorder, HIV or AIDS?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Name of Illness/Injury",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Medication recieved/recieving",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Month of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Year of Diagnosis",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Treatment Outcome",
                  field_type: "DROPDOWN",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ07: {
        main_question: {
          id: "MQ07",
          icon_class: "tobaco",
          title: "Alcohol",
          description:
            "Do you consume Alcohol(Beer - No. of Pints/week, Wine & Spirit - ml/week)?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ08: {
        main_question: {
          id: "MQ08",
          icon_class: "tobaco",
          title: "Smoking",
          description: "Do you smoke?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ09: {
        main_question: {
          id: "MQ09",
          icon_class: "tobaco",
          title: "Tobacco",
          description: "Do you consume Tobacco/Gutka/Pan/Pan masala?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ10: {
        main_question: {
          id: "MQ10",
          icon_class: "tobaco",
          title: "Narcotics",
          description: "Do you consume Narcotics?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
    };
    setMedicalQuestionData({ ...setMemberData });
  };
  console.log("medicalQuestionData", medicalQuestionData);

  useEffect(() => {
    if (PAGE_STATUS === true || Preview_Loader === true) {
      setMedicalQuestionData(MEDICAL_QUESTION_DATA_RSA);
    } else {
      setMemberDataAsPerUserSelection();
    }
  }, [MEDICAL_QUESTION_DATA_RSA]);
  let flag = false;

  const updateMasterState = (attrName: any, value: any) => {
    // debugger
    flag = value;
    // console.log('sss',flag)
    console.log("attrName", attrName, value);
    let manipulatedMedicalQuestion: any = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    if (attrName[0] === "question") {
      // debugger;
      manipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question,
            status: value,
          },
        },
      };

      if (value === false) {
        // Update selection_status of members
        manipulatedMedicalQuestion[attrName[1]].main_question.member_data = {
          ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data,
          adult_one: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
              .adult_one,
            selection_status: false,
          },
          adult_two: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
              .adult_two,
            selection_status: false,
          },
          child_one: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
              .child_one,
            selection_status: false,
          },
          child_two: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
              .child_two,
            selection_status: false,
          },
          child_three: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
              .child_three,
            selection_status: false,
          },
          child_four: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
              .child_four,
            selection_status: false,
          },
        };
      }

      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    } else if (attrName[0] === "mainMember") {
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data[
          attrName[2]
        ] !== undefined
      )
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              member_data: {
                ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                  .member_data,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    ?.member_data?.[attrName[2]],
                  selection_status: value,
                },
              },
            },
          },
        };

      setMedicalQuestionData(manipulatedMedicalQuestion);
    } else if (attrName[0] === "mainMemberDataField") {
      manipulatedMedicalQuestion[attrName[1]].main_question.member_data[
        attrName[2]
      ].field_data[attrName[3]].value = {
        value: value,
        warning: isEmpty(value),
      };

      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    }
  };

  // const updateMasterState = (attrName: any, value: any) => {
  //   // debugger
  //   flag = value;
  //   // console.log('sss',flag)
  //   console.log("attrName",attrName, value);
  //   let manipulatedMedicalQuestion: any = JSON.parse(
  //     JSON.stringify(medicalQuestionData)
  //   );
  //   if (attrName[0] === "question") {
  //     // debugger;
  //     manipulatedMedicalQuestion = {
  //       ...manipulatedMedicalQuestion,
  //       [attrName[1]]: {
  //         ...manipulatedMedicalQuestion[attrName[1]],
  //         main_question: {
  //           ...manipulatedMedicalQuestion[attrName[1]].main_question,
  //           status: value,
  //         },
  //       },
  //     };
  //     // if (value === false) {
  //     //   // Update selection_status of members
  //     //   manipulatedMedicalQuestion[attrName[1]].main_question.member_data = {
  //     //     ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data,
  //     //     adult_one: {
  //     //       ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
  //     //         .adult_one,
  //     //       selection_status: false,
  //     //     },
  //     //     adult_two: {
  //     //       ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
  //     //         .adult_two,
  //     //       selection_status: false,
  //     //     },
  //     //     child_one: {
  //     //       ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
  //     //         .child_one,
  //     //       selection_status: false,
  //     //     },
  //     //     child_two: {
  //     //       ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
  //     //         .child_two,
  //     //       selection_status: false,
  //     //     },
  //     //     child_three: {
  //     //       ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
  //     //         .child_three,
  //     //       selection_status: false,
  //     //     },
  //     //     child_four: {
  //     //       ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
  //     //         .child_four,
  //     //       selection_status: false,
  //     //     },
  //     //   };
  //     // }

  //     setMedicalQuestionData({ ...manipulatedMedicalQuestion });
  //   } else if (attrName[0] === "subQuestion") {
  //     // debugger;
  //     setchange_status(flag);

  //     let updatedSubQuestion = {
  //       ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data,
  //       [attrName[2]]: {
  //         ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data[
  //           attrName[2]
  //         ],
  //         status: value,
  //       },
  //     };
  //     let updatedMainQuestion = {
  //       ...manipulatedMedicalQuestion[attrName[1]].main_question,
  //       member_data: updatedSubQuestion,
  //     };

  //     let updatedManipulatedMedicalQuestion = {
  //       ...manipulatedMedicalQuestion,
  //       [attrName[1]]: {
  //         ...manipulatedMedicalQuestion[attrName[1]],
  //         main_question: updatedMainQuestion,
  //       },
  //     };

  //     setMedicalQuestionData(updatedManipulatedMedicalQuestion);
  //   } else if (attrName[0] === "mainMember") {
  //     // debugger;
  //     console.log("attrName[3]1",attrName[3]);

  //     if (manipulatedMedicalQuestion[attrName[1]]?.main_question.member_data[attrName[2]]?.member_data[attrName[3]] !== undefined) {
  //       manipulatedMedicalQuestion = {
  //         ...manipulatedMedicalQuestion,
  //         [attrName[1]]: {
  //           ...manipulatedMedicalQuestion[attrName[1]],
  //           main_question: {
  //             ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
  //             member_data: {
  //               ...manipulatedMedicalQuestion[attrName[1]].main_question
  //                 .member_data,
  //               [attrName[2]]: {
  //                 ...manipulatedMedicalQuestion[attrName[1]]?.main_question
  //                   .member_data?.[attrName[2]],
  //                 member_data: {
  //                   ...manipulatedMedicalQuestion[attrName[1]]?.main_question
  //                     .member_data?.[attrName[2]].member_data,
  //                   [attrName[3]]: {
  //                     ...manipulatedMedicalQuestion[attrName[1]]?.main_question
  //                       .member_data?.[attrName[2]].member_data?.[attrName[3]],
  //                     selection_status: value,
  //                   },
  //                 },
  //               },
  //             },
  //           },
  //         },
  //       };
  //       setMedicalQuestionData({ ...manipulatedMedicalQuestion });
  //     }
  //   } else if (attrName[0] === "mainMemberData") {
  //     // debugger;
  //     if (
  //       manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data[attrName[2]] !== undefined)
  //       manipulatedMedicalQuestion = {
  //         ...manipulatedMedicalQuestion,
  //         [attrName[1]]: {
  //           ...manipulatedMedicalQuestion[attrName[1]],
  //           main_question: {
  //             ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
  //             member_data: {
  //               ...manipulatedMedicalQuestion[attrName[1]]?.main_question
  //                 .member_data,
  //               [attrName[2]]: {
  //                 ...manipulatedMedicalQuestion[attrName[1]]?.main_question
  //                   ?.member_data?.[attrName[2]],
  //                 selection_status: value,
  //               },
  //             },
  //           },
  //         },
  //       };

  //     setMedicalQuestionData(manipulatedMedicalQuestion);
  //   } else if (attrName[0] === "mainMemberDataField" && attrName[1] === "MQ02") {
  //     // debugger;
  //     if (
  //       attrName[2] === "adult_one" ||
  //       attrName[2] === "adult_two" ||
  //       attrName[2] === "child_one" ||
  //       attrName[2] === "child_two" ||
  //       attrName[2] === "child_three" ||
  //       (attrName[2] === "child_four" &&
  //         manipulatedMedicalQuestion !== undefined)
  //     ) {
  //       manipulatedMedicalQuestion[attrName[1]].main_question.member_data[
  //         attrName[4]
  //       ].member_data[attrName[2]].field_data[attrName[3]].value = {
  //         value: value,
  //         warning: isEmpty(value),
  //       };

  //       setMedicalQuestionData({ ...manipulatedMedicalQuestion });
  //     }
  //   } else if (attrName[0] === "mainMemberDataField" && attrName[1] !== "MQ02") {
  //     if ( attrName[2] === "adult_one" ||attrName[2] === "adult_two" ||attrName[2] === "child_one" ||attrName[2] === "child_two" || attrName[2] === "child_three" ||
  //       (attrName[2] === "child_four" && manipulatedMedicalQuestion !== undefined)
  //     ) {
  //       manipulatedMedicalQuestion[attrName[1]].main_question.member_data[attrName[2]].field_data["MQ1-SQ1-F1"].value = {
  //         value: value,
  //         warning: isEmpty(value),
  //     }

  //     setMedicalQuestionData({ ...manipulatedMedicalQuestion });
  //     };

  //   }
  // };

  const validateForm = () => {
    if (!medicalQuestionData) {
      return; // Exit early if medicalQuestionData is null or undefined
    }
    let data: TKotakMedicalQuestion = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    let hasError = false;

    Object.values(data).forEach((element) => {
      const mainQuestion = element?.main_question;
      if (mainQuestion && mainQuestion.status) {
        const memberData = mainQuestion.member_data;
        if (memberData) {
          Object.values(memberData).forEach((member) => {
            if (member.selection_status && member.field_data) {
              Object.values(member.field_data).forEach((field) => {
                if (field.value?.value === "") {
                  field.value = {
                    ...field.value,
                    warning: true,
                  };
                  hasError = true;
                }
              });
            }
          });
        }
      }
    });
    setMedicalQuestionData(data);
    console.log("hasError", hasError);
    if (!hasError) {
      dispatch(HealthSlice.actions.SAVE_MEDICAL_QUESTION_DATA_RSA(data));
      const onSuccess = (res: any) => {
        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
        if (res.results.error === false) {
          let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
          quoteData = {
            ...quoteData,
            premiumDetails: {
              ...quoteData.premiumDetails,
              finalPremium: res.results.response.premiumDetails.finalPremium,
            },
          };
          dispatch(HealthSlice.actions.SAVE_SELECTED_QUOTE_DATA(quoteData));
        }
      };
      const onError = (err: any) => {
        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
        console.log("err..", err);
      };
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
      const param = {
        quote_no: ADD_FORM_RESPONSE.quote_no,
        company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
        product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
        medical_info: "Yes",
        mainConsultedDoctorDiesease:
          data.MQ01.main_question.status === true ? "Yes" : "No", //Are you currently in good mental and physical health
        selfConsultedDoctorDiesease:
          data.MQ01.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",

        selfConsultedDieseaseName:
          data.MQ01.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        selfConsultedDieseaseReceive:
          data.MQ01.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        selfConsultedDieseaseMonth:
          data.MQ01.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        selfConsultedDieseaseYear:
          data.MQ01.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        selfConsultedDieseaseOutcome:
          data.MQ01.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        spouseConsultedDoctorDiesease:
          data.MQ01.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",

        spouseConsultedDieseaseName:
          data.MQ01.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouseConsultedDieseaseReceive:
          data.MQ01.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        spouseConsultedDieseaseMonth:
          data.MQ01.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        spouseConsultedDieseaseYear:
          data.MQ01.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        spouseConsultedDieseaseOutcome:
          data.MQ01.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child1ConsultedDoctorDiesease:
          data.MQ01.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",

        child1ConsultedDieseaseName:
          data.MQ01.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child1ConsultedDieseaseReceive:
          data.MQ01.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child1ConsultedDieseaseMonth:
          data.MQ01.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child1ConsultedDieseaseYear:
          data.MQ01.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child1ConsultedDieseaseOutcome:
          data.MQ01.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child2ConsultedDoctorDiesease:
          data.MQ01.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",

        child2ConsultedDieseaseName:
          data.MQ01.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child2ConsultedDieseaseReceive:
          data.MQ01.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child2ConsultedDieseaseMonth:
          data.MQ01.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child2ConsultedDieseaseYear:
          data.MQ01.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child2ConsultedDieseaseOutcome:
          data.MQ01.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child3ConsultedDoctorDiesease:
          data.MQ01.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",

        child3ConsultedDieseaseName:
          data.MQ01.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child3ConsultedDieseaseReceive:
          data.MQ01.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child3ConsultedDieseaseMonth:
          data.MQ01.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child3ConsultedDieseaseYear:
          data.MQ01.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child3ConsultedDieseaseOutcome:
          data.MQ01.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child4ConsultedDoctorDiesease:
          data.MQ01.main_question.member_data?.child_four.selection_status ===
          true
            ? "Yes"
            : "No",

        child4ConsultedDieseaseName:
          data.MQ01.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child4ConsultedDieseaseReceive:
          data.MQ01.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child4ConsultedDieseaseMonth:
          data.MQ01.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child4ConsultedDieseaseYear:
          data.MQ01.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child4ConsultedDieseaseOutcome:
          data.MQ01.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        mainDetailInvestigationDiesease:
          data.MQ02.main_question.status === true ? "Yes" : "No", //Are you currently in good mental and physical health
        selfDetailInvestigationDiesease:
          data.MQ02.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",

        selfDetailInvestigationDieseaseName:
          data.MQ02.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        selfDetailInvestigationDieseaseReceive:
          data.MQ02.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        selfDetailInvestigationDieseaseMonth:
          data.MQ02.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        selfDetailInvestigationDieseaseYear:
          data.MQ02.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        selfDetailInvestigationDieseaseOutcome:
          data.MQ02.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        spouseDetailInvestigationDiesease:
          data.MQ02.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",

        spouseDetailInvestigationDieseaseName:
          data.MQ02.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouseDetailInvestigationDieseaseReceive:
          data.MQ02.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        spouseDetailInvestigationDieseaseMonth:
          data.MQ02.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        spouseDetailInvestigationDieseaseYear:
          data.MQ02.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        spouseDetailInvestigationDieseaseOutcome:
          data.MQ02.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child1DetailInvestigationDiesease:
          data.MQ02.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",

        child1DetailInvestigationDieseaseName:
          data.MQ02.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child1DetailInvestigationDieseaseReceive:
          data.MQ02.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child1DetailInvestigationDieseaseMonth:
          data.MQ02.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child1DetailInvestigationDieseaseYear:
          data.MQ02.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child1DetailInvestigationDieseaseOutcome:
          data.MQ02.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child2DetailInvestigationDiesease:
          data.MQ02.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",

        child2DetailInvestigationDieseaseName:
          data.MQ02.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child2DetailInvestigationDieseaseReceive:
          data.MQ02.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child2DetailInvestigationDieseaseMonth:
          data.MQ02.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child2DetailInvestigationDieseaseYear:
          data.MQ02.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child2DetailInvestigationDieseaseOutcome:
          data.MQ02.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child3DetailInvestigationDiesease:
          data.MQ02.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",

        child3DetailInvestigationDieseaseName:
          data.MQ02.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child3DetailInvestigationDieseaseReceive:
          data.MQ02.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child3DetailInvestigationDieseaseMonth:
          data.MQ02.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child3DetailInvestigationDieseaseYear:
          data.MQ02.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child3DetailInvestigationDieseaseOutcome:
          data.MQ02.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child4DetailInvestigationDiesease:
          data.MQ02.main_question.member_data?.child_four.selection_status ===
          true
            ? "Yes"
            : "No",

        child4DetailInvestigationDieseaseName:
          data.MQ02.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child4DetailInvestigationDieseaseReceive:
          data.MQ02.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child4DetailInvestigationDieseaseMonth:
          data.MQ02.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child4DetailInvestigationDieseaseYear:
          data.MQ02.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child4DetailInvestigationDieseaseOutcome:
          data.MQ02.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        mainMedicalTreatmentDiesease:
          data.MQ03.main_question.status === true ? "Yes" : "No", //Any hospitalization in the past Yes / No
        selfMedicalTreatmentDiesease:
          data.MQ03.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",

        selfMedicalTreatmentDieseaseName:
          data.MQ03.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        selfMedicalTreatmentDieseaseReceive:
          data.MQ03.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        selfMedicalTreatmentDieseaseMonth:
          data.MQ03.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        selfMedicalTreatmentDieseaseYear:
          data.MQ03.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        selfMedicalTreatmentDieseaseOutcome:
          data.MQ03.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        spouseMedicalTreatmentDiesease:
          data.MQ03.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",

        spouseMedicalTreatmentDieseaseName:
          data.MQ03.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouseMedicalTreatmentDieseaseReceive:
          data.MQ03.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        spouseMedicalTreatmentDieseaseMonth:
          data.MQ03.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        spouseMedicalTreatmentDieseaseYear:
          data.MQ03.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        spouseMedicalTreatmentDieseaseOutcome:
          data.MQ03.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child1MedicalTreatmentDiesease:
          data.MQ03.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",

        child1MedicalTreatmentDieseaseName:
          data.MQ03.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child1MedicalTreatmentDieseaseReceive:
          data.MQ03.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child1MedicalTreatmentDieseaseMonth:
          data.MQ03.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child1MedicalTreatmentDieseaseYear:
          data.MQ03.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child1MedicalTreatmentDieseaseOutcome:
          data.MQ03.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child2MedicalTreatmentDiesease:
          data.MQ03.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",

        child2MedicalTreatmentDieseaseName:
          data.MQ03.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child2MedicalTreatmentDieseaseReceive:
          data.MQ03.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child2MedicalTreatmentDieseaseMonth:
          data.MQ03.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child2MedicalTreatmentDieseaseYear:
          data.MQ03.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child2MedicalTreatmentDieseaseOutcome:
          data.MQ03.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child3MedicalTreatmentDiesease:
          data.MQ03.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",

        child3MedicalTreatmentDieseaseName:
          data.MQ03.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child3MedicalTreatmentDieseaseReceive:
          data.MQ03.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child3MedicalTreatmentDieseaseMonth:
          data.MQ03.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child3MedicalTreatmentDieseaseYear:
          data.MQ03.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child3MedicalTreatmentDieseaseOutcome:
          data.MQ03.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child4MedicalTreatmentDiesease:
          data.MQ03.main_question.member_data?.child_four.selection_status ===
          true
            ? "Yes"
            : "No",

        child4MedicalTreatmentDieseaseName:
          data.MQ03.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child4MedicalTreatmentDieseaseReceive:
          data.MQ03.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child4MedicalTreatmentDieseaseMonth:
          data.MQ03.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child4MedicalTreatmentDieseaseYear:
          data.MQ03.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child4MedicalTreatmentDieseaseOutcome:
          data.MQ03.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        mainRegularBasicMedicineDiesease:
          data.MQ04.main_question.status === true ? "Yes" : "No", //Any hospitalization in the past Yes / No
        selfRegularBasicMedicineDiesease:
          data.MQ04.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",

        selfRegularBasicDieseaseName:
          data.MQ04.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        selfRegularBasicDieseaseReceive:
          data.MQ04.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        selfRegularBasicDieseaseMonth:
          data.MQ04.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        selfRegularBasicDieseaseYear:
          data.MQ04.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        selfRegularBasicDieseaseOutcome:
          data.MQ04.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        spouseRegularBasicMedicineDiesease:
          data.MQ04.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",

        spouseRegularBasicDieseaseName:
          data.MQ04.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouseRegularBasicDieseaseReceive:
          data.MQ04.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        spouseRegularBasicDieseaseMonth:
          data.MQ04.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        spouseRegularBasicDieseaseYear:
          data.MQ04.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        spouseRegularBasicDieseaseOutcome:
          data.MQ04.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child1RegularBasicMedicineDiesease:
          data.MQ04.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",

        child1RegularBasicDieseaseName:
          data.MQ04.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child1RegularBasicDieseaseReceive:
          data.MQ04.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child1RegularBasicDieseaseMonth:
          data.MQ04.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child1RegularBasicDieseaseYear:
          data.MQ04.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child1RegularBasicDieseaseOutcome:
          data.MQ04.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child2RegularBasicMedicineDiesease:
          data.MQ04.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",

        child2RegularBasicDieseaseName:
          data.MQ04.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child2RegularBasicDieseaseReceive:
          data.MQ04.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child2RegularBasicDieseaseMonth:
          data.MQ04.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child2RegularBasicDieseaseYear:
          data.MQ04.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child2RegularBasicDieseaseOutcome:
          data.MQ04.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child3RegularBasicMedicineDiesease:
          data.MQ04.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",

        child3RegularBasicDieseaseName:
          data.MQ04.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child3RegularBasicDieseaseReceive:
          data.MQ04.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child3RegularBasicDieseaseMonth:
          data.MQ04.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child3RegularBasicDieseaseYear:
          data.MQ04.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child3RegularBasicDieseaseOutcome:
          data.MQ04.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child4RegularBasicMedicineDiesease:
          data.MQ04.main_question.member_data?.child_four.selection_status ===
          true
            ? "Yes"
            : "No",

        child4RegularBasicDieseaseName:
          data.MQ04.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child4RegularBasicDieseaseReceive:
          data.MQ04.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child4RegularBasicDieseaseMonth:
          data.MQ04.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child4RegularBasicDieseaseYear:
          data.MQ04.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child4RegularBasicDieseaseOutcome:
          data.MQ04.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        mainLastMedialTreatmentDiesease:
          data.MQ05.main_question.status === true ? "Yes" : "No", //Any hospitalization in the past Yes / No
        selfLastMedialTreatmentDiesease:
          data.MQ05.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        selfLastMedialTreatmentDieseaseName:
          data.MQ05.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        selfLastMedialTreatmentDieseaseReceive:
          data.MQ05.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        selfLastMedialTreatmentDieseaseMonth:
          data.MQ05.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        selfLastMedialTreatmentDieseaseYear:
          data.MQ05.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        selfLastMedialTreatmentDieseaseOutcome:
          data.MQ05.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        spouseLastMedialTreatmentDiesease:
          data.MQ05.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",

        adult2LastMedialTreatmentDieseaseName:
          data.MQ05.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        adult2LastMedialTreatmentDieseaseReceive:
          data.MQ05.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        adult2LastMedialTreatmentDieseaseMonth:
          data.MQ05.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        adult2LastMedialTreatmentDieseaseYear:
          data.MQ05.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        adult2LastMedialTreatmentDieseaseOutcome:
          data.MQ05.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child1LastMedialTreatmentDiesease:
          data.MQ05.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",

        child1LastMedialTreatmentDieseaseName:
          data.MQ05.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child1LastMedialTreatmentDieseaseReceive:
          data.MQ05.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child1LastMedialTreatmentDieseaseMonth:
          data.MQ05.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child1LastMedialTreatmentDieseaseYear:
          data.MQ05.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child1LastMedialTreatmentDieseaseOutcome:
          data.MQ05.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child2LastMedialTreatmentDiesease:
          data.MQ05.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",

        child2LastMedialTreatmentDieseaseName:
          data.MQ05.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child2LastMedialTreatmentDieseaseReceive:
          data.MQ05.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child2LastMedialTreatmentDieseaseMonth:
          data.MQ05.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child2LastMedialTreatmentDieseaseYear:
          data.MQ05.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child2LastMedialTreatmentDieseaseOutcome:
          data.MQ05.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child3fLastMedialTreatmentDiesease:
          data.MQ05.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",

        child3LastMedialTreatmentDieseaseName:
          data.MQ05.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child3LastMedialTreatmentDieseaseReceive:
          data.MQ05.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child3LastMedialTreatmentDieseaseMonth:
          data.MQ05.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child3LastMedialTreatmentDieseaseYear:
          data.MQ05.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child3LastMedialTreatmentDieseaseOutcome:
          data.MQ05.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child4fLastMedialTreatmentDiesease:
          data.MQ05.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",

        child4LastMedialTreatmentDieseaseName:
          data.MQ05.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child4LastMedialTreatmentDieseaseReceive:
          data.MQ05.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child4LastMedialTreatmentDieseaseMonth:
          data.MQ05.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child4LastMedialTreatmentDieseaseYear:
          data.MQ05.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child4LastMedialTreatmentDieseaseOutcome:
          data.MQ05.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        mainAnyPedDiesease:
          data.MQ06.main_question.status === true ? "Yes" : "No", //Any hospitalization in the past Yes / No
        selfAnyPedDiesease:
          data.MQ06.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        selfAnyPedDieseaseName:
          data.MQ06.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        selfAnyPedDieseaseReceive:
          data.MQ06.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        selfAnyPedDieseaseMonth:
          data.MQ06.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        selfAnyPedDieseaseYear:
          data.MQ06.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        selfAnyPedDieseaseOutcome:
          data.MQ06.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        spouseAnyPedDiesease:
          data.MQ06.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",

        spouseAnyPedDieseaseName:
          data.MQ06.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouseAnyPedDieseaseReceive:
          data.MQ06.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        spouseAnyPedDieseaseMonth:
          data.MQ06.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        spouseAnyPedDieseaseYear:
          data.MQ06.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        spouseAnyPedDieseaseOutcome:
          data.MQ06.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child1AnyPedDiesease:
          data.MQ06.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",

        child1AnyPedDieseaseName:
          data.MQ06.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child1AnyPedDieseaseReceive:
          data.MQ06.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child1AnyPedDieseaseMonth:
          data.MQ06.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child1AnyPedDieseaseYear:
          data.MQ06.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child1AnyPedDieseaseOutcome:
          data.MQ06.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child2AnyPedDiesease:
          data.MQ06.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",

        child2AnyPedDieseaseName:
          data.MQ06.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child2AnyPedDieseaseReceive:
          data.MQ06.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child2AnyPedDieseaseMonth:
          data.MQ06.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child2AnyPedDieseaseYear:
          data.MQ06.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child2AnyPedDieseaseOutcome:
          data.MQ06.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child3AnyPedDiesease:
          data.MQ06.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",

        child3AnyPedDieseaseName:
          data.MQ06.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child3AnyPedDieseaseReceive:
          data.MQ06.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child3AnyPedDieseaseMonth:
          data.MQ06.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child3AnyPedDieseaseYear:
          data.MQ06.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child3AnyPedDieseaseOutcome:
          data.MQ06.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child4AnyPedDiesease:
          data.MQ06.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",

        mainAlcohol: data.MQ07.main_question.status === true ? "Yes" : "No", // Accidents / burns
        selfAlcohol:
          data.MQ07.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        selfAlcoholQuantity:
          data?.MQ07?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        selfAlcoholQuantityYear:
          data?.MQ07?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        spouseAlcohol:
          data.MQ07.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouseAlcoholQuantity:
          data?.MQ07?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        spouseAlcoholQuantityYear:
          data?.MQ07?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child1Alcohol:
          data.MQ07.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",
        child1AlcoholQuantity:
          data?.MQ07?.main_question?.member_data?.child_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child1AlcoholQuantityYear:
          data?.MQ07?.main_question?.member_data?.child_one?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child2Alcohol:
          data.MQ07.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child2AlcoholQuantity:
          data?.MQ07?.main_question?.member_data?.child_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child2AlcoholQuantityYear:
          data?.MQ07?.main_question?.member_data?.child_two?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child3Alcohol:
          data.MQ07.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child3AlcoholQuantity:
          data?.MQ07?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child3AlcoholQuantityYear:
          data?.MQ07?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child4Alcohol:
          data.MQ07.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child4AlcoholQuantity:
          data?.MQ07?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child4AlcoholQuantityYear:
          data?.MQ07?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        mainSmoking: data.MQ08.main_question.status === true ? "Yes" : "No", // Accidents / burns
        selfSmoking:
          data.MQ08.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        selfSmokingQuantity:
          data?.MQ08?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        selfSmokingQuantityYear:
          data?.MQ08?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        spouseSmoking:
          data.MQ08.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouseSmokingQuantity:
          data?.MQ08?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        spouseSmokingQuantityYear:
          data?.MQ08?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child1Smoking:
          data.MQ08.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",
        child1SmokingQuantity:
          data?.MQ08?.main_question?.member_data?.child_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child1SmokingQuantityYear:
          data?.MQ08?.main_question?.member_data?.child_one?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child2Smoking:
          data.MQ08.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child2SmokingQuantity:
          data?.MQ08?.main_question?.member_data?.child_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child2SmokingQuantityYear:
          data?.MQ08?.main_question?.member_data?.child_two?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child3Smoking:
          data.MQ08.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child3SmokingQuantity:
          data?.MQ08?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child3SmokingQuantityYear:
          data?.MQ08?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child4Smoking:
          data.MQ08.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child4SmokingQuantity:
          data?.MQ08?.main_question?.member_data?.child_four?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child4SmokingQuantityYear:
          data?.MQ08?.main_question?.member_data?.child_four?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        mainOtherSubstance:
          data.MQ09.main_question.status === true ? "Yes" : "No", //Smoking / Tobacco consumption
        selfOtherSubstance:
          data.MQ09.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        selfOtherSubstanceQuantity:
          data?.MQ09?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        selfOtherSubstanceQuantityYear:
          data?.MQ09?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,
        spouseOtherSubstance:
          data.MQ09.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouseOtherSubstanceQuantity:
          data?.MQ09?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        spouseOtherSubstanceQuantityYear:
          data?.MQ09?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,
        child1OtherSubstance:
          data.MQ09.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",
        child1OtherSubstanceQuantity:
          data?.MQ09?.main_question?.member_data?.child_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child1OtherSubstanceQuantityYear:
          data?.MQ09?.main_question?.member_data?.child_one?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,
        child2OtherSubstance:
          data.MQ09.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child2OtherSubstanceQuantity:
          data?.MQ09?.main_question?.member_data?.child_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child2OtherSubstanceQuantityYear:
          data?.MQ09?.main_question?.member_data?.child_two?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,
        child3OtherSubstance:
          data.MQ09.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child3OtherSubstanceQuantity:
          data?.MQ09?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child3OtherSubstanceQuantityYear:
          data?.MQ09?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child4OtherSubstance:
          data.MQ09.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child4OtherSubstanceQuantity:
          data?.MQ09?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child4OtherSubstanceQuantityYear:
          data?.MQ09?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        mainNarcotics: data.MQ10.main_question.status === true ? "Yes" : "No", //Alcohol consumption
        selfNarcotics:
          data.MQ10.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        selfNarcoticsQuantity:
          data?.MQ10?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        selfNarcoticsQuantityYear:
          data?.MQ10?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        spouseNarcotics:
          data.MQ10.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouseNarcoticsQuantity:
          data?.MQ10?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        spouseNarcoticsQuantityYear:
          data?.MQ10?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child1Narcotics:
          data.MQ10.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",
        child1NarcoticsQuantity:
          data?.MQ10?.main_question?.member_data?.child_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child1_naroticsDuration:
          data?.MQ10?.main_question?.member_data?.child_one?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child2Narcotics:
          data.MQ10.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child2NarcoticsQuantity:
          data?.MQ10?.main_question?.member_data?.child_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child2NarcoticsQuantityYear:
          data?.MQ10?.main_question?.member_data?.child_two?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child3Narcotics:
          data.MQ10.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child3NarcoticsQuantity:
          data?.MQ10?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child3NarcoticsQuantityYear:
          data?.MQ10?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child4Narcotics:
          data.MQ10.main_question.member_data?.child_four.selection_status ===
          true
            ? "Yes"
            : "No",
        child4NarcoticsQuantity:
          data?.MQ10?.main_question?.member_data?.child_four?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child4NarcoticsQuantityYear:
          data?.MQ10?.main_question?.member_data?.child_four?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,
      };

      console.log("has Error", hasError);

      HEALTH_PROPOSAL_SERVICES.getMedicalData(onSuccess, onError, param);
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
      );
    }
  };

  return (
    <>
      {isMobile ? (
        <MMedicalDetailsRSA
          medicalQuestionData={medicalQuestionData}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          PAGE_STATUS={PAGE_STATUS}
        />
      ) : (
        <MedicalDetailsRSA
          medicalQuestionData={medicalQuestionData}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          PAGE_STATUS={PAGE_STATUS}
        />
      )}
    </>
  );
};

export default MedicalDetailsContainerRSA;
