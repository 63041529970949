import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import TermFooter from "../../../../../Component/Term/TermFooter/TermFooter";
import TermRiderQuestion from "../../../../../Component/Term/TermRiderQuestion/TermRiderQuestion";
import { TermSlice } from "../../../../../redux/slice/Term/TermSlice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { TDropdown } from "../../../../../types/Common/TDropdown";
import "./../../../../../SCSS/CommonSCSS/ProposalForms.scss";
import { calculateAge } from "../../../../../SupportingFiles/HelpingFunction";
import { CLIENTS } from "../../../../../URLCollection/Clients";

function RidersBenefits({
  loaderTf001,
  loaderTf003,
  loaderTf004,
  tf001Data,
  tf003Data,
  showWOP,
  loader,
  GET_REDIRECTION_URL,
}: {
  loaderTf001: boolean;
  loaderTf003: boolean;
  loaderTf004: boolean;
  tf001Data: TDropdown[];
  tf003Data: TDropdown[];
  showWOP: boolean;
  loader: boolean;
  GET_REDIRECTION_URL: Function;
}) {
  const dispatch = useAppDispatch();

  const { RIDERS_BENEFITS, ADD_FORM, PROPOSER_DATA, SELECTED_QUOTE_DATA } =
    useAppSelector((state) => state.Term);

  return (
    <Box>
      <h5 className="sectionTitle">Addon Details</h5>
      <Grid container spacing={3}>
        <Grid xs={12}>
          {calculateAge(`${ADD_FORM.age.value}`) > 55 ||
          PROPOSER_DATA.occupation.value === "01" ||
          PROPOSER_DATA.diabetic ? null : window.location.hostname ===
            CLIENTS.AMAZE ? (
            <TermRiderQuestion
              loader={loaderTf001}
              addon_amount={`${RIDERS_BENEFITS.riders_benefits_response.riders?.TF001?.premium}`}
              question_name="Critical Illness and Disability Rider"
              desc="The critical illness benefit pays claim amount on first diagnosis of any of the 64 critical illnesses. It means the life assured will be paid the critical illness benefit amount immediately without any hospital bills."
              toggle_status={RIDERS_BENEFITS.local_value.tf001Status}
              value_update={(a: any, v: any) => {
                dispatch(
                  TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                    updates: [
                      {
                        key: "tf001Status",
                        value: v,
                      },
                    ],
                  })
                );
              }}
              sub_details={
                <Box sx={{ maxWidth: "250px", paddingBottom: "16px" }}>
                  <SelectDropdown
                    attrName={""}
                    title="Select Value"
                    value={RIDERS_BENEFITS.local_value.tf001Value}
                    value_update={(a: any, v: any) => {
                      dispatch(
                        TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
                          {
                            updates: [
                              {
                                key: "tf001Value",
                                value: v,
                              },
                            ],
                          }
                        )
                      );
                    }}
                    data={tf001Data}
                    warn_status={false}
                  />
                </Box>
              }
            />
          ) : null}
          <TermRiderQuestion
            loader={loaderTf003}
            addon_amount={`${RIDERS_BENEFITS.riders_benefits_response.riders?.TF003?.premium}`}
            question_name="Accidental Death Coverage"
            desc="On death due to an accident, 100% of Accident Cover Sum Assured will be payable as lump sum in addition to the death benefit Sum Assured."
            toggle_status={RIDERS_BENEFITS.local_value.tf003Status}
            value_update={(a: any, v: any) => {
              dispatch(
                TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "tf003Status",
                      value: v,
                    },
                  ],
                })
              );
            }}
            sub_details={
              <Box sx={{ maxWidth: "250px", paddingBottom: "16px" }}>
                <SelectDropdown
                  attrName={""}
                  title="Select Value"
                  value={RIDERS_BENEFITS.local_value.tf003Value}
                  value_update={(a: any, v: any) => {
                    dispatch(
                      TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                        updates: [
                          {
                            key: "tf003Value",
                            value: v,
                          },
                        ],
                      })
                    );
                  }}
                  data={tf003Data}
                  warn_status={false}
                />
              </Box>
            }
          />
          {window.location.hostname === CLIENTS.AMAZE &&
          showWOP &&
          !PROPOSER_DATA.diabetic &&
          PROPOSER_DATA.occupation.value !== "01" &&
          Number(SELECTED_QUOTE_DATA?.premiumDetails?.pay_term) <= 15 ? (
            <TermRiderQuestion
              loader={loaderTf004}
              addon_amount={`${RIDERS_BENEFITS.riders_benefits_response.riders?.TF004?.premium}`}
              question_name="Waiver of Premium"
              desc={
                <>
                  <p>
                    All future premiums are waived on Accidental Total Permanent
                    Disability, and the policy will continue
                  </p>
                </>
              }
              toggle_status={RIDERS_BENEFITS.local_value.tf004Status}
              value_update={(a: any, v: any) => {
                dispatch(
                  TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                    updates: [
                      {
                        key: "tf004Status",
                        value: v,
                      },
                    ],
                  })
                );
              }}
            />
          ) : null}
        </Grid>
      </Grid>
      <TermFooter
        forward={() => {
          GET_REDIRECTION_URL();
        }}
        loader={loader}
      />
    </Box>
  );
}

export default RidersBenefits;
