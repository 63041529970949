import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NiVABUPA from "../../../Page/Desktop/CKYC/NIVA_BUPA/NiVABUPA";
import MNiVABUPA from "../../../Page/Mobile/CKYC/NIVA_BUPA/MNiVABUPA";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import { CKYCServcies } from "../../../Services/ckyc-node/CKYCServices";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { CKYCSlice } from "../../../redux/slice/CKYC/CKYCSlice";
import { CommonDropdownSlice } from "../../../redux/slice/CommonDropdown/CommonDropdownSlice";
import { HealthSlice } from "../../../redux/slice/Health/HealthSlice";
import {
  isEmpty,
  validateEmail,
  validateFullName,
  validateMobileNumber,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TVerifyCKYCForm } from "../../../types/CKYC/TCKYC";
import { TDropdown } from "../../../types/Common/TDropdown";
const NIVABUPACKYCContainer = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  const { SELECTED_QUOTE_DATA, ADD_FORM_RESPONSE } = useAppSelector(
    (state) => state.Health
  );
  const { CKYC, Health } = useAppSelector((state) => state);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCKYCDetailsPopup, setShowCKYCDetailsPopup] =
    useState<boolean>(false);
  const [showManualPopup, setShowManualPopup] = useState<boolean>(false);
  const [formDetails, setFormDetails] =
    useState<TVerifyCKYCForm>(VERIFY_KYC_FORM);
  useEffect(() => {
    GET_DROPDOWN_MASTER("POV");
  }, []);
  const GET_DROPDOWN_MASTER = (TYPE: "POI" | "POA" | "POV") => {
    const onSuccess = (res: any) => {
      const results = res.data;
      const error = results.error;

      if (!error) {
        const response = results.data;

        let dropdown_data: TDropdown[] = [];
        for (const data of response) {
          dropdown_data.push({ label: data?.document, value: data?.doc_code });
        }

        dispatch(
          CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
            key: TYPE,
            value: dropdown_data,
          })
        );
      }
    };
    const onError = (error: any) => {};
    const Health_params = {
      company_code: "G023",
      list_for: TYPE,
    };
    CKYCServcies.CKYC_DROPDOWN_MASTER(onError, onSuccess, Health_params);
  };
  const updateMasterState = (attrName: string, value: any) => {
    if (attrName === "full_name") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: !validateFullName(value) },
      }));
    } else if (attrName === "dob") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName === "gender") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName === "doc_type") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName === "doc_id") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName === "mobile") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName === "email") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    } else {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    }
  };
  const validateForm = () => {
    let data: TVerifyCKYCForm = { ...formDetails };
    data = {
      ...data,
      full_name: {
        ...data.full_name,
        warning: !validateFullName(data.full_name.value),
      },
      dob: {
        ...data.dob,
        warning: isEmpty(data.dob.value),
      },
      doc_type: {
        ...data.doc_type,
        warning: isEmpty(data.doc_type.value),
      },
      doc_id: {
        ...data.doc_id,
        warning: isEmpty(data.doc_id.value),
      },
      customer_type: {
        ...data.customer_type,
        warning: isEmpty(data.customer_type.value),
      },
      gender: { ...data.gender, warning: isEmpty(data.gender.value) },
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(`${data.mobile.value}`),
      },
      email: {
        ...data.email,
        warning: !validateEmail(`${data.email.value}`),
      },
    };
    setFormDetails(data);
    let hasError =
      data.full_name.warning ||
      data.doc_type.warning ||
      data.doc_id.warning ||
      data.customer_type.warning ||
      data.dob.warning ||
      data.mobile.warning ||
      data.email.warning;

    if (data.customer_type.value !== "Organization") {
      hasError = hasError || data.gender.warning;
    }

    if (!hasError) {
      VERIFY_CKYC_DETAILS(data);
    }
  };

  const VERIFY_CKYC_DETAILS = (data: any) => {
    const onSuccess = (res: any) => {
      setIsLoading(false);
      const error = res.error;
      const message = res.message;
      const response = res.response;
      const kycManualURL = response.kycManualURL;
      if (error) {
        if (message === "No record found") {
          dispatch(
            CKYCSlice.actions.BULK_UPDATE({
              ...CKYC,
              VERIFY_KYC_FORM: { ...data },
              MANUAL_KYC_THIRD_PARTY_URL: kycManualURL,
            })
          );
          setShowManualPopup(true);
        }
      } else {
        dispatch(
          CKYCSlice.actions.BULK_UPDATE({
            ...CKYC,
            VERIFY_KYC_FORM: { ...data },
            CKYC_DETAILS: response,
          })
        );
        setShowCKYCDetailsPopup(true);
      }
    };

    const onError = (err: any) => {};

    let dto: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      cusType: data.customer_type.value,
      insuranceType: "HP",
      companyCode: "G023",
      kycCusName: `${data.full_name.value}`,
      dobForKyc: `${data.dob.value}`,
      docNameForKyc: `${data.doc_type.value}`,
      docIDNoForKyc: `${data.doc_id.value}`,
      kycCusMobNo: `${data.mobile.value}`,
      kycCusEmail: `${data.email.value}`,
      genderForKyc: `${data.gender.value}`,
      section: "premium_details",
    };

    setIsLoading(true);
    CKYCServcies.VERIFY_NIVA_BUPA_CKYC(onSuccess, onError, dto);
  };

  const CONTINUE_TO_PROPOSAL = () => {
    dispatch(
      HealthSlice.actions.BULK_UPLOAD({
        ...Health,
        INSURED_MEMBER_DETAILS: {
          ...Health.INSURED_MEMBER_DETAILS,
          PROPOSER_DETAILS: {
            ...Health.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS,
            name: {
              value: CKYC.CKYC_DETAILS.kycCustomerName,
              warning: false,
            },
            dob: {
              value: CKYC.CKYC_DETAILS.kycDOB,
              warning: false,
            },
            mobile: { value: CKYC.CKYC_DETAILS.kycMobile, warning: false },
            emergencyPhone: { value: "", warning: false },
            email: { value: CKYC.CKYC_DETAILS.kycEmail, warning: false },
            aadhar: { value: "", warning: false },
            gender: { value: VERIFY_KYC_FORM.gender.value, warning: false },
            maritalStatus: { value: "", warning: false },
            weight: { value: "", warning: false },
            heightFeet: { value: "", warning: false },
            heightInches: { value: "", warning: false },
            occupation: { value: "", warning: false },
            relationship: { value: "", warning: false },
            proposer_name: { value: "", warning: false },
          },
        },
        ADDRESS_DETAILS: {
          ...Health.ADDRESS_DETAILS,
          pincode: { value: CKYC.CKYC_DETAILS.kycPincode, warning: false },
          city: CKYC.CKYC_DETAILS.kycCity,
          state: CKYC.CKYC_DETAILS.kycState,
          addressLineOne: {
            value: CKYC.CKYC_DETAILS.kycAddress1,
            warning: false,
          },
          addressLineTwo: {
            value: CKYC.CKYC_DETAILS.kycAddress2,
            warning: false,
          },
          isMailingAddressSame: true,
          mailingPinCode: { value: "", warning: false },
          mailingCity: "",
          mailingState: "",
          mailingAddressLineOne: { value: "", warning: false },
          mailingAddressLineTwo: { value: "", warning: false },
        },
      })
    );
    navigate(
      `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADDON_DETAILS}?type=HP&companyCode=${Health?.SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${Health?.ADD_FORM_RESPONSE.quote_no}&product_id=${Health?.SELECTED_QUOTE_DATA?.productDetails.id}`
    );
  };
  return (
    <>
      {isMobile ? (
        <MNiVABUPA
          isLoading={isLoading}
          showCKYCDetailsPopup={showCKYCDetailsPopup}
          setShowCKYCDetailsPopup={setShowCKYCDetailsPopup}
          showManualPopup={showManualPopup}
          setShowManualPopup={setShowManualPopup}
          updateMasterState={updateMasterState}
          formDetails={formDetails}
          validateForm={validateForm}
          CONTINUE_TO_PROPOSAL={CONTINUE_TO_PROPOSAL}
        />
      ) : (
        <NiVABUPA
          isLoading={isLoading}
          showCKYCDetailsPopup={showCKYCDetailsPopup}
          setShowCKYCDetailsPopup={setShowCKYCDetailsPopup}
          showManualPopup={showManualPopup}
          setShowManualPopup={setShowManualPopup}
          updateMasterState={updateMasterState}
          formDetails={formDetails}
          validateForm={validateForm}
          CONTINUE_TO_PROPOSAL={CONTINUE_TO_PROPOSAL}
        />
      )}
    </>
  );
};

export default NIVABUPACKYCContainer;
