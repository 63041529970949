import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../../../LeftSidebar/LeftSidebar.scss";
import { useAppSelector } from "../../../../../redux/hooks";
import {
  extractNumbersFromString,
  formatNumberToLakhOrCrores,
  formatNumberWithCurrencySymbol,
  isEmpty,
} from "../../../../../SupportingFiles/HelpingFunction";

const LeftSidebar = ({ class_name }: { class_name: string }) => {
  const policyInfoState = useAppSelector(
    (state) => state.proposalInformation.policy_information
  );
  const householdDetailsState = useAppSelector(
    (state) => state.householdDetails
  );
  console.log(
    "policyInfoState",
    policyInfoState,
    "householdDetailsState",
    householdDetailsState
  );
  return (
    <Grid xs className="proposalLeftSidebar">
      <Box className={class_name} />
      <img
        className="insurerLogo"
        src={policyInfoState.CompanyDetails.logoUrl}
      />
      <h6 className="mb-8">{policyInfoState.productDetails.product_name}</h6>
      {(householdDetailsState.sumInsuredFor == "building" ||
        householdDetailsState.sumInsuredFor == "building_household") && (
        <>
          <h5>
            {formatNumberToLakhOrCrores(
              Number(
                extractNumbersFromString(
                  householdDetailsState.buildingValue.value
                )
              )
            )}
          </h5>
          <p className="mb-8">SI for Building</p>
        </>
      )}
      {(householdDetailsState.sumInsuredFor == "householdItems" ||
        householdDetailsState.sumInsuredFor == "building_household") && (
        <>
          <h5>
            {formatNumberToLakhOrCrores(
              Number(
                extractNumbersFromString(
                  householdDetailsState.householdItems.value
                )
              )
            )}
          </h5>
          <p className="mb-8">SI for Household Items</p>
        </>
      )}
      {householdDetailsState.policyTerm === 1 ? (
        <h5>{householdDetailsState.policyTerm} year</h5>
      ) : (
        <h5>{householdDetailsState.policyTerm} years</h5>
      )}
      {/* <h5>{householdDetailsState.policyTerm} years</h5> */}
      <p className="mb-8">Policy Tenure</p>
      <h5 className="premium">
        {!isEmpty(policyInfoState.premiumDetails?.grossPremium) ? (
          formatNumberWithCurrencySymbol(
            Number(policyInfoState.premiumDetails?.grossPremium)
          )
        ) : (
          <img src="../images/loader.svg" height="10px" alt="Loader" />
        )}
        {/* {policyInfoState.CompanyDetails.company_code ===
        ECompanyCode.GO_DIGIT ? (
          !isEmpty(policyInfoState.premiumDetails?.grossPremium) ? (
            formatNumberWithCurrencySymbol(
              Number(policyInfoState.premiumDetails?.grossPremium)
            )
          ) : (
            <img src="images/loader.svg" height="10px" alt="Loader" />
          )
        ) : policyInfoState.CompanyDetails.company_code ===
          ECompanyCode.ICICI ? (
          !isEmpty(policyInfoState.premiumDetails?.grossPremium) ? (
            formatNumberWithCurrencySymbol(
              Number(policyInfoState.premiumDetails?.grossPremium)
            )
          ) : (
            <img src="images/loader.svg" height="10px" alt="Loader" />
          )
        ) : null} */}
        {/* {policyInfoState.CompanyDetails.company_code ===
        ECompanyCode.GO_DIGIT ? !isEmpty(policyInfoState.premiumDetails?.grossPremium) ?(
          formatNumberWithCurrencySymbol(
            Number(policyInfoState.premiumDetails?.grossPremium)
          ):<img src="images/loader.svg" height="10px" alt="Loader" />
        ) : policyInfoState.CompanyDetails.company_code ===
          ECompanyCode.ICICI ? (
          formatNumberWithCurrencySymbol(
            Number(policyInfoState.premiumDetails?.totalPremium)
          )
        ) : (
          <img src="images/loader.svg" height="10px" alt="Loader" />
        )} */}
      </h5>
      <p className="mb-8">Premium (Incl. GST)</p>
    </Grid>
  );
};

export default LeftSidebar;
