import React, { useEffect, useState } from "react";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import {
  TGOLDEN_SHIELDICICIAddon,
  TELEVATEICICIAddon,
} from "../../../../types/Health/ProposalDeatail/TICICAddon/TICICAddon";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { HealthSlice } from "../../../../redux/slice/Health/HealthSlice";
import { THealthQuote } from "../../../../types/Health/HealthQuotation/THealthQuote";
// import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/HealthServices/HealthPropsalServices";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/health-node/HealthPropsalServices";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { useNavigate } from "react-router-dom";
// import MAddonDetailGoldenShieldICICI from "../../../../Page/Mobile/Health/ICIC/AddonDetailsICIC/MAddonDetailGoldenShieldICICI";
import AddonDetailElevateICICI from "../../../../Page/Desktop/health-node/icici/AddonDetailsICIC/AddonDetailsElevateICICI";
import MAddonDetailElevateICICI from "../../../../Page/Mobile/health-node/icici/AddonDetailsICIC/MAddonDetailsElevateICICI";
import { CKYC_ROUTES } from "../../../../Router/Path/CKYCRoutes";
import { ICICI_ELEVATE_ADDONS_QUESTION_INITIAL_VALUE } from "../../../../redux/slice/Health/MedicalInitialValue/ICICI_ELEVATE";
import { toast } from "react-toastify";

const AddonDetailsContainerElevateICICI = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { Health, CKYC } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const {
    ADD_FORM,
    ADD_FORM_RESPONSE,
    ADDON_DETAILS,
    SELECTED_QUOTE_DATA,
    PAGE_STATUS,
    ADDON_DETAILS_ELEVATE_ICICI,
  } = useAppSelector((state) => state.Health);
  const { CKYC_DETAILS, VERIFY_KYC_FORM } = useAppSelector(
    (state) => state.CKYC
  );

  const [userType, setUserType] = useState<string>("");
  const [utm_medium, setUtm_medium] = useState<string>("");
  const [utm_source, setUtm_source] = useState<string>("");

  useEffect(() => {
    // Access the query parameters directly from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const userTypeFromQuery = searchParams.get("user_type");
    const utm_mediumFromQuery = searchParams.get("utm_medium");
    const utm_sourceFromQuery = searchParams.get("utm_source");
    setUtm_medium(`${utm_mediumFromQuery}`);
    setUtm_source(`${utm_sourceFromQuery}`);
    setUserType(
      atob(`${userTypeFromQuery}`) === "agent" ? btoa("agent") : btoa("user")
    );
  }, []);

  const addons = {
    Critical_Illness: {
      label: "Critical Illness",
      desc: "Provides cover for critical illness suffered. Recieve cover for the Annual Sum Insured, up to ₹ 50 Lakhs, upon your first diagnosis of one or more Critical Illnesses, applicable for ages up to 50 years. (T&C Apply)",
      status: false,
    },

    Maternity: {
      label: "Maternity Cover",
      desc: "Covers medical expenses pertaining to maternity benefits. All SI -Covers medical expenses up to 10% of the Annual SI, with a maximum limit of INR 1 Lakh (Maternity Sum Insured) for childbirth and/or pregnancy termination, limited to three instances, for insured females aged 21 to 45.",
      status: false,
    },
    Vaccination: {
      label: "Vaccination For New Born Baby",
      desc: "",
      status: false,
    },
    New_born: {
      label: "New Born",
      desc: "",
      status: false,
    },
    Anuual_Health_Checkup: {
      label: "Annual Health Checkup",
      desc: "",
      status: false,
    },
    Domestic_Air_Ambulance: {
      label: "Domestic Air Ambulance",
      desc: "",
      status: false,
    },
    Dependent_Accomodation_benefit: {
      label: "Dependent accommodation benefit",
      desc: "",
      status: false,
    },
    Convalescence_Benefit: {
      label: "Convalescence Benefit",
      desc: "",
      status: false,
    },
    Durable_Medical_Equipment_Cover: {
      label: "Durable Medical Equipment Cover",
      desc: "",
      status: false,
    },
    Worldwide_Cover: {
      label: "Worldwide Cover",
      desc: "",
      status: false,
    },
    Personal_Accident: {
      label: "Personal Accident",
      desc: "",
      status: false,
    },

    // Sum_Insured_Protector: "",
    SI_Protector: {
      label: "Sum Insured Protector",
      desc: "",
      status: false,
    },
    Claim_Protector: {
      label: "Claim Protector",
      desc: "Get benefit for the non-payable items in case of claim to ensure no out-pocket expenses.",
      status: false,
    },
    Infinite_Care: {
      label: "Infinite Care",
      desc: "",
      status: false,
    },
    Compassionate_Visit_HP: {
      label: "Compassionate Visit HP",
      desc: "",
      status: false,
    },
    Nursing_at_Home_HP: {
      label: "Nursing At Home HP",
      desc: "",
      status: false,
    },
    BeFit_A_HP: {
      label: "BeFit A",
      desc: "Routine Diagnostic, Minor Procedure cover & Pharmacy cover - ₹1000/- cover E-counselling virtual sessions with qualified mental health medical practitioners - 6 sessions Diet and nutrition e-consultation with qualified nutrition experts - 6 sessions",
      status: false,
    },
    BeFit_B_HP: {
      label: "BeFit B",
      desc: "	Routine Diagnostic & Minor Procedure cover - ₹1000/- cover Pharmacy cover - ₹1000/- cover E-counselling virtual sessions with qualified mental health medical practitioners - 6 sessions",
      status: false,
    },
    BeFit_C_HP: {
      label: "BeFit C",
      desc: "Get up to 4 outpatient consultations(OPD). Routine diagnostic and minor procedures cover of ₹ 1,000. Pharmacy cover of ₹ 1,000. 6 sessions for physiotherapy, e-counseling, diet and nutrition e-consultation each.",
      status: false,
    },
    BeFit_D_HP: {
      label: "BeFit D",
      desc: "Routine Diagnostic & Minor Procedure cover - ₹1000/- cover Pharmacy cover - ₹1000/- cover E-counselling virtual sessions with qualified mental health medical practitioners - 6 sessions",
      status: false,
    },
    BeFit_E_HP: {
      label: "BeFit E",
      desc: "",
      status: false,
    },
    BeFit_F_HP: {
      label: "BeFit F",
      desc: "",
      status: false,
    },
    BeFit_G_HP: {
      label: "BeFit G",
      desc: "",
      status: false,
    },
    BeFit_H_HP: {
      label: "BeFit H",
      desc: "",
      status: false,
    },
    Room_Upgrade: {
      label: "Room Upgrade",
      desc: "",
      status: false,
    },
    Waiting_Period: {
      label: "Waiting Period",
      desc: "",
      status: false,
    },
  };

  const [addonStatus, setAddonStatus] = useState<TELEVATEICICIAddon>(
    ADDON_DETAILS_ELEVATE_ICICI
  );
  const [addonLabels, setAddonLabels] = useState<any>(addons);
  //   ADDON_DETAILS_GOLDEN_SHIELD_ICICI
  const [loader, setLoader] = useState<boolean>(false);
  const [memberState, setMemberState] = useState<any>([]);

  const getAddonStatus = () => {
    let member: any = { ...ADDON_DETAILS_ELEVATE_ICICI };
    console.log("member", member);
    let clone = { ...addonLabels };
    Object.keys(addonLabels).forEach((key, i) => {
      if (Object.keys(member).length - 1 >= i) {
        console.log("i", i, member[i][key]);
        if (clone[key] === member[i][key]) {
          clone[key].status = true;
        }
      }
    });
    console.log("clone", clone);
    setAddonLabels(clone);
    // console.log("clone >>>", clone);
  };

  useEffect(() => {
    updatePreiumApi();
    // dispatch(HealthSlice.actions.ADDON_DETAILS_DATA_ELEVATE_ICIC(addonStatus));
    dispatch(HealthSlice.actions.ADDON_DETAILS_DATA_ELEVATE_ICIC(memberState));
    // }, [addonStatus]);
  }, [memberState]);

  useEffect(() => {
    console.log("page status", PAGE_STATUS);
    if (PAGE_STATUS) {
      setMemberState(ADDON_DETAILS_ELEVATE_ICICI);
      getAddonStatus();
    } else {
      const adults = ADD_FORM.spouse_status ? 2 : 1;
      const children = Number(ADD_FORM.children);
      const total = adults + children;
      let newAddons = [];
      for (let k = 0; k < total; k++) {
        newAddons.push(ICICI_ELEVATE_ADDONS_QUESTION_INITIAL_VALUE);
      }
      setMemberState(newAddons);
    }
  }, [PAGE_STATUS]);

  const updateMasterState = (attrName: any, value: any) => {
    console.log("attr", attrName, "val", value);

    let clone: any = [...memberState];
    if (attrName[0] === "addon") {
      // if (attrName[1] === "Maternity" && !value) {
      //   let
      //    setAddonLabels((prev: any) => ({
      //      ...prev,
      //      [attrName[1]]: { ...addonLabels[attrName[1]], status: value },
      //    }));
      // }
      setAddonLabels((prev: any) => ({
        ...prev,
        [attrName[1]]: { ...addonLabels[attrName[1]], status: value },
      }));
    }
    if (attrName[0] === "member") {
      console.log(
        "clone",
        memberState[attrName[1]],
        memberState[attrName[1]][attrName[2]]
      );
      // setMemberState((prev: any) => {
      //   return [
      //     ...memberState,
      //     (memberState[attrName[1]] = {
      //       ...memberState[attrName[1]],
      //       [memberState[attrName[1]][attrName[2]]]: value,
      //     }),
      //   ];
      // });

      let addonsArray = clone.map((item: any, i: number) => {
        if (i === attrName[1]) {
          console.log("item", item);
          return {
            // ...attrName[1],
            ...item,
            [attrName[2]]: value,
          };
        } else return item;
      });
      console.log("addonsArray", addonsArray);
      setMemberState(addonsArray);
    }
    setAddonStatus((prevState) => ({
      ...prevState,
      [attrName]: value,
    }));
    //   if (attrName === "Modification_base_co_payment") {
    //     setAddonStatus((prev) => ({
    //       ...prev,
    //       Voluntary_deduction: false,
    //       Modification_base_co_payment: value,
    //       VoluntaryCopaymentPercentage: 20,
    //     }));
    //   } else if (attrName === "Voluntary_deduction") {
    //     setAddonStatus((prev) => ({
    //       ...prev,
    //       Voluntary_deduction: value,
    //       Modification_base_co_payment: false,
    //       VoluntaryCopaymentPercentage: 20,
    //     }));
    //   } else if (attrName === "Modification_base_co_payment" && value === false) {
    //     setAddonStatus((prev) => ({
    //       ...prev,
    //       VoluntaryCopaymentPercentage: 40,
    //     }));
    //   } else if (attrName === "Voluntary_deduction" && value === false) {
    //     setAddonStatus((prev) => ({
    //       ...prev,
    //       VoluntaryCopaymentPercentage: 40,
    //     }));
    //   }
  };
  useEffect(() => {
    //   updatePreiumApi();
    //   dispatch(
    //     HealthSlice.actions.ADDON_DETAILS_DATA_GOLDEN_SHIELD_ICIC(addonStatus)
    //   );
  }, [addonStatus]);

  const updatePreiumApi = () => {
    setLoader(true);
    const onSuccess = (res: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      setLoader(false);
      if (res.results.error === false) {
        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.results.response.premiumDetails.finalPremium,
          },
        };
        // dispatch(
        //   HealthSlice.actions.BULK_UPLOAD({
        //     ...Health,
        //     SELECTED_QUOTE_DATA: quoteData,
        //     // ADDON_DETAILS_GOLDEN_SHIELD_ICICI: addonStatus,
        //     QUOTE_LOADER: false,
        //   })
        // );
      }
    };
    const onError = (err: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));

      console.log("err", err);
    };

    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    // let param: any = {
    //   product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
    //   company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
    //   quote_no: ADD_FORM_RESPONSE.quote_no,
    //   adult: ADD_FORM.spouse_status === true ? "2" : "1",
    //   child: ADD_FORM.children,
    //   gender: VERIFY_KYC_FORM.gender.value,
    //   term: SELECTED_QUOTE_DATA.premiumDetails.term,
    //   section: "add_ons_details",
    //   cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
    //     (parseInt(ADD_FORM?.son_count?.value) || 0) +
    //       (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
    //     0
    //       ? `${
    //           (parseInt(ADD_FORM?.son_count?.value) || 0) +
    //           (parseInt(ADD_FORM?.daughter_count?.value) || 0)
    //         }C`
    //       : ""
    //   }`,
    //   addon: {
    //     AddOn1: addonStatus.Claim_protector === true ? 1 : 0, //Claim Protector
    //     AddOn2: addonStatus.Modification_base_co_payment === true ? 1 : 0, //Modification of base co-payment
    //     VoluntaryCopaymentPercentage:
    //       addonStatus.Voluntary_deduction ||
    //       addonStatus.Modification_base_co_payment
    //         ? addonStatus.VoluntaryCopaymentPercentage
    //         : 0,
    //     AddOn3: addonStatus.Voluntary_deduction === true ? 1 : 0, //Voluntary Deductible
    //     AddOn4: addonStatus.Care_management_program === true ? 1 : 0, // Care Management Program  (Mandatory 1 selectbale)
    //     AddOn5: addonStatus.Care_management_plus_program === true ? 1 : 0, //Care Management Plus Program
    //   },
    // };

    let param = {
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      quote_no: ADD_FORM_RESPONSE.quote_no,

      adult: ADD_FORM.spouse_status === true ? "2" : "1",
      child: ADD_FORM.children,
      gender: VERIFY_KYC_FORM.gender.value,
      term: SELECTED_QUOTE_DATA.premiumDetails.term,
      section: "add_ons_details",
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,

      // quote_no: "GI2115221021",
      network_advantage: "Yes",
      product_ids: [2948],
      medical_info: "No",

      // addon: {
      //   room_modifier: "Y",
      //   room_modifier_value: "DoubleOccupancyRoom",
      //   jump_start: "Y",
      //   PED_waiting_period_reduction: "Y",
      //   specific_condition_reduction: "Y",
      //   maternity_waiting_period_reduction: "Y",
      //   guaranteed_super_bonus: "Y",
      //   has_cibil: "Y",
      //   voluntary_co_pay: "Y",
      // },

      addons: addonsPayload(),
    };

    HEALTH_PROPOSAL_SERVICES.getPeriumByAddonElevateIcic(
      onSuccess,
      onError,
      param
    );
  };

  const FinalPremium = () => {
    const onSuccess = (res: any) => {
      console.log("res", res);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      final_premium: SELECTED_QUOTE_DATA?.premiumDetails?.finalPremium,
    };
    HEALTH_PROPOSAL_SERVICES.GET_FINAL_PREMIUM(onSuccess, onError, param);
  };

  const addonsPayload = () => {
    let payload = memberState.map((item: any) => {
      let obj = {
        AddOn78: Number(item.Critical_Illness),
        AddOn79: Number(item.Maternity),
        AddOn80: Number(item.New_Born),
        AddOn81: Number(item.Vaccination),
        AddOn82: Number(item.Personal_Accident),
        AddOn83: Number(item.SI_Protector),
        AddOn84: Number(item.Claim_Protector),
        AddOn85: Number(item.Compassionate_Visit_HP),
        AddOn86: Number(item.Nursing_at_Home_HP),
        AddOn87: Number(item.BeFit_A_HP),
        AddOn88: Number(item.BeFit_B_HP),
        AddOn89: Number(item.BeFit_C_HP),
        AddOn90: Number(item.BeFit_D_HP),
        AddOn91: Number(item.BeFit_E_HP),
        AddOn92: Number(item.BeFit_F_HP),
        AddOn93: Number(item.BeFit_G_HP),
        AddOn94: Number(item.Infinite_Care),
        AddOn95: Number(item.Worldwide_Cover),
        AddOn96: Number(item.Durable_Medical_Equipment_Cover),
        AddOn97: Number(item.Convalescence_Benefit),
        AddOn98: Number(item.Dependent_Accomodation_benefit),
        AddOn99: Number(item.Domestic_Air_Ambulance),
        AddOn100: Number(item.Anuual_Health_Checkup),
      };
      return obj;
    });
    return payload;
  };

  const validate = () => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const cus_type = params.get("cus_type");

    const onSuccess = (res: any) => {
      console.log("res>>>>>", res);
      if (!res.error) {
        setLoader(false);
        if (PAGE_STATUS) {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
          );
        } else {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
            // `${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=HP&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.productDetails.id}`
          );
        }
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    // setLoader(true);
    let hasError = validatePayload();
    let param = {
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      quote_no: ADD_FORM_RESPONSE.quote_no,

      adult: ADD_FORM.spouse_status === true ? "2" : "1",
      child: ADD_FORM.children,
      gender: VERIFY_KYC_FORM.gender.value,
      term: SELECTED_QUOTE_DATA.premiumDetails.term,
      section: "add_ons_details",
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,

      // quote_no: "GI2115221021",
      network_advantage: "Yes",
      product_ids: [2948],
      medical_info: "No",
      insurance_type: "HP",

      addon: {
        room_modifier: "Y",
        room_modifier_value: "DoubleOccupancyRoom",
        jump_start: "Y",
        PED_waiting_period_reduction: "Y",
        specific_condition_reduction: "Y",
        maternity_waiting_period_reduction: "Y",
        guaranteed_super_bonus: "Y",
        has_cibil: "Y",
        voluntary_co_pay: "Y",
      },

      addons: addonsPayload(),
    };
    console.log("has error", hasError);
    // if (!hasError) {
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
    // }
  };

  const validatePayload = () => {
    let objErrorArr: any = [];
    for (let key in addonLabels) {
      if (addonLabels[key].status) {
        memberState.forEach((obj: any) => {
          console.log("mmm", key, obj[key], "obj", obj);
          if (!obj[key]) {
            console.log("key", key, obj);
            if (!objErrorArr.includes(key)) {
              objErrorArr.push(key);
            }
          }
        });
      }
    }
    if (Boolean(objErrorArr.length)) {
      toast.error("Please Select Member");
    }
    console.log("has error", objErrorArr);
    return Boolean(objErrorArr.length);
  };

  useEffect(() => {
    FinalPremium();
  }, [SELECTED_QUOTE_DATA]);

  useEffect(() => {
    RECORD_CKYC_RESPONSE();
  }, [CKYC.CKYC_DETAILS]);

  const RECORD_CKYC_RESPONSE = () => {
    const onSuccess = (res: any) => {
      console.log("res...", res?.results);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      details: CKYC.CKYC_DETAILS,
      section: "kyc_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
      insurance_type: "HP",
    };
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };
  return (
    <>
      {isMobile ? null : (
        // <MAddonDetailElevateICICI
        //   addonStatus={addonStatus}
        //   validate={validate}
        //   PAGE_STATUS={PAGE_STATUS}
        //   isLoading={loader}
        //   updateMasterState={updateMasterState}
        //   //   updateMasterState={() => {}}
        // />
        <AddonDetailElevateICICI
          addonStatus={addonStatus}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
          isLoading={loader}
          updateMasterState={updateMasterState}
          addonData={addonLabels}
          memberstate={memberState}
          //   updateMasterState={() => {}}
        />
      )}
    </>
  );
};

export default AddonDetailsContainerElevateICICI;
