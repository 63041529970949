import { Route, Routes } from "react-router-dom";
import HDFCBankDetailsContainerNode from "../../../../../Container/bank-details-node/HDFC/HDFCBankDetailsContainer";
import HDFCBankDetailsContainer from "../../../../../Container/BankDetails/HDFC/HDFCBankDetailsContainer";
import HDFCCKYCContainerNode from "../../../../../Container/ckyc-node/hdfc/HDFCCKYCContainer";
import HDFCCKYCContainer from "../../../../../Container/CKYC/HDFC/HDFCCKYCContainer";
import AddonDetailsContainerOptimaRestoreNode from "../../../../../Container/health-node/HDFC/AddonDetailsContainerOptimaRestore";
import AddonDetailsContainerOptimaSecureNode from "../../../../../Container/health-node/HDFC/AddonDetailsContainerOptimaSecure";
import MedicalDetailsContainerOptimarestoreNode from "../../../../../Container/health-node/HDFC/MedicalDetailsContainerOptimarestore";
import MedicalDetailsContainerOptimaSecureNode from "../../../../../Container/health-node/HDFC/MedicalDetailsContainerOptimaSecure";
import { useAppSelector } from "../../../../../redux/hooks";
import { EProductId } from "../../../../../Services/Enum/EHome";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import { HEALTH_ROUTE } from "../../../../Path/HealthRoutes";
import HealthProposalContainer from "../../HealthProposalContainer";

export const HDFC_ROUTES_NODE = () => {
  const { SELECTED_QUOTE_DATA } = useAppSelector((state) => state.Health);
  return (
    <Routes>
      <Route
        path={HEALTH_ROUTE.PROPOSAL_PAGE}
        element={<HealthProposalContainer />}
      >
        {SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_RESTORE ||
        EProductId.HDFC_OPTIMA_RESTORE_FAMILY_PLAN ||
        SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_HEALTH_Plan ? (
          <Route path={CKYC_ROUTES.CKYC} element={<HDFCCKYCContainerNode />} />
        ) : (
          <Route path={CKYC_ROUTES.CKYC} element={<HDFCCKYCContainer />} />
        )}

        {SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_RESTORE ||
        SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_RESTORE_FAMILY_PLAN ? (
          <Route
            path={HEALTH_ROUTE.ADDON_DETAILS}
            element={<AddonDetailsContainerOptimaRestoreNode />}
          />
        ) : (
          <></>
        )}

        {SELECTED_QUOTE_DATA.productDetails.id === EProductId.HDFC_HEALTH ||
        SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_HEALTH_Global ||
        SELECTED_QUOTE_DATA.productDetails.id === EProductId.HDFC_HEALTH_Plan ||
        SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_SUPER_SECURE_Plan ? (
          <Route
            path={HEALTH_ROUTE.ADDON_DETAILS}
            element={<AddonDetailsContainerOptimaSecureNode />}
          />
        ) : (
          <></>
        )}
        {SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_RESTORE ||
        SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_RESTORE_FAMILY_PLAN ? (
          <Route
            path={HEALTH_ROUTE.MEDICAL_DETAILS}
            element={<MedicalDetailsContainerOptimarestoreNode />}
          />
        ) : (
          <></>
        )}
        {SELECTED_QUOTE_DATA.productDetails.id === EProductId.HDFC_HEALTH ||
        SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_HEALTH_Global ||
        SELECTED_QUOTE_DATA.productDetails.id === EProductId.HDFC_HEALTH_Plan ||
        SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_SUPER_SECURE_Plan ? (
          <Route
            path={HEALTH_ROUTE.MEDICAL_DETAILS}
            element={<MedicalDetailsContainerOptimaSecureNode />}
          />
        ) : (
          <></>
        )}

        {SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_RESTORE ||
        SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_RESTORE_FAMILY_PLAN ||
        SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_HEALTH_Plan ? (
          <Route
            path={HEALTH_ROUTE.BANK_DETAILS}
            element={<HDFCBankDetailsContainerNode />}
          />
        ) : (
          <Route
            path={HEALTH_ROUTE.BANK_DETAILS}
            element={<HDFCBankDetailsContainer />}
          />
        )}
      </Route>
    </Routes>
  );
};
