export const ECompanyCode = {
  GO_DIGIT: "G033",
  IFFCO_TOKIO: "G003",
  LIBERTY_VIDEOCON: "G030",
  UNIVERSAL_SOMPO: "G018",
  KOTAK: "G031",
  FUTURE_GENERALI: "G017",
  STAR: "G015",
  CARE: "G025",
  HDFC_HEALTH: "G016",
  ICIC: "G002",
  NIVA_BUPA: "G023",
  BAJAJ: "G001",
  ADITY_BIRLA: "G028",
  CIGNA: "G027",
  RSA: "G009",
  ICICI: "G002",
  TATA_NT: "L011",
  MAX: "L007",
};
// | CHRONIC - VYTL

export const EProductId = {
  HDFC_OPTIMA_RESTORE: 2111,
  HDFC_OPTIMA_RESTORE_FAMILY_PLAN: 2763,
  HDFC_HEALTH: 2767,
  HDFC_HEALTH_Global: 2766,
  HDFC_HEALTH_Plan: 2650,
  HDFC_OPTIMA_SUPER_SECURE_Plan: 2916,
  ICICI_GOLDEN_PLAN: 2843,
  ICICI_ELEVATE_PLAN: 2948,
  ICICI_HEALTH_ADVANTAGE: 2551,
  NIVA_BUPA_REASSURE_BRONZE: 2592,
  NIVA_BUPA_REASSURE_PLATINUM: 2593,
  NIVA_BUPA_REASSURE_TITANIUM: 2594,
  NIVA_BUPA_SENIOR_FIRST_GOLD_IND: 2890,
  NIVA_BUPA_Senior_First_Gold_Floater: 2891,
  NIVA_BUPA_Senior_First_Platinum_IND: 2892,
  NIVA_BUPA_Senior_First_Platinum_Floater: 2893,
  NIVA_BUPA_Aspire_Gold_Plus: 2943,
  NIVA_BUPA_Aspire_Diamond_Plus: 2944,
  NIVA_BUPA_Aspire_Platinum_Plus: 2945,
  NIVA_BUPA_Aspire_Titanium_Plus: 2946,
  KOTAK_PREMIER_EDGE: 2027,
  STAR_FHO_PRODUCT_ID: 197,
  STAR_COMP_PRODUCT_ID: 180,
  CIGNA_PRIME_ADVANTAGE: 2772,
  CIGNA_PRIME_ACTIVE: 2773,
  CIGNA_PRIME_PROTECT: 2712,
  RSA_LIFELINE_SUPREME: 99,
  RSA_LIFELINE_CLASSIC: 98,
  RSA_LIFELINE_ELITE: 100,
  START_RED_CARPET: 187,
  STAR_SUPER_STAR: 2947,
  CIGNA_LIFETIME_INDIA: 2757,
  CIGNA_LIFETIME_GLOBAL: 2895,
  ADITY_BIRLA_Activ_One_Max: 2902,
  ADITY_BIRLA_Activ_One_NXT: 2903,
  ADITY_BIRLA_Activ_One_Max_Plus: 2904,
  ADITY_BIRLA_Activ_One_Saver: 2905,
  ADITY_BIRLA_Activ_One_VIP: 2906,
  ADITY_BIRLA_Activ_One_VIP_Plus: 2907,
  ADITY_BIRLA_VYTL: 2908,
  CARE_ADVANTAGE: 2133,
  CARE_SUPREME: 2651,
  CARE_SENIOR_CITIZEN: 443,
  CARE_FREEDOM: 219,
  CARE_CARE: 56,
};
