import { Box, Modal, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import DatePicker from "../../../../../Component/FieldTypes/DatePicker/DatePicker";
import RKTextField from "../../../../../Component/FieldTypes/RKTextField/RKTextField";
import SearchDropdown from "../../../../../Component/FieldTypes/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import { useAppSelector } from "../../../../../redux/hooks";
import { TTermProposerData } from "../../../../../types/TTermSlice";
import TermFooter from "../../../../../Component/Term/TermFooter/TermFooter";
import ToggleSwitch from "../../../../../Component/ToggleSwitch/ToggleSwitch";
import Checkbox from "@mui/material/Checkbox";
import CustomButton from "../../../../../Component/CustomButton/CustomButton";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";

const ProposalBasicDetails = ({
  validateForm,
  updateMasterState,
  proposerDetails,
  loader,
  saleriedSelected,
  openModal,
  setOpenModal,
  LifeMilestoneData,
}: {
  validateForm: Function;
  updateMasterState: Function;
  proposerDetails: TTermProposerData;
  loader: boolean;
  saleriedSelected: boolean;
  openModal: boolean;
  setOpenModal: Function;
  LifeMilestoneData: any;
}) => {
  const dropdownData = useAppSelector((state) => state.Term.DROPDOWN_DATA);
  const update_status = (attrName: any, value: any) => {
    updateMasterState?.(attrName, value);
  };

  return (
    <>
      <Box>
        <h5 className="sectionTitle">Proposer Details</h5>
        <Grid container spacing={3}>
          <Grid xs={6}>
            <p>Salaried Discount Available</p>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Full Name As per PAN"}
              value={proposerDetails.full_name.value}
              attrName={"full_name"}
              validation_type="NAME"
              value_update={updateMasterState}
              warn_status={proposerDetails.full_name.warning}
              error_message="Enter full name "
              max_length={75}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={4}>
            <DatePicker
              class_name="inputField"
              title={"DOB"}
              value={proposerDetails.dob.value}
              attrName={"dob"}
              value_update={updateMasterState}
              error_message="Select DOB"
              warn_status={proposerDetails.dob.warning}
              min_date={60}
              disabled={true}
            />
          </Grid>
          <Grid xs={4}>
            <SelectDropdown
              class_name="inputField"
              title="Gender"
              value={proposerDetails.gender.value}
              attrName={"gender"}
              value_update={updateMasterState}
              data={dropdownData.GENDER}
              warn_status={proposerDetails.gender.warning}
              error_message="Select gender"
              disabled={true}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={4}>
            <RKTextField
              class_name="inputField"
              title={"Mobile"}
              value={proposerDetails.mobile.value}
              attrName={"mobile"}
              value_update={updateMasterState}
              warn_status={proposerDetails.mobile.warning}
              error_message="Enter mobile no."
              validation_type="NUMBER"
              max_length={10}
              disabled={true}
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Email"}
              value={proposerDetails.email.value}
              attrName={"email"}
              value_update={updateMasterState}
              warn_status={proposerDetails.email.warning}
              error_message={
                isEmpty(proposerDetails.email.value)
                  ? "Enter E-mail"
                  : "Enter Valid E-mail"
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={4}>
            <RKTextField
              class_name="inputField"
              title={"Pan"}
              value={proposerDetails.pan_no.value}
              attrName={"pan_no"}
              value_update={updateMasterState}
              warn_status={proposerDetails.pan_no.warning}
              error_message={
                isEmpty(proposerDetails.pan_no.value)
                  ? "Enter PAN"
                  : "Enter Valid Pan"
              }
              max_length={10}
              validation_type="ALPHANUMERIC"
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Pincode"}
              value={proposerDetails.pincode.value}
              attrName={"Pincode"}
              value_update={updateMasterState}
              warn_status={proposerDetails.pincode.warning}
              error_message="Enter emergency no."
              validation_type="NUMBER"
              max_length={10}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={6}>
            <SearchDropdown
              class_name="inputField"
              title="Qualification"
              value={proposerDetails.qualification.value}
              attrName={"qualification"}
              value_update={updateMasterState}
              data={dropdownData.QUALIFICATION_DATA}
              warn_status={proposerDetails.qualification.warning}
              error_message="Select qualification"
            />
          </Grid>
          <Grid xs={6}>
            <SearchDropdown
              class_name="inputField"
              title="Occupation"
              value={proposerDetails.occupation.value}
              attrName={"occupation"}
              value_update={updateMasterState}
              data={dropdownData.OCCUPATION_DATA}
              warn_status={proposerDetails.occupation.warning}
              error_message="Select occupation"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {saleriedSelected && (
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Corporate Email Id"}
                value={proposerDetails.corporate_email_id.value}
                attrName={"corporate_email_id"}
                value_update={updateMasterState}
                warn_status={proposerDetails.corporate_email_id.warning}
                error_message="Enter Valid Corporate E-mail"
              />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={3}>
          <Grid
            xs={6}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <h6>Life Stage Discount</h6>
            <ToggleSwitch
              toggle_status={proposerDetails.lifeMileStoneDiscount.value}
              value_update={update_status}
              attrName={"lifeMileStoneDiscount"}
            />
          </Grid>
        </Grid>
        {proposerDetails.lifeMileStoneDiscount.value && (
          <Grid container spacing={3}>
            <Grid xs={6}>
              <SearchDropdown
                class_name="inputField"
                title="Life Milestones"
                value={proposerDetails.lifeMileStoneType.value}
                attrName={"lifeMileStoneType"}
                value_update={updateMasterState}
                data={LifeMilestoneData}
                warn_status={proposerDetails.lifeMileStoneType.warning}
                error_message="Select lifeMile Stone Type"
              />
            </Grid>
            {(proposerDetails.lifeMileStoneType.value === "7" ||
              proposerDetails.lifeMileStoneType.value === "8") && (
              <Grid xs={6}>
                <DatePicker
                  class_name="inputField"
                  title={"Date of Event"}
                  value={proposerDetails.lifeMileStonedate.value}
                  attrName={"lifeMileStonedate"}
                  value_update={updateMasterState}
                  error_message="Select life MileStone date"
                  warn_status={proposerDetails.lifeMileStonedate.warning}
                  min_date={179}
                  max_date={-180}
                  date_validation_type="DAYS"
                />
              </Grid>
            )}

            {(proposerDetails.lifeMileStoneType.value === "9" ||
              proposerDetails.lifeMileStoneType.value === "10") && (
              <Grid xs={6}>
                <DatePicker
                  class_name="inputField"
                  title={"Date of Event"}
                  value={proposerDetails.lifeMileStonedate.value}
                  attrName={"lifeMileStonedate"}
                  value_update={updateMasterState}
                  error_message="Select date"
                  warn_status={proposerDetails.lifeMileStonedate.warning}
                  min_date={0}
                  max_date={-180}
                  date_validation_type="DAYS"
                />
              </Grid>
            )}
          </Grid>
        )}

        {saleriedSelected && (
          <Grid xs={12}>
            <p>
              <Checkbox
                defaultChecked={false}
                onChange={(e) => {
                  updateMasterState("isSalariedConsent", e.target.checked);
                }}
                checked={Boolean(proposerDetails?.isSalariedConsent?.value)}
              />
              I confirm that I am a salaried individual and my employer details
              provided by me are correct.
            </p>
          </Grid>
        )}
        <TermFooter forward={validateForm} loader={loader} />
      </Box>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        className="modalWrapper"
      >
        <Box className="modalContent xl-width kycPopups">
          <Grid container spacing={3}>
            <Grid xs={12}>
              <h4 className="popup-heading mb-5">Corporate Email</h4>
              <Link
                className="close-button"
                onClick={() => setOpenModal(false)}
              />
              <hr />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <p>
                You do not have a valid Email Id. To avail the salaried discount
                benefit you will have to mandatorily upload the photo of your
                Employee Id Card or Latest Salary slip, later in the application
              </p>
              <p>
                In document upload section - Mandatory to Upload Employee
                ID/Salary Slip
              </p>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={12} className="ctaBtn my-4">
              <hr />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={12} className="ctaBtn" textAlign={"center"}>
              <CustomButton
                disabled={window.location.pathname.includes("health")}
                text_name={"OK"}
                class_name="greenMdBtn"
                onClickFunction={() => {
                  setOpenModal(false);
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default ProposalBasicDetails;
