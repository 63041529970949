import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import sessionStorage from "redux-persist/es/storage/session";
import { CKYCSlice } from "../slice/CKYC/CKYCSlice";
import { CarSlice } from "../slice/Car/CarSlice";
import { CommonDropdownSlice } from "../slice/CommonDropdown/CommonDropdownSlice";
import { HealthSlice } from "../slice/Health/HealthSlice";
import { PaymentGatewaySlice } from "../slice/PaymentGateway/PaymentGatewaySlice";
import { TermSlice } from "../slice/Term/TermSlice";
import { TWSlice } from "../slice/TwoWheeler/TWSlice";
import { TermFullJourneySlice } from "../slice/TermFullJourney/TermFullJourneySlice";
import { householdDetailsSlice } from "../slice/HI/HouseholdDetails/HouseholdDetailSlice";
import { quoteSlice } from "../slice/HI/Quotes/QuoteSlice";
import { proposalSlice } from "../slice/HI/Proposal/ProposalSlice";
import { kycResponseSlice } from "../slice/HI/KycResponse/KycResponseSlice";
import { pageSlice } from "../slice/HI/PageSlice/PageSlice";
import { TravelSlice } from "../slice/Travel/TravelSlice";
import { NonTermSlice } from "../slice/NonTerm/NonTermSlice";

const rootReducer = combineReducers({
  CommonDropdownData: CommonDropdownSlice.reducer,
  TW: TWSlice.reducer,
  Car: CarSlice.reducer,
  Term: TermSlice.reducer,
  NonTerm: NonTermSlice.reducer,
  TermFullJourney: TermFullJourneySlice.reducer,
  Health: HealthSlice.reducer,
  Travel: TravelSlice.reducer,
  CKYC: CKYCSlice.reducer,
  PAYMENT_GATEWAY: PaymentGatewaySlice.reducer,

  householdDetails: householdDetailsSlice.reducer,
  quote: quoteSlice.reducer,
  proposalInformation: proposalSlice.reducer,
  kycResponseSlice: kycResponseSlice.reducer,
  pageSlice: pageSlice.reducer,
});

// Configure Redux Persist with session storage
const persistConfig = {
  key: "proposal root",
  storage: sessionStorage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Create the Redux Persist store
const persistor = persistStore(store);

export { persistor, store };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
