import React from "react";
import { TStarMedicalQuestion } from "../../../../types/Health/HealthQuotation/TStarMedicalQuestion";
import { Box, Collapse } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import SuperStarMedicalQuestion from "../../../../Component/MedicalQuestion/SuperStarMedicalQuestion";
import MemberRadioButtonNew from "../../../../Component/MemberRadioButton/MemberRadioButtonNew";
import CustomCheckbox from "../../../../Component/FieldTypes/CustomCheckbox/CustomCheckbox";
import RKTextField from "../../../../Component/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import HealthFooter from "../../health-php/HealthFooter/HealthFooter";
import { useAppSelector } from "../../../../redux/hooks";
import { calculateAgeInDays } from "../../../../SupportingFiles/HelpingFunction";
import { subDays } from "date-fns";
import {
  ASTHMA_PULMONARY_TEST,
  ASTHMA_SYMPTOMS,
  ASTHMA_WHEN,
  Diabetes_HBA1C,
  Diabetes_type,
  Diabetes_when,
  HYPERLIPIDAEMIA_CHOLESTROL_LEVEL,
  HYPERLIPIDAEMIA_ORAL_MEDICINE_COUNT,
  HYPERLIPIDAEMIA_WHEN,
  HYPERTENSION_WHEN,
  Yes_Or_No,
} from "../../../../Container/health-node/STAR/MedicalDetailsContainer/SuperStarMedicalDropdown";
import DatePicker from "../../../../Component/FieldTypes/DatePicker/DatePicker";

const MedicalDetailsSuperStar = ({
  medicalQuestionData,
  updateMasterState,
  validateForm,
  PAGE_STATUS,
}: {
  medicalQuestionData: TStarMedicalQuestion;
  updateMasterState: Function;
  validateForm: Function;
  PAGE_STATUS: boolean;
}) => {
  const { INSURED_MEMBER_DETAILS } = useAppSelector((state) => state.Health);
  const getMinDate = (keyName: any) => {
    switch (keyName) {
      case "adult_one":
        return `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
          ? calculateAgeInDays(
              `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
            )
          : 25;
      case "adult_two":
        return calculateAgeInDays(
          `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`
        );
      case "child_one":
        return calculateAgeInDays(
          `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`
        );
      case "child_two":
        return calculateAgeInDays(
          `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`
        );
      case "child_three":
        return calculateAgeInDays(
          `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`
        );
      case "child_four":
        return calculateAgeInDays(
          `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`
        );
      default:
        return 25;
    }
  };

  return (
    <Box>
      <h5 className="sectionTitle">Underwriting Details</h5>
      <>
        {Object.values(medicalQuestionData).map(
          (data: any, indexMainQuestion: number) => (
            <div key={indexMainQuestion}>
              <SuperStarMedicalQuestion
                key={indexMainQuestion}
                main_class="medicalQuestion borderBottom"
                icon_class={data?.main_question?.icon_class}
                question_name={data?.main_question?.title}
                sub_details={data?.main_question?.description}
                input_id_first={data?.main_question?.id}
                input_id_second={data?.main_question?.id}
                input_name="diagnosed"
                toggle_button_status={data?.main_question?.status}
                attrName={["question", data?.main_question?.id]}
                value_update={updateMasterState}
                data={data}
                updateMasterState={updateMasterState}
                select_disease={
                  <Collapse
                    in={data?.main_question?.status}
                    className="medicalQuestion-SubSection"
                  >
                    <Grid container spacing={3} className="pr-2 mb-3">
                      {data?.main_question?.sub_question && (
                        <Grid xs={12}>
                          {/* <h5>High or low blood pressure</h5> */}
                          {data?.main_question?.sub_question &&
                            Object.values(
                              data?.main_question?.sub_question
                            )?.map(
                              (
                                dataSubQuestion: any,
                                indexSubQuestion: number
                              ) => (
                                <>
                                  {dataSubQuestion.id !== "MQ4-SQ4" ? (
                                    <SuperStarMedicalQuestion
                                      key={indexSubQuestion}
                                      main_class="medicalQuestion borderBottom"
                                      icon_class={"tobaco"}
                                      question_name={dataSubQuestion?.title}
                                      sub_details={
                                        <p>{dataSubQuestion?.description}</p>
                                      }
                                      input_id_first={dataSubQuestion?.id}
                                      input_id_second={dataSubQuestion?.id}
                                      input_name="blood pressure"
                                      toggle_button_status={
                                        dataSubQuestion?.status
                                      }
                                      attrName={[
                                        "subQuestion",
                                        data?.main_question?.id,
                                        dataSubQuestion?.id,
                                      ]}
                                      value_update={updateMasterState}
                                      data={data}
                                      dataSubQuestion={dataSubQuestion}
                                      updateMasterState={updateMasterState}
                                      select_disease={
                                        <Collapse
                                          in={dataSubQuestion?.status}
                                          className="medicalQuestion-SubSection"
                                        >
                                          <Grid container spacing={2}>
                                            {dataSubQuestion?.member_data &&
                                              dataSubQuestion?.status &&
                                              Object.values(
                                                dataSubQuestion?.member_data
                                              )?.map(
                                                (
                                                  memberDataValue: any,
                                                  memberIndex: number
                                                ) => (
                                                  <React.Fragment
                                                    key={memberIndex}
                                                  >
                                                    {memberDataValue.name !==
                                                      "" && (
                                                      <Grid xs={4}>
                                                        <MemberRadioButtonNew
                                                          class_name={
                                                            memberIndex === 0 ||
                                                            memberIndex === 1
                                                              ? "adult"
                                                              : "child"
                                                          }
                                                          checked={
                                                            memberDataValue?.selection_status
                                                          }
                                                          label_name={
                                                            memberDataValue.name
                                                          }
                                                          attrName={[
                                                            "mainMember",
                                                            data?.main_question
                                                              ?.id,
                                                            dataSubQuestion.id,
                                                            memberDataValue?.keyName,
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                        />
                                                        {memberDataValue?.selection_status &&
                                                          memberDataValue.keyName && (
                                                            <>
                                                              {Object.values(
                                                                memberDataValue.field_data
                                                              ).map(
                                                                (
                                                                  field_data_data: any
                                                                ) => {
                                                                  let datePickerRendered =
                                                                    false; // Flag to track DatePicker rendering
                                                                  return (
                                                                    <React.Fragment
                                                                      key={
                                                                        field_data_data.id
                                                                      }
                                                                    >
                                                                      {field_data_data.field_type ===
                                                                      "DROPDOWN" ? (
                                                                        <SelectDropdown
                                                                          class_name=" inputField mb-5"
                                                                          title={
                                                                            field_data_data.title
                                                                          }
                                                                          attrName={[
                                                                            "mainMemberDataField",
                                                                            data
                                                                              ?.main_question
                                                                              ?.id,
                                                                            memberDataValue.keyName,
                                                                            field_data_data.id,
                                                                            dataSubQuestion.id,
                                                                          ]}
                                                                          value={
                                                                            field_data_data
                                                                              ?.value
                                                                              ?.value
                                                                          }
                                                                          value_update={
                                                                            updateMasterState
                                                                          }
                                                                          warn_status={
                                                                            field_data_data
                                                                              ?.value
                                                                              ?.warning
                                                                          }
                                                                          data={
                                                                            field_data_data.title ===
                                                                            "Duration of Diabetes"
                                                                              ? Diabetes_when
                                                                              : field_data_data.title ===
                                                                                "Type of Diabetes"
                                                                              ? Diabetes_type
                                                                              : field_data_data.title ===
                                                                                "DIABETES HBA1C"
                                                                              ? Diabetes_HBA1C
                                                                              : field_data_data.title ===
                                                                                "HYPERTENSION DURATION"
                                                                              ? HYPERTENSION_WHEN
                                                                              : field_data_data.title ===
                                                                                "ASTHMA DURATION"
                                                                              ? ASTHMA_WHEN
                                                                              : field_data_data.title ===
                                                                                "Symptoms"
                                                                              ? ASTHMA_SYMPTOMS
                                                                              : field_data_data.title ===
                                                                                "ASTHMA PULMONARY TEST"
                                                                              ? ASTHMA_PULMONARY_TEST
                                                                              : field_data_data.title ===
                                                                                "Duration since detection"
                                                                              ? HYPERLIPIDAEMIA_WHEN
                                                                              : field_data_data.title ===
                                                                                "Number of oral medicines"
                                                                              ? HYPERLIPIDAEMIA_ORAL_MEDICINE_COUNT
                                                                              : field_data_data.title ===
                                                                                "Cholesterol level"
                                                                              ? HYPERLIPIDAEMIA_CHOLESTROL_LEVEL
                                                                              : Yes_Or_No
                                                                          }
                                                                          error_message={
                                                                            "Enter description"
                                                                          }
                                                                        />
                                                                      ) : (
                                                                        ""
                                                                      )}
                                                                      {field_data_data.field_type ===
                                                                      "TEXTFIELD" ? (
                                                                        <RKTextField
                                                                          class_name=" inputField mb-5"
                                                                          title={
                                                                            field_data_data.title
                                                                          }
                                                                          attrName={[
                                                                            "mainMemberDataField",
                                                                            data
                                                                              ?.main_question
                                                                              ?.id,
                                                                            memberDataValue.keyName,
                                                                            field_data_data.id,
                                                                            dataSubQuestion.id,
                                                                          ]}
                                                                          value={
                                                                            field_data_data
                                                                              ?.value
                                                                              ?.value
                                                                          }
                                                                          value_update={
                                                                            updateMasterState
                                                                          }
                                                                          warn_status={
                                                                            field_data_data
                                                                              ?.value
                                                                              ?.warning
                                                                          }
                                                                          error_message={
                                                                            "Enter description"
                                                                          }
                                                                        />
                                                                      ) : (
                                                                        ""
                                                                      )}
                                                                      {field_data_data.field_type ===
                                                                        "DATEPICKER" && (
                                                                        <>
                                                                          {/* <DatePicker
                                                                            class_name="inputField mb-5"
                                                                            date_validation_type="DAYS"
                                                                            error_message="Select existing since"
                                                                            title={
                                                                              field_data_data?.title
                                                                            }
                                                                            value={
                                                                              field_data_data
                                                                                ?.value
                                                                                ?.value
                                                                            }
                                                                            attrName={[
                                                                              "mainMemberDataField",
                                                                              data
                                                                                ?.main_question
                                                                                ?.id,
                                                                              memberDataValue.keyName,
                                                                              field_data_data.id,
                                                                              dataSubQuestion.id,
                                                                            ]}
                                                                            value_update={
                                                                              updateMasterState
                                                                            }
                                                                            min_date={getMinDate(
                                                                              memberDataValue.keyName
                                                                            )}
                                                                            max_date={
                                                                              0
                                                                            }
                                                                            default_date={subDays(
                                                                              new Date(),
                                                                              getMinDate(
                                                                                memberDataValue.keyName
                                                                              )
                                                                            )}
                                                                            warn_status={
                                                                              field_data_data
                                                                                ?.value
                                                                                ?.warning
                                                                            }
                                                                          /> */}
                                                                        </>
                                                                      )}
                                                                    </React.Fragment>
                                                                  );
                                                                }
                                                              )}
                                                            </>
                                                          )}
                                                      </Grid>
                                                    )}
                                                  </React.Fragment>
                                                )
                                              )}
                                          </Grid>
                                        </Collapse>
                                      }
                                    />
                                  ) : null}
                                </>
                              )
                            )}
                        </Grid>
                      )}
                    </Grid>
                  </Collapse>
                }
              />
            </div>
          )
        )}
      </>
      <HealthFooter
        textName={PAGE_STATUS ? "Update" : "Continue"}
        forward={() => validateForm()}
      />
    </Box>
  );
};

export default MedicalDetailsSuperStar;
