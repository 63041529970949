import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import { HEALTH_ROUTE } from "../../Router/Path/HealthRoutes";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { HealthSlice } from "../../redux/slice/Health/HealthSlice";
import {
  FORMAT_DATE_DD_MM_YYYY,
  isEmpty,
} from "../../SupportingFiles/HelpingFunction";
import "./../../SCSS/CommonSCSS/MProposalForms.scss";
import { useEffect, useState } from "react";

const MHealthPreviewDetailBox = ({
  detailBoxName,
  detailBoxData,
  url,
  isMailingAddresssame,
  mailing_detail_view,
}: {
  detailBoxName: String;
  url?: any;
  detailBoxData: Array<{
    label: string;
    label_value: string;
  }>;
  isMailingAddresssame?: boolean;
  mailing_detail_view?: any;
}) => {
  console.log("detailBoxData", detailBoxData);
  const { SELECTED_QUOTE_DATA, INSURED_MEMBER_DETAILS, ADD_FORM_RESPONSE } =
    useAppSelector((state) => state.Health);
  const { TW, Car } = useAppSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [param, setParam] = useState("");
  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const payment = params.get("cus_type");
    setParam(payment || "");
  }, []);

  return (
    <>
      {detailBoxData?.length !== 0 && Array.isArray(detailBoxData) ? (
        <Box className="previewBox">
          <Grid
            container
            columnSpacing={1}
            rowSpacing={0}
            alignItems={"center"}
            margin={0}
            className="row"
          >
            <Grid xs={8}>
              <p className="previewBoxTitle">{detailBoxName}</p>
            </Grid>
            <Grid xs={4} textAlign={"right"}>
              <Link
                onClick={() => {
                  if (
                    detailBoxName === "Adult 1 Details" ||
                    detailBoxName === "Nominee Details"
                  ) {
                    if (
                      INSURED_MEMBER_DETAILS.PROPOSER_DETAILS
                        .IS_PROPOSER_INSURED
                    ) {
                      navigate(
                        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
                          HEALTH_ROUTE.ADULT_ONE_DETAILS
                        }?type=HP&companyCode=${
                          SELECTED_QUOTE_DATA?.CompanyDetails.company_code
                        }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
                          SELECTED_QUOTE_DATA?.productDetails.id
                        }${param === "user" ? "&user_type=user" : ""}`
                      );
                    } else {
                      navigate(
                        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
                          HEALTH_ROUTE.INSURED_MEMBER
                        }?type=HP&companyCode=${
                          SELECTED_QUOTE_DATA?.CompanyDetails.company_code
                        }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
                          SELECTED_QUOTE_DATA?.productDetails.id
                        }${param === "user" ? "&user_type=user" : ""}`
                      );
                    }
                  } else {
                    navigate(
                      `${url}?type=HP&companyCode=${
                        SELECTED_QUOTE_DATA?.CompanyDetails.company_code
                      }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
                        SELECTED_QUOTE_DATA?.productDetails.id
                      }${param === "user" ? "&user_type=user" : ""}`
                    );
                  }
                  dispatch(HealthSlice.actions.CHANGE_PAGE_STATUS(true));
                }}
                className="previewBoxEdit"
              >
                Edit
              </Link>
            </Grid>
            <Grid xs={12}>
              <ul>
                {Array.isArray(detailBoxData) &&
                  detailBoxData.length >= 0 &&
                  detailBoxData.map((data: any, index: any) =>
                    isEmpty(data?.label_value) ? (
                      ""
                    ) : (
                      <>
                        <li>
                          <p>
                            {data?.label}: <span>{data?.label_value}</span>
                          </p>
                        </li>
                      </>
                    )
                  )}
              </ul>
              {isMailingAddresssame && detailBoxName === "Address Details" ? (
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  marginTop={"4px"}
                  border={"1px dashed #ccc"}
                  borderRadius={"8px"}
                  padding={"4px"}
                >
                  <TaskAltIcon
                    sx={{
                      color: "#00785A",
                      marginRight: "3px",
                      height: "16px",
                    }}
                  />
                  <p style={{ fontSize: "12px", color: "#00785A" }}>
                    Mailing / Correspondence address is same as permanent
                    address.
                  </p>
                </Box>
              ) : (
                <>
                  {!isMailingAddresssame && mailing_detail_view?.length && (
                    <p
                      style={{
                        fontSize: "13px",
                        fontWeight: 600,
                        textTransform: "uppercase",
                        marginBottom: "8px",
                        marginTop: "8px",
                      }}
                    >
                      {" "}
                      Mailing Address
                    </p>
                  )}

                  <ul>
                    {mailing_detail_view?.map((data: any) => (
                      <li>
                        <p>
                          {data.label}:{""}
                          <span>
                            {data.label === "DOB"
                              ? FORMAT_DATE_DD_MM_YYYY(data?.label_value)
                              : data?.label_value}
                          </span>
                        </p>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </>
  );
};

export default MHealthPreviewDetailBox;
