import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { useAppSelector } from "../../redux/hooks";
import {
  calculateAgeInDays,
  isEmpty,
} from "../../SupportingFiles/HelpingFunction";
import MemberRadioButtonNew from "../MemberRadioButton/MemberRadioButtonNew";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import "./MedicalQuestion.scss";
import SelectDropdown from "../FieldTypes/SelectDropdown/SelectDropdown";
import DatePicker from "../FieldTypes/DatePicker/DatePicker";
import { subDays } from "date-fns";
import RKTextField from "../FieldTypes/RKTextField/RKTextField";

interface Props {
  main_class?: string;
  icon_class?: string;
  question_name?: string;
  sub_details?: any;
  input_id_first?: string;
  input_id_second?: string;
  input_name?: string;
  toggle_button_status?: boolean;
  value_update?: Function;
  attrName?: any;
  select_disease?: any;
  data?: any;
  dataSubQuestion?: any;
  updateMasterState?: any;
}
const MedicalQuestionRSA: React.FC<Props> = ({
  main_class,
  icon_class,
  question_name,
  sub_details,
  input_id_first,
  input_id_second,
  input_name,
  value_update,
  toggle_button_status,
  attrName,
  data,
  select_disease,
  dataSubQuestion,
  updateMasterState,
}) => {
  const { INSURED_MEMBER_DETAILS, ADD_FORM, KYC_DETAILS } = useAppSelector(
    (state) => state.Health
  );
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  const update_status = (attrName: any, value: any) => {
    value_update?.(attrName, value);
  };

  const getMonthData = () => {
    const Month_Data: any = [];
    for (let i = 1; i <= 12; i++) {
      Month_Data.push({ value: `${i}`, label: ` ${i}` });
    }
    return Month_Data;
  };

  const getYearDropdownData = () => {
    let yearList = [];
    const yearMax = new Date().getFullYear();
    const yearMin = yearMax - 10;
    for (let i = yearMin; i <= yearMax; i++) {
      yearList.push({ label: `${i}`, value: `${i}` });
    }
    return yearList;
  };

  const getDropDownData = (id: string) => {
    switch (id) {
      case "MQ1-SQ1-F2": {
        return [
          { value: "Recieved", label: "Recieved" },
          { value: "Receiving", label: "Receiving" },
        ];
      }
      case "MQ1-SQ1-F3": {
        return getMonthData();
      }
      case "MQ1-SQ1-F4": {
        return getYearDropdownData();
      }
      case "MQ1-SQ1-F5": {
        return [
          { value: "Fully Cured", label: "Fully Cured" },
          { value: "Partially Cured", label: "Partially Cured" },
          { value: "Ongoing", label: "Ongoing" },
        ];
      }
    }
  };

  return (
    <Box className={main_class}>
      <Grid container spacing={2}>
        <Grid xs={8} md={9} className="medicalQuestionInner">
          <span className={icon_class}></span>
          <div>
            <h6>{isEmpty(question_name) ? sub_details : question_name}</h6>
            {isEmpty(question_name) ? null : <p>{sub_details}</p>}
          </div>
        </Grid>
        <Grid xs={4} md={3} textAlign="right" className="pt-4">
          <ToggleSwitch
            toggle_status={toggle_button_status}
            value_update={update_status}
            attrName={attrName}
          />
        </Grid>
      </Grid>
      {select_disease}

      <Grid container spacing={2}>
        {data?.main_question?.member_data &&
          data?.main_question?.status &&
          Object.values(data?.main_question?.member_data)?.map(
            (memberDataValue: any, memberIndex: number) => (
              <React.Fragment key={memberIndex}>
                {memberDataValue.name !== "" && (
                  <Grid xs={4}>
                    <MemberRadioButtonNew
                      class_name={
                        memberIndex === 0 || memberIndex === 1
                          ? "adult"
                          : "child"
                      }
                      checked={memberDataValue?.selection_status}
                      label_name={memberDataValue.name}
                      attrName={[
                        "mainMember",
                        data?.main_question?.id,
                        memberDataValue?.keyName,
                      ]}
                      value_update={updateMasterState}
                    />
                    {memberDataValue?.selection_status &&
                      memberDataValue.field_data &&
                      memberDataValue.keyName && (
                        <>
                          {Object.values(memberDataValue.field_data).map(
                            (field_data_data: any) => {
                              return (
                                <React.Fragment key={field_data_data.id}>
                                  {field_data_data.field_type ===
                                  "TEXTFIELD" ? (
                                    <RKTextField
                                      id="mainMemberDataField"
                                      class_name="mb-5"
                                      title={field_data_data?.title}
                                      attrName={[
                                        "mainMemberDataField",
                                        data?.main_question?.id,
                                        memberDataValue.keyName,
                                        field_data_data.id,
                                        "desc",
                                      ]}
                                      value={field_data_data?.value?.value}
                                      value_update={updateMasterState}
                                      warn_status={
                                        field_data_data?.value?.warning
                                      }
                                      error_message={"Enter description"}
                                    />
                                  ) : field_data_data.field_type ===
                                    "DROPDOWN" ? (
                                    // Render your dropdown component here

                                    <SelectDropdown
                                      class_name="inputField mb-5"
                                      title={field_data_data.title}
                                      attrName={[
                                        "mainMemberDataField",
                                        data?.main_question?.id,
                                        memberDataValue.keyName,
                                        field_data_data.id,
                                      ]}
                                      value={field_data_data?.value?.value}
                                      value_update={updateMasterState}
                                      warn_status={
                                        field_data_data?.value?.warning
                                      }
                                      data={getDropDownData(field_data_data.id)}
                                      error_message={"Enter description"}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </React.Fragment>
                              );
                            }
                          )}
                        </>
                      )}
                  </Grid>
                )}
              </React.Fragment>
            )
          )}
      </Grid>
    </Box>
  );
};

export default MedicalQuestionRSA;
