import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subYears } from "date-fns";
import DatePicker from "../../../../../Component/FieldTypes/DatePicker/DatePicker";
import RKTextField from "../../../../../Component/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import MFooter from "../../../../../Component/MFooter/MFooter";
import MemberRadioButton from "../../../../../Component/MemberRadioButton/MemberRadioButton";
import { ECompanyCode, EProductId } from "../../../../../Services/Enum/EHome";
import { HealthSlice } from "../../../../../redux/slice/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { GET_DATE_VALIDATION } from "../../../../../SupportingFiles/HealthHelper";
import { TAdultOneDetails } from "../../../../../types/Health/ProposalDeatail/TProposalDetail";
import { THealthNomineeDetails } from "../../../../../types/Health/THealthSlice";
import { TDropdownData } from "../../../../../types/DropdownData/DropdownData";

function InsuredMember({
  proposerFields,
  nomineeDetails,
  fieldsUpdateState,
  validateForm,
  handleClick,
  adultOneDetails,
  occupationList,
  relationshipList,
  maritalStatusList,
  nomineeRealtionshipList,
}: {
  proposerFields: TAdultOneDetails;
  adultOneDetails: TAdultOneDetails;
  nomineeDetails: THealthNomineeDetails;
  fieldsUpdateState: Function;
  validateForm: Function;
  handleClick: Function;
  occupationList: TDropdownData[];
  relationshipList: TDropdownData[];
  maritalStatusList: TDropdownData[];
  nomineeRealtionshipList: TDropdownData[];
}) {
  const dispatch = useAppDispatch();
  const {
    ADD_FORM,
    DROPDOWN_DATA,
    PAGE_STATUS,
    NOMINEE_RELATIONSHIP_STATUS,
    INSURED_MEMBER_DETAILS,
    SELECTED_QUOTE_DATA,
    ADDON_DETAILS,
  } = useAppSelector((state) => state.Health);

  const annual_income_niva_bupa = [];
  for (
    let incomeValue = 100000;
    incomeValue <= 10000000;
    incomeValue += 100000
  ) {
    let label;
    if (incomeValue === 10000000) {
      label = "1 Crore";
    } else {
      label = `${incomeValue / 100000} Lakh`;
    }
    annual_income_niva_bupa.push({ label: label, value: `${incomeValue}` });
  }

  const EDUCATION = [
    { label: "Matric", value: "Matric" },
    { label: "Non-Matric", value: "Non-Matric" },
    { label: "Graduate", value: "Graduate" },
    { label: "Post-Graduate", value: "Post-Graduate" },
    { label: "Professional Course", value: "Professional Course" },
  ];
  const { HEIGHT_FEET, HEIGHT_INCH, NIVA_BUPA_OCCUPATION } = useAppSelector(
    (state) => state.CommonDropdownData
  );
  const hdfcProductIds = [
    ECompanyCode.HDFC_HEALTH,
    ECompanyCode.HDFC_HEALTH,
    EProductId.HDFC_HEALTH,
    EProductId.HDFC_HEALTH_Global,
    EProductId.HDFC_HEALTH_Plan,
  ];

  let ANNUAL_INCOME = [
    {
      label: "1 Lakh",
      value: "100000",
    },
    {
      label: "2 Lakh",
      value: "200000",
    },
    {
      label: "3 Lakh",
      value: "300000",
    },
    {
      label: "4 Lakh",
      value: "400000",
    },
    {
      label: "5 Lakh",
      value: "500000",
    },
    {
      label: "6 Lakh",
      value: "600000",
    },
    {
      label: "7 Lakh",
      value: "700000",
    },
    {
      label: "8 Lakh",
      value: "800000",
    },
    {
      label: "9 Lakh",
      value: "900000",
    },
    {
      label: "10 Lakh",
      value: "1000000",
    },
  ];
  return (
    <Box>
      <h5 className="sectionTitle">
        1<sup>st</sup> Adult Details
      </h5>
      <>
        <Grid container spacing={2}>
          <Grid xs={12} sm={6}>
            <RKTextField
              class_name="inputField"
              title="Full Name"
              value={adultOneDetails.name.value}
              attrName={["name", "adultOneDetails"]}
              value_update={fieldsUpdateState}
              warn_status={adultOneDetails.name.warning}
              error_message={
                adultOneDetails.name.value === "" ? "Enter Name" : ""
              }
            />
          </Grid>

          <Grid xs={12} sm={6} md={3}>
            <DatePicker
              class_name="inputField"
              title={"DOB"}
              value={adultOneDetails.dob.value}
              attrName={["dob", "adultOneDetails"]}
              value_update={fieldsUpdateState}
              error_message="Select DOB"
              warn_status={adultOneDetails.dob.warning}
              date_validation_type={
                GET_DATE_VALIDATION(
                  SELECTED_QUOTE_DATA.CompanyDetails.company_code,
                  `${SELECTED_QUOTE_DATA.productDetails.id}`
                ).validation_type
              }
              min_date={
                GET_DATE_VALIDATION(
                  SELECTED_QUOTE_DATA.CompanyDetails.company_code,
                  `${SELECTED_QUOTE_DATA.productDetails.id}`
                ).min_date
              }
              max_date={
                GET_DATE_VALIDATION(
                  SELECTED_QUOTE_DATA.CompanyDetails.company_code,
                  `${SELECTED_QUOTE_DATA.productDetails.id}`
                ).max_date
              }
              default_date={
                SELECTED_QUOTE_DATA.productDetails.id === 2843
                  ? subYears(new Date(), 56)
                  : subYears(new Date(), 18)
              }
            />
          </Grid>

          <Grid xs={12} sm={6} md={5}>
            {!INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.IS_PROPOSER_INSURED && (
              <SelectDropdown
                disabled={PAGE_STATUS}
                class_name="inputField"
                title="Relationship"
                value={adultOneDetails.relationship.value}
                attrName={["relationship", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                data={[
                  { value: "Spouse", label: "Spouse" },
                  { value: "Father", label: "Father" },
                  { value: "Mother", label: "Mother" },
                ]}
                warn_status={adultOneDetails.relationship.warning}
                error_message="Select Relationship"
              />
            )}
          </Grid>

          <Grid xs={12} sm={6} md={4}>
            <RKTextField
              validation_type="NUMBER"
              max_length={3}
              class_name="inputField"
              title="Weight (Kg)"
              value={adultOneDetails.weight.value}
              attrName={["weight", "adultOneDetails"]}
              value_update={fieldsUpdateState}
              warn_status={adultOneDetails.weight.warning}
              error_message="Enter Weight"
            />
          </Grid>

          <Grid xs={12} sm={6} md={8}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <SelectDropdown
                class_name="inputField mr-2"
                title="Feet"
                value={adultOneDetails.heightFeet.value}
                attrName={["heightFeet", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                data={HEIGHT_FEET}
                warn_status={adultOneDetails.heightFeet.warning}
                error_message="Select Height Feet"
              />
              <SelectDropdown
                class_name="inputField"
                title="Inches"
                value={adultOneDetails.heightInches.value}
                attrName={["heightInches", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                data={HEIGHT_INCH}
                warn_status={adultOneDetails.heightInches.warning}
                error_message="Select Height Inches"
              />
            </div>
          </Grid>
          {SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G015" ? null : (
            <Grid xs={12} sm={6} md={4}>
              <SelectDropdown
                class_name="inputField"
                title="Marital Status"
                value={adultOneDetails.maritalStatus.value}
                attrName={["maritalStatus", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                // data={DROPDOWN_DATA.MARTIAL}
                data={maritalStatusList}
                warn_status={adultOneDetails.maritalStatus.warning}
                error_message="Select Marital Status"
              />
            </Grid>
          )}
          {SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G015" ? (
            <Grid xs={6} md={5} lg={4}>
              <SelectDropdown
                class_name="inputField"
                title="Occupation"
                value={adultOneDetails.occupation.value}
                attrName={["occupation", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                // data={DROPDOWN_DATA.OCCUPATION}
                data={occupationList}
                warn_status={adultOneDetails.occupation.warning}
                error_message="Select occupation Status"
              />
            </Grid>
          ) : (
            <></>
          )}

          {(SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.HDFC_HEALTH ||
            hdfcProductIds.includes(SELECTED_QUOTE_DATA.productDetails.id)) && (
            <>
              <Grid xs={12} sm={6} md={4}>
                <SelectDropdown
                  class_name="inputField"
                  title="Occupation"
                  value={adultOneDetails.occupation.value}
                  attrName={["occupation", "adultOneDetails"]}
                  value_update={fieldsUpdateState}
                  // data={DROPDOWN_DATA.OCCUPATION}
                  data={occupationList}
                  warn_status={adultOneDetails.occupation.warning}
                  error_message="Select Occupation Status"
                />
              </Grid>

              <Grid xs={12}>
                <SelectDropdown
                  class_name="inputField"
                  title="Annual Income"
                  value={adultOneDetails?.annual_income?.value}
                  attrName={["annual_income", "adultOneDetails"]}
                  value_update={fieldsUpdateState}
                  data={ANNUAL_INCOME}
                  warn_status={adultOneDetails?.annual_income?.warning}
                  error_message="Select Annual Income"
                />
              </Grid>
            </>
          )}
          {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
          ECompanyCode.NIVA_BUPA ? (
            <>
              <Grid xs={6} md={5} lg={4}>
                <SelectDropdown
                  class_name="inputField"
                  title="Occupation"
                  value={adultOneDetails.occupation.value}
                  attrName={["occupation", "adultOneDetails"]}
                  value_update={fieldsUpdateState}
                  // data={NIVA_BUPA_OCCUPATION}
                  data={occupationList}
                  warn_status={adultOneDetails.occupation.warning}
                  error_message="Select Occupation Status"
                />
              </Grid>
              <Grid xs={6} md={5} lg={4}>
                <SelectDropdown
                  class_name="inputField"
                  title="Qualification"
                  value={adultOneDetails.qualification.value}
                  attrName={["qualification", "adultOneDetails"]}
                  value_update={fieldsUpdateState}
                  data={EDUCATION}
                  warn_status={adultOneDetails.qualification.warning}
                  error_message="Select Qualification Status"
                />
              </Grid>
              <Grid xs={6} md={5} lg={4}>
                <SelectDropdown
                  class_name="inputField"
                  title="Annual Income"
                  value={adultOneDetails.annual_income.value}
                  attrName={["annual_income", "adultOneDetails"]}
                  value_update={fieldsUpdateState}
                  data={annual_income_niva_bupa}
                  warn_status={adultOneDetails.annual_income.warning}
                  error_message="Select Annual Income Status"
                />
              </Grid>
            </>
          ) : (
            <></>
          )}

          <Grid xs={12}>
            <hr />
            <h6>Nominee Details</h6>
          </Grid>

          {ADD_FORM.spouse_status && (
            <Grid xs={6}>
              <MemberRadioButton
                checked={NOMINEE_RELATIONSHIP_STATUS === "ADULT_2"}
                class_name="adult"
                input_name="nomineeDetail"
                input_id="adult2"
                label_name="Adult 2"
                input_type="radio"
                value={NOMINEE_RELATIONSHIP_STATUS === "ADULT_2"}
                attrName="nomineeDetail"
                value_update={() => {
                  dispatch(
                    HealthSlice.actions.CHANGE_NOMINEE_STATUS("ADULT_2")
                  );
                }}
              />
            </Grid>
          )}

          {ADD_FORM.spouse_status && (
            <Grid xs={6} sm={4}>
              <MemberRadioButton
                checked={NOMINEE_RELATIONSHIP_STATUS === "OTHER"}
                class_name="adult"
                input_name="nomineeDetail"
                input_id="others"
                label_name="Other"
                input_type="radio"
                value={NOMINEE_RELATIONSHIP_STATUS === "OTHER"}
                attrName="nomineeDetail"
                value_update={() => {
                  dispatch(HealthSlice.actions.CHANGE_NOMINEE_STATUS("OTHER"));
                }}
              />
            </Grid>
          )}
        </Grid>

        {NOMINEE_RELATIONSHIP_STATUS === "OTHER" && (
          <Grid container spacing={2}>
            <Grid xs={12}>
              <RKTextField
                validation_type="NAME"
                class_name="inputField"
                title="Full Name"
                value={nomineeDetails.full_name.value}
                attrName={["nomineeDetails", "full_name"]}
                value_update={fieldsUpdateState}
                warn_status={nomineeDetails.full_name.warning}
                error_message="Enter Nominee Full Name"
              />
            </Grid>
            <Grid xs={6}>
              <DatePicker
                class_name="inputField"
                title="DOB"
                value={nomineeDetails.dob.value}
                attrName={["nomineeDetails", "dob"]}
                date_validation_type="YEARS"
                min_date={110}
                max_date={18}
                value_update={fieldsUpdateState}
                error_message="Enter Nominee Date Of Birth"
                warn_status={nomineeDetails.dob.warning}
                default_date={subYears(new Date(), 18)}
              />
            </Grid>

            <Grid xs={6}>
              <SelectDropdown
                class_name="inputField"
                title="Relationship"
                value={nomineeDetails?.relationship?.value}
                attrName={["nomineeDetails", "relationship"]}
                value_update={fieldsUpdateState}
                // data={DROPDOWN_DATA.NOMINEE_RELATIONSHIP}
                data={nomineeRealtionshipList}
                warn_status={nomineeDetails?.relationship?.warning}
                error_message="Enter Nominee Relationship"
              />
            </Grid>
            {SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G009" ? (
              <Grid xs={12}>
                <SelectDropdown
                  class_name="inputField"
                  title="TPA"
                  value={nomineeDetails?.tpaName?.value}
                  attrName={["nomineeDetails", "tpaName"]}
                  value_update={fieldsUpdateState}
                  data={[
                    {
                      label: "Paramount Health Services (TPA) Pvt Ltd",
                      value: "Paramount Health Services (TPA) Pvt Ltd",
                    },
                    {
                      label: "Medi Assist Insurance (TPA) Pvt Ltd",
                      value: "Medi Assist Insurance (TPA) Pvt Ltd",
                    },
                  ]}
                  warn_status={nomineeDetails?.tpaName?.warning}
                  error_message="Please select TPA"
                />
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        )}

        {/* <Grid container spacing={3}>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Full Name"}
              value={adultOneDetails.name.value}
              attrName={["name", "adultOneDetails"]}
              value_update={fieldsUpdateState}
              warn_status={adultOneDetails.name.warning}
              error_message={
                adultOneDetails.name.value === "" ? "Enter Name" : ""
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
          ECompanyCode.HDFC_HEALTH ? (
            <Grid xs={3}>
              <DatePicker
                class_name="inputField"
                title={"DOB"}
                value={adultOneDetails.dob.value}
                attrName={["dob", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                error_message="Select dob"
                warn_status={adultOneDetails.dob.warning}
                date_validation_type={
                  SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                  ECompanyCode.HDFC_HEALTH
                    ? "DAYS"
                    : "YEARS"
                }
                min_date={
                  SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                  ECompanyCode.HDFC_HEALTH
                    ? 16801
                    : 100
                }
                max_date={
                  SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                  ECompanyCode.HDFC_HEALTH
                    ? 6575
                    : 18
                }
                default_date={subYears(new Date(), 18)}
              />
            </Grid>
          ) : SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.ICIC ? (
            <Grid xs={4}>
              <DatePicker
                class_name="inputField"
                title={"DOB"}
                value={adultOneDetails.dob.value}
                attrName={["dob", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                error_message="Enter Dob"
                warn_status={adultOneDetails.dob.warning}
                date_validation_type="YEARS"
                min_date={99}
                max_date={
                  SELECTED_QUOTE_DATA.productDetails.id === 443 ? 61 : 18
                }
                default_date={subYears(
                  new Date(),
                  SELECTED_QUOTE_DATA.productDetails.id === 443 ? 61 : 18
                )}
              />
            </Grid>
          ) : (
            <Grid xs={4}>
              <DatePicker
                class_name="inputField"
                title={"DOB"}
                value={adultOneDetails.dob.value}
                attrName={["dob", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                error_message="Enter Dob"
                warn_status={adultOneDetails.dob.warning}
                date_validation_type="YEARS"
                min_date={99}
                max_date={
                  SELECTED_QUOTE_DATA.productDetails.id === 443 ? 61 : 18
                }
                default_date={subYears(
                  new Date(),
                  SELECTED_QUOTE_DATA.productDetails.id === 443 ? 61 : 18
                )}
              />
            </Grid>
          )}

          <Grid xs={4} md={5} lg={4}>
            {INSURED_MEMBER_DETAILS.PROPOSER_DETAILS
              .IS_PROPOSER_INSURED ? null : (
              <SelectDropdown
                disabled={PAGE_STATUS}
                class_name="inputField"
                title="Relationship"
                value={adultOneDetails.relationship.value}
                attrName={["relationship", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                data={
                  ADD_FORM.spouse_status === false
                    ? [
                        { value: "Spouse", label: "Spouse" },
                        { value: "Father", label: "Father" },
                        { value: "Mother", label: "Mother" },
                      ]
                    : [
                        { value: "Father", label: "Father" },
                        { value: "Mother", label: "Mother" },
                      ]
                }
                warn_status={adultOneDetails.relationship.warning}
                error_message="Select Relationship"
              />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={6} md={4} lg={3} className="weightField">
            <span className="weightIcon"></span>
            <RKTextField
              validation_type="NUMBER"
              max_length={3}
              class_name="inputField"
              title={"Weight (Kg)"}
              value={adultOneDetails.weight.value}
              attrName={["weight", "adultOneDetails"]}
              value_update={fieldsUpdateState}
              warn_status={adultOneDetails.weight.warning}
              error_message="Enter Weight"
            />
          </Grid>
          <Grid xs={6} md={6} lg={5} className="heightField">
            <span className="heightIcon"></span>
            <SelectDropdown
              class_name="inputField mr-2"
              title="Feet"
              value={adultOneDetails.heightFeet.value}
              attrName={["heightFeet", "adultOneDetails"]}
              value_update={fieldsUpdateState}
              data={HEIGHT_FEET}
              warn_status={adultOneDetails.heightFeet.warning}
              error_message="Select Height Feet"
            />
            <SelectDropdown
              class_name="inputField"
              title="Inches"
              value={adultOneDetails.heightInches.value}
              attrName={["heightInches", "adultOneDetails"]}
              value_update={fieldsUpdateState}
              data={HEIGHT_INCH}
              warn_status={adultOneDetails.heightInches.warning}
              error_message="Select Height Inches"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={6} md={5} lg={4}>
            <SelectDropdown
              class_name="inputField"
              title="Marital Status"
              value={adultOneDetails.maritalStatus.value}
              attrName={["maritalStatus", "adultOneDetails"]}
              value_update={fieldsUpdateState}
              data={DROPDOWN_DATA.MARTIAL}
              warn_status={adultOneDetails.maritalStatus.warning}
              error_message="Select Marital Status"
            />
          </Grid>
          {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.HDFC_HEALTH ||
          hdfcProductIds.includes(SELECTED_QUOTE_DATA.productDetails.id) ? (
            <Grid xs={6} md={5} lg={4}>
              <SelectDropdown
                class_name="inputField"
                title="Occupation"
                value={adultOneDetails.occupation.value}
                attrName={["occupation", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.OCCUPATION}
                warn_status={adultOneDetails.occupation.warning}
                error_message="Select occupation Status"
              />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>

        <Grid container spacing={3}>
          <Grid xs={12}>
            <hr />
            <h6>Nominee Details</h6>
          </Grid>
        </Grid>
        <Grid container spacing={3} justifyContent="center">
          {ADD_FORM.spouse_status ? (
            <Grid xs={4}>
              <MemberRadioButton
                checked={NOMINEE_RELATIONSHIP_STATUS === "ADULT_2"}
                class_name="adult"
                input_name="ksdgkj"
                input_id={"adult2"}
                label_name="Adult 2"
                input_type="radio"
                value={NOMINEE_RELATIONSHIP_STATUS === "ADULT_2"}
                attrName={"nomineDetail"}
                value_update={() => {
                  dispatch(
                    HealthSlice.actions.CHANGE_NOMINEE_STATUS("ADULT_2")
                  );
                }}
              />
            </Grid>
          ) : null}
          {!ADD_FORM.spouse_status ? null : (
            <Grid xs={4}>
              <MemberRadioButton
                checked={NOMINEE_RELATIONSHIP_STATUS === "OTHER"}
                class_name="adult"
                input_name="ksdgkj"
                input_id="others"
                label_name="Other"
                input_type="radio"
                value={NOMINEE_RELATIONSHIP_STATUS === "OTHER"}
                attrName={"nomineDetail"}
                value_update={() => {
                  dispatch(HealthSlice.actions.CHANGE_NOMINEE_STATUS("OTHER"));
                }}
              />
            </Grid>
          )}
        </Grid>
        // Nominee Member Details
        {NOMINEE_RELATIONSHIP_STATUS === "OTHER" ? (
          <Box>
            <Grid container spacing={3}>
              <Grid xs={6}>
                <RKTextField
                  validation_type="NAME"
                  class_name="inputField"
                  title={"Full Name"}
                  value={nomineeDetails.full_name.value}
                  attrName={["nomineeDetails", "full_name"]}
                  value_update={fieldsUpdateState}
                  warn_status={nomineeDetails.full_name.warning}
                  error_message="Enter Nominee Full Name"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={4}>
                <DatePicker
                  class_name="inputField"
                  title={"DOB"}
                  value={nomineeDetails.dob.value}
                  attrName={["nomineeDetails", "dob"]}
                  date_validation_type="YEARS"
                  min_date={110}
                  max_date={18}
                  value_update={fieldsUpdateState}
                  error_message="Enter Nominee Date of birth"
                  warn_status={nomineeDetails.dob.warning}
                  default_date={subYears(new Date(), 18)}
                />
              </Grid>
              <Grid xs={4}>
                <SelectDropdown
                  class_name="inputField"
                  title="Relationship"
                  value={nomineeDetails?.relationship?.value}
                  attrName={["nomineeDetails", "relationship"]}
                  value_update={fieldsUpdateState}
                  data={DROPDOWN_DATA?.NOMINEE_RELATIONSHIP}
                  warn_status={nomineeDetails?.relationship?.warning}
                  error_message="Enter Nominee RelationShip"
                />
              </Grid>
            </Grid>
          </Box>
        ) : null} */}
      </>

      {/* Footer */}

      <MFooter
        attrName={"pageStatus"}
        textName={PAGE_STATUS ? "Update" : "Continue"}
        value={1}
        value_update={() => {}}
        forward={() => {
          validateForm();
        }}
      />
    </Box>
  );
}

export default InsuredMember;
