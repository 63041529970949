import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subYears } from "date-fns";
import { useAppSelector } from "../../../../../redux/hooks";
import RKTextField from "../../../../../Component/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import { TBank_Details_AdityaBirla } from "../../../../../types/Health/THealthSlice";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";
import Footer from "../../../../../Component/Footer/Footer";

const BankDetailsAdityaBirla = ({
  BankDetails,
  updateBankDetail,
  validateForm,
  disbaledButton,
}: {
  BankDetails: TBank_Details_AdityaBirla;
  updateBankDetail: Function;
  validateForm: Function;
  disbaledButton: boolean;
}) => {
  const { PAGE_STATUS } = useAppSelector((state) => state.Health);

  return (
    <>
      <Grid container spacing={3}>
        <h5 className="sectionTitle">Proposer's Bank Details</h5>
        <Grid container spacing={3}></Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Bank Account Number"}
            value={BankDetails?.Bank_Account_No?.value}
            attrName={"Bank_Account_No"}
            value_update={updateBankDetail}
            warn_status={BankDetails?.Bank_Account_No?.warning}
            error_message={"Enter Bank Account Number"}
            max_length={21}
            validation_type="NUMBER"
          />
        </Grid>
        <Grid xs={6}>
          <SelectDropdown
            class_name="inputField"
            title="Bank Account Type"
            value={BankDetails?.Bank_Account_Type?.value}
            attrName={"Bank_Account_Type"}
            value_update={updateBankDetail}
            data={[
              {
                label: "Saving",
                value: "Saving",
              },
              {
                label: "Current",
                value: "Current",
              },
            ]}
            warn_status={BankDetails?.Bank_Account_Type?.warning}
            error_message={"Enter Bank Account Type"}
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"IFSC Code"}
            value={BankDetails.IFSC_Code.value}
            attrName={"IFSC_Code"}
            value_update={updateBankDetail}
            warn_status={BankDetails.IFSC_Code.warning}
            error_message={
              isEmpty(BankDetails.IFSC_Code.value)
                ? "Enter IFSC Code"
                : "Enter Valid IFSC Code"
            }
            max_length={11}
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Bank Name"}
            value={BankDetails?.Bank_Name?.value}
            attrName={"Bank_Name"}
            value_update={updateBankDetail}
            warn_status={BankDetails?.Bank_Name?.warning}
            error_message={"Enter Bank Name"}
          />
        </Grid>

        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Branch Name"}
            value={BankDetails.Branch_Name.value}
            attrName={"Branch_Name"}
            value_update={updateBankDetail}
            warn_status={BankDetails?.Branch_Name?.warning}
            error_message={"Enter Customer Bank Branch"}
          />
        </Grid>

        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"MICR Code"}
            value={BankDetails?.MICR_Code?.value}
            attrName={"MICR_Code"}
            value_update={updateBankDetail}
            warn_status={BankDetails?.MICR_Code?.warning}
            error_message={"Enter MICR Code"}
            max_length={21}
            validation_type="NUMBER"
          />
        </Grid>

        <Footer
          attrName={"pageStatus"}
          textName={PAGE_STATUS ? "Update" : "Continue"}
          value={1}
          value_update={() => {}}
          forward={() => {
            validateForm();
          }}
          disableButton={disbaledButton}
        />
      </Grid>
    </>
  );
};

export default BankDetailsAdityaBirla;
