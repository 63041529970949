import { Route, Routes } from "react-router-dom";
import STARCKYCContainer from "../../../../../Container/CKYC/Star/STARCKYCContainer";
import STARManualKYCContainer from "../../../../../Container/CKYC/Star/STARManualKycContainer";
import MedicalDetailsContainerComp from "../../../../../Container/health-php/STAR/MedicalDetailsContainer/MedicalDetailsContainerComp";
import MedicalDetailsContainerFHO from "../../../../../Container/health-php/STAR/MedicalDetailsContainer/MedicalDetailsContainerFHO";
import { EProductId } from "../../../../../Services/Enum/EHome";
import { useAppSelector } from "../../../../../redux/hooks";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import { HEALTH_ROUTE } from "../../../../Path/HealthRoutes";
import HealthProposalContainer from "../../HealthProposalContainer";
import AddonDetailsContainerSuperStar from "../../../../../Container/health-node/STAR/addon/AddonDetailsContainerSuperStar";
import MedicalDetailsContainerRedCarpet from "../../../../../Container/health-php/STAR/MedicalDetailsContainer/MedicalDetailsContainerRedCarpet";
import STARCKYCContainerNode from "../../../../../Container/ckyc-node/Star/STARCKYCContainer";
import STARManualKYCContainerNode from "../../../../../Container/ckyc-node/Star/STARManualKycContainer";
import MedicalDetailsContainerSuperStar from "../../../../../Container/health-node/STAR/MedicalDetailsContainer/MedicalDetailsContainerSuperStar";
import SuperStarBankDetailsContainer from "../../../../../Container/BankDetails/super-star/SuperStarBankDetailsContainer";
import { CLIENTS } from "../../../../../URLCollection/Clients";

const STAR_ROUTES = () => {
  const { id } = useAppSelector(
    (state) => state.Health.SELECTED_QUOTE_DATA.productDetails
  );

  const isNode =
    (`${id}` === "2943" ||
      `${id}` === "2944" ||
      `${id}` === "2945" ||
      `${id}` === "2946" ||
      `${id}` === "2947" ||
      (`${id}` === `${EProductId.CARE_SUPREME}` &&
        window.location.hostname === CLIENTS.GROWWINSURANCE) ||
      `${id}` === `${EProductId.ICICI_ELEVATE_PLAN}` ||
      `${id}` === `${EProductId.CARE_ADVANTAGE}` ||
      `${id}` === `${EProductId.CARE_SENIOR_CITIZEN}`) &&
    process.env.REACT_APP_ENV === "DEV";

  return (
    <Routes>
      <Route
        path={HEALTH_ROUTE.PROPOSAL_PAGE}
        element={<HealthProposalContainer />}
      >
        {isNode ? (
          <>
            <Route
              path={CKYC_ROUTES.CKYC}
              element={<STARCKYCContainerNode />}
            />
            <Route
              path={CKYC_ROUTES.MANUAL_CKYC}
              element={<STARManualKYCContainerNode />}
            />
          </>
        ) : (
          <>
            <Route path={CKYC_ROUTES.CKYC} element={<STARCKYCContainer />} />
            <Route
              path={CKYC_ROUTES.MANUAL_CKYC}
              element={<STARManualKYCContainer />}
            />
          </>
        )}
        {id === EProductId.STAR_SUPER_STAR ? (
          <>
            <Route
              path={HEALTH_ROUTE.ADDON_DETAILS}
              element={<AddonDetailsContainerSuperStar />}
            />
            <Route
              path={HEALTH_ROUTE.MEDICAL_DETAILS}
              element={<MedicalDetailsContainerSuperStar />}
            />
          </>
        ) : (
          <></>
        )}

        {id === EProductId.STAR_COMP_PRODUCT_ID ? (
          <Route
            path={HEALTH_ROUTE.MEDICAL_DETAILS}
            element={<MedicalDetailsContainerComp />}
          />
        ) : (
          <></>
        )}
        {id === EProductId.STAR_FHO_PRODUCT_ID ? (
          <Route
            path={HEALTH_ROUTE.MEDICAL_DETAILS}
            element={<MedicalDetailsContainerFHO />}
          />
        ) : (
          <></>
        )}

        {id === EProductId.START_RED_CARPET ? (
          <Route
            path={HEALTH_ROUTE.MEDICAL_DETAILS}
            element={<MedicalDetailsContainerRedCarpet />}
          />
        ) : (
          <></>
        )}
        <Route
          path={HEALTH_ROUTE.BANK_DETAILS}
          element={<SuperStarBankDetailsContainer />}
        />
      </Route>
    </Routes>
  );
};

export default STAR_ROUTES;
