import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import AddonQuestion from "../../../../Component/AddonQuestion/AddonQuestion";
import Footer from "../../../../Component/Footer/Footer";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { TADDON_DETAILS_CIGNA_PRIME_ACTIVE } from "../../../../types/Health/ProposalDeatail/TCIGNAAddon/TCIGNAAddon";

function AddonDetailsPrimeActive({
  addonStatus,
  updateMasterState,
  validate,
  PAGE_STATUS,
  isLoading,
}: {
  addonStatus: TADDON_DETAILS_CIGNA_PRIME_ACTIVE;
  PAGE_STATUS: boolean;
  updateMasterState: Function;
  validate: Function;
  isLoading: any;
}) {
  const navigate = useNavigate();
  const { QUOTE_LOADER, DROPDOWN_DATA, ADD_FORM } = useAppSelector(
    (state) => state.Health
  );
  const { CKYC_DETAILS } = useAppSelector((state) => state.CKYC);

  return (
    <>
      <Box>
        <h5 className="sectionTitle">Addon Details</h5>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Prime Wellness"
            toggle_status={addonStatus.wellness}
            value_update={updateMasterState}
            attrName={"wellness"}
            desc=""
            disable={true}
          />
        </Grid>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Waiver of Diesease Specific Sub-Limit"
            toggle_status={addonStatus.waiver_diesease}
            value_update={updateMasterState}
            attrName={"waiver_diesease"}
            desc=""
          />
        </Grid>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Non-Medical Items"
            toggle_status={addonStatus.non_medical}
            value_update={updateMasterState}
            attrName={"non_medical"}
            desc="All the Non-Medical Items (as per List I of Annexure III) shall be covered upto sum insured which in normal course are not covered."
          />
        </Grid>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Health Check Up"
            toggle_status={addonStatus.health_ckeck}
            value_update={updateMasterState}
            attrName={"health_ckeck"}
            desc="For any test of choice from the first year itself. (for active only)."
          />
        </Grid>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Waiver of Mandatory Co-pay"
            toggle_status={addonStatus.waiver}
            value_update={updateMasterState}
            attrName={"waiver"}
            desc="10% of Mandatory co-payment per claim will be waived off if this coverage is opted."
          />
        </Grid>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Worldwide Accidental Emergency Hospitalization Cover"
            toggle_status={addonStatus.world_accident}
            value_update={updateMasterState}
            attrName={"world_accident"}
            desc="In addition to the Base Sum Insured, up to Sum Insured, when a person meets with an Accidental emergency, Hospitalization abroad will be covered."
          />
        </Grid>

        <Footer
          textName={PAGE_STATUS === true ? "Update" : "Continue"}
          attrName={"pageStatus"}
          forward={() => {
            validate();
          }}
          value={0}
        />
      </Box>
    </>
  );
}

export default AddonDetailsPrimeActive;
