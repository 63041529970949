import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddonDetails from "../../../Page/Desktop/health-php/CIGNA/AddonDetails";
import MAddonDetails from "../../../Page/Mobile/health-php/CIGNA/MAddonDetails";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import { HEALTH_PROPOSAL_SERVICES } from "../../../Services/health-php/HealthPropsalServices";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { HealthSlice } from "../../../redux/slice/Health/HealthSlice";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { THealthQuote } from "../../../types/Health/HealthQuotation/THealthQuote";
import { TADDON_DETAILS_CIGNA_PRIME_ADVANTAGE } from "../../../types/Health/ProposalDeatail/TCIGNAAddon/TCIGNAAddon";

export default function AddonDetailsContainerPrimeAdvantage() {
  const isMobile = useIsMobile();
  const {
    ADD_FORM,
    ADD_FORM_RESPONSE,
    SELECTED_QUOTE_DATA,
    PAGE_STATUS,
    ADDON_DETAILS_CIGNA_PRIME_ADVANTAGE,
    QUOTE_LOADER,
  } = useAppSelector((state) => state.Health);
  const [userType, setUserType] = useState<string>("");
  const [utm_medium, setUtm_medium] = useState<string>("");
  const [utm_source, setUtm_source] = useState<string>("");

  useEffect(() => {
    // Access the query parameters directly from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const userTypeFromQuery = searchParams.get("user_type");
    const utm_mediumFromQuery = searchParams.get("utm_medium");
    const utm_sourceFromQuery = searchParams.get("utm_source");
    setUtm_medium(`${utm_mediumFromQuery}`);
    setUtm_source(`${utm_sourceFromQuery}`);
    setUserType(
      atob(`${userTypeFromQuery}`) === "agent" ? btoa("agent") : btoa("user")
    );
  }, []);
  const { Health, CKYC } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [addonStatus, setAddonStatus] =
    useState<TADDON_DETAILS_CIGNA_PRIME_ADVANTAGE>(
      ADDON_DETAILS_CIGNA_PRIME_ADVANTAGE
    );
  const [loader, setLoader] = useState<boolean>(false);
  const [mandatoryAddon, setMandatoryAddon] = useState();

  useEffect(() => {
    const onSuccess = (res: any) => {
      if (!res.results.error) {
        const mand_addon_status = res?.results?.response;
        setMandatoryAddon(mand_addon_status);
        if (mand_addon_status === "ZONE1" || mand_addon_status === "ZONE2") {
          setAddonStatus((prev) => ({
            ...prev,
            room_rent: true,
            supreme_bonus: true,
          }));
        }
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log("err", err);
    };

    let param = {
      pincode: ADD_FORM.pincode.value,
    };

    setLoader(true);
    HEALTH_PROPOSAL_SERVICES.GET_MANDATORY_CIGNA(onSuccess, onError, param);
  }, []);

  useEffect(() => {
    updatePreiumApi();
    dispatch(
      HealthSlice.actions.ADDON_DETAILS_CIGNA_PRIME_ADVANTAGE(addonStatus)
    );
  }, [addonStatus]);

  const updateMasterState = (attrName: any, value: any) => {
    console.log("value", value, "attr", attrName);
    if (attrName === "prime_enhance" && !value) {
      if (addonStatus.prime_infertility) {
        setAddonStatus((prev) => ({
          ...prev,
          prime_infertility: false,
          [attrName]: value,
        }));
      }
    } else {
      setAddonStatus((prev) => ({
        ...prev,
        [attrName]: value,
      }));
    }
  };

  console.log(addonStatus, "addonStatus");

  const updatePreiumApi = () => {
    const onSuccess = (res: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      if (res.results.error === false) {
        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.results.response.premiumDetails.finalPremium,
          },
        };
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            ADDON_DETAILS_CIGNA_PRIME_ADVANTAGE: addonStatus,
            QUOTE_LOADER: false,
          })
        );
      }
    };
    const onError = (err: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      console.log("err", err);
    };

    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));

    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      gender: ADD_FORM.gender.value,
      addon: {
        critical_illness: addonStatus.critical_illness === true ? "Yes" : "No",
        outpatient: addonStatus.outpatient === true ? "Yes" : "No", //Mandatory
        cumulative: addonStatus.cumulative === true ? "Yes" : "No",
        healthAddSumInsured: addonStatus.healthAddSumInsured, // dropdwon(20000,30000,50000)
        non_medical: addonStatus.non_medical === true ? "Yes" : "No",
        personal_accident:
          addonStatus.personal_accident === true ? "Yes" : "No",
        prime_enhance: addonStatus.prime_enhance === true ? "Yes" : "No",
        prime_freedom: addonStatus.prime_freedom === true ? "Yes" : "No",
        room_rent: addonStatus.room_rent === true ? "Yes" : "No",
        supreme_bonus: addonStatus.supreme_bonus === true ? "Yes" : "No",
        surplus_benifit: addonStatus.surplus_benifit === true ? "Yes" : "No",
        premium_management:
          addonStatus.premium_management === true ? "Yes" : "No",
        prime_advantage: addonStatus.prime_advantage ? "Yes" : "No",
        prime_infertility: addonStatus.prime_enhance
          ? addonStatus.prime_infertility
            ? "Yes"
            : "No"
          : "No",
        room_rent_option: addonStatus.room_rent
          ? addonStatus.room_rent_option
          : "",
      },
    };
    HEALTH_PROPOSAL_SERVICES.getPeriumByAddonCignaPrimeAdvantage(
      onSuccess,
      onError,
      param
    );
  };

  const validate = () => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const cus_type = params.get("cus_type");

    const onSuccess = (res: any) => {
      if (!res.results.error) {
        setLoader(false);
        if (PAGE_STATUS) {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&utm_source=${utm_source}&utm_medium=${utm_medium}&user_type=${userType}`
          );
        } else {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&utm_source=${utm_source}&utm_medium=${utm_medium}&user_type=${userType}`
          );
        }
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    setLoader(true);
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      section: "add_ons_details",
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
      critical_illness: addonStatus.critical_illness === true ? "Yes" : "No",
      outpatient: "Yes", //Mandatory
      cumulative: addonStatus.cumulative === true ? "Yes" : "No",
      healthAddSumInsured: addonStatus.healthAddSumInsured, // dropdwon(20000,30000,50000)
      non_medical: addonStatus.non_medical === true ? "Yes" : "No",
      personal_accident: addonStatus.personal_accident === true ? "Yes" : "No",
      prime_enhance: addonStatus.prime_enhance === true ? "Yes" : "No",
      prime_freedom: addonStatus.prime_freedom === true ? "Yes" : "No",
      room_rent: addonStatus.room_rent === true ? "Yes" : "No",
      supreme_bonus: addonStatus.supreme_bonus === true ? "Yes" : "No",
      surplus_benifit: addonStatus.surplus_benifit === true ? "Yes" : "No",
      premium_management:
        addonStatus.premium_management === true ? "Yes" : "No",
      prime_advantage: addonStatus.prime_advantage ? "Yes" : "No",
      prime_infertility: addonStatus.prime_enhance
        ? addonStatus.prime_infertility
          ? "Yes"
          : "No"
        : "No",
      room_rent_option: addonStatus.room_rent
        ? addonStatus.room_rent_option
        : "",
    };

    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  const FinalPremium = () => {
    const onSuccess = (res: any) => {
      console.log("res", res);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      final_premium: SELECTED_QUOTE_DATA?.premiumDetails?.finalPremium,
    };
    HEALTH_PROPOSAL_SERVICES.GET_FINAL_PREMIUM(onSuccess, onError, param);
  };

  useEffect(() => {
    FinalPremium();
  }, [SELECTED_QUOTE_DATA]);

  useEffect(() => {
    RECORD_CKYC_RESPONSE();
  }, [CKYC.CKYC_DETAILS]);

  const RECORD_CKYC_RESPONSE = () => {
    const onSuccess = (res: any) => {
      console.log("res...", res?.results);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      details: CKYC.CKYC_DETAILS,
      section: "kyc_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
    };
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };
  return (
    <>
      {" "}
      {QUOTE_LOADER ? (
        <Box
          position="fixed"
          height="100%"
          width="100%"
          zIndex="991"
          top="0px"
          left="0px"
        />
      ) : null}{" "}
      {isMobile ? (
        <MAddonDetails
          isLoading={loader}
          addonStatus={addonStatus}
          updateMasterState={updateMasterState}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
        />
      ) : (
        <AddonDetails
          isLoading={loader}
          addonStatus={addonStatus}
          updateMasterState={updateMasterState}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
          mandatoryAddon={mandatoryAddon}
        />
      )}
    </>
  );
}
