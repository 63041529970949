import { Route, Routes } from "react-router-dom";
import AddressDetailsContainer from "../../../../../Container/health-node/common/AddressDetailsContainer/AddressDetailsContainer";
import AdultOneDetailsContainer from "../../../../../Container/health-node/common/AdultOneDetailsContainer/AdultOneDetailsContainer";
import MemberDetailContainer from "../../../../../Container/health-node/common/AdultTwoDetailsContainer/AdultTwoDetailsContainer";
import ChildFourDetailsContainer from "../../../../../Container/health-node/common/ChildFourDetailsContainer/ChildFourDetailsContainer";
import ChildOneDetailsContainer from "../../../../../Container/health-node/common/ChildOneDetailsContainer/ChildOneDetailsContainer";
import ChildThreeDetailsContainer from "../../../../../Container/health-node/common/ChildThreeDetailsContainer/ChildThreeDetailsContainer";
import ChildTwoDetailsContainer from "../../../../../Container/health-node/common/ChildTwoDetailsContainer/ChildTwoDetailsContainer";
import InsuredMemberContainer from "../../../../../Container/health-node/common/InsuredMemberContainer/InsuredMemberContainer";
import PreviewDetailsContainer from "../../../../../Container/health-node/common/PreviewDetailsContainer/PreviewDetailsContainer";
import { HEALTH_ROUTE } from "../../../../Path/HealthRoutes";
import HealthProposalContainer from "../../HealthProposalContainer";
import { COMMON_ROUTES } from "../../../../Path/CommonRoutes";
import PaymentConfContainerNode from "../../../../../Container/Common/PaymentConfContainerNode";

export const COMMON_ROUTES_NODE = () => {
  return (
    <Routes>
      <Route
        path={HEALTH_ROUTE.PROPOSAL_PAGE}
        element={<HealthProposalContainer />}
      >
        <Route
          path={HEALTH_ROUTE.ADULT_ONE_DETAILS}
          element={<AdultOneDetailsContainer />}
        />
        <Route
          path={HEALTH_ROUTE.INSURED_MEMBER}
          element={<InsuredMemberContainer />}
        />
        <Route
          path={HEALTH_ROUTE.ADULT_TWO_DETAILS}
          element={<MemberDetailContainer />}
        />
        <Route
          path={HEALTH_ROUTE.CHILD_ONE_DETAILS}
          element={<ChildOneDetailsContainer />}
        />
        <Route
          path={HEALTH_ROUTE.CHILD_TWO_DETAILS}
          element={<ChildTwoDetailsContainer />}
        />
        <Route
          path={HEALTH_ROUTE.CHILD_THREE_DETAILS}
          element={<ChildThreeDetailsContainer />}
        />
        <Route
          path={HEALTH_ROUTE.CHILD_FOUR_DETAILS}
          element={<ChildFourDetailsContainer />}
        />
        <Route
          path={HEALTH_ROUTE.ADDRESS_DETAILS}
          element={<AddressDetailsContainer />}
        />
        <Route
          path={HEALTH_ROUTE.PREVIEW_DETAILS}
          element={<PreviewDetailsContainer />}
        />
      </Route>
      <Route
        path={COMMON_ROUTES.PAYMENT_CONFIRMATION}
        element={<PaymentConfContainerNode />}
      />
    </Routes>
  );
};
