import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import NomineeDetails from "../../../Page/Desktop/TW/Common/NomineeDetails";
import MNomineeDetails from "../../../Page/Mobile/TW/Common/MNomineeDetails";
import { TW_ROUTES } from "../../../Router/Path/TWRoutes";
import { TW_SERVICES } from "../../../Services/TW/TWServices";
import { useAppSelector } from "../../../redux/hooks";
import { TWSlice } from "../../../redux/slice/TwoWheeler/TWSlice";
import { validateFullName } from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../types/Common/TDropdown";
import { TNomineeDetails } from "../../../types/TTWSlice";
import { CLIENTS } from "../../../URLCollection/Clients";
import {
  pushMotorDataLayer,
  sendEventToNativeApp,
} from "../../../utils/analytics";

const NomineeDetailsContainer = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userType, setUserType] = useState<string>("");
  const [utm_medium, setUtm_medium] = useState<string>("");
  const [utm_source, setUtm_source] = useState<string>("");

  useEffect(() => {
    // Access the query parameters directly from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const userTypeFromQuery = searchParams.get("user_type");
    const utm_mediumFromQuery = searchParams.get("utm_medium");
    const utm_sourceFromQuery = searchParams.get("utm_source");
    setUtm_medium(`${utm_mediumFromQuery}`);
    setUtm_source(`${utm_sourceFromQuery}`);
    setUserType(
      atob(`${userTypeFromQuery}`) === "agent" ? btoa("agent") : btoa("user")
    );
  }, []);
  const {
    NOMINEE_DETAILS,
    SELECTED_QUOTE_DATA,
    PAGE_STATUS,
    ADD_FORM_RESPONSE,
    REDIRECTED,
  } = useAppSelector((state) => state.TW);
  const [pageStatus, setPageStatus] = useState<number>(1);
  const [nomineeDetails, setNomineeDetails] =
    useState<TNomineeDetails>(NOMINEE_DETAILS);

  useEffect(() => {
    GET_NOMINEE_RELATIONSHIP_LIST();
  }, []);

  useEffect(() => {
    GET_NOMINEE_RELATIONSHIP_LIST();
  }, []);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "pageStatus") {
      validateForm();
      setPageStatus(value);
    } else if (attrName === "backbutton") {
      navigate(-1);
    } else {
      attrName[3]((prevDetails: any) => {
        return {
          ...prevDetails,
          [attrName[0]]: {
            ...prevDetails[attrName[0]],
            [attrName[1]]: value,
            warning:
              attrName[0] === "fullName" ? !validateFullName(value) : false,
          },
        };
      });
    }
  };

  const validateForm = () => {
    let data: any = { ...nomineeDetails };

    const fieldValidations = [
      {
        field: "fullName",
        condition: !validateFullName(data.fullName.value),
      },
      {
        field: "dob",
        condition:
          !data.dob.value || data.dob.value === "Invalid date" ? true : false,
      },
      // {
      //   field: "gender",
      //   condition: !data.gender.value,
      // },
      {
        field: "relationship",
        condition: !data.relationship.value,
      },
    ];

    fieldValidations.forEach((validation) => {
      data = {
        ...data,
        [validation.field]: {
          ...data[validation.field],
          warning: validation.condition,
        },
      };
    });

    setNomineeDetails(data);
    let hasError =
      data.fullName.warning || data.dob.warning || data.relationship.warning;

    if (!hasError) {
      if (window.location.hostname === CLIENTS.JIO) {
        pushMotorDataLayer(
          "jioinsure_flow_details",
          "nominee",
          "proceed",
          "two wheeler",
          REDIRECTED ? "JFS app" : "Insure website"
        );
        const eventProps = {
          action: "nominee",
          click: "continue",
          type: "two wheeler",
          EntrySource: REDIRECTED ? "JFS app" : "Insure website",
        };
        sendEventToNativeApp("jioinsure_flow_details", eventProps);
      }
      if (PAGE_STATUS) {
        navigate(
          `${TW_ROUTES.PROPOSAL_PAGE}/${TW_ROUTES.PREVIEW_DEATILS}?type=TW&companyCode=${SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id}&user_type=${userType}&utm_medium=${utm_medium}&utm_source=${utm_source}`
        );
      } else {
        navigate(
          `${TW_ROUTES.PROPOSAL_PAGE}/${TW_ROUTES.ADDRESS_DETAILS}?type=TW&companyCode=${SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id}&user_type=${userType}&utm_medium=${utm_medium}&utm_source=${utm_source}`
        );
      }

      updateRecords();
      dispatch(TWSlice.actions.NOMINEE_DETAILS(data));
    }
  };

  const GET_NOMINEE_RELATIONSHIP_LIST = () => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
      if (error === false) {
        const response = results.response;
        let data: TDropdown[] = [];
        for (let i = 0; i < response.length; i++) {
          data.push({ label: response[i].title, value: response[i].code });
        }

        dispatch(
          TWSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "NOMINEE_RELATIONSHIP",
            value: data,
          })
        );
      }
    };

    const onError = (err: any) => {
      console.log("error", err);
    };
    let param = {
      product_type_code: "TW",
      product_code: `${SELECTED_QUOTE_DATA.quotationDetail?.productDetails.product_code}`,
    };

    TW_SERVICES.GET_NOMINEE_RELATIONSHIP_LIST(onSuccess, onError, param);
  };

  const updateRecords = () => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
      if (error === false) {
        const response = results.response;
      }
    };

    const onError = (err: any) => {
      console.log("error", err);
    };
    let param = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      nomineeName: nomineeDetails.fullName.value,
      nomineeRelationShip: nomineeDetails.relationship.value,
      nomineeDOB: nomineeDetails.dob.value,
      section: "nominee_details",
      insurance_type: "TW",
    };

    TW_SERVICES.CAPTURE_RECORD_ON_EVERY_STEP(onSuccess, onError, param);
  };

  return (
    <>
      {isMobile ? (
        <MNomineeDetails
          nomineeDetails={nomineeDetails}
          gender_data={[]}
          relationship_data={[]}
          updateMasterState={updateMasterState}
          setNomineeDetails={setNomineeDetails}
          pageStatus={pageStatus}
          validateForm={validateForm}
        />
      ) : (
        <NomineeDetails
          nomineeDetails={nomineeDetails}
          updateMasterState={updateMasterState}
          setNomineeDetails={setNomineeDetails}
          pageStatus={pageStatus}
        />
      )}
    </>
  );
};

export default NomineeDetailsContainer;
