import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ChildThreeDetails from "../../../../Page/Desktop/health-php/CARE/ChildThreeDetails/ChildThreeDetails";
import MChildThreeDetails from "../../../../Page/Mobile/health-php/CARE/MChildThreeDetails/MChildThreeDetails";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { ECompanyCode } from "../../../../Services/Enum/EHome";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/health-php/HealthPropsalServices";
import { HealthSlice } from "../../../../redux/slice/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  isEmpty,
  validateFullName,
} from "../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../../types/Common/TDropdown";
import { TOtherMemberDetails } from "../../../../types/Health/ProposalDeatail/TProposalDetail";

export default function ChildFourDetailsContainer() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [userType, setUserType] = useState<string>("");
  const [utm_medium, setUtm_medium] = useState<string>("");
  const [utm_source, setUtm_source] = useState<string>("");

  useEffect(() => {
    // Access the query parameters directly from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const userTypeFromQuery = searchParams.get("user_type");
    const utm_mediumFromQuery = searchParams.get("utm_medium");
    const utm_sourceFromQuery = searchParams.get("utm_source");
    setUtm_medium(`${utm_mediumFromQuery}`);
    setUtm_source(`${utm_sourceFromQuery}`);
    setUserType(
      atob(`${userTypeFromQuery}`) === "agent" ? btoa("agent") : btoa("user")
    );
  }, []);
  const dispatch = useAppDispatch();
  const {
    ADD_FORM,
    INSURED_MEMBER_DETAILS,
    SELECTED_QUOTE_DATA,
    PAGE_STATUS,
    ADD_FORM_RESPONSE,
  } = useAppSelector((state) => state.Health);
  const [memberFields, setMemberFields] = useState<TOtherMemberDetails>(
    INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS
  );
  const { Health } = useAppSelector((state) => state);

  useEffect(() => {
    getRealtionData();
  }, []);

  useEffect(() => {
    setMemberFields({ ...INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS });
  }, [INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value]);

  const getRealtionData = () => {
    const onSuccess = (res: any) => {
      console.log("res..", res);
      let data: TDropdown[] = res?.response?.map((maritalData: any) => ({
        label: maritalData.title,
        value: maritalData.code,
      }));
      dispatch(
        HealthSlice.actions.HEALTH_DROPDOWNDATA({
          key: "CHILD_RELATIONSHIP",
          value: data,
        })
      );
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      product_type_code: SELECTED_QUOTE_DATA.productDetails.product_type_code,
    };
    HEALTH_PROPOSAL_SERVICES.getRelationData(onSuccess, onError, param);
  };

  const fieldsUpdateState = (attrName: any, value: any) => {
    setMemberFields((prev) => ({
      ...prev,
      [attrName]: {
        value: value,
        warning:
          attrName === "name" ? !validateFullName(value) : isEmpty(value),
      },
    }));
  };

  const SAVE_CHILD_DATA = (data: TOtherMemberDetails) => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
    };
    const onError = (err: any) => {
      console.log("errr", err);
    };
    let param = {
      child3Gender: data.relationship.value === "daughter" ? "F" : "M",
      child3Name: data.name.value,
      child3DOB: data.dob.value,
      child3Weight: data.weight.value,
      child3HeightFeet: data.heightFeet.value,
      child3HeightInches: data.heightInches.value,
      section: "child3_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
    };
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  const validateForm = () => {
    let formValue = { ...memberFields };
    formValue = {
      ...formValue,
      dob: { ...formValue.dob, warning: isEmpty(formValue.dob.value) },
      heightFeet: {
        ...formValue.heightFeet,
        warning: isEmpty(formValue.heightFeet.value),
      },
      heightInches: {
        ...formValue.heightInches,
        warning: isEmpty(formValue.heightInches.value),
      },
      name: {
        ...formValue.name,
        warning: !validateFullName(formValue.name.value),
      },
      relationship: {
        ...formValue.relationship,
        warning: isEmpty(formValue.relationship.value),
      },
      weight: { ...formValue.weight, warning: isEmpty(formValue.weight.value) },
    };
    setMemberFields({ ...formValue });

    if (
      !formValue.dob.warning &&
      !formValue.heightFeet.warning &&
      !formValue.heightInches.warning &&
      !formValue.name.warning &&
      !formValue.relationship.warning &&
      !formValue.weight.warning
    ) {
      const total_child = ADD_FORM.children;
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      const cus_type = params.get("cus_type");

      SAVE_CHILD_DATA(formValue);
      if (PAGE_STATUS) {
        navigate(
          `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
        );
      } else {
        if (total_child > 3) {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.CHILD_FOUR_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
          );
        } else {
          if (
            SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.HDFC_HEALTH ||
            SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.NIVA_BUPA ||
            SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.ADITY_BIRLA ||
            SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.CIGNA
          ) {
            navigate(
              `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.BANK_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
            );
          } else {
            navigate(
              `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADDRESS_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
            );
          }
        }
      }
      dispatch(
        HealthSlice.actions.BULK_UPLOAD({
          ...Health,
          INSURED_MEMBER_DETAILS: {
            ...Health.INSURED_MEMBER_DETAILS,
            CHILD_THREE_DETAILS: { ...formValue },
          },
          PAGE_STATUS: false,
        })
      );
    }
  };
  return isMobile ? (
    <MChildThreeDetails
      fieldsUpdateState={fieldsUpdateState}
      memberFields={memberFields}
      validateForm={validateForm}
    />
  ) : (
    <ChildThreeDetails
      fieldsUpdateState={fieldsUpdateState}
      memberFields={memberFields}
      validateForm={validateForm}
    />
  );
}
