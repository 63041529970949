import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MedicalPage from "../../../../../Page/Desktop/TermFullJourney/HDFC/Proposal/MedicalDetails/MedicalPage";
import MMedicalPage from "../../../../../Page/Mobile/TermFullJourney/HDFC/Proposal/MedicalDetails/MMedicalPage";
import { TERM_FULL_JOURNEY_ROUTES } from "../../../../../Router/Path/TermFullJourneyRoutes";
import { HDFCMedicalDetailsDTO } from "../../../../../Services/DTO/TermFullJourney/HDFCMedicalDetailsDTO";
import { HDFC_PROPOSAL_PAGE_SERVICES } from "../../../../../Services/TermFullJourneyServices/HDFCProposalPageSubmissionService";
import { PROPOSAL_PAGE_SERVICES } from "../../../../../Services/TermFullJourneyServices/ProposalPageServices";
import { TermFullJourneySlice } from "../../../../../redux/slice/TermFullJourney/TermFullJourneySlice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../../../types/TermFullJourney/TDropdown";
import {
  TFormdataTermMedicalQuestionDetails,
  TQuestion,
  TTermHDFCMedicalQuestionDetails,
  main_question,
  medical_question_field_data,
} from "../../../../../types/TermFullJourney/TTermMedicalQuestionDetails";

function MedicalPageContainer() {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { SELECTED_QUOTE_DATA, ADD_FORM_RESPONSE } = useAppSelector(
    (state) => state.Term
  );
  const proposerPage = useAppSelector(
    (state) => state.TermFullJourney.proposerPage
  );
  const proposerDetails = useAppSelector(
    (state) => state.TermFullJourney.proposerDetails
  );
  const [loader, setLoader] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const TermHDFCMedicalQuestion = useAppSelector(
    (state) => state.TermFullJourney.TermHDFCMedicalQuestion
  );
  const [medicalQuestion, setMedicalQuestion] =
    useState<TTermHDFCMedicalQuestionDetails>(TermHDFCMedicalQuestion);
  const [formData, setFormData] = useState<TFormdataTermMedicalQuestionDetails>(
    TermHDFCMedicalQuestion.FormdataTermMedicalQuestionDetails
  );

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName[0] === "main_question") {
      setMedicalQuestion((prevState) => {
        const questionIndex = attrName[1];
        const updatedQuestion = [...prevState.question];
        if (attrName[2] === "toggle_status") {
          // check if the third element of attrName is "toggle_status"
          if (updatedQuestion[questionIndex].main_question.code !== "PQ17") {
            updatedQuestion[questionIndex] = {
              ...updatedQuestion[questionIndex],
              main_question: {
                ...updatedQuestion[questionIndex].main_question,
                status: value,
              },
            };
          }
        } else {
          const fieldIndex = attrName[2];
          const updatedFieldData = [
            ...(prevState.question[questionIndex]?.main_question?.field_data ??
              []),
          ];
          updatedFieldData[fieldIndex] = {
            ...updatedFieldData[fieldIndex],
            value: value,
            warning: value.toString().trim().length > 0 ? false : true,
          };
          updatedQuestion[questionIndex] = {
            ...updatedQuestion[questionIndex],
            main_question: {
              ...updatedQuestion[questionIndex].main_question,
              field_data: updatedFieldData,
            },
          };
        }
        return {
          ...prevState,
          question: updatedQuestion,
        };
      });
    } else if (attrName[0] === "sub_question") {
      setMedicalQuestion((prevState) => {
        const questionIndex = attrName[1];
        const subQuestionIndex = attrName[2];
        const updatedQuestion = [...prevState.question];
        const updatedSubQuestion = [
          ...(updatedQuestion[questionIndex]?.sub_question ?? []),
        ];
        if (attrName[3] === "toggle_status") {
          updatedSubQuestion[subQuestionIndex] = {
            ...updatedSubQuestion[subQuestionIndex],
            status: value,
          };
        } else {
          const fieldIndex = attrName[3];
          const updatedFieldData = [
            ...(updatedSubQuestion[subQuestionIndex]?.field_data ?? []),
          ];
          updatedFieldData[fieldIndex] = {
            ...updatedFieldData[fieldIndex],
            value: value,
            warning: value.toString().trim().length > 0 ? false : true,
          };
          updatedSubQuestion[subQuestionIndex] = {
            ...updatedSubQuestion[subQuestionIndex],
            field_data: updatedFieldData,
          };
        }
        updatedQuestion[questionIndex] = {
          ...updatedQuestion[questionIndex],
          sub_question: updatedSubQuestion,
        };
        return {
          ...prevState,
          question: updatedQuestion,
        };
      });
    } else if (attrName[0] === "formData") {
      let data = {
        ...formData,
      };
      if (attrName[1] === "occupation_involve_status") {
        data = {
          ...formData,
          [attrName[1]]: value,
        };
      } else {
        data = {
          ...formData,
          [attrName[1]]: {
            value: value,
            warning: value.toString().length > 0 ? false : true,
          },
        };
      }
      setFormData(data);
    } else {
      setMedicalQuestion((prev) => ({
        ...prev,
        [attrName]: {
          value: value,
          warning: value.toString().length > 0 ? false : true,
        },
      }));
    }
  };

  function validateFields(): boolean {
    let valid = true;
    let validatedMedicalQuestion = medicalQuestion;

    validatedMedicalQuestion.question.forEach((data, index) => {
      try {
        if (
          data.main_question &&
          data.main_question.status &&
          data.main_question.field_data
        ) {
          const fieldData = data.main_question.field_data.map(
            (field, index_field) => {
              let updatedField = { ...field };
              if (
                field.value === undefined ||
                field.value.toString().trim().length === 0
              ) {
                updatedField = {
                  ...updatedField,
                  warning: true,
                };
                valid = false;
              } else {
                updatedField = {
                  ...updatedField,
                  warning: false,
                };
              }
              return updatedField;
            }
          );

          validatedMedicalQuestion = {
            ...validatedMedicalQuestion,
            question: validatedMedicalQuestion.question.map((q, i) => {
              if (i === index && q.main_question) {
                return {
                  ...q,
                  main_question: {
                    ...q.main_question,
                    field_data: fieldData,
                  },
                };
              }
              return q;
            }),
          };
        }

        if (data.sub_question) {
          data.sub_question.forEach((subQuestion, subIndex) => {
            if (subQuestion && subQuestion.status && subQuestion.field_data) {
              const fieldData = subQuestion.field_data.map(
                (field, subIndex_field) => {
                  let updatedField = { ...field };
                  if (
                    field.value !== undefined &&
                    field.value.toString().trim().length === 0
                  ) {
                    updatedField = {
                      ...updatedField,
                      warning: true,
                    };
                    valid = false;
                  } else {
                    updatedField = {
                      ...updatedField,
                      warning: false,
                    };
                  }
                  return updatedField;
                }
              );

              validatedMedicalQuestion = {
                ...validatedMedicalQuestion,
                question: validatedMedicalQuestion.question.map((q, i) => {
                  if (i === index && q.sub_question) {
                    const updatedSubQuestion = q.sub_question.map(
                      (subQ, subI) => {
                        if (subI === subIndex) {
                          return {
                            ...subQ,
                            field_data: fieldData,
                          };
                        }
                        return subQ;
                      }
                    );
                    return {
                      ...q,
                      sub_question: updatedSubQuestion,
                    };
                  }
                  return q;
                }),
              };
            }
          });
        }
      } catch (error) {
        console.log("An error occurred while validating fields: ", error);
      }
    });

    validatedMedicalQuestion = {
      question: [...validatedMedicalQuestion.question],
      weight_kg: {
        ...validatedMedicalQuestion.weight_kg,
        warning:
          validatedMedicalQuestion.weight_kg.value === null
            ? true
            : validatedMedicalQuestion.weight_kg.value.toString().trim()
                .length > 0
            ? false
            : true,
      },
      height_ft: {
        ...validatedMedicalQuestion.height_ft,
        warning:
          validatedMedicalQuestion.height_ft.value === null
            ? true
            : validatedMedicalQuestion.height_ft.value.toString().trim()
                .length > 0
            ? false
            : true,
      },
      height_inches: {
        ...validatedMedicalQuestion.height_inches,
        warning:
          validatedMedicalQuestion.height_inches.value === null
            ? true
            : validatedMedicalQuestion.height_inches.value.toString().trim()
                .length > 0
            ? false
            : false,
      },
      height_cm: { ...validatedMedicalQuestion.height_cm },
      FormdataTermMedicalQuestionDetails: formData,
    };

    if (
      validatedMedicalQuestion.weight_kg.warning ||
      validatedMedicalQuestion.height_ft.warning ||
      validatedMedicalQuestion.height_inches.warning
    ) {
      valid = false;
    }

    let form_data: TFormdataTermMedicalQuestionDetails = { ...formData };

    form_data = {
      ...form_data,
      identification_mark: {
        ...form_data.identification_mark,
        warning:
          form_data.identification_mark.value.toString().length > 0
            ? false
            : true,
      },
    };

    form_data = {
      ...form_data,
      designation: {
        ...form_data.designation,
        warning:
          form_data.designation.value.toString().length > 0 ? false : true,
      },
    };

    form_data = {
      ...form_data,
      business_name: {
        ...form_data.business_name,
        warning:
          form_data.business_name.value.toString().length > 0 ? false : true,
      },
    };

    form_data = {
      ...form_data,
      gross_annual_income: {
        ...form_data.gross_annual_income,
        warning:
          form_data.gross_annual_income.value.toString().length > 0
            ? false
            : true,
      },
    };

    form_data = {
      ...form_data,
      nature_of_work: {
        ...form_data.nature_of_work,
        warning:
          form_data.nature_of_work.value.toString().length > 0 ? false : true,
      },
    };

    form_data = {
      ...form_data,
      industry_type: {
        ...form_data.industry_type,
        warning:
          form_data.industry_type.value.toString().length > 0 ? false : true,
      },
    };

    form_data = {
      ...form_data,
      workplace_city: {
        ...form_data.workplace_city,
        warning:
          form_data.workplace_city.value.toString().length > 0 ? false : true,
      },
    };

    form_data = {
      ...form_data,
      workplace_address: {
        ...form_data.workplace_address,
        warning:
          form_data.workplace_address.value.toString().length > 0
            ? false
            : true,
      },
    };

    if (
      form_data.identification_mark.warning ||
      form_data.designation.warning ||
      form_data.business_name.warning ||
      form_data.gross_annual_income.warning ||
      form_data.nature_of_work.warning ||
      form_data.industry_type.warning ||
      form_data.workplace_city.warning ||
      form_data.workplace_address.warning
    ) {
      valid = false;
    }

    setFormData(form_data);
    setMedicalQuestion(validatedMedicalQuestion);
    return valid;
  }

  const continue_function = () => {
    const hasError = !validateFields();

    if (!hasError) {
      dispatch(
        TermFullJourneySlice.actions.SET_HDFC_MEDICAL_QUESTION(medicalQuestion)
      );
      dispatch(
        TermFullJourneySlice.actions.SET_HDFC_FORM_DATA_MEDICAL(formData)
      );
      updateMedicalDetailsData();
    }
  };

  type ValueTypes = keyof TQuestion;
  function getQuestionDataByCodeAndType(
    code: string,
    type: "main_question" | "field_data" | "sub_question",
    data_needed: ValueTypes
  ): any {
    const question = medicalQuestion.question
      .flatMap((mainQuestion: main_question) =>
        mainQuestion.sub_question
          ? [mainQuestion.main_question, ...mainQuestion.sub_question]
          : mainQuestion.main_question
      )
      .find((question: TQuestion) => question.code === code);
    if (question) {
      switch (type) {
        case "main_question":
          if (data_needed === "status") {
            if (question[data_needed] === true) {
              return "Y";
            } else {
              return "N";
            }
          }
          return question[data_needed];

        case "field_data":
          return question.field_data;
        case "sub_question":
          if (data_needed === "status") {
            if (question[data_needed] === true) {
              return "Y";
            } else {
              return "N";
            }
          }
          return question[data_needed];
      }
    }
    return undefined;
  }

  type ValueTypesFields = keyof medical_question_field_data;
  function findFieldDataByCode(code: string, field: ValueTypesFields): any {
    const questions = medicalQuestion.question.flatMap(
      (mainQuestion: main_question) =>
        mainQuestion.sub_question
          ? [mainQuestion.main_question, ...mainQuestion.sub_question]
          : mainQuestion.main_question
    );

    for (let question of questions) {
      const fieldData = question.field_data?.find((data) => data.code === code);
      if (fieldData) {
        return fieldData[field];
      }
    }

    return undefined;
  }

  const updateMedicalDetailsData = () => {
    const onSuccess = (data: any) => {
      setLoader(false);
      const error = data.error;
      const message = data.message;

      if (error === false) {
        if (proposerPage.update_status) {
          navigate(-1);
          dispatch(TermFullJourneySlice.actions.updateUpdateStatus(false));
        } else {
          navigate(TERM_FULL_JOURNEY_ROUTES.PREVIEW_PAGE);
        }
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    const data: HDFCMedicalDetailsDTO = {
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
      section: "underwriting_details",
      details: {
        uwName: formData.identification_mark.value,
        uwOccupation: `${proposerDetails.occupation.value}`,
        uwDesignation: formData.designation.value,
        uwBuisness: formData.business_name.value,
        anyOccupation: formData.occupation_involve_status ? "Y" : "N",
        uwGrossIncome: formData.gross_annual_income.value,
        uwNatureWork: formData.nature_of_work.value,
        uwIndustry: formData.industry_type.value,
        uwCountry: "",
        uwCity: formData.workplace_city.value,
        uwAddress: formData.workplace_address.value,
        coverPremium: getQuestionDataByCodeAndType(
          "PQ01",
          "main_question",
          "status"
        ),
        uwDeathSumAssured: findFieldDataByCode("PQ01-1", "value"),
        uwAccidentalSumAssured: findFieldDataByCode("PQ01-2", "value"),
        uwBenifit: findFieldDataByCode("PQ01-3", "value"),
        uwCoverMonths: findFieldDataByCode("PQ01-4", "value"),
        uwCoverYears: findFieldDataByCode("PQ01-5", "value"),
        simultaneous: getQuestionDataByCodeAndType(
          "PQ02",
          "main_question",
          "status"
        ),
        uwSimultaneous: findFieldDataByCode("PQ02-1", "value"),
        uwSimaltaneousSumAssured: findFieldDataByCode("PQ02-2", "value"),
        uwCompany: findFieldDataByCode("PQ03-1", "value"),
        uwProductType: findFieldDataByCode("PQ04-1", "value"),
        uwCoverPurpose: findFieldDataByCode("PQ05-1", "value"),
        lifePostponed: getQuestionDataByCodeAndType(
          "PQ03",
          "main_question",
          "status"
        ),
        uwPostponedPolicyNumber: findFieldDataByCode("PQ03-1", "value"),
        uwPostponedCompany: findFieldDataByCode("PQ03-2", "value"),
        uwPostponedReason: findFieldDataByCode("PQ03-3", "value"),
        lifeExtraPremium: getQuestionDataByCodeAndType(
          "PQ04",
          "main_question",
          "status"
        ),
        uwPremiumPolicyNumber: findFieldDataByCode("PQ04-1", "value"),
        uwPremiumCompany: findFieldDataByCode("PQ04-2", "value"),
        uwPremiumReason: findFieldDataByCode("PQ04-3", "value"),
        lifeSpecialTermPremium: getQuestionDataByCodeAndType(
          "PQ05",
          "main_question",
          "status"
        ),
        uwSpecialTermPolicyNumber: findFieldDataByCode("PQ05-1", "value"),
        uwSpecialTermCompany: findFieldDataByCode("PQ05-2", "value"),
        uwSpecialTermReason: findFieldDataByCode("PQ05-3", "value"),
        lifeDeclined: getQuestionDataByCodeAndType(
          "PQ06",
          "main_question",
          "status"
        ),
        uwDeclinedPolicyNumber: findFieldDataByCode("PQ06-1", "value"),
        uwDeclinedCompany: findFieldDataByCode("PQ06-2", "value"),
        uwDeclinedReason: findFieldDataByCode("PQ06-3", "value"),
        lifeWithdrawn: getQuestionDataByCodeAndType(
          "PQ07",
          "main_question",
          "status"
        ),
        uwWithdrawnPolicyNumber: findFieldDataByCode("PQ07-1", "value"),
        uwWithdrawnCompany: findFieldDataByCode("PQ07-2", "value"),
        uwWithdrawnReason: findFieldDataByCode("PQ07-3", "value"),
        lifeROverseas: getQuestionDataByCodeAndType(
          "PQ08-SQ01",
          "sub_question",
          "status"
        ),
        uwPastTravel: findFieldDataByCode("PQ08-SQ01-1", "value"),
        uwStay: findFieldDataByCode("PQ08-SQ01-2", "value"),
        uwNextSixMonth: getQuestionDataByCodeAndType(
          "PQ08-SQ01",
          "sub_question",
          "status"
        ),
        uwFutureTravel: findFieldDataByCode("PQ08-SQ02-1", "value"),
        uwFutureStay: findFieldDataByCode("PQ08-SQ02-2", "value"),
        avocation: getQuestionDataByCodeAndType(
          "PQ09",
          "main_question",
          "status"
        ),
        uwAvocation: findFieldDataByCode("PQ09-1", "value"),
        impairment1: getQuestionDataByCodeAndType(
          "PQ20-SQ01",
          "sub_question",
          "status"
        ),
        uwimpairment1: findFieldDataByCode("PQ20-SQ01-1", "value"),
        impairment2: getQuestionDataByCodeAndType(
          "PQ20-SQ02",
          "sub_question",
          "status"
        ),
        uwimpairment2: findFieldDataByCode("PQ20-SQ02-1", "value"),
        impairment3: getQuestionDataByCodeAndType(
          "PQ20-SQ03",
          "sub_question",
          "status"
        ),
        uwimpairment3: findFieldDataByCode("PQ20-SQ03-1", "value"),
        impairment4: getQuestionDataByCodeAndType(
          "PQ20-SQ04",
          "sub_question",
          "status"
        ),
        uwimpairment4: findFieldDataByCode("PQ20-SQ04-1", "value"),
        impairment5: getQuestionDataByCodeAndType(
          "PQ20-SQ05",
          "sub_question",
          "status"
        ),
        uwimpairment5: findFieldDataByCode("PQ20-SQ05-1", "value"),
        physicalDisability: getQuestionDataByCodeAndType(
          "PQ10",
          "main_question",
          "status"
        ),
        uwPhysicalDisabilityImpairment: findFieldDataByCode("PQ10-1", "value"),
        uwPhysicalDisabilityDiagnosisDate: findFieldDataByCode(
          "PQ10-2",
          "value"
        ),
        uwPhysicalDisabilityAddressDoctor: findFieldDataByCode(
          "PQ10-3",
          "value"
        ),
        uwPhysicalDisabilityInvestigationDetails: findFieldDataByCode(
          "PQ10-4",
          "value"
        ),
        uwPhysicalDisabilityUnderMedication: getQuestionDataByCodeAndType(
          "PQ10-SQ01",
          "sub_question",
          "status"
        ),
        uwPhysicalDisabilityFullyRecovered: getQuestionDataByCodeAndType(
          "PQ10-SQ02",
          "sub_question",
          "status"
        ),
        illnessDrugs: getQuestionDataByCodeAndType(
          "PQ11",
          "main_question",
          "status"
        ),
        uwillnessDrugsImpairment: findFieldDataByCode("PQ11-1", "value"),
        uwillnessDrugsDiagnosisDate: findFieldDataByCode("PQ11-2", "value"),
        uwillnessDrugsAddressDoctor: findFieldDataByCode("PQ11-3", "value"),
        uwillnessDrugsInvestigationDetails: findFieldDataByCode(
          "PQ11-4",
          "value"
        ),
        uwillnessDrugsUnderMedication: getQuestionDataByCodeAndType(
          "PQ11-SQ01",
          "sub_question",
          "status"
        ),
        uwillnessDrugsFullyRecovered: getQuestionDataByCodeAndType(
          "PQ11-SQ02",
          "sub_question",
          "status"
        ),
        sexuallyDisease: getQuestionDataByCodeAndType(
          "PQ12",
          "main_question",
          "status"
        ),
        uwsexuallyDiseaseImpairment: findFieldDataByCode("PQ12-1", "value"),
        uwsexuallyDiseaseDiagnosisDate: findFieldDataByCode("PQ12-2", "value"),
        uwsexuallyDiseaseAddressDoctor: findFieldDataByCode("PQ12-3", "value"),
        uwsexuallyDiseaseInvestigationDetails: findFieldDataByCode(
          "PQ12-4",
          "value"
        ),
        uwsexuallyDiseaseUnderMedication: getQuestionDataByCodeAndType(
          "PQ12-SQ01",
          "sub_question",
          "status"
        ),
        uwsexuallyDiseaseFullyRecovered: getQuestionDataByCodeAndType(
          "PQ12-SQ02",
          "sub_question",
          "status"
        ),
        recurrentMedical: getQuestionDataByCodeAndType(
          "PQ13",
          "main_question",
          "status"
        ),
        uwrecurrentMedicalImpairment: findFieldDataByCode("PQ13-1", "value"),
        uwrecurrentMedicalDiagnosisDate: findFieldDataByCode("PQ13-2", "value"),
        uwrecurrentMedicalAddressDoctor: findFieldDataByCode("PQ13-3", "value"),
        uwrecurrentMedicalInvestigationDetails: findFieldDataByCode(
          "PQ13-4",
          "value"
        ),
        uwrecurrentMedicalUnderMedication: getQuestionDataByCodeAndType(
          "PQ13-SQ01",
          "sub_question",
          "status"
        ),
        uwrecurrentMedicalFullyRecovered: getQuestionDataByCodeAndType(
          "PQ13-SQ02",
          "sub_question",
          "status"
        ),
        hospitalisation: getQuestionDataByCodeAndType(
          "PQ14",
          "main_question",
          "status"
        ),
        uwhospitalisationImpairment: findFieldDataByCode("PQ14-1", "value"),
        uwhospitalisationDiagnosisDate: findFieldDataByCode("PQ14-2", "value"),
        uwhospitalisationAddressDoctor: findFieldDataByCode("PQ14-3", "value"),
        uwhospitalisationInvestigationDetails: findFieldDataByCode(
          "PQ14-4",
          "value"
        ),
        uwhospitalisationUnderMedication: getQuestionDataByCodeAndType(
          "PQ14-SQ01",
          "sub_question",
          "status"
        ),
        uwhospitalisationFullyRecovered: getQuestionDataByCodeAndType(
          "PQ14-SQ02",
          "sub_question",
          "status"
        ),
        operation: getQuestionDataByCodeAndType(
          "PQ15",
          "main_question",
          "status"
        ),
        uwoperationImpairment: findFieldDataByCode("PQ15-1", "value"),
        uwoperationDiagnosisDate: findFieldDataByCode("PQ15-2", "value"),
        uwoperationAddressDoctor: findFieldDataByCode("PQ15-3", "value"),
        uwoperationInvestigationDetails: findFieldDataByCode("PQ15-4", "value"),
        uwoperationUnderMedication: getQuestionDataByCodeAndType(
          "PQ15-SQ01",
          "sub_question",
          "status"
        ),
        uwoperationFullyRecovered: getQuestionDataByCodeAndType(
          "PQ15-SQ02",
          "sub_question",
          "status"
        ),
        investigationCheckup: getQuestionDataByCodeAndType(
          "PQ16",
          "main_question",
          "status"
        ),
        uwinvestigationCheckupImpairment: findFieldDataByCode(
          "PQ16-1",
          "value"
        ),
        uwinvestigationCheckupDiagnosisDate: findFieldDataByCode(
          "PQ16-2",
          "value"
        ),
        uwinvestigationCheckupAddressDoctor: findFieldDataByCode(
          "PQ16-3",
          "value"
        ),
        uwinvestigationCheckupInvestigationDetails: findFieldDataByCode(
          "PQ16-4",
          "value"
        ),
        uwinvestigationCheckupUnderMedication: getQuestionDataByCodeAndType(
          "PQ16-SQ01",
          "sub_question",
          "status"
        ),
        uwinvestigationCheckupFullyRecovered: getQuestionDataByCodeAndType(
          "PQ16-SQ02",
          "sub_question",
          "status"
        ),
        uwMedicalLocation: findFieldDataByCode("PQ17-1", "value"),
        uwDoctorDetails: findFieldDataByCode("PQ17-2", "value"),
        heightFeet: `${medicalQuestion.height_ft.value}`,
        weight: `${medicalQuestion.weight_kg.value}`,
        alcohol: getQuestionDataByCodeAndType(
          "PQ18-SQ01",
          "sub_question",
          "status"
        ),
        consume_alcohal: findFieldDataByCode("PQ18-SQ01-1", "value"),
        quantity: findFieldDataByCode("PQ18-SQ01-2", "value"),
        tobacco: getQuestionDataByCodeAndType(
          "PQ18-SQ02",
          "sub_question",
          "status"
        ),
        consume_tobacco: findFieldDataByCode("PQ18-SQ02-1", "value"),
        stick: findFieldDataByCode("PQ18-SQ02-2", "value"),
        uwnarcotics: getQuestionDataByCodeAndType(
          "PQ18-SQ03",
          "sub_question",
          "status"
        ),
        familyHistory: getQuestionDataByCodeAndType(
          "PQ19-SQ01",
          "sub_question",
          "status"
        ),
        uwmagnumRelationShip: findFieldDataByCode("PQ19-SQ01-1", "value"),
        uwdisease: findFieldDataByCode("PQ19-SQ01-2", "value"),
        uwDiagnoseAge: findFieldDataByCode("PQ19-SQ01-3", "value"),
        uwAlive: findFieldDataByCode("PQ19-SQ01-4", "value"),
        uwCurDiedAge: findFieldDataByCode("PQ19-SQ01-5", "value"),
        pregnant: findFieldDataByCode("PQ20", "value"),
        pregnantWeeks: findFieldDataByCode("PQ20-1", "value"),
        abortion: findFieldDataByCode("PQ21", "value"),
        disease: findFieldDataByCode("PQ22", "value"),
        hysterectomy: findFieldDataByCode("PQ23", "value"),
        abortionDetails: findFieldDataByCode("PQ21-1", "value"),
        diseaseDetails: findFieldDataByCode("PQ22-1", "value"),
        hysterectomyDetails: findFieldDataByCode("PQ23-1", "value"),
      },
    };
    setLoader(true);
    PROPOSAL_PAGE_SERVICES.updateProposalPageHDFCDetails(
      onSuccess,
      onError,
      data
    );
  };

  useEffect(() => {
    getCountriesMaster();
    getCompanyBelongsMaster();
    getHeightMaster();
    getWeightMaster();
    getHobbiesMaster();
    getDiaseaMaster();
  }, []);

  const getCountriesMaster = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        let data: TDropdown[] = response.map((obj: any) => {
          return {
            value: obj.country_code,
            label: obj.country_name,
          };
        });

        dispatch(TermFullJourneySlice.actions.setHDFCCountryMaster(data));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    HDFC_PROPOSAL_PAGE_SERVICES.get_dropdown_master(onSuccess, onError, {
      under_writing_code: "countries",
    });
  };

  const getCompanyBelongsMaster = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        let data: TDropdown[] = response.map((obj: any) => {
          return {
            value: obj.code,
            label: obj.description,
          };
        });

        dispatch(TermFullJourneySlice.actions.setHDFCCompanyBelongsData(data));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    let param = {
      under_writing_code: "industryType",
    };
    HDFC_PROPOSAL_PAGE_SERVICES.get_dropdown_master(onSuccess, onError, param);
  };

  const getHeightMaster = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        let data: TDropdown[] = response.map((obj: any) => {
          return {
            value: obj.code,
            label: obj.description,
          };
        });

        dispatch(TermFullJourneySlice.actions.setHDFCHeightData(data));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    HDFC_PROPOSAL_PAGE_SERVICES.get_dropdown_master(onSuccess, onError, {
      under_writing_code: "height",
    });
  };

  const getWeightMaster = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        let data: TDropdown[] = response.map((obj: any) => {
          return {
            value: obj.weight_code,
            label: obj.weight_description,
          };
        });

        data.sort((a, b) => parseInt(a.value) - parseInt(b.value));

        dispatch(TermFullJourneySlice.actions.setHDFCWeightData(data));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    HDFC_PROPOSAL_PAGE_SERVICES.get_dropdown_master(onSuccess, onError, {
      under_writing_code: "weight",
    });
  };

  const getHobbiesMaster = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        let data: TDropdown[] = response.map((obj: any) => {
          return {
            value: obj.code,
            label: obj.description,
          };
        });

        dispatch(TermFullJourneySlice.actions.setHDFCHobbiesData(data));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    HDFC_PROPOSAL_PAGE_SERVICES.get_dropdown_master(onSuccess, onError, {
      under_writing_code: "avocations",
    });
  };

  const getDiaseaMaster = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        let data: TDropdown[] = response.map((obj: any) => {
          return {
            value: obj.code,
            label: obj.description,
          };
        });

        dispatch(TermFullJourneySlice.actions.setHDFCDiseaseMaster(data));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    HDFC_PROPOSAL_PAGE_SERVICES.get_dropdown_master(onSuccess, onError, {
      under_writing_code: "diseases",
    });
  };

  return (
    <>
      {isMobile ? (
        <MMedicalPage
          continue_function={continue_function}
          medicalQuestion={medicalQuestion}
          updateMasterState={updateMasterState}
          loader={loader}
          formData={formData}
        />
      ) : (
        <MedicalPage
          continue_function={continue_function}
          medicalQuestion={medicalQuestion}
          updateMasterState={updateMasterState}
          loader={loader}
          formData={formData}
        />
      )}
    </>
  );
}

export default MedicalPageContainer;
