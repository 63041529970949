import "@evervent_pvt_ltd/ui-kit/dist/index.css";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import {
  FRONTEND_DOMAIN,
  NODE_DOMAIN,
  PHP_DOMAIN,
} from "./URLCollection/Domain";
import "./index.scss";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";

// Initialize Sentry
Sentry.init({
  dsn: "https://980451ae1bbf9d9a83bcd5b1a4e1ef92@o4506864219521024.ingest.us.sentry.io/4506987932811264",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [FRONTEND_DOMAIN, PHP_DOMAIN, `${NODE_DOMAIN}`],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Get the root DOM element
const rootElement = document.getElementById("root");
if (!rootElement) {
  throw new Error("Root element not found");
}

// Create a root using React 18's createRoot
const root = createRoot(rootElement);

// Render the application
root.render(
  <Provider store={store}>
    <ToastContainer theme="colored" />
    <App />
  </Provider>
);

// Measure performance
reportWebVitals();
