import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import { CKYCSlice } from "../../../redux/slice/CKYC/CKYCSlice";
import { CommonDropdownSlice } from "../../../redux/slice/CommonDropdown/CommonDropdownSlice";
import { HealthSlice } from "../../../redux/slice/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  isEmpty,
  validateFullName,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TManualCKYCForm } from "../../../types/CKYC/TCKYC";
import { TDropdown } from "../../../types/Common/TDropdown";
import CAREManualKYC from "../../../Page/Desktop/CKYC/CARE/CAREManualKyc";
import { CKYCServcies } from "../../../Services/ckyc-node/CKYCServices";

function CAREManualKYCContainer() {
  const [loader, setLoader] = useState<boolean>(false);
  const [showManualPopup, setShowManualPopup] = useState<boolean>(false);
  const [showCKYCDetailsPopup, setShowCKYCDetailsPopup] =
    useState<boolean>(false);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const REDUX_HEAD = useAppSelector((state) => state);
  const { CKYC, Health, Travel } = useAppSelector((state) => state);
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  const [formDetails, setFormDetails] = useState<TManualCKYCForm>({
    customer_type: { value: "Individual", warning: false },
    dob: { value: "", warning: false },
    doc_type_id_proof: { value: "", warning: false },
    doc_no_id_proof: { value: "", warning: false },
    doc_id_proof_image: { value: "", warning: false },
    full_name: { value: "", warning: false },
    gender: { value: "", warning: false },
    doc_address_proof_image: { value: "", warning: false },
    doc_type_address_proof: { value: "", warning: false },
  });
  const [pageStatus, setPageStatus] = useState<string>("");

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "full_name") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: !validateFullName(value) },
      }));
    } else if (attrName === "doc_type_address_proof") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName === "doc_type_id_proof") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName === "customer_type") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
        dob: { value: "", warning: false },
      }));
    } else if (
      attrName === "doc_address_proof_image" ||
      attrName === "doc_id_proof_image"
    ) {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: false },
      }));
    } else {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    }
  };

  const GET_DROPDOWN_MASTER = (TYPE: "POI" | "POA" | "POV") => {
    const onSuccess = (res: any) => {
      const response = res.data.data;
      const error = res.data.error;
      if (!error) {
        const manupulated_data = response.map((item: any) => {
          return {
            value: item.doc_code,
            label: item.document,
          };
        });

        dispatch(
          CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
            key: TYPE,
            value: manupulated_data,
          })
        );
      }
    };

    const onError = (error: any) => {};
    const Health_params = {
      company_code: "G025",
      list_for: TYPE,
    };
    CKYCServcies.CKYC_DROPDOWN_MASTER(onError, onSuccess, Health_params);
  };

  const validateForm = () => {
    let data: TManualCKYCForm = { ...formDetails };

    data = {
      ...data,
      full_name: {
        ...data.full_name,
        warning: !validateFullName(data.full_name.value),
      },
      dob: { ...data.dob, warning: isEmpty(data.dob.value) },
      doc_type_id_proof: {
        ...data.doc_type_id_proof,
        warning: isEmpty(data.doc_type_id_proof.value),
      },
      doc_no_id_proof: {
        ...data.doc_no_id_proof,
        warning: isEmpty(data.doc_no_id_proof.value),
      },
      doc_id_proof_image: {
        ...data.doc_id_proof_image,
        warning: isEmpty(data.doc_id_proof_image.value),
      },
      customer_type: {
        ...data.customer_type,
        warning: isEmpty(data.customer_type.value),
      },
      gender: { ...data.gender, warning: isEmpty(data.gender.value) },
      doc_type_address_proof: {
        ...data.doc_type_address_proof,
        warning: isEmpty(data.doc_type_address_proof.value),
      },
      doc_address_proof_image: {
        ...data.doc_address_proof_image,
        warning: isEmpty(data.doc_address_proof_image.value),
      },
    };

    setFormDetails(data);

    let hasError =
      data.full_name.warning ||
      data.doc_type_id_proof.warning ||
      data.doc_no_id_proof.warning ||
      data.doc_id_proof_image.warning ||
      data.customer_type.warning ||
      data.dob.warning ||
      data.doc_type_address_proof.warning ||
      data.doc_address_proof_image.warning ||
      data.doc_id_proof_image.warning;

    if (data.customer_type.value !== "Organization") {
      hasError = hasError || data.gender.warning;
    }

    if (
      data.doc_id_proof_image.warning ||
      data.doc_address_proof_image.warning
    ) {
      toast.error("Please attach documents");
    }

    if (!hasError) {
      MANUAL_CKYC_DETAILS(data);
    }
  };

  const MANUAL_CKYC_DETAILS = (data: TManualCKYCForm) => {
    console.log("datatatat", data);
    const onSuccess = (res: any) => {
      setLoader(false);
      const results = res;
      const error = results.error;
      if (!error) {
        const message = results.message;
        const response = results.response;
        if (message === "No record found") {
        } else {
          dispatch(
            CKYCSlice.actions.BULK_UPDATE({
              ...CKYC,
              CKYC_DETAILS: response,
            })
          );
          setShowCKYCDetailsPopup(true);
        }
      } else {
        const message = results.message;
        toast.error(`${message}`);
        setShowManualPopup(false);
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };
    setLoader(true);
    switch (pageStatus) {
      case "TI":
        let travel_dto = {
          quote_no: Travel.ADD_FORM_RESPONSE.quote_no,
          product_code:
            Travel.SELECTED_QUOTE_DATA.quote_details?.productDetails
              .product_code,
          cusType: data.customer_type.value,
          kycCusName: data.full_name.value,
          dobForKyc: data.dob.value,
          docNameForKyc: data.doc_type_id_proof.value,
          docIDNoForKyc: data.doc_no_id_proof.value,
          genderForKyc: data.gender.value,
          insurance_type: "TI",
          ckycdetails: "doc",
          docNameForKyc1: data.doc_type_address_proof.value,
          indetityDocument: data.doc_id_proof_image.value,
          addressDocument: data.doc_address_proof_image.value,
          section: "premium_details",
          product_id:
            Travel.SELECTED_QUOTE_DATA.quote_details?.productDetails.id,
        };

        CKYCServcies.CARE_MANUAL_KYC(onSuccess, onError, travel_dto);
        break;
      case "HP":
        let health_dto = {
          quote_no: Health.ADD_FORM_RESPONSE.quote_no,
          cusType: data.customer_type.value,
          kycCusName: data.full_name.value,
          dobForKyc: data.dob.value,
          docNameForKyc: data.doc_type_id_proof.value,
          docIDNoForKyc: data.doc_no_id_proof.value,
          genderForKyc: data.gender.value,
          docNameForKyc1: data.doc_type_address_proof.value,
          indetityDocument: data.doc_id_proof_image.value,
          insurance_type: "HP",
          addressDocument: data.doc_address_proof_image.value, // quote_no: Health.ADD_FORM_RESPONSE.quote_no,
          // product_code: Health.SELECTED_QUOTE_DATA.productDetails.product_code,
          // cusType: data.customer_type.value,
          // kycCusName: data.full_name.value,
          // dobForKyc: data.dob.value,
          // docNameForKyc: data.doc_type_id_proof.value,
          // docIDNoForKyc: data.doc_no_id_proof.value,
          // genderForKyc: data.gender.value,
          // insurance_type: "HP",
          // ckycdetails: "doc",
          // docNameForKyc1: data.doc_type_address_proof.value,
          // indetityDocument: data.doc_id_proof_image.value,
          // addressDocument: data.doc_address_proof_image.value,
          // section: "premium_details",
          // product_id: Health.SELECTED_QUOTE_DATA.productDetails.id,
        };

        CKYCServcies.CARE_MANUAL_KYC(onSuccess, onError, health_dto);
        break;
      default:
        break;
    }
  };

  const CONTINUE_TO_PROPOSAL = () => {
    switch (pageStatus) {
      case "HP":
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            INSURED_MEMBER_DETAILS: {
              ...Health.INSURED_MEMBER_DETAILS,
              PROPOSER_DETAILS: {
                ...Health.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS,
                name: {
                  value: CKYC.CKYC_DETAILS.kycCustomerName,
                  warning: false,
                },
                dob: {
                  value: CKYC.CKYC_DETAILS.kycDOB,
                  warning: false,
                },
                mobile: { value: CKYC.CKYC_DETAILS.kycMobile, warning: false },
                emergencyPhone: { value: "", warning: false },
                email: { value: CKYC.CKYC_DETAILS.kycEmail, warning: false },
                aadhar: { value: "", warning: false },
                gender: {
                  value: VERIFY_KYC_FORM.gender.value,
                  warning: false,
                },
                maritalStatus: { value: "", warning: false },
                weight: { value: "", warning: false },
                heightFeet: { value: "", warning: false },
                heightInches: { value: "", warning: false },
                occupation: { value: "", warning: false },
                relationship: { value: "", warning: false },
                proposer_name: { value: "", warning: false },
              },
            },
            ADDRESS_DETAILS: {
              ...Health.ADDRESS_DETAILS,
              pincode: { value: CKYC.CKYC_DETAILS.kycPincode, warning: false },
              city: CKYC.CKYC_DETAILS.kycCity,
              state: CKYC.CKYC_DETAILS.kycState,
              addressLineOne: {
                value: CKYC.CKYC_DETAILS.kycAddress1,
                warning: false,
              },
              addressLineTwo: {
                value: CKYC.CKYC_DETAILS.kycAddress2,
                warning: false,
              },
              isMailingAddressSame: true,
              mailingPinCode: { value: "", warning: false },
              mailingCity: "",
              mailingState: "",
              mailingAddressLineOne: { value: "", warning: false },
              mailingAddressLineTwo: { value: "", warning: false },
            },
          })
        );

        navigate(
          // `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}`
          `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADDON_DETAILS}?type=HP&companyCode=${Health?.SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${Health?.ADD_FORM_RESPONSE.quote_no}&product_id=${Health?.SELECTED_QUOTE_DATA?.productDetails.id}`
        );

        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const type = params.get("type");

    if (type) {
      setPageStatus(type);
    }
  }, []);

  useEffect(() => {
    GET_DROPDOWN_MASTER("POV");
  }, []);

  return (
    <>
      {isMobile ? null : (
        <CAREManualKYC
          updateMasterState={updateMasterState}
          formDetails={formDetails}
          validateForm={validateForm}
          CONTINUE_TO_PROPOSAL={CONTINUE_TO_PROPOSAL}
          setShowCKYCDetailsPopup={setShowCKYCDetailsPopup}
          setShowManualPopup={setShowManualPopup}
          showCKYCDetailsPopup={showCKYCDetailsPopup}
          showManualPopup={showManualPopup}
          loader={loader}
        />
      )}
    </>
  );
}

export default CAREManualKYCContainer;
