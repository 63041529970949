import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PreviewDetails from "../../../../../Page/Desktop/TermFullJourney/HDFC/Proposal/PreviewDetails/PreviewDetails";
import MPreviewDetails from "../../../../../Page/Mobile/TermFullJourney/HDFC/Proposal/PreviewDetails/MPreviewDetails";
import { TERM_FULL_JOURNEY_ROUTES } from "../../../../../Router/Path/TermFullJourneyRoutes";
import { HDFC_PROPOSAL_PAGE_SERVICES } from "../../../../../Services/TermFullJourneyServices/HDFCProposalPageSubmissionService";
import { TermFullJourneySlice } from "../../../../../redux/slice/TermFullJourney/TermFullJourneySlice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { FORMAT_DATE_DD_MM_YYYY } from "../../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../../../types/Common/TDropdown";

function PreviewPageContainer() {
  const [loader, setLoader] = useState<boolean>(false);
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const { SELECTED_QUOTE_DATA, ADD_FORM_RESPONSE } = useAppSelector(
    (state) => state.Term
  );
  const dropdownData = useAppSelector(
    (state) => state.TermFullJourney.dropdownData
  );
  const proposer_details = useAppSelector(
    (state) => state.TermFullJourney.proposerDetails
  );
  const insured_member_details = useAppSelector(
    (state) => state.TermFullJourney.insuredDetails
  );
  const nominee_details = useAppSelector(
    (state) => state.TermFullJourney.nomineeDetails
  );
  const address_details = useAppSelector(
    (state) => state.TermFullJourney.addressDetails
  );

  const findLabelByCode = (
    selected_value: any,
    dropdown_array: TDropdown[]
  ) => {
    const value =
      selected_value?.value === undefined
        ? selected_value
        : selected_value?.value;
    const foundItem = dropdown_array.find((item) => item.value === value);

    if (foundItem) {
      return foundItem.label;
    } else {
      return "Label not found" + value + value; // or handle accordingly
    }
  };

  const previewDetails = [
    {
      title: "Proposer Details",
      editFunction: () => {
        navigate(TERM_FULL_JOURNEY_ROUTES.PROPOSER_DETAILS);

        dispatch(TermFullJourneySlice.actions.updateUpdateStatus(true));
      },
      detailView: [
        {
          label: "Full Name",
          label_value: proposer_details.full_name.value,
        },
        {
          label: "DOB",
          label_value: FORMAT_DATE_DD_MM_YYYY(`${proposer_details.dob.value}`),
        },
        {
          label: "Gender",
          label_value: dropdownData.genderData.find(
            (item) => item.value === proposer_details.gender.value
          )?.value,
        },
        {
          label: "Mobile",
          label_value: proposer_details.mobile.value,
        },
        {
          label: "Emergency Phone",
          label_value: proposer_details.emergency_number.value,
        },
        {
          label: "Email",
          label_value: proposer_details.email.value,
        },
        {
          label: "Aadhar",
          label_value:
            "XXXX XXXX " + `${proposer_details.aadhar_number.value}`.slice(-4),
        },
        {
          label: "Marital Status",
          label_value: findLabelByCode(
            proposer_details.marital_status.value,
            dropdownData.maritalStatusData
          ),
        },
        {
          label: "Occupation",
          label_value: findLabelByCode(
            proposer_details.occupation.value,
            dropdownData.occupationData
          ),
        },
        {
          label: "Education",
          label_value: findLabelByCode(
            proposer_details.education.value,
            dropdownData.EDUCATION_MASTER
          ),
        },
      ],
    },
    {
      title: "Insured Member Details",
      editFunction: () => {
        navigate(TERM_FULL_JOURNEY_ROUTES.PROPOSER_DETAILS);

        dispatch(TermFullJourneySlice.actions.updateUpdateStatus(true));
      },
      detailView: [
        {
          label: "Full Name",
          label_value: insured_member_details.full_name.value,
        },
        {
          label: "DOB",
          label_value: FORMAT_DATE_DD_MM_YYYY(
            `${insured_member_details.dob.value}`
          ),
        },
        {
          label: "Gender",
          label_value: dropdownData.genderData.find(
            (item) => item.value === insured_member_details.gender.value
          )?.value,
        },
        {
          label: "Mobile",
          label_value: insured_member_details.mobile.value,
        },
        {
          label: "Email",
          label_value: insured_member_details.email.value,
        },
        {
          label: "Aadhar",
          label_value:
            "XXXX XXXX " +
            `${insured_member_details.aadhar_number.value}`.slice(-4),
        },
        {
          label: "Marital Status",
          label_value: findLabelByCode(
            insured_member_details.marital_status.value,
            dropdownData.maritalStatusData
          ),
        },
        {
          label: "Occupation",
          label_value: findLabelByCode(
            insured_member_details.occupation.value,
            dropdownData.occupationData
          ),
        },
      ],
    },
    {
      title: "Nominee Details",
      editFunction: () => {
        navigate(TERM_FULL_JOURNEY_ROUTES.NOMINEE_DETAILS);

        dispatch(TermFullJourneySlice.actions.updateUpdateStatus(true));
      },
      detailView: [
        {
          label: "Full Name",
          label_value: nominee_details.full_name.value,
        },
        {
          label: "Relationship with Proposer",
          label_value: dropdownData.nomineeRelationshipData.find(
            (item) => item.value === nominee_details.relationship.value
          )?.value,
        },
        {
          label: "Gender",
          label_value: dropdownData.genderData.find(
            (item) => item.value === nominee_details.gender.value
          )?.value,
        },
        {
          label: "DOB",
          label_value: FORMAT_DATE_DD_MM_YYYY(`${nominee_details.dob.value}`),
        },
      ],
    },
  ];

  const permanentAddressDetails = {
    title: "Address Details",
    editFunction: () => {
      navigate(TERM_FULL_JOURNEY_ROUTES.ADDRESS_DETAILS);

      dispatch(TermFullJourneySlice.actions.updateUpdateStatus(true));
    },
    detailView: [
      {
        label: "H.No./Building",
        label_value: address_details.permanent_address.house_no_building.value,
      },
      {
        label: "Street/Area",
        label_value: address_details.permanent_address.area_town_locality.value,
      },
      {
        label: "Pincode",
        label_value: address_details.permanent_address.pincode.value,
      },
      {
        label: "City",
        label_value: address_details.permanent_address.city,
      },
      {
        label: "State",
        label_value: address_details.permanent_address.state,
      },
    ],
  };

  const mailingAddressDetails = {
    title: "Address Details",
    detailView: [
      {
        label: "H.No./Building",
        label_value: address_details.mailing_address.house_no_building.value,
      },
      {
        label: "Street/Area",
        label_value: address_details.mailing_address.area_town_locality.value,
      },
      {
        label: "Pincode",
        label_value: address_details.mailing_address.pincode.value,
      },
      {
        label: "City",
        label_value: address_details.mailing_address.city,
      },
      {
        label: "State",
        label_value: address_details.mailing_address.state,
      },
    ],
  };

  const proposalSubmission = () => {
    HDFC_CKYC();
  };

  const HDFC_CKYC = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        HDFC_TERM_APP_SUBMISSION();
      } else {
        setLoader(false);
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    const data: any = {
      product_id: `${SELECTED_QUOTE_DATA.productDetails.id}`,
      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
    };
    setLoader(true);
    HDFC_PROPOSAL_PAGE_SERVICES.ckyc(onSuccess, onError, data);
  };

  const HDFC_TERM_APP_SUBMISSION = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        HDFC_TERM_GET_REQUIREMENT();
      } else {
        setLoader(false);
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    const data: any = {
      product_id: `${SELECTED_QUOTE_DATA.productDetails.id}`,
      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
    };
    setLoader(true);
    HDFC_PROPOSAL_PAGE_SERVICES.term_application_submission(
      onSuccess,
      onError,
      data
    );
  };

  const HDFC_TERM_GET_REQUIREMENT = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        dispatch(
          TermFullJourneySlice.actions.SET_HDFC_UPLOAD_DOC_DETAILS(response)
        );
        navigate(TERM_FULL_JOURNEY_ROUTES.UPLOAD_DOCUMENT);
      } else {
        console.log({ error: error, message: message });
      }
      setLoader(false);
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    const data: any = {
      product_id: `${SELECTED_QUOTE_DATA.productDetails.id}`,
      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
    };
    setLoader(true);
    HDFC_PROPOSAL_PAGE_SERVICES.get_term_requirement(onSuccess, onError, data);
  };

  return (
    <>
      {isMobile ? (
        <MPreviewDetails
          loader={loader}
          PreviewDetails={previewDetails}
          permanentAddressDetails={permanentAddressDetails}
          mailingAddressDetails={mailingAddressDetails}
          address_details={address_details}
          continueFunction={proposalSubmission}
        />
      ) : (
        <PreviewDetails
          loader={loader}
          PreviewDetails={previewDetails}
          permanentAddressDetails={permanentAddressDetails}
          mailingAddressDetails={mailingAddressDetails}
          address_details={address_details}
          continueFunction={proposalSubmission}
        />
      )}
    </>
  );
}

export default PreviewPageContainer;
