import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TNonTermProposerData } from "../../../types/TNonTermSlice";
import {
  isEmpty,
  validateEmail,
  validateFullName,
  validateMobileNumber,
} from "../../../SupportingFiles/HelpingFunction";
import NonTermProposal from "../../../Page/Desktop/NonTermProposal/SHRIRAM/SHRIRAMNonTermProposal";
import { NonTermSlice } from "../../../redux/slice/NonTerm/NonTermSlice";
import { NON_TERM_SERVICES } from "../../../Services/NonTerm/NonTermFormService";
import { toast } from "react-toastify";
import MNonTermProposal from "../../../Page/Mobile/MNonTermProposal/SHRIRAM/MNonTermProposal";

export const NonTermBasicDetailsContainer = () => {
  const isMobile = useIsMobile();
  const [loader, setLoader] = useState<boolean>(false);
  const {
    PROPOSER_DATA,
    ADD_FORM,
    ADD_FORM_RESPONSE,
    UPDATE_RESPONSE,
    SELECTED_QUOTE_DATA,
    QUOTE_FIELDS_DATA,
  } = useAppSelector((state) => state.NonTerm);
  const [proposerFields, setProposerFields] =
    useState<TNonTermProposerData>(PROPOSER_DATA);
  const dispatch = useAppDispatch();
  const [showOTPPopup, setShowOTPPopup] = useState(false);
  const [otpValue, setOtpValue] = useState("");

  const fieldsUpdateState = (attr: any, value: any) => {
    console.log("attr", attr, value);
    setProposerFields({
      ...proposerFields,
      [attr[0]]: {
        value: `${value}`?.trimStart(),
        warning:
          attr[0] === "full_name"
            ? !validateFullName(value)
            : attr[0] === "mobile"
            ? !validateMobileNumber(value)
            : attr[0] === "email"
            ? !validateEmail(value)
            : attr[0] === "emergency_number"
            ? !validateMobileNumber(value)
            : isEmpty(value),
      },
    });
  };

  const GET_REDIRECTION_URL = () => {
    dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    const onSuccess = (res: any) => {
      dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      setLoader(false);
      const error = res?.error;
      if (!error) {
        const response = res?.response;
        const redirectionUrl = response.redirectionUrl;
        window.location.href = redirectionUrl;
      }
    };
    const onError = () => {
      dispatch(NonTermSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      setLoader(false);
    };
    const params = {
      product_ids: [SELECTED_QUOTE_DATA?.productDetails?.id],
      quote_no: ADD_FORM_RESPONSE.quote_no,
    };
    NON_TERM_SERVICES.GET_REDIRECTION_URL(onSuccess, onError, params);
  };

  const SEND_OTP = () => {
    let params = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_ids: [SELECTED_QUOTE_DATA.productDetails.id],
    };
    const onSuccess = (res: any) => {
      setLoader(false);
    };
    const onError = () => {
      setLoader(false);
    };
    setLoader(true);
    NON_TERM_SERVICES.SEND_OTP(onSuccess, onError, params);
  };

  const VERIFY_OTP = () => {
    let params = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_ids: [SELECTED_QUOTE_DATA.productDetails.id],
      otp: otpValue,
    };
    const onSuccess = (res: any) => {
      setLoader(false);
      if (res.ResultSet.ResponseCode === "200") {
        GET_REDIRECTION_URL();
      } else {
        toast.error(res.ResultSet.ResponseMsg);
      }
    };
    const onError = () => {
      setLoader(false);
    };
    setLoader(true);
    NON_TERM_SERVICES.VERIFY_OTP(onSuccess, onError, params);
  };

  const UPDATE_PROPOSER_DETAILS = () => {
    let params = {
      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
      section: "proposer_details",
      details: {
        proposer_dob: UPDATE_RESPONSE?.dob || ADD_FORM.age.value,
        proposer_email: proposerFields?.email?.value,
        proposer_emergency_no: proposerFields?.emergency_number?.value,
        proposer_gender: ADD_FORM.gender.value,
        proposer_mobile: ADD_FORM.mobile.value,
        proposer_name: proposerFields?.full_name?.value,
        address_pincode: proposerFields.pincode.value,
      },
    };
    const onSuccess = (res: any) => {
      setLoader(false);
      setShowOTPPopup(true);
      SEND_OTP();
    };
    const onError = () => {
      setLoader(false);
    };
    setLoader(true);
    NON_TERM_SERVICES.UPDATE_PROPOSER_DETAILS(onSuccess, onError, params);
  };

  const validateForm = () => {
    let data = { ...proposerFields };
    data = {
      ...data,
      full_name: {
        ...data.full_name,
        warning: !validateFullName(data.full_name.value),
      },
      dob: {
        ...data.dob,
        warning: isEmpty(data.dob.value),
      },
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
      email: {
        ...data.email,
        warning: !validateEmail(data.email.value),
      },
    };
    setProposerFields({ ...data });
    if (!data.full_name.warning && !data.email.warning) {
      dispatch(NonTermSlice.actions.UPDATE_PROPOSER_DATA(data));
      UPDATE_PROPOSER_DETAILS();
    }
  };

  return (
    <>
      {isMobile ? (
        <MNonTermProposal
          proposerFields={proposerFields}
          fieldsUpdateState={fieldsUpdateState}
          validateForm={validateForm}
          loader={loader}
          showOTPPopup={showOTPPopup}
          setShowOTPPopup={setShowOTPPopup}
          setOtpValue={setOtpValue}
          otpValue={otpValue}
          VERIFY_OTP={VERIFY_OTP}
        />
      ) : (
        <NonTermProposal
          proposerFields={proposerFields}
          fieldsUpdateState={fieldsUpdateState}
          validateForm={validateForm}
          loader={loader}
          showOTPPopup={showOTPPopup}
          setShowOTPPopup={setShowOTPPopup}
          setOtpValue={setOtpValue}
          otpValue={otpValue}
          VERIFY_OTP={VERIFY_OTP}
        />
      )}
    </>
  );
};
