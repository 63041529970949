import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  isEmpty,
  validateFullName,
} from "../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { TDropdownData } from "../../../../types/DropdownData/DropdownData";
import AdultTwoDetails from "../../../../Page/Desktop/Travel/CARE/AdultTwoDetails/AdultTwoDetails";
import { TTwoAdultMemberDetails } from "../../../../types/Travel/TTravelSlice";
import { TRAVEL_PROPOSAL_SERVICES } from "../../../../Services/Travel/TravelPropsalServices";
import { TravelSlice } from "../../../../redux/slice/Travel/TravelSlice";
import { TRAVEL_ROUTE } from "../../../../Router/Path/TravelRoutes";

export default function MemberDetailContainer() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const {
    ADD_FORM,
    INSURED_MEMBER_DETAILS,
    ADD_FORM_RESPONSE,
    PAGE_STATUS,
    SELECTED_QUOTE_DATA,
  } = useAppSelector((state) => state.Travel);
  const { Travel } = useAppSelector((state) => state);
  const [memberFields, setMemberFields] = useState<TTwoAdultMemberDetails>(
    INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS
  );
  const dispatch = useAppDispatch();
  const [relationshipList, setRelationshipList] = useState<TDropdownData[]>([
    { value: "Spouse", label: "Spouse" },
    { value: "Father", label: "Father" },
    { value: "Mother", label: "Mother" },
  ]);

  const fieldsUpdateState = (attrName: any, value: any) => {
    setMemberFields((prev: any) => ({
      ...prev,
      [attrName]: {
        value: value,
        warning: false,
      },
    }));
  };

  const UPDATE_PROPOSER_NOMINEE_DATA = (
    ADULT_TWO_DETAILS: TTwoAdultMemberDetails
  ) => {
    const onSuccess = (res: any) => {
      console.log("res...", res?.results);
      let hasError = false;
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      const cus_type = params.get("cus_type");

      if (PAGE_STATUS) {
        navigate(
          `${TRAVEL_ROUTE.PROPOSAL_PAGE}/${
            TRAVEL_ROUTE.PREVIEW_DETAILS
          }?type=TI&companyCode=${SELECTED_QUOTE_DATA?.companyCode}&quote_no=${
            ADD_FORM_RESPONSE.quote_no
          }&product_id=${SELECTED_QUOTE_DATA?.productID}${
            cus_type === "YWdlbnQ=" ? "&user_type=YWdlbnQ=" : ""
          }`
        );
      } else {
        if (!isEmpty(ADD_FORM.memberDetails.child1_age.value)) {
          navigate(
            `${TRAVEL_ROUTE.PROPOSAL_PAGE}/${
              TRAVEL_ROUTE.CHILD_ONE_DETAILS
            }?type=TI&companyCode=${
              SELECTED_QUOTE_DATA?.companyCode
            }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
              SELECTED_QUOTE_DATA?.productID
            }${cus_type === "YWdlbnQ=" ? "&user_type=YWdlbnQ=" : ""}`
          );
        } else {
          navigate(
            `${TRAVEL_ROUTE.PROPOSAL_PAGE}/${
              TRAVEL_ROUTE.ADDRESS_DETAILS
            }?type=TI&companyCode=${
              SELECTED_QUOTE_DATA?.companyCode
            }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
              SELECTED_QUOTE_DATA?.productID
            }${cus_type === "YWdlbnQ=" ? "&user_type=YWdlbnQ=" : ""}`
          );
        }
      }
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };

    let param = {
      adult2Relationship: ADULT_TWO_DETAILS.relationship.value,
      spouseName: ADULT_TWO_DETAILS.name.value,
      spouseDOB: ADULT_TWO_DETAILS.dob.value,
      adult2PassportNumber: ADULT_TWO_DETAILS.spouse_passport.value,
      section: "adult2_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      cover: ADD_FORM.members.value,
      sale_panel: "undefined",
      saveAllDetails: "Yes",
      finalPremium: SELECTED_QUOTE_DATA.quote_details.premiumDetails.premium,
    };
    TRAVEL_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  const validateForm = () => {
    let formValue = { ...memberFields };
    formValue = {
      ...formValue,
      dob: { ...formValue.dob, warning: isEmpty(formValue.dob.value) },
      name: {
        ...formValue.name,
        warning: !validateFullName(formValue.name.value),
      },
      relationship: {
        ...formValue.relationship,
        warning: isEmpty(formValue.relationship.value),
      },
    };
    setMemberFields({ ...formValue });
    if (
      !formValue.dob.warning &&
      !formValue.name.warning &&
      !formValue.relationship.warning
    ) {
      dispatch(
        TravelSlice.actions.BULK_UPLOAD({
          ...Travel,
          INSURED_MEMBER_DETAILS: {
            ...Travel.INSURED_MEMBER_DETAILS,
            ADULT_TWO_DETAILS: { ...formValue },
          },
        })
      );
      UPDATE_PROPOSER_NOMINEE_DATA(formValue);
    }
  };

  useEffect(() => {
    setMemberFields({ ...INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS });
  }, [INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value]);

  return isMobile ? null : (
    <AdultTwoDetails
      fieldsUpdateState={fieldsUpdateState}
      memberFields={memberFields}
      validateForm={validateForm}
      relationshipList={relationshipList}
    />
  );
}
