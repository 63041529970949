import React, { useEffect, useState } from "react";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MedicalDetailNivaBupa from "../../../Page/Desktop/health-php/NIVA_BUPA/medicalDetails/MedicalDetailNivaBupa";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { TNivaBupaMedicalQuestion } from "../../../types/Health/HealthQuotation/TNivaBupaMedicalQuestion";
import { isEmpty } from "../../../SupportingFiles/HelpingFunction";
import { HEALTH_PROPOSAL_SERVICES } from "../../../Services/health-php/HealthPropsalServices";
import { HealthSlice } from "../../../redux/slice/Health/HealthSlice";
import { THealthQuote } from "../../../types/Health/HealthQuotation/THealthQuote";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import MMedicalDetailNivaBupa from "../../../Page/Mobile/health-php/NIVA_BUPA/medicalDetails/MMedicalDetailNivaBupa";
import { toast } from "react-toastify";

const MedicalDetailContainerNivaBupa = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [userType, setUserType] = useState<string>("");
  const [utm_medium, setUtm_medium] = useState<string>("");
  const [utm_source, setUtm_source] = useState<string>("");

  useEffect(() => {
    // Access the query parameters directly from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const userTypeFromQuery = searchParams.get("user_type");
    const utm_mediumFromQuery = searchParams.get("utm_medium");
    const utm_sourceFromQuery = searchParams.get("utm_source");
    setUtm_medium(`${utm_mediumFromQuery}`);
    setUtm_source(`${utm_sourceFromQuery}`);
    setUserType(
      atob(`${userTypeFromQuery}`) === "agent" ? btoa("agent") : btoa("user")
    );
  }, []);
  const {
    INSURED_MEMBER_DETAILS,
    PAGE_STATUS,
    MEDICAL_QUESTION_DATA_NIVA_BUPA,
    SELECTED_QUOTE_DATA,
    ADD_FORM_RESPONSE,
    Preview_Loader,
    ADDON_QUESTION_NIVA_BUPA,
  } = useAppSelector((state) => state.Health);
  const [change_status, setchange_status] = useState(false);
  const [medicalQuestionData, setMedicalQuestionData] =
    useState<TNivaBupaMedicalQuestion>(MEDICAL_QUESTION_DATA_NIVA_BUPA);

  const setMemberDataAsPerUserSelection = () => {
    let setMemberData: any = { ...MEDICAL_QUESTION_DATA_NIVA_BUPA };
    setMemberData = {
      MQ01: {
        main_question: {
          id: "MQ01",
          icon_class: "tobaco",
          title: "",
          description:
            "Other than common cold, flu, infections, minor injury or other minor ailments; has the Applicant ever been diagnosed with any disease and / or hospitalized for more than 5 days and / or undergone / advised to undergo any surgical procedures and / or taken any medication/ had any symptoms for more than 14 days? Medication is including but not limited to inhalers, injections, oral drugs and external medical applications on body parts?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ02: {
        main_question: {
          id: "MQ02",
          icon_class: "tobaco",
          title: "",
          description:
            "Has the Applicant ever had adverse findings to any diagnostic tests or investigations related to Thyroid Profile, Lipid Profile, Treadmill test, Angiography, Echocardiography, Endoscopy, Ultrasound, CT Scan, MRI, Biopsy and FNAC?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ03: {
        main_question: {
          id: "MQ03",
          icon_class: "tobaco",
          title: "",
          description:
            "Does the Applicant have diabetes or pre-diabetes or has he/she EVER had high blood sugar?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "HbA1c",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "HbA1c",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "HbA1c",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "HbA1c",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "HbA1c",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F8": {
                  id: "MQ1-SQ1-F8",
                  title: "HbA1c",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ04: {
        main_question: {
          id: "MQ04",
          icon_class: "tobaco",
          title: "",
          description:
            "Does the Applicant have Hypertension or High Blood Pressure?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                // "MQ1-SQ1-F8": {
                //   id: "MQ1-SQ1-F8",
                //   title: "HbA1c",
                //   field_type: "TEXTFIELD",
                //   value: { value: "", warning: false },
                // },
                "MQ1-SQ1-F9": {
                  id: "MQ1-SQ1-F9",
                  title: "Systolic",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F10": {
                  id: "MQ1-SQ1-F10",
                  title: "Diastolic",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                // "MQ1-SQ1-F8": {
                //   id: "MQ1-SQ1-F8",
                //   title: "HbA1c",
                //   field_type: "TEXTFIELD",
                //   value: { value: "", warning: false },
                // },
                "MQ1-SQ1-F9": {
                  id: "MQ1-SQ1-F9",
                  title: "Systolic",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F10": {
                  id: "MQ1-SQ1-F10",
                  title: "Diastolic",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                // "MQ1-SQ1-F8": {
                //   id: "MQ1-SQ1-F8",
                //   title: "HbA1c",
                //   field_type: "TEXTFIELD",
                //   value: { value: "", warning: false },
                // },
                "MQ1-SQ1-F9": {
                  id: "MQ1-SQ1-F9",
                  title: "Systolic",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F10": {
                  id: "MQ1-SQ1-F10",
                  title: "Diastolic",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                // "MQ1-SQ1-F8": {
                //   id: "MQ1-SQ1-F8",
                //   title: "HbA1c",
                //   field_type: "TEXTFIELD",
                //   value: { value: "", warning: false },
                // },
                "MQ1-SQ1-F9": {
                  id: "MQ1-SQ1-F9",
                  title: "Systolic",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F10": {
                  id: "MQ1-SQ1-F10",
                  title: "Diastolic",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                // "MQ1-SQ1-F8": {
                //   id: "MQ1-SQ1-F8",
                //   title: "HbA1c",
                //   field_type: "TEXTFIELD",
                //   value: { value: "", warning: false },
                // },
                "MQ1-SQ1-F9": {
                  id: "MQ1-SQ1-F9",
                  title: "Systolic",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F10": {
                  id: "MQ1-SQ1-F10",
                  title: "Diastolic",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                // "MQ1-SQ1-F8": {
                //   id: "MQ1-SQ1-F8",
                //   title: "HbA1c",
                //   field_type: "TEXTFIELD",
                //   value: { value: "", warning: false },
                // },
                "MQ1-SQ1-F9": {
                  id: "MQ1-SQ1-F9",
                  title: "Systolic",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F10": {
                  id: "MQ1-SQ1-F10",
                  title: "Diastolic",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ05: {
        main_question: {
          id: "MQ05",
          icon_class: "tobaco",
          title: "",
          description:
            "Has the Applicant ever been diagnosed or treated for any genetic / hereditary disorders / HIV?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ06: {
        main_question: {
          id: "MQ06",
          icon_class: "tobaco",
          title: "",
          description:
            "Has the Applicant ever been diagnosed or treated for any mental/ psychiatric disorders?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Details of Symptom(s)",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Date",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Duration of Condition",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Medication",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F5": {
                  id: "MQ1-SQ1-F5",
                  title: "Dosage",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F6": {
                  id: "MQ1-SQ1-F6",
                  title: "Current Status",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F7": {
                  id: "MQ1-SQ1-F7",
                  title: "Doctor's details",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ07: {
        main_question: {
          id: "MQ07",
          icon_class: "tobaco",
          title: "",
          description:
            "Has any proposal for life, health, hospital daily cash or critical illness insurance on the life of the Applicant ever been declined, postponed, loaded or subjected to any special conditions such as exclusions by any insurance company?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
          },
        },
      },
      MQ08: {
        main_question: {
          id: "MQ08",
          icon_class: "tobaco",
          title: "",
          description:
            "Are you a politically exposed person (PEP) or a close relative of PEP?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
          },
        },
      },
      MQ09: {
        main_question: {
          id: "MQ09",
          icon_class: "tobaco",
          title: "",
          description: "DO you have any EIA account?",
          status: false,
          field_data: {
            "MQ1-SQ1-F1": {
              id: "MQ1-SQ1-F1",
              title: "Account Number",
              field_type: "TEXTFIELD",
              value: { value: "", warning: false },
            },
            "MQ1-SQ1-F2": {
              id: "MQ1-SQ1-F2",
              title: "Repository Name",
              field_type: "DROPDOWN",
              value: { value: "", warning: false },
            },
          },
        },
      },
      MQ10: {
        main_question: {
          id: "MQ10",
          icon_class: "tobaco",
          title:
            "Does the applicant consume Chewable tobacco /Gutkha/Pan Masala,Alcohol,Drugs,Cigarettes/Bidi/Cigar",
          description: "",
          status: false,
          sub_question: {
            "MQ1-SQ1": {
              id: "MQ1-SQ1",
              icon_class: "",
              status: false,
              title: "",
              description: "Chewable tobacco / Gutkha / Pan Masala",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "No. of pouches per day",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "No. of pouches per day",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                // child_one: {
                //   keyName: "child_one",
                //   name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                //   image_class: "child",
                //   selection_status: false,
                //   field_data: {
                //     "MQ1-SQ1-F1": {
                //       id: "MQ1-SQ1-F1",
                //       title: "No. of pouches per day",
                //       field_type: "TEXTFIELD",
                //       value: { value: "", warning: false },
                //     },
                //   },
                // },
                // child_two: {
                //   keyName: "child_two",
                //   name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                //   image_class: "child",
                //   selection_status: false,
                //   field_data: {
                //     "MQ1-SQ1-F1": {
                //       id: "MQ1-SQ1-F1",
                //       title: "No. of pouches per day",
                //       field_type: "TEXTFIELD",
                //       value: { value: "", warning: false },
                //     },
                //   },
                // },
                // child_three: {
                //   keyName: "child_three",
                //   name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                //   image_class: "child",
                //   selection_status: false,
                //   field_data: {
                //     "MQ1-SQ1-F1": {
                //       id: "MQ1-SQ1-F1",
                //       title: "No. of pouches per day",
                //       field_type: "TEXTFIELD",
                //       value: { value: "", warning: false },
                //     },
                //   },
                // },
                // child_four: {
                //   keyName: "child_four",
                //   name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                //   image_class: "child",
                //   selection_status: false,
                //   field_data: {
                //     "MQ1-SQ1-F1": {
                //       id: "MQ1-SQ1-F1",
                //       title: "No. of pouches per day",
                //       field_type: "TEXTFIELD",
                //       value: { value: "", warning: false },
                //     },
                //   },
                // },
              },
            },
            "MQ2-SQ2": {
              id: "MQ2-SQ2",
              icon_class: "",
              status: false,
              title: "",
              description: "Alcohol",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Alcohal (No. of ml per week)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Is Daily Drinker",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Alcohal (No. of ml per week)",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Is Daily Drinker",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                  },
                },
                // child_one: {
                //   keyName: "child_one",
                //   name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                //   image_class: "child",
                //   selection_status: false,
                //   field_data: {
                //     "MQ1-SQ1-F1": {
                //       id: "MQ1-SQ1-F1",
                //       title: "Alcohal (No. of ml per week)",
                //       field_type: "TEXTFIELD",
                //       value: { value: "", warning: false },
                //     },
                //     "MQ1-SQ1-F2": {
                //       id: "MQ1-SQ1-F2",
                //       title: "Is Daily Drinker",
                //       field_type: "DROPDOWN",
                //       value: { value: "", warning: false },
                //     },
                //   },
                // },
                // child_two: {
                //   keyName: "child_two",
                //   name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                //   image_class: "child",
                //   selection_status: false,
                //   field_data: {
                //     "MQ1-SQ1-F1": {
                //       id: "MQ1-SQ1-F1",
                //       title: "Alcohal (No. of ml per week)",
                //       field_type: "TEXTFIELD",
                //       value: { value: "", warning: false },
                //     },
                //     "MQ1-SQ1-F2": {
                //       id: "MQ1-SQ1-F2",
                //       title: "Is Daily Drinker",
                //       field_type: "DROPDOWN",
                //       value: { value: "", warning: false },
                //     },
                //   },
                // },
                // child_three: {
                //   keyName: "child_three",
                //   name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                //   image_class: "child",
                //   selection_status: false,
                //   field_data: {
                //     "MQ1-SQ1-F1": {
                //       id: "MQ1-SQ1-F1",
                //       title: "Alcohal (No. of ml per week)",
                //       field_type: "TEXTFIELD",
                //       value: { value: "", warning: false },
                //     },
                //     "MQ1-SQ1-F2": {
                //       id: "MQ1-SQ1-F2",
                //       title: "Is Daily Drinker",
                //       field_type: "DROPDOWN",
                //       value: { value: "", warning: false },
                //     },
                //   },
                // },
                // child_four: {
                //   keyName: "child_four",
                //   name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                //   image_class: "child",
                //   selection_status: false,
                //   field_data: {
                //     "MQ1-SQ1-F1": {
                //       id: "MQ1-SQ1-F1",
                //       title: "Alcohal (No. of ml per week)",
                //       field_type: "TEXTFIELD",
                //       value: { value: "", warning: false },
                //     },
                //     "MQ1-SQ1-F2": {
                //       id: "MQ1-SQ1-F2",
                //       title: "Is Daily Drinker",
                //       field_type: "DROPDOWN",
                //       value: { value: "", warning: false },
                //     },
                //   },
                // },
              },
            },
            "MQ3-SQ3": {
              id: "MQ3-SQ3",
              icon_class: "",
              status: false,
              title: "",
              description: "Cigarettes / Bidi / Cigar",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Per week Consumption",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Per week Consumption",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                // child_one: {
                //   keyName: "child_one",
                //   name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                //   image_class: "child",
                //   selection_status: false,
                //   field_data: {
                //     "MQ1-SQ1-F1": {
                //       id: "MQ1-SQ1-F1",
                //       title: "Per week Consumption",
                //       field_type: "TEXTFIELD",
                //       value: { value: "", warning: false },
                //     },
                //   },
                // },
                // child_two: {
                //   keyName: "child_two",
                //   name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                //   image_class: "child",
                //   selection_status: false,
                //   field_data: {
                //     "MQ1-SQ1-F1": {
                //       id: "MQ1-SQ1-F1",
                //       title: "Per week Consumption",
                //       field_type: "TEXTFIELD",
                //       value: { value: "", warning: false },
                //     },
                //   },
                // },
                // child_three: {
                //   keyName: "child_three",
                //   name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                //   image_class: "child",
                //   selection_status: false,
                //   field_data: {
                //     "MQ1-SQ1-F1": {
                //       id: "MQ1-SQ1-F1",
                //       title: "Per week Consumption",
                //       field_type: "TEXTFIELD",
                //       value: { value: "", warning: false },
                //     },
                //   },
                // },
                // child_four: {
                //   keyName: "child_four",
                //   name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                //   image_class: "child",
                //   selection_status: false,
                //   field_data: {
                //     "MQ1-SQ1-F1": {
                //       id: "MQ1-SQ1-F1",
                //       title: "Per week Consumption",
                //       field_type: "TEXTFIELD",
                //       value: { value: "", warning: false },
                //     },
                //   },
                // },
              },
            },
          },
        },
      },
    };
    setMedicalQuestionData({ ...setMemberData });
  };

  const [dailyDrinker, setDailyDrinker] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(false);

  useEffect(() => {
    let manipulatedMedicalQuestion: any = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );

    const checkFields = [
      manipulatedMedicalQuestion?.MQ10?.main_question?.sub_question?.["MQ2-SQ2"]
        ?.member_data?.adult_one?.field_data["MQ1-SQ1-F2"]?.value?.value,
      manipulatedMedicalQuestion?.MQ10?.main_question?.sub_question?.["MQ2-SQ2"]
        ?.member_data?.adult_two?.field_data["MQ1-SQ1-F2"]?.value?.value,
      manipulatedMedicalQuestion?.MQ10?.main_question?.sub_question?.["MQ2-SQ2"]
        ?.member_data?.child_one?.field_data["MQ1-SQ1-F2"]?.value?.value,
      manipulatedMedicalQuestion?.MQ10?.main_question?.sub_question?.["MQ2-SQ2"]
        ?.member_data?.child_two?.field_data["MQ1-SQ1-F2"]?.value?.value,
      manipulatedMedicalQuestion?.MQ10?.main_question?.sub_question?.["MQ2-SQ2"]
        ?.member_data?.child_three?.field_data["MQ1-SQ1-F2"]?.value?.value,
      manipulatedMedicalQuestion?.MQ10?.main_question?.sub_question?.["MQ2-SQ2"]
        ?.member_data?.child_four?.field_data["MQ1-SQ1-F2"]?.value?.value,
    ];

    const anyYes = checkFields.some((fieldValue) => fieldValue === "Yes");

    if (anyYes) {
      setDailyDrinker(true);
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [medicalQuestionData]);

  useEffect(() => {
    if (PAGE_STATUS === true || Preview_Loader === true) {
      setMedicalQuestionData(MEDICAL_QUESTION_DATA_NIVA_BUPA);
    } else {
      setMemberDataAsPerUserSelection();
    }
  }, [MEDICAL_QUESTION_DATA_NIVA_BUPA, PAGE_STATUS]);
  let flag = false;
  const updateMasterState = (attrName: any, value: any) => {
    console.log(attrName, "attrName", value);

    let manipulatedMedicalQuestion: any = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );

    const clearFieldsAndStatuses = (questionData: any) => {
      for (const field in questionData.main_question?.field_data) {
        questionData.main_question.field_data[field].value = {
          value: "",
          warning: false,
        };
      }
      for (const subQuestion in questionData.main_question?.sub_question) {
        clearSubQuestionFieldsAndStatuses(
          questionData.main_question.sub_question[subQuestion]
        );
      }
      for (const member in questionData.main_question?.member_data) {
        for (const field in questionData.main_question.member_data[member]
          ?.field_data) {
          questionData.main_question.member_data[member].field_data[
            field
          ].value = {
            value: "",
            warning: false,
          };
        }
        questionData.main_question.member_data[member].selection_status = false;
      }
    };

    const clearSubQuestionFieldsAndStatuses = (subQuestionData: any) => {
      for (const field in subQuestionData?.field_data) {
        subQuestionData.field_data[field].value = {
          value: "",
          warning: false,
        };
      }
      for (const member in subQuestionData?.member_data) {
        for (const field in subQuestionData.member_data[member]?.field_data) {
          subQuestionData.member_data[member].field_data[field].value = {
            value: "",
            warning: false,
          };
        }
        subQuestionData.member_data[member].selection_status = false;
      }
      subQuestionData.status = false;
    };

    const clearMemberFieldsAndStatuses = (memberData: any) => {
      for (const field in memberData?.field_data) {
        memberData.field_data[field].value = {
          value: "",
          warning: false,
        };
      }
      memberData.selection_status = false;
    };

    if (attrName[0] === "question") {
      manipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question,
            status: value,
          },
        },
      };

      if (!value) {
        clearFieldsAndStatuses(manipulatedMedicalQuestion[attrName[1]]);
      }

      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    } else if (attrName[0] === "subQuestion") {
      let updatedSubQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question,
        [attrName[2]]: {
          ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
            attrName[2]
          ],
          status: value,
        },
      };

      let updatedMainQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question,
        sub_question: updatedSubQuestion,
      };

      let updatedManipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: updatedMainQuestion,
        },
      };

      if (!value) {
        clearSubQuestionFieldsAndStatuses(
          manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
            attrName[2]
          ]
        );
      }

      setMedicalQuestionData(updatedManipulatedMedicalQuestion);
    } else if (attrName[0] === "mainMember") {
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question.sub_question[
          attrName[2]
        ]?.member_data[attrName[3]] !== undefined
      ) {
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              sub_question: {
                ...manipulatedMedicalQuestion[attrName[1]].main_question
                  .sub_question,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    .sub_question?.[attrName[2]],
                  member_data: {
                    ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                      .sub_question?.[attrName[2]].member_data,
                    [attrName[3]]: {
                      ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                        .sub_question?.[attrName[2]].member_data?.[attrName[3]],
                      selection_status: value,
                    },
                  },
                },
              },
            },
          },
        };

        if (!value) {
          clearMemberFieldsAndStatuses(
            manipulatedMedicalQuestion[attrName[1]]?.main_question
              .sub_question?.[attrName[2]]?.member_data?.[attrName[3]]
          );
        }

        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      }
    } else if (attrName[0] === "mainMemberData") {
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data[
          attrName[2]
        ] !== undefined
      )
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              member_data: {
                ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                  .member_data,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    ?.member_data?.[attrName[2]],
                  selection_status: value,
                },
              },
            },
          },
        };

      if (!value) {
        clearMemberFieldsAndStatuses(
          manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data?.[
            attrName[2]
          ]
        );
      }

      setMedicalQuestionData(manipulatedMedicalQuestion);
    } else if (
      attrName[0] === "mainMemberDataField" &&
      attrName[1] !== "MQ09"
    ) {
      if (
        (attrName[1] === "MQ10" && attrName[2] === "adult_one") ||
        (attrName[1] === "MQ10" && attrName[2] === "adult_two") ||
        (attrName[1] === "MQ10" && attrName[2] === "child_one") ||
        (attrName[1] === "MQ10" && attrName[2] === "child_two") ||
        (attrName[1] === "MQ10" && attrName[2] === "child_three") ||
        (attrName[1] === "MQ10" &&
          attrName[2] === "child_four" &&
          manipulatedMedicalQuestion !== undefined)
      ) {
        manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
          attrName[4]
        ].member_data[attrName[2]].field_data[attrName[3]].value = {
          value: value,
          warning: isEmpty(value),
        };

        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      } else {
        manipulatedMedicalQuestion[attrName[1]].main_question.member_data[
          attrName[2]
        ].field_data[attrName[3]].value = {
          value: value,
          warning: isEmpty(value),
        };

        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      }
    } else if (
      attrName[0] === "mainMemberDataField" &&
      attrName[1] === "MQ09"
    ) {
      manipulatedMedicalQuestion[attrName[1]].main_question.field_data[
        attrName[3]
      ].value = {
        value: value,
        warning: isEmpty(value),
      };

      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    }
  };

  function validateForm() {
    if (!medicalQuestionData) {
      return; // Exit early if medicalQuestionData is null or undefined
    }
    let hasError = false;
    let data: TNivaBupaMedicalQuestion = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    // ADDON_QUESTION_NIVA_BUPA.MQ06.main_question.status && data.MQ03.main_question.status && data.MQ04.main_question.status
    if (ADDON_QUESTION_NIVA_BUPA.MQ06.main_question.status) {
      if (!data.MQ03.main_question.status && !data.MQ04.main_question.status) {
        hasError = true;
        toast.error(
          "Select Anyone from Hypertension or diabetes Medical Question"
        );
      }
    }

    if (medicalQuestionData.MQ09.main_question.status) {
      if (medicalQuestionData?.MQ09?.main_question?.field_data)
        if (
          isEmpty(
            medicalQuestionData?.MQ09?.main_question?.field_data["MQ1-SQ1-F1"]
              .value?.value
          ) ||
          isEmpty(
            medicalQuestionData?.MQ09?.main_question?.field_data["MQ1-SQ1-F2"]
              .value?.value
          )
        ) {
          toast.error("Please fill all mandatory fields");
          return;
        }
    }

    Object.values(data).forEach((element: any) => {
      const mainQuestion = element?.main_question;

      if (mainQuestion) {
        // Check if mainQuestion has subQuestions
        if (mainQuestion.sub_question) {
          Object.values(mainQuestion.sub_question).forEach(
            (subQuestion: any) => {
              if (subQuestion && subQuestion.status) {
                const hasFalseSelectionStatus = Object.values(
                  subQuestion.member_data
                ).some((memberdata: any) => !memberdata.selection_status);
                const hasTrueSelectionStatus = Object.values(
                  subQuestion.member_data
                ).some((memberdata: any) => memberdata.selection_status);

                if (hasFalseSelectionStatus && !hasTrueSelectionStatus) {
                  hasError = true;
                  toast.error("Select Sub-Question members");
                }
              }
            }
          );
        } else if (mainQuestion.status && mainQuestion.member_data) {
          // No subQuestions, handle member_data directly
          const hasFalseSelectionStatus = Object.values(
            mainQuestion.member_data
          ).some((memberdata: any) => !memberdata.selection_status);
          const hasTrueSelectionStatus = Object.values(
            mainQuestion.member_data
          ).some((memberdata: any) => memberdata.selection_status);

          if (hasFalseSelectionStatus && !hasTrueSelectionStatus) {
            hasError = true;
            toast.error("Select members");
          }
        }
      }
    });

    Object.values(data).forEach((element: any) => {
      const mainQuestion = element?.main_question;
      if (mainQuestion && mainQuestion.status && mainQuestion.sub_question) {
        Object.values(mainQuestion.sub_question).forEach((subQuestion: any) => {
          if (subQuestion && subQuestion.status) {
            Object.values(subQuestion.member_data).forEach(
              (memberdata: any) => {
                if (
                  memberdata &&
                  memberdata.selection_status &&
                  memberdata.field_data
                ) {
                  Object.values(memberdata.field_data).forEach(
                    (field_data: any) => {
                      if (field_data && field_data.value?.value === "") {
                        let clonedValue = {
                          ...field_data.value,
                          warning: true,
                        };
                        if (field_data.value) {
                          field_data.value = clonedValue;
                        }

                        hasError = true;
                      }
                    }
                  );
                }
              }
            );
          }
        });
      } else {
        if (mainQuestion && mainQuestion.member_data) {
          Object.values(mainQuestion.member_data).forEach((memberdata: any) => {
            if (
              memberdata &&
              memberdata.selection_status &&
              memberdata.field_data
            ) {
              Object.values(memberdata.field_data).forEach(
                (field_data: any) => {
                  if (field_data && field_data.value?.value === "") {
                    let clonedValue = {
                      ...field_data.value,
                      warning: true,
                    };

                    if (field_data.value) {
                      field_data.value = clonedValue;
                    }
                    hasError = true;
                  }
                }
              );
            }
          });
        }
      }
    });
    setMedicalQuestionData(data);
    if (!hasError) {
      dispatch(HealthSlice.actions.SAVE_MEDICAL_QUESTION_DATA_NIVA_BUPA(data));
      const onSuccess = (res: any) => {
        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
        if (res.results.error === false) {
          let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
          quoteData = {
            ...quoteData,
            premiumDetails: {
              ...quoteData.premiumDetails,
              finalPremium: res.results.response.premiumDetails.finalPremium,
            },
          };
          dispatch(HealthSlice.actions.SAVE_SELECTED_QUOTE_DATA(quoteData));
        }
      };
      const onError = (err: any) => {
        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
        console.log("err..", err);
      };
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
      const param = {
        quote_no: ADD_FORM_RESPONSE.quote_no,
        company_code: "G023",
        product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
        medical_info: "Yes",
        medicalPreExistingDiseases:
          data.MQ01.main_question.status === true ? "Yes" : "NO",
        medicalPreExisting:
          data.MQ01.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "NO",
        self_exact_currently:
          data?.MQ01.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        self_date_of_currently:
          data?.MQ01.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        self_details_of_currently:
          data?.MQ01.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        self_currently_month:
          data?.MQ01.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        self_currently_year:
          data?.MQ01.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,
        self_currently_status:
          data?.MQ01.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F6"
          ].value?.value,
        self_currently_doctor_name:
          data?.MQ01.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F7"
          ].value?.value,
        spouse_medicalPreExisting:
          data.MQ01.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "NO",
        spouse_exact_currently:
          data?.MQ01.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouse_date_of_currently:
          data?.MQ01.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        spouse_details_of_currently:
          data?.MQ01.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        spouse_currently_month:
          data?.MQ01.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        spouse_currently_year:
          data?.MQ01.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,
        spouse_currently_status:
          data?.MQ01.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F6"
          ].value?.value,
        spouse_currently_doctor_name:
          data?.MQ01.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F7"
          ].value?.value,
        child1_medicalPreExisting:
          data.MQ01.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "NO",
        child1_exact_currently:
          data?.MQ01.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child1_date_of_currently:
          data?.MQ01.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child1_details_of_currently:
          data?.MQ01.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child1_currently_month:
          data?.MQ01.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child1_currently_year:
          data?.MQ01.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,
        child1_currently_status:
          data?.MQ01.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F6"
          ].value?.value,
        child1_currently_doctor_name:
          data?.MQ01.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F7"
          ].value?.value,
        child2_medicalPreExisting:
          data.MQ01.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "NO",
        child2_exact_currently:
          data?.MQ01.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child2_date_of_currently:
          data?.MQ01.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child2_details_of_currently:
          data?.MQ01.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child2_currently_month:
          data?.MQ01.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child2_currently_year:
          data?.MQ01.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,
        child2_currently_status:
          data?.MQ01.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F6"
          ].value?.value,
        child2_currently_doctor_name:
          data?.MQ01.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F7"
          ].value?.value,
        medicalHospitalizedDiseases:
          data.MQ02.main_question.status === true ? "Yes" : "NO", // Has the Applicant ever had adverse findings to any diagnostic tests or investigations related to Thyroid Profile, Lipid Profile, Treadmill test, Angiography, Echocardiography, Endoscopy, Ultrasound, CT Scan, MRI, Biopsy and FNAC?
        medicalHospitalized:
          data.MQ02.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "NO",
        self_exact_hospitalized:
          data?.MQ02.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        self_date_of_hospitalized:
          data?.MQ02.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        self_details_of_hospitalized:
          data?.MQ02.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        self_hospitalized_month:
          data?.MQ02.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        self_hospitalized_year:
          data?.MQ02.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,
        self_hospitalized_status:
          data?.MQ02.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F6"
          ].value?.value,
        self_hospitalized_doctor_name:
          data?.MQ02.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F7"
          ].value?.value,
        spouse_medicalHospitalized:
          data.MQ02.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "NO",
        spouse_exact_hospitalized:
          data?.MQ02.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouse_date_of_hospitalized:
          data?.MQ02.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        spouse_details_of_hospitalized:
          data?.MQ02.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        spouse_hospitalized_month:
          data?.MQ02.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        spouse_hospitalized_year:
          data?.MQ02.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,
        spouse_hospitalized_status:
          data?.MQ02.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F6"
          ].value?.value,
        spouse_hospitalized_doctor_name:
          data?.MQ02.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F7"
          ].value?.value,
        child1_medicalHospitalized:
          data.MQ02.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "NO",
        child1_exact_hospitalized:
          data?.MQ02.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child1_date_of_hospitalized:
          data?.MQ02.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child1_details_of_hospitalized:
          data?.MQ02.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child1_hospitalized_month:
          data?.MQ02.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child1_hospitalized_year:
          data?.MQ02.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,
        child1_hospitalized_status:
          data?.MQ02.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F6"
          ].value?.value,
        child1_hospitalized_doctor_name:
          data?.MQ02.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F7"
          ].value?.value,
        child2_medicalHospitalized:
          data.MQ02.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "NO",
        child2_exact_hospitalized:
          data?.MQ02.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child2_date_of_hospitalized:
          data?.MQ02.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child2_details_of_hospitalized:
          data?.MQ02.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child2_hospitalized_month:
          data?.MQ02.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child2_hospitalized_year:
          data?.MQ02.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,
        child2_hospitalized_status:
          data?.MQ02.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F6"
          ].value?.value,
        child2_hospitalized_doctor_name:
          data?.MQ02.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F7"
          ].value?.value,
        medicalDiabetesDiseases:
          data.MQ03.main_question.status === true ? "Yes" : "NO", // Does the Applicant have diabetes or pre-diabetes or has he/she EVER had high blood sugar?
        medicalDiabetes:
          data.MQ03.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "NO",
        self_details_of_hba:
          data?.MQ03.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F8"
          ].value?.value,
        self_exact_diabetes:
          data?.MQ03.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        self_date_of_diabetes:
          data?.MQ03.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        self_details_of_diabetes:
          data?.MQ03.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        self_diabetes_month:
          data?.MQ03.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        self_diabetes_year:
          data?.MQ03.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,
        self_diabetes_status:
          data?.MQ03.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F6"
          ].value?.value,
        self_diabetes_doctor_name:
          data?.MQ03.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F7"
          ].value?.value,
        spouse_medicalDiabetes:
          data.MQ03.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "NO",
        spouse_details_of_hba:
          data?.MQ03.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F8"
          ].value?.value,
        spouse_exact_diabetes:
          data?.MQ03.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouse_date_of_diabetes:
          data?.MQ03.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        spouse_details_of_diabetes:
          data?.MQ03.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        spouse_diabetes_month:
          data?.MQ03.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        spouse_diabetes_year:
          data?.MQ03.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,
        spouse_diabetes_status:
          data?.MQ03.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F6"
          ].value?.value,
        spouse_diabetes_doctor_name:
          data?.MQ03.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F7"
          ].value?.value,
        child1_medicalDiabetes:
          data.MQ03.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "NO",
        child1_details_of_hba:
          data?.MQ03.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F8"
          ].value?.value,
        child1_exact_diabetes:
          data?.MQ03.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child1_date_of_diabetes:
          data?.MQ03.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child1_details_of_diabetes:
          data?.MQ03.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child1_diabetes_month:
          data?.MQ03.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child1_diabetes_year:
          data?.MQ03.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,
        child1_diabetes_status:
          data?.MQ03.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F6"
          ].value?.value,
        child1_diabetes_doctor_name:
          data?.MQ03.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F7"
          ].value?.value,
        child2_medicalDiabetes:
          data.MQ03.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "NO",
        child2_details_of_hba:
          data?.MQ03.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F8"
          ].value?.value,
        child2_exact_diabetes:
          data?.MQ03.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child2_date_of_diabetes:
          data?.MQ03.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child2_details_of_diabetes:
          data?.MQ03.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child2_diabetes_month:
          data?.MQ03.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child2_diabetes_year:
          data?.MQ03.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,
        child2_diabetes_status:
          data?.MQ03.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F6"
          ].value?.value,
        child2_diabetes_doctor_name:
          data?.MQ03.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F7"
          ].value?.value,
        medicalNarotics: data.MQ04.main_question.status === true ? "Yes" : "NO", //Does the Applicant have Hypertension or High Blood Pressure?
        respiratory_year:
          data.MQ04.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "NO",
        self_details_of_systolic:
          data?.MQ04.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F10"
          ].value?.value,
        self_details_of_diastolic:
          data?.MQ04.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F9"
          ].value?.value,
        self_exact_bloodPressure:
          data?.MQ04.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        self_date_of_bloodPressure:
          data?.MQ04.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        self_details_of_bloodPressure:
          data?.MQ04.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        self_bloodPressure_month:
          data?.MQ04.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        self_bloodPressure_year:
          data?.MQ04.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        self_bloodPressure_status:
          data?.MQ04.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F6"
          ].value?.value,
        self_bloodPressure_doctor_name:
          data?.MQ04.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F7"
          ].value?.value,
        spouse_respiratory_year:
          data.MQ04.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "NO",
        spouse_details_of_systolic:
          data?.MQ04.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F10"
          ].value?.value,
        spouse_details_of_diastolic:
          data?.MQ04.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F9"
          ].value?.value,
        spouse_exact_bloodPressure:
          data?.MQ04.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouse_date_of_bloodPressure:
          data?.MQ04.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        spouse_details_of_bloodPressure:
          data?.MQ04.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        spouse_bloodPressure_month:
          data?.MQ04.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        spouse_bloodPressure_year:
          data?.MQ04.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        spouse_bloodPressure_status:
          data?.MQ04.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F6"
          ].value?.value,
        spouse_bloodPressure_doctor_name:
          data?.MQ04.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F7"
          ].value?.value,
        child1_respiratory_year:
          data.MQ04.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "NO",
        child1_details_of_systolic:
          data?.MQ04.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F10"
          ].value?.value,
        child1_details_of_diastolic:
          data?.MQ04.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F9"
          ].value?.value,
        child1_exact_bloodPressure:
          data?.MQ04.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child1_date_of_bloodPressure:
          data?.MQ04.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child1_details_of_bloodPressure:
          data?.MQ04.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child1_bloodPressure_month:
          data?.MQ04.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child1_bloodPressure_year:
          data?.MQ04.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child1_bloodPressure_status:
          data?.MQ04.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F6"
          ].value?.value,
        child1_bloodPressure_doctor_name:
          data?.MQ04.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F7"
          ].value?.value,
        child2_respiratory_year:
          data.MQ04.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "NO",
        child2_details_of_systolic:
          data?.MQ04.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F10"
          ].value?.value,
        child2_details_of_diastolic:
          data?.MQ04.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F9"
          ].value?.value,
        child2_exact_bloodPressure:
          data?.MQ04.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child2_date_of_bloodPressure:
          data?.MQ04.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child2_details_of_bloodPressure:
          data?.MQ04.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child2_bloodPressure_month:
          data?.MQ04.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child2_bloodPressure_year:
          data?.MQ04.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,

        child2_bloodPressure_status:
          data?.MQ04.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F6"
          ].value?.value,
        child2_bloodPressure_doctor_name:
          data?.MQ04.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F7"
          ].value?.value,
        medicalMedicationDiseases:
          data.MQ05.main_question.status === true ? "Yes" : "NO", //  the Applicant ever been diagnosed or treated for any genetic / hereditary disorders / HIV?
        medicalMedication:
          data.MQ05.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "NO",
        self_exact_medication:
          data?.MQ05.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        self_date_of_medication:
          data?.MQ05.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        self_details_of_medication:
          data?.MQ05.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        self_medication_month:
          data?.MQ05.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        self_medication_year:
          data?.MQ05.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,
        self_medication_status:
          data?.MQ05.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F6"
          ].value?.value,
        self_medication_doctor_name:
          data?.MQ05.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F7"
          ].value?.value,
        spouse_medicalMedication:
          data.MQ05.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "NO",
        spouse_exact_medication:
          data?.MQ05.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouse_date_of_medication:
          data?.MQ05.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        spouse_details_of_medication:
          data?.MQ05.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        spouse_medication_month:
          data?.MQ05.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        spouse_medication_year:
          data?.MQ05.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,
        spouse_medication_status:
          data?.MQ05.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F6"
          ].value?.value,
        spouse_medication_doctor_name:
          data?.MQ05.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F7"
          ].value?.value,
        child1_medicalMedication:
          data.MQ05.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "NO",
        child1_exact_medication:
          data?.MQ05.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child1_date_of_medication:
          data?.MQ05.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child1_details_of_medication:
          data?.MQ05.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child1_medication_month:
          data?.MQ05.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child1_medication_year:
          data?.MQ05.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,
        child1_medication_status:
          data?.MQ05.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F6"
          ].value?.value,
        child1_medication_doctor_name:
          data?.MQ05.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F7"
          ].value?.value,
        child2_medicalMedication:
          data.MQ05.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "NO",
        child2_exact_medication:
          data?.MQ05.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child2_date_of_medication:
          data?.MQ05.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child2_details_of_medication:
          data?.MQ05.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child2_medication_month:
          data?.MQ05.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child2_medication_year:
          data?.MQ05.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,
        child2_medication_status:
          data?.MQ05.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F6"
          ].value?.value,
        child2_medication_doctor_name:
          data?.MQ05.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F7"
          ].value?.value,
        medicalHypertensionDiseases:
          data.MQ06.main_question.status === true ? "Yes" : "NO", // Has the Applicant ever been diagnosed or treated for any mental/ psychiatric disorders?
        medicalHypertension:
          data.MQ06.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "NO",
        self_exact_hypertension:
          data?.MQ06.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        self_date_of_hypertension:
          data?.MQ06.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        self_details_of_hypertension:
          data?.MQ06.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        self_hypertension_month:
          data?.MQ06.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        self_hypertension_year:
          data?.MQ06.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,
        self_hypertension_status:
          data?.MQ06.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F6"
          ].value?.value,
        self_hypertension_doctor_name:
          data?.MQ06.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F7"
          ].value?.value,
        spouse_medicalHypertension:
          data.MQ06.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "NO",
        spouse_exact_hypertension:
          data?.MQ06.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        spouse_date_of_hypertension:
          data?.MQ06.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        spouse_details_of_hypertension:
          data?.MQ06.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        spouse_hypertension_month:
          data?.MQ06.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        spouse_hypertension_year:
          data?.MQ06.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,
        spouse_hypertension_status:
          data?.MQ06.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F6"
          ].value?.value,
        spouse_hypertension_doctor_name:
          data?.MQ06.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F7"
          ].value?.value,
        child1_medicalHypertension:
          data.MQ06.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "NO",
        child1_exact_hypertension:
          data?.MQ06.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child1_date_of_hypertension:
          data?.MQ06.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child1_details_of_hypertension:
          data?.MQ06.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child1_hypertension_month:
          data?.MQ06.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child1_hypertension_year:
          data?.MQ06.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,
        child1_hypertension_status:
          data?.MQ06.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F6"
          ].value?.value,
        child1_hypertension_doctor_name:
          data?.MQ06.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F7"
          ].value?.value,
        child2_medicalHypertension:
          data.MQ06.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "NO",
        child2_exact_hypertension:
          data?.MQ06.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F1"
          ].value?.value,
        child2_date_of_hypertension:
          data?.MQ06.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F2"
          ].value?.value,
        child2_details_of_hypertension:
          data?.MQ06.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F3"
          ].value?.value,
        child2_hypertension_month:
          data?.MQ06.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F4"
          ].value?.value,
        child2_hypertension_year:
          data?.MQ06.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F5"
          ].value?.value,
        child2_hypertension_status:
          data?.MQ06.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F6"
          ].value?.value,
        child2_hypertension_doctor_name:
          data?.MQ06.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F7"
          ].value?.value,
        medicalMedicinesDiseases:
          data.MQ07.main_question.status === true ? "Yes" : "NO", // Has any proposal for life, health, hospital daily cash or critical illness insurance on the life of the Applicant ever been declined, postponed, loaded or subjected to any special conditions such as exclusions by any insurance company?
        medicalMedicines:
          data.MQ07.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "NO",
        spouse_medicalMedicines:
          data.MQ07.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "NO",
        child1_medicalMedicines:
          data.MQ07.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "NO",
        child2_medicalMedicines:
          data.MQ07.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "NO",
        medicalOtherPolicyDiseases:
          data.MQ08.main_question.status === true ? "Yes" : "NO", // Are you a politically exposed person (PEP) or a close relative of PEP?
        medicalOtherPolicy:
          data.MQ08.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "NO",
        spouse_medicalOtherPolicy:
          data.MQ08.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "NO",
        child1_medicalOtherPolicy:
          data.MQ08.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "NO",
        child2_medicalOtherPolicy:
          data.MQ08.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "NO",
        medicalAccountPolicyDiseases:
          data.MQ09.main_question.status === true ? "Yes" : "NO", // DO you have any EIA account?
        medicalAccountPolicy: `${data?.MQ09.main_question.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        medicalAccountNamePolicy: `${data?.MQ09.main_question.field_data?.["MQ1-SQ1-F2"].value?.value}`, // NSDL,CIRL,Karvy,CAMS
        medicalOtherSubstance:
          data.MQ10.main_question.status === true ? "Yes" : "NO", //Does the applicant consume Chewable tobacco /Gutkha/Pan Masala,Alcohol,Drugs,Cigarettes/Bidi/Cigar
        medicalTobaccoDiseases:
          data?.MQ10?.main_question?.sub_question?.["MQ1-SQ1"]?.status === true
            ? "Yes"
            : "NO", // Chewable tobacco / Gutkha / Pan Masala
        medicalTobacco:
          data?.MQ10?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        tobacco_month: `${data?.MQ10?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        spouse_medicalTobacco:
          data?.MQ10?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_tobacco_month: `${data?.MQ10?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        medicalAlcoholDiseases:
          data?.MQ10?.main_question?.sub_question?.["MQ2-SQ2"]?.status === true
            ? "Yes"
            : "NO", // Alcohol
        medicalAlcohol:
          data?.MQ10?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        alcohol_month: `${data?.MQ10?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        is_daily_drinker: `${data?.MQ10?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value}`,
        spouse_medicalAlcohol:
          data?.MQ10?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_alcohol_month: `${data?.MQ10?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        spouse_is_daily_drinker: `${data?.MQ10?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value}`,
        medicalSmokingDiseases:
          data?.MQ10?.main_question?.sub_question?.["MQ3-SQ3"]?.status === true
            ? "Yes"
            : "NO", // Cigarettes / Bidi / Cigar
        medicalSmoking:
          data?.MQ10?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        smoking_month: `${data?.MQ10?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        spouse_medicalSmoking:
          data?.MQ10?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_smoking_month: `${data?.MQ10?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        react_medical: data,
      };

      HEALTH_PROPOSAL_SERVICES.getMedicalData(onSuccess, onError, param);
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
      );
    }
  }

  useEffect(() => {
    if (medicalQuestionData?.MQ08?.main_question?.status) {
      toast.error(
        "Politically exposed person (PEP) is not allowed for this Plan"
      );
    }
  }, [medicalQuestionData?.MQ08?.main_question?.status]);

  let data: TNivaBupaMedicalQuestion = JSON.parse(
    JSON.stringify(medicalQuestionData)
  );
  useEffect(() => {
    if (
      ADDON_QUESTION_NIVA_BUPA?.MQ06?.main_question?.status &&
      data?.MQ03?.main_question?.status &&
      data?.MQ04?.main_question?.status
    ) {
    }
  }, []);

  return (
    <>
      {isMobile ? (
        <MMedicalDetailNivaBupa
          medicalQuestionData={medicalQuestionData}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          PAGE_STATUS={PAGE_STATUS}
          setDailyDrinker={setDailyDrinker}
          dailyDrinker={dailyDrinker}
          disable={disable}
        />
      ) : (
        <MedicalDetailNivaBupa
          medicalQuestionData={medicalQuestionData}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          PAGE_STATUS={PAGE_STATUS}
          setDailyDrinker={setDailyDrinker}
          dailyDrinker={dailyDrinker}
          disable={disable}
        />
      )}
    </>
  );
};

export default MedicalDetailContainerNivaBupa;
