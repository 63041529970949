import { Box, Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Unstable_Grid2";
import DatePicker from "../../../../Component/FieldTypes/DatePicker/DatePicker";
import RKTextField from "../../../../Component/FieldTypes/RKTextField/RKTextField";
import SearchDropdown from "../../../../Component/FieldTypes/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import MTermFooter from "../../../../Component/Term/MTermFooter/MTermFooter";
import MPremiumCalculationPopup from "../../../../Container/Term/MaxLife/MPremiumCalculationPopup";
import { useAppSelector } from "../../../../redux/hooks";
import {
  formatAmountToCurrency,
  formatNumberToLakhOrCrores,
  isEmpty,
  validateAadharNumber,
} from "../../../../SupportingFiles/HelpingFunction";
import { TRidersBenefitsResponse } from "../../../../types/Term/TRidersBenifits";
import { TTermProposerData } from "../../../../types/TTermSlice";
import "../../../../SCSS/CommonSCSS/MProposalForms.scss";
import AddonQuestion from "../../../../Component/AddonQuestion/AddonQuestion";

const MProposerDetails = ({
  updateMasterState,
  proposerDetails,
  setProposerDetails,
  validateForm,
  loader,
  open,
  setOpen,
  recRiders,
  setRecRiders,
}: {
  updateMasterState: Function;
  proposerDetails: TTermProposerData;
  setProposerDetails: Function;
  validateForm: Function;
  loader: boolean;
  open: boolean;
  setOpen: Function;
  recRiders: TRidersBenefitsResponse;
  setRecRiders: Function;
}) => {
  const { SELECTED_QUOTE_DATA, QUOTE_FIELDS_DATA, RIDERS_BENEFITS, LOADER } =
    useAppSelector((state) => state.Term);
  const dropdownData = useAppSelector((state) => state.Term.DROPDOWN_DATA);
  const { PREMIUM_LOADER } = useAppSelector((state) => state.TermFullJourney);
  return (
    <Box>
      <MPremiumCalculationPopup
        open={open}
        setOpen={setOpen}
        recRiders={recRiders}
        setProposerDetails={setProposerDetails}
      />
      <h5 className="sectionTitle">Proposer Details</h5>
      <Box className="previewDetailBox">
        <Grid container spacing={2} alignItems={"center"}>
          <Grid xs={6}>
            <img
              src={SELECTED_QUOTE_DATA.companyDetails.logo}
              alt="logo"
              height={"50px"}
            />
            <p className="mt-2 product_name">
              {SELECTED_QUOTE_DATA.productDetails.product_name}
            </p>
            <p className="mt-2">
              Payment Mode:{" "}
              <span>
                {QUOTE_FIELDS_DATA.payMode === "12"
                  ? "Monthly"
                  : QUOTE_FIELDS_DATA.payMode === "4"
                  ? "Quaterly"
                  : QUOTE_FIELDS_DATA.payMode === "2"
                  ? "Half-yearly"
                  : QUOTE_FIELDS_DATA.payMode === "1"
                  ? "Yearly"
                  : QUOTE_FIELDS_DATA.payMode === "5"
                  ? "Single Pay"
                  : null}
              </span>
            </p>
          </Grid>
          <Grid xs={6} textAlign="right">
            <p className="premium mb-2">
              Premium (Incl. GST):{" "}
              {/* {PREMIUM_LOADER ? (
                <img
                  src="images/loader.svg"
                  className="loader_img"
                  alt="Brand Logo"
                />
              ) : ( */}
              {LOADER || PREMIUM_LOADER ? (
                <img
                  src="../images/loader.svg"
                  height="10px"
                  alt="Brand Logo"
                />
              ) : (
                <span>
                  {isEmpty(
                    RIDERS_BENEFITS?.riders_benefits_response?.finalPremium
                  )
                    ? formatAmountToCurrency(
                        SELECTED_QUOTE_DATA.premiumDetails.premium
                      )
                    : formatAmountToCurrency(
                        RIDERS_BENEFITS?.riders_benefits_response?.finalPremium
                      )}
                </span>
              )}
            </p>
            <p className=" mb-2">
              Sum Assured:{" "}
              <span>
                {formatNumberToLakhOrCrores(
                  parseInt(QUOTE_FIELDS_DATA.sumAssured)
                )}
              </span>
            </p>

            <p>
              Policy Tenure:{" "}
              <span>{`${SELECTED_QUOTE_DATA.premiumDetails.term} Years`}</span>
            </p>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <h6>
            {proposerDetails.is_proposer_is_insured_member
              ? "Proposer / Insured Details"
              : "Proposer Details"}
          </h6>
        </Grid>
        <Grid xs={12}>
          <RKTextField
            class_name="inputField mb-2"
            title={"Full Name"}
            value={proposerDetails.full_name.value}
            attrName={"full_name"}
            validation_type="NAME"
            value_update={updateMasterState}
            warn_status={proposerDetails.full_name.warning}
            error_message="Enter full name"
          />
        </Grid>
        <Grid xs={6}>
          <DatePicker
            class_name="inputField"
            title={"DOB"}
            value={proposerDetails.dob.value}
            attrName={"dob"}
            value_update={updateMasterState}
            error_message="Select DOB"
            warn_status={proposerDetails.dob.warning}
            min_date={60}
            disabled={true}
          />
        </Grid>
        <Grid xs={6}>
          <SelectDropdown
            class_name="inputField mb-2"
            title="Gender"
            value={proposerDetails.gender.value}
            attrName={"gender"}
            value_update={updateMasterState}
            data={dropdownData.GENDER}
            warn_status={proposerDetails.gender.warning}
            error_message="Select gender"
            disabled={true}
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField mb-2"
            title={"Mobile"}
            value={proposerDetails.mobile.value}
            attrName={"mobile"}
            value_update={updateMasterState}
            warn_status={proposerDetails.mobile.warning}
            error_message="Enter mobile no."
            validation_type="NUMBER"
            max_length={10}
            disabled={true}
          />
        </Grid>
        {/* <Grid xs={6}>
          <RKTextField
            class_name="inputField mb-2"
            title={"Emergency Phone"}
            value={proposerDetails.emergency_number.value}
            attrName={"emergency_number"}
            value_update={updateMasterState}
            warn_status={proposerDetails.emergency_number.warning}
            error_message={
              !isEmpty(proposerDetails?.emergency_number?.value)
                ? "Enter Valid emergency no."
                : "Enter emergency Number"
            }
            validation_type="NUMBER"
            max_length={10}
          />
        </Grid> */}
        <Grid xs={12}>
          <RKTextField
            class_name="inputField mb-2"
            title={"Email"}
            value={proposerDetails.email.value}
            attrName={"email"}
            value_update={updateMasterState}
            warn_status={proposerDetails.email.warning}
            error_message={
              isEmpty(proposerDetails?.email?.value)
                ? "Enter E-mail"
                : "Enter Valid E-mail"
            }
          />
        </Grid>
        {/* <Grid xs={12}>
          <RKTextField
            class_name="inputField mb-2"
            title={"Aadhar"}
            value={proposerDetails.aadhar_number.value}
            attrName={"aadhar_number"}
            value_update={updateMasterState}
            warn_status={proposerDetails.aadhar_number.warning}
            error_message={
              !validateAadharNumber(proposerDetails?.aadhar_number?.value)
                ? "Enter Valid Aadhar no."
                : ""
            }
            validation_type="NUMBER"
            max_length={12}
          />
        </Grid> */}
        <Grid xs={12}>
          <SearchDropdown
            class_name="inputField"
            title="Education"
            value={proposerDetails.education.value}
            attrName={"education"}
            value_update={updateMasterState}
            data={dropdownData.EDUCATION_MASTER}
            warn_status={proposerDetails.education.warning}
            error_message="Select education"
          />
        </Grid>
        <Grid xs={12}>
          <SearchDropdown
            class_name="inputField"
            title="Occupation"
            value={proposerDetails.occupation.value}
            attrName={"occupation"}
            value_update={updateMasterState}
            data={dropdownData.OCCUPATION_DATA}
            warn_status={proposerDetails.occupation.warning}
            error_message="Select occupation"
          />
        </Grid>
        <Grid xs={12}>
          <RKTextField
            disabled={
              isEmpty(proposerDetails.education.value) ||
              isEmpty(proposerDetails.occupation.value)
            }
            class_name="inputField"
            title="Annual Income"
            value={proposerDetails.annual_income.value}
            attrName={"annual_income"}
            value_update={updateMasterState}
            warn_status={proposerDetails.annual_income.warning}
            error_message={
              isEmpty(proposerDetails.annual_income.value)
                ? "Enter annual income"
                : "Enter valid annual income"
            }
            validation_type="NUMBER"
            max_length={8}
          />
        </Grid>
        <Grid xs={12}>
          <SearchDropdown
            class_name="inputField"
            title="Sum Assured"
            value={
              proposerDetails.sum_assured.value !== ""
                ? proposerDetails.sum_assured.value
                : QUOTE_FIELDS_DATA.sumAssured
            }
            attrName={"sum_assured"}
            value_update={updateMasterState}
            data={dropdownData?.MAX_SUM_ASSURED}
            warn_status={proposerDetails?.sum_assured?.warning}
            error_message="Select sum assured"
          />
        </Grid>
        <Grid xs={12}>
          <FormControlLabel
            className="proposalCheckBoxWrapper mr-0"
            control={
              <Checkbox
                disabled={SELECTED_QUOTE_DATA?.productDetails.frequency === "5"}
                value={proposerDetails.diabetic}
                defaultChecked={proposerDetails.diabetic}
                onChange={(e, v) => updateMasterState("diabetic", v)}
              />
            }
            label="Are you suffering from diabetes?"
          />
        </Grid>
        {proposerDetails?.occupation?.value === "01" ? (
          <Grid xs={12}>
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class=""
              question_name="Are you paying for policy?"
              toggle_status={proposerDetails?.housewifePayerStatus}
              value_update={updateMasterState}
              attrName={"housewifePayerStatus"}
            />
          </Grid>
        ) : null}

        {proposerDetails?.occupation?.value === "01" &&
        !proposerDetails?.housewifePayerStatus ? (
          <Grid xs={12}>
            <SelectDropdown
              class_name="inputField"
              title="Relationship"
              value={proposerDetails?.housewifePayerRelation?.value}
              attrName={"housewifePayerRelation"}
              value_update={updateMasterState}
              data={[
                { label: "Spouse", value: "01" },
                { label: "Parents", value: "02" },
              ]}
              warn_status={proposerDetails?.housewifePayerRelation?.warning}
              error_message="Select relationship with payer"
            />
          </Grid>
        ) : null}
      </Grid>

      <MTermFooter forward={validateForm} loader={loader} />
    </Box>
  );
};

export default MProposerDetails;
