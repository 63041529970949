import { Box, Collapse } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import DatePicker from "../../../../Component/FieldTypes/DatePicker/DatePicker";
import RKTextField from "../../../../Component/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import StarMedicalQuestion from "../../../../Component/MedicalQuestion/StarMedicalQuestion";
import MemberRadioButtonNew from "../../../../Component/MemberRadioButton/MemberRadioButtonNew";
import { medical_question_field_data } from "../../../../types/Health/HealthQuotation/TMedicalQuestionv3";
import { TStarMedicalQuestion } from "../../../../types/Health/HealthQuotation/TStarMedicalQuestion";
import CustomCheckbox from "../../../../Component/FieldTypes/CustomCheckbox/CustomCheckbox";
import { useAppSelector } from "../../../../redux/hooks";
import { EProductId } from "../../../../Services/Enum/EHome";
import MFooter from "../../../../Component/MFooter/MFooter";
import MStarMedicalQuestion from "../../../../Component/MMedicalDetailQuestion/MStarMedicalQuestion";
const MMedicalDetailsFHO = ({
  medicalQuestionData,
  updateMasterState,
  validateForm,
  PAGE_STATUS,
}: {
  medicalQuestionData: TStarMedicalQuestion;
  updateMasterState: Function;
  validateForm: Function;
  PAGE_STATUS: boolean;
}) => {
  console.log("medicalQuestionData", medicalQuestionData);

  return (
    <Box>
      <h5 className="sectionTitle">Underwriting Details</h5>
      <>
        {medicalQuestionData &&
          Object.values(medicalQuestionData).map(
            (data, indexMainQuestion: number) => {
              console.log("datas", data);
              return (
                <div key={indexMainQuestion}>
                  <MStarMedicalQuestion
                    key={indexMainQuestion}
                    show_toggle_status={data.main_question.id !== "MQ02"}
                    main_class="medicalQuestion_m borderBottom"
                    icon_class={data?.main_question?.icon_class}
                    question_name={data?.main_question?.title}
                    sub_details={data?.main_question?.description}
                    input_id_first={data?.main_question?.id}
                    input_id_second={data?.main_question?.id}
                    input_name="diagnosed"
                    toggle_button_status={data?.main_question?.status}
                    attrName={["question", data?.main_question?.id]}
                    value_update={updateMasterState}
                    data={data}
                    updateMasterState={updateMasterState}
                    any_question_or_sub_question={
                      <Collapse
                        in={data?.main_question?.status}
                        className="medicalQuestion_m-SubSection mt-3"
                      >
                        <Grid container spacing={3} className="pr-2 mb-3">
                          {data?.main_question?.sub_question && (
                            <Grid xs={12}>
                              {/* <h5>High or low blood pressure</h5> */}
                              {data?.main_question?.sub_question &&
                                Object.values(
                                  data?.main_question?.sub_question
                                )?.map(
                                  (
                                    dataSubQuestion: any,
                                    indexSubQuestion: number
                                  ) => (
                                    <>
                                      <MStarMedicalQuestion
                                        key={indexSubQuestion}
                                        main_class="medicalQuestion_m borderBottom"
                                        icon_class={"tobaco"}
                                        question_name={dataSubQuestion?.title}
                                        sub_details={
                                          <p>{dataSubQuestion?.description}</p>
                                        }
                                        input_id_first={dataSubQuestion?.id}
                                        input_id_second={dataSubQuestion?.id}
                                        input_name="blood pressure"
                                        toggle_button_status={
                                          dataSubQuestion?.status
                                        }
                                        attrName={[
                                          "subQuestion",
                                          data?.main_question?.id,
                                          dataSubQuestion?.id,
                                        ]}
                                        value_update={updateMasterState}
                                        data={data}
                                        dataSubQuestion={dataSubQuestion}
                                        updateMasterState={updateMasterState}
                                        any_question_or_sub_question={
                                          <>
                                            <Collapse
                                              in={data.main_question.status}
                                              className="medicalQuestion_m-SubSection"
                                            >
                                              <Grid
                                                container
                                                columnSpacing={3}
                                                rowSpacing={3}
                                                className="pr-2 py-0"
                                              >
                                                {dataSubQuestion?.member_data &&
                                                  dataSubQuestion?.member_data?.map(
                                                    (
                                                      memberDataValue: any,
                                                      indexMemberSection: Number
                                                    ) =>
                                                      memberDataValue.name !==
                                                      "" ? (
                                                        <Grid xs={12}>
                                                          <Box>
                                                            <MemberRadioButtonNew
                                                              key={`${indexMemberSection}`}
                                                              class_name={
                                                                indexMemberSection ===
                                                                  0 ||
                                                                indexMemberSection ===
                                                                  1
                                                                  ? "adult"
                                                                  : "child"
                                                              }
                                                              checked={
                                                                memberDataValue?.selection_status
                                                              }
                                                              label_name={
                                                                memberDataValue.name
                                                              }
                                                              attrName={[
                                                                "memberData",
                                                                data
                                                                  .main_question
                                                                  .id,
                                                                dataSubQuestion.id,
                                                                memberDataValue.keyName,
                                                              ]}
                                                              value_update={
                                                                updateMasterState
                                                              }
                                                            />
                                                          </Box>
                                                          <Box className="mt-3">
                                                            {memberDataValue?.selection_status &&
                                                              memberDataValue.keyName &&
                                                              memberDataValue.field_data.map(
                                                                (
                                                                  data_sub_question_fields: any,
                                                                  index_sub_question_fields: any
                                                                ) =>
                                                                  memberDataValue.selection_status ===
                                                                  true ? (
                                                                    <>
                                                                      {data_sub_question_fields.field_type ===
                                                                      "CHECKBOX" ? (
                                                                        <CustomCheckbox
                                                                          label={
                                                                            data_sub_question_fields.title
                                                                          }
                                                                          value_update={
                                                                            updateMasterState
                                                                          }
                                                                          value={
                                                                            data_sub_question_fields
                                                                              .value
                                                                              ?.value
                                                                          }
                                                                          defaultChecked={
                                                                            false
                                                                          }
                                                                          attrName={[
                                                                            "main_question",
                                                                            indexMemberSection,
                                                                            index_sub_question_fields,
                                                                            "checkbox",
                                                                          ]}
                                                                        />
                                                                      ) : data_sub_question_fields.field_type ===
                                                                        "TEXTFIELD" ? (
                                                                        <RKTextField
                                                                          class_name="inputField mb-5"
                                                                          title={
                                                                            data_sub_question_fields.title
                                                                          }
                                                                          attrName={[
                                                                            "main_question",
                                                                            indexMemberSection,
                                                                            index_sub_question_fields,
                                                                            "TEXTFIELD",
                                                                          ]}
                                                                          value={
                                                                            data_sub_question_fields
                                                                              ?.value
                                                                              ?.value
                                                                          }
                                                                          warn_status={
                                                                            data_sub_question_fields
                                                                              .value
                                                                              .warning
                                                                          }
                                                                          value_update={
                                                                            updateMasterState
                                                                          }
                                                                        />
                                                                      ) : data_sub_question_fields.field_type ===
                                                                        "DROPDOWN" ? (
                                                                        <SelectDropdown
                                                                          class_name="inputField mb-5"
                                                                          title={
                                                                            data_sub_question_fields.title
                                                                          }
                                                                          value={
                                                                            data_sub_question_fields.value
                                                                          }
                                                                          attrName={[
                                                                            "main_question",
                                                                            indexMemberSection,
                                                                            index_sub_question_fields,
                                                                            "textfield",
                                                                          ]}
                                                                          value_update={
                                                                            updateMasterState
                                                                          }
                                                                          data={
                                                                            data_sub_question_fields.dropdown_data
                                                                          }
                                                                          warn_status={
                                                                            data_sub_question_fields.warning
                                                                          }
                                                                          error_message="Select"
                                                                        />
                                                                      ) : data_sub_question_fields.field_type ===
                                                                        "DATEPICKER" ? (
                                                                        <DatePicker
                                                                          class_name="inputField mb-5"
                                                                          title={
                                                                            data_sub_question_fields.title
                                                                          }
                                                                          value={
                                                                            data_sub_question_fields.value
                                                                          }
                                                                          attrName={[
                                                                            "main_question",
                                                                            indexMemberSection,
                                                                            index_sub_question_fields,
                                                                            "textfield",
                                                                          ]}
                                                                          value_update={
                                                                            updateMasterState
                                                                          }
                                                                          warn_status={
                                                                            data_sub_question_fields.warning
                                                                          }
                                                                        />
                                                                      ) : null}
                                                                    </>
                                                                  ) : null
                                                              )}
                                                          </Box>
                                                        </Grid>
                                                      ) : null
                                                  )}
                                                {/* {data.main_question.sub_question &&
                                              data.main_question.sub_question.map(
                                                (
                                                  data_sub_question: any,
                                                  index_sub_question: any
                                                ) => (
                                                  <StarMedicalQuestion
                                                    key={indexMainQuestion}
                                                    main_class="medicalQuestion borderBottom"
                                                    icon_class={
                                                      data_sub_question.icon_class
                                                    }
                                                    question_name={
                                                      data_sub_question.title
                                                    }
                                                    sub_details={
                                                      data?.main_question
                                                        ?.description
                                                    }
                                                    input_id_first={
                                                      data?.main_question?.id
                                                    }
                                                    input_id_second={
                                                      data?.main_question?.id
                                                    }
                                                    input_name="diagnosed"
                                                    toggle_button_status={
                                                      data_sub_question.status
                                                    }
                                                    attrName={[
                                                      "sub_question",
                                                      index_sub_question,
                                                      "toggle_status",
                                                    ]}
                                                    value_update={
                                                      updateMasterState
                                                    }
                                                    data={data}
                                                    updateMasterState={
                                                      updateMasterState
                                                    }
                                                    any_question_or_sub_question={
                                                      data_sub_question.status ===
                                                      true ? (
                                                        <Collapse
                                                          in={
                                                            data_sub_question.status
                                                          }
                                                          className="medicalQuestion-SubSection"
                                                        >
                                                          <Grid
                                                            container
                                                            spacing={3}
                                                            className="pr-2"
                                                          >
                                                            <Grid container>
                                                              {data_sub_question.member_data &&
                                                                data_sub_question?.member_data?.map(
                                                                  (
                                                                    subMemberDataValue: any,
                                                                    indexSubMemberSection: any
                                                                  ) =>
                                                                    subMemberDataValue.name !==
                                                                    "" ? (
                                                                      <Grid
                                                                        xs={6}
                                                                        lg={4}
                                                                      >
                                                                        <MemberRadioButtonNew
                                                                          key={
                                                                            indexSubMemberSection
                                                                          }
                                                                          class_name={
                                                                            subMemberDataValue.image_class
                                                                          }
                                                                          checked={
                                                                            subMemberDataValue?.selection_status
                                                                          }
                                                                          label_name={
                                                                            subMemberDataValue.name
                                                                          }
                                                                          attrName={[
                                                                            "sub_question",
                                                                            index_sub_question,
                                                                            indexSubMemberSection,
                                                                            "insured_member",
                                                                          ]}
                                                                          value_update={
                                                                            updateMasterState
                                                                          }
                                                                        />

                                                                        {subMemberDataValue.field_data &&
                                                                          subMemberDataValue.field_data.map(
                                                                            (
                                                                              data_sub_question_fields: medical_question_field_data,
                                                                              index_sub_question_fields: Number
                                                                            ) =>
                                                                              subMemberDataValue.selection_status ===
                                                                              true ? (
                                                                                <Box className="mt-3">
                                                                                  {data_sub_question_fields.field_type ===
                                                                                  "textField" ? (
                                                                                    <RKTextField
                                                                                      class_name="inputField mb-5"
                                                                                      title={
                                                                                        data_sub_question_fields.title
                                                                                      }
                                                                                      attrName={[
                                                                                        "sub_question",
                                                                                        index_sub_question,
                                                                                        indexSubMemberSection,
                                                                                        index_sub_question_fields,
                                                                                        "textfield",
                                                                                      ]}
                                                                                      value={
                                                                                        data_sub_question_fields.value
                                                                                      }
                                                                                      warn_status={
                                                                                        data_sub_question_fields.warning
                                                                                      }
                                                                                      value_update={
                                                                                        updateMasterState
                                                                                      }
                                                                                    />
                                                                                  ) : data_sub_question_fields.field_type ===
                                                                                    "dropdown" ? (
                                                                                    <SelectDropdown
                                                                                      class_name="inputField mb-5"
                                                                                      title={
                                                                                        data_sub_question_fields.title
                                                                                      }
                                                                                      value={
                                                                                        data_sub_question_fields.value
                                                                                      }
                                                                                      attrName={[
                                                                                        "sub_question",
                                                                                        index_sub_question,
                                                                                        indexSubMemberSection,
                                                                                        index_sub_question_fields,
                                                                                        "textfield",
                                                                                      ]}
                                                                                      value_update={
                                                                                        updateMasterState
                                                                                      }
                                                                                      data={
                                                                                        data_sub_question_fields.dropdown_data
                                                                                      }
                                                                                      warn_status={
                                                                                        data_sub_question_fields.warning
                                                                                      }
                                                                                      error_message="Select"
                                                                                    />
                                                                                  ) : data_sub_question_fields.field_type ===
                                                                                    "datePicker" ? (
                                                                                    <DatePicker
                                                                                      class_name="inputField mb-5"
                                                                                      title={
                                                                                        data_sub_question_fields.title
                                                                                      }
                                                                                      value={
                                                                                        data_sub_question_fields.value
                                                                                      }
                                                                                      attrName={[
                                                                                        "sub_question",
                                                                                        index_sub_question,
                                                                                        indexSubMemberSection,
                                                                                        index_sub_question_fields,
                                                                                        "textfield",
                                                                                      ]}
                                                                                      value_update={
                                                                                        updateMasterState
                                                                                      }
                                                                                      warn_status={
                                                                                        data_sub_question_fields.warning
                                                                                      }
                                                                                    />
                                                                                  ) : null}
                                                                                </Box>
                                                                              ) : null
                                                                          )}
                                                                      </Grid>
                                                                    ) : null
                                                                )}
                                                            </Grid>
                                                          </Grid>
                                                        </Collapse>
                                                      ) : null
                                                    }
                                                  />
                                                )
                                              )} */}
                                              </Grid>
                                            </Collapse>
                                          </>
                                        }
                                      />
                                    </>
                                  )
                                )}
                            </Grid>
                          )}
                        </Grid>
                      </Collapse>
                    }
                  />
                </div>
              );
            }
          )}
      </>
      <MFooter
        textName={PAGE_STATUS ? "Update" : "Continue"}
        forward={() => validateForm()}
      />
    </Box>
  );
};

export default MMedicalDetailsFHO;
