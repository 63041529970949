import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./MNonTermFooter.scss";
import { useAppSelector } from "../../../redux/hooks";
import { EVButton } from "@evervent_pvt_ltd/ui-kit";

const MNonTermFooter = ({
  disableButton,
  forward = () => {},
  loader,
  value,
}: {
  disableButton?: any;
  forward?: Function;
  loader?: boolean;
  value?: any;
}) => {
  const { PAGE_STATUS } = useAppSelector((state) => state.Car);
  const buttonText = PAGE_STATUS === true ? "Update" : "Continue";
  return (
    <Box className="mProposalFooter">
      <Grid container spacing={3}>
        <Grid xs={12} className="footerInner" alignItems="center">
          <Box>
            <EVButton
              className="primaryBtn"
              size="large"
              fullWidth={true}
              text={buttonText}
              onClick={() => {
                if (!loader) {
                  forward();
                }
              }}
              loader={loader}
              disabled={disableButton}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MNonTermFooter;
