import { Route, Routes } from "react-router-dom";
import { TRAVEL_ROUTE } from "../../../../Path/TravelRoutes";
import TravelProposalContainer from "../../TravelProposalContainer";
import AdultOneDetailsContainer from "../../../../../Container/Travel/common/AdultOneDetailsContainer/AdultOneDetailsContainer";
import InsuredMemberContainer from "../../../../../Container/Travel/common/InsuredMemberContainer/InsuredMemberContainer";
import MemberDetailContainer from "../../../../../Container/Travel/common/AdultTwoDetailsContainer/AdultTwoDetailsContainer";
import ChildOneDetailsContainer from "../../../../../Container/Travel/common/ChildOneDetailsContainer/ChildOneDetailsContainer";
import ChildTwoDetailsContainer from "../../../../../Container/Travel/common/ChildTwoDetailsContainer/ChildTwoDetailsContainer";
import ChildFourDetailsContainer from "../../../../../Container/Travel/common/ChildFourDetailsContainer/ChildFourDetailsContainer";
import AddressDetailsContainer from "../../../../../Container/Travel/common/AddressDetailsContainer/AddressDetailsContainer";
import PreviewDetailsContainer from "../../../../../Container/Travel/common/PreviewDetailsContainer/PreviewDetailsContainer";
import ChildThreeDetailsContainer from "../../../../../Container/Travel/common/ChildThreeDetailsContainer/ChildThreeDetailsContainer";


export const COMMON_ROUTES = () => {
  return (
    <Routes>
      <Route
        path={TRAVEL_ROUTE.PROPOSAL_PAGE}
        element={<TravelProposalContainer />}>
        <Route
          path={TRAVEL_ROUTE.ADULT_ONE_DETAILS}
          element={<AdultOneDetailsContainer />}
        />
        <Route
          path={TRAVEL_ROUTE.INSURED_MEMBER}
          element={<InsuredMemberContainer />}
        />
        <Route
          path={TRAVEL_ROUTE.ADULT_TWO_DETAILS}
          element={<MemberDetailContainer />}
        />
        <Route
          path={TRAVEL_ROUTE.CHILD_ONE_DETAILS}
          element={<ChildOneDetailsContainer />}
        />
        <Route
          path={TRAVEL_ROUTE.CHILD_TWO_DETAILS}
          element={<ChildTwoDetailsContainer />}
        />
        <Route
          path={TRAVEL_ROUTE.CHILD_THREE_DETAILS}
          element={<ChildThreeDetailsContainer />}
        />
        <Route
          path={TRAVEL_ROUTE.CHILD_FOUR_DETAILS}
          element={<ChildFourDetailsContainer />}
        />
        <Route
          path={TRAVEL_ROUTE.ADDRESS_DETAILS}
          element={<AddressDetailsContainer />}
        />
        <Route
          path={TRAVEL_ROUTE.PREVIEW_DETAILS}
          element={<PreviewDetailsContainer />}
        />
      </Route>
    </Routes>
  );
};

