import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import {
  formatAmountToCurrency,
  isEmpty,
} from "../../../SupportingFiles/HelpingFunction";
import { COLORS } from "../../../SupportingFiles/colors";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { useAppSelector } from "../../../redux/hooks";

interface Props {
  addon_amount?: string;
  main_class?: string;
  icon_class?: string;
  question_name: string;
  sub_details?: any;
  value_update?: Function;
  attrName?: string;
  toggle_status?: boolean | undefined;
  value?: boolean;
  show_premium?: boolean;
  addOnData?: any;
  desc?: any;
  policy_term?: string;
  criticalIllnessSelected?: boolean;
  loader?: boolean;
  addonStatus?: any;
  disable?: boolean;
  show_toggle_button?: boolean;
}
const TermRiderQuestion: React.FC<Props> = ({
  addon_amount,
  main_class = "",
  icon_class = "",
  question_name,
  sub_details,
  value_update,
  attrName,
  toggle_status,
  value,
  addOnData,
  show_premium = true,
  desc,
  policy_term,
  criticalIllnessSelected,
  addonStatus,
  loader,
  disable = false,
  show_toggle_button = true,
}) => {
  const { SELECTED_QUOTE_DATA } = useAppSelector((state) => state.Term);
  return (
    <Box className={main_class}>
      <Grid container spacing={2} alignItems="center">
        <Grid xs={9} className="addonQuestionInner">
          <span className={icon_class}></span>
          <div>
            <h6>{question_name}</h6>
            <p>
              {" "}
              <b>{policy_term}</b>{" "}
            </p>
            <p>{desc}</p>
          </div>
        </Grid>
        <Grid xs={3} textAlign="right" className="pt-4">
          {SELECTED_QUOTE_DATA?.companyDetails?.company_code === "L032" ? (
            <>
              {isEmpty(addon_amount) ||
              Number(addon_amount) === 0 ||
              formatAmountToCurrency(parseInt(`${addon_amount}`)) ===
                "₹ NaN" ? null : (
                <h5 style={{ color: COLORS.primary, marginBottom: "4px" }}>
                  {formatAmountToCurrency(Number(`${addon_amount}`))}
                </h5>
              )}
            </>
          ) : (
            <>
              {formatAmountToCurrency(parseInt(`${addon_amount}`)) !==
                "₹ NaN" &&
              toggle_status &&
              !loader ? (
                <h5 style={{ color: COLORS.primary, marginBottom: "4px" }}>
                  {formatAmountToCurrency(parseInt(`${addon_amount}`))}
                </h5>
              ) : null}
            </>
          )}

          {question_name === "Special Exit Value" ||
          question_name === "Cover Continuance Benefit" ||
          question_name === "Insta-Payment on Claim Intimation" ||
          !show_toggle_button ? null : (
            <ToggleSwitch
              disable={disable}
              toggle_status={toggle_status}
              value_update={value_update}
              attrName={attrName}
            />
          )}
        </Grid>
        {SELECTED_QUOTE_DATA?.companyDetails?.company_code === "L003" ? (
          toggle_status ? (
            sub_details
          ) : null
        ) : toggle_status ? (
          <Grid xs={12}>{sub_details}</Grid>
        ) : null}
        {SELECTED_QUOTE_DATA?.companyDetails?.company_code === "L001" &&
        SELECTED_QUOTE_DATA.productDetails.id === 19810 ? (
          <Grid xs={12}>{sub_details}</Grid>
        ) : null}

        {/* */}
      </Grid>
    </Box>
  );
};

export default TermRiderQuestion;
