import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ETermFullJourneyMasterDropdown } from "../../../../../Enum/ETermFullJourneyMasterDropdown";
import ProposerDetails from "../../../../../Page/Desktop/TermFullJourney/HDFC/Proposal/ProposerDetails/ProposerDetails";
import MProposerDetails from "../../../../../Page/Mobile/TermFullJourney/HDFC/Proposal/ProposerDetails/MProposerDetails";
import { PROPOSAL_PAGE_SERVICES } from "../../../../../Services/TermFullJourneyServices/ProposalPageServices";
import { TermFullJourneySlice } from "../../../../../redux/slice/TermFullJourney/TermFullJourneySlice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  FORMAT_YYYY_MM_DD,
  isEmpty,
  validateAadharNumber,
  validateEmail,
  validateFullName,
  validateMobileNumber,
} from "../../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import { TInsuredMemberDetails } from "../../../../../types/TermFullJourney/TInsuredMemberDetails";
import {
  PROPOSAL_PAGE_SECTION_STATUS_API,
  TProposerDetails,
} from "../../../../../types/TermFullJourney/TProposerDetails";
import { TermSlice } from "../../../../../redux/slice/Term/TermSlice";
import { TDropdown } from "../../../../../types/Common/TDropdown";

const ProposerDetailsContainer = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const { RIDERS_BENEFITS } = useAppSelector((state) => state.TermFullJourney);
  const {
    SELECTED_QUOTE_DATA,
    ADD_FORM,
    ADD_FORM_RESPONSE,
    QUOTE_FIELDS_DATA,
    PROPOSER_DATA,
  } = useAppSelector((state) => state.Term);
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const state = useAppSelector((state) => state);
  const [proposerDetails, setProposerDetails] = useState<TProposerDetails>(
    state.TermFullJourney.proposerDetails
  );
  const [insuredMemberDetails, setInsuredMemberDetails] =
    useState<TInsuredMemberDetails>(state.TermFullJourney.insuredDetails);

  useEffect(() => {
    getOccupationList();
    getMaritalStatusList();
    getInsuranceCompanyMaster();
    getEducationMaster();
    getDropdownMaster(ETermFullJourneyMasterDropdown.MY_PROFESSION);
    getDropdownMaster(ETermFullJourneyMasterDropdown.ORG_TYPE);
    getDropdownMaster(ETermFullJourneyMasterDropdown.INDUSTRY_TYPE);
    getDropdownMaster(ETermFullJourneyMasterDropdown.OBJ_OF_BUYING_POLICY);

    setProposerDetails({
      ...state.TermFullJourney.proposerDetails,
      full_name: { value: "", warning: false },
      mobile: { value: ADD_FORM.mobile.value, warning: false },
      email: { value: "", warning: false },
      gender: { value: ADD_FORM.gender.value, warning: false },
      dob: {
        value: PROPOSER_DATA.dob.value,
        warning: false,
      },
    });
  }, []);

  const getOccupationList = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        const occupationData: Array<TDropdown> = response.map((obj: any) => {
          return {
            value: obj.code,
            label: obj.title,
          };
        });

        dispatch(
          TermFullJourneySlice.actions.setOccupationData(occupationData)
        );
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };
    PROPOSAL_PAGE_SERVICES.GET_OCCUPATION_LIST(onSuccess, onError, {
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      product_type_code: "TP",
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
    });
  };

  const getDropdownMaster = (type: ETermFullJourneyMasterDropdown) => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        switch (type) {
          case ETermFullJourneyMasterDropdown.MY_PROFESSION:
            const data_prof: Array<TDropdown> = response.map((obj: any) => {
              return {
                value: obj.code,
                label: obj.title,
              };
            });
            dispatch(TermFullJourneySlice.actions.setMyProfession(data_prof));
            break;
          case ETermFullJourneyMasterDropdown.ORG_TYPE:
            const data_org_type: Array<TDropdown> = response.map((obj: any) => {
              return {
                value: obj.code,
                label: obj.title,
              };
            });
            dispatch(
              TermFullJourneySlice.actions.setICICIOrgType(data_org_type)
            );
            break;
          case ETermFullJourneyMasterDropdown.INDUSTRY_TYPE:
            const data_ind_type: Array<TDropdown> = response.map((obj: any) => {
              return {
                value: obj.code,
                label: obj.title,
              };
            });
            dispatch(
              TermFullJourneySlice.actions.setICICIIndustryType(data_ind_type)
            );
            break;
          case ETermFullJourneyMasterDropdown.OBJ_OF_BUYING_POLICY:
            const data__buying_policy: Array<TDropdown> = response.map(
              (obj: any) => {
                return {
                  value: obj.code,
                  label: obj.title,
                };
              }
            );
            dispatch(
              TermFullJourneySlice.actions.setObjBuyingPolicy(
                data__buying_policy
              )
            );
            break;
          default:
            break;
        }
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };
    let param = {
      product_type_code: "TP",
      product_code: state.Term.SELECTED_QUOTE_DATA.productDetails.product_code,
      buy_online_code:
        state.Term.SELECTED_QUOTE_DATA.productDetails.buy_online_code,
      company_code: "L004",
    };
    PROPOSAL_PAGE_SERVICES.getDropdownDataMaster(onSuccess, onError, param);
  };

  const getMaritalStatusList = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        const maritalStatusData: Array<TDropdown> = response.map((obj: any) => {
          return {
            value: obj.code,
            label: obj.title,
          };
        });

        dispatch(
          TermFullJourneySlice.actions.setMaritalStatusData(maritalStatusData)
        );
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };
    PROPOSAL_PAGE_SERVICES.getMaritalStatusList(onSuccess, onError, {
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      product_type_code: "TP",
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
    });
  };

  const getEducationMaster = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        const data: Array<TDropdown> = response.map((obj: any) => {
          return {
            value: obj.code,
            label: obj.title,
          };
        });

        dispatch(TermFullJourneySlice.actions.setEducationMaster(data));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
    };
    PROPOSAL_PAGE_SERVICES.GET_EDUCATION_MASTER(onSuccess, onError, {
      product_type_code: "TP",
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
    });
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName[0] === "is_proposer_is_insured_member") {
      setProposerDetails((prev) => ({
        ...prev,
        is_proposer_is_insured_member: value,
      }));
    } else if (attrName[0] === "full_name") {
      setProposerDetails((prev) => ({
        ...prev,
        full_name: {
          value: value,
          warning: !validateFullName(value),
        },
      }));
    } else if (attrName[0] === "mobile") {
      setProposerDetails((prev) => ({
        ...prev,
        mobile: {
          value: value,
          warning: !validateMobileNumber(value),
        },
      }));
    } else if (attrName[0] === "emergency_number") {
      setProposerDetails((prev) => ({
        ...prev,
        emergency_number: {
          value: value,
          warning: !validateMobileNumber(value),
        },
      }));
    } else if (attrName[0] === "email") {
      setProposerDetails((prev) => ({
        ...prev,
        email: {
          value: value,
          warning: !validateEmail(value),
        },
      }));
    } else if (attrName[0] === "aadhar_number") {
      setProposerDetails((prev) => ({
        ...prev,
        aadhar_number: {
          value: value,
          warning: !validateAadharNumber(value),
        },
      }));
    } else if (attrName[0] === "marital_status") {
      setProposerDetails((prev) => ({
        ...prev,
        marital_status: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName[0] === "education") {
      setProposerDetails((prev) => ({
        ...prev,
        education: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName[0] === "org_type") {
      setProposerDetails((prev) => ({
        ...prev,
        org_type: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName[0] === "occupation") {
      setProposerDetails((prev) => ({
        ...prev,
        occupation: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName[0] === "org_name") {
      setProposerDetails((prev) => ({
        ...prev,
        org_name: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName[0] === "obj_of_buying_policy") {
      setProposerDetails((prev) => ({
        ...prev,
        obj_of_buying_policy: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName[0] === "annual_incomr") {
      setProposerDetails((prev) => ({
        ...prev,
        annual_income: { value: value, warning: false },
      }));
    } else if (attrName[0] === "occupation") {
      setProposerDetails((prev) => ({
        ...prev,
        occupation: {
          value: value,
          warning: !isEmpty(value),
        },
      }));
      if (value === "BSEM") {
        setProposerDetails((prev) => ({
          ...prev,
          org_name: {
            value: value,
            warning: !isEmpty(value),
          },
        }));
      }
    } else if (attrName === "full_name") {
      setInsuredMemberDetails((prev) => ({
        ...prev,
        full_name: {
          value: value,
          warning: !validateFullName(value),
        },
      }));
    } else if (attrName === "dob") {
      setInsuredMemberDetails((prev) => ({
        ...prev,
        dob: {
          value: value,
          warning: isEmpty(value),
        },
      }));
    } else if (attrName === "gender") {
      setInsuredMemberDetails((prev) => ({
        ...prev,
        gender: {
          value: value,
          warning: isEmpty(value),
        },
      }));
    } else if (attrName === "aadhar_number") {
      setInsuredMemberDetails((prev) => ({
        ...prev,
        aadhar_number: {
          value: value,
          warning: !validateAadharNumber(value),
        },
      }));
    } else if (attrName === "marital_status") {
      setInsuredMemberDetails((prev) => ({
        ...prev,
        marital_status: {
          value: value,
          warning: isEmpty(value),
        },
      }));
    } else if (attrName === "occupation") {
      setInsuredMemberDetails((prev) => ({
        ...prev,
        occupation: {
          value: value,
          warning: isEmpty(value),
        },
      }));
    } else {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: attrName[2] === false ? false : isEmpty(value),
        },
      }));
    }
  };

  const validateForm = () => {
    let proposer_details_data: TProposerDetails = { ...proposerDetails };
    let insured_member_data: TInsuredMemberDetails = {
      ...insuredMemberDetails,
    };

    proposer_details_data = {
      ...proposer_details_data,
      full_name: {
        ...proposer_details_data.full_name,
        warning: !validateFullName(`${proposer_details_data.full_name.value}`),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      dob: {
        ...proposer_details_data.dob,
        warning: isEmpty(proposer_details_data?.dob?.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      gender: {
        ...proposer_details_data.gender,
        warning: isEmpty(proposer_details_data?.gender?.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      mobile: {
        ...proposer_details_data.mobile,
        warning: !validateMobileNumber(proposer_details_data.mobile.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      email: {
        ...proposer_details_data.email,
        warning: !validateEmail(`${proposer_details_data.email.value}`),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      aadhar_number: {
        ...proposer_details_data.aadhar_number,
        warning: !validateAadharNumber(
          `${proposer_details_data.aadhar_number.value}`
        ),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      emergency_number: {
        ...proposer_details_data.emergency_number,
        warning: !validateMobileNumber(
          proposer_details_data.emergency_number.value
        ),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      marital_status: {
        ...proposer_details_data.marital_status,
        warning: isEmpty(proposer_details_data?.marital_status?.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      annual_income: {
        ...proposer_details_data.annual_income,
        warning: isEmpty(proposer_details_data?.annual_income?.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      occupation: {
        ...proposer_details_data.occupation,
        warning: isEmpty(proposer_details_data?.occupation?.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      education: {
        ...proposer_details_data.education,
        warning: isEmpty(proposer_details_data?.education?.value),
      },
    };

    if (!proposer_details_data.is_proposer_is_insured_member) {
      insured_member_data = {
        ...insured_member_data,
        full_name: {
          ...insured_member_data.full_name,
          warning: !validateFullName(`${insured_member_data.full_name.value}`),
        },
      };

      insured_member_data = {
        ...insured_member_data,
        dob: {
          ...insured_member_data.dob,
          warning: isEmpty(insured_member_data?.dob?.value),
        },
      };
      insured_member_data = {
        ...insured_member_data,
        gender: {
          ...insured_member_data.gender,
          warning: isEmpty(insured_member_data?.gender?.value),
        },
      };
      insured_member_data = {
        ...insured_member_data,
        aadhar_number: {
          ...insured_member_data.aadhar_number,
          warning: !validateAadharNumber(
            `${insured_member_data.aadhar_number.value}`
          ),
        },
      };
      insured_member_data = {
        ...insured_member_data,
        marital_status: {
          ...insured_member_data.marital_status,
          warning: isEmpty(insured_member_data?.marital_status?.value),
        },
      };
      insured_member_data = {
        ...insured_member_data,
        occupation: {
          ...insured_member_data.occupation,
          warning: isEmpty(insured_member_data?.occupation?.value),
        },
      };
    } else {
      insured_member_data = {
        education: proposerDetails.education,
        full_name: proposer_details_data.full_name,
        email: proposer_details_data.email,
        dob: proposer_details_data.dob,
        gender: proposer_details_data.gender,
        mobile: proposer_details_data.mobile,
        aadhar_number: proposer_details_data.aadhar_number,
        marital_status: proposer_details_data.marital_status,
        occupation: proposer_details_data.occupation,
        org_desc: proposer_details_data.org_description,
        org_name: proposer_details_data.org_name,
      };
    }
    setProposerDetails(proposer_details_data);
    setInsuredMemberDetails(insured_member_data);

    let hasError = false;

    if (
      proposer_details_data.full_name.warning ||
      proposer_details_data.dob.warning ||
      proposer_details_data.gender.warning ||
      proposer_details_data.mobile.warning ||
      proposer_details_data.email.warning ||
      proposer_details_data.aadhar_number.warning ||
      proposer_details_data.marital_status.warning ||
      proposer_details_data.annual_income.warning ||
      proposer_details_data.occupation.warning ||
      proposer_details_data.education.warning
    ) {
      hasError = true;
    }
    if (proposer_details_data.is_proposer_is_insured_member === false) {
      if (
        insured_member_data.full_name.warning ||
        insured_member_data.email.warning ||
        insured_member_data.dob.warning ||
        insured_member_data.gender.warning ||
        insured_member_data.mobile.warning ||
        insured_member_data.aadhar_number.warning ||
        insured_member_data.marital_status.warning ||
        insured_member_data.occupation.warning
      ) {
        hasError = true;
      }
    }

    if (!hasError) {
      dispatch(
        TermFullJourneySlice.actions.setProposerDetails(proposer_details_data)
      );
      dispatch(
        TermFullJourneySlice.actions.setInsuredDetails(insured_member_data)
      );

      updateProposerDetailsData(insured_member_data, proposer_details_data);
    }
  };

  const updateProposerDetailsData = (
    insuredMemberDetails: TInsuredMemberDetails,
    proposerDetails: TProposerDetails
  ) => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = data.error;
      const message = data.message;

      if (error === false) {
        GET_REDIRECTION_URL();
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    const data = {
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
      section: PROPOSAL_PAGE_SECTION_STATUS_API.PROPOSAL_DETAILS,
      details: {
        proposer_relationship: proposerDetails.is_proposer_is_insured_member
          ? "Yes"
          : "No",
        aadhar_number: proposerDetails.aadhar_number.value,
        insured_aadhar: insuredMemberDetails.aadhar_number.value,
        insured_dob: FORMAT_YYYY_MM_DD(`${insuredMemberDetails.dob.value}`),
        insured_email: insuredMemberDetails.email.value,
        insured_gender: insuredMemberDetails.gender.value,
        insured_marital_status: insuredMemberDetails.marital_status.value.code,
        insured_mobile: insuredMemberDetails.mobile.value,
        insured_name: insuredMemberDetails.full_name.value,
        insured_occupation: insuredMemberDetails.occupation.value.code,
        premium: RIDERS_BENEFITS?.riders_benefits_response?.finalPremium,
        proposer_annual_income: proposerDetails.annual_income.value.code,
        proposer_dob: FORMAT_YYYY_MM_DD(`${proposerDetails.dob.value}`),
        proposer_email: proposerDetails.email.value,
        proposer_emergency_no: proposerDetails.emergency_number.value,
        proposer_gender: proposerDetails.gender.value,
        proposer_marital_status: proposerDetails.marital_status.value.code,
        proposer_mobile: proposerDetails.mobile.value,
        proposer_name: proposerDetails.full_name.value,
        proposer_occupation: proposerDetails.occupation.value.code,
        proposer_occDesc: proposerDetails.occupation_desc.value,
        proposer_education: proposerDetails.education.value.code,
      },
    };
    setLoader(true);
    PROPOSAL_PAGE_SERVICES.updateProposalPageHDFCDetails(
      onSuccess,
      onError,
      data
    );
  };

  const GET_REDIRECTION_URL = async () => {
    const onSuccess = (data: any) => {
      setLoader(false);
      const error = data.error;
      const message = data.message;
      const response = data.response;
      if (!error) {
        window.location.href = response?.redirectionUrl;
      } else {
        toast.error("There are some technical error. Please contact to admin.");
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      toast.error("There are some technical error. Please contact to admin.");
    };

    const data = {
      product_ids: [`${SELECTED_QUOTE_DATA.productDetails.id}`],
      criticalIllnessSelected: RIDERS_BENEFITS.local_value.tf001Status
        ? "Yes"
        : "No",
      accidentalDeathSelected: RIDERS_BENEFITS.local_value.tf005Status
        ? "Yes"
        : "No",
      cancerCovSelected: RIDERS_BENEFITS.local_value.tf004Status ? "Yes" : "No",
      accidentalDisSelected: RIDERS_BENEFITS.local_value.tf002Status
        ? "Yes"
        : "No",
      criticalIllnessValue: RIDERS_BENEFITS.local_value.tf001Status
        ? RIDERS_BENEFITS.local_value.tf001Value
        : "",
      accidentalDisValue: RIDERS_BENEFITS.local_value.tf002Status
        ? RIDERS_BENEFITS.local_value.tf002Value
        : "",
      accidentalDeathValue: RIDERS_BENEFITS.local_value.tf005Status
        ? RIDERS_BENEFITS.local_value.tf005Value
        : "",
      personalAccSelected: RIDERS_BENEFITS.local_value.tf003Status
        ? "Yes"
        : "No",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      adb: RIDERS_BENEFITS.local_value.BNF001Status ? "Yes" : "No",
      rop: RIDERS_BENEFITS.local_value.BNF002Status ? "Yes" : "No",
      wop: RIDERS_BENEFITS.local_value.BNF003Status ? "Yes" : "No",
    };
    setLoader(true);
    PROPOSAL_PAGE_SERVICES.hdfcCreatePaymentLink(onSuccess, onError, data);
  };

  const getInsuranceCompanyMaster = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        let data: TDropdown[] = response.map((obj: any) => {
          return {
            value: obj.code,
            label: obj.title,
          };
        });

        dispatch(TermFullJourneySlice.actions.setInsuranceCompany(data));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    PROPOSAL_PAGE_SERVICES.getInsuranceCompanyMaster(onSuccess, onError, {
      product_type_code: "TP",
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
      company_code: "L004",
    });
  };

  return (
    <>
      {isMobile ? (
        <MProposerDetails
          proposerDetails={proposerDetails}
          setProposerDetails={setProposerDetails}
          insuredMemberDetails={insuredMemberDetails}
          setInsuredMemberDetails={setInsuredMemberDetails}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          loader={loader}
        />
      ) : (
        <ProposerDetails
          proposerDetails={proposerDetails}
          setProposerDetails={setProposerDetails}
          insuredMemberDetails={insuredMemberDetails}
          setInsuredMemberDetails={setInsuredMemberDetails}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          loader={loader}
        />
      )}
    </>
  );
};

export default ProposerDetailsContainer;
