import { Box, Collapse } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { subDays } from "date-fns";
import { useAppSelector } from "../../../../redux/hooks";
import Medicaldeatailsoptimum from "../../../../Component/MedicalQuestion/Medicaldeatailsoptimum";
import DatePicker from "../../../../Component/FieldTypes/DatePicker/DatePicker";
import { calculateAgeInDays } from "../../../../SupportingFiles/HelpingFunction";
import MemberRadioButtonNew from "../../../../Component/MemberRadioButton/MemberRadioButtonNew";
import RKTextField from "../../../../Component/FieldTypes/RKTextField/RKTextField";
import Footer from "../../../../Component/Footer/Footer";

function Medicaldetailsoptimarestore({
  medicalQuestionData,
  updateMasterState,
  validateForm,
  PAGE_STATUS,
  loader,
}: {
  medicalQuestionData: any;
  updateMasterState: Function;
  validateForm: Function;
  PAGE_STATUS: boolean;
  loader?: boolean;
}) {
  const { INSURED_MEMBER_DETAILS } = useAppSelector((state) => state.Health);
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  console.log(INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value, "jjj3");
  return (
    <Box>
      <h5 className="sectionTitle">Underwriting Details</h5>
      <>
        {Object.values(medicalQuestionData).map(
          (data: any, indexMainQuestion: number) => (
            <div key={indexMainQuestion}>
              <Medicaldeatailsoptimum
                key={indexMainQuestion}
                main_class="medicalQuestion borderBottom"
                icon_class={data?.main_question?.icon_class}
                question_name={data?.main_question?.title}
                sub_details={<p>{data?.main_question?.description}</p>}
                input_id_first={data?.main_question?.id}
                input_id_second={data?.main_question?.id}
                input_name="diagnosed"
                toggle_button_status={data?.main_question?.status}
                attrName={["question", data?.main_question?.id]}
                value_update={updateMasterState}
                data={data}
                updateMasterState={updateMasterState}
                select_disease={
                  <Collapse
                    in={data?.main_question?.status}
                    className="medicalQuestion-SubSection"
                  >
                    <Grid container spacing={3} className="pr-2 mb-3">
                      {data?.main_question?.sub_question && (
                        <Grid xs={12}>
                          {/* <h5>High or low blood pressure</h5> */}
                          {data?.main_question?.sub_question &&
                            Object.values(
                              data?.main_question?.sub_question
                            )?.map(
                              (
                                dataSubQuestion: any,
                                indexSubQuestion: number
                              ) => (
                                <>
                                  <Medicaldeatailsoptimum
                                    key={indexSubQuestion}
                                    main_class="medicalQuestion borderBottom"
                                    icon_class={"tobaco"}
                                    question_name={dataSubQuestion?.title}
                                    sub_details={
                                      <p>{dataSubQuestion?.description}</p>
                                    }
                                    input_id_first={dataSubQuestion?.id}
                                    input_id_second={dataSubQuestion?.id}
                                    input_name="blood pressure"
                                    toggle_button_status={
                                      dataSubQuestion?.status
                                    }
                                    attrName={[
                                      "subQuestion",
                                      data?.main_question?.id,
                                      dataSubQuestion?.id,
                                    ]}
                                    value_update={updateMasterState}
                                    data={data}
                                    dataSubQuestion={dataSubQuestion}
                                    updateMasterState={updateMasterState}
                                    select_disease={
                                      <Collapse
                                        in={dataSubQuestion?.status}
                                        className="medicalQuestion-SubSection"
                                      >
                                        <Grid container spacing={2}>
                                          {Object.values(
                                            dataSubQuestion?.member_data
                                          )?.map(
                                            (
                                              memberDataValue: any,
                                              memberIndex: any
                                            ) => (
                                              <Grid xs={6} md={3} lg={4}>
                                                <>
                                                  {memberDataValue.name !==
                                                  "" ? (
                                                    <form>
                                                      {" "}
                                                      <MemberRadioButtonNew
                                                        class_name={
                                                          memberIndex === 0 ||
                                                          memberIndex === 1
                                                            ? "adult"
                                                            : "child"
                                                        }
                                                        checked={
                                                          memberDataValue?.selection_status
                                                        }
                                                        label_name={
                                                          memberDataValue.name
                                                        }
                                                        attrName={[
                                                          "memberData",
                                                          data.main_question.id,
                                                          dataSubQuestion.id,
                                                          memberDataValue.keyName,
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                      />
                                                    </form>
                                                  ) : (
                                                    ""
                                                  )}
                                                </>
                                              </Grid>
                                            )
                                          )}
                                        </Grid>
                                      </Collapse>
                                    }
                                  />
                                </>
                              )
                            )}
                        </Grid>
                      )}
                    </Grid>
                  </Collapse>
                }
              />
            </div>
          )
        )}
      </>
      <Footer
        textName={PAGE_STATUS ? "Update" : "Continue"}
        loader={loader}
        forward={() => validateForm()}
      />
    </Box>
  );
}

export default Medicaldetailsoptimarestore;
