import { Box, Collapse } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Unstable_Grid2";
import { useAppSelector } from "../../../../redux/hooks";
import MedicalQuestion from "../../../../Component/MedicalQuestion/MedicalQuestion";
import SelectDropdown from "../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import RKTextField from "../../../../Component/FieldTypes/RKTextField/RKTextField";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import DatePicker from "../../../../Component/FieldTypes/DatePicker/DatePicker";
import MFooter from "../../../../Component/MFooter/MFooter";
import { subYears } from "date-fns";

function MOwnerDetails({
  ownerFields,
  questionToggleStatus,
  pageStatus,
  updateMasterState,
  handleClick,
  setOwnerFields,
  fieldsUpdateState,
  validateForm,
}: {
  ownerFields: any;
  questionToggleStatus: any;
  pageStatus: number;
  updateMasterState: Function;
  handleClick: any;
  setOwnerFields: Function;
  fieldsUpdateState: Function;
  validateForm: Function;
}) {
  const { DROPDOWN_DATA } = useAppSelector((state) => state.Car);
  return (
    <Box>
      <h5 className="sectionTitle"> Owner Details </h5>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <FormControlLabel
            className="proposalCheckBoxWrapper"
            control={
              <Checkbox
                defaultChecked={ownerFields.validPuc}
                onClick={() => handleClick("validPuc", !ownerFields.validPuc)}
              />
            }
            label="I hold a valid and effective PUC as applicable for the vehicle mentioned herein and undertake to renew the same during the policy period"
          />
        </Grid>
        <Grid className="mb-3" xs={12}>
          <FormControlLabel
            className="proposalCheckBoxWrapper redBox"
            control={
              <Checkbox
                defaultChecked={ownerFields.isPolicyComprehensive}
                onClick={() =>
                  handleClick(
                    "isPolicyComprehensive",
                    !ownerFields.isPolicyComprehensive
                  )
                }
              />
            }
            label="Previous policy is comprehensive."
          />
        </Grid>
        {/* <Grid xs={12} className="mb-3">
          <FormControlLabel
            className="proposalCheckBoxWrapper"
            control={
              <Checkbox
                defaultChecked={ownerFields.vehicleRegistered}
                onClick={() =>
                  handleClick(
                    "vehicleRegistered",
                    !ownerFields.vehicleRegistered
                  )
                }
              />
            }
            label="This vehicle is regestered on company's name."
          />
        </Grid> */}
      </Grid>
      {!ownerFields.vehicleRegistered && (
        <Grid container spacing={0} className="mb-3">
          <Grid xs={12}>
            <MedicalQuestion
              main_class="medicalQuestion borderBottom"
              icon_class="accident"
              question_name="Do you want to include Personal Accident Cover in this policy?"
              sub_details={""}
              input_id_first="mentalone"
              input_id_second="mentaltwo"
              input_name="mental"
              toggle_button_status={ownerFields.includePAOwner}
              attrName="includePAOwner"
              value_update={updateMasterState}
              select_disease={
                <Collapse
                  in={!ownerFields.includePAOwner}
                  className="medicalQuestion-SubSection pl-7"
                >
                  <Grid container spacing={3} className="pr-2 mb-3">
                    <Grid xs={12} lg={6}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Reason for Exemption PA Cover"
                        value={ownerFields.pacover.value}
                        attrName={[
                          "pacover",
                          "value",
                          ownerFields,
                          setOwnerFields,
                        ]}
                        value_update={fieldsUpdateState}
                        data={DROPDOWN_DATA.PA_OWNER_COVER_DATA}
                        warn_status={ownerFields.pacover.warning}
                        error_message="Select Reason for Exemption"
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              }
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={3} className="mt-3">
        <Grid xs={12}>
          <RKTextField
            class_name="inputField"
            title={"Full Name as per RC copy"}
            value={ownerFields.name.value}
            attrName={["name", "value", ownerFields, setOwnerFields]}
            value_update={fieldsUpdateState}
            warn_status={ownerFields.name.warning}
            validation_type="NAME"
            error_message={
              ownerFields.name.value === ""
                ? "Enter Full Name"
                : "Enter Valid Full Name"
            }
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            disabled
            class_name="inputField"
            title={"Mobile"}
            value={ownerFields.mobile.value}
            attrName={["mobile", "value", ownerFields, setOwnerFields]}
            value_update={fieldsUpdateState}
            warn_status={ownerFields.mobile.warning}
            max_length={10}
            validation_type="NUMBER"
            error_message={
              isEmpty(ownerFields.mobile.value)
                ? "Enter Mobile Number"
                : "Enter valid mobile number"
            }
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Email"}
            value={ownerFields.email.value}
            attrName={["email", "value", ownerFields, setOwnerFields]}
            value_update={fieldsUpdateState}
            warn_status={ownerFields.email.warning}
            error_message={
              ownerFields.email.value === ""
                ? "Enter Email"
                : "Enter Valid Email"
            }
          />
        </Grid>
        {ownerFields.vehicleRegistered && (
          <Grid xs>
            <RKTextField
              class_name="inputField"
              title={"GST"}
              value={ownerFields.gst.value}
              attrName={["gst", "value", ownerFields, setOwnerFields]}
              value_update={fieldsUpdateState}
              warn_status={ownerFields.gst.warning}
              validation_type="ALPHANUMERIC"
              max_length={15}
              error_message={
                ownerFields.gst.value === "" ? "Enter GST" : "Enter Valid GST"
              }
            />
          </Grid>
        )}
        {!ownerFields.vehicleRegistered && (
          <Grid xs={6}>
            <DatePicker
              class_name="inputField"
              title={"DOB"}
              value={ownerFields.dob.value}
              attrName={["dob", "value", ownerFields, setOwnerFields]}
              value_update={fieldsUpdateState}
              error_message="Select DOB"
              warn_status={ownerFields.dob.warning}
              date_validation_type="MONTHS"
              min_date={1200}
              max_date={216}
              default_date={subYears(new Date(), 18)}
            />
          </Grid>
        )}{" "}
        {!ownerFields.vehicleRegistered && (
          <Grid xs={6}>
            <SelectDropdown
              class_name="inputField"
              title="Gender"
              value={ownerFields.gender.value}
              attrName={["gender", "value", ownerFields, setOwnerFields]}
              value_update={fieldsUpdateState}
              data={DROPDOWN_DATA.GENDER_DATA}
              warn_status={ownerFields.gender.warning}
              error_message="Select Gender"
            />
          </Grid>
        )}
        {!ownerFields.vehicleRegistered && (
          <Grid xs={12}>
            <SelectDropdown
              class_name="inputField"
              title="Marital Status"
              value={ownerFields.maritalStatus.value}
              attrName={["maritalStatus", "value", ownerFields, setOwnerFields]}
              value_update={fieldsUpdateState}
              data={DROPDOWN_DATA.OWNER_MARTIAL_STATUS_DATA}
              warn_status={ownerFields.maritalStatus.warning}
              error_message="Select Marital Status"
            />
          </Grid>
        )}
      </Grid>
      <MFooter
        value={pageStatus}
        forward={validateForm}
        disableButton={
          !ownerFields.isPolicyComprehensive || !ownerFields.validPuc
        }
      />
    </Box>
  );
}

export default MOwnerDetails;
