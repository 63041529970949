import EditIcon from "@mui/icons-material/Edit";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { HealthSlice } from "../../../../../redux/slice/Health/HealthSlice";
import { HEALTH_ROUTE } from "../../../../../Router/Path/HealthRoutes";
import { ECompanyCode } from "../../../../../Services/Enum/EHome";
import { TStarMedicalQuestion } from "../../../../../types/Health/HealthQuotation/TStarMedicalQuestion";
import { GET_QUERY_PARAMETERS } from "../../../../../SupportingFiles/HelpingFunction";
const MedicalDetailPreviewRedCarPet = () => {
  const {
    MEDICAL_QUESTION_DATA_STAR_RED_CARPET,
    SELECTED_QUOTE_DATA,
    past_health_insurance_value,
    ADD_FORM_RESPONSE,
  } = useAppSelector((state) => state.Health);
  const navigate = useNavigate();
  const [medicalQuestionData, setMedicalQuestionData] =
    React.useState<TStarMedicalQuestion>(MEDICAL_QUESTION_DATA_STAR_RED_CARPET);

  const dispatch = useAppDispatch();
  // Initialize a variable to track if a main question with status true is found
  let found = false;
  let founds = false;

  // Check if any main question's member has selection_status set to true
  medicalQuestionData &&
    Object.values(medicalQuestionData).forEach((main_data: any) => {
      if (
        main_data &&
        main_data.main_question &&
        main_data.main_question.member_data
      ) {
        Object.values(main_data.main_question.member_data).forEach(
          (member_data: any) => {
            if (member_data && member_data.selection_status) {
              founds = true;
              console.log("main_data:", main_data);
              console.log("member_data:", member_data);
              console.log("founds:", founds);
              return; // Break out of the loop
            }
          }
        );
      }
    });

  // Check if any sub-question's member has selection_status set to true and the sub-question's status is true
  medicalQuestionData &&
    Object.values(medicalQuestionData).forEach((main_data: any) => {
      if (
        main_data &&
        main_data.main_question &&
        main_data.main_question.sub_question
      ) {
        Object.values(main_data.main_question.sub_question).forEach(
          (sub_question_data: any) => {
            if (
              sub_question_data &&
              sub_question_data.status &&
              sub_question_data.member_data
            ) {
              Object.values(sub_question_data.member_data).forEach(
                (member_data: any) => {
                  if (member_data && member_data.selection_status) {
                    found = true;
                    console.log("sub_question_data:", sub_question_data);
                    console.log("member_data:", member_data);
                    console.log("founds:", founds);
                    return; // Break out of the loop
                  }
                }
              );
            }
          }
        );
      }
    });

  // for fho ********************
  // Check if any main question's member has selection_status set to true
  MEDICAL_QUESTION_DATA_STAR_RED_CARPET &&
    Object.values(MEDICAL_QUESTION_DATA_STAR_RED_CARPET).forEach(
      (main_data: any) => {
        if (
          main_data &&
          main_data.main_question &&
          main_data.main_question.member_data
        ) {
          Object.values(main_data.main_question.member_data).forEach(
            (member_data: any) => {
              if (member_data && member_data.selection_status) {
                founds = true;
                console.log("main_data:", main_data);
                console.log("member_data:", member_data);
                console.log("founds:", founds);
                return; // Break out of the loop
              }
            }
          );
        }
      }
    );

  // Check if any sub-question's member has selection_status set to true and the sub-question's status is true
  MEDICAL_QUESTION_DATA_STAR_RED_CARPET &&
    Object.values(MEDICAL_QUESTION_DATA_STAR_RED_CARPET).forEach(
      (main_data: any) => {
        if (
          main_data &&
          main_data.main_question &&
          main_data.main_question.sub_question
        ) {
          Object.values(main_data.main_question.sub_question).forEach(
            (sub_question_data: any) => {
              if (
                sub_question_data &&
                sub_question_data.status &&
                sub_question_data.member_data
              ) {
                Object.values(sub_question_data.member_data).forEach(
                  (member_data: any) => {
                    if (member_data && member_data.selection_status) {
                      found = true;
                      console.log("sub_question_data:", sub_question_data);
                      console.log("member_data:", member_data);
                      console.log("founds:", founds);
                      return; // Break out of the loop
                    }
                  }
                );
              }
            }
          );
        }
      }
    );

  return (
    <Box className="previewBox mb-6 mx-3">
      <Grid container columnSpacing={2}>
        <Grid xs={8}>
          {found || founds ? (
            <p className="previewBoxTitle">Insured Member's Medical Details</p>
          ) : (
            <p className="previewBoxTitle">No Medical History Declared</p>
          )}
        </Grid>
        <Grid xs={4} textAlign="right">
          <Link
            onClick={() => {
              dispatch(HealthSlice.actions.CHANGE_PAGE_STATUS(true));
              navigate(
                `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
                  HEALTH_ROUTE.MEDICAL_DETAILS
                }?type=HP&companyCode=${
                  SELECTED_QUOTE_DATA?.CompanyDetails.company_code
                }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
                  SELECTED_QUOTE_DATA?.productDetails.id
                }&user_type=${GET_QUERY_PARAMETERS(
                  "user_type"
                )}&utm_source=${GET_QUERY_PARAMETERS(
                  "utm_source"
                )}&utm_medium=${GET_QUERY_PARAMETERS("utm_medium")}`
              );
            }}
            className="previewBoxEdit"
          >
            <EditIcon /> Edit
          </Link>
        </Grid>
      </Grid>
      {SELECTED_QUOTE_DATA.CompanyDetails.company_code === ECompanyCode.STAR ? (
        <Grid xs={12}>
          {/* {Object.values(MEDICAL_QUESTION_DATA_STAR_FHO) */}
          {MEDICAL_QUESTION_DATA_STAR_RED_CARPET &&
            Object.values(MEDICAL_QUESTION_DATA_STAR_RED_CARPET).map(
              (mainData: any) =>
                mainData.main_question.status ? (
                  <div key={mainData.main_question.id}>
                    {Object.values(
                      mainData.main_question.member_data || {}
                    ).some(
                      (memberData: any) => memberData.selection_status
                    ) && (
                      <>
                        <h6 className="question">
                          {mainData.main_question.title}
                        </h6>
                        <p className="mb-4">
                          {mainData.main_question.description}
                        </p>
                      </>
                    )}
                    <ul className="member_list">
                      {Object.values(
                        mainData.main_question.member_data || {}
                      ).map(
                        (memberData: any) =>
                          memberData.selection_status && (
                            <li key={memberData.id}>
                              <div
                                className={`${memberData.image_class} illness`}
                              ></div>
                              <p className="member_name">{memberData.name}</p>
                              <p className="member_name">
                                {memberData.field_data &&
                                  Object.values(memberData.field_data)
                                    .slice(0, 3)
                                    .map((fieldData: any) => (
                                      <p key={fieldData.id}>
                                        {fieldData.title === true
                                          ? `${fieldData.title}: ${fieldData.value.value}`
                                          : `${fieldData.title}: ${
                                              fieldData?.value?.value === true
                                                ? "Yes"
                                                : "No"
                                            }`}
                                      </p>
                                    ))}
                                {memberData.field_data &&
                                  Object.values(memberData.field_data)
                                    .slice(3, 4)
                                    .map((fieldData: any) =>
                                      fieldData?.value?.value ? (
                                        <p key={fieldData.id}>
                                          {`${fieldData.title}: ${fieldData.value.value}`}
                                        </p>
                                      ) : null
                                    )}
                              </p>
                            </li>
                          )
                      )}
                      {mainData.main_question.status &&
                        mainData.main_question.title ===
                          "Pre-Existing Diseases" && (
                          <div>
                            <h6 className="question">
                              {mainData.main_question.title}
                            </h6>
                            <p className="mb-4">
                              {mainData.main_question.description}
                            </p>
                          </div>
                        )}

                      {mainData.main_question.status &&
                        mainData.main_question.title === "Social Status" &&
                        Object.values(mainData.main_question.sub_question).some(
                          (sub_question_data: any) => sub_question_data.status
                        ) && (
                          <Box>
                            <h6 className="question">
                              {mainData.main_question.title}
                            </h6>
                            <p className="mb-4">
                              {mainData.main_question.description}
                            </p>
                          </Box>
                        )}
                    </ul>
                    {mainData.main_question.sub_question &&
                      Object.values(mainData.main_question.sub_question).map(
                        (sub_question_data: any) =>
                          sub_question_data.status && (
                            <React.Fragment key={sub_question_data.id}>
                              <h6
                                style={{
                                  fontSize: "14px",
                                  fontWeight: 500,
                                  marginBottom: "5px",
                                  width: "70%",
                                }}
                              >
                                {sub_question_data.title}{" "}
                                <span style={{ float: "right" }}> Yes</span>
                              </h6>
                            </React.Fragment>
                          )
                      )}
                  </div>
                ) : null
            )}
        </Grid>
      ) : (
        <Grid container columnSpacing={2}>
          <Grid xs={12}></Grid>
        </Grid>
      )}
      {SELECTED_QUOTE_DATA.CompanyDetails.company_code === ECompanyCode.STAR ? (
        <Grid xs={12}>
          {past_health_insurance_value !== "" && (
            <>
              {" "}
              <div>
                <h6 className="question">Other Health Insurance Policy</h6>
                <p className="mb-4">
                  Do you have any other health insurance covering the persons
                  proposed in the past or current ?
                </p>
              </div>
              <div>
                <p>{past_health_insurance_value}</p>
              </div>
            </>
          )}
        </Grid>
      ) : (
        <Grid container columnSpacing={2}>
          <Grid xs={12}></Grid>
        </Grid>
      )}
    </Box>
  );
};

export default MedicalDetailPreviewRedCarPet;
