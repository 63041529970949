import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subYears } from "date-fns";
import { useNavigate } from "react-router-dom";
import DatePicker from "../../../../../Component/FieldTypes/DatePicker/DatePicker";
import RKTextField from "../../../../../Component/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import Footer from "../../../../../Component/Footer/Footer";
import { useAppSelector } from "../../../../../redux/hooks";
import { TTwoAdultMemberDetails } from "../../../../../types/Travel/TTravelSlice";

const AdultTwoDetails = ({
  fieldsUpdateState,
  validateForm,
  memberFields,
  relationshipList,
}: {
  fieldsUpdateState: Function;
  validateForm: Function;
  memberFields: TTwoAdultMemberDetails;
  relationshipList: any;
}) => {
  const navigate = useNavigate();
  const { PAGE_STATUS, DROPDOWN_DATA } = useAppSelector(
    (state) => state.Travel
  );
  const { GENDER, HEIGHT_FEET, HEIGHT_INCH } = useAppSelector(
    (state) => state.CommonDropdownData
  );
  return (
    <Box>
      <h5 className="sectionTitle">
        {/* 2<sup>nd</sup> Adult Details */}
        Traveller 2 Details
      </h5>
      <Grid container spacing={3}>
        <Grid xs={3}>
          <SelectDropdown
            disabled={PAGE_STATUS}
            class_name="inputField"
            title="Relationship with Proposer"
            value={memberFields.relationship.value}
            attrName={"relationship"}
            value_update={fieldsUpdateState}
            data={[{ value: "Spouse", label: "Spouse" }]}
            warn_status={memberFields.relationship.warning}
            error_message="Select Relationship"
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            validation_type="NAME"
            class_name="inputField"
            title={"Full Name"}
            value={memberFields.name.value}
            attrName={"name"}
            value_update={fieldsUpdateState}
            warn_status={memberFields.name.warning}
            error_message="Enter Full Name"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={3}>
          <DatePicker
            class_name="inputField"
            title={"DOB"}
            value={memberFields.dob.value}
            attrName={"dob"}
            value_update={fieldsUpdateState}
            error_message="Select DOB"
            warn_status={memberFields.dob.warning}
            min_date={99}
            max_date={18}
            date_validation_type="YEARS"
          />
        </Grid>
        <Grid xs={6} md={5} lg={4}>
          <RKTextField
            validation_type="NAME"
            class_name="inputField"
            title={"Passport"}
            value={memberFields.spouse_passport.value}
            attrName={"spouse_passport"}
            value_update={fieldsUpdateState}
            warn_status={memberFields.spouse_passport.warning}
            error_message="Enter Passport"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={6} md={4} lg={3} className="weightField">
          <span className="weightIcon"></span>
          <RKTextField
            validation_type="NUMBER"
            max_length={3}
            class_name="inputField"
            title={"Weight (Kg)"}
            value={memberFields.weight.value}
            attrName={"weight"}
            value_update={fieldsUpdateState}
            warn_status={memberFields.weight.warning}
            error_message="Enter Weight"
          />
        </Grid>
        <Grid xs={6} md={6} lg={5} className="heightField">
          <span className="heightIcon"></span>
          <SelectDropdown
            class_name="inputField mr-2"
            title="Feet"
            value={memberFields.heightFeet.value}
            attrName={"heightFeet"}
            value_update={fieldsUpdateState}
            data={HEIGHT_FEET}
            warn_status={memberFields.heightFeet.warning}
            error_message="Select Height Feet"
          />
          <SelectDropdown
            class_name="inputField"
            title="Inches"
            value={memberFields.heightInches.value}
            attrName={"heightInches"}
            value_update={fieldsUpdateState}
            data={HEIGHT_INCH}
            warn_status={memberFields.heightInches.warning}
            error_message="Select Height Inches"
          />
        </Grid>
      </Grid>

      <Footer
        attrName={"pageStatus"}
        textName={PAGE_STATUS === true ? "Update" : "Continue"}
        value={1}
        forward={() => validateForm()}
      />
    </Box>
  );
};

export default AdultTwoDetails;
