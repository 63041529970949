import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { TNomineeDetails } from "../../../../../../types/TermFullJourney/TNomineeDetails";
import { useAppSelector } from "../../../../../../redux/hooks";
import RKTextField from "../../../../../../Component/FieldTypes/RKTextField/RKTextField";
import DatePicker from "../../../../../../Component/FieldTypes/DatePicker/DatePicker";
import SelectDropdown from "../../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import ToggleSwitch from "../../../../../../Component/Term/ToggleSwitch/ToggleSwitch";
import Footer from "../../../../../../Component/Mobile/TermFullJourney/ProposalForm/Common/Footer/Footer";

const MNomineeDetails = ({
  continueFunction,
  nomineeDetails,
  setNomineeDetails,
  updateMasterState,
}: {
  nomineeDetails: TNomineeDetails;
  setNomineeDetails: Function;
  continueFunction: Function;
  updateMasterState: Function;
}) => {
  const { dropdownData, proposerDetails } = useAppSelector(
    (state) => state.TermFullJourney
  );
  return (
    <Box>
      <h5 className="sectionTitle">Nominee Details</h5>
      {nomineeDetails.is_beneficiary === false ? (
        <>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title={"Full Name"}
                value={nomineeDetails.full_name.value}
                attrName={["full_name", setNomineeDetails, true]}
                value_update={updateMasterState}
                warn_status={nomineeDetails.full_name.warning}
                error_message="Enter full name"
              />
            </Grid>
            <Grid xs={6}>
              <DatePicker
                class_name="inputField"
                title={"DOB"}
                value={nomineeDetails.dob.value}
                attrName={["dob", setNomineeDetails, true]}
                value_update={updateMasterState}
                error_message="Select DOB"
                warn_status={nomineeDetails.dob.warning}
                min_date={60}
              />
            </Grid>
            <Grid xs={6}>
              <SelectDropdown
                class_name="inputField"
                title="Relationship"
                value={nomineeDetails.relationship.value}
                attrName={["relationship", setNomineeDetails, true]}
                value_update={updateMasterState}
                data={dropdownData.nomineeRelationshipData}
                warn_status={nomineeDetails.relationship.warning}
                error_message="Select relationship"
              />
            </Grid>
          </Grid>
        </>
      ) : null}
      {proposerDetails.gender.value === "M" &&
      proposerDetails.marital_status.value.code === "696" ? (
        <Grid container spacing={3}>
          <Grid
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <h6 className="mr-3">
              I would like to take this policy under Married Women's Property
              Act (MWPA)
            </h6>
            <ToggleSwitch
              attrName="is_beneficiary"
              toggle_status={nomineeDetails.is_beneficiary}
              value_update={updateMasterState}
            />
          </Grid>
        </Grid>
      ) : null}
      <Grid container spacing={3}>
        {nomineeDetails.is_beneficiary ? (
          <>
            {nomineeDetails.beneficiary.map((data: any, index: any) => (
              <>
                <Grid xs={7}>
                  <h6>{index + 1}. Beneficiary</h6>
                </Grid>
                {index > 0 ? (
                  <Grid xs={2} textAlign={"right"}>
                    <Link
                      color={"#EA4559"}
                      fontWeight={700}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        updateMasterState(["remove_beneficiary", index]);
                      }}
                    >
                      Remove
                    </Link>
                  </Grid>
                ) : null}
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField"
                    title={"Full Name"}
                    value={data.full_name.value}
                    attrName={["beneficiary", "full_name", index, true]}
                    value_update={updateMasterState}
                    warn_status={data.full_name.warning}
                    error_message="Enter full name"
                  />
                </Grid>
                <Grid xs={6}>
                  <DatePicker
                    class_name="inputField"
                    title={"DOB"}
                    value={data.date_of_birth.value}
                    attrName={["beneficiary", "date_of_birth", index, true]}
                    value_update={updateMasterState}
                    error_message="Select DOB"
                    warn_status={data.date_of_birth.warning}
                    min_date={60}
                  />
                </Grid>
                <Grid xs={6}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Relationship"
                    value={data.relation_to_proposer.value}
                    attrName={[
                      "beneficiary",
                      "relation_to_proposer",
                      index,
                      true,
                    ]}
                    value_update={updateMasterState}
                    data={dropdownData.BENEFICIARY_RELATIONSHIP}
                    warn_status={data.relation_to_proposer.warning}
                    error_message="Select relationship"
                  />
                </Grid>
                <Grid xs={6}>
                  <RKTextField
                    class_name="inputField"
                    title={"Share"}
                    value={data.share.value}
                    attrName={["beneficiary", "share", index, true]}
                    value_update={updateMasterState}
                    warn_status={data.share.warning}
                    error_message="Enter share"
                    max_length={3}
                    validation_type="NUMBER"
                  />
                </Grid>
              </>
            ))}
            {nomineeDetails.beneficiary.length < 6 ? (
              <Grid xs={7} textAlign={"center"}>
                <Button
                  sx={{
                    backgroundColor: "#EA4559",
                    textTransform: "capitalize",
                    ":hover": { backgroundColor: "#EA4559" },
                  }}
                  variant="contained"
                  onClick={() => {
                    updateMasterState(["add_beneficiary"], "");
                  }}
                >
                  Add More
                </Button>
              </Grid>
            ) : null}
            <Grid xs={12}>
              <h6>Trustee Details</h6>
            </Grid>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title={"Name"}
                value={nomineeDetails.trustee_details.name.value}
                attrName={["trustee_details", "name"]}
                value_update={updateMasterState}
                warn_status={nomineeDetails.trustee_details.name.warning}
                error_message="Enter name"
              />
            </Grid>
            <Grid xs={6}>
              <DatePicker
                class_name="inputField"
                title={"DOB"}
                value={nomineeDetails.trustee_details.dob.value}
                attrName={["trustee_details", "dob"]}
                value_update={updateMasterState}
                error_message="Select DOB"
                warn_status={nomineeDetails.trustee_details.dob.warning}
                min_date={60}
              />
            </Grid>
            <Grid xs={6}>
              <SelectDropdown
                class_name="inputField"
                title="Trustee Type"
                value={nomineeDetails.trustee_details.trusteeType.value}
                attrName={["trustee_details", "trusteeType"]}
                value_update={updateMasterState}
                data={dropdownData.TRUSTEE_TYPE}
                warn_status={nomineeDetails.trustee_details.trusteeType.warning}
                error_message="Select trustee type"
              />
            </Grid>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title={"Address"}
                value={nomineeDetails.trustee_details.address.value}
                attrName={["trustee_details", "address"]}
                value_update={updateMasterState}
                warn_status={nomineeDetails.trustee_details.address.warning}
                error_message="Enter address"
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"City"}
                value={nomineeDetails.trustee_details.city.value}
                attrName={["trustee_details", "city"]}
                value_update={updateMasterState}
                warn_status={nomineeDetails.trustee_details.city.warning}
                error_message="Enter city"
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Pincode"}
                value={nomineeDetails.trustee_details.pincode.value}
                attrName={["trustee_details", "pincode"]}
                value_update={updateMasterState}
                warn_status={nomineeDetails.trustee_details.pincode.warning}
                error_message="Enter pincode"
                max_length={6}
                validation_type="NUMBER"
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"State"}
                value={nomineeDetails.trustee_details.state.value}
                attrName={["trustee_details", "state"]}
                value_update={updateMasterState}
                warn_status={nomineeDetails.trustee_details.state.warning}
                error_message="Enter state"
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"PAN"}
                value={nomineeDetails.trustee_details.panNo.value}
                attrName={["trustee_details", "panNo"]}
                value_update={updateMasterState}
                warn_status={nomineeDetails.trustee_details.panNo.warning}
                error_message="Enter PAN"
                max_length={10}
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Mobile"}
                value={nomineeDetails.trustee_details.mobileNo.value}
                attrName={["trustee_details", "mobileNo"]}
                value_update={updateMasterState}
                warn_status={nomineeDetails.trustee_details.mobileNo.warning}
                error_message="Enter mobile no."
                max_length={10}
                validation_type="NUMBER"
              />
            </Grid>
            <Grid xs={10}>
              <RKTextField
                class_name="inputField"
                title={"Email"}
                value={nomineeDetails.trustee_details.email.value}
                attrName={["trustee_details", "email"]}
                value_update={updateMasterState}
                warn_status={nomineeDetails.trustee_details.email.warning}
                error_message="Enter email"
              />
            </Grid>
          </>
        ) : null}
      </Grid>
      <Footer
        continueFunction={() => {
          continueFunction();
        }}
        backText="Back to Quotes"
      />
    </Box>
  );
};

export default MNomineeDetails;
