import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddonDetailsRSA from "../../../Page/Desktop/health-php/RSA/AddonDetails/AddonDetailsRSA";
import MAddonDetailsRSA from "../../../Page/Mobile/health-php/RSA/MAddonDetails/MAddonDetailsRSA";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import { HEALTH_PROPOSAL_SERVICES } from "../../../Services/health-php/HealthPropsalServices";
import { HealthSlice } from "../../../redux/slice/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TRSAAddon } from "../../../types/Health/HealthQuotation/TAddonRSA";
import { THealthQuote } from "../../../types/Health/HealthQuotation/THealthQuote";

const AddonDetailsCOntainerRSA = () => {
  const isMobile = useIsMobile();
  const { Health } = useAppSelector((state) => state);
  const {
    ADD_FORM,
    ADD_FORM_RESPONSE,
    ADDON_DETAILS_RSA,
    SELECTED_QUOTE_DATA,
    PAGE_STATUS,
    QUOTE_LOADER,
  } = useAppSelector((state) => state.Health);
  const { CKYC } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const [addonStatus, setAddonStatus] = useState<TRSAAddon>(ADDON_DETAILS_RSA);
  const [userType, setUserType] = useState<string>("");
  const [utm_medium, setUtm_medium] = useState<string>("");
  const [utm_source, setUtm_source] = useState<string>("");

  useEffect(() => {
    // Access the query parameters directly from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const userTypeFromQuery = searchParams.get("user_type");
    const utm_mediumFromQuery = searchParams.get("utm_medium");
    const utm_sourceFromQuery = searchParams.get("utm_source");
    setUtm_medium(`${utm_mediumFromQuery}`);
    setUtm_source(`${utm_sourceFromQuery}`);
    setUserType(
      atob(`${userTypeFromQuery}`) === "agent" ? btoa("agent") : btoa("user")
    );
  }, []);

  useEffect(() => {
    updatePreiumApi();
    setTimeout(() => {
      FinalPremium();
    }, 1000);
    // dispatch(HealthSlice.actions.ADDON_DETAILS_DATA_ICIC(addonStatus));
    dispatch(HealthSlice.actions.ADDON_DETAILS_DATA_RSA(addonStatus));
  }, [addonStatus]);
  useEffect(() => {
    const sumInsured = ADD_FORM_RESPONSE.sum_insured; // Assuming sum_insured is the correct property to access the sum insured amount

    // Check if sum insured is 15 lacs or above and set AddOn84 as mandatory
    if (sumInsured >= 1500000) {
      setAddonStatus((prev) => ({
        ...prev,
        Claim_Protector: true,
      }));
    }

    // Check if sum insured is 1 crore or above and set AddOn85 and AddOn86 as mandatory
    if (sumInsured >= 10000000) {
      setAddonStatus((prev) => ({
        ...prev,
        Compassionate_Visit: true,
        Nursing_at_Home: true,
      }));
    }
  }, []);

  useEffect(() => {
    if (Number(SELECTED_QUOTE_DATA.sumInsured) >= 10000000) {
      setAddonStatus((prevState) => ({
        ...prevState,
        Compassionate_Visit: true,
        Nursing_at_Home: true,
      }));
    }
  }, []);
  const updateMasterState = (attrName: any, value: any) => {
    setAddonStatus((prevState) => ({
      ...prevState,
      [attrName]: value,
    }));
  };

  const updatePreiumApi = () => {
    const onSuccess = (res: any) => {
      console.log("res", res);
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));

      if (res.results.error === false) {
        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.results.response.premiumDetails.finalPremium,
          },
        };
        console.log("quoteData");

        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            ADDON_DETAILS_RSA: addonStatus,
            QUOTE_LOADER: false,
          })
        );
      }
    };
    const onError = (err: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      console.log("err", err);
    };

    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      addon: {
        hospitalCashbenefitOpted:
          addonStatus?.hospitalCashbenefitOpted === true ? "on" : "off",
        isVoluntaryCoPaymentSelected:
          addonStatus?.isVoluntaryCoPaymentSelected === true ? "Yes" : "No",
        opttopupOpted: addonStatus?.opttopupOpted === true ? "on" : "off",
        internationalTreatmentOpted:
          addonStatus?.internationalTreatmentOpted === true ? "on" : "off",
        supremePlusCoverSelected:
          addonStatus?.supremePlusCoverSelected === true ? "Yes" : "No",
        elitePlusCoverSelected:
          addonStatus?.elitePlusCoverSelected === true ? "Yes" : "No",
        consumableItemsCoverageOpted:
          addonStatus?.consumableItemsCoverageOpted === true ? "Yes" : "No",
        deductibleAmount: addonStatus?.deductibleAmount,
        topUpOptedCount: addonStatus?.topUpOptedCount,
        voluntaryCoPaymentPercentage: addonStatus?.voluntaryCoPaymentPercentage,
      },
    };

    HEALTH_PROPOSAL_SERVICES.getPeriumByAddonRsa(onSuccess, onError, param);
  };

  const FinalPremium = () => {
    const onSuccess = (res: any) => {
      console.log("res", res);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      final_premium: SELECTED_QUOTE_DATA?.premiumDetails?.finalPremium,
    };
    HEALTH_PROPOSAL_SERVICES.GET_FINAL_PREMIUM(onSuccess, onError, param);
  };

  console.log("addonStatus", addonStatus);
  const validate = () => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const cus_type = params.get("cus_type");

    if (Number(SELECTED_QUOTE_DATA.sumInsured) >= 20000000) {
      setAddonStatus((prevState) => ({
        ...prevState,
        Claim_Protector: true,
        Compassionate_Visit: true,
        Nursing_at_Home: true,
      }));
    }
    const onSuccess = (res: any) => {
      if (!res.results.error) {
        setLoader(false);
        if (PAGE_STATUS) {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
          );
        } else {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
          );
        }
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    setLoader(true);
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      section: "add_ons_details",
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
      hospitalCashbenefitOpted:
        addonStatus?.hospitalCashbenefitOpted === true ? 1 : 0,
      isVoluntaryCoPaymentSelected:
        addonStatus?.isVoluntaryCoPaymentSelected === true ? 1 : 0,
      opttopupOpted: addonStatus?.opttopupOpted === true ? 1 : 0,
      internationalTreatmentOpted:
        addonStatus?.internationalTreatmentOpted === true ? 1 : 0,
      supremePlusCoverSelected:
        addonStatus?.supremePlusCoverSelected === true ? 1 : 0,
      elitePlusCoverSelected:
        addonStatus?.elitePlusCoverSelected === true ? 1 : 0,
      consumableItemsCoverageOpted:
        addonStatus?.consumableItemsCoverageOpted === true ? 1 : 0,
      deductibleAmount: addonStatus?.deductibleAmount,
      topUpOptedCount: addonStatus?.topUpOptedCount,
    };

    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  useEffect(() => {
    FinalPremium();
  }, [SELECTED_QUOTE_DATA]);

  useEffect(() => {
    RECORD_CKYC_RESPONSE();
  }, [CKYC.CKYC_DETAILS]);

  const RECORD_CKYC_RESPONSE = () => {
    const onSuccess = (res: any) => {
      console.log("res...", res?.results);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      details: CKYC.CKYC_DETAILS,
      section: "kyc_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
    };
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };
  return (
    <>
      {QUOTE_LOADER ? (
        <Box
          position="fixed"
          height="100%"
          width="100%"
          zIndex="991"
          top="0px"
          left="0px"
        />
      ) : null}
      {isMobile ? (
        <MAddonDetailsRSA
          isLoading={loader}
          addonStatus={addonStatus}
          updateMasterState={updateMasterState}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
        />
      ) : (
        <AddonDetailsRSA
          isLoading={loader}
          addonStatus={addonStatus}
          updateMasterState={updateMasterState}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
        />
      )}
    </>
  );
};

export default AddonDetailsCOntainerRSA;
