import { Route, Routes } from "react-router-dom";
import PaymentConfMotorContainerNode from "../../../Container/Common/PaymentConfMotorContainerNode";
import { EMotorCompCode } from "../../../Enum/EMotorCompCode";
import { isEmpty } from "../../../SupportingFiles/HelpingFunction";
import { CLIENTS } from "../../../URLCollection/Clients";
import { FRONTEND_DOMAIN } from "../../../URLCollection/Domain";
import { useAppSelector } from "../../../redux/hooks";
import { COMMON_ROUTES } from "../../Path/CommonRoutes";
import { ACKO_ROUTES } from "./ProposalRoutes/Acko/AckoRoutes";
import { BAJAJ_ROUTES } from "./ProposalRoutes/Bajaj/BajajRoutes";
import { FutureGenerali_ROUTES } from "./ProposalRoutes/FutureGenerali/FutureGeneraliRoutes";
import { GO_DIGIT_ROUTES } from "./ProposalRoutes/GoDigit/GoDigitRoutes";
import { GO_DIGIT_NODE_ROUTES } from "./ProposalRoutes/GoDigit/go-digit-node-routes";
import { HDFC_ROUTES } from "./ProposalRoutes/HDFC/HDFCRoutes";
import { HDFC_NODE_ROUTES } from "./ProposalRoutes/HDFC/hdfc-node-routes";
import { ICICI_ROUTES } from "./ProposalRoutes/ICICI/ICICIRoutes";
import { ICICI_NODE_ROUTES } from "./ProposalRoutes/ICICI/icici-node-routes";
import { IFFCO_TOKIO_ROUTES } from "./ProposalRoutes/IffcoTokio/IffcoTokioRoutes";
import { KOTAK_ROUTES } from "./ProposalRoutes/Kotak/KotakRoutes";
import { KOTAK_NODE_ROUTES } from "./ProposalRoutes/Kotak/kotak-node-routes";
import { MAGMA_ROUTES } from "./ProposalRoutes/Magma/MagmaRoutes";
import { RSA_ROUTES } from "./ProposalRoutes/RSA/RSARoutes";
import { RSA_NODE_ROUTES } from "./ProposalRoutes/RSA/rsa-node-routes";
import { Reliance_ROUTES } from "./ProposalRoutes/Reliance/RelianceRoutes";
import { TATA_ROUTES } from "./ProposalRoutes/Tata/TataRoutes";
import { TATA_NODE_ROUTES } from "./ProposalRoutes/Tata/tata-node-routes";
import { NATIONAL_ROUTES } from "./ProposalRoutes/national/national-routes";
import { SBI_NODE_ROUTES } from "./ProposalRoutes/sbi/sbi-node-routes";
import { TwInitialValues } from "../../../redux/initialValues/twInitialValues";
import PaymentConfContainer from "../../../Container/Common/PaymentConfContainer";

function TWRoutes() {
  const company_code: string | undefined = useAppSelector(
    (state) =>
      state.TW.SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code
  );

  const hostname = window.location.hostname;
  const isNodeRoutes =
    (isEmpty(FRONTEND_DOMAIN) ||
      [
        CLIENTS.BANKIT,
        CLIENTS.AHALIA,
        CLIENTS.WALNNUT,
        CLIENTS.POLICYPARIVAAR,
        CLIENTS.AMAZE,
      ].includes(hostname)) &&
    process.env.REACT_APP_ENV === "DEV";

  // Define ROUTE_MAP with explicit typing
  const ROUTE_MAP: Record<EMotorCompCode, any> = {
    [EMotorCompCode.HDFC]:
      isNodeRoutes && hostname !== CLIENTS.POLICYPARIVAAR
        ? HDFC_NODE_ROUTES
        : HDFC_ROUTES,
    [EMotorCompCode.ICICI]: isNodeRoutes ? ICICI_NODE_ROUTES : ICICI_ROUTES,
    [EMotorCompCode.GO_DIGIT]: isNodeRoutes
      ? GO_DIGIT_NODE_ROUTES
      : GO_DIGIT_ROUTES,
    [EMotorCompCode.RSA]: isNodeRoutes ? RSA_NODE_ROUTES : RSA_ROUTES,
    [EMotorCompCode.BAJAJ]: BAJAJ_ROUTES,
    [EMotorCompCode.ACKO]: ACKO_ROUTES,
    [EMotorCompCode.TATA]: isNodeRoutes ? TATA_NODE_ROUTES : TATA_ROUTES,
    [EMotorCompCode.Kotak]: isNodeRoutes ? KOTAK_NODE_ROUTES : KOTAK_ROUTES,
    [EMotorCompCode.Reliance]: Reliance_ROUTES,
    [EMotorCompCode.FutureGenerali]: FutureGenerali_ROUTES,
    [EMotorCompCode.IffcoTokio]: IFFCO_TOKIO_ROUTES,
    [EMotorCompCode.Magma]: MAGMA_ROUTES,
    [EMotorCompCode.National]: NATIONAL_ROUTES,
    [EMotorCompCode.SBI]: SBI_NODE_ROUTES,
  };

  // Ensure company_code matches a valid EMotorCompCode
  const selectedRoute =
    company_code &&
    Object.values(EMotorCompCode).includes(company_code as EMotorCompCode)
      ? ROUTE_MAP[company_code as EMotorCompCode]
      : null;

  return (
    <>
      <Routes>
        {isNodeRoutes ? (
          <Route
            path={COMMON_ROUTES.PAYMENT_CONFIRMATION}
            element={<PaymentConfMotorContainerNode />}
          />
        ) : (
          <Route
            path={COMMON_ROUTES.PAYMENT_CONFIRMATION}
            element={<PaymentConfContainer />}
          />
        )}
      </Routes>
      <TwInitialValues />
      {selectedRoute}
    </>
  );
}

export default TWRoutes;
