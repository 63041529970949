import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { Alert, Box, Tooltip } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { extractNumbersFromString } from "../../../../../../SupportingFiles/HelpingFunction";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { proposalSlice } from "../../../../../../redux/slice/HI/Proposal/ProposalSlice";
import { QUOTES_SERVICES } from "../../../../../../Services/HI/QuotesService";
import { toast } from "react-toastify";
import { pageSlice } from "../../../../../../redux/slice/HI/PageSlice/PageSlice";
import { HI_PATH } from "../../../../../../Router/Path/HIPath";
import { PROPOSAL_SERVICES } from "../../../../../../Services/HI/ProposalService";
import { Save_HouseholdBreakup_DTO } from "../../../../../../DTO/HIDTO/ProposalDTO";
import RKTextField from "../../../../../../Component/FieldTypes/RKTextField/RKTextField";
import Footer from "../../../../../../Component/HI/Desktop/ProposalComponents/Footer/Footer";

enum ItemLabels {
  ELECTRONICS = "Electrical & Electronic Items",
  FURNITURE = "Furniture, Fixtures & Fittings",
  OTHERS = "Other Items",
  JewellerySI = "JewellerySI",
  ClothingSI = "ClothingSI",
}

const HouseholdItemsBreakup: React.FC = () => {
  const { PAGE_STATUS } = useAppSelector((state) => state.pageSlice);
  const state = useAppSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [selectedForBreakup, setSelectedForBreakup] = useState<Array<string>>(
    []
  );

  const [electronicsValue, setElectronicsValue] = useState<number>(0);
  const [furnitureValue, setFurnitureValue] = useState<number>(0);
  const [othersValue, setOthersValue] = useState<number>(0);

  const [breakupTotal, setBreakupTotal] = useState<number>(0);
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    const newSelectedForBreakup = [...selectedForBreakup];

    const objToUse: any = state.proposalInformation.householdItemsBreakup;

    Object.keys(objToUse).map((item) => {
      if (objToUse[item]) {
        if (item === "electronics") {
          newSelectedForBreakup.push(ItemLabels.ELECTRONICS);
          setElectronicsValue(objToUse[item]);
        } else if (item === "furniture") {
          newSelectedForBreakup.push(ItemLabels.FURNITURE);
          setFurnitureValue(objToUse[item]);
        } else if (item === "others") {
          newSelectedForBreakup.push(ItemLabels.OTHERS);
          setOthersValue(objToUse[item]);
        }
      }
    });

    setSelectedForBreakup(newSelectedForBreakup);
  }, [state.proposalInformation.householdItemsBreakup]);

  useEffect(() => {
    const newTotal =
      Number(extractNumbersFromString(electronicsValue.toString())) +
      Number(extractNumbersFromString(furnitureValue.toString())) +
      Number(extractNumbersFromString(othersValue.toString()));

    let newShowError =
      Number(newTotal) !==
      Number(
        extractNumbersFromString(state.householdDetails.householdItems.value)
      );

    setShowError(newShowError);
    setBreakupTotal(Number(newTotal));

    dispatch(
      proposalSlice.actions.setHouseholdItemsBreakup({
        electronics: electronicsValue,
        furniture: furnitureValue,
        others: othersValue,
        showError: newShowError,
      })
    );
  }, [electronicsValue, furnitureValue, othersValue]);

  const updateMasterState = (attrName: any, value: number) => {
    attrName(value);
  };

  const handleCheckBreakup = (
    valueState: any,
    checked: boolean,
    label: string
  ) => {
    let newTotal: number = breakupTotal;

    let newSelectedForBreakup = [...selectedForBreakup];

    if (checked) {
      newTotal = newTotal + valueState;
      newSelectedForBreakup.push(label);
    } else {
      newTotal = newTotal - valueState;
      newSelectedForBreakup = newSelectedForBreakup.filter(
        (item) => item !== label
      );
    }

    if (label === ItemLabels.ELECTRONICS) {
      if (!checked) {
        setElectronicsValue(0);
      }
    } else if (label === ItemLabels.FURNITURE) {
      if (!checked) {
        setFurnitureValue(0);
      }
    } else if (label === ItemLabels.OTHERS) {
      if (!checked) {
        setOthersValue(0);
      }
    }

    if (newTotal !== Number(state.householdDetails.householdItems.value)) {
      setShowError(true);
    } else {
      setShowError(false);
    }

    setSelectedForBreakup(newSelectedForBreakup);
    setBreakupTotal(newTotal);
  };

  const updateDigitQuotePremium = () => {
    const onSuccess = (res: any) => {
      dispatch(
        proposalSlice.actions.setPolicyInfo({
          ...state.proposalInformation.policy_information,
          premiumDetails: res.data.quoteLists,
        })
      );
    };

    const onError = (err: any) => {
      console.log(err);
    };

    QUOTES_SERVICES.update_digit_quote_premium(
      onSuccess,
      onError,
      state.quote.quote_id
    );
  };

  const saveProposalInfo = (data: any) => {
    const loadingToast = toast.loading("Updating Proposal...");

    const onSuccess = (res: any) => {
      toast.dismiss(loadingToast);

      if (PAGE_STATUS) {
        dispatch(pageSlice.actions.UPDATE_PAGE_STATUS(false));
        navigate(-1);
      } else {
        navigate(`${HI_PATH.Proposal}/${HI_PATH.VERIFY_CKYC}`);
      }
      updateDigitQuotePremium();
    };
    const onError = (err: any) => {
      toast.dismiss(loadingToast);
    };

    PROPOSAL_SERVICES.save_proposal_info(
      onSuccess,
      onError,
      data,
      state.quote.quote_id
    );
  };

  const validate_form = () => {
    if (!state.proposalInformation.householdItemsBreakup.showError) {
      const dto: Save_HouseholdBreakup_DTO = {
        household_details: {
          electronics: state.proposalInformation.householdItemsBreakup
            .electronics
            ? Number(
                extractNumbersFromString(
                  state.proposalInformation.householdItemsBreakup.electronics.toString()
                )
              )
            : 0,
          furniture: state.proposalInformation.householdItemsBreakup.furniture
            ? Number(
                extractNumbersFromString(
                  state.proposalInformation.householdItemsBreakup.furniture.toString()
                )
              )
            : 0,
          others: state.proposalInformation.householdItemsBreakup.others
            ? Number(
                extractNumbersFromString(
                  state.proposalInformation.householdItemsBreakup.others.toString()
                )
              )
            : 0,
        },
      };
      saveProposalInfo(dto);
    } else {
      toast.error("Please Select Household Items.");
    }
  };

  return (
    <Box>
      <h5 className="sectionTitle">Breakup of Household Items Sum Insured</h5>
      <Grid container spacing={3}>
        <Grid xs={6}>
          <Box className="householdItemDiv" display="flex" alignItems="center">
            <FormControlLabel
              className="proposalCheckBoxWrapper"
              control={
                <Checkbox
                  checked={selectedForBreakup.includes(ItemLabels.ELECTRONICS)}
                  onClick={(e: any) => {
                    if (e.target.checked) {
                      if (
                        breakupTotal ===
                        Number(state.householdDetails.householdItems.value)
                      ) {
                        return;
                      }
                    }

                    handleCheckBreakup(
                      electronicsValue,
                      e.target.checked,
                      ItemLabels.ELECTRONICS
                    );
                  }}
                />
              }
              label={ItemLabels.ELECTRONICS}
            />
            <Tooltip title="Coverage for Loss due to Mechanical or Electrical Breakdown of Nearly any type of Equipment.">
              <ErrorOutlineRoundedIcon
                style={{ height: "18px", width: "18px" }}
                className="tooltip"
              />
            </Tooltip>
          </Box>
        </Grid>
        {selectedForBreakup.includes(ItemLabels.ELECTRONICS) ? (
          <Grid xs={6}>
            <RKTextField
              class_name="inputField inrField"
              validation_type="NUMBER"
              title={"Enter Value for Electrical & Electronic Items"}
              value={electronicsValue}
              attrName={setElectronicsValue}
              value_update={updateMasterState}
              warn_status={false}
              // InputProps={{
              //   startAdornment: <>₹</>,
              // }}
              max_length={15}
            />
          </Grid>
        ) : null}
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={6}>
          <Box className="householdItemDiv" display="flex" alignItems="center">
            <FormControlLabel
              className="proposalCheckBoxWrapper"
              control={
                <Checkbox
                  checked={selectedForBreakup.includes(ItemLabels.FURNITURE)}
                  onClick={(e: any) => {
                    if (e.target.checked) {
                      if (
                        breakupTotal ===
                        Number(state.householdDetails.householdItems.value)
                      ) {
                        return;
                      }
                    }

                    handleCheckBreakup(
                      furnitureValue,
                      e.target.checked,
                      ItemLabels.FURNITURE
                    );
                  }}
                />
              }
              label={ItemLabels.FURNITURE}
            />
            <Tooltip title="Tables, chairs, desks, and the like would be furniture. Whereas electrical fittings would be fixtures.">
              <ErrorOutlineRoundedIcon
                style={{ height: "18px", width: "18px" }}
                className="tooltip"
              />
            </Tooltip>
          </Box>
        </Grid>
        {selectedForBreakup.includes(ItemLabels.FURNITURE) ? (
          <Grid xs={6}>
            <RKTextField
              class_name="inputField inrField"
              validation_type="NUMBER"
              title={"Enter Value for Furniture, Fixtures & Fittings"}
              value={furnitureValue}
              attrName={setFurnitureValue}
              value_update={updateMasterState}
              warn_status={false}
              // InputProps={{
              //   startAdornment: <>₹</>,
              // }}
              max_length={15}
            />
          </Grid>
        ) : null}
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={6}>
          <Box className="householdItemDiv" display="flex" alignItems="center">
            <FormControlLabel
              className="proposalCheckBoxWrapper"
              control={
                <Checkbox
                  checked={selectedForBreakup.includes(ItemLabels.OTHERS)}
                  onClick={(e: any) => {
                    if (e.target.checked) {
                      if (
                        breakupTotal ===
                        Number(state.householdDetails.householdItems.value)
                      ) {
                        return;
                      }
                    }
                    handleCheckBreakup(
                      othersValue,
                      e.target.checked,
                      ItemLabels.OTHERS
                    );
                  }}
                />
              }
              label={ItemLabels.OTHERS}
            />
            <Tooltip title="If you are unable to categorize a certain amount under a specific head (such as fixture, furniture, stocks, etc.), that value goes here.">
              <ErrorOutlineRoundedIcon
                style={{ height: "18px", width: "18px" }}
                className="tooltip"
              />
            </Tooltip>
          </Box>
        </Grid>
        {selectedForBreakup.includes(ItemLabels.OTHERS) ? (
          <Grid xs={6}>
            <RKTextField
              class_name="inputField inrField"
              validation_type="NUMBER"
              title={"Enter Value for Other Items"}
              value={othersValue}
              attrName={setOthersValue}
              value_update={updateMasterState}
              warn_status={false}
              // InputProps={{
              //   startAdornment: <>₹</>,
              // }}
              max_length={15}
            />
          </Grid>
        ) : null}
      </Grid>

      {showError && selectedForBreakup.length > 0 && (
        <Alert style={{ marginTop: 20 }} severity="error">
          The Sum of{" "}
          {selectedForBreakup.includes(ItemLabels.FURNITURE) &&
            "Furniture & Fixtures,"}{" "}
          {selectedForBreakup.includes(ItemLabels.ELECTRONICS) &&
            "Electronics,"}{" "}
          {selectedForBreakup.includes(ItemLabels.OTHERS) && "Other Property,"}{" "}
          should be equal to Household Items Value{" "}
          <strong>₹ {state.householdDetails.householdItems.value}</strong>
        </Alert>
      )}

      <Footer continue_function={validate_form} />
    </Box>
  );
};

export default HouseholdItemsBreakup;
