import { Route, Routes } from "react-router-dom";
import HDFCBankDetailsContainer from "../../../../../Container/BankDetails/HDFC/HDFCBankDetailsContainer";
import HDFCCKYCContainer from "../../../../../Container/CKYC/HDFC/HDFCCKYCContainer";
import AddonDetailsContainerOptimaRestore from "../../../../../Container/health-php/HDFC/AddonDetailsContainerOptimaRestore";
import AddonDetailsContainerOptimaSecure from "../../../../../Container/health-php/HDFC/AddonDetailsContainerOptimaSecure";
import MedicalDetailsContainerOptimarestore from "../../../../../Container/health-php/HDFC/MedicalDetailsContainerOptimarestore";
import MedicalDetailsContainerOptimaSecure from "../../../../../Container/health-php/HDFC/MedicalDetailsContainerOptimaSecure";
import { useAppSelector } from "../../../../../redux/hooks";
import { EProductId } from "../../../../../Services/Enum/EHome";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import { HEALTH_ROUTE } from "../../../../Path/HealthRoutes";
import HealthProposalContainer from "../../HealthProposalContainer";

export const HDFC_ROUTES = () => {
  const { SELECTED_QUOTE_DATA } = useAppSelector((state) => state.Health);
  return (
    <Routes>
      <Route
        path={HEALTH_ROUTE.PROPOSAL_PAGE}
        element={<HealthProposalContainer />}
      >
        <Route path={CKYC_ROUTES.CKYC} element={<HDFCCKYCContainer />} />
        {SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_RESTORE ||
          SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_RESTORE_FAMILY_PLAN ? (
          <Route
            path={HEALTH_ROUTE.ADDON_DETAILS}
            element={<AddonDetailsContainerOptimaRestore />}
          />
        ) : (
          <></>
        )}
        {SELECTED_QUOTE_DATA.productDetails.id === EProductId.HDFC_HEALTH ||
          SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_HEALTH_Global ||
          SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_HEALTH_Plan ||
          SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_SUPER_SECURE_Plan ? (
          <Route
            path={HEALTH_ROUTE.ADDON_DETAILS}
            element={<AddonDetailsContainerOptimaSecure />}
          />
        ) : (
          <></>
        )}
        {SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_RESTORE ||
          SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_RESTORE_FAMILY_PLAN ? (
          <Route
            path={HEALTH_ROUTE.MEDICAL_DETAILS}
            element={<MedicalDetailsContainerOptimarestore />}
          />
        ) : (
          <></>
        )}
        {SELECTED_QUOTE_DATA.productDetails.id === EProductId.HDFC_HEALTH ||
          SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_HEALTH_Global ||
          SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_HEALTH_Plan ||
          SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_SUPER_SECURE_Plan ? (
          <Route
            path={HEALTH_ROUTE.MEDICAL_DETAILS}
            element={<MedicalDetailsContainerOptimaSecure />}
          />
        ) : (
          <></>
        )}
        {SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_RESTORE ||
          SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_RESTORE_FAMILY_PLAN ||
          SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_HEALTH_Plan ? (
          <Route
            path={HEALTH_ROUTE.BANK_DETAILS}
            element={<HDFCBankDetailsContainer />}
          />
        ) : (
          <></>
        )}
      </Route>
    </Routes>
  );
};
