import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { TERM_SERVICES } from "../../../Services/Term/TermServices";
import { TermSlice } from "../../../redux/slice/Term/TermSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  isEmpty,
  validateEmail,
  validateFullName,
  validateMobileNumber,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../types/Common/TDropdown";
import { TTermProposerData } from "../../../types/TTermSlice";
import { TRidersBenefitsResponse } from "../../../types/Term/TRidersBenifits";
import { toast } from "react-toastify";
import { PREMIUM_CONFIRMATION_SERVICES } from "../../../Services/PremiumConf/PremiumConfServices";
import ProposerDetails from "../../../Page/Desktop/Term/ShortPay/Bajaj/ProposerDetails";

const BasicDetailsContainer = () => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const {
    PROPOSER_DATA,
    ADD_FORM_RESPONSE,
    RIDERS_BENEFITS,
    SELECTED_QUOTE_DATA,
    QUOTE_FIELDS_DATA,
    LOADER,
  } = useAppSelector((state) => state.Term);
  const { Term } = useAppSelector((state) => state);
  const [loader, setLoader] = useState<boolean>(false);

  const [userType, setUserType] = useState<string>("");
  const [utm_medium, setUtm_medium] = useState<string>("");
  const [utm_source, setUtm_source] = useState<string>("");

  useEffect(() => {
    // Access the query parameters directly from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const userTypeFromQuery = searchParams.get("user_type");
    const utm_mediumFromQuery = searchParams.get("utm_medium");
    const utm_sourceFromQuery = searchParams.get("utm_source");
    setUtm_medium(`${utm_mediumFromQuery}`);
    setUtm_source(`${utm_sourceFromQuery}`);
    setUserType(
      atob(`${userTypeFromQuery}`) === "agent" ? btoa("agent") : btoa("user")
    );
  }, []);

  const [proposerFields, setProposerFields] =
    useState<TTermProposerData>(PROPOSER_DATA);

  const [isProposalDetailFormValid, setIsProposalDetailFormValid] =
    useState(false);

  useEffect(() => {
    UPDATE_RIDERS_STATUS();
  }, []);

  // Effect that runs when isProposalDetailFormValid changes
  useEffect(() => {
    if (isProposalDetailFormValid) {
      UPDATE_PROPOSER_DETAILS(); // Call the function when the state is true
    }
  }, [isProposalDetailFormValid]);

  const GET_RIDERS_PREMIUM = () => {
    dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(true));
    const onSuccess = (data: any) => {
      const results = data.response;
      const error = data.error;
      const message = data.message;
      if (!error) {
        // dispatch(
        //   TermSlice.actions.BULK_UPDATE({
        //     ...Term,
        //     LOADER: false,
        //     RIDERS_BENEFITS: {
        //       ...Term.RIDERS_BENEFITS,
        //       riders_benefits_response: response,
        //     },
        //     SELECTED_QUOTE_DATA: {
        //       ...Term.SELECTED_QUOTE_DATA,
        //       premiumDetails: {
        //         ...Term.SELECTED_QUOTE_DATA.premiumDetails,
        //         pay_term: response.pay_term,
        //         term: response.term,
        //       },
        //     },
        //   })
        // );
        // if (isProposalDetailFormValid) {
        //   return setIsProposalDetailFormValid(false);
        // }

        const redirectionURL = results[0]?.redirectionURL;
        window.location.href = redirectionURL;
      } else {
        dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(false));
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(false));
    };

    const data = {
      product_ids: [SELECTED_QUOTE_DATA.productDetails?.id],
      quote_no: ADD_FORM_RESPONSE?.quote_no,
    };
    TERM_SERVICES.GET_REDIRECTION_URL(onSuccess, onError, data);
  };

  const fieldsUpdateState = (attr: any, value: any) => {
    setProposerFields({
      ...proposerFields,
      [attr[0]]: {
        value: value,
        warning:
          attr[0] === "full_name"
            ? !validateFullName(value)
            : attr[0] === "mobile"
            ? !validateMobileNumber(value)
            : attr[0] === "email"
            ? !validateEmail(value)
            : // : attr[0] === "emergency_number"
              // ? !validateMobileNumber(value)
              isEmpty(value),
      },
    });
  };

  const validateForm = () => {
    let data = { ...proposerFields };
    data = {
      ...data,
      full_name: {
        ...data.full_name,
        warning: !validateFullName(data.full_name.value),
      },
      dob: {
        ...data.dob,
        warning: isEmpty(data.dob.value),
      },
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
      // emergency_number: {
      //   ...data.emergency_number,
      //   warning: !validateMobileNumber(data.emergency_number.value),
      // },
      email: {
        ...data.email,
        warning: !validateEmail(data.email.value),
      },
      gender: {
        ...data.gender,
        warning: isEmpty(data.gender.value),
      },
      occupation: {
        ...data.occupation,
        warning: isEmpty(data.occupation.value),
      },
      qualification: {
        ...data.qualification,
        warning: isEmpty(data.qualification.value),
      },
    };
    setProposerFields({ ...data });

    let hasError = false;

    if (
      data.full_name.warning ||
      data.dob.warning ||
      data.mobile.warning ||
      // data.emergency_number.warning ||
      data.email.warning ||
      data.gender.warning ||
      data.occupation.warning ||
      data.qualification.warning
    ) {
      hasError = true;
    }

    if (!hasError) {
      dispatch(
        TermSlice.actions.BULK_UPDATE({ ...Term, PROPOSER_DATA: { ...data } })
      );
      UPDATE_PROPOSER_DETAILS();
    }
  };

  function convertDate(inputDate: string) {
    const [year, month, day] = inputDate.split("-");
    return `${day}-${month}-${year}`;
  }

  const UPDATE_PROPOSER_DETAILS = () => {
    let params = {
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
      section: "proposer_details",
      details: {
        proposer_name: proposerFields?.full_name?.value,
        proposer_mobile: proposerFields?.mobile?.value,
        // proposer_emergency_no: proposerFields?.emergency_number?.value,
        proposer_email: proposerFields?.email?.value,
        proposer_gender: proposerFields?.gender?.value,
        proposer_dob: convertDate(proposerFields?.dob?.value),
        proposer_occupation: proposerFields.occupation.value,
        proposer_education: proposerFields.qualification.value,
        address_pincode: proposerFields.pincode.value,
        // insured_pincode: ADD_FORM.pincode.value,
      },
    };

    const onSuccess = (res: any) => {
      // GET_REDIRECTION_URL();
      GET_RIDERS_PREMIUM();
      console.log("res", res);
      setLoader(false);
    };
    const onError = () => {
      setLoader(false);
    };
    setLoader(true);
    TERM_SERVICES.UPDATE_PROPOSER_DETAILS(onSuccess, onError, params);
  };

  const GET_REDIRECTION_URL = async () => {
    setLoader(true);
    const onSuccess = (res: any) => {
      setLoader(false);
      const results = res;
      const error = results.error;
      if (!error) {
        const response = results.response;
        setLoader(false);
        console.log(response);
        const application_url = response[0].application_url;

        if (application_url) {
          window.location.href = application_url;
        } else {
          toast.error("Redirection Url Missing!");
        }
      }
    };
    const onError = () => {
      setLoader(false);
    };
    const params = {
      product_ids: [SELECTED_QUOTE_DATA.productDetails.id],
      quote_no: ADD_FORM_RESPONSE.quote_no,
      criticalIllnessSelected:
        QUOTE_FIELDS_DATA.payMode === "5"
          ? "No"
          : RIDERS_BENEFITS.local_value.tf001Status
          ? "Yes"
          : "No",
      accidentalDeathSelected: RIDERS_BENEFITS.local_value.tf003Status
        ? "Yes"
        : "No",
      criticalIllnessValue:
        QUOTE_FIELDS_DATA.payMode === "5"
          ? ""
          : RIDERS_BENEFITS.local_value.tf001Value,
      accidentalDeathValue: RIDERS_BENEFITS.local_value.tf003Value,

      occupation: proposerFields.occupation.value,
    };
    TERM_SERVICES.GET_REDIRECTION_URL(onSuccess, onError, params);
  };

  const UPDATE_RIDERS_STATUS = () => {
    dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(true));
    const onSuccess = (data: any) => {
      const results = data.response;
      const error = data.error;
      const message = data.message;
      if (!error) {
        const response = data.response;
        setLoader(false);
        // const application_url = response.redirectionUrl;
        // if (!isEmpty(application_url)) {
        //   window.location.href = application_url;
        // }
        dispatch(
          TermSlice.actions.BULK_UPDATE({
            ...Term,
            LOADER: false,
            RIDERS_BENEFITS: {
              ...Term.RIDERS_BENEFITS,
              riders_benefits_response: response,
            },
          })
        );
        PROPOSER_OCCUPATION_DETAILS();
        PROPOSER_QUALIFICATION_DETAILS();
      } else {
        dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(false));
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(false));
    };

    const data = {
      product_ids: [SELECTED_QUOTE_DATA.productDetails?.id],
      quote_no: ADD_FORM_RESPONSE?.quote_no,
    };
    TERM_SERVICES.UPDATE_RIDERS_STATUS(onSuccess, onError, data);
  };

  const PROPOSER_OCCUPATION_DETAILS = () => {
    let params = {
      product_type_code: "TP",
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
    };

    const onSuccess = (res: any) => {
      console.log("res", res);

      const results = res.results;
      const error = results.error;
      const response = results.response;

      let data: TDropdown[] = response.map((item: any) => ({
        value: item.code,
        label: item.title,
      }));

      dispatch(
        TermSlice.actions.SET_DROPDOWN_DATA({
          key: "OCCUPATION_DATA",
          value: data,
        })
      );
    };

    const onError = () => {};

    TERM_SERVICES.PROPOSER_OCCUPATION_DETAILS(onSuccess, onError, params);
  };

  const PROPOSER_QUALIFICATION_DETAILS = () => {
    let params = {
      product_type_code: "TP",
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      buy_online_code: SELECTED_QUOTE_DATA?.productDetails?.buy_online_code,
    };
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
      const response = results.response;

      let data: TDropdown[] = response.map((item: any) => ({
        value: item.code,
        label: item.title,
      }));

      dispatch(
        TermSlice.actions.SET_DROPDOWN_DATA({
          key: "QUALIFICATION_DATA",
          value: data,
        })
      );
    };
    const onError = () => {};
    TERM_SERVICES.PROPOSER_QUALIFICATION_DETAILS(onSuccess, onError, params);
  };

  const HANDLE_PDF_DOWNLOAD = async () => {
    const loader = toast.loading("Please wait...");
    const onSuccess = (res: any) => {
      toast.dismiss(loader);
      window.open(res?.URL, "_blank");
    };

    const onError = (err: any) => {
      toast.dismiss(loader);
      console.error("Error in pickupWhereYouLeft:", err);
    };

    let param = {
      type: "Get",
      targetDir: `${RIDERS_BENEFITS.riders_benefits_response.policyPdf}`,
    };

    PREMIUM_CONFIRMATION_SERVICES.DOWNLOAD_POLICY(onSuccess, onError, param);
  };

  return (
    <>
      {LOADER ? (
        <Box
          position={"fixed"}
          height={"100%"}
          width={"100%"}
          top="0"
          left="0"
          zIndex={911}
        />
      ) : null}
      {isMobile ? null : (
        <ProposerDetails
          proposerFields={proposerFields}
          fieldsUpdateState={fieldsUpdateState}
          validateForm={validateForm}
          loader={loader}
        />
      )}
    </>
  );
};

export default BasicDetailsContainer;
