import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import AddonQuestion from "../../../../../Component/AddonQuestion/AddonQuestion";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import Loader from "../../../../../Component/LoaderblackBg/Loader";
import MFooter from "../../../../../Component/MFooter/MFooter";
import { HEALTH_ROUTE } from "../../../../../Router/Path/HealthRoutes";
import { useAppSelector } from "../../../../../redux/hooks";
import { COLORS } from "../../../../../SupportingFiles/colors";
import { TCareAddon } from "../../../../../types/Health/TCareAddon";

function MAddonDetailsOptimaRestore({
  addonStatus,
  updateMasterState,
  validate,
  PAGE_STATUS,
  isLoading,
}: {
  addonStatus: TCareAddon;
  PAGE_STATUS: boolean;
  updateMasterState: Function;
  validate: Function;
  isLoading: any;
}) {
  const navigate = useNavigate();
  const { QUOTE_LOADER } = useAppSelector((state) => state.Health);

  return (
    <>
      {QUOTE_LOADER ? (
        <Box
          sx={{
            backgroundColor: "rgba(20,20,20, 0.9)",
            height: "100%",
            width: "100%",
            position: "fixed",
            left: 0,
            top: "0px",
            zIndex: "991",
            flexDirection: "column",
            gap: "16px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <Loader size="small" /> */}
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader size="large" />
            <h5
              style={{
                color: COLORS.white,
                textAlign: "center",
                marginTop: "4px",
              }}
            >
              Please wait...
            </h5>
          </Box>
        </Box>
      ) : (
        <Box>
          <h5 className="sectionTitle">Addon Details</h5>
          <Grid xs={12}>
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="Daily Hospital Cash Rider"
              toggle_status={addonStatus.recharge}
              value_update={updateMasterState}
              attrName={"recharge"}
              sub_details={
                <Box maxWidth={"200px"}>
                  <SelectDropdown
                    title="Sum Assured"
                    value={addonStatus.hdcSa.value}
                    value_update={updateMasterState}
                    attrName={"hdcSa"}
                    data={[
                      { value: "1000", label: "1000" },
                      { value: "2000", label: "2000" },
                      { value: "3000", label: "3000" },
                    ]}
                  />
                </Box>
              }
            />
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="Protector Rider"
              toggle_status={addonStatus.critical_illness}
              value_update={updateMasterState}
              attrName={"critical_illness"}
            />
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="Ipa RiderCheck"
              toggle_status={addonStatus.ipaRiderCheck}
              value_update={updateMasterState}
              attrName={"ipaRiderCheck"}
              // sub_details={
              //   <Box maxWidth={"200px"}>
              //     <SelectDropdown
              //       title="Sum Assured"
              //       value={addonStatus.ipaSa.value}
              //       value_update={updateMasterState}
              //       attrName={"ipaSa"}
              //       data={[
              //         { value: "1000", label: "1000" },
              //         { value: "2000", label: "2000" },
              //         { value: "3000", label: "3000" },
              //       ]}
              //     />
              //   </Box>
              // }
            />
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="unlimited RiderCheck"
              toggle_status={addonStatus.unlimitedRiderCheck}
              value_update={updateMasterState}
              attrName={"unlimitedRiderCheck"}
            />
          </Grid>
          <MFooter
            textName={PAGE_STATUS === true ? "Update" : "Continue"}
            attrName={"pageStatus"}
            forward={() => {
              validate();
            }}
            value={0}
            backward={() => {
              navigate(
                `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.QOUTE_PAGE}`
              );
            }}
          />
        </Box>
      )}
    </>
  );
}

export default MAddonDetailsOptimaRestore;
