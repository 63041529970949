import { ECompanyCode, EProductId } from "../../../Services/Enum/EHome";
import { useAppSelector } from "../../../redux/hooks";
import { CARE_ROUTES } from "./TravelProposalRoutes/CARE/CARE_ROUTES";
import { COMMON_ROUTES } from "./TravelProposalRoutes/Common/COMMON_ROUTES";
import { ICICI_ROUTES } from "./TravelProposalRoutes/ICICI/ICICI_ROUTES";

export default function TravelRoutes() {
  const { SELECTED_QUOTE_DATA } = useAppSelector((state) => state.Travel);
  const company_code = SELECTED_QUOTE_DATA.companyCode;

  console.log(company_code, "company_code");

  return (
    <>
      <COMMON_ROUTES />
      {(() => {
        if (company_code) {
          switch (company_code) {
            case ECompanyCode.CARE:
              return <CARE_ROUTES />;
            case ECompanyCode.ICICI:
              return <ICICI_ROUTES />;
            default:
              return null;
          }
        }
      })()}
    </>
  );
}
