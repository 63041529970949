import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { HEALTH_ROUTE } from "../../../../../../Router/Path/HealthRoutes";
import { ECompanyCode } from "../../../../../../Services/Enum/EHome";
import { HealthSlice } from "../../../../../../redux/slice/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";

const MMedicalDetailPreviewBoxNivabupaSen = () => {
  const { MEDICAL_QUESTION_DATA_NIVA_BUPA_SENIOR, SELECTED_QUOTE_DATA } =
    useAppSelector((state) => state.Health);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // Initialize a variable to track if a main question with status true is found
  let found = false;
  let founds = false;

  // Check if any main question's member has selection_status set to true
  Object.values(MEDICAL_QUESTION_DATA_NIVA_BUPA_SENIOR).forEach(
    (main_data: any) => {
      if (
        main_data &&
        main_data.main_question &&
        main_data.main_question.member_data
      ) {
        Object.values(main_data.main_question.member_data).forEach(
          (member_data: any) => {
            if (member_data && member_data.selection_status) {
              founds = true;
              return; // Break out of the loop
            }
          }
        );
      }
    }
  );

  // Check if any sub-question's member has selection_status set to true and the sub-question's status is true
  Object.values(MEDICAL_QUESTION_DATA_NIVA_BUPA_SENIOR).forEach(
    (main_data: any) => {
      if (
        main_data &&
        main_data.main_question &&
        main_data.main_question.sub_question
      ) {
        Object.values(main_data.main_question.sub_question).forEach(
          (sub_question_data: any) => {
            if (
              sub_question_data &&
              sub_question_data.status &&
              sub_question_data.member_data
            ) {
              Object.values(sub_question_data.member_data).forEach(
                (member_data: any) => {
                  if (member_data && member_data.selection_status) {
                    found = true;
                    return; // Break out of the loop
                  }
                }
              );
            }
          }
        );
      }
    }
  );

  return (
    <Box className="previewBox mb-6 mx-3">
      <Grid container columnSpacing={2}>
        <Grid xs={8}>
          {found || founds ? (
            <p className="previewBoxTitle">Insured Member's Medical Details</p>
          ) : (
            <p className="previewBoxTitle">No Medical History Declared</p>
          )}
        </Grid>
        <Grid xs={4} textAlign="right">
          <Link
            onClick={() => {
              dispatch(HealthSlice.actions.CHANGE_PAGE_STATUS(true));
              navigate(
                `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.MEDICAL_DETAILS}`
              );
            }}
            className="previewBoxEdit"
          >
            Edit
          </Link>
        </Grid>
      </Grid>
      {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
      ECompanyCode.NIVA_BUPA ? (
        <Grid xs={12}>
          {Object.values(MEDICAL_QUESTION_DATA_NIVA_BUPA_SENIOR).map(
            (mainData: any) => {
              console.log("mainData>>", mainData);

              return mainData.main_question.status ? (
                <div key={mainData.main_question.id}>
                  {Object.values(mainData.main_question.member_data || {}).some(
                    (memberData: any) => memberData.selection_status
                  ) && (
                    <>
                      {mainData.main_question.title === "" ? null : (
                        <h6 className="question">
                          {mainData.main_question.title}
                        </h6>
                      )}
                      <p className="mb-4">
                        {mainData.main_question.description}
                      </p>
                      {/* <p className="mb-4">
                        status:{" "}
                        {mainData.main_question.status ? "true" : "false"}
                      </p> */}
                    </>
                  )}
                  <ul className="member_list">
                    {Object.values(
                      mainData.main_question.member_data || {}
                    ).map(
                      (memberData: any) =>
                        memberData.selection_status && (
                          <li key={memberData.id}>
                            <div
                              className={`${memberData.image_class} illness`}
                            ></div>
                            <p className="member_name">{memberData.name}</p>
                            <p className="member_name">
                              {memberData.field_data &&
                                Object.values(memberData.field_data).map(
                                  (fieldData: any) => (
                                    <p key={fieldData.id}>
                                      {`${fieldData.title}: ${fieldData.value.value}`}
                                    </p>
                                  )
                                )}
                            </p>
                          </li>
                        )
                    )}
                    {/* {mainData.main_question.sub_question &&
                    Object.values(mainData.main_question.sub_question).map(
                      (sub_question_data: any) =>
                        sub_question_data.status && (
                          <React.Fragment key={sub_question_data.id}>
                            <Grid xs={12}>
                              <h6 className="">
                                {sub_question_data.description}
                              </h6>
                            </Grid>
                          </React.Fragment>
                        )
                    )} */}
                    {mainData.main_question.sub_question &&
                      Object.values(mainData.main_question.sub_question).map(
                        (subQuestionData: any) => {
                          console.log("subQuestionData>>>", subQuestionData);
                          return (
                            subQuestionData.status &&
                            Object.values(
                              subQuestionData.member_data || {}
                            ).some(
                              (memberData: any) => memberData.selection_status
                            ) && (
                              <React.Fragment key={subQuestionData.id}>
                                <Grid xs={12}>
                                  <h6 className="">{subQuestionData.title}</h6>
                                  <ul className="member_list">
                                    {Object.values(
                                      subQuestionData.member_data || {}
                                    ).map(
                                      (memberData: any) =>
                                        memberData.selection_status && (
                                          <li key={memberData.keyName}>
                                            <div
                                              className={`${memberData.image_class} illness`}
                                            ></div>
                                            <p className="member_name">
                                              {memberData.name}
                                            </p>
                                            {memberData.field_data &&
                                              Object.values(
                                                memberData.field_data
                                              ).map((fieldData: any) => (
                                                <p key={fieldData.id}>
                                                  {`${fieldData.title} : ${fieldData.value.value}`}
                                                </p>
                                              ))}
                                          </li>
                                        )
                                    )}
                                  </ul>
                                </Grid>
                              </React.Fragment>
                            )
                          );
                        }
                      )}
                  </ul>
                </div>
              ) : null;
            }
          )}
        </Grid>
      ) : (
        <Grid container columnSpacing={2}>
          <Grid xs={12}></Grid>
        </Grid>
      )}
    </Box>
  );
};

export default MMedicalDetailPreviewBoxNivabupaSen;
