import { Route, Routes } from "react-router-dom";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import { TRAVEL_ROUTE } from "../../../../Path/TravelRoutes";
import TravelProposalContainer from "../../TravelProposalContainer";
import ICICICKYCContainer from "../../../../../Container/ckyc-node/icici/ICICICKYCContainer";
import ICICIManualKYCContainer from "../../../../../Container/ckyc-node/icici/ICICIManualKYCContainer";

export const ICICI_ROUTES = () => {
  return (
    <Routes>
      <Route
        path={TRAVEL_ROUTE.PROPOSAL_PAGE}
        element={<TravelProposalContainer />}
      >
        <Route path={CKYC_ROUTES.CKYC} element={<ICICICKYCContainer />} />
        <Route
          path={CKYC_ROUTES.MANUAL_CKYC}
          element={<ICICIManualKYCContainer />}
        />
      </Route>
    </Routes>
  );
};
