import * as React from "react";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { TKotakMedicalQuestion } from "../../../../../../types/Health/HealthQuotation/TKotakMedicalQuestion";
import { HealthSlice } from "../../../../../../redux/slice/Health/HealthSlice";
import { HEALTH_ROUTE } from "../../../../../../Router/Path/HealthRoutes";
import { ECompanyCode } from "../../../../../../Services/Enum/EHome";
import { GET_QUERY_PARAMETERS } from "../../../../../../SupportingFiles/HelpingFunction";
const MedicalDetailPreviewBoxKotak = () => {
  const {
    MEDICAL_QUESTION_DATA_KOTAK,
    SELECTED_QUOTE_DATA,
    ADD_FORM_RESPONSE,
  } = useAppSelector((state) => state.Health);
  const navigate = useNavigate();
  const [medicalQuestionData, setMedicalQuestionData] =
    React.useState<TKotakMedicalQuestion>(MEDICAL_QUESTION_DATA_KOTAK);
  const dispatch = useAppDispatch();
  // Initialize a variable to track if a main question with status true is found
  let found = false;
  let founds = false;

  // Check if any main question's member has selection_status set to true
  Object.values(medicalQuestionData).forEach((main_data: any) => {
    if (
      main_data &&
      main_data.main_question &&
      main_data.main_question.member_data
    ) {
      Object.values(main_data.main_question.member_data).forEach(
        (member_data: any) => {
          if (member_data && member_data.selection_status) {
            founds = true;
            console.log("main_data:", main_data);
            console.log("member_data:", member_data);
            console.log("founds:", founds);
            return; // Break out of the loop
          }
        }
      );
    }
  });

  // Check if any sub-question's member has selection_status set to true and the sub-question's status is true
  Object.values(medicalQuestionData).forEach((main_data: any) => {
    if (
      main_data &&
      main_data.main_question &&
      main_data.main_question.sub_question
    ) {
      Object.values(main_data.main_question.sub_question).forEach(
        (sub_question_data: any) => {
          if (
            sub_question_data &&
            sub_question_data.status &&
            sub_question_data.member_data
          ) {
            Object.values(sub_question_data.member_data).forEach(
              (member_data: any) => {
                if (member_data && member_data.selection_status) {
                  found = true;
                  console.log("sub_question_data:", sub_question_data);
                  console.log("member_data:", member_data);
                  console.log("founds:", founds);
                  return; // Break out of the loop
                }
              }
            );
          }
        }
      );
    }
  });

  return (
    <Box className="previewBox mb-6 mx-3">
      <Grid container columnSpacing={2}>
        <Grid xs={8}>
          {found || founds ? (
            <p className="previewBoxTitle">Insured Member's Medical Details</p>
          ) : (
            <p className="previewBoxTitle">No Medical History Declared</p>
          )}
        </Grid>
        <Grid xs={4} textAlign="right">
          <Link
            onClick={() => {
              dispatch(HealthSlice.actions.CHANGE_PAGE_STATUS(true));
              navigate(
                `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
                  HEALTH_ROUTE.MEDICAL_DETAILS
                }?type=HP&companyCode=${
                  SELECTED_QUOTE_DATA?.CompanyDetails.company_code
                }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
                  SELECTED_QUOTE_DATA?.productDetails.id
                }&user_type=${GET_QUERY_PARAMETERS().userType}&utm_source=${
                  GET_QUERY_PARAMETERS().utm_source
                }&utm_medium=${GET_QUERY_PARAMETERS().utm_medium}`
              );
            }}
            className="previewBoxEdit"
          >
            <EditIcon /> Edit
          </Link>
        </Grid>
      </Grid>
      {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
      ECompanyCode.KOTAK ? (
        <Grid xs={12}>
          {Object.values(medicalQuestionData).map((mainData: any) =>
            mainData.main_question.status ? (
              <div key={mainData.main_question.id}>
                {Object.values(mainData.main_question.member_data || {}).some(
                  (memberData: any) => memberData.selection_status
                ) && (
                  <>
                    <h6 className="question">{mainData.main_question.title}</h6>
                    <p className="mb-4">{mainData.main_question.description}</p>
                  </>
                )}
                <ul className="member_list">
                  {Object.values(mainData.main_question.member_data || {}).map(
                    (memberData: any) =>
                      memberData.selection_status && (
                        <li key={memberData.id}>
                          <div
                            className={`${memberData.image_class} illness`}
                          ></div>
                          <p className="member_name">{memberData.name}</p>
                          <p className="member_name">
                            {memberData.field_data &&
                              Object.values(memberData.field_data).map(
                                (fieldData: any) => (
                                  <p key={fieldData.id}>
                                    {`${fieldData.title}: ${fieldData.value.value}`}
                                  </p>
                                )
                              )}
                          </p>
                        </li>
                      )
                  )}
                  {mainData.main_question.status &&
                    mainData.main_question.title ===
                      "Pre-Existing Diseases" && (
                      <div>
                        <h6 className="question">
                          {mainData.main_question.title}
                        </h6>
                        <p className="mb-4">
                          {mainData.main_question.description}
                        </p>
                      </div>
                    )}
                  {mainData.main_question.sub_question &&
                    Object.values(mainData.main_question.sub_question).map(
                      (sub_question_data: any) =>
                        sub_question_data.status && (
                          <React.Fragment key={sub_question_data.id}>
                            {Object.values(sub_question_data.member_data).some(
                              (member_data: any) => member_data.selection_status
                            ) && (
                              <>
                                <h6
                                  style={{
                                    width: "100%",
                                    color: "rgba(0, 0, 0, 0.6)",
                                    fontSize: "14px",
                                    fontWeight: 600,
                                  }}
                                >{` ${sub_question_data.title}`}</h6>
                              </>
                            )}
                            {Object.values(sub_question_data.member_data).map(
                              (member_data: any) =>
                                member_data.selection_status && (
                                  <li key={member_data.id}>
                                    <div
                                      className={`${member_data.image_class} illness`}
                                    ></div>
                                    <p className="member_name">
                                      {member_data.name}
                                    </p>
                                    <p className="member_name">
                                      {member_data.field_data &&
                                        Object.values(
                                          member_data.field_data
                                        ).map((field_data: any) => (
                                          <p key={field_data.id}>
                                            {`${field_data.title}: ${field_data.value.value}`}
                                          </p>
                                        ))}
                                    </p>
                                  </li>
                                )
                            )}
                          </React.Fragment>
                        )
                    )}
                  {/* {mainData.main_question.sub_question &&
                    Object.values(mainData.main_question.sub_question).map(
                      (sub_question_data: any) =>
                        sub_question_data.status && (
                          <React.Fragment key={sub_question_data.id}>
                            <Grid xs={12}>
                              <h6 className="">{sub_question_data.title}</h6>
                            </Grid>
                          </React.Fragment>
                        )
                    )} */}
                </ul>
              </div>
            ) : null
          )}
        </Grid>
      ) : (
        <Grid container columnSpacing={2}>
          <Grid xs={12}></Grid>
        </Grid>
      )}
    </Box>
  );
};

export default MedicalDetailPreviewBoxKotak;
