import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import TermFooter from "../../../../../Component/Term/TermFooter/TermFooter";
import TermRiderQuestion from "../../../../../Component/Term/TermRiderQuestion/TermRiderQuestion";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { TermSlice } from "../../../../../redux/slice/Term/TermSlice";
import { formatToCurrency } from "../../../../../SupportingFiles/HelpingFunction";
import "./../../../../../SCSS/CommonSCSS/ProposalForms.scss";

function RidersBenefits({
  forward,
  loaderTf001,
  loaderTf002,
  loaderTf003,
}: {
  forward: Function;
  loaderTf001: any;
  loaderTf002: any;
  loaderTf003: any;
}) {
  const { RIDERS_BENEFITS, QUOTE_FIELDS_DATA, LOADER, SELECTED_QUOTE_DATA } =
    useAppSelector((state) => state.Term);
  const dispatch = useAppDispatch();
  return (
    <Box>
      <h5 className="sectionTitle">Addon Detail</h5>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <TermRiderQuestion
            loader={loaderTf001}
            addon_amount={`${RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF003?.premium}`}
            main_class="addonQuestion borderBottom"
            icon_class={"accident"}
            policy_term=""
            // desc={
            //   RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF003
            //     ?.description
            // }
            question_name={"Accidental Death Benefit"}
            toggle_status={RIDERS_BENEFITS.local_value.tf001Status}
            value_update={(attrName: any, value: any) => {
              dispatch(
                TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "tf001Status",
                      value: value,
                    },
                  ],
                })
              );
            }}
            attrName={"tf003Value"}
          />
        </Grid>
        <Grid xs={12}>
          <TermRiderQuestion
            loader={loaderTf002}
            addon_amount={`${RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF002?.premium}`}
            main_class="addonQuestion borderBottom"
            icon_class={"accident"}
            policy_term=""
            // desc={
            //   RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF002
            //     ?.description
            // }
            question_name={
              "Additional Accidental Total and Permanent Disability Benefit"
            }
            toggle_status={RIDERS_BENEFITS.local_value.tf002Status}
            value_update={(attrName: any, value: any) => {
              dispatch(
                TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "tf002Status",
                      value: value,
                    },
                  ],
                })
              );
            }}
            attrName={"tf002Value"}
          />
        </Grid>
        <Grid xs={12}>
          <TermRiderQuestion
            loader={loaderTf003}
            addon_amount={`${RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF005?.premium}`}
            main_class="addonQuestion borderBottom"
            icon_class={"accident"}
            policy_term=""
            // desc={
            //   RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF003
            //     ?.description
            // }
            question_name={"Terminal Illness"}
            toggle_status={RIDERS_BENEFITS.local_value.tf003Status}
            value_update={(attrName: any, value: any) => {
              dispatch(
                TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "tf003Status",
                      value: value,
                    },
                  ],
                })
              );
            }}
            attrName={"tf003Value"}
          />
        </Grid>
        <Grid xs={12}>
          <TermRiderQuestion
            // loader={loaderTf004}
            addon_amount={`${RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF004?.premium}`}
            main_class="addonQuestion borderBottom"
            icon_class={"accident"}
            policy_term=""
            // desc={
            //   RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF004
            //     ?.description
            // }
            question_name={"Death Benefit"}
            toggle_status={true}
            // value_update={(attrName: any, value: any) => {
            //   dispatch(
            //     TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
            //       updates: [
            //         {
            //           key: "tf004Status",
            //           value: value,
            //         },
            //       ],
            //     })
            //   );
            // }}
            attrName={"tf004Value"}
            disable={true}
          />
        </Grid>
      </Grid>
      <TermFooter
        forward={() => {
          forward();
        }}
      />
    </Box>
  );
}

export default RidersBenefits;
