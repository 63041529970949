import { Alert, AlertTitle, Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./MPolicyConfirm.scss";
import { useAppSelector } from "../../../../../redux/hooks";
import { formatToCurrency } from "../../../../../SupportingFiles/HelpingFunction";

function MPolicyConfirm() {
  const { proposerDetails, proposerPage } = useAppSelector(
    (state) => state.TermFullJourney
  );
  const { SELECTED_QUOTE_DATA, QUOTE_FIELDS_DATA } = useAppSelector(
    (state) => state.Term
  );
  return (
    <Box className="paymentWrapperMobile ">
      <Grid container className="topbar">
        <Grid xs={12} spacing={3} alignSelf="center">
          <img src="./images/muthoot.svg" className="logo" alt="" />
        </Grid>
      </Grid>
      <Grid container className="innerWrapper">
        <Grid xs={12} spacing={3} className="mb-6 imgsec">
          <img src="./images/policy-confirmation.svg" alt="" />
        </Grid>
        <Grid xs={12} textAlign="center">
          <h3>Policy Confirmation</h3>
          <p className=" mb-6">
            <span>GREAT !!</span> Congratulations on buying your investment.
          </p>
        </Grid>
        <Grid xs={12} textAlign="center">
          <p className="mb-0 paymentDetail_p">
            {`Hi, ${proposerDetails.full_name.value}`}
          </p>
          <p className="paymentDetail_p mb-6">
            {proposerDetails.gender.value === "M" ? "Male" : "Female"} -{" "}
            {`${proposerDetails.dob.value}`}
          </p>
        </Grid>
        <Grid xs={12}>
          <div className="policy_detail mb-6">
            <div className="policy_detail_topbar">
              <Grid container padding="12px">
                <Grid xs={6}>
                  <p className="mb-0 detail_p" style={{ textAlign: "left" }}>
                    Application No. :- {SELECTED_QUOTE_DATA.appno}
                  </p>
                </Grid>
                <Grid xs={6}>
                  <p className="mb-0 detail_p" style={{ textAlign: "right" }}>
                    {`Transaction ID :- ${SELECTED_QUOTE_DATA.appno}`}
                  </p>
                </Grid>
              </Grid>
            </div>
            <Grid container padding="12px">
              <Grid xs={6}>
                <p className="mb-0 detail_p" style={{ textAlign: "left" }}>
                  <img
                    src={SELECTED_QUOTE_DATA.companyDetails.logo}
                    alt=""
                    style={{ width: "70px" }}
                  />
                </p>
              </Grid>
              <Grid xs={6}>
                <p className="mb-0 detail_p" style={{ textAlign: "right" }}>
                  Sum Assured
                  <span>
                    {formatToCurrency(Number(QUOTE_FIELDS_DATA.sumAssured))}
                  </span>
                </p>
              </Grid>
              <Grid xs={6}>
                <p className="mb-0 detail_p" style={{ textAlign: "left" }}>
                  {SELECTED_QUOTE_DATA.productDetails.product_name}
                </p>
              </Grid>
              <Grid xs={6}>
                <p className="mb-0 detail_p" style={{ textAlign: "right" }}>
                  Policy Term
                  <span>{SELECTED_QUOTE_DATA.premiumDetails.term} Years</span>
                </p>
              </Grid>
              <Grid xs={6}>
                <p className="mb-0 detail_p" style={{ textAlign: "left" }}>
                  Policy Paying Term
                  <span>
                    {SELECTED_QUOTE_DATA.premiumDetails.pay_term} Years
                  </span>
                </p>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid xs={12}>
          <Alert severity="info">
            <AlertTitle>Upload Documents</AlertTitle>
            <Link
              target="_blank"
              sx={{ cursor: "pointer", wordBreak: "break-all" }}
              href={proposerPage.PAYMENT_URL}
            >
              {proposerPage.PAYMENT_URL}
            </Link>
          </Alert>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MPolicyConfirm;
